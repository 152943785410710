import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Workflow } from "../../model/workflows/Workflow.model";
import { getMultipleWorkflowsByIdandVersion, getWorkflowByIdandVersion_dev, getWorkflowByIdandVersion_prod, getWorkflowByIdandVersion_uat } from "../../services/GetWorkflows.api";
import { ConnectionsCollection } from "../../model/connections/ConnectionsCollection.model";
import { getConnections_dev, getConnections_prod, getConnections_uat } from "../../services/GetConnections.api";
import { AppPersona } from "../../model/app-persona/AppPersona.model";
import { getAppPersonas_dev, getAppPersonas_uat, getAppPersonas_prod } from "../../services/GetAppPersonaMapping.api";
import { ModelMapping } from "../../model/configModels/ModelMapping.model";
import { getModelMapping_dev, getModelMapping_uat } from "../../services/GetModelMapping.api";
import { APIResponseMappingObject } from "../../model/apiConfig/APIResponseMappingObject.model";
import { getApiConfig_dev, getApiConfig_uat } from "../../services/APIConfig/GetApiConig.api";
import { MergeRule } from "../../model/merge-rules/MergeRule.model";
import { getMergeRules_dev, getMergeRules_uat } from "../../services/get-merge-rules/GetMergeRules.api";
import { getCatalogueConfig_dev, getCatalogueConfig_uat } from "../../services/GetCatalogueConfig.api";
import { CatalogueSchemaObject } from "../../model/configModels/CatalogueSchemaObject.model";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { EdmEntityTypeSettings } from "../../model/apiConfig/EdmEntityTypeSettings.model";
import { getConfigurationByIdandVersion_dev, getConfigurationByIdandVersion_prod, getConfigurationByIdandVersion_uat, getConfigurationSummaryByIdandVersion, getMultipleConfigurationsByIdandVersion } from "./Configuration/ConfigurationApi";
import { Campaign } from "../../model/SPAModels/campaigns/campaign.Model";


interface ReleaseDataState {
  devWorkflow: Workflow;
  uatWorkflow: Workflow;
  prodWorkflow: Workflow;

  devConfiguration: any;
  uatConfiguration: any;
  prodConfiguration: any;

  configurationSummary: any;
  configurationSummaryStatus: RequestStatus; 
  showConfigurationSummaryPanel: boolean;

  selectedConfigurationVersions: string[];
  selectedConfigurations: any;
  selectedConfigurationsStatus: RequestStatus; 
  showConfigurationsDiffPanel: boolean;
  showAuditHistoryPanel: boolean;

  selectedWorkflowVersions: string[]
  selectedWorkflows: Workflow[]
  selectedWorkflowsStatus: RequestStatus
  showWorkflowsDiffPanel: boolean
  
  devConnections: {[subscriptionKey: string]: ConnectionsCollection};
  uatConnections: {[subscriptionKey: string]: ConnectionsCollection};
  prodConnections: {[subscriptionKey: string]: ConnectionsCollection};
 
  devAppPersonas : AppPersona[];
  uatAppPersonas : AppPersona[];
  prodAppPersonas : AppPersona[];
  
  modelMappings : {
    devModelMapping: ModelMapping[];
    uatModelMapping: ModelMapping[];
   };
  
  apiConfig: {
    devApiConfig: APIResponseMappingObject;
    uatApiConfig: APIResponseMappingObject;
  };
  mergeRules: {
    devMergeRules: MergeRule[];
    uatMergeRules: MergeRule[];
  };
  
  catalogueConfig: {  
    devCatalogueConfig: CatalogueSchemaObject;
    uatCatalogueConfig: CatalogueSchemaObject;
  };
  getConfigStatus : {
    getWorkflowStatus: {
      dev: RequestStatus; uat: RequestStatus; prod: RequestStatus;
    }
    getConnectionsStatus: {
      dev: RequestStatus; uat: RequestStatus; prod: RequestStatus;
    }
    getAppPersonaStatus: {
      dev: RequestStatus; uat: RequestStatus; prod: RequestStatus;
    }
    getModelMappingStatus: {
      dev: RequestStatus; uat: RequestStatus;
    } 
    getMergeRulesStatus: {
      dev: RequestStatus; uat: RequestStatus;
    }
    getApiConfigStatus: {  
      dev: RequestStatus; uat: RequestStatus;
    }
    getCatalogueConfigStatus: {
      dev: RequestStatus; uat: RequestStatus;
    }
  }
}

const initialState: ReleaseDataState = {
    devWorkflow: {} as Workflow,
    uatWorkflow: {} as Workflow,
    prodWorkflow: {} as Workflow,
    devConfiguration: {},
    uatConfiguration: {},
    prodConfiguration: {},
    configurationSummary: {},
    selectedConfigurationVersions: [],
    selectedConfigurations: [],
    selectedConfigurationsStatus: RequestStatus.idle,
    configurationSummaryStatus: RequestStatus.idle,
    showConfigurationSummaryPanel: false,
    showConfigurationsDiffPanel: false,
    showAuditHistoryPanel: false,
    selectedWorkflowVersions: [],
    selectedWorkflows: [],
    selectedWorkflowsStatus: RequestStatus.idle,
    showWorkflowsDiffPanel: false,
    devConnections: {},
    uatConnections: {},
    prodConnections: {},
    devAppPersonas : [],
    uatAppPersonas : [],
    prodAppPersonas : [],
    modelMappings: {
      devModelMapping: [],
      uatModelMapping: []
    },
    apiConfig: {
      devApiConfig: {} as APIResponseMappingObject,
      uatApiConfig: {} as APIResponseMappingObject
    },
    mergeRules: {
      devMergeRules: [],
      uatMergeRules: []
    },
    catalogueConfig: { 
      devCatalogueConfig: {} as CatalogueSchemaObject,
      uatCatalogueConfig: {} as CatalogueSchemaObject
    },
    getConfigStatus : {
      getWorkflowStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle, prod : RequestStatus.idle},
      getAppPersonaStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle, prod : RequestStatus.idle},
      getConnectionsStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle, prod : RequestStatus.idle},
      getModelMappingStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle},
      getMergeRulesStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle},
      getApiConfigStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle},
      getCatalogueConfigStatus: {dev : RequestStatus.idle, uat : RequestStatus.idle}
    }

};

const releaseDataSlice = createSlice({
  name: "releaseData",
  initialState,
  reducers: {
    setSelectedWorflowVersions: (state, action: PayloadAction<string[]>) => {
      state.selectedWorkflowVersions = action.payload
    },
    setSelectedWorkflows: (state, action: PayloadAction<Workflow[]>) => {
      state.selectedWorkflows = action.payload
    },
    setSelectedConfigurationVersions: (state, action: PayloadAction<string[]>) => {
      state.selectedConfigurationVersions = action.payload
    },
    setSelectedConfigurations: (state, action: PayloadAction<[]>) => {
      state.selectedConfigurations = action.payload
    },
    setShowConfigurationsDiffPanel : (state, action: PayloadAction<boolean>) => {
      state.showConfigurationsDiffPanel = action.payload
    },
    setShowConfigurationsSummaryPanel : (state, action: PayloadAction<boolean>) => {
      state.showConfigurationSummaryPanel = action.payload
    },
    setShowWorkflowsDiffPanel : (state, action: PayloadAction<boolean>) => {
      state.showWorkflowsDiffPanel = action.payload
    },
    setShowAuditHistoryPanel : (state, action: PayloadAction<boolean>) => {
      state.showAuditHistoryPanel = action.payload
    },
    addConnection: (state, action: PayloadAction<{subskey:string, input: ConnectionsCollection, env:string}>) => {
      if(action.payload.env == "uat")
      state.uatConnections[action.payload.subskey] = action.payload.input;
      if(action.payload.env == "prod")
      state.prodConnections[action.payload.subskey] = action.payload.input;
    },
    patchModelMapping: (state, action: PayloadAction<{input: ModelMapping, env:string}>) => {
      if(action.payload.env == "uat")
      {
        state.modelMappings.uatModelMapping = state.modelMappings.uatModelMapping.filter(
          (item) => item.id !== action.payload.input.id);
        state.modelMappings.uatModelMapping = state.modelMappings.uatModelMapping.concat(action.payload.input);
      }
    },
    patchMergeRule: (state, action: PayloadAction<{input: MergeRule, env:string}>) => {
      if(action.payload.env == "uat" && state.mergeRules.uatMergeRules?.length > 0)
      {
        state.mergeRules.uatMergeRules = state.mergeRules.uatMergeRules?.filter(
          (item) => item.mergeRuleId !== action.payload.input.mergeRuleId);
        state.mergeRules.uatMergeRules = state.mergeRules.uatMergeRules.concat(action.payload.input);
      }
    },
    patchApiConfig: (state, action: PayloadAction<{input: EdmEntityTypeSettings, env:string, routeType: string}>) => 
    { 
      if (action.payload.env === "uat") {
        var edmEntity = action.payload.input
        if (action.payload.routeType === "Insight") {
        state.apiConfig.uatApiConfig.edmEntityTypeSettings = state.apiConfig.uatApiConfig?.edmEntityTypeSettings?.filter(
          item => item.routeName !== edmEntity.routeName
        )
        state.apiConfig.uatApiConfig.edmEntityTypeSettings = state.apiConfig.uatApiConfig.edmEntityTypeSettings.concat(edmEntity)
        }
        else if (action.payload.routeType === "Recommendation" ) {
          var sameRouteDiffFamily = state.apiConfig.uatApiConfig.edmEntityTypeSettings?.filter(item => item.routeName === edmEntity.routeName && item.type !== edmEntity.type)
          state.apiConfig.uatApiConfig.edmEntityTypeSettings = state.apiConfig.uatApiConfig.edmEntityTypeSettings?.filter(
            item => (item.routeName !== edmEntity.routeName) 
          )
          state.apiConfig.uatApiConfig.edmEntityTypeSettings = state.apiConfig.uatApiConfig.edmEntityTypeSettings.concat(edmEntity)
          if (sameRouteDiffFamily !== undefined && sameRouteDiffFamily !== null && sameRouteDiffFamily.length > 0)
            state.apiConfig.uatApiConfig.edmEntityTypeSettings = state.apiConfig.uatApiConfig.edmEntityTypeSettings.concat(sameRouteDiffFamily)
        }
      }
    },
    patchCatalogueConfig: (state, action: PayloadAction<{input: CatalogueSchemaObject, env:string}>) => {
      if (action.payload.env === "uat") {
        state.catalogueConfig.uatCatalogueConfig = action.payload.input
      }
    },
    setReleaseConfigurationStatus: (state, action: PayloadAction<{status: string, env:string}>) => {
      let currWorkflow:any = [];
      let updatedWorkflow:any = [];
      if(action.payload.env == "dev")
      {
        currWorkflow = state.devConfiguration;
        updatedWorkflow = {...currWorkflow, status : action.payload.status};
        state.devConfiguration = updatedWorkflow;
      };
      if(action.payload.env == "uat")
      {
        currWorkflow = state.uatConfiguration;
        updatedWorkflow = {...currWorkflow, status : action.payload.status};
        state.uatConfiguration = updatedWorkflow;
      };
      if(action.payload.env == "prod")
      {
        currWorkflow = state.prodConfiguration;
        updatedWorkflow = {...currWorkflow, status : action.payload.status};
        state.prodConfiguration = updatedWorkflow;
      };
    },
    setReleaseWorkflowStatus: (state, action: PayloadAction<{status: string, env:string}>) => {
      
      let currWorkflow:any = [];
      let updatedWorkflow:any = [];
      
      if(action.payload.env == "dev")
      {
        currWorkflow = state.devWorkflow;
        updatedWorkflow = {...currWorkflow, status : action.payload.status};
        state.devWorkflow = updatedWorkflow;
      };
      if(action.payload.env == "uat")
      {
        currWorkflow = state.uatWorkflow;
        updatedWorkflow = {...currWorkflow, status : action.payload.status};
        state.uatWorkflow = updatedWorkflow;
      };
      if(action.payload.env == "prod")
      {
        currWorkflow = state.prodWorkflow;
        updatedWorkflow = {...currWorkflow, status : action.payload.status};
        state.prodWorkflow = updatedWorkflow;
      };
      
    },
    patchReleaseWorkflow : (state, action: PayloadAction<{input: Workflow, env:string}>) => {

      if(action.payload.env == "dev")
      state.devWorkflow = action.payload.input
      if(action.payload.env == "uat")
      state.uatWorkflow = action.payload.input
      if(action.payload.env == "prod")
      state.prodWorkflow = action.payload.input
    },
    patchReleaseConfiguration : (state, action: PayloadAction<{input: any, env:string}>) => {

      if(action.payload.env == "dev")
      state.devConfiguration = action.payload.input
      if(action.payload.env == "uat")
      state.uatConfiguration = action.payload.input
      if(action.payload.env == "prod")
      state.prodConfiguration = action.payload.input
    }
    
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkflowByIdandVersion_dev.fulfilled, (state, action) => {
        state.devWorkflow = action.payload;
        state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.succeeded
      })
      .addCase(getWorkflowByIdandVersion_dev.pending, (state) => {
        state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.loading
      })
      .addCase(getWorkflowByIdandVersion_dev.rejected, (state) => {
        state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.failed
      })
      .addCase(getWorkflowByIdandVersion_uat.fulfilled, (state, action) => {
        state.uatWorkflow = action.payload;
        state.getConfigStatus.getWorkflowStatus.uat = RequestStatus.succeeded
      })
      .addCase(getWorkflowByIdandVersion_uat.pending, (state) => {
        state.getConfigStatus.getWorkflowStatus.uat = RequestStatus.loading
      })
      .addCase(getWorkflowByIdandVersion_uat.rejected, (state) => {
        state.getConfigStatus.getWorkflowStatus.uat = RequestStatus.failed
      })
      .addCase(getWorkflowByIdandVersion_prod.fulfilled, (state, action) => {
        state.prodWorkflow = action.payload;
        state.getConfigStatus.getWorkflowStatus.prod = RequestStatus.succeeded
      })
      .addCase(getWorkflowByIdandVersion_prod.pending, (state) => {
        state.getConfigStatus.getWorkflowStatus.prod = RequestStatus.loading      
      })
      .addCase(getWorkflowByIdandVersion_prod.rejected, (state) => {
        state.getConfigStatus.getWorkflowStatus.prod = RequestStatus.failed
      })
      .addCase(getConnections_dev.fulfilled, (state, action) => {
        state.devConnections = action.payload;
        state.getConfigStatus.getConnectionsStatus.dev = RequestStatus.succeeded
      })
      .addCase(getConnections_dev.pending, (state) => {
        state.getConfigStatus.getConnectionsStatus.dev = RequestStatus.loading
      })
      .addCase(getConnections_dev.rejected, (state) => {
        state.getConfigStatus.getConnectionsStatus.dev = RequestStatus.failed
      })
      .addCase(getConnections_uat.fulfilled, (state, action) => {
        state.uatConnections = action.payload;
        state.getConfigStatus.getConnectionsStatus.uat = RequestStatus.succeeded
      })
      .addCase(getConnections_uat.pending, (state) => {
        state.getConfigStatus.getConnectionsStatus.uat = RequestStatus.loading
      })
      .addCase(getConnections_uat.rejected, (state) => {
        state.getConfigStatus.getConnectionsStatus.uat = RequestStatus.failed
      })
      .addCase(getConnections_prod.fulfilled, (state, action) => {
        state.prodConnections = action.payload;
        state.getConfigStatus.getConnectionsStatus.prod = RequestStatus.succeeded
      })
      .addCase(getConnections_prod.pending, (state) => {
        state.getConfigStatus.getConnectionsStatus.prod = RequestStatus.loading
      })
      .addCase(getConnections_prod.rejected, (state) => {
        state.getConfigStatus.getConnectionsStatus.prod = RequestStatus.failed
      })
      .addCase(getAppPersonas_dev.fulfilled, (state, action) => {
        state.devAppPersonas = action.payload;
        state.getConfigStatus.getAppPersonaStatus.dev = RequestStatus.succeeded
      })
      .addCase(getAppPersonas_dev.pending, (state) => {
        state.getConfigStatus.getAppPersonaStatus.dev = RequestStatus.loading
      })
      .addCase(getAppPersonas_dev.rejected, (state) => {
        state.getConfigStatus.getAppPersonaStatus.dev = RequestStatus.failed
      })
      .addCase(getAppPersonas_uat.fulfilled, (state, action) => {
        state.uatAppPersonas = action.payload;
        state.getConfigStatus.getAppPersonaStatus.uat = RequestStatus.succeeded
      })
      .addCase(getAppPersonas_uat.pending, (state) => {
        state.getConfigStatus.getAppPersonaStatus.uat = RequestStatus.loading
      })
      .addCase(getAppPersonas_uat.rejected, (state) => {
        state.getConfigStatus.getAppPersonaStatus.uat = RequestStatus.failed
      })
      .addCase(getAppPersonas_prod.fulfilled, (state, action) => {
        state.prodAppPersonas = action.payload;
        state.getConfigStatus.getAppPersonaStatus.prod = RequestStatus.succeeded
      })
      .addCase(getAppPersonas_prod.pending, (state) => {
        state.getConfigStatus.getAppPersonaStatus.prod = RequestStatus.loading
      })
      .addCase(getAppPersonas_prod.rejected, (state) => {
        state.getConfigStatus.getAppPersonaStatus.prod = RequestStatus.failed
      })
      .addCase(getModelMapping_dev.fulfilled, (state, action) => {
        state.modelMappings.devModelMapping = action.payload;
        state.getConfigStatus.getModelMappingStatus.dev = RequestStatus.succeeded
      })
      .addCase(getModelMapping_dev.pending, (state) => {
        state.getConfigStatus.getModelMappingStatus.dev = RequestStatus.loading
      })
      .addCase(getModelMapping_dev.rejected, (state) => {
        state.getConfigStatus.getModelMappingStatus.dev = RequestStatus.failed
      })
      .addCase(getModelMapping_uat.fulfilled, (state, action) => {
        state.modelMappings.uatModelMapping = action.payload;
        state.getConfigStatus.getModelMappingStatus.uat = RequestStatus.succeeded
      })
      .addCase(getModelMapping_uat.pending, (state) => {
        state.getConfigStatus.getModelMappingStatus.uat = RequestStatus.loading
      })
      .addCase(getModelMapping_uat.rejected, (state) => {
        state.getConfigStatus.getModelMappingStatus.uat = RequestStatus.failed
      })
      .addCase(getApiConfig_dev.pending, (state) => {
        state.getConfigStatus.getApiConfigStatus.dev = RequestStatus.loading;
      })
      .addCase(getApiConfig_dev.rejected, (state) => {
        state.getConfigStatus.getApiConfigStatus.dev = RequestStatus.failed;
      })
      .addCase(getApiConfig_dev.fulfilled, (state, action) => {
        state.apiConfig.devApiConfig = action.payload;
        state.getConfigStatus.getApiConfigStatus.dev = RequestStatus.succeeded;
      })
      .addCase(getApiConfig_uat.pending, (state) => {
        state.getConfigStatus.getApiConfigStatus.uat = RequestStatus.loading;
      })
      .addCase(getApiConfig_uat.rejected, (state) => {
        state.getConfigStatus.getApiConfigStatus.uat = RequestStatus.failed;
      })
      .addCase(getApiConfig_uat.fulfilled, (state, action) => {
        state.apiConfig.uatApiConfig = action.payload;
        state.getConfigStatus.getApiConfigStatus.uat = RequestStatus.succeeded;
      })
      .addCase(getMergeRules_dev.fulfilled, (state, action) => {
        state.mergeRules.devMergeRules = action.payload;
        state.getConfigStatus.getMergeRulesStatus.dev = RequestStatus.succeeded
      })
      .addCase(getMergeRules_dev.pending, (state) => {
        state.getConfigStatus.getMergeRulesStatus.dev = RequestStatus.loading
      })
      .addCase(getMergeRules_dev.rejected, (state) => {
        state.getConfigStatus.getMergeRulesStatus.dev = RequestStatus.failed
      })
      .addCase(getMergeRules_uat.fulfilled, (state, action) => {
        state.mergeRules.uatMergeRules = action.payload;
        state.getConfigStatus.getMergeRulesStatus.uat = RequestStatus.succeeded
      })
      .addCase(getMergeRules_uat.pending, (state) => {
        state.getConfigStatus.getMergeRulesStatus.uat = RequestStatus.loading
      })
      .addCase(getMergeRules_uat.rejected, (state) => {
        state.getConfigStatus.getMergeRulesStatus.uat = RequestStatus.failed
      })
      .addCase(getCatalogueConfig_dev.pending, (state) => {
        state.getConfigStatus.getCatalogueConfigStatus.dev = RequestStatus.loading;
      })
      .addCase(getCatalogueConfig_dev.fulfilled, (state, action) => {
        state.catalogueConfig.devCatalogueConfig = action.payload;
        state.getConfigStatus.getCatalogueConfigStatus.dev = RequestStatus.succeeded;
    })
    .addCase(getCatalogueConfig_dev.rejected, (state) => {
      state.getConfigStatus.getCatalogueConfigStatus.dev = RequestStatus.failed;
    })
   .addCase(getCatalogueConfig_uat.pending, (state) => {
      state.getConfigStatus.getCatalogueConfigStatus.uat = RequestStatus.loading;
    })
   .addCase(getCatalogueConfig_uat.fulfilled, (state, action) => {
     state.catalogueConfig.uatCatalogueConfig = action.payload;
     state.getConfigStatus.getCatalogueConfigStatus.uat = RequestStatus.succeeded;
    })
   .addCase(getCatalogueConfig_uat.rejected, (state) => {
      state.getConfigStatus.getCatalogueConfigStatus.uat = RequestStatus.failed;
    })
    .addCase(getConfigurationByIdandVersion_dev.fulfilled, (state, action) => {
      state.devConfiguration = action.payload;
      state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.succeeded
    })
    .addCase(getConfigurationByIdandVersion_dev.pending, (state) => {
      state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.loading
    })
    .addCase(getConfigurationByIdandVersion_dev.rejected, (state) => {
      state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.failed
    })
    .addCase(getConfigurationByIdandVersion_uat.fulfilled, (state, action) => {
      state.uatConfiguration = action.payload;
      state.getConfigStatus.getWorkflowStatus.uat = RequestStatus.succeeded
    })
    .addCase(getConfigurationByIdandVersion_uat.pending, (state) => {
      state.getConfigStatus.getWorkflowStatus.uat = RequestStatus.loading
    })
    .addCase(getConfigurationByIdandVersion_uat.rejected, (state) => {
      state.getConfigStatus.getWorkflowStatus.dev = RequestStatus.failed
    })
    .addCase(getConfigurationByIdandVersion_prod.fulfilled, (state, action) => {
      state.prodConfiguration = action.payload;
      state.getConfigStatus.getWorkflowStatus.prod = RequestStatus.succeeded
    })
    .addCase(getConfigurationByIdandVersion_prod.pending, (state) => {
      state.getConfigStatus.getWorkflowStatus.prod = RequestStatus.loading
    })
    .addCase(getConfigurationByIdandVersion_prod.rejected, (state) => {
      state.getConfigStatus.getWorkflowStatus.prod = RequestStatus.failed
    })
    .addCase(getMultipleConfigurationsByIdandVersion.pending, (state) => {
      state.selectedConfigurationsStatus = RequestStatus.loading;
      state.showConfigurationsDiffPanel = true
    })
   .addCase(getMultipleConfigurationsByIdandVersion.fulfilled, (state, action) => {
     state.selectedConfigurations = action.payload;
     state.selectedConfigurationsStatus = RequestStatus.succeeded;
    })
   .addCase(getMultipleConfigurationsByIdandVersion.rejected, (state) => {
      state.selectedConfigurationsStatus = RequestStatus.failed;
    })
    .addCase(getConfigurationSummaryByIdandVersion.fulfilled, (state, action) => {
      state.configurationSummary = action.payload;
      state.configurationSummaryStatus = RequestStatus.succeeded;
      state.showConfigurationSummaryPanel = true;
    })
    .addCase(getConfigurationSummaryByIdandVersion.pending, (state) => {
      state.configurationSummaryStatus = RequestStatus.loading
    })
    .addCase(getConfigurationSummaryByIdandVersion.rejected, (state) => {
      state.configurationSummaryStatus = RequestStatus.failed
    })
    .addCase(getMultipleWorkflowsByIdandVersion.pending, (state) => {
      state.selectedWorkflowsStatus = RequestStatus.loading;
      state.showWorkflowsDiffPanel = true
    })
   .addCase(getMultipleWorkflowsByIdandVersion.fulfilled, (state, action) => {
     state.selectedWorkflows = action.payload;
     state.selectedWorkflowsStatus = RequestStatus.succeeded;
    })
   .addCase(getMultipleWorkflowsByIdandVersion.rejected, (state) => {
      state.selectedWorkflowsStatus = RequestStatus.failed;
    })
  },
});

export const { setShowWorkflowsDiffPanel, setSelectedWorflowVersions, setSelectedWorkflows, setReleaseWorkflowStatus, patchReleaseWorkflow, addConnection, patchModelMapping, patchMergeRule, patchApiConfig, patchCatalogueConfig, patchReleaseConfiguration,setReleaseConfigurationStatus,setSelectedConfigurationVersions,setShowConfigurationsDiffPanel,setShowConfigurationsSummaryPanel,setShowAuditHistoryPanel } = releaseDataSlice.actions;

export const releaseDataReducer = releaseDataSlice.reducer;
