import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { PreviewWorkflow } from "../../../model/preview-workflow/PreviewWorkflow.model";
import { getPreviewByPolling, getPreviewWorkflow } from "../../../services/GetPreviewWorkflow.api";
import { DataIngestion } from "../../../model/workflows/DataIngestion.model";
import { Processing } from "../../../model/workflows/Processing.model";

interface PreviewWorkflowState {
  map: { [workflowId: string]: PreviewWorkflow };
  status: RequestStatus; // todo: remove this after removing its dependency in merge
  error: string | undefined;
  isPanelOpen: boolean;
  isExpanded: boolean;
  dataIngestionStepIdStatusForAtrributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  };
  preProcessingStepIdStatusForAtrributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  };
  postProcessingStepIdStatusForAtrributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  };
  dataIngestionStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  };
  preProcessingStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  };
  postProcessingStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  };
  dataIngestionStepIdDismissToast: {
    [stepId: string]: boolean;
  };
  preProcessingStepIdDismissToast: {
    [stepId: string]: boolean;
  };
  postProcessingStepIdDismissToast: {
    [stepId: string]: boolean;
  };
  transformationStageLoading: {[workflowId: string]: boolean};
}

const initialState: PreviewWorkflowState = {
  map: {},
  status: RequestStatus.idle,
  error: "",
  isPanelOpen: false,
  isExpanded: false,
  dataIngestionStepIdStatusForAtrributes: {},
  preProcessingStepIdStatusForAtrributes: {},
  postProcessingStepIdStatusForAtrributes: {},
  dataIngestionStepIdStatusForTable: {},
  preProcessingStepIdStatusForTable: {},
  postProcessingStepIdStatusForTable: {},
  transformationStageLoading: {},
  dataIngestionStepIdDismissToast: {},
  preProcessingStepIdDismissToast: {},
  postProcessingStepIdDismissToast: {}
};
export const previewWorkflowSlice = createSlice({
  name: "previewWorkflow",
  initialState,
  reducers: {
    dismissStepToast: (state, action: PayloadAction<{stepId: string, stageName: string}>) => {
      switch(action.payload.stageName) {
        case "DataIngestion":
          state.dataIngestionStepIdDismissToast[action.payload.stepId] = true;
          state.dataIngestionStepIdDismissToast = {...state.dataIngestionStepIdDismissToast};
          break;
        case "PreProcessing":
          state.preProcessingStepIdDismissToast[action.payload.stepId] = true;
          state.preProcessingStepIdDismissToast = {...state.preProcessingStepIdDismissToast};
          break;
        case "PostProcessing":
          state.postProcessingStepIdDismissToast[action.payload.stepId] = true;
          state.postProcessingStepIdDismissToast = {...state.postProcessingStepIdDismissToast};
          break;
      }
    },
    setIsPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isPanelOpen = action.payload;
      if (!state.isPanelOpen) {
        state.isExpanded = false;
      }
    },
    setIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload;
      if (state.isExpanded) {
        state.isPanelOpen = true;
      }
    },
    updateTransformationStageLoading: (
      state,
      action: PayloadAction<{workflowId: string, loading: boolean}>
    ) => {
      state.transformationStageLoading[action.payload.workflowId] = action.payload.loading;
    },
    updateStepLoadingForAttributes: (
      state,
      action: PayloadAction<{
        dataIngestionStepIds: {stepID: string, outputViewName: string}[];
        preProcessingStepIds: {stepID: string, outputViewName: string}[];
        postProcessingStepIds: {stepID: string, outputViewName: string}[];
        loading: boolean;
        error: string | undefined;
      }>
    ) => {
      // data ingestion
      let updated = false;
      // reset all step loading status
      // Object.keys(state.dataIngestionStepIdStatusForAtrributes)?.forEach((stepId) => {
      //   state.dataIngestionStepIdStatusForAtrributes[stepId] = {
      //     loading: false,
      //     error: undefined
      //   };

      //   if (!updated) {
      //     updated = true;
      //   }
      // });

      if (
        action.payload.dataIngestionStepIds &&
        action.payload.dataIngestionStepIds.length > 0
      ) {
        action.payload.dataIngestionStepIds.forEach((step) => {
          state.dataIngestionStepIdStatusForAtrributes[step.stepID] = {
            loading: action.payload.loading,
            error: action.payload.error
          };
          // this is a hack to show loader in publish since there is only reference to outputViewName in publish - need to refactor publish code
          state.dataIngestionStepIdStatusForAtrributes[step.outputViewName] = {
            loading: action.payload.loading,
            error: action.payload.error
          };

          state.dataIngestionStepIdDismissToast[step.stepID] = false;

          if (!updated) {
            updated = true;
          }
        });
      }

      if (updated) {
        state.dataIngestionStepIdStatusForAtrributes = {
          ...state.dataIngestionStepIdStatusForAtrributes,
        };
        state.dataIngestionStepIdDismissToast = {...state.dataIngestionStepIdDismissToast}
      }

      // pre-processing

      updated = false;
      // reset all step loading status
      // Object.keys(state.preProcessingStepIdStatusForAtrributes)?.forEach((stepId) => {
      //   state.preProcessingStepIdStatusForAtrributes[stepId] = {
      //     loading: false,
      //     error: undefined
      //   };

      //   if (!updated) {
      //     updated = true;
      //   }
      // });

      if (
        action.payload.preProcessingStepIds &&
        action.payload.preProcessingStepIds.length > 0
      ) {
        action.payload.preProcessingStepIds.forEach((step) => {
          state.preProcessingStepIdStatusForAtrributes[step.stepID] = {
            loading: action.payload.loading,
            error: action.payload.error
          };
          // this is a hack to show loader in publish since there is only reference to outputViewName in publish - need to refactor publish code
          state.preProcessingStepIdStatusForAtrributes[step.outputViewName] = {
            loading: action.payload.loading,
            error: action.payload.error
          };

          state.preProcessingStepIdDismissToast[step.stepID] = false;

          if (!updated) {
            updated = true;
          }
        });
      }

      if (updated) {
        state.preProcessingStepIdStatusForAtrributes = {
          ...state.preProcessingStepIdStatusForAtrributes,
        };
        state.preProcessingStepIdDismissToast = {...state.preProcessingStepIdDismissToast}
      }

      // post-processing

      updated = false;
      // reset all step loading status
      // Object.keys(state.postProcessingStepIdStatusForAtrributes)?.forEach((stepId) => {
      //   state.postProcessingStepIdStatusForAtrributes[stepId] = {
      //     loading: false,
      //     error: undefined
      //   };

      //   if (!updated) {
      //     updated = true;
      //   }
      // });

      if (
        action.payload.postProcessingStepIds &&
        action.payload.postProcessingStepIds.length > 0
      ) {
        action.payload.postProcessingStepIds.forEach((step) => {
          state.postProcessingStepIdStatusForAtrributes[step.stepID] = {
            loading: action.payload.loading,
            error: action.payload.error
          };
          // this is a hack to show loader in publish since there is only reference to outputViewName in publish - need to refactor publish code
          state.postProcessingStepIdStatusForAtrributes[step.outputViewName] = {
            loading: action.payload.loading,
            error: action.payload.error
          };

          state.postProcessingStepIdDismissToast[step.stepID] = false;
          if (!updated) {
            updated = true;
          }
        });
      }

      if (updated) {
        state.postProcessingStepIdStatusForAtrributes = {
          ...state.postProcessingStepIdStatusForAtrributes,
        };
        state.postProcessingStepIdDismissToast = {
          ...state.postProcessingStepIdDismissToast
        }
      }
    },
    updateStepLoadingForTable: (
      state,
      action: PayloadAction<{
        dataIngestionStepIds: {stepID: string, outputViewName: string}[];
        preProcessingStepIds: {stepID: string, outputViewName: string}[];
        postProcessingStepIds: {stepID: string, outputViewName: string}[];
        loading: boolean;
        error: string | undefined;
      }>
    ) => {
      // data ingestion
      let updated = false;
      // reset all step loading status
      // Object.keys(state.dataIngestionStepIdStatusForTable)?.forEach((stepId) => {
      //   state.dataIngestionStepIdStatusForTable[stepId] = {
      //     loading: false,
      //     error: undefined
      //   };

      //   if (!updated) {
      //     updated = true;
      //   }
      // });

      if (
        action.payload.dataIngestionStepIds &&
        action.payload.dataIngestionStepIds.length > 0
      ) {
        action.payload.dataIngestionStepIds.forEach((step) => {
          state.dataIngestionStepIdStatusForTable[step.stepID] = {
            loading: action.payload.loading,
            error: action.payload.error
          };
          // this is a hack to show loader in publish since there is only reference to outputViewName in publish - need to refactor publish code
          state.dataIngestionStepIdStatusForTable[step.outputViewName] = {
            loading: action.payload.loading,
            error: action.payload.error
          };

          if (!updated) {
            updated = true;
          }
        });
      }

      if (updated) {
        state.dataIngestionStepIdStatusForTable = {
          ...state.dataIngestionStepIdStatusForTable,
        };
      }

      // pre-processing

      updated = false;
      // reset all step loading status
      // Object.keys(state.preProcessingStepIdStatusForTable)?.forEach((stepId) => {
      //   state.preProcessingStepIdStatusForTable[stepId] = {
      //     loading: false,
      //     error: undefined
      //   };

      //   if (!updated) {
      //     updated = true;
      //   }
      // });

      if (
        action.payload.preProcessingStepIds &&
        action.payload.preProcessingStepIds.length > 0
      ) {
        action.payload.preProcessingStepIds.forEach((step) => {
          state.preProcessingStepIdStatusForTable[step.stepID] = {
            loading: action.payload.loading,
            error: action.payload.error
          };
          // this is a hack to show loader in publish since there is only reference to outputViewName in publish - need to refactor publish code
          state.preProcessingStepIdStatusForTable[step.outputViewName] = {
            loading: action.payload.loading,
            error: action.payload.error
          };

          if (!updated) {
            updated = true;
          }
        });
      }

      if (updated) {
        state.preProcessingStepIdStatusForTable = {
          ...state.preProcessingStepIdStatusForTable,
        };
      }

      // post-processing

      updated = false;
      // reset all step loading status
      // Object.keys(state.postProcessingStepIdStatusForTable)?.forEach((stepId) => {
      //   state.postProcessingStepIdStatusForTable[stepId] = {
      //     loading: false,
      //     error: undefined
      //   };

      //   if (!updated) {
      //     updated = true;
      //   }
      // });

      if (
        action.payload.postProcessingStepIds &&
        action.payload.postProcessingStepIds.length > 0
      ) {
        action.payload.postProcessingStepIds.forEach((step) => {
          state.postProcessingStepIdStatusForTable[step.stepID] = {
            loading: action.payload.loading,
            error: action.payload.error
          };
          // this is a hack to show loader in publish since there is only reference to outputViewName in publish - need to refactor publish code
          state.postProcessingStepIdStatusForTable[step.outputViewName] = {
            loading: action.payload.loading,
            error: action.payload.error
          };

          if (!updated) {
            updated = true;
          }
        });
      }

      if (updated) {
        state.postProcessingStepIdStatusForTable = {
          ...state.postProcessingStepIdStatusForTable,
        };
      }
    },
    updatePreviewMapFromPolling: (state,
      action: PayloadAction<{workflowId: string, data: PreviewWorkflow}>) => {
        const payload = action.payload;
        if (payload) {
          state.map[payload.workflowId] = payload.data;
          state.map = {...state.map};
        }
        state.error = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getPreviewWorkflow.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload) {
          state.map[payload.workflowId] = payload.data;
          state.map = {...state.map};
        }
        state.error = undefined;
      })
      .addCase(getPreviewWorkflow.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getPreviewByPolling.fulfilled, (state, action) => {
        const payload = action.payload;
        if (payload) {
          state.map[payload.workflowId] = payload.data;
          state.map = {...state.map};
        }
        state.error = undefined;
      })
      .addCase(getPreviewByPolling.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

export const previewWorkflowReducer = previewWorkflowSlice.reducer;
