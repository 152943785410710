import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../root-redux/RootState";
import { postRequest } from "./utils/PostRequest";
import { postRequestReleaseManagement } from "./utils/PostRequestReleaseManagement";

export const postInsightFamily = createAsyncThunk<boolean, { family: string, env: string }, { state: RootState }>('post/workflows/addCollection', async ( insightFamilyInput, { getState }) => {

    const selectedEnv = getState().env.selectedEnvItem;
    const environment = insightFamilyInput.env == "" || insightFamilyInput.env == null ? selectedEnv : insightFamilyInput.env;
    const response = await postRequest<boolean, null>(`${environment}/workflows/addCollection/${insightFamilyInput.family}`, null);
    return response.data;
    
  });

  export const postInsightFamilyReleaseManagement = createAsyncThunk<boolean, { family: string, env: string }, { state: RootState }>('post/workflows/addCollection', async ( insightFamilyInput, { getState }) => {

    const selectedEnv = getState().env.selectedEnvItem;
    const environment = insightFamilyInput.env == "" || insightFamilyInput.env == null ? selectedEnv : insightFamilyInput.env;
    const response = await postRequestReleaseManagement<boolean, null>(`${environment}/workflows/addCollection/${insightFamilyInput.family}`, null);
    return response.data;
    
  });