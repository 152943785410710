import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { previewRecommendationsOData, previewRecommendationsVanilla } from "../../../services/PostRecommendationsApiPreview.api"


interface RecommendationsPreviewObject {
    recommendationsAPIOutputOData : Object
    recommendationsAPIOutputVanilla : Object 
    statusOData: RequestStatus,
    statusVanilla: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean
}

const initialState: RecommendationsPreviewObject = {
    recommendationsAPIOutputOData: {} as JSON,
    recommendationsAPIOutputVanilla: {} as JSON,
    statusOData: RequestStatus.idle,
    statusVanilla: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false
}

export const previewRecommendationsApiSlice = createSlice({
    name: 'apiPreviewRecommendations',
    initialState,
    reducers: {
        resetRecommendationsPreviewObject: (state) => {
            state.recommendationsAPIOutputOData = {} as JSON
            state.recommendationsAPIOutputVanilla = {} as JSON
            state.statusOData = RequestStatus.idle
            state.statusVanilla = RequestStatus.idle
            state.error = ''
            state.updateSuccessStatus = false
        }
    },
    extraReducers(builder) {
        builder
        .addCase(previewRecommendationsOData.pending, (state, action) => {
            state.statusOData = RequestStatus.loading
        })
        .addCase(previewRecommendationsOData.fulfilled, (state, action) => {
            state.statusOData = RequestStatus.succeeded
            const payload = action.payload
            if (payload) {

                state.recommendationsAPIOutputOData = payload
            }
            state.error = undefined
        })
        .addCase(previewRecommendationsOData.rejected, (state, action) => {
            state.statusOData = RequestStatus.failed
            state.error = action.error.message
        })
        .addCase(previewRecommendationsVanilla.pending, (state, action) => {
            state.statusVanilla = RequestStatus.loading
        })
        .addCase(previewRecommendationsVanilla.fulfilled, (state, action) => {
            state.statusVanilla = RequestStatus.succeeded
            const payload = action.payload
            if (payload) {

                state.recommendationsAPIOutputVanilla = payload
            }
            state.error = undefined
        })
        .addCase(previewRecommendationsVanilla.rejected, (state, action) => {
            state.statusVanilla = RequestStatus.failed
            state.error = action.error.message
        })
    }
})
export const apiPreviewRecommendationsReducer = previewRecommendationsApiSlice.reducer