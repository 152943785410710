import { useCallback, useEffect } from "react";
import { withErrorBoundary } from "../shared-components/ErrorBoundary";
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../root-redux/RootState";
import { NavMenuItemType } from "../model/NavMenu.model";
import { UserMode, setCampaignCache } from "./spa-admin/redux/CacheHandler.redux";
import { getCampaignById } from "../services/SPAAdmin/CampaignById.api";
import { ProgressRing } from "./spa-admin/SpaAdminScope";
import { Initialize } from "./spa-admin/utils/Initialize";
import { Helper } from "./spa-admin/utils/Help";
import { setEnvState } from "../core-components/user-profile/user-profile-redux/UserProfile.actions.redux";
import { getWorkflowsBySubKeys } from "../services/GetWorkflows.api";
import { SPA_SubscriptionKey } from "./spa-admin/SPAConstants";

const RedirectC: React.FC = () => {

    const navigate = useNavigate()
    const dispatchApp = useDispatch<AppDispatch>();

    let { entityEnv, entity, entityId } = useParams();

    // Temprory fix for entityEnv
    if (entityEnv === undefined) {
        entityEnv = 'uat';
    }

    window.localStorage.setItem("previousEnv", entityEnv || 'dev');
    dispatchApp(setEnvState(entityEnv || 'dev'));

    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);

    const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
        navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
    }, [navigate]);

    useEffect(() => {
        if (entity === 'Campaign' && entityId !== undefined && Helper.isReadyToLoad(userSalesRoles)) {
            dispatchApp(setCampaignCache({
                campaignId: entityId,
                mode: userSalesRoles.isApprover ? UserMode.REVIEW : UserMode.EDIT
            }));
            dispatchApp(getCampaignById(entityId)).then((campaignData) => {
                if (campaignData.meta.requestStatus === 'rejected') {
                    _onNavMenuItemClick(NavMenuItemType.Configuration);
                    return;
                }
                dispatchApp(getWorkflowsBySubKeys({ subKeys: campaignData.payload.subscriptionKey || SPA_SubscriptionKey.toLowerCase() })).then(() => {
                    _onNavMenuItemClick(NavMenuItemType.Campaign, userSalesRoles.isApprover ? "refine" : "summary");
                });
            }).catch(() => {
                _onNavMenuItemClick(NavMenuItemType.Configuration);
            });
        }
    }, [userSalesRoles, dispatchApp, entityId, entity, _onNavMenuItemClick]);

    return (
        <>
            <Initialize />

            <div className='spa-progressring'>
                <ProgressRing label="Loading..." indeterminate></ProgressRing>
            </div>
        </>
    );
}

export const Redirect = withErrorBoundary("URL Redirector", RedirectC);