import { useSelector } from "react-redux";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Icon } from "../SpaAdminScope";
import { RootState } from "../../../root-redux/RootState";

enum CampaignEditStage {
    Input,
    ImportAndRefine,
    ValidateAndApprove,
    Publish
}

const EditCampaignTabsC: React.FC<{stage: number}> = ({stage}) => {

    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);

    const stageNameMap = {
        [CampaignEditStage.Input]: {
            name: 'Add details',
            required: true
        },
        [CampaignEditStage.ImportAndRefine]: {
            name: 'Import & Refine',
            required: true
        },
        [CampaignEditStage.ValidateAndApprove]: {
            name: 'Validate & Approve data',
            required: true
        },
        [CampaignEditStage.Publish]: {
            name: 'Certify & Publish',
            required: true
        }
    }

    return (
        <div className="workflow-tab-bar spa-tab-bar">
            {
                userSalesRoles.isApprover &&
                <div className="workflow-tab">
                    <div className="workflow-tab-number-selected">1</div>
                    <div className={'workflow-tab-name-selected'}>{stageNameMap[CampaignEditStage.ValidateAndApprove].name} <span className={'workflow-tab-required'}></span></div>
                    
                </div>
            }
            {
                !userSalesRoles.isApprover &&
                Object.keys(CampaignEditStage).filter((v) => isNaN(Number(v)) && v !== 'Complete').map((stageName, index) => {
                    
                    let tabNumberComponent = <div className="workflow-tab-number-normal">{index + 1}</div>
                    let tabNameClassName = 'workflow-tab-name-normal'

                    if (index < stage) {
                        tabNumberComponent = <Icon className="workflow-tab-number-completed" name='skypecirclecheck' />
                    } else if (index === stage) {
                        tabNumberComponent = <div className="workflow-tab-number-selected">{index + 1}</div>
                        tabNameClassName = 'workflow-tab-name-selected'
                    }

                    const stageDetails = stageNameMap[index as CampaignEditStage];

                    return (
                        <div className="workflow-tab spa-tab">
                            {tabNumberComponent}
                            <div className={tabNameClassName}>{stageDetails.name} <span className={stageDetails.required ? 'workflow-tab-required' : 'workflow-tab-optional'}>{stageDetails.required ? '' : '(Optional)'}</span></div>
                            
                        </div>
                    )
                })
            }
        </div>
    );
}

export const EditCampaignTabs = withErrorBoundary("Edit workflow tabs", EditCampaignTabsC);