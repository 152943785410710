
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../shared-content/help-content/AllInsightAndRecommendations";
import "./HelpContent.css";
import { HelpNote } from "./Help-notes";
import { NebulaInformation } from "../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { AllInsightsAndRecommendations } from "./createNewWorkflow/allInsightsAndRecommendations";
import { InputEssentialInfo } from "./createNewWorkflow/InputEssentialInfo-Workflows";
import { DataSources } from "./createNewWorkflow/DataSources-Workflow";
import { DataRefine } from "./createNewWorkflow/DataRefine-Workflows";
import { DataPublish } from "./createNewWorkflow/Publish-Workflows";
import { DataMerge } from "./createNewWorkflow/DataMerge-Workflows";
import { DataConsume } from "./createNewWorkflow/DataConsume-Workflows";
import { MoreSupport } from "./createNewWorkflow/MoreSupport";

const HelpContentC: React.FC<any> = (props: any) => {

//   const [searchText, setSearchText] = useState<string>("");

//   const handleSearch = (e: any) => {
//     setSearchText(e.target.value);
// };
const [helpState, setHelpState] = useState<string>("allState");


  return (
    <>
     <FlyInPanel
     id = "helpPanelFlyIn"
      heading = "Help"
        open={props.openHelpPanel}
        style={{ '--size': '50%' }}
        onHeAfterHide={
          ({ target, currentTarget }) => (target === currentTarget ? props.setOpenHelpPanel(false) : null)
        }
        onHeHide={() => {setHelpState("allState")}}
      >
        {/* <div id = "top">
        <SearchBox
          style={{ width: "80%", marginBottom:"24px" }}
          onInput={handleSearch}
          placeholder="Search help"
          onHeClear={() => setSearchText("")}
        />
        </div> */}

      <div id = "allInsightsRecommendations" style = {{display:"flex", flexDirection:"column", marginBottom:"24px", fontSize:"18px", background:"#f7f7f7"}}>
        
        <span style = {{margin:"12px", fontWeight:"500", fontSize:"18px"}}>Index</span>
        <ol>
        <li key="allInsightsRecommendations-key" className="buttonlinks" >
        <Button id = "insrecsbutton"  onClick={() => {
          setHelpState("allState");
        }} appearance="command" className="buttonlinks_text">
            All Insights & Recommendations
        </Button>
        </li>

        <li key="onboarding_essentialInfo-key" className="buttonlinks" >
        <Button id = "createnewbutton" onClick={() => {
          setHelpState("onboarding_essentialInfo");
        }} appearance="command" className="buttonlinks_text">
          Create New / Update Insights & Recommendations
        </Button>
        </li>
       

      <div style = {{marginLeft:"28px"}} className="createWorkflowIndex">
        <ol >
        <li key="onboarding_dataSources-key" className="buttonlinks">
        <Button id = "datasourcesbutton" onClick={() => {
          setHelpState("onboarding_dataSources");
        }} appearance="command" className="buttonlinks_text">
          Define Data Sources
        </Button>
        </li>

        <li key="onboarding_refine-key" className="buttonlinks">
        <Button id = "refinebutton" onClick={() => {
          setHelpState("onboarding_refine");
        }} appearance="command" className="buttonlinks_text">
          Refine Onboarded Data
        </Button>
        </li>

        <li key="onboarding_publish-key" className="buttonlinks">
        <Button id="publishbutton" onClick={() => {
          setHelpState("onboarding_publish");
        }} appearance="command" className="buttonlinks_text">
          Publish Data
        </Button>
        </li>

        <li key="onboarding_merge-key" className="buttonlinks">
        <Button id="mergebutton" onClick={() => {
          setHelpState("onboarding_merge");
        }} appearance="command" className="buttonlinks_text">
          Merge
        </Button>
        </li>

        <li key="onboarding_consume-key" className="buttonlinks">
        <Button id="consumebutton" onClick={() => {
          setHelpState("onboarding_consume");
        }} appearance="command" className="buttonlinks_text">
          Consume
        </Button>
        </li>
        </ol>
        </div>

        <li key="moreSupport-key" className="buttonlinks" >
        <Button id="moresupportbutton" onClick={() => {
          setHelpState("moreSupport");
        }} appearance="command" className="buttonlinks_text">
            More Support Options
        </Button>
        </li>

        <li key="moreSupport1-key" className="buttonlinks" >
        <Button id="contactusbutton" onClick={() => {
          setHelpState("moreSupport");
        }} appearance="command" className="buttonlinks_text">
            Contact Us
        </Button>
        </li>
        </ol>
      </div> 

{ helpState === "allState" ? 
    <div>
      <AllInsightsAndRecommendations/>
      <div style = {{marginBottom:"18px"}}>
        <h3 >Create New/Update Insights & Recommendations</h3>
        <ol>
          <li key = "essentialinfo">
            <InputEssentialInfo/>
          </li>
          <li key = "datasources">
            <DataSources/>
          </li>
          <li key = "refine">
            <DataRefine/>
          </li>
          <li key = "publish">
            <DataPublish/>
          </li>
          <li key = "merge">
            <DataMerge/>
          </li>
          <li key = "consume">
            <DataConsume/>
          </li>
        </ol>
      </div>
      <MoreSupport/>
      </div> 
      // : helpState === "allInsightsRecommendations" ? <AllInsightsAndRecommendations/>
      : helpState === "onboarding_essentialInfo" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><h3 >Create New/Update Insights & Recommendations</h3><InputEssentialInfo/></>
      : helpState === "onboarding_dataSources" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><DataSources/></>
      : helpState === "onboarding_refine" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><DataRefine/></>
      : helpState === "onboarding_publish" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><DataPublish/></>
      : helpState === "onboarding_merge" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><DataMerge/></>
      : helpState === "onboarding_consume" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><DataConsume/></>
      : helpState === "moreSupport" ? <><Icon name="chromeback" className="backIcon" onClick={() => {setHelpState("allState")}}/><MoreSupport/></>
      : <></>
}

      </FlyInPanel>

    </>
  );
};
const HelpContent = withErrorBoundary("Help content", HelpContentC);
export default HelpContent;