import { useDispatch, useSelector } from "react-redux";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";
import { useEffect, useMemo, useRef, useState } from "react";
import { CartFilterSelector } from "../../redux/CampaignCartList.Selector.redux";
import { Button, Dropdown, Icon, Menu, MenuItem, ProgressRing, SearchBox, Select, Option } from "../../SpaAdminScope";
import { AppDispatch, RootState } from "../../../../root-redux/RootState";
import { getPredictions } from "../../../../services/SPAAdmin/CampaignDataCart.api";
import { Helper } from "../../utils/Help";
import { searchBox } from "@harmony/enablers/react";
import { setCartPageNum } from "../../redux/CampaignDataCart.redux";
import { RequestStatus } from "../../../../model/ServiceRequestStatus.model";
import { FilterComponent } from "./FilterPill";

const DataCartFilterC: React.FC = () => {
    
    const dispatch = useDispatch<AppDispatch>();
    const searchInput = useRef<searchBox>(null);

    const campaignById = useSelector((state: RootState) => state.campaignById);
    const workflows = useSelector((state: RootState) => state.workflowsBySubKeys);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);

    const CartFilterSelectorState = useSelector(CartFilterSelector);

    const keys = useMemo(() => {
        let fKeys: string[] = [];
        if (!userSalesRoles.isApprover) {
            fKeys.push('Area');
        }
        return fKeys.concat(["SubRegion", "Region", "SalesUnit", "Segment", "Subsegment", "Subsidiary", "Source"]);
    }, [userSalesRoles]);

    const initState: {[key:string]: any} = useRef({});

    keys.forEach((k) => {
        if (!Object.hasOwn(initState.current, k)) {
            initState.current[k] = {};
        }
        if (!Object.hasOwn(initState.current, 'TPID')) {
            initState.current['TPID'] = {};
        }
    });

    const [checkedState, setCheckedState] = useState<any>(initState.current);
    const [filtersList, setFilterList] = useState<string[]>([]);
    const [clearState, setClearState] = useState<boolean>(false);

    const handleCheckState = (k: string, e: any) => {
        const isSmallList = Object.keys(e).length < Object.keys(initState.current[k]).length;
        if (!isSmallList && clearState) {
            Object.keys(e).forEach(kk => e[kk] = initState.current[k][kk]);
        } else {
            Object.keys(e).forEach(kk => initState.current[k][kk] = e[kk]);
        }
        setClearState(isSmallList);
        setCheckedState({...checkedState, [k]: e});
        if ((k.toUpperCase() !== 'TPID' && Object.keys(e).every(fkey => (checkedState[k] || {})[fkey] === e[fkey])) || (!isSmallList && clearState)) {
            return;
        } else {
            dispatch(setCartPageNum(1));
            dispatch(getPredictions(initState.current));
        }
    }

    const handleFilterSelection = ({ target }: any) => {
        const ls = [...filtersList];
        ls.push(target.textContent);
        setFilterList([...ls]);
    }

    const clearFilters = () => {
        dispatch(setCartPageNum(1));
        setFilterList([]);
        const checked: any = {};
        for(const k in checkedState) {
            checked[k] = {};
            for(const v in checkedState[k]) {
                checked[k][v] = false;
            }
        }
        searchInput.current?.setAttribute("value", "");
        setCheckedState(checked);
    }

    useEffect(() => {
        if (campaignById.requestStatus === RequestStatus.loading) {
            clearFilters();
        }
    }, [campaignById])
    
    useEffect(() => {
        if (Helper.isEmpty(CartFilterSelectorState.details)) {
            return;
        }
        for(const k of keys) {
            const states: {[key:string]: boolean} = {};
            (CartFilterSelectorState.details[k] || []).forEach((v: string) => states[v] = false);
            initState.current[k] = states;
        }
        setCheckedState(initState.current);
    }, [CartFilterSelectorState, keys]);

    const handleSearch = (e: any) => {
        const searchText = e.target.value;
        if (searchText && searchText.length > 0) {
            initState.current['TPID'] = {[searchText]: true};
            handleCheckState('TPID', {[searchText]: true});
        } else {
            const state = {...checkedState};
            delete state['TPID'];
            delete initState.current['TPID'];
            setCheckedState(state);
            dispatch(getPredictions({...state}));
        }
    }

    return (
        <>
            {
                Helper.isLoadingStatus(campaignById.requestStatus, workflows.status) ? 
                    <ProgressRing indeterminate/>
                :
                <>
                    <SearchBox style={{marginRight: '10px'}} placeholder="Search by TPID or Name" onHeSearch={handleSearch} ref={searchInput}/>
                    <Button id="user-info" appearance="stealth" style={{width: '10px', marginRight: '0'}}><Icon name='info' style={{ cursor: "pointer", color: "#0078D4", paddingRight: "15px", paddingLeft: "15px" }}/></Button>
                    <Dropdown style={{ marginRight: "0" }}>
                        <Button slot="trigger" appearance='stealth' style={{paddingRight: "0"}}>
                            <Icon name='filter' style={{ color: "#0078D4" }}/>
                        </Button>
                        <Menu>
                        {
                            keys.map(k => <MenuItem onHeChange={handleFilterSelection}>{k}</MenuItem>)
                        }
                        </Menu>
                    </Dropdown>
                    {
                        !Helper.isEmpty(filtersList) &&
                        <Button appearance='stealth' style={{paddingRight: "0", marginRight: "0"}} onClick={() => {
                            clearFilters();
                            dispatch(getPredictions({}));
                        }}>
                            <Icon name='cancel' style={{ color: "#0078D4" }}/>
                        </Button>
                    }
                    {
                        <div style={{ marginTop: '3px', marginLeft: '5px' }}>
                        {
                            !Helper.isEmpty(CartFilterSelectorState.details) &&
                            filtersList.map(label => (
                                <FilterComponent selectedChecks={initState.current[label]} filterlabel={label} allData={CartFilterSelectorState.details[label]} checkedState={checkedState[label]} setCheckedState={(e: any) => handleCheckState(label, e)} searchBarVisible={true} />
                            ))
                        }
                        </div>
                    }
                </>
            }
        </>
    )
}

export const DataCartFilter = withErrorBoundary('Campaign: DataCart Filter', DataCartFilterC);