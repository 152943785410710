export class Schedule
{
    public isScheduleCreated!: boolean
    public jobId!: string 
    public timePeriod!: string // recurrence
    public frequency!: string  //Every
    public startFrom!: string | null
    public minute!: string 
    public hour!: string 
    public dayOfMonth!: string
    public dayOfWeek!: string
    public maxRetries!: number
    public quartz_Cron_Expression!: string
    public timezone_Id!: string // timezone_Id
    public pause_Status!: string
}

export class Job
{
    public job_id!: number
    public settings!: Settings
}

export class Settings
{
    public schedule!: Schedule
}