import { createAsyncThunk } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from 'uuid';
import { postRequestFileUpload } from "./utils/PostRequest";
import { setCurrentDatasetFileUploadState } from "../feature-components/workflows/workflows-redux/DatasetFileUpload.redux";
import { setWorkflowDatasetFileUploadState } from "../feature-components/workflows/workflow-by-id-redux/WorkflowById.redux";
import { postWorkflow } from "./PostWorkflow.api";
import { RootState } from "../root-redux/RootState";
import { resolve } from "path";


export const uploadDatasetFile = createAsyncThunk<any, any, { state: RootState}>('fileUploadDataset/Submit', async ({payload, area=""}, {getState, dispatch}) => {
  try{
    const selectedEnvItem  = getState().env.selectedEnvItem;
    let response;
    if(area)
      response = await postRequestFileUpload<any, any>(`${selectedEnvItem}/fileupload/submit?area=`+area, payload);
    else
      response = await postRequestFileUpload<any, any>(`${selectedEnvItem}/fileupload/submit`, payload);
    if(response && response.status == 200)
    {
      dispatch(setWorkflowDatasetFileUploadState(response.data.fileUploadObj));
      dispatch(setCurrentDatasetFileUploadState(response.data));
      await dispatch(postWorkflow({workflow: getState().workflowById.workflow, env: selectedEnvItem})).unwrap();
    }
    return response.data
  }
  catch(e){
    console.log(e);
  }
});
