import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { Dialog, Button } from "../../../common/HarmonyEnablers";
import { useNavigate } from "react-router-dom";
import { MergeRule } from "../../../model/merge-rules/MergeRule.model";
import { postMergeRule } from "../../../services/get-merge-rules/PostMergeRule.api";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Loader } from "../../../shared-components/loader/Loader";
import { addMergeRulesByModel, updateMergeRuleByModel, updateMergeRulesByModel } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux";
import { updateDefaultWarningMsg } from "../../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { updateMergeRule } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { Icon } from "../../workflows/WorkflowsScope";
import { addMergeRuleBySubKey } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleBySubKey.redux";
import { patchMergeRule } from "../../../services/get-merge-rules/PatchMergeRule.api";
import { Workflow, WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { MergeRuleSet, emptyMergeRuleSet } from "../../../model/merge-rules/MergeRuleSet.model";
import { v4 as uuidv4 } from 'uuid';
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";

export const ResetDefaultMergeRulePopup: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch();
  const workflow = useSelector((state: RootState): Workflow => state.workflowById.workflow);
  const {
    setOpenResetPopup, setPopUpShown, setDisableCreate, defaultMergeRule,
    setShowSuccessNotif, setSuccessText, setShowErrorNotif, setErrorText, setDefaultMergeRule
  } = props
  const postMergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.status)
  const permissionsList = useSelector(permissionsSelector);
  const userTeams = permissionsList.userTeams;
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const workflowList = useSelector((state: RootState): WorkflowSummary[] => state.workflows.list);

  const handleRequestClose = (event: any) => {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      setOpenResetPopup(false)
    }
  };

const fillDefaultMergeRule = () => {
  var localDefaultMergeRule = {} as MergeRule;
  var localWorkflow = workflowList.find(x => x.config.workflowId === workflow.config.workflowId)

  var localFamily = "", localModelName = localWorkflow?.modelName || "", localMergeRuleName = defaultMergeRule.mergeRuleId, localDistinctField = ""
  var primaryKeyslocal: string[] = []
  workflow.publish.forEach(element => {
    if (element.type === "COSMOS" && element.publishTo.toLocaleLowerCase() === "nebula") {
      if (element.primaryKeys) {
        primaryKeyslocal = element.primaryKeys.split(",")
      } else
      // MRTODO Remove after migration is complete
      {
        element.publishMap.forEach(eachField => {
          if (eachField.isPrimary && eachField.destination !== "PartitionKey") {
            primaryKeyslocal.push(eachField.destination)
          }
          if (eachField.destination === "Primary.Family") {
            localFamily = eachField.source
          }
          if (eachField.destination === "PartitionKey") {
            localDistinctField = eachField.source
          }
        });
      }

      if (!localFamily) {
        localFamily = workflow.family
        // MRTODO Remove after migration is complete
        if (!localFamily) {
          var localFamilObj = element.publishMap.find(x => { return x.destination === "Primary.Family" })
          if (localFamilObj) {
            localDistinctField = localFamilObj.source
          }
        }
      }
      if (!localDistinctField) {
        var distinctFieldObj = element.publishMap.find(x => { return x.destination === "PartitionKey" })
        if (distinctFieldObj) {
          localDistinctField = distinctFieldObj.source
        }
      }
    }
  });

  var primaryMergeRuleSet: MergeRuleSet[] = []
  var obj = emptyMergeRuleSet;
  obj = {
    ...obj,
    id: uuidv4().toString(),
    rank: '1',
    name: 'Default Merge Ruleset',
    priority: [localModelName],
    mergeModel: [localModelName],
    mergeOn: { fields: { default: primaryKeyslocal || [] }, mergeConditions: [] }
  }

  primaryMergeRuleSet.push(obj)

  localDefaultMergeRule = {
    ...defaultMergeRule,
    mergeRuleId: localMergeRuleName,
    mergeRuleName: localMergeRuleName,
    subscriptionKey: workflow.subscriptionKey,
    tenantName: workflow.tenantName || userTeams.name[userTeams.key.indexOf(workflow.subscriptionKey)],
    version: defaultMergeRule.version || '1',
    modifiedBy: userDetails?.localAccountId,
    tags: localModelName,
    description: "Default merge rule for " + localModelName,
    primaryMergeRuleSet: primaryMergeRuleSet,
    type: localFamily,
    secondaryMergeRuleSet: [] as MergeRuleSet[],
    sourceFilter: [localModelName],
    distinctField: localDistinctField
  }
  return localDefaultMergeRule
}

  const handleSave = async () => {
    var localDefaultMergeRule = fillDefaultMergeRule()
    var output = await dispatchApp(patchMergeRule({reset: true, mergeRule: localDefaultMergeRule }))
    if (output.payload === true) {
      setDisableCreate(true)
      setDefaultMergeRule(localDefaultMergeRule)
      dispatch(updateMergeRule(defaultMergeRule))
      dispatch(updateMergeRuleByModel(defaultMergeRule))
      dispatch(updateMergeRulesByModel(defaultMergeRule))
      setDisableCreate(true)
      setOpenResetPopup(false)
      setSuccessText(defaultMergeRule.mergeRuleId + " reset successfully.")
      setShowSuccessNotif(true)
      dispatchApp(updateDefaultWarningMsg(false))
    } else {
      setDisableCreate(false)
      setErrorText(defaultMergeRule.mergeRuleId + " failed to reset.")
      setShowErrorNotif(true)
    }
  }

  const handleNoTypeChange = () => {
    setOpenResetPopup(false)
    setPopUpShown(true)
  }

  return (
    <>
      <Dialog heading={"Reset Default Merge Rule"} open onHeRequestClose={handleRequestClose}
        onHeAfterHide={
          ({ target, currentTarget }) => (target === currentTarget ? (false) : null)
        }
      >
        <p style={{ fontSize: "14px" }}> There has been change in Workflow's Classification of Recommendation/Insight Type from {defaultMergeRule.type} to {workflow.family}.</p>
        <p style={{ fontSize: "14px" }}> <b>Do you wish to reset the Default Merge Rule to reflect this change?</b> </p>
        
        <Button slot="footer" appearance="primary" onClick={() => { handleSave() }}>
          Yes
        </Button>
        <Button slot="footer" onClick={() => { handleNoTypeChange() }}>
          No
        </Button>
      </Dialog>
    </>
  );
};
