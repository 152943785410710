import * as CryptoJS from 'crypto-js'
import { DataIngestion } from '../workflows/DataIngestion.model';
import { Processing } from '../workflows/Processing.model';

export class PreviewWorkflow {
  public adbContextId!: string;
  public dataIngestion!: {
    [outputViewName: string]: PreviewWorkflowStepOutput;
  };
  public preProcessing!: {
    [outputViewName: string]: PreviewWorkflowStepOutput;
  };
  public postProcessing!: {
    [outputViewName: string]: PreviewWorkflowStepOutput;
  };
  public publish!: { [outputViewName: string]: PreviewWorkflowStepOutput };
}

export class PreviewWorkflowStepOutput {
  public adbCommandId!: string;
  public stepID!: string;
  public adbOutput!: string;
  public adbOutputAttributes!: [string, string][]; // [attributeName, attributeDataType]
  public adbStatus!: ADBStatus;
  public adbResultType!: ADBResultType;
  public status!: PreviewStatus;
  public previewInputHash!: string;
  public outputType!: PreviewOutputType;
  public outputViewName!: string;
}

export enum ADBStatus {
  Unknown,
  Cancelled,
  Cancelling,
  Error,
  Finished,
  Queued,
  Running,
}

export enum ADBResultType {
  text,
  error,
  image,
  images,
  table,
}

export enum PreviewStatus {
  Saved,
  Unsaved,
  Save,
}

export enum PreviewOutputType {
  Attributes,
  PreviewDataTable
}

const hashObject = (obj: any): string => {
  const str = JSON.stringify(obj);
  const hash = CryptoJS.SHA256(str).toString();
  return hash;
}

export const getPreviewDataIngestionStepHash = (step?: DataIngestion): string => {
  if (!step) {
    return ''
  }
  const { type, inputViewName, outputViewName, path, appModelName, scalaScript, query, connectionId } = step;
  const obj = { type, inputViewName, outputViewName, path, appModelName, scalaScript, query, connectionId };
  return hashObject(obj);
}

export const getPreviewProcessingStepHash = (step?: Processing): string => {
  if (!step) {
    return ''
  }
  const { type, inputViewName, outputViewName, path, appModelName, scalaScript, query, jsonQueryString } = step;
  const obj = { type, inputViewName, outputViewName, path, appModelName, scalaScript, query, jsonQueryString };
  return hashObject(obj);
}