import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../common/HarmonyEnablers"
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { postScheduleWorkflow } from "../../../services/PostScheduleWorkflow.api";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Spinner } from "../../../shared-components/loader/Spinner";

export const ScheduleWorkflowFooter: React.FC<{showEnv?: boolean}> = ({showEnv}) => {
    const dispatch = useDispatch<AppDispatch>();
    const postScheduleWorkflowStatus = useSelector((state: RootState) => state.workflowById.postScheduleWorkflowStatus);
    return (
        <div slot="footer" className="display-flex">
            <Button disabled={postScheduleWorkflowStatus===RequestStatus.loading} appearance='primary' onClick={() => dispatch(postScheduleWorkflow({showEnv: showEnv}))}>Schedule</Button>
            {
                postScheduleWorkflowStatus===RequestStatus.loading &&
                (<div className="schedule-loader">
                  <Spinner type='small' />
                  <div className="unsaved-text">{'Saving changes...'}</div>
                </div>)
                
            }
        </div>
    )
}