import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { WorkflowSummary } from "../../model/workflows/Workflow.model";
import { OutputRow } from "../../model/merge-rules/OutputRow.model";

export interface OutputRowDataGrid extends Row {
  cells: {
    field1: string,
    field2: string,
    field3: string
  }
}
export interface LearnMoreDataGrid extends Row {
  cells: {
    field1: string,
    field2: string,
    field3: string,
    field4: string,
    field5: string,
    field6: string
  }
}
export const outputRowColumns: Column[] = [
  {
    field: 'field1',
    content: 'Field',
  },
  {
    field: 'field2',
    content: 'Command',
  },
  {
    field: 'field3',
    content: '',
  }
];
export const LearnMoreColumns: Column[] = [
  {
    field: 'field1',
    content: 'Command',
  },
  {
    field: 'field2',
    content: 'Description',
  },
  {
    field: 'field3',
    content: 'Formula',
  },
  {
    field: 'field4',
    content: 'DataType',
  },
  {
    field: 'field5',
    content: 'Rules',
  },
  {
    field: 'field6',
    content: 'Example',
  }
];

export const addOutputRowData = (data: OutputRow[], workflowsBySubKeys: WorkflowSummary[]): OutputRowDataGrid[] => {
  var i = 1;
  var transformedToRowItems: OutputRowDataGrid[] = []
  if (data && data.length > 0) {
    data.forEach((item: OutputRow) => {
      var commandForDisplay = item.commandForDisplay && item.commandForDisplay.replace(/[{}']/g, '') || ""
      commandForDisplay =  commandForDisplay && commandForDisplay.replace(/ . /g, '.').replace(/,/g, ", ") || ""
      transformedToRowItems.push(
        {
          id: item.id,
          cells: {
            field1: item.field,
            field2: commandForDisplay, 
            field3: ""
          }
        })
      i++;
    });
  }
  return transformedToRowItems
}

export const convertQueryToFilterFormat = (filter: string, workflowsBySubKeys: WorkflowSummary[]) => {
  var allTextArray: string[] = []
  filter = filter.replace(/{Calendrical . 'Days'}/g, "{d}")
  filter = filter.replace(/{Calendrical . 'Months'}/g, "{M}")
  filter = filter.replace(/{Calendrical . 'Years'}/g, "{y}")
  filter = filter.replace(/{TodaysDate . 'Now'}/g, "{Now}")

  var allStaticText = filter.match(/{StaticText = '([^{]*)'}|{StaticNumber = ([^{]*)}/g)
  if (allStaticText && allStaticText.length > 0) {
    allStaticText.forEach((text) => {
      var local = text
      if (text.includes("StaticText")) {
        local = text.replace(/{StaticText = /g, '')
      }
      if (text.includes("StaticNumber")) {
        local = text.replace(/{StaticNumber = /g, '')
      }
      local = local.replace(/['}]/g, '')
      allTextArray.push(local)
    })
    filter = filter.replace(/{StaticText = '([^{]*)'}/g, 'NEBULA')
    filter = filter.replace(/{StaticNumber = ([^{]*)}/g, 'NEBULA')
    filter = filter.replace(/[ ']/g, "")
  } else {
    filter = filter.replace(/[ ']/g, "")
  }
  workflowsBySubKeys.forEach((eachWorkflow) => {
    filter = filter && filter.replaceAll(eachWorkflow.config.workflowName, eachWorkflow.modelName)
  })
  allTextArray.forEach((eachText) => {
    filter = filter.replace(/NEBULA/, eachText)
  })
  return filter
}



export const LearnMoreData = (): LearnMoreDataGrid[] => {
  var i = 1;
  var transformedToRowItems: LearnMoreDataGrid[] = []

  transformedToRowItems.push(
    {
      id: "1",
      cells: {
        field1: "FIRST",
        field2: "Picks data from the input which resolves first",
        field3: "FIRST(<input1>,<input2>,...)",
        field4: "String, Integer, Decimal, Boolean, Date",
        field5: "Should have atleast 1 input.|Input can be static text or a field from insight/recommendation.",
        field6: "FIRST(RecommendationName1.Field1)|FIRST(RecommendationName1.Field1,StaticText.Nebula Text)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "2",
      cells: {
        field1: "LIST",
        field2: "Adds to a list field in the order of resolution of inputs",
        field3: "LIST(<input1>,<input2>,...)",
        field4: "String, Integer, Decimal, Boolean, Date",
        field5: "Should have atleast 1 input.|Input can be static text or a field from insight/recommendation.",
        field6: "LIST(RecommendationName1.Field1)|LIST(RecommendationName1.Field1,StaticText.Nebula Text)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "3",
      cells: {
        field1: "CONCAT",
        field2: "Concatenates data resolved from each input",
        field3: "CONCAT(<input1>,<input2>,...)",
        field4: "String, Integer, Decimal, Boolean, Date",
        field5: "Should have atleast 1 input.|Input can be static text or a field from insight/recommendation.",
        field6: "CONCAT(RecommendationName1.Field1)|FIRST(RecommendationName1.Field1,StaticText.Nebula Text)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "4",
      cells: {
        field1: "MAX",
        field2: "Picks max data from the inputs",
        field3: "MAX(<input1>,<input2>,...)",
        field4: "Integer, Decimal, Date",
        field5: "Should have atleast 1 input.|Input can be static number/date or a field from insight/recommendation.",
        field6: "MAX(RecommendationName1.Field1)|MAX(RecommendationName1.Field1,StaticNumber.3)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "5",
      cells: {
        field1: "MIN",
        field2: "Picks min data from the inputs",
        field3: "MIN(<input1>,<input2>,...)",
        field4: "String, Integer, Decimal, Boolean, Date",
        field5: "Should have atleast 1 input.|Input can be static number/date or a field from insight/recommendation.",
        field6: "MIN(RecommendationName1.Field1)|MIN(RecommendationName1.Field1,TodaysDate.Now)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "6",
      cells: {
        field1: "DATEADD",
        field2: "Adds x number of days/months/years to a date",
        field3: "DATEADD(<DateInput1>,<Calendrical>,<NumberOfDaysToAdd>)",
        field4: "Date",
        field5: "Should have 3 inputs.|First Input has to be date. It can be static date or a field of type Date from insight/recommendation and can be wrapped under FIRST,MAX,MIN.|Secondary input has to be a calendrical|Third input has to represent number to add to date.",
        field6: "DATEADD(RecommendationName1.Field1,Calendrical.Days,StaticNumber.4)|DATEADD(FIRST(RecommendationName1.Field1,TodaysDate.Now),Calendircal.Years,MAX(InsightName.FieldName,StaticNumber.10))"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "7",
      cells: {
        field1: "DATEDIFF",
        field2: "Finds difference between 2 dates in days/months/years",
        field3: "DATEDIFF(<DateInput1>,<Calendrical>,<DateInput2>)",
        field4: "Date",
        field5: "Should have 3 inputs.|First and third inputs have to be date.|Second input has to be Calendrical.",
        field6: "DATEDIFF(RecommendationName1.Field1,Calendrical.Days,TodaysDate.Now)|DATEDIFF(FIRST(RecommendationName1.Field1,TodaysDate.Now),Calendircal.Years,MAX(InsightName.FieldName,TodaysDate.Now))"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "8",
      cells: {
        field1: "REPLACE",
        field2: "Replaces a substring with another substring in a string",
        field3: "REPLACE(<CompleteString>,<StringToBeReplaced>,<StringToReplaceWith>)",
        field4: "String",
        field5: "Should have 3 inputs.|Inputs can be static text or a field from insight/recommendation.",
        field6: "REPLACE(RecommendationName1.Field1,StaticText.abc,StaticText.def)|REPLACE(RecommendationName1.Field1,RecommendationName1.Field2,RecommendationName1.Field3)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "9",
      cells: {
        field1: "SUBSTRING",
        field2: "Picks substring from string from given offset",
        field3: "SUBSTRING(<CompleteString>,<StartPosition>,<TotalLength>)",
        field4: "String",
        field5: "Should have 3 inputs.|Second and Third inputs should be integer.|If second integer is negative,it is considered as 0.|If third input > length of string, then  substring from StartPosition till end of string.",
        field6: "SUBSTRING(RecommendationName1.Field1,StaticNumber.0,StaticNumber.10)"
      }
    }
  )

  transformedToRowItems.push(
    {
      id: "10",
      cells: {
        field1: "JOIN",
        field2: "Concatenates strings from all predictions with comma as separator to form a single string",
        field3: "JOIN(<input1>, <input2>...)",
        field4: "String, Integer, Decimal, Boolean, Date",
        field5: "Should have atleast 1 input.|Input can be a field from insight/recommendation.",
        field6: "JOIN(RecommendationName1.Field1)|JOIN(RecommendationName1.Field1, RecommendationName2.Field2)"
      }
    }
  )

  return transformedToRowItems
}