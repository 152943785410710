import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../root-redux/RootState";
import { ConnectionsCollection, IConnectionsResponse } from "../model/connections/ConnectionsCollection.model";
import { postRequestReleaseManagement } from "./utils/PostRequestReleaseManagement";

export const propagateConnectionReleaseManagement = createAsyncThunk<IConnectionsResponse, 
{ payload: ConnectionsCollection, subscriptionKey: string, env: string }, { state: RootState }>
('propagate/connections', async ( connectionsInput, { getState }) => {

    const response = await postRequestReleaseManagement<IConnectionsResponse, ConnectionsCollection>(`${connectionsInput.env}/connections/${connectionsInput.subscriptionKey}/propagate`, connectionsInput.payload)
    return response.data;
    
  })