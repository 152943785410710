import { AdlsConnection } from "./AdlsConnection.model"
import { ApiConnection } from "./ApiConnection.model"
import { BlobConnection } from "./BlobConnection.model"
import { CosmosConnection } from "./CosmosConnection.model"
import { EventConnection } from "./EventConnection.model"
import { SqlAadConnection } from "./SqlAadConnection.model"
import { SqlConnection } from "./SqlConnection.model"
import { TopicConnection } from "./TopicConnection.model"

export interface IConnectionsResponse {
    connections: ConnectionsCollection
}

export class ConnectionsCollection {
    public blobConnections!: BlobConnection[]
    public adlsConnections!: AdlsConnection[]
    public adlsGen1Connections!: AdlsConnection[]
    public cosmosConnections!: CosmosConnection[]
    public apiConnections!: ApiConnection[]
    public sqlaadConnections!: SqlAadConnection[]
    public sqlConnections!: SqlConnection[]
    public eventConnections!: EventConnection[]
    public topicConnections!: TopicConnection[]
}

export class ConnectionsRequestPayload extends ConnectionsCollection {
    partitionKey: string
    subscriptionKey: string
    
    constructor(key: string) {
        super()
        this.partitionKey = key
        this.subscriptionKey = key
    }
}