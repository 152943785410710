// config.js
const config = {
    redirect_url: process.env.REACT_APP_REDIRECT_URL || "http://localhost:3000/",
    authority: process.env.REACT_APP_AUTHORITY || "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
    client_id: process.env.REACT_APP_CLIENT_ID || "da13cd7c-7713-493f-8d78-458b7d2f8eb5",
    scope: process.env.REACT_APP_SCOPE || "api://da13cd7c-7713-493f-8d78-458b7d2f8eb5/.default",
    oauth2TokenUrl: process.env.REACT_APP_OAUTH2_TOKEN_URL || "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/oauth2/v2.0/token",
    target: process.env.REACT_APP_TARGET || "https://management.core.windows.net//user_impersonation"
};

export default config;
