import axios from 'axios';
import { BASE_URL } from './Constants';
import { v4 as uuidv4 } from 'uuid';
import { AuthClientProvider } from '../../auth/AuthClientProvider';
import { Logger } from '../../utils/Logger';

export const postRequest = async<T, D>(apiPath: string, payload: D, userObjID?: string, nobase?: boolean) => {
  const correlationId = uuidv4();
  Logger.Instance.trackTrace(`start postRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId,
    reqPayload:payload
  });
  try {
    const url = nobase ? apiPath : `${BASE_URL}/${apiPath}`;
    const response = await axios.post<T>(url, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
        "user-oid": userObjID
      },
    }
    );
    Logger.Instance.trackTrace(`completed PostRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      response:response
    });

    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PostRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });   
    throw error;
  }
};

export const postRequestFileUpload = async<T, D>(apiPath: string, payload: D, userObjID?: string, nobase?: boolean) => {
  const correlationId = uuidv4();
  Logger.Instance.trackTrace(`start postRequestFileUpload ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId,
    reqPayload:payload
  });
  try {
    const url = nobase ? apiPath : `${BASE_URL}/${apiPath}`;
    const response = await axios.post<T>(url, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(),
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
        "user-oid": userObjID
      },
    }
    );
    Logger.Instance.trackTrace(`completed PostRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      response:response
    });

    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PostRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });   
    throw error;
  }
};

export const patchRequest = async<T, D>(apiPath: string, payload: D) => {
  const correlationId = uuidv4();
  Logger.Instance.trackTrace(`start patchRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId,
    reqPayload:payload
  });
  try {
    const response = await axios.patch<T>(`${BASE_URL}/${apiPath}`, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId
      },
    });
    Logger.Instance.trackTrace(`completed PatchRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      response:response
    });

    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PatchRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
};

export const postRequestServiceAPI = async<T, D>(baseurlPerEnv: string, apiPath: string, payload: D, subKey: string) => {
  const correlationId = uuidv4();
  try {
    Logger.Instance.trackTrace(`start PostRequestService ${apiPath}`, {
      baseUrl: baseurlPerEnv,
      correlationId: correlationId,
      reqPayload:payload,
      subKey:subKey
    });
    const response = await axios.post<T>(`${baseurlPerEnv}/${apiPath}`, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "CorrelationId": correlationId,
        "nebula-subscriptionkey": subKey

      },
    }

    );
    Logger.Instance.trackTrace(`completed PostRequestService ${apiPath}`, {
      baseUrl: baseurlPerEnv,
      correlationId: correlationId,
      response:response,
      subKey:subKey
    });
    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PostRequestService ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
};

export const putRequestServiceAPI = async<T, D>(apiPath: string, payload: D, subKey: string) => {
  const correlationId = uuidv4();
  try {
    Logger.Instance.trackTrace(`start PatchRequestService ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      reqPayload:payload,
      subKey:subKey
    });
    const response = await axios.put<T>(`${BASE_URL}/${apiPath}`, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "CorrelationId": correlationId,
        "nebula-subscriptionkey": subKey

      },
    }

    );
    Logger.Instance.trackTrace(`completed PatchRequestService ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      response:response,
      subKey:subKey
    });
    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PatchRequestService ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
}