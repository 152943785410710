import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { setShowAuditHistoryPanel, setShowConfigurationsDiffPanel } from "../../release-management/ReleaseData.redux";
import { Spinner } from "../../../shared-components/loader/Spinner";
import { TextDiff } from "../../text-diff/TextDiff";
import { DataGrid, Dialog } from "../SpaAdminScope";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { set } from "immer/dist/internal";
import { FlyInPanel } from "../../../common/HarmonyEnablers";


export const CampaignAuditHistory: React.FC<any> = React.memo((props:any) => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedConfigurationVersions = useSelector((state: RootState) => state.releaseData.selectedConfigurationVersions);
    const selectedConfigurations = useSelector((state: RootState) => state.releaseData.selectedConfigurations);
    const showDiff = useSelector((state: RootState) => state.releaseData.showConfigurationsDiffPanel);
    const loading = useSelector((state: RootState) => state.releaseData.selectedConfigurationsStatus === RequestStatus.loading);
    const [selectedCampaignVersions, setSelectedCampaignVersions] = useState<Campaign[]>([]);
    const [displayCampaignVersions,setDisplayCampaignVersions]:any = useState<any>([]);
    const displayCampaignSummaryProps:string[] = ["RecommendationTitle","RecommendationText"];
    const showAuditHistoryPanel: boolean = useSelector((state: RootState) => state.releaseData.showAuditHistoryPanel);


    const columns = [
        { field: 'Version', content: 'Version' },
        { field: 'Name', content: 'Name' },
        { field: 'RecommendationTitle', content: 'Recommendation Title' },
        { field: 'RecommendationText', content: 'Recommendation Text' },
        // { field: 'Added', content: '#Acc Added' },
        // { field: 'Removed', content: '#Acc Removed' },
        { field: 'TotalUploaded', content: 'Total Uploaded' },
        { field: 'Removed', content: 'Removed' },
        { field: 'ExpiryDate', content: 'Expiry Date' },
        { field: 'ActionByDate', content: 'Action By Date' }
    ];

    
    useEffect(() => {
        if (selectedConfigurations && Array.isArray(selectedConfigurations) && selectedConfigurations.length === 2) {
            setSelectedCampaignVersions(selectedConfigurations);
        }
    }, [selectedConfigurations])

    useEffect(() => {   
        if(selectedCampaignVersions.length === 2){
            var displayCampaignVersionsTemp:any = [];
            selectedCampaignVersions.map((campaign) => {
                var displayCampaignVersionRow:any = {};
                displayCampaignVersionRow["id"] = campaign.id;
                var displayCampaignVersion:any = {};
                campaign.summary.map((summary) => {
                    var index = displayCampaignSummaryProps.findIndex((prop) => prop === summary.key);
                    if(index > -1){
                        displayCampaignVersion[summary.key] = summary.value;
                    }
                });
                displayCampaignVersion["Version"] = campaign.version;
                displayCampaignVersion["Name"] = campaign.config.workflowName;
                displayCampaignVersion["TotalUploaded"] = campaign.inCart.length;
                displayCampaignVersion["Removed"] = campaign.outOfCart?.length??0;
                displayCampaignVersion["ExpiryDate"] = campaign.expiryDate;
                displayCampaignVersion["ActionByDate"] = campaign.actionByDate;
                displayCampaignVersionRow["cells"] = displayCampaignVersion;
                displayCampaignVersionsTemp.push(displayCampaignVersionRow);
            }); 
            setDisplayCampaignVersions(displayCampaignVersionsTemp);
        }
    },[selectedCampaignVersions])

    const handleRequestClose = (event: any) => {
        setDisplayCampaignVersions([]);
        setSelectedCampaignVersions([]);
        //dispatch(setShowConfigurationsDiffPanel(false))
        dispatch(setShowAuditHistoryPanel(false));
    }

    return (
        <FlyInPanel open={showAuditHistoryPanel} onHeRequestClose={handleRequestClose}
        style={{ '--size': 'var(--he-panel-size-large)' }}>
        <div>
        {
            loading && <div className='release-diff-loader'><Spinner type='medium' /></div>
        }
        { 
            !loading &&  <DataGrid 
                columns={columns}
                rows={displayCampaignVersions}
                /> 
        }
    </div>
    </FlyInPanel>
    )

})
