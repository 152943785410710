import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { RequestMessageBar } from "../../../shared-components/message-bar/RequestMessageBar";
import { resetPostScheduleWorkflowStatus } from "../workflow-by-id-redux/WorkflowById.actions.redux";

export const ScheduleWorkflowMessageBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const requestStatus = useSelector((state: RootState) => state.workflowById.postScheduleWorkflowStatus);
    const requestError = useSelector((state: RootState) => state.workflowById.postScheduleWorkflowError);
    
    return <RequestMessageBar errorMessage={requestError} status={requestStatus} callback={() => dispatch(resetPostScheduleWorkflowStatus())}/>
}