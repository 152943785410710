import { Dialog } from "../../../../common/HarmonyEnablers";
import { Button, TextField } from '../../SpaAdminScope';
import { useState, useEffect } from 'react';

export const SaveQueryPopUp: React.FC<any> = (props:any) => {

  const [queryName, setQueryName] = useState<string>(props.editqueryName ? props.editqueryName : '');
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  useEffect(() => {
    setIsDuplicate(props.filterQueryJSON.some((item: { name: string; }) => item.name === queryName));
  }, [queryName]);

  function handleRequestClose(event:any) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }
  }

  return (
    <>
      <Dialog
        heading="Save Query"
        open
        onHeRequestClose={handleRequestClose}
        onHeAfterHide={({ target, currentTarget }) =>
          target === currentTarget ? props.closePopup(false) : null
        }
      >
        <TextField label="Enter Query Name" required value = {queryName} autofocus onHeChange={(e:any) => {setQueryName(e.target.value)}}/>
        {
          isDuplicate ? 
          <i>*Query Name already exists</i>
          :
          <></>
        }
        <Button
          slot="footer"
          appearance="primary"
          onClick={() => {
            props.handleSaveQuery(queryName);
            props.closePopup(false);
          }}
          disabled={(queryName === '' || isDuplicate ? true : false)}
        >
          Save as New Query
        </Button>
        {
          props.isEditQuery ? 
            <Button
            slot="footer"
            appearance="primary"
            onClick={() => {
              props.handleUpdateQuery(props.queryId, queryName);
              props.closePopup(false);
            }}
          >
            Update Query
          </Button> 
            : 
          <></>
        }
        <Button slot="footer" onClick={() => props.closePopup(false)}>
          Cancel
        </Button>
      </Dialog>
    </>
  );
};
