import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../root-redux/RootState';
import { TenantAdminNavMenuItemType } from '../../feature-components/tenant-admin/TenantAdminNavMenuItem.model';
import { setNavState } from '../nav-panel/nav-panel-redux/NavPanel.actions.redux';
import ReleaseConfiguration from '../../feature-components/release-management/Configuration/ReleaseConfiguration';
import { CampaignRefineData } from '../../feature-components/spa-admin/edit-campaign/campaign-refinedata';
import { CampaignIngestFinalView } from '../../feature-components/spa-admin/edit-campaign/CampaignIngestFinalView';
import { CampaignSummary } from '../../feature-components/spa-admin/edit-campaign/CampaignSummary';

import Home from '../../feature-components/home/Home';
import { Redirect } from '../../feature-components/redirect';
import { DatasetFileUpload } from '../../feature-components/ingestion/DatasetFileUpload';
import { FileUpload } from '../../feature-components/spa-admin/dataset/FileUpload';
import DatasetReleaseConfiguration from '../../feature-components/release-management/Configuration/DatasetReleaseConfiguration';
const Workflows = React.lazy(() => import('../../feature-components/workflows/Workflows'));

const EditWorkflow = React.lazy(() => import('../../feature-components/edit-workflow/EditWorkflow'));
const MergeRules =  React.lazy(() => import('../../feature-components/merge-rules/MergeRules'));
const APIConfiguration = React.lazy(()=> import('../../feature-components/api-configuration/APIConfiguration'));
const TenantAdmin = React.lazy(()=> import('../../feature-components/tenant-admin/TenantAdmin'));
const Connections = React.lazy(()=> import('../../feature-components/connections/Connections'));
const APIConfigurationConsume = React.lazy(()=> import('../../feature-components/api-configuration/consume/APIConfigurationConsume'));
const ReleaseManagement = React.lazy(()=> import('../../feature-components/release-management/Release'));
const EditMergeRules = React.lazy(()=> import('../../feature-components/edit-merge-rules/EditMergeRules'));

const RootRouterC: React.FC = () => { 
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  useEffect(() => {
    const pathName = location.pathname.split('/')[1] || NavMenuItemType[NavMenuItemType.Home];
    dispatch(setNavState(NavMenuItemType[pathName as keyof typeof NavMenuItemType]));
  }, [location]);
   
  return (    
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path={"/redirect/:entityEnv/:entity/:entityId"} element={<Redirect />} />
        <Route path={"/redirect/:entity/:entityId"} element={<Redirect />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Home]} element={<Home />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Users]} element={<Home />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Reports]} element={<Home />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.WorkFlows]} element={<Workflows />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.WorkFlows]+"/release"} element={<ReleaseManagement />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.MergeRules]} element={<MergeRules />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Configuration]} element={<MergeRules />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.MergeRules] + "/edit"} element={<EditMergeRules />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.APIConfigurations]} element={<APIConfiguration />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.APIConfigurations] + "/consume"} element={<APIConfigurationConsume />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.WorkFlows]+"/edit"} element={<EditWorkflow />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Campaign] + "/summary"} element={<CampaignSummary />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Campaign] + "/refine"} element={<CampaignRefineData />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Campaign] + "/publish"} element={<CampaignIngestFinalView />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Campaign] + "/release"} element={<ReleaseConfiguration />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Dataset] +"/upload"} element={<FileUpload />} ></Route>
        <Route path={"/"+NavMenuItemType[NavMenuItemType.Dataset] + "/release"} element={<DatasetReleaseConfiguration />} />
        <Route path={"/"+NavMenuItemType[NavMenuItemType.TenantAdmin]} element={<TenantAdmin />} >
        <Route path={TenantAdminNavMenuItemType[TenantAdminNavMenuItemType.Connections]} element={<Connections />} />
        </ Route>
    </Routes>   
  );
}

export const RootRouter = React.memo(RootRouterC);


