import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { RootState } from "../../root-redux/RootState"
import { WorkflowSummary } from "../../model/workflows/Workflow.model"

export const getDatasetsList = createAsyncThunk<any, void, { state: RootState }>('datasets/getDatasetsList',async (_, {getState, rejectWithValue})  => {
    const selectedEnvItem  = getState().env.selectedEnvItem;
      const response = await getRequest<any>(`${selectedEnvItem}/workflows`);
      const datasets = response.data.filter((record: { workFlowType: string; status: string}) => record.workFlowType === "Dataset" && record.status !== "Deleted");
      return datasets as WorkflowSummary[];
  });