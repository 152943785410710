import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../../root-redux/RootState"
import { CampaignFilterRequest, QueryTypes } from "../../model/SPAModels/campaigns/CampaignFilter.Model";
import { postRequest } from "../utils/PostRequest";
import { Prediction } from "../../model/preview-mergerule/Prediction.model"; 
import { CampaignByIdState } from "../../feature-components/spa-admin/redux/CampaignById.redux";
import { WorkflowsBySubKeyState } from "../../feature-components/workflows/workflow-by-subKey-redux/WorkflowBySubKey.redux";
import { UserRolesState } from "../../feature-components/spa-admin/redux/UserSalesRoles.redux";
import { CampaignDataCartState } from "../../feature-components/spa-admin/redux/CampaignDataCart.redux";
import { ConvertRecordsToCSV } from "../utils/ExcelHandler";
import { FilterFields } from "../../model/SPAModels/campaigns/campaignConfig.Model";
/**
 * campaignById and workflowBySubKeys states should be loaded before these calls
 */

const getQueryString = (query: {[key:string]: any}, fields: any, sourceIds: Array<string>, sourceIdNames: {[key:string]: string}) => {

    const getQueryKey = (param: string) => Object.keys(fields).find(f => f.toLowerCase() === param.toLowerCase()) || param;

    const getSearchQuery = (param: string) => {
        const lower = param.toLowerCase(), upper = param.toUpperCase();
        let newStr = "";
        for(let i = 0; i < lower.length; i++) {
            newStr += '[' + lower.at(i) + upper.at(i) + ']';
        }
        return newStr;
    }

    let queryString = "";
    let filteredSources = [];
    for(const k in query) {
        const ls: string[] = [];
        for(const v in query[k]) {
            if (query[k][v] === true && k.toUpperCase() === "SOURCE") {
                filteredSources.push(sourceIdNames[v]);
            } else if (query[k][v] === true && !["STATUS", "TPID"].includes(k.toUpperCase())) {
                ls.push(`${getQueryKey(k)} = '${v}'`);
            } else if (k === 'TPID') {
                ls.push(`(${getQueryKey("TPID")} like '%${v}%' OR ${getQueryKey("AccountName")} like '%${getSearchQuery(v)}%')`)
            }
        }
        if (ls.length > 1)
            queryString += ' AND (' + ls.join(' OR ') + ')';
        else if (ls.length > 0)
            queryString += ' AND ' + ls.join('');
    }

    const IdsList = filteredSources.length > 0 ? filteredSources : sourceIds;
    const sourceIdQuery = '(' + IdsList.map(s => `upper(SourceId) = '${s.toUpperCase()}'`).join(' OR ') + ')';

    if (queryString.length > 0) {
        queryString = '(' + sourceIdQuery + queryString + ')';
    } else {
        queryString = sourceIdQuery;
    }
    return queryString;
}

export const saveDatacartRecordsToFile = createAsyncThunk<any, any, { state: RootState}>('campaignById/downloadRecords', async (_, {getState}) => {
    try {
        const [inCart, viewResult] = getState().campaignDataCart.allpredictions;
        let reviewRefIds: string[] = [];
        const reviewerList = getState().campaignById.campaign?.reviewList || [];
        reviewerList.forEach(rList => reviewRefIds = reviewRefIds.concat(rList.list));

        const sourceKeys: any = {}
        getState().workflowsBySubKeys.list.forEach(w => sourceKeys[w.config.workflowId.toLowerCase()] = w.config.workflowName);

        let columns = getState().campaignConfig?.campaignConfig?.accountsCartFields.map((fields: FilterFields) => ({
            displayName: fields.displayName,
            fieldName: fields.columnMap,
        })) || [];

        columns = [{displayName: 'Status', fieldName: 'Status'}, ...columns];

        let allRecords: Prediction[] = [];
        allRecords = allRecords.concat(inCart.map(record => {
            let rd: Prediction = {...record};
            rd.attributes = [...rd.attributes, {key: 'Status', value: 'In Cart'}];
            return rd;
        }));
        allRecords = allRecords.concat(viewResult.map(record => {
            let rd: Prediction = {...record};
            const attr = record.attributes.find(attr => attr.key === 'ReferenceId');
            if (reviewRefIds.includes(attr?.value || '')) {
                rd.attributes = [...rd.attributes, {key: 'Status', value: 'Pending Review'}];
            } else {
                rd.attributes = [...rd.attributes, {key: 'Status', value: ''}];
            }
            return rd;
        }));

        if (allRecords.length !== 0) {
            ConvertRecordsToCSV(allRecords, columns, sourceKeys);
        }
    } catch (e) {
        console.log(e);
        return e;
    }
});

const getFilteredData = async<T> (
    query: {[key:string]: any},
    url: string,
    campaignById: CampaignByIdState,
    workflowsBySubKeys: WorkflowsBySubKeyState,
    userSalesRoles: UserRolesState,
    pageNum: number,
    inputModel: {modelId: string|undefined, sqlQuery: string|undefined},
    queryType: QueryTypes
): Promise<{ data: T|undefined, error: any|undefined }> => {
    
    const sourceIds: Array<string> = inputModel.modelId 
        ? [inputModel.modelId.toUpperCase()] 
        : (campaignById.campaign?.dataSets || []).map(modelId => modelId.toUpperCase());

    if (sourceIds.length === 0) {
        return Promise.resolve({data: [] as T, error: undefined});
    }

    const workflowSummaries = workflowsBySubKeys.list.filter(obj => sourceIds.includes((obj.modelId || "").toUpperCase()));
    let fields: any = {};
    let sourceIdNames: {[key:string]: string} = {};
    workflowSummaries.forEach(wsummary => {
        for(const k in wsummary.fields) {
            fields[k] = wsummary.fields[k];
        }
        sourceIdNames[wsummary.config.workflowName] = wsummary.config.workflowId;
    });

    if (userSalesRoles.isApprover) {
        const area = userSalesRoles.userDetails?.area!;
        query['Area'] = {[area] : true};
    }

    const queryString = inputModel.sqlQuery ? inputModel.sqlQuery : getQueryString(query, fields, sourceIds, sourceIdNames);

    const getReviewList = () => {
        let list: string[] = [];
        campaignById.campaign?.reviewList.forEach(rList => list = list.concat(rList.list));
        return list;
    }

    const request: CampaignFilterRequest = new CampaignFilterRequest();
    request.filters = campaignById.campaign?.filters!;
    request.inCart = campaignById.campaign?.inCart!;
    request.outOfCart = campaignById.campaign?.outOfCart!;
    request.query = queryString;
    request.queryType = queryType;
    request.pageNum = pageNum;
    request.datasetId = sourceIds[0];
    request.reviewList = getReviewList();
    request.fields = fields;
    const response = await postRequest<T, CampaignFilterRequest>(url, request);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return { data: undefined, error: data };
    }
    return { data: response.data, error: undefined };
}

const getQueryType = (userSalesRoles: UserRolesState, pageNum: number, campaignDataCart: CampaignDataCartState,) => {
    if (userSalesRoles.isApprover || pageNum === -1) { 
        return QueryTypes.VIEW_RESULTS; 
    }
    return campaignDataCart.pendingReview ? QueryTypes.REVIEW_CART : QueryTypes.IN_DATA_CART;
}

const removeDuplicateRefIds = (result: Array<Prediction>) => {
    const refIds: Set<string> = new Set<string>();
    return result?.filter(record => {
        const attr = record.attributes.find(attr => attr.key === 'ReferenceId');
        if (attr !== undefined && attr.value !== undefined && !refIds.has(attr?.value)) {
            refIds.add(attr?.value);
            return true;
        }
        return false;
    });
}

export const getPredictions = createAsyncThunk<any, {[key:string]: any}, { state: RootState }>('campaignDataCart/getPredictions', async (query: {[key:string]: any}, {getState, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    const pageNum = Math.round(getState().campaignDataCart.pageNum / 10) + 1;
    let inCartResult: Array<Prediction>|undefined = [];

    if (getState().userSalesRoles.isApprover) {
        const inCResult = await getFilteredData<Array<Prediction>>(query, `${env}/campaigns/filterData`,
            getState().campaignById, 
            getState().workflowsBySubKeys, 
            getState().userSalesRoles,
            pageNum,
            {modelId: undefined, sqlQuery: undefined},
            QueryTypes.IN_DATA_CART);

        if (inCResult.error) {
            return rejectWithValue(inCResult.error);
        }

        inCartResult = removeDuplicateRefIds(inCResult.data || []);
    }

    if (inCartResult?.length === 200) {
        return inCartResult;
    }

    const viewCResult = await getFilteredData<Array<Prediction>>(query, `${env}/campaigns/filterData`,
        getState().campaignById, 
        getState().workflowsBySubKeys, 
        getState().userSalesRoles,
        pageNum,
        {modelId: undefined, sqlQuery: undefined},
        getQueryType(getState().userSalesRoles, pageNum, getState().campaignDataCart));
    
    if (viewCResult.error) {
        return rejectWithValue(viewCResult.error);
    }

    let result: Array<Prediction>|undefined = removeDuplicateRefIds(viewCResult.data || []);

    const filtered = (result || []).filter(record => !inCartResult?.some(rs => rs.id === record.id));
    result = (inCartResult || []).concat(filtered);
    
    return result;
});

export const getViewResultsCount = createAsyncThunk<any, {[key:string]: any}, { state: RootState }>('campaignDataCart/getResultCount', async (query: {[key:string]: any}, {getState, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;

    const inCResult = await getFilteredData<Array<Prediction>>(query, `${env}/campaigns/filterData`,
        getState().campaignById, 
        getState().workflowsBySubKeys, 
        getState().userSalesRoles,
        -1,
        {modelId: undefined, sqlQuery: undefined},
        QueryTypes.IN_DATA_CART);

    if (inCResult.error) {
        return rejectWithValue(inCResult.error);
    }

    const inCResultFiltered = removeDuplicateRefIds(inCResult.data || []);
    const inCartCount = inCResultFiltered.length || 0;
    return {inCartCount: inCartCount, vCount: 0};
});

export const getAllRecords = createAsyncThunk<any, {modelId: string|undefined, sqlQuery: string|undefined}, { state: RootState }>('campaignById/getAllRecords', async (request, {getState, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    const pageNum = -1;
    const id = getState().campaignById.campaign?.config.workflowId;
    const countData = await getFilteredData<Array<string>>({}, `${env}/campaigns/filterData/${id}/all`,
        getState().campaignById, 
        getState().workflowsBySubKeys, 
        getState().userSalesRoles,
        pageNum,
        request,
        getQueryType(getState().userSalesRoles, pageNum, getState().campaignDataCart));

    if (countData.error) {
        return rejectWithValue(countData.error);
    }

    return countData.data;
});

export const getAllPredictions = createAsyncThunk<any, {[key:string]: any}, { state: RootState }>('filteredQueryModelData/getAllPredictions', async (query: {[key:string]: any}, {getState, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    let inCartResult: Array<Prediction> = [];

    const inCResult = await getFilteredData<Array<Prediction>>(query, `${env}/campaigns/filterData`,
        getState().campaignById, 
        getState().workflowsBySubKeys, 
        getState().userSalesRoles,
        -1,
        {modelId: undefined, sqlQuery: undefined},
        QueryTypes.IN_DATA_CART);

    if (inCResult.error) {
        return rejectWithValue(inCResult.error);
    }

    inCartResult = removeDuplicateRefIds(inCResult.data || []) || [];

    const viewCResult = await getFilteredData<Array<Prediction>>(query, `${env}/campaigns/filterData`,
        getState().campaignById, 
        getState().workflowsBySubKeys, 
        getState().userSalesRoles,
        -1,
        {modelId: undefined, sqlQuery: undefined},
        QueryTypes.VIEW_RESULTS);
    
    if (viewCResult.error) {
        return rejectWithValue(viewCResult.error);
    }

    let result: Array<Prediction> = removeDuplicateRefIds(viewCResult.data || []) || [];
    result = result.filter((p: Prediction) => !inCartResult.some((inp: Prediction) => inp.id === p.id));

    return [inCartResult, result];
});