import React, { useEffect, useState } from "react"
import { Accordion, AccordionItem, TextArea, Tooltip, RadioGroup, Radio, Checkbox, Icon, TextField, Button } from "../../common/HarmonyEnablers"
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux"
import { useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../root-redux/RootState"
import { updatePreviewMergeRuleUserInput } from "./preview-mergerule-redux/PreviewMergeRule.actions.redux"
import { useDispatch } from "react-redux"
import { Toggle } from "../connections/ConnectionScope"
import { postMergeRulePreview } from "../../services/PostMergeRulePreview.api"
import { formDataInsights, formDataPredictions } from "./PreviewFunctions"
import { Prediction } from "../../model/preview-mergerule/Prediction.model"
import { Insight } from "../../model/preview-mergerule/Insight.model"

export const PreviewMergeRuleUserInput: React.FC<any> = (props: any) => {
    const dispatchApp = useDispatch<AppDispatch>()
    const dispatch = useDispatch()
    const {source, subKey, mergeRuleName, workflowId, setPreviewInput, previewInput, mergeruleSetId, mergeRule} = props
    const access = CheckPageAccessSelector(subKey)
    const previewMergeRuleUserInput = useSelector((state: RootState) => state.previewMergeRule.previewMergeRuleUserInput)
    const previewMap = useSelector((state: RootState) => state.previewWorkflow.map)
    const workflow = useSelector((state: RootState) => state.workflowById.workflow)
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [data,setData] = useState(previewMergeRuleUserInput);
    const maxLimit = 10
    const minLimit = 1

    useEffect(() => {
        var isValueWithinLimit = Number(data.tpidLimit) >= minLimit && Number(data.tpidLimit) <= maxLimit
        var hasInputChanged = data && previewMergeRuleUserInput && JSON.stringify(data) !== JSON.stringify(previewMergeRuleUserInput)
        setButtonDisabled(!isValueWithinLimit || !hasInputChanged)
    },[data])

    useEffect(() => {
        setData(previewMergeRuleUserInput)
    },[previewMergeRuleUserInput])

    const handleChange = (e: any) => {
        var selectedValue = e.target.value
        setData({...data, inputFrom: selectedValue})
    }

    const handleChangeTpidSource = (e: any) => {
        var selectedValue = e.target.value
        setData({...data, tpidSource: selectedValue})
    }

    const formInput = () => {
        var input = {
            ...previewInput,
            tpidList: data.tpids,
            predictions: [] as Prediction[],
            insights: [] as Insight[]
        }
      
        if (workflowId && data.inputFrom !== "existing") {
            const previewData = previewMap[workflowId]
            var publishName = workflow.publish.filter((x: any) => { return x.type === "COSMOS" && x.publishTo.toLowerCase() === "nebula" })
            var workflowType = workflow.workFlowType
            if (publishName && publishName.length > 0) {
                var local = publishName[0].publishMap.find((x: any) => { return x.destination === "Type" })
                if (local) {
                    workflowType = local.source
                }
            }

            if (workflowType === "Prediction") {
                var predictions = formDataPredictions(workflow, previewData, data.tpids, Number(data.tpidLimit), data.tpidSource)
                input = { ...input, predictions: predictions }
            }
            if (workflowType === "Insight") {
                var insights = formDataInsights(workflow, previewData, data.tpids, Number(data.tpidLimit), data.tpidSource)
                input = { ...input, insights: insights }
            }
        }
        return input
    }

    const handleTPIDChange = (e: any) => {
        var value = e.target.value
        var valueArray = value && value.split(",") || [] 
        if(valueArray.length > 0) {
            valueArray = valueArray.filter((entry: string) => entry.trim() != '');
        }
        setData({...data, tpids: valueArray})
    }

    const handleRerun = () => {
        var input = formInput()
        setPreviewInput(input)
        dispatch(updatePreviewMergeRuleUserInput({...data}))
        if (data.mergeRuleSetId) {
            dispatchApp(postMergeRulePreview({ id: mergeRuleName, subKey: subKey, mergeruleSetId: data.mergeRuleSetId, limit: data.tpidLimit, type: data.inputFrom, input: input, onlyMergeruleSet: data.onlyOneMergeRuleSet }))
        } else {
            dispatchApp(postMergeRulePreview({ id: mergeRuleName, subKey: subKey, mergeruleSetId: data.mergeRuleSetId, limit: data.tpidLimit, type: data.inputFrom, input: input, onlyMergeruleSet: false }))
        }
    }

    const handleLimitChange = (e: any) => {
        var valueString = e.target.value
        var valueAsNumber = Number(valueString)
        setData({...data, tpidLimit: valueString})
    }

    return (
        <>
            <Button appearance="command"  onClick={handleRerun}>
                <Icon style={{ color: 'var(--he-color-primary-700)', paddingRight:"10px" }} name="refresh"></Icon> 
                ReRun Preview By Changing below inputs
            </Button>

            <Accordion><AccordionItem expanded>
                <span slot="heading">Preview Run For Below Input
                </span>
                <div className="mergeule-form-table">
                    <div>
                        <table className='mergerule-form-table mergerule-basics-table' >
                            {/* <tr>
                                <td><div>Perform Merge Only:
                                    <Icon id="info11111" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                                    <Tooltip anchor="info11111" placement='bottom' fixedPlacement={true}>
                                        Toggling this off applies other configurations also during preview, if applicable. Eg: Suppression, Deduplication etc...
                                    </Tooltip>
                                </div>
                                </td>
                                <td>
                                    <Toggle label="" name="isOnlyMerge" checked={data.isOnlyMerge} disabled={!access.canEdit} onHeChange={() => {
                                        setData({...data, isOnlyMerge: !data.isOnlyMerge})
                                         }} />
                                </td>
                            </tr> */}
                            <tr>
                                <td><div style={{"paddingTop": "10px"}}>Source of Data:</div></td>
                                <td>
                                    <RadioGroup value={data.inputFrom}>
                                        <div style={{ display: 'flex', width: '500px' }}>
                                            {workflowId && !workflow.workFlowType.includes("Insight") &&
                                                <Radio style={{ marginRight: '10px' }} value='sample' onHeSelected={handleChange} >Previous Preview Steps</Radio>
                                            }
                                            <Radio value='existing' style={{ marginRight: '10px' }} onHeSelected={handleChange} >Data Published To Cosmos</Radio>
                                            {workflowId &&
                                                <Radio value='both' onHeSelected={handleChange} >Both Previous Preview Steps and Cosmos</Radio>
                                            }
                                        </div>
                                    </RadioGroup>
                                </td>
                            </tr>
                            <tr>
                                <td><span>Number of Unique {data.distinctField || "TPID"}(s) to be considered:</span></td>
                                <td>
                                    <TextField label="tpidLimit" name='tpidLimit' type="number" min={minLimit.toString()} max={maxLimit.toString()} onHeInput={handleLimitChange} value={data.tpidLimit} className={'Text-fields'} />
                                </td>
                            </tr>
                            <tr>
                                <td><div style={{"paddingTop": "10px"}}>Source of {data.distinctField || "TPID"}(s):</div></td>
                                <td>
                                    <RadioGroup value={data.tpidSource}>
                                        <div style={{ display: 'flex', width: '500px' }}>
                                            {workflowId && <Radio  style={{ marginRight: '10px' }} value='workflow' onHeSelected={handleChangeTpidSource} >Pick from Preview</Radio>}
                                            <Radio value='user' onHeSelected={handleChangeTpidSource} >User Input </Radio>
                                        </div>
                                    </RadioGroup>
                                </td>
                            </tr>
                            {data.tpidSource === "workflow" &&
                                <tr>
                                    <td><span>{data.distinctField || "TPID"}(s) on which preview is run:</span></td>
                                    <td>
                                        <TextArea label="Tpids on which preview is run" disabled={true} resize="vertical" name='tpids' value={data.tpids && data.tpids.join("") || ''} className={'Text-fields'} />
                                    </td>
                                </tr>
                            }
                            {data.tpidSource === "user" &&
                                <tr>
                                    <td><div>{data.distinctField || "TPID"}(s):
                                        <Icon id="info11112" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                                        <Tooltip anchor="info11112" placement='right' fixedPlacement={true}>
                                            If user doesn't input {data.distinctField || "TPID"}(s), then it will be read from Cosmos.<br/>If you input more than 10 {data.distinctField || "TPID"}s, only first 10 are considered.
                                        </Tooltip>
                                    </div>
                                        <div>Input Comma-Separated values</div></td>
                                    <td>
                                        <TextArea resize="vertical" name='tpids' placeholder="Input comma separated values" value={data.tpids && data.tpids.join(",") || ''} onHeChange={handleTPIDChange} className={'Text-fields'} />
                                    </td>
                                </tr>
                            }
                            {mergeruleSetId && !source &&
                                <tr>
                                    <td>Apply only selected Merge Ruleset</td>
                                    <td>
                                        <Toggle label="" name="onlyOneMergeRuleSet" checked={data.onlyOneMergeRuleSet} onHeChange={() => {setData({...data, onlyOneMergeRuleSet: !data.onlyOneMergeRuleSet})}} />
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>
                </div>
            </AccordionItem></Accordion>
        </>
    )
}