import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { Breadcrumb, BreadcrumbItem, Button, Icon } from "../workflows/WorkflowsScope";
import { InformationText } from "../../shared-components/info-text/InformationText";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { useNavigate } from "react-router-dom";
import informationConfig from "../../shared-content/inputDescription";
import "./ReleaseManagement.css"

const ReleasesHeaderC = (props: any) => {

  const  workflowName = props.workflowName

  var navMenuItems = props.navMenuItems ?props.navMenuItems :{
    Home:{"title":"Home","nav":NavMenuItemType.Home},
    Configuration:{"title":"Insights & Recommendations","nav":NavMenuItemType.WorkFlows},
    Cancel:{"title":"Cancel","nav":NavMenuItemType.WorkFlows}
  }

  const navigate = useNavigate();
    const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
        navigate("/"+ NavMenuItemType[navMenuItemType]);
      };
  const onCancelIconClick = (navMenuItemType: NavMenuItemType) => {
    navigate("/"+ NavMenuItemType[navMenuItemType]);
};

  return (
    <>
     <div>
        <Breadcrumb  className="releaseBreadcrumb"tabIndex={0}>
        <span tabIndex={0}
            onKeyDown={e => e.key === "Enter" && _onNavMenuItemClick(navMenuItems.Home.nav)}>
          <BreadcrumbItem
            style={{ color: "#0078D4", fontWeight: "400" }}
            onClick={() =>  _onNavMenuItemClick(navMenuItems.Home.nav)}
          >
           {navMenuItems.Home.title}
          </BreadcrumbItem>
          </span>
          <span tabIndex={0}
            onKeyDown={e => e.key === "Enter" && _onNavMenuItemClick(navMenuItems.Configuration.nav)}>
          <BreadcrumbItem
            style={{ color: "#0078D4", fontWeight: "400" }}
            onClick={() => _onNavMenuItemClick(navMenuItems.Configuration.nav)}
          >
            {navMenuItems.Configuration.title}
          </BreadcrumbItem>
          </span>
          <BreadcrumbItem style={{ color: "#0078D4", fontWeight: "400" }}>
            Release Management
          </BreadcrumbItem>
          <BreadcrumbItem style={{ color: "#0078D4", fontWeight: "400" }}>
            {workflowName}
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <div>
      <h2 style={{ textAlign: "center", margin: "12px 0 0" }}>
        Deploy | {workflowName}
      </h2>
      <InformationText text = {informationConfig.onboardingRelease} type = "Heading"/>
            <div style = {{right:"28px", top:"28px", zIndex:"7", position:"absolute"}} aria-label = "releaseManagementClose">
                <Button appearance="link" 
                onClick={() => {
                  onCancelIconClick(navMenuItems.Cancel.nav);
                }}
                aria-label="releaseManagementCancel"
                name = "releaseManagementCancel"
                
                >
                    <Icon name="calculatormultiply" style = {{width:"24px", height:"24px"}} aria-label="releaseManagementCancelIcon"/>
                </Button>
            </div>
      </div>

    </>
  );
};
export const ReleasesHeader = withErrorBoundary(
  "releases header",
  ReleasesHeaderC
);
