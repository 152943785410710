import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { APIResponseMappingObject } from '../../../model/apiConfig/APIResponseMappingObject.model'
import { getAllApiConfig, getApiConfig, getApiConfigInsight, getApiConfigRecom } from '../../../services/APIConfig/GetApiConig.api'
import { addNewRoute, updateAPIConfig, updateRecommendationAPIConfig } from '../../../services/APIConfig/PostApiConfig.api'
import { deleteApiConfig } from '../../../services/APIConfig/DeleteApiConfig.api'
import { propagateRoute } from '../../../services/APIConfig/PropagateApiConfig.api'


interface APIConfigState {
    apiConfig: APIResponseMappingObject
    status: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean,
    apiComplete: boolean,
    apiConfigRecommendation: APIResponseMappingObject,
    apiConfigInsight: APIResponseMappingObject,
    statusRecommendation: RequestStatus,
    statusInsight: RequestStatus
    
}



const initialState: APIConfigState = {
    apiConfig: {
        routeAPIs: [],
        edmEntityTypeSettings: []
    },
    status: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false,
    apiComplete: false,
    apiConfigRecommendation: {
        routeAPIs: [],
        edmEntityTypeSettings: []
    },
    apiConfigInsight: {
        routeAPIs: [],
        edmEntityTypeSettings: []
    },
    statusInsight: RequestStatus.idle,
    statusRecommendation: RequestStatus.idle
    
}

export const apiConfigSlice = createSlice({
    name: 'apiConfig',
    initialState,
    reducers: {
        resetApiConfig: (state) => 
        {
            state.apiConfig = {
                routeAPIs: [],
                edmEntityTypeSettings: []
            }
            state.apiConfigRecommendation = {
                routeAPIs: [],
                edmEntityTypeSettings: []
            }
            state.apiConfigInsight = {
                routeAPIs: [],
                edmEntityTypeSettings: []
            }
            state.statusInsight = RequestStatus.idle
            state.statusRecommendation = RequestStatus.idle
            state.status = RequestStatus.idle
            state.apiComplete = false
            
        },
        updateApiComplete: (state, action:PayloadAction<boolean>) => {
            state.apiComplete = action.payload
         }
    },
    extraReducers(builder) {
        builder
            .addCase(getApiConfig.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(getApiConfig.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {

                    state.apiConfig = payload
                }
                state.error = undefined
            })
            .addCase(getApiConfig.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(getAllApiConfig.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(getAllApiConfig.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {

                    state.apiConfig = payload
                }
                state.error = undefined
            })
            .addCase(getAllApiConfig.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(addNewRoute.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(addNewRoute.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload
                }
                state.error = undefined
            })
            .addCase(addNewRoute.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(updateAPIConfig.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(updateAPIConfig.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload
                }
                state.error = undefined
            })
            .addCase(updateAPIConfig.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(deleteApiConfig.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(deleteApiConfig.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload
                }
                state.error = undefined
            })
            .addCase(deleteApiConfig.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(updateRecommendationAPIConfig.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(updateRecommendationAPIConfig.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload
                }
                state.error = undefined
            })
            .addCase(updateRecommendationAPIConfig.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(getApiConfigRecom.pending, (state, action) => {
                state.statusRecommendation = RequestStatus.loading
            })
            .addCase(getApiConfigRecom.fulfilled, (state, action) => {
                state.statusRecommendation = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {

                    state.apiConfigRecommendation = payload
                }
                state.error = undefined
            })
            .addCase(getApiConfigRecom.rejected, (state, action) => {
                state.statusRecommendation = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(getApiConfigInsight.pending, (state, action) => {
                state.statusInsight = RequestStatus.loading
            })
            .addCase(getApiConfigInsight.fulfilled, (state, action) => {
                state.statusInsight = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {

                    state.apiConfigInsight = payload
                }
                state.error = undefined
            })
            .addCase(getApiConfigInsight.rejected, (state, action) => {
                state.statusInsight = RequestStatus.failed
                state.error = action.error.message
            })
    }
})

export const apiConfigReducer = apiConfigSlice.reducer