import { useDispatch, useSelector } from "react-redux";
import { Button } from "../WorkflowsScope";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { Dialog, Select, TextField, Option, Icon, Tooltip } from "../../../common/HarmonyEnablers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { emptyMergeRule } from "../../../model/merge-rules/MergeRule.model";
import { getMergeRuleById } from "../../../services/get-merge-rules/GetMergeRules.api";
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { postMergeRule } from "../../../services/get-merge-rules/PostMergeRule.api";
import { addMergeRule } from "../merge-rules-redux/MergeRules.redux";
import { addMergeRuleBySubKey } from "../merge-rules-by-id-redux/MergeRuleBySubKey.redux";
import { AppPersona } from "../../../model/app-persona/AppPersona.model";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Loader } from "../../../shared-components/loader/Loader";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { addMergeRulesByModel, updateMergeRuleByModel } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux";
import { getWorkflowsBySubKeys } from "../../../services/GetWorkflows.api";
import { getTypeByKeyMapping } from "../../../services/GetTypeMapping.api";

export const CreateNewMergeRulePopup: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch();

  const mergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.status)
  const postMergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.postMergeRuleStatus)
  const typeMappingByKey = useSelector((state: RootState) => state.typeMappingConfigByKey.typeMappingByKey);
  const userTeams = useSelector(permissionsSelector).userTeams;
  const [enableSave, setEnableSave] = useState(false);
  const workflowSubsKey = useSelector((state: RootState) => state.workflowsBySubKeys.list)
  const {source, setOpenCreateNewMergeRule, setsuccessNotif, setSuccessText, setSelectedMergeRuleId, mergeRuleName, seterrorText, seterrornotif } = props
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
  const [newMergeRuleName, setNewMergeRuleName] = useState("")
  const [errorMsg, setErrorMsg] = useState("");
  const isSuperAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin)
  const [subKey, setSubKey] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [distinctField, setDistinctField] = useState("");
  const [listOfFamilies, setListOfFamilies] = useState([] as string[])
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);


useEffect(() => {
  if (typeMappingByKey) {
      var distinctField = Object.values(typeMappingByKey).map(x => { return x })
      if (distinctField && distinctField.length > 0) {
          setDistinctField(distinctField.join(""))
      }
  }
}, [typeMappingByKey])

  useEffect(() => {
    if(subKey || workflowSubsKey) {
      var localFamilies = workflowsBySubKeys.filter(x => { return x.family && x.workFlowType === "Recommendation" }).map(x => x.family)
      localFamilies = localFamilies.filter((value, index, array) => array.indexOf(value) === index);
      localFamilies = sortArrayOfString(localFamilies)
      setListOfFamilies(localFamilies)
    }
  },[subKey, workflowSubsKey])

  useEffect(() => {
    if (newMergeRuleName && subKey && familyName) {
      setEnableSave(true)
    } else { setEnableSave(false) }
  }, [newMergeRuleName,subKey ,familyName])

  const handleCloneMergeRule = async () => {
    var tenantName = allAppsList.find((item) => item.subscriptionKey === subKey)?.appName || ""

    var newMergeRule = {
      ...emptyMergeRule,
      subscriptionkey: subKey,
      type: familyName,
      modifiedBy: userDetails?.localAccountId,
      tenantName: tenantName,
      distinctField: distinctField,
      version: "1",
      mergeRuleName: newMergeRuleName,
      mergeRuleId: newMergeRuleName
    }

    var output = await dispatchApp(postMergeRule({ mergeRule: newMergeRule }));
    if (output.payload === true) {
      setOpenCreateNewMergeRule(false);
      setsuccessNotif(true);
      setSuccessText(`${newMergeRuleName} created successfully.`)
      
      if (source === "default") {
        dispatch(addMergeRuleBySubKey(newMergeRule))
        dispatch(addMergeRulesByModel(newMergeRule))
        dispatch(updateMergeRuleByModel(newMergeRule))
      } else {
        dispatch(addMergeRule(newMergeRule))
      }
      if (source !== "default") {
        navigate("/" + NavMenuItemType[NavMenuItemType.MergeRules] + "/edit", { state: { mergeRuleId: newMergeRule.mergeRuleId } })
      } else {
        props.setMergeRuleIdToEdit(newMergeRuleName)
        props.setOpenEditMergeRulePage(true)
      }
    } else {
      setOpenCreateNewMergeRule(true);
      var message = output.payload.Message.includes("MergeRule already exists") ? output.payload.Message + ", please provide a different name." : ""
      setErrorMsg(message)
      seterrorText(`${newMergeRuleName}- ${output.payload.Message}${message}`)
      seterrornotif(true);
    }
    
  }

  const handleRequestClose = (event: any) => {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      setOpenCreateNewMergeRule(false);
    }
  };

  const handleTenantChange = (e: any) => {
    var subkeyLocal = ""
    if (!isSuperAdmin) {
      subkeyLocal = userTeams.key[userTeams.name.indexOf(e.target.textContent)]
    } else {
      subkeyLocal = allAppsList.find(item => item.appName === e.target.textContent)?.subscriptionKey || ""
    }
    setFamilyName("")
    setDistinctField("")
    setSubKey(subkeyLocal)
    dispatchApp(getWorkflowsBySubKeys({subKeys: subkeyLocal}))
}

const handleFamilyChange = (e: any) => {
  dispatchApp(getTypeByKeyMapping(e.target.textContent))
  setFamilyName(e.target.textContent)
}

  return (
    <>
      <Dialog heading="Create New MergeRule" open onHeRequestClose={handleRequestClose}
        onHeAfterHide={
          ({ target, currentTarget }) => (target === currentTarget ? setOpenCreateNewMergeRule(false) : null)
        }
      >
        {mergeruleStatus === RequestStatus.loading &&
          <Loader shape={"rect"} label={"Loading..."} />
        }
        {postMergeruleStatus === RequestStatus.loading &&
          <Loader shape={"rect"} label={"Create New MergeRule..."} />
        }
        {mergeruleStatus !== RequestStatus.loading && postMergeruleStatus !== RequestStatus.loading &&
          <>
            <p style={{ fontSize: "14px" }}> Create New MergeRule</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <div>MergeRuleName<Icon className='required-icon' name='actioncenterasterisk'></Icon></div>
              <TextField className={'Text-fields'} label="" value={newMergeRuleName} validationMessage={errorMsg} autofocus onHeInput={(e: any) => { setErrorMsg(""); setNewMergeRuleName(e.target.value) }}></TextField>
            </div>
            <div>
                    Tenant<Icon className='required-icon' name='actioncenterasterisk'></Icon>
                        <Icon id="info401" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info401" fixedPlacement={true} placement='bottom'>
                            Choose the team under which you want to create the Merge Rule. This choice is very important as only those Insights/Recommendations will be available to merge that belong to the chosen team.
                        </Tooltip>
                   
                        {isSuperAdmin &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                value={allAppsList.find(item => item.subscriptionKey === subKey)?.appName || ""}
                                slot="action"
                                fixed-placement
                                label="Select Tenant"
                                placeholder={allAppsList.find(item => item.subscriptionKey === subKey)?.appName  || ""}
                                className={'Text-fields'}
                                name="tenant"
                                required
                            >
                                {
                                    allAppsList.map(item => (
                                        <Option
                                            key={item.subscriptionKey}
                                            role="menuitemcheckbox"
                                            onHeSelected={handleTenantChange}
                                        >
                                            {item.appName}
                                        </Option>
                                    ))
                                }
                            </Select>
                        }
                        {!isSuperAdmin &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                value={userTeams.name[userTeams.key.indexOf(subKey)]  || ""}
                                slot="action"
                                fixed-placement
                                label="Select Tenant"
                                placeholder={userTeams.name[userTeams.key.indexOf(subKey)] || ""}
                                className={'Text-fields'}
                                name="tenant"
                                required
                            >
                                {
                                    Object.keys(userTeams.name)?.map(item => (
                                        <Option
                                            key={userTeams.name[Number(item)]}
                                            role="menuitemcheckbox"
                                            onHeSelected={handleTenantChange}
                                        >
                                            {userTeams.name[Number(item)]}
                                        </Option>
                                    ))
                                }
                            </Select>
                        }
                
                    Family<Icon className='required-icon' name='actioncenterasterisk'></Icon>
                        <Icon id="info4011" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info4011" fixedPlacement={true} placement='bottom'>
                            Pre-requisite for this is to choose Tenant from above dropdown. Choose the family rightly as only those Insights/Recommendations will be available for merging which have chosen family associated with them.
                        </Tooltip>
                    
                        
                        {listOfFamilies.length > 0 &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                className={'Text-fields'}
                                value={familyName || ''}
                                slot="action"
                                fixed-placement
                                label="Select Family"
                                placeholder={familyName || 'Select Family'}
                                name="family"
                                required
                                validationMessage="Please choose a Tenant"
                            >
                                {
                                    listOfFamilies.map(item => (
                                        <Option
                                            key={item}
                                            role="menuitemcheckbox"
                                            onHeSelected={handleFamilyChange}
                                        >
                                            {item}
                                        </Option>
                                    ))

                                }
                            </Select>
                        }
                        {listOfFamilies.length === 0 &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                value={familyName || ''}
                                slot="action"
                                fixed-placement
                                label="Select Family"
                                placeholder='Select Family'
                                className={'Text-fields'}
                                name="family"
                                required
                            >
                                {

                                }
                            </Select>
                        }
                        
                    </div>
            <span style={{ fontSize: "12px", color: "red" }}>{errorMsg}</span>

            <Button slot="footer" appearance="primary" disabled={!enableSave} onClick={() => {
              handleCloneMergeRule();
            }}>
              Create
            </Button>
            <Button slot="footer" onClick={() => {
              setOpenCreateNewMergeRule(false)
            }}>
              Cancel
            </Button>
          </>
        }
      </Dialog>
    </>
  );
};
const sortArrayOfString = (set: string[]) => {
  var sortedSet = set.sort((a, b) => {
      if (a.toLowerCase() > b.toLowerCase()) {
          return 1
      } else if (a.toLowerCase() < b.toLowerCase()) {
          return -1
      } else {
          return 0
      }
  });
  return sortedSet
}

