import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { WorkflowSummary } from "../../model/workflows/Workflow.model";

export interface ModelListDataGridRow extends Row {
  cells: {
    field1: string,
    field2: string,
    field3: string
  }
}
export const addedModelsColumns : Column[] = [
  {
    field: 'field2',
    content: 'Order Insight/Recommendation',
  },
  {
    field: 'field1',
    content: 'Insight/Recommendation Name',
  },
  {
    field: 'field3',
    content: '',
  }
];

export const addedModelsItemSelectorData = (data: MergeRuleSet, workflowSummary: WorkflowSummary[]): ModelListDataGridRow[] => {
  var i = 1;

  var transformedToRowItems: ModelListDataGridRow[] = []
  if (data.priority != undefined) {
    data.priority.forEach((item: string) => {
      var partOfWorkflow = workflowSummary.find(x => {return x.modelName === item})
      transformedToRowItems.push(
        {
          id: i,
          cells: {
            field1: partOfWorkflow && partOfWorkflow.config.workflowName || "",
            field2: '',
            field3: ''
          }
        })
      i++;
    });
  }
  return transformedToRowItems
}

export const isArraySame = (a1: string[], a2: string[]) => {
  return !([...a1].sort() > [...a2].sort() || [...a1].sort() < [...a2].sort());
}
