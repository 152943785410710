
import { useDispatch, useSelector } from 'react-redux';
import { CommandBar, Button, Icon } from '../../workflows/WorkflowsScope';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { postCreateNewVersion } from '../../../services/PostCreateNewVersion.api';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Environment, Release } from '../../../model/releases/Release.model';
import { v4 as uuidv4 } from 'uuid';
import { addRelease, addRollbackRelease } from '../Releases.redux';
import { SuccessNotification } from '../../notifications/SuccessNotification';
import { ErrorNotification } from '../../notifications/ErrorNotification';
import { withErrorBoundary } from '../../../shared-components/ErrorBoundary';
import { getWorkflowByIdandVersion_dev } from '../../../services/GetWorkflows.api';
import { postWorkflow, postWorkflowReleaseManagement } from '../../../services/PostWorkflow.api';
import { postReleaseReleaseManagement } from '../../../services/PostRelease.api';


const RollBackC: React.FC<any> = (props: any) => {
  const dispatchApp = useDispatch<AppDispatch>();
  const latestVersion = props.latestVersion;
  const selectedVersion = props.selectedVersion;
  const selectedReleaseIds = props.selectedReleaseIds;
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const workflow = useSelector((state: RootState) => state.releaseData.devWorkflow);
  const userId = useSelector((state: RootState) => state.userDetails.localAccountId);

  const environments: Environment[] = [
    { environment: "dev", isDeployed: true },
    { environment: "uat", isDeployed: false },
    { environment: "prod", isDeployed: false },
  ];

  const releaseData: Release = {
    id: uuidv4().toString(),
    releaseId: uuidv4().toString() ,
    workflowId: props.workflowId,
    version: latestVersion + 1,
    environments: environments,
    type: "New Version",
    timestamp: "",
    status: "New",
    scheduleCreated : false
  };

useEffect(() => {

  dispatchApp(getWorkflowByIdandVersion_dev({workflowId: props.workflowId, version: selectedVersion}));

},[selectedVersion]);

const handleRollback = (e:any) => {

    var updatedWorkflow = {...workflow};
    updatedWorkflow.version = latestVersion + 1;
    updatedWorkflow.status = "New";
    updatedWorkflow.modifiedBy = userId;

    dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "dev" })).then(unwrapResult)
    .then((result) => {
      if(result)
    {
      setShowSuccessNotif(true); setSuccessText("Rollback successful")
    }
    else
    {
      setErrorText("Rollback failed"); setShowErrorNotif(true);
    } 
    });

    dispatchApp(postReleaseReleaseManagement({payload: releaseData, env: "dev"}));
          
    dispatchApp(addRelease({input : releaseData, env:"dev"}));
    dispatchApp(addRollbackRelease({input : releaseData}));

}
  

  return (
    <div
      style={{
        display: "flex",
        padding: "4px",
        fontSize: "14px",
        alignItems: "center",
        marginRight:"12px", 
        marginLeft:"12px"
      }}
    >

      <div> <SuccessNotification successNotif={showSuccessNotif} setsuccesnotif={setShowSuccessNotif} successText = {successText} /> </div>
      <div> <ErrorNotification errorNotif={showErrorNotif} seterrornotif={setShowErrorNotif} errorText = {errorText} /> </div>

      <CommandBar>
        <Button
          appearance="command"
          disabled={latestVersion == selectedVersion || !selectedVersion}
          onClick={handleRollback}
        >
          <Icon slot="start" name="updaterestore"></Icon>
          Rollback
        </Button>
      </CommandBar>

    </div>
  );
};
export const RollBack = withErrorBoundary("Roll Back release management", RollBackC);