import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../root-redux/RootState";
import { Button, Dialog } from "../../../common/HarmonyEnablers";
import { deleteMergeRule } from "../../../services/get-merge-rules/DeleteMergeRule.api";
import { removeMergeRuleById } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { removeMergeRuleFromModel, removeMergeRuleFromModelList } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux";
import { removeMergeRuleBySubKey } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleBySubKey.redux";

export const DeleteConfirmation: React.FC<any> = (props: any) => {
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch()
  const heading = props.nextTarget === "SkipMerge" ? "Skip Merge Confirmation" : "Delete Default MergeRule Confirmation";
  function handleRequestClose(event: any) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }

  }

  const handleDeleteMergeRule = async () => {
    var output = await dispatchApp(deleteMergeRule({ mergeRuleId: props.mergeRuleName }));
    if (output.payload === true) {
      props.closePopup(false);
      dispatch(removeMergeRuleFromModelList(props.mergeRuleName))
      dispatch(removeMergeRuleById(props.mergeRuleName))
      dispatch(removeMergeRuleFromModel(props.mergeRuleName))
      dispatch(removeMergeRuleBySubKey(props.mergeRuleName))
      props.setsuccessNotif(true);
      props.setSuccessText(`${props.mergeRuleName} deleted successfully.`)
      props.setcurrentMergeType(props.nextTarget)
      props.setNextTarget('')
    } else {
      props.closePopup(true);
      var message = output.payload?.Message || ""
      props.seterrorText(`${props.mergeRuleName}- merge rule deletion failed. ${message}`)
      props.seterrornotif(true);
    }
  }
  return (
    <>
      <Dialog
        heading={heading}
        open
        onHeRequestClose={handleRequestClose}
        onHeAfterHide={({ target, currentTarget }) =>
          target === currentTarget ?
            props.closePopup(false)
            : null
        }
      >
        {props.nextTarget === "SkipMerge" && <p>Choosing skip will delete the default mergerule created: {props.mergeRuleName}</p>}
        {props.nextTarget === "NewUpdate" && <p>Do you want to delete the default mergerule created as part of Default option: {props.mergeRuleName}</p>}

        <Button
          slot="footer"
          appearance="primary"
          onClick={() => handleDeleteMergeRule()}
        >
          {props.nextTarget === "SkipMerge" ? 'Delete' : 'Yes'}
        </Button>
        <Button slot="footer" onClick={() => {
          if (props.nextTarget === "SkipMerge") {
            props.setcurrentMergeType(props.currentMergeType)
          } else if (props.nextTarget === "NewUpdate") {
            props.setcurrentMergeType(props.nextTarget)
          }
          props.setNextTarget('')
          props.closePopup(false)
        }}>
          {props.nextTarget === "SkipMerge" ? 'Cancel' : 'No'}
        </Button>
      </Dialog>
    </>
  );
};
