import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Accordion, AccordionItem, FlyInPanel } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateEnableSave, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeRuleSetBasics } from "./MergeRuleSetBasics";
import { MergeRuleSetModels } from "./MergeRuleSetModels";
import { MergeRuleSetFields } from "./MergeRuleSetFields";
import { MergeRuleSetMergeConditions } from "./MergeRuleSetMergeConditions";
import { MergeRuleSetResurface } from "./MergeRuleSetResurface";
import { EditMergeRuleSetHeader } from "./EditMergeRuleSetHeader";
import { MergeRuleSetFilter } from "./MergeRuleSetFilter";
import { MergeOnType } from "./MergeOnType";
import { MergeRuleSetOutput } from "./MergeRuleSetOutput";
import informationConfig from "../../shared-content/inputDescription";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { PreviewMergeRule } from "../preview-mergerule/PreviewMergeRule";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const AddEditMergeRuleSetsPageC: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation();
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
  const originalMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.originalMergeRuleSet);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
 
  return (
    <>
      <EditMergeRuleSetHeader mergeRuleSetName={originalMergeRuleSet.name} source={props.source} />
      <div className={`mergerule-set ${props.source === 'default' ? 'default' : ''}`}>
      <Accordion>
        <AccordionItem>
          <span slot="heading">Basic Information <Icon className='required-icon' name='actioncenterasterisk'></Icon>
          </span>
          <MergeRuleSetBasics />
        </AccordionItem>
        <AccordionItem>
          <span slot="heading">Merge Insights/Recommendations <Icon className='required-icon' name='actioncenterasterisk'></Icon></span>
          <div style={{ display: "flex" }}>
            <MergeRuleSetModels />
          </div>
        </AccordionItem>
        <AccordionItem>
          <span slot="heading">Merge Criteria </span>
          <div>
            <MergeOnType />
          </div>
        </AccordionItem>
        <AccordionItem>
          <span slot="heading">Advanced Settings (optional)</span>
          <div style={{ marginBottom: "24px" }}>
            <InformationTextMergeRule text={informationConfig.editMergeRuleSetAdvanced} type="Tab" />
          </div>
          <Accordion>
            <AccordionItem>
              <span slot="heading">Configure Resurface on Recommendations</span>
              <MergeRuleSetResurface />
            </AccordionItem>
            <AccordionItem>
              <span slot="heading">Apply Filters on Data to be considered for Merge</span>
              <MergeRuleSetFilter />
            </AccordionItem>
            <AccordionItem>
              <span slot="heading">Transform Fields of Generated Recommendations</span>
              <MergeRuleSetOutput />
            </AccordionItem>
            <div style={{ paddingBottom: "100px" }}></div>
          </Accordion>
        </AccordionItem>
      </Accordion>
      </div>
    </>
  )
}
export const AddEditMergeRuleSetsPage = withErrorBoundary("AddEditMergeRuleSetsPage", AddEditMergeRuleSetsPageC);