import { createAsyncThunk } from "@reduxjs/toolkit"
import { Workflow, WorkflowSummary } from "../model/workflows/Workflow.model"
import { getRequest } from "./utils/GetRequest"
import { RootState } from "../root-redux/RootState"
import { getAllUserObjects } from "./GetUserObjects.api"
import { getRequestReleaseManagement } from "./utils/GetRequestReleaseManagement"
import { AxiosResponse } from "axios"

export const getWorkflows = createAsyncThunk<WorkflowSummary[], {fetchUserObjects: boolean} | null, { state: RootState }>('workflows/getWorkflows', async (props, { getState, dispatch }) => {
  const selectedEnvItem  = getState().env.selectedEnvItem;
  const response = await getRequest<WorkflowSummary[]>(`${selectedEnvItem}/workflows`);
  const workflows = response.data;

  if (props && props.fetchUserObjects && workflows && workflows.length > 0) {
    dispatch(getAllUserObjects(workflows));
  }

  return workflows;
})

export const getWorkflowById = createAsyncThunk<Workflow, string, { state: RootState }>('workflows/getWorkflowById', async (workflowId: string, { getState }) => {
const selectedEnvItem  = getState().env.selectedEnvItem;
const response = await getRequest<Workflow>(`${selectedEnvItem}/workflows/${workflowId}`);
return response.data
})

export const getMultipleWorkflowsByIdandVersion = createAsyncThunk<Workflow[], {workflowId: string, versions: string[]}>('workflows/getMultipleWorkflowsByIdandVersion', async ({ workflowId, versions}) => {
  const responses = await Promise.all(versions.map((version: string) => getRequestReleaseManagement<Workflow>(`dev/workflows/${version}/${workflowId}`)))
  let result: Workflow[] = []
  responses?.forEach((res: AxiosResponse<Workflow>) => {
    if (res.data && res.data.id) {
      result.push(res.data)
    }
  })
  return result
});

export const getWorkflowByIdandVersion_dev = createAsyncThunk<Workflow, {workflowId:string, version:string}>('workflows/getWorkflowByIdandVersion_dev', async (workflowDetails) => {
const response = await getRequestReleaseManagement<Workflow>(`dev/workflows/${workflowDetails.version}/${workflowDetails.workflowId}`);
return response.data
});

export const getWorkflowByIdandVersion_uat = createAsyncThunk<Workflow, {workflowId:string, version:string}>('workflows/getWorkflowByIdandVersion_uat', async (workflowDetails) => {
const response = await getRequestReleaseManagement<Workflow>(`uat/workflows/${workflowDetails.version}/${workflowDetails.workflowId}`);
return response.data
});

export const getWorkflowByIdandVersion_prod = createAsyncThunk<Workflow, {workflowId:string, version:string}>('workflows/getWorkflowByIdandVersion_prod', async (workflowDetails) => {
const response = await getRequestReleaseManagement<Workflow>(`prod/workflows/${workflowDetails.version}/${workflowDetails.workflowId}`);
return response.data
});

export const getWorkflowsBySubKeys = createAsyncThunk<WorkflowSummary[], {subKeys: string },{ state: RootState}>('workflows/getWorkflowsBySubKey', async ({subKeys}, { getState }) => {
  var env = getState().env.selectedEnvItem;
  var subKeysString = ""
  if(subKeys){
    subKeysString += "?subKeys=" + subKeys
  } else {
    subKeysString += "?subKeys="
  }
  const response = await getRequest<WorkflowSummary[]>(`${env}/workflows/details`+subKeysString)
  return response.data
});
