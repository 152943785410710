import { useDispatch, useSelector } from "react-redux";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { authorizationLoaderSelector } from "../../../shared-components/role-based-access-control/AuthorizationLoader.selectors.redux";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { TypeMappingObject } from "../../../model/configModels/TypeMappingObject.model";
import { WorkflowStageType } from "../../../model/preview-workflow/WorkflowStageType.model";
import { getDevReleases, getUatReleases } from "../../../services/GetReleases.api";
import { postRelease } from "../../../services/PostRelease.api";
import { updateTypeMapping } from "../../../services/PostTypeMapping.api";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Button } from "../SpaAdminScope";
import { CampaignStageType } from "../../../model/SPAModels/campaigns/CampaignStageType.Model";
import { v4 as uuidv4 } from "uuid";
import { saveCampaign, updateCurrentStepId, updateEnableSave, updatePublishMapChanged, updateUnsavedChanges } from "../redux/CampaignById.redux";
import { postCampaignDetails } from "../../../services/SPAAdmin/CampaignById.api";
import { CampaignStatusEnum, CampaignTypes } from "../SPAConstants";
import { CheckCampaignPageAccessSelector } from "../redux/CheckCampaignAccess.selectors.redux";
import { sendVTeamReviewNotification } from "../../../services/SPAAdmin/MEONotification.api";


const EditCampaignFooterC: React.FC<{certCheck: any}> = ({ certCheck }) => {
    // redux states
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const stage = useSelector((state: RootState) => state.campaignById.currentStage);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const currentStepId = useSelector((state: RootState) => state.campaignById.currentStepId);
    const campaign = useSelector((state: RootState): Campaign => state.campaignById.campaign!);
    const originalCampaign = useSelector((state: RootState): Campaign => state.campaignById.originalCampaign);
    const campaignById = useSelector((state: RootState) => state.campaignById);
    const isStandardSPA = CampaignTypes.includes(campaignById.summary['CampaignType'] || "");
    const enableSave = useSelector((state: RootState) => state.campaignById.enableSave);
    const unsavedChanges = useSelector((state: RootState) => state.campaignById.unsavedChanges);
    const defaultWarningMsg = useSelector((state: RootState) => state.campaignById.defaultWarningMsg);
    const showLoader = useSelector(authorizationLoaderSelector)
    const access = CheckCampaignPageAccessSelector(campaign?.subscriptionKey)
    const type = useSelector((state: RootState) => state.workflowById.type);
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    const regexViewName = /^[a-zA-Z0-9-_]+$/;
    const regexPath = /^[a-zA-Z0-9- _/\.\\*]+$/;
    const regexName = /^[a-zA-Z0-9_ -]+$/;
    const regexNumber = /^[0-9]+$/;
    const releases = useSelector((state: RootState) => state.releases.releasesDev);
    const [isCreateNewVersion, setIsCreateNewVersion] = useState(false);
    const currentEnv = useSelector((state: RootState) => state.env.selectedEnvItem);
    const [isAllCertValid, setIsAllCertValid] = useState<boolean>(false);
   
    
    // set the first step in that workflow stage as current step id whenever stage changes
    useEffect(() => {
        if (!campaign) return

        switch (stage) {
           
            case WorkflowStageType.Publish:
                if (campaign.publish?.length > 0) {
                    dispatch(updateCurrentStepId({ stepId: campaign.publish[0].stepID, stepIndex: 0 }))
                } else{
                    dispatch(updateCurrentStepId({ stepId: '', stepIndex: 0 }))
                }
                break
        }
    }, [stage])

    // Validations for workflow stages
    useEffect(() => {
        if (!campaign) return

        if (!access.canEdit) {
            dispatch(updateEnableSave(true));
            return
        }
        switch (stage) {
            
            case CampaignStageType.Publish:
                const publishItem = campaign.publish?.filter(item => item.stepID === currentStepId)[0]
                const inputViewNames: string[] = [];
                campaign?.dataIngestion?.map((item) => {
                    inputViewNames.push(item.outputViewName);
                  });
                if (!publishItem) {
                    dispatch(updateEnableSave(false));
                    return
                }
                if(publishItem.publishTo === 'actioncenter' && !publishItem.outputViewName){
                    dispatch(updateEnableSave(false));
                    return
                }
                if(!publishItem.inputViewName || !publishItem.primaryKeys || !publishItem.modelId || !publishItem.publishTo || !campaign.family
                     || !regexViewName.test(campaign.family) || (publishItem.outputViewName && !regexViewName.test(publishItem.outputViewName)) ) {   
                    dispatch(updateEnableSave(false));
                    return
                }
                if(!inputViewNames.includes(publishItem.inputViewName)){
                    dispatch(updateEnableSave(false));
                    return
                }
                if (!publishItem.publishMap || publishItem.publishMap.length === 0) {
                    dispatch(updateEnableSave(false));
                    return
                }
                else {
                    if (!publishItem.publishMap?.find(item => item.destination === "PartitionKey")) {
                        dispatch(updateEnableSave(false));
                        return
                    }
                    // if (campaign.workFlowType === "Recommendation") {
                        const primayColumnsList = ["Reason", "StartOn", "DueInDays", "ExpiryInDays", "Text"];
                        var isInvalid = false;
                        primayColumnsList.forEach(element => {
                            if (!publishItem.publishMap.find(item => item.destination === "Primary." + element)) {
                                dispatch(updateEnableSave(false));
                                isInvalid = true;
                            }
                            if(element === "DueInDays" || element === "ExpiryInDays"){
                                let map = publishItem.publishMap.find(item => item.destination === "Primary." + element);
                                if(map && ((regexNumber.test(map.source) && map.mapType !== "Static") || (!regexNumber.test(map.source) && map.mapType === "Static"))){
                                    dispatch(updateEnableSave(false));
                                    isInvalid = true;
                                }
                            }
                            
                        });
                        if(isInvalid){
                            dispatch(updateEnableSave(false));
                            return
                        }
                    //} 
                    let save = true;
                    publishItem.publishMap?.forEach(map => {
                        if (map.fieldType === "None" || map.fieldType === null || map.fieldType === undefined || map.fieldType === "") {
                            save = false;
                            return;
                        }
                        
                    })
                    if(!save) {
                        dispatch(updateEnableSave(false));
                        return;
                    }

                    if (publishItem.delta) {
                        // check for corresponding delta item
                        const deltaItem = campaign.delta?.filter(item => item.inputViewName === publishItem.inputViewName)[0]
                        if (!deltaItem) {
                            dispatch(updateEnableSave(false));
                            return
                        }
                        if (!deltaItem.deltaMap || deltaItem.deltaMap.length === 0) {
                            dispatch(updateEnableSave(false));
                            return
                        }

                        let save = true;
                        publishItem.primaryKeys.split(",").forEach(key => {
                            if (deltaItem.deltaMap.filter(item => item.columnName === key).length === 0 || !deltaItem.deltaMap.filter(item => item.columnName === key)[0]?.isPrimary) {
                                save = false;
                                return;
                            }
                        });
                        if (!save) {
                            dispatch(updateEnableSave(false));
                            return
                        }
                    }

                    if (publishItem.publishTo === 'custom') {
                        if (publishItem.type?.toUpperCase() === "BLOB" && !publishItem.path && !publishItem.blobConnectionId && !publishItem.type) {
                            dispatch(updateEnableSave(false));
                            return
                        }
                        else if (!publishItem.connectionId && !publishItem.type) {
                            dispatch(updateEnableSave(false));
                            return
                        }
                        if (publishItem.type === 'SQL' && !publishItem.tableName) {
                            dispatch(updateEnableSave(false));
                            return
                        }
                        if(publishItem.type === "API" && !publishItem.outputViewName){
                            dispatch(updateEnableSave(false));
                            return
                        }
                    }

                    dispatch(updateEnableSave(true));
                }

                if (type !== 'existing'
                    || campaign?.publish?.length !== originalCampaign?.publish?.length
                    || JSON.stringify(campaign?.publish) !== JSON.stringify(originalCampaign?.publish)
                    || campaign?.delta?.length !== originalCampaign?.delta?.length
                    || JSON.stringify(campaign?.delta) !== JSON.stringify(originalCampaign?.delta)
                    || campaign?.family !== originalCampaign?.family ) {
                    dispatch(updateUnsavedChanges(true));
                    // TODO: The API config will be updated whenever publish map changes. Else wont
                    dispatch(updatePublishMapChanged(true));
                } else {
                    dispatch(updateUnsavedChanges(false))
                    dispatch(updatePublishMapChanged(false));

                }
                break
        }
    }, [stage, currentStepId, campaign])
    
    useEffect(() => {
        if(releases !== undefined && releases.length > 0 && campaign?.status === "Published" && releases[0].workflowId.toLowerCase() === campaign.config.workflowId.toLowerCase() && isCreateNewVersion){
            var updatedWorkflow = {...campaign}
            var currentDateTime = new Date()
            // find the release with the max version
            const newVersion = (Math.max(...releases.map(item => item.version as unknown as number)) + 1)
            updatedWorkflow.version = newVersion
            updatedWorkflow.status = "New"
            updatedWorkflow.id = uuidv4().toString()
            dispatch(saveCampaign(updatedWorkflow))
            dispatch(postCampaignDetails()); //TO-DO check for Postcampaign
            var newRelease = {...releases[0]};
            newRelease.version = newVersion.toString();
            newRelease.id = uuidv4().toString();
            newRelease.releaseId = uuidv4().toString();
            newRelease.status = "New";
            newRelease.type = "New Version";
            newRelease.timestamp = new Date(currentDateTime.toUTCString()).getTime().toString();
            dispatch(postRelease({payload: newRelease, env: currentEnv}));
            setIsCreateNewVersion(false);
        }
    }, [releases, isCreateNewVersion])

    const createNewVersion = () => { 
        if(releases === undefined || releases.length === 0 || releases[0].workflowId !== campaign.config.workflowId){
            if (currentEnv === "dev") {
                dispatch(getDevReleases(campaign.config.workflowId));
            } else if (currentEnv === "uat") {
                dispatch(getUatReleases(campaign.config.workflowId));
            }
        }       
        setIsCreateNewVersion(true);       
    }

    async function onPreviousClick() {
        _onNavMenuItemClick(NavMenuItemType.Campaign, "refine");
    }

    const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
        navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
    }, [navigate]);
    
    //TODO: if updating this method, also update the same method in ReleaseActionsUAT.tsx and ReleaseActionsPROD.tsx
    const addTypeMapping = () => {
        var family = campaign.family;
        const selectedPublish = campaign.publish.find(x => x.type === 'COSMOS')
        const partitionKey = selectedPublish != null ? (selectedPublish?.publishMap.find(x => x.destination === 'PartitionKey') != null ? selectedPublish?.publishMap.find(x => x.destination === 'PartitionKey')?.source : '') : ''
        let typeMap = new Map<string, string>();
        let mapValues = null;
        if (family && partitionKey)
            typeMap.set(family, partitionKey);
      
        if (typeMap.size > 0) mapValues = Object.fromEntries(typeMap);

        if (mapValues != null) {
            let typeMappingSchema: TypeMappingObject = {
                typeMapping: mapValues,
            };
            dispatch(updateTypeMapping({ payload: typeMappingSchema }));
        }
    };

    const sendSPACompleteNotificationCall = () => {
        dispatch(sendVTeamReviewNotification()).unwrap().then((result) => {
            if(result) {
                console.log(result);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
      
    async function onClickNext() {
        // call the API only if there is a change in workflow
        if (unsavedChanges && access.canEdit) {

            if(stage === CampaignStageType.Publish){
              //  dispatch(setWorkflowSummaryStatus({workflowId: campaign.config.workflowId, status: "Published"}));
                addTypeMapping();

            } 
            
            if(campaign.status === "Published"){
                createNewVersion();
            }
            else{
                dispatch(postCampaignDetails()).unwrap().then((result) => {
                   // if(result) {
                        //publish adb 
                        //dispatch(publishCampaignWorkflow({workflowId: campaign.config.workflowId, env: currentEnv})).unwrap().then((result) => {
                            navigate("/" + NavMenuItemType[NavMenuItemType.Configuration]);

                     //   });
                        //_onNavMenuItemClick(NavMenuItemType.Campaign, "refine");
                  //  }
                });
            }
            
            // Send a mail to SPA v-team to review before launching the campaign
            sendSPACompleteNotificationCall();
           
        } 
       // updateStep(1);
    }

    //TODO: Need to check for the authorization and show the actions 

    // if (showLoader) {
    //     return <></>
    // }

    const checkTerms =  () => {
        return userSalesRoles.isSpaUser && Object.keys(certCheck).filter(k => certCheck[k] === true).length !== 4 && !(campaignById.summary['CampaignType'] || "").startsWith('SMC');
    }

    return (
        <>
            <div className={`workflow-footer ${isNavPanelOpen ? '': 'collapsed'}`}>
                <Button type='button' appearance='secondary' onClick={onPreviousClick}>Previous</Button>
                <span style={{ marginLeft: '20px' }}></span>
                
                { (<Button type='button' appearance='primary' onClick={() => onClickNext()} disabled={checkTerms() || (isStandardSPA?campaign.status !==  CampaignStatusEnum.DRAFT:!enableSave)}>Save</Button>)}
                <span style={{ marginLeft: '20px' }}></span>
                {/* <EditWorkflowStatus /> */}
            </div>
        </>
    )
}
export const EditCampaignFooter = withErrorBoundary("EditCampaignFooter", EditCampaignFooterC)


