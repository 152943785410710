import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { MergeRule, emptyMergeRule } from '../../../model/merge-rules/MergeRule.model'
import { getMergeRuleById, getMergeRuleByModel } from '../../../services/get-merge-rules/GetMergeRules.api'

interface MergeRuleByModelState {
    mergeRule: MergeRule,
    list: MergeRule[],
    status: RequestStatus,
    error: string | undefined
}

const initialState: MergeRuleByModelState = {
    mergeRule: emptyMergeRule,
    list: [] as MergeRule[],
    status: RequestStatus.idle,
    error: ''
}
export const mergeRuleByModelSlice = createSlice({
    name: 'mergeRuleByModel',
    initialState,
    reducers: {
        updateMergeRuleByModel: (state, action: PayloadAction<MergeRule>) => {
            state.mergeRule = action.payload
        },
        updateMergeRulesByModel: (state, action: PayloadAction<MergeRule>) => {
            var list = [...state.list]
            var isMergeRulePresent = list.find(x => {return x.mergeRuleId === action.payload.mergeRuleId})
            if(isMergeRulePresent) {
                var index = list.indexOf(isMergeRulePresent)
                list[index] = action.payload
            }
            state.list = [...state.list, action.payload]
        },
        addMergeRulesByModel: (state, action: PayloadAction<MergeRule>) => {
            state.list = [...state.list, action.payload]
        },
        removeMergeRuleFromModel: (state, action: PayloadAction<string>) => {
            state.mergeRule = {...emptyMergeRule, mergeRuleId: action.payload, mergeRuleName: action.payload}
        },
        removeMergeRuleFromModelList: (state, action: PayloadAction<string>) => {
            state.mergeRule = {...emptyMergeRule, mergeRuleId: action.payload, mergeRuleName: action.payload}
            var list = state.list
            var currList = [...list]
            var isMergeRulePresent = currList.find(x => {return x.mergeRuleId === action.payload})
            if(isMergeRulePresent) {
                var index = currList.indexOf(isMergeRulePresent)
                currList.splice(index,1)
                state.list = [...currList]
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getMergeRuleByModel.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(getMergeRuleByModel.fulfilled, (state, action) => {
                if (action.payload.data instanceof Array) {
                    state.status = RequestStatus.succeeded
                    var allMergeRules = action.payload.data
                    if (allMergeRules.length > 1) {
                        var mergeRuleToBeAssigned = allMergeRules.filter((x: any) => { return x.sourceFilter && x.sourceFilter.length > 0 })
                        if (mergeRuleToBeAssigned && mergeRuleToBeAssigned.length > 0) {
                            state.mergeRule = mergeRuleToBeAssigned[0]
                        }
                    } else {
                        state.mergeRule = action.payload.data[0]
                    }
                    state.list = allMergeRules
                    state.error = undefined
                } else {
                    state.status = RequestStatus.failed
                    state.mergeRule = emptyMergeRule
                    state.list = []
                    state.error = action.payload?.data?.Message || undefined
                }
            })
            .addCase(getMergeRuleByModel.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
    }
})

export const { } = mergeRuleByModelSlice.actions

export const mergeRuleByModelReducer = mergeRuleByModelSlice.reducer