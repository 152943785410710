import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { TypeMappingObject } from "../../model/configModels/TypeMappingObject.model";
import { getTypeByKeyMapping } from "../../services/GetTypeMapping.api";

interface TypeMappingConfigByKey {
  typeMappingByKey: TypeMappingObject,
  statusByKey: RequestStatus,
  errorByKey: string | undefined,
  updateSuccessStatusByKey: boolean
}

const initialState: TypeMappingConfigByKey = {
  typeMappingByKey: {} as TypeMappingObject,
  statusByKey: RequestStatus.idle,
  errorByKey: '',
  updateSuccessStatusByKey: false
}


export const typeMappingConfigByKeySlice = createSlice({
  name: 'typeMappingByKey',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTypeByKeyMapping.pending, (state, action) => {
        state.statusByKey = RequestStatus.loading
      })
      .addCase(getTypeByKeyMapping.fulfilled, (state, action) => {
        state.statusByKey = RequestStatus.succeeded
        state.typeMappingByKey = action.payload
        state.errorByKey = undefined
      })
      .addCase(getTypeByKeyMapping.rejected, (state, action) => {
        state.statusByKey = RequestStatus.failed
        state.errorByKey = action.error.message
      })
  }
});

export const typeMappingConfigByKeyReducer = typeMappingConfigByKeySlice.reducer