import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { getDatasetsList } from "../../../services/Dataset/DatasetsList.api";

export interface DatasetListState {
    datasetList: WorkflowSummary[],
    status: RequestStatus,
    currPage: number,
    totalPage: number,
    filters: any,
    error: string | undefined
}

const initialState: DatasetListState = {
    datasetList: [],
    status: RequestStatus.idle,
    currPage: 1,
    totalPage: 1,
    filters: {},
    error: ''
}

export const DatasetListSlice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
      changePageNumber: (state, action: PayloadAction<number>) => {
        state.currPage = action.payload
      },
      setDatasetFilters: (state, action: PayloadAction<any>) => {
        state.filters = action.payload;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(getDatasetsList.pending, (state, action) => {
              state.status = RequestStatus.loading
            })
            .addCase(getDatasetsList.fulfilled, (state, action: PayloadAction<WorkflowSummary[]>) => {
              state.status = RequestStatus.succeeded
              state.datasetList = action.payload
              state.error = undefined
            })
            .addCase(getDatasetsList.rejected, (state, action: any) => {
              state.status = RequestStatus.failed
              const message = (action?.payload && action.payload["Message"] ? action.payload["Message"] : action.error.message);
              state.error = "Dataset List: " + message;
            })
    }
});

export const {
  changePageNumber,
  setDatasetFilters
} = DatasetListSlice.actions;

export const datasetListReducer = DatasetListSlice.reducer