import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { userObject } from '../../model/user-object/UserObject.model';
import { RequestStatus } from '../../model/ServiceRequestStatus.model';
import { getAllUserObjects } from '../../services/GetUserObjects.api';
import { getAllUserObjectsOfMergerules } from '../../services/GetUserObjectsOfMergeRules.api';

interface UserObjectsStateOfMergeRule {
  list : userObject[],
  status: RequestStatus,
  error: string | undefined
}

const initialState: UserObjectsStateOfMergeRule = {
  list : [],
  status: RequestStatus.idle,
  error: ''
}

export const userObjectsSliceOfMergeRule  = createSlice({
  name: 'userObjectsOfMergeRule',
  initialState,
  reducers: {
    addUserObject: (state, action) => {
        state.list.push(action.payload)
      }
  },
  extraReducers(builder) {
    builder
    .addCase(getAllUserObjectsOfMergerules.pending, (state, action) => {
      state.status = RequestStatus.loading
    })
    .addCase(getAllUserObjectsOfMergerules.fulfilled, (state, action) => {
      state.status = RequestStatus.succeeded
      state.list = action.payload
      state.error = undefined
    })
    .addCase(getAllUserObjectsOfMergerules.rejected, (state, action) => {
      state.status = RequestStatus.failed
      state.error = action.error.message
    })
  }
});


export const userObjectsOfMergeRuleReducer = userObjectsSliceOfMergeRule.reducer