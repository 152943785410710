const informationConfig = {

    allInsightsAndRecommendationsList: "Explore insights and recommendations from all teams, including external model providers. Duplicate for reuse or create new. Screen defaults to filtering your team's contributions",
    onboardingEssentialInformation: "This tab captures input for an insight or recommendation and source model/signal data. All details entered here contribute to the discoverability of your insights and recommendations by your team or for others.",
    onboardingDataSources: "Establish connections with model/signal data sources and transfer data to destination datasets on Nebula platform for further processing.",
    onboardingDataRefinement: "The data in Nebula datasets is refined by applying conditions, thresholds, filters, and limiters to ensure its quality and relevance. These configurations help fine-tune the data to generate accurate insights and recommendations.",
    onboardingPublish: "Select the datasets and map attributes to your workspace/UX schema publish the API. You can define primary keys and triggering attributes for efficient delta updates, ensuring data customization and alignment.​",
    onboardingMerge: "Merge configuration enables us to configure rules on what data from which insights/recommendations should merge and how, inorder to generate recommendations.",
    onboardingConsume: "",
    onboardingRelease:"Select a version in the Release Management for actions - Deploy, Approve/Reject by authorized team members in each environment.",
    allMergeRules : "Create new or use existing merge rules to generate recommendations.",
    editMergeRule: "Edit Merge Rule to generate recommendations.",
    createNewMergeRule: "Create New Merge Rule to generate recommendations",
    editMergeRuleEssentialInformation: "Metadata Information of Merge Rule",
    editMergeRuleSetConfiguration: "Recommendations are generated by merging data from different Insight(s)/Recommendation(s) on different fields available. All these rules on how to generate recommendations are curated defined under a Merge Ruleset.",
    editMergeRuleSetBasics: "Enter Metadata information for Merge Ruleset",
    editMergeRuleSetModels: "Add Insight(s)/Recommendation(s) that should participate in recommendation generation",
    editMergeRuleSetFields: "Recommendations can be generated when insights/input recommendations with matching values of different fields merge with each other or when specific value of certain field match with that of some other field as specified in the condition. Based on your requirement, add fields or specific values on fields on which insights/input recommendations should merge to generate recommendations.",
    editMergeRuleSetAdvanced: "Advanced settings which are not mandatory for recommendation generation but can be applied to make recommendation generation process more customised as per your need.",
    editMergeRuleSetResurface: "Recommendations once actioned by seller are not resurfaced to them again unless specified explicitly. Edit following to resurface recommendations after x number of days on different conditions",
    editMergeRuleSetFilter: "Helps to apply merge on only those insights/input recommendations that match the filter configured. Eg: You can choose to only include those insights/input recommendations which does not belong to certain Insight/Recommendation and so on..",
    editMergeRuleSetOutput: "Recommendations' output is formed based on some default choices by platform. If you want to override this, add transformations below.",
    campaignsList: "Create new or edit existing SPA Campaign.",
    campaignSummary: "Please add details to initiate SPA creation",
    campaignRefine: "This tab is to add data from selected model datasets, refine data with filter criteria's and finalize the targeted result set",
    campaignPostProcess:"To show the preview of the final output",
    datasetFileUploadText: "Upload the dataset file to Nebula platform for further processing.",
    datasetSummary: "Please add details to initiate dataset creation",
};

export default informationConfig;
