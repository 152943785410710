import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid"
import { DeltaMap } from "../../model/workflows/Delta.model"
import { PublishMap } from "../../model/workflows/Publish.model"
import { MergeRule } from "../../model/merge-rules/MergeRule.model"
import { RootState } from "../../root-redux/RootState"
import { MergeRulePreviewOutput } from "../../model/preview-mergerule/MergeRulePreviewOutput.model"
import { ModelMapping } from "../../model/configModels/ModelMapping.model"
import { MergeRulePreviewUserInput } from "../../model/preview-mergerule/MergeRulePreviewUserInput.model"

export interface PreviewOutputGrid extends Row {
  id: string,
  cells: {
    field0: string
    field1: string
    // field12: string
    field2: string
    field3: string
    field4: string,
    field450: string
    field451: string
    field452: string
    field5: string,
    field562: string
    field560: string
    field561: string
    field6: string
    field7: string
    field8: string
    field9: string
  }
}

export var PreviewOutputColumns = [
  {
    field: 'field1',
    content: 'MergeRule Set Name',
  },
  // {
  //   field: 'field12',
  //   content: 'MergeRule Set Type',
  // },
  {
    field: 'field2',
    content: 'Merged On',
  },
  {
    field: 'field3',
    content: 'Input Recommendations',
  },
  {
    field: 'field4',
    content: 'Input Insights',
  },
  {
    field: 'field450',
    content: 'Prioritised Input Recommendation',
  },
  {
    field: 'field451',
    content: 'Recommendations Before Merge',
  },
  {
    field: 'field452',
    content: 'Recommendation Version Before Merge',
  },
  {
    field: 'field5',
    content: 'Recommendations After Merge',
  },
  {
    field: 'field560',
    content: 'Recommendation Unchanged After Merge',
  },
  {
    field: 'field561',
    content: 'Is Recommedation Resurfaced',
  },
  {
    field: 'field562',
    content: 'Recommendation Version After Merge',
  },
  {
    field: 'field6',
    content: 'Left over Input Recommendations',
  },
  {
    field: 'field7',
    content: 'Recommendation responsible for left over input recommendations',
  },
  {
    field: 'field8',
    content: 'Recommendations Superseded',
  },
  {
    field: 'field9',
    content: 'Recommendations Deleted',
  },
];

export const previewOutputData = (previewMergeRuleOutput: MergeRulePreviewOutput, predsMap: [string,string][], recsMap: [string,string][], insMap: [string,string,string][], mergeRule: MergeRule, previewMergeRuleUserInput: MergeRulePreviewUserInput): PreviewOutputGrid[] => {
  var isFound = PreviewOutputColumns.find(x => { return x.field === 'field0' })
  if (!isFound) {
    PreviewOutputColumns.unshift({
      field: 'field0',
      content: previewMergeRuleOutput.distinctField || "TPID"
    })
  }

  var i = 1;
  var transformedToRowItems: PreviewOutputGrid[] = []
  var output = previewMergeRuleOutput.curationOutput
  if (output && output.length > 0) {
    for(var i = 0 ; i < output.length; i ++) {
      var item = output[i]
      if(previewMergeRuleUserInput.onlyOneMergeRuleSet) {
        if(previewMergeRuleUserInput.mergeRuleSetId !== item.mergeRuleSetId && item.mergeRuleType === "primary") {
          continue;
        }
      }

      var h = item.existingRecommendationId;
      var shortPredNames : string[] = []
      if(item.predictionIds && item.predictionIds.length > 0) {
        item.predictionIds.forEach((eachItem) => {
          var shortName = predsMap.find(x => {return x[0] === eachItem})
          if(shortName){
            shortPredNames.push(shortName[1])
          } else {
            shortPredNames.push(eachItem)
          }
        })
      }

      var shortInNames : string[] = []
      if(item.insightIds && item.insightIds.length > 0) {
        item.insightIds.forEach((eachItem) => {
          var shortName = insMap.find(x => {return x[0] === eachItem})
          if(shortName){
            shortInNames.push(shortName[1])
          } else {
            shortInNames.push(eachItem)
          }
        })
      }

      var leftOverPredNames: string[] = []
      if(item.leftOverPredictionIds && item.leftOverPredictionIds.length > 0 ) {
        item.leftOverPredictionIds.forEach((eachItem) => {
          var shortName = predsMap.find(x => {return x[0] === eachItem})
          if(shortName){
            leftOverPredNames.push(shortName[1])
          } else {
            leftOverPredNames.push(eachItem)
          }
        })
      }

      var supersedRecNames: string[] = []
      if(item.supersededList && item.supersededList.length > 0 ) {
        item.supersededList.forEach((eachItem) => {
          var shortName = recsMap.find(x => {return x[0] === eachItem})
          if(shortName){
            supersedRecNames.push(shortName[1])
          } else {
            supersedRecNames.push(eachItem)
          }
        })
      }

      var deletedRecNames: string[] = []
      if(item.deletedList && item.deletedList.length > 0 ) {
        item.deletedList.forEach((eachItem) => {
          var shortName = recsMap.find(x => {return x[0] === eachItem})
          if(shortName){
            deletedRecNames.push(shortName[1])
          } else {
            deletedRecNames.push(eachItem)
          }
        })
      }

      var recId = item.existingRecommendationId || ""
      if(recId) {
        var shortName = recsMap.find(x => {return x[0] === recId})
        if(shortName) {
          recId = shortName[1]
        }
      }

      var validId = item.validRecommendationId || ""
      if(validId) {
        var shortName = recsMap.find(x => {return x[0] === validId})
        if(shortName) {
          validId = shortName[1]
        }
      }

      var prioritisedPredId = item.prioritisedPredictionId || ""
      if(prioritisedPredId) {
        var shortName = predsMap.find(x => {return x[0] === prioritisedPredId})
        if(shortName) {
          prioritisedPredId = shortName[1]
        }
      }

      var existingId = item.existingRecommendationId || ""
      if(existingId) {
        var shortName = recsMap.find(x => {return x[0] === existingId})
        if(shortName) {
          existingId = shortName[1]
        }
      }

      var recResponsible = item.recommendationResponsible || ""
      if(recResponsible) {
        var shortName = recsMap.find(x => {return x[0] === recResponsible})
        if(shortName) {
          recResponsible = shortName[1]
        }
      }

      var mergeRuleSetName = item.mergeRuleSetId || ""
      var mergeRuleSet = mergeRule && mergeRule.primaryMergeRuleSet.find((x: any) => {return x.id === mergeRuleSetName})
      if(mergeRuleSet) {
        mergeRuleSetName = mergeRuleSet.name
      } else {
        mergeRuleSet = mergeRule && mergeRule.secondaryMergeRuleSet.find((x: any) => {return x.id === mergeRuleSetName})
        if(mergeRuleSet) {
          mergeRuleSetName = mergeRuleSet.name
        }
      }      

      transformedToRowItems.push(
        {
          id: i.toString(),
          cells: {
            field0: item.tpid || "",
            field1: mergeRuleSetName,
            //field12: item.mergeRuleType === "primary" ? "Generate New Rec" : "Enrich Existing Rec",
            field2: item.groupValue || '',
            field3: shortPredNames.join(",") || "",
            field4: shortInNames.join(",") || "",
            field450: prioritisedPredId,
            field451: existingId,
            field452: item.existingRecommendationVersion || "",
            field5: validId,
            field562: item.validRecommendationVersion || "",
            field560: item.returnAsIs == undefined ? "false" : item.returnAsIs.toString(),
            field561: item.resurfaced == undefined ? "false" : item.resurfaced.toString(),
            field6:  leftOverPredNames.join(",") || "",
            field7: recResponsible,
            field8: supersedRecNames.join(",") || "",
            field9: deletedRecNames.join(",") || ""
          }
        })
    }
  } 
  return transformedToRowItems
}