
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const InputEssentialInfoC: React.FC<any> = (props: any) => {

  return (
    <>
    
    <div className="help-mainHeading">
      
          <span id="inputessentialinfoTag" className="help-subheading">Input essential details</span>
          <div>
          <span className="help-heading">Summary: </span>
          <span className="help-content"> {onboarding.essentialInformation.summary} </span>
          </div>
          </div>
          <hr/>
          <div className="help-mainHeading" id = "onboarding_dataSources">
          <div>
          <span className="help-heading" style = {{fontSize:"18px"}}>Fields Descriptions</span>
          <ul>
            {onboarding.essentialInformation.infoicons.map(info =>
              <li key={info} style={{marginBottom:"6px"}}>
                <span className="help-content" dangerouslySetInnerHTML={{ __html: info }} />
              </li>
            )}
          </ul>
          </div>
          </div>
          <hr/>

    </>
  );
};
export const InputEssentialInfo = withErrorBoundary("InputEssentialInfo content", InputEssentialInfoC);