import { RoleBasedAccessControlModel } from "../../model/role-based-access-control/RoleBasedAcessControl.model";
import { RootState } from "../../root-redux/RootState";

export const devReleasePermissionsSelector = (
  state: RootState
): RoleBasedAccessControlModel => {
  const devappPersonaItems = state.releaseData.devAppPersonas;
  const groupsUserIsIn = state.userGroups.list;

  const devpermissionsList: RoleBasedAccessControlModel = {
    edit : [],
    approve: [],
    release: [],
    userTeams: { key: [], name: []},
    primaryTeam : ""
  };

  devappPersonaItems.map((appPersonaObject) => {
    appPersonaObject.appRoles.edit.map((editSG) => {
      if (
        groupsUserIsIn.includes(editSG) &&
        !devpermissionsList.edit.includes(appPersonaObject.subscriptionKey)
      ) {
        devpermissionsList.edit.push(appPersonaObject.subscriptionKey);
        if (
          !devpermissionsList.userTeams.key.includes(
            appPersonaObject.subscriptionKey
          )
        ) {
            devpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            devpermissionsList.userTeams.name.push(appPersonaObject.appName);
        }
      }
    });

    appPersonaObject.appRoles.approve.map((approveSG) => {
      if (
        groupsUserIsIn.includes(approveSG) &&
        !devpermissionsList.approve.includes(appPersonaObject.subscriptionKey)
      ) {
        devpermissionsList.approve.push(appPersonaObject.subscriptionKey);
        if (
          !devpermissionsList.userTeams.key.includes(
            appPersonaObject.subscriptionKey
          )
        ) {
            devpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            devpermissionsList.userTeams.name.push(appPersonaObject.appName);
        }
      }
    });

    appPersonaObject.appRoles.release.map((releaseSG) => {
      if (
        groupsUserIsIn.includes(releaseSG) &&
        !devpermissionsList.release.includes(appPersonaObject.subscriptionKey)
      ) {
        devpermissionsList.release.push(appPersonaObject.subscriptionKey);
        if (
          !devpermissionsList.userTeams.key.includes(
            appPersonaObject.subscriptionKey
          )
        ) {
            devpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            devpermissionsList.userTeams.name.push(appPersonaObject.appName);
        }
      }
    });
  });

  return devpermissionsList;
};


export const uatReleasePermissionsSelector = (
    state: RootState
  ): RoleBasedAccessControlModel => {
    const uatappPersonaItems = state.releaseData.uatAppPersonas;
    const groupsUserIsIn = state.userGroups.list;
  
    const uatpermissionsList: RoleBasedAccessControlModel = {
      edit : [],
      approve: [],
      release: [],
      userTeams:{ key: [], name: []},
      primaryTeam : ""
    };
  
    uatappPersonaItems.map((appPersonaObject) => {
      appPersonaObject.appRoles.edit.map((editSG) => {
        if (
          groupsUserIsIn.includes(editSG) &&
          !uatpermissionsList.edit.includes(appPersonaObject.subscriptionKey)
        ) {
            uatpermissionsList.edit.push(appPersonaObject.subscriptionKey);
          if (
            !uatpermissionsList.userTeams.key.includes(
              appPersonaObject.subscriptionKey
            )
          ) {
            uatpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            uatpermissionsList.userTeams.name.push(appPersonaObject.appName);
          }
        }
      });

      appPersonaObject.appRoles.approve.map((approveSG) => {
        if (
          groupsUserIsIn.includes(approveSG) &&
          !uatpermissionsList.approve.includes(appPersonaObject.subscriptionKey)
        ) {
            uatpermissionsList.approve.push(appPersonaObject.subscriptionKey);
          if (
            !uatpermissionsList.userTeams.key.includes(
              appPersonaObject.subscriptionKey
            )
          ) {
            uatpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            uatpermissionsList.userTeams.name.push(appPersonaObject.appName);
          }
        }
      });
  
      appPersonaObject.appRoles.release.map((releaseSG) => {
        if (
          groupsUserIsIn.includes(releaseSG) &&
          !uatpermissionsList.release.includes(appPersonaObject.subscriptionKey)
        ) {
            uatpermissionsList.release.push(appPersonaObject.subscriptionKey);
          if (
            !uatpermissionsList.userTeams.key.includes(
              appPersonaObject.subscriptionKey
            )
          ) {
            uatpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            uatpermissionsList.userTeams.name.push(appPersonaObject.appName);
          }
        }
      });
    });
  
    return uatpermissionsList;
  };

  export const prodReleasePermissionsSelector = (
    state: RootState
  ): RoleBasedAccessControlModel => {
    const prodappPersonaItems = state.releaseData.prodAppPersonas;
    const groupsUserIsIn = state.userGroups.list;
  
    const prodpermissionsList: RoleBasedAccessControlModel = {
      edit : [],
      approve: [],
      release: [],
      userTeams:{ key: [], name: []},
      primaryTeam : ""
    };
  
    prodappPersonaItems.map((appPersonaObject) => {
      appPersonaObject.appRoles.edit.map((editSG) => {
        if (
          groupsUserIsIn.includes(editSG) &&
          !prodpermissionsList.edit.includes(appPersonaObject.subscriptionKey)
        ) {
            prodpermissionsList.edit.push(appPersonaObject.subscriptionKey);
          if (
            !prodpermissionsList.userTeams.key.includes(
              appPersonaObject.subscriptionKey
            )
          ) {
            prodpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            prodpermissionsList.userTeams.name.push(appPersonaObject.appName);
          }
        }
      });

      appPersonaObject.appRoles.approve.map((approveSG) => {
        if (
          groupsUserIsIn.includes(approveSG) &&
          !prodpermissionsList.approve.includes(appPersonaObject.subscriptionKey)
        ) {
            prodpermissionsList.approve.push(appPersonaObject.subscriptionKey);
          if (
            !prodpermissionsList.userTeams.key.includes(
              appPersonaObject.subscriptionKey
            )
          ) {
            prodpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            prodpermissionsList.userTeams.name.push(appPersonaObject.appName);
          }
        }
      });
  
      appPersonaObject.appRoles.release.map((releaseSG) => {
        if (
          groupsUserIsIn.includes(releaseSG) &&
          !prodpermissionsList.release.includes(appPersonaObject.subscriptionKey)
        ) {
            prodpermissionsList.release.push(appPersonaObject.subscriptionKey);
          if (
            !prodpermissionsList.userTeams.key.includes(
              appPersonaObject.subscriptionKey
            )
          ) {
            prodpermissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
            prodpermissionsList.userTeams.name.push(appPersonaObject.appName);
          }
        }
      });
    });
  
    return prodpermissionsList;
  };
  
  