import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { Workflow } from "../../../model/workflows/Workflow.model";
import { getDatasetById } from "../../../services/Dataset/DatasetById.api";
export interface DatasetByIdState {
    dataset: Workflow|undefined ,
    originalDataset: Workflow,
    requestStatus: RequestStatus,
    error: string | undefined,
    unsavedChanges: boolean,
    editDatasetFlag: boolean,
    enableSave: boolean,
    isSideNavOpen: boolean,
    defaultWarningMsg: boolean
}

const initialState: DatasetByIdState = {
    dataset: undefined,
    originalDataset: {} as Workflow,
    requestStatus: RequestStatus.idle,
    error: undefined,
    unsavedChanges: false,
    editDatasetFlag: false,
    enableSave: false,
    isSideNavOpen: true,
    defaultWarningMsg:false
};


export const DatasetByIdSlice = createSlice({
    name: 'datasetById',
    initialState,
    reducers: {
        setEditDatasetFlag: (state, action: PayloadAction<any>) => {
            state.editDatasetFlag = action.payload;
        }
    },
    extraReducers(builder) {
        builder
        .addCase(getDatasetById.pending, (state, _) => {
            state.requestStatus = RequestStatus.loading
            state.error = undefined
        })
        .addCase(getDatasetById.fulfilled, (state, action: PayloadAction<Workflow>) => {
            state.requestStatus = RequestStatus.succeeded
            state.dataset = action.payload;
            state.unsavedChanges = false;
            state.error = undefined
        })
        .addCase(getDatasetById.rejected, (state, action: any) => {
            state.requestStatus = RequestStatus.failed
            state.error = "Get Dataset: " + (action.payload['Message'] || action.error.message);
        })
    }
});


export const {
    setEditDatasetFlag
} = DatasetByIdSlice.actions

export const DatasetByIdReducer = DatasetByIdSlice.reducer

