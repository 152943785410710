import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "./utils/PostRequest"
import { PreviewWorkflowRequest } from "../model/preview-workflow/PreviewWorkflowRequest.model"
import { PreviewWorkflow, PreviewWorkflowStepOutput } from "../model/preview-workflow/PreviewWorkflow.model"
import { RootState } from "../root-redux/RootState"
import { MergeRulePreviewInput } from "../model/preview-mergerule/MergeRulePreviewInput.model"

export const postMergeRulePreview = createAsyncThunk<any,{id:String,subKey:String,mergeruleSetId:String,type:String,limit:String,input: MergeRulePreviewInput,onlyMergeruleSet:boolean},{state: RootState}>('mergerules/postMergeRuleReview', async ({id,subKey,mergeruleSetId,type,limit,input,onlyMergeruleSet},{ getState }) => {
    const payload = input
    const selectedEnv = getState().env.selectedEnvItem
    var queryParam = `?subKey=${subKey}&type=${type}`
    if(limit) {
        queryParam += `&limit=${limit}`
    }
    if(mergeruleSetId) {
        queryParam += `&mergeruleSetId=${mergeruleSetId}`
    }
    if(onlyMergeruleSet) {
        queryParam += `&onlyMergeruleSet=${onlyMergeruleSet}`
    }
    
    const response = await postRequest<String,MergeRulePreviewInput>(`/${selectedEnv}/mergerules/preview/${id}${queryParam}`, payload)
    return response.data
  })

  export const postMergePreviewForRecommendationApi = createAsyncThunk<any,{subKey:String,input: MergeRulePreviewInput, type: string},{state: RootState}>('mergerules/postMergePreviewForRecommendationApi', async ({subKey,input, type},{ getState }) => {
    const payload = input
    const selectedEnv = getState().env.selectedEnvItem
    var queryParam = `?subKey=${subKey}&type=${type}&limit=2&isApiPreview=true`
   
    const response = await postRequest<string,MergeRulePreviewInput>(`/${selectedEnv}/mergerules/preview/all${queryParam}`, payload)
    return response.data
  })