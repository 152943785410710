import React, { useState, useCallback, useEffect } from "react";

import type { JsonGroup, Config, ImmutableTree, BuilderProps, Fields, JsonTree, ListValues, ListItem, Operator } from '@react-awesome-query-builder/ui';
import { Utils as QbUtils, Query, Builder, BasicConfig } from '@react-awesome-query-builder/ui';
import '../common/querybuilder-styles.css'

import { Button, Radio, RadioGroup, TextArea } from "../../common/HarmonyEnablers";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { Workflow, WorkflowSummary } from "../../model/workflows/Workflow.model";
import { useDispatch } from "react-redux";
import { validate } from "uuid";
import { sortByWorkflowName } from "./MergeRuleSetHelper";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
const InitialConfig = BasicConfig;

let config: Config = {
  ...InitialConfig,
  conjunctions: {
    // ...InitialConfig.conjunctions,
    "AND": {
      ...InitialConfig.conjunctions["AND"],
      label: "FIRST",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "FIRST(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "FIRST(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "FIRST(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "LIST": {
      ...InitialConfig.conjunctions["AND"],
      label: "LIST",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "LIST(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "LIST(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "LIST(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "CONCAT": {
      ...InitialConfig.conjunctions["OR"],
      label: "CONCAT",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "CONCAT(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "CONCAT(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "CONCAT(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "MAX": {
      ...InitialConfig.conjunctions["AND"],
      label: "MAX",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "MAX(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "MAX(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "MAX(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "MIN": {
      ...InitialConfig.conjunctions["AND"],
      label: "MIN",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "MIN(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "MIN(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "MIN(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "DATEADD": {
      ...InitialConfig.conjunctions["AND"],
      label: "DATEADD",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "DATEADD(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "DATEADD(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "DATEADD(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "DATEDIFF": {
      ...InitialConfig.conjunctions["AND"],
      label: "DATEDIFF",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "DATEDIFF(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "DATEDIFF(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "DATEDIFF(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "REPLACE": {
      ...InitialConfig.conjunctions["AND"],
      label: "REPLACE",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "REPLACE(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "REPLACE(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "REPLACE(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    "SUBSTRING": {
      ...InitialConfig.conjunctions["AND"],
      label: "SUBSTRING",
      formatConj: function formatConj(children, conj, not, isForDisplay) {
        return children.size > 0 ? "SUBSTRING(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      sqlFormatConj: function sqlFormatConj(children, conj, not) {
        return children.size > 0 ? "SUBSTRING(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
        return children.size > 0 ? "SUBSTRING(" + children.map(e => "{" + e + "}").join(",") + ")" : "()";
      },
      mongoConj: "$and",
    },
    // "CASE": {
    //   ...InitialConfig.conjunctions["AND"],
    //   label: "CASE",
    //   formatConj: function formatConj(children, conj, not, isForDisplay) {
    //     return children.size > 0 ?  "CASE(" + children.join(",") + ")" : "()" ;
    //   },
    //   sqlFormatConj: function sqlFormatConj(children, conj, not) {
    //     return children.size > 0 ?  "CASE(" + children.join(",") + ")" : "()" ;
    //   },
    //   spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
    //     return children.size > 0 ?  "CASE(" + children.join(",") + ")" : "()" ;
    //   },
    //   mongoConj: "$and",
    // },
    // "USE": {
    //   ...InitialConfig.conjunctions["AND"],
    //   label: "USE",
    //   formatConj: function formatConj(children, conj, not, isForDisplay) {
    //     return children.size > 0 ?  "USE(" + children.join(",") + ")" : "()" ;
    //   },
    //   sqlFormatConj: function sqlFormatConj(children, conj, not) {
    //     return children.size > 0 ?  "USE(" + children.join(",") + ")" : "()" ;
    //   },
    //   spelFormatConj: function spelFormatConj(children, conj, not, omitBrackets) {
    //     return children.size > 0 ?  "USE(" + children.join(",") + ")" : "()" ;
    //   },
    //   mongoConj: "$and",
    // },
  },
  settings: {
    ...InitialConfig.settings, showNot: false, forceShowConj: true,
    groupOperators: ["all"]
  },
  operators: {
    ...InitialConfig.operators,
    select_field: {
      ...InitialConfig.operators["equal"],
      label: "Select Field",
      reversedOp: "not_equal",
      sqlOp: "."
    },
    not_equal_NoCase: {
      ...InitialConfig.operators["not_equal"],
      label: "!=IgnoreCase",
      reversedOp: "equal_NoCase",
      sqlOp: "<>NoCase"
    }
  },
  types: {
    ...InitialConfig.types,
    select: {
      ...InitialConfig.types["select"],
      widgets: {
        multiselect: {
          operators: []
        },
        select: {
          operators: ["select_field"]
        }
      }
    },
    text: {
      ...InitialConfig.types["text"],
      widgets: {
        ...InitialConfig.types["text"].widgets,
        text: {
          operators: ["equal", "not_equal", "equal_NoCase", "not_equal_NoCase", "is_empty", "is_not_empty"]
        }
      }
    }
  },
  fields: {
  }
};

const CommandQueryBuilderC: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const [commandError, setCommandError] = useState("")
  const [newconfig, setNewConfig] = useState({ ...config });
  const [limiter, setLimiter] = useState<string>("");
  var singleArgumentCommands = ["FIRST", "LIST", "CONCAT", "MAX", "MIN"]
  var threeArgumentCommands = ["DATEADD", "DATEDIFF", "REPLACE", "SUBSTRING"]

  const {subKey, workflowsBySubKeys, currentCommandType, onQueryReturn, currentJsonTree, handleRequestClose } = props
  const access = CheckPageAccessSelector(subKey)

  useEffect(() => {
    const newFields: Fields = { ...config.fields }
    newFields["StaticText"] = {
      label: "StaticText",
      type: "text",
      valueSources: ['value'],
      operators: ["equal", "not_equal", "equal_NoCase", "not_equal_NoCase", "is_empty", "is_not_empty"]
    }
    var options = ["Days", "Months", "Years"]
    newFields["StaticNumber"] = {
      label: "StaticNumber",
      type: "number",
      valueSources: ['value'],
      operators: ["equal","not_equal"]
    }
    newFields["TodaysDate"] = {
      label: "Today'sDate",
      type: "select",
      valueSources: ['value'],
      defaultValue: "Now",
      fieldSettings: {
        listValues: ["Now"],
      },
      operators: ["select_field"]
    }
    newFields["Calendrical"] = {
      label: "Calendrical",
      type: "select",
      valueSources: ['value'],
      fieldSettings: {
        listValues: options
      }
    }

    var localworkflow = sortByWorkflowName(workflowsBySubKeys)
    localworkflow.forEach((eachWorkflow: WorkflowSummary) => {
      var modelName = eachWorkflow.config.workflowName
      var label = eachWorkflow.config.workflowName
      var fields = eachWorkflow.fields
      var values: any = []
      if (fields && Object.keys(fields).length > 0) {
        Object.keys(fields).map((eachField) => {
          values.push({
            title: eachField,
            value: eachField
          })
        })
      }

      newFields[modelName] = {
        label: label,
        type: "select",
        valueSources: ['value'],
        fieldSettings: {
          listValues: values
        }
      }
    });

    setNewConfig({ ...newconfig, fields: newFields });

  }, [workflowsBySubKeys, currentCommandType, dispatch])

  const parseJsonTree = (jsonTree: string) => {
    const parsedTree: JsonTree = jsonTree ? JSON.parse(jsonTree) : "";
    return parsedTree;
  }

  useEffect(() => {
    const jsonTree: JsonTree = parseJsonTree(currentJsonTree);

    setState({
      tree: QbUtils.checkTree(QbUtils.loadTree(Object.keys(jsonTree).length !== 0 ? jsonTree : { id: QbUtils.uuid(), type: "group" }), newconfig),
      config: newconfig
    })
  }, [newconfig])

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(Object.keys(parseJsonTree(currentJsonTree)).length !== 0 ? parseJsonTree(currentJsonTree) : { id: QbUtils.uuid(), type: "group" }), newconfig),
    config: newconfig
  });

  useEffect(() => {
    setCommandError("")
  }, [state.tree])

  const isNumberOfChildrenValid = (command: string, childrenLength: number) => {
    if (singleArgumentCommands.includes(command)) {
      return childrenLength > 0;
    }
    if (threeArgumentCommands.includes(command)) {
      return childrenLength === 3;
    }
  }

  const dateValidation = (command: any, children: any, conjunctionsAllowedFirst: any, conjunctionsAllowedThird: any) => {
    var i = 1;
    try {
      children.forEach((eachChild: any) => {
        if (i === 1 || i === 3) {
          var commandType = eachChild.type
          if (commandType === "group") {
            var commandInside = eachChild.hasOwnProperty("properties") && eachChild.properties.conjunction !== "AND" ? eachChild.properties.conjunction : "FIRST"
            if (i === 1 && !conjunctionsAllowedFirst.includes(commandInside)) {
              var msg = `Error with command ${command}: First input can be grouped only under one of - ${conjunctionsAllowedFirst.join(" , ")}. Currently it is grouped under ${commandInside}.`
              setCommandError(msg)
              throw "error"
            }
            if (i === 3 && !conjunctionsAllowedThird.includes(commandInside)) {
              var msg = `Error with command ${command}: Third input can be grouped only under one of - ${conjunctionsAllowedThird.join(" , ")}. Currently it is grouped under ${commandInside}.`
              setCommandError(msg)
              throw "error"
            }
          }
          // MRTODO - should we check data type also
        } else if (i === 2) {
          var commandType = eachChild.type
          if (commandType === "rule") {
            var field = eachChild.properties.field
            if (field !== "Calendrical") {
              var msg = `Error with command ${command}: Second input should be a calendrical.`
              setCommandError(msg)
              throw "error"
            }
          } else {
            var msg = `Error with command ${command}: Second input should be a calendrical and a rule.`
            setCommandError(msg)
            throw "error"
          }
        }
        i++
      });
    } catch (e) {
      return false
    }
    return true
  }

  const isInputCorrectForEachCommand = (command: string, children: any) => {
    var output = true
    switch (command) {
      case "DATEADD": {
        var conjunctionsAllowedFirst = ["FIRST", "MAX", "MIN"]
        var conjunctionsAllowedThird = ["FIRST", "MAX", "MIN", "DATEDIFF"]

        output = dateValidation(command, children, conjunctionsAllowedFirst, conjunctionsAllowedThird)
        break;
      }
      case "DATEDIFF": {
        var conjunctionsAllowedFirst = ["FIRST", "MAX", "MIN", "DATEADD"]
        var conjunctionsAllowedThird = ["FIRST", "MAX", "MIN"]
        output = dateValidation(command, children, conjunctionsAllowedFirst, conjunctionsAllowedThird)
        break;
      }
    }
    return output;
  }

  const validateChildren = (tree: any) => {
    var isGroup = tree.type === "group"

    if (isGroup) {
      var command = tree.hasOwnProperty("properties") && tree.properties.conjunction !== "AND" ? tree.properties.conjunction : "FIRST"
      var childrenLength = tree.children1.length
      var lengthCorrect = isNumberOfChildrenValid(command, childrenLength)

      if (!lengthCorrect) {
        var msg = `Error with command ${command}:`
        if (singleArgumentCommands.includes(command)) {
          msg += ` There should be atleast one input for this command.`
        } else {
          msg += ` There should be three inputs for this command.Currently there ${childrenLength === 1 ? `is` : `are`} ${childrenLength} input(s).`
        }
        setCommandError(msg)
        return false;
      } else {
        var isValid = isInputCorrectForEachCommand(command, tree.children1)
        return isValid
      }
    }
    return true
  }

  function iterateTree(current: any, depth: any, isValid: any) {
    try {
      isValid = isValid && validateChildren(current)
      if (!isValid) { throw "error" }
      if (current.type === "group" && isValid) {
        var children = current.children1

        for (var i = 0, len = children.length; i < len; i++) {
          if (children[i].type === "group") {
            isValid = isValid && iterateTree(children[i], depth + 1, isValid);
          }
        }
      }
    } catch (e) {
      return false
    }
    return isValid
  }

  const updateQuery = () => {
    var isValid = iterateTree(QbUtils.getTree(state.tree), 0, true);
    if (isValid) {
      onQueryReturn(QbUtils.sqlFormat(state.tree, state.config)?.replace("\"", "") || "", JSON.stringify(QbUtils.getTree(state.tree) as JsonTree));
    }
  }

  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState(prevState => ({ ...prevState, tree: immutableTree, config: config }));

    const jsonTree = QbUtils.getTree(immutableTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);

  const renderBuilder = useCallback((props: BuilderProps) => (
    <div className="query-builder-container" style={{ padding: "10px" }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  ), []);

  const isDisabled = () => {
    var isError = commandError !== ""
    var output = (QbUtils.sqlFormat(state.tree, state.config)?.replace("\"", "").replace(/['{}]/g, '') || "")
    var isEmpty = output.length <= 0
    var isUnChanged = currentJsonTree && state.tree && currentJsonTree === JSON.stringify(QbUtils.getTree(state.tree) as JsonTree)
    var isNull = JSON.stringify(QbUtils.getTree(state.tree) as JsonTree) && JSON.stringify(QbUtils.getTree(state.tree) as JsonTree).includes("null")
    return isError || isEmpty || isUnChanged || isNull
  }

  return (
    <div>
      <div style={{ fontSize: "13px", color: "red", fontStyle: "italic" }}>{commandError}</div>
      <Query
        {...newconfig}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />

      <div style={{ display: 'flex', width: '100%' }}>
        <div className="query-builder-result" style={{ flex: '1' }}>
          <div> Transformation Configured
            <TextArea disabled={!access.canEdit} style={{ margin: "20px", marginTop: `${commandError.length === 0 ? `20px` : `0px`}`, width: "800px", paddingBottom: "100px", overflowWrap: 'break-word' }} resize="vertical" value={QbUtils.sqlFormat(state.tree, state.config)?.replace("\"", "").replace(/['{}]/g, '').replace(/ . /g,'.').replace(/,/g,", ") || ""} />
          </div>
        </div>
      </div>
      <div>
        <div className="custom-flyinpanel-footer">
          <Button type='button' appearance='primary' onClick={updateQuery} disabled={!access.canEdit || isDisabled()}  >Save</Button>
          <span style={{ marginLeft: '20px' }}></span>
          {(<Button type='button' appearance='secondary' onClick={handleRequestClose}>Cancel</Button>)}
          <span style={{ marginLeft: '20px' }}></span>
        </div>
      </div>
    </div>
  );
};
export const CommandQueryBuilder= withErrorBoundary("Command Query Builder", CommandQueryBuilderC);
