import { MergeRule } from "../merge-rules/MergeRule.model"
import { Insight } from "./Insight.model"
import { Prediction } from "./Prediction.model"
import { Recommendation } from "./Recommendation.model"

export class MergeRulePreviewInput
{
    public tpidList!: string[]
    public predictions!: Prediction[]
    public recommendations!: Recommendation[]
    public insights!: Insight[]
    public mergerule!: MergeRule
}

export const emptyMergeRulePreviewInput :MergeRulePreviewInput = {
    tpidList: [] as string[],
    predictions: [] as Prediction[],
    recommendations: [] as Recommendation[],
    insights: [] as Insight[],
    mergerule: {} as MergeRule
}


    