import { createAsyncThunk } from "@reduxjs/toolkit"
import { CosmosDataCollection } from "../model/workflows/Workflow.model"
import { getRequest } from "./utils/GetRequest"
import { getRequestReleaseManagement } from "./utils/GetRequestReleaseManagement";

export const validateInsightFamily = createAsyncThunk<CosmosDataCollection, string>('workflows/validateCollection', async (family: string) => {
    const response = await getRequest<CosmosDataCollection>(`dev/workflows/validateCollection/${family}`)
    return response.data
  });


  export const validateInsightFamily_uat = createAsyncThunk<CosmosDataCollection, string>('uat/workflows/validateCollection', async (family: string) => {
    const response = await getRequestReleaseManagement<CosmosDataCollection>(`uat/workflows/validateCollection/${family}`)
    return response.data
  });

  export const validateInsightFamily_prod = createAsyncThunk<CosmosDataCollection, string>('prod/workflows/validateCollection', async (family: string) => {
    const response = await getRequestReleaseManagement<CosmosDataCollection>(`prod/workflows/validateCollection/${family}`)
    return response.data
  })