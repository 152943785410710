
import { postRequest } from './utils/PostRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../root-redux/RootState';
import { postRequestReleaseManagement } from './utils/PostRequestReleaseManagement';

export const publishWorkflow = createAsyncThunk<boolean, {workflowId:string, env:string}, { state: RootState }>('workflows/publish', async (workflowDetails, { getState }) => {
  const selectedEnv = getState().env.selectedEnvItem
  var environment = workflowDetails.env == "" ? selectedEnv : workflowDetails.env;
  const response = await postRequest<boolean, null>(`${environment}/workflows/${workflowDetails.workflowId}/publish`, null);
  return response.data;
});

export const publishWorkflowReleaseManagement = createAsyncThunk<boolean, {workflowId:string, env:string}, { state: RootState }>('workflows/publish', async (workflowDetails, { getState }) => {
  var environment = workflowDetails.env;
  const response = await postRequestReleaseManagement<boolean, null>(`${environment}/workflows/${workflowDetails.workflowId}/publish`, null);

  return response.data;
})