import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkflowStageType } from "../../../model/preview-workflow/WorkflowStageType.model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getPreviewWorkflow } from "../../../services/GetPreviewWorkflow.api";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { setIsPanelOpen } from "../../preview-workflow/preview-workflow-redux/PreviewWorkflow.actions.redux";
import { Menu, MenuItem, Icon } from "../SpaAdminScope";
import { addDataSet, deleteDataSet } from "../redux/CampaignById.redux";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { CampaignStageType } from "../../../model/SPAModels/campaigns/CampaignStageType.Model";


const EditCampaignSideBarMenuC = () => {
    // redux states
    const dispatch = useDispatch<AppDispatch>()
    const currStepId = useSelector((state: RootState): string => state.campaignById.currentStepId);
    const campaign = useSelector((state: RootState): Campaign => state.campaignById.campaign!);
    const currStepIndex = useSelector((state: RootState): number => state.campaignById.currentStepIndex);
    const currStage = useSelector((state: RootState): number => state.campaignById.currentStage);
    const stepPreviewStatus = useSelector((state: RootState) => {
        const currStepId = state.workflowById.currentStepId
        const currStage = state.workflowById.currentStage
        switch(currStage) {
                default:
                return {loading: false, error: undefined}
        }
    })
    const enablePreview = (!stepPreviewStatus || (stepPreviewStatus && !stepPreviewStatus.loading)) && currStepId

   

    const onPreviewClick = () => {
        //For future implementation      
    }

    const stopBubbling = (event: any) => {
        if (event.target === event.currentTarget) {
            event.stopPropagation();
        }
    }

    return (
        <Menu>
            <MenuItem onClick={(e: any) => {
                    stopBubbling(e) 
                    dispatch(addDataSet(true))
                }}>
                <Icon slot="start" name="copy" />
                Duplicate
            </MenuItem>
            <MenuItem onClick={(e: any) => {
                stopBubbling(e) 
                dispatch(deleteDataSet())}}>
                <Icon slot="start" name="delete"></Icon>
                Delete
            </MenuItem>
           
            <MenuItem disabled={!enablePreview} onClick={onPreviewClick}>
                <Icon slot='start' name='redeye'></Icon>
                Preview
            </MenuItem>
        </Menu>
    )
}
export const EditCampaignSideBarMenu = withErrorBoundary("EditCampaignSideBarMenu", EditCampaignSideBarMenuC)