import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getCampaignsList } from "../../../services/SPAAdmin/CampaignsList.api";

export interface CampaignListState {
    campaignList: Campaign[],
    status: RequestStatus,
    currPage: number,
    totalPage: number,
    filters: any,
    error: string | undefined
}

const initialState: CampaignListState = {
    campaignList: [],
    status: RequestStatus.idle,
    currPage: 1,
    totalPage: 1,
    filters: {},
    error: ''
}

export const CampaignListSlice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
      changePageNumber: (state, action: PayloadAction<number>) => {
        state.currPage = action.payload
      },
      setCampaignFilters: (state, action: PayloadAction<any>) => {
        state.filters = action.payload;
      }
    },
    extraReducers(builder) {
        builder
            .addCase(getCampaignsList.pending, (state, action) => {
              state.status = RequestStatus.loading
            })
            .addCase(getCampaignsList.fulfilled, (state, action: PayloadAction<Campaign[]>) => {
              state.status = RequestStatus.succeeded
              state.campaignList = action.payload
              state.error = undefined
            })
            .addCase(getCampaignsList.rejected, (state, action: any) => {
              state.status = RequestStatus.failed
              const message = (action?.payload && action.payload["Message"] ? action.payload["Message"] : action.error.message);
              state.error = "Campaigns List: " + message;
            })
    }
});

export const {
  changePageNumber,
  setCampaignFilters
} = CampaignListSlice.actions;

export const campaignListReducer = CampaignListSlice.reducer