import React from "react";
import { createScope, partnerCenterTheme } from "@harmony/enablers/react";
import {
  dataGrid,
  button,
  icon,
  commandBar,
  dropdown,
  menu,
  menuItem,
  searchBox,
  pagination,
  badge,
  hoverCard,
  breadcrumb,
  breadcrumbItem,
} from '@harmony/enablers/react';

const scope = createScope({
  reactInstance: React,
  styles: [partnerCenterTheme]
});

export const DataGrid = scope.forReact(dataGrid);
export const Button = scope.forReact(button);
export const Icon = scope.forReact(icon);
export const CommandBar = scope.forReact(commandBar);
export const Dropdown = scope.forReact(dropdown);
export const Menu = scope.forReact(menu);
export const MenuItem = scope.forReact(menuItem);
export const SearchBox = scope.forReact(searchBox);
export const Pagination = scope.forReact(pagination);
export const Badge = scope.forReact(badge);
export const HoverCard = scope.forReact(hoverCard);
export const Breadcrumb = scope.forReact(breadcrumb);
export const BreadcrumbItem = scope.forReact(breadcrumbItem);