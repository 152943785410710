import { createSelector } from "@reduxjs/toolkit";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { RootState } from "../../root-redux/RootState";

export const authorizationLoaderSelector = createSelector(
  (state: RootState) => state.appPersonas.status,
  (state: RootState) => state.userGroups.status,
  (state: RootState) => state.superAdmin.status,
  (state: RootState) => state.tenantStats.status,
  (appPersonas, userGroups, superAdmin) => {
    return ((appPersonas & (RequestStatus.succeeded | RequestStatus.failed)) === 0 || 
    (userGroups & (RequestStatus.succeeded | RequestStatus.failed)) === 0 || 
    (superAdmin & (RequestStatus.succeeded | RequestStatus.failed)) === 0 )
  })