import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { MergeRule } from "../../model/merge-rules/MergeRule.model";

export interface AddedPriorityFieldsDataGridRow extends Row {
  cells: {
    field1: string,
    field2: string,
    field3: string,
  }
}
export const addedPriorityFieldColumns: Column[] = [
  {
    field: 'field1',
    content: 'Name',
  },
  {
    field: 'field2',
    content: 'Order',
  },
  {
    field: 'field3',
    content: '',
  }
];

export const addedPriorityFieldsItemSelectorData = (data: MergeRuleSet): AddedPriorityFieldsDataGridRow[] => {
  var i = 1;
  var transformedToRowItems: AddedPriorityFieldsDataGridRow[] = []
  var prioriryFieldsunSplit = data?.prioritizePredictionsOnField;
  var priorityFields : string[] = []
  prioriryFieldsunSplit?.map((item: string) => {
    var temp = item.split(' ');
    priorityFields.push(temp[0]);
  })

  if (prioriryFieldsunSplit && priorityFields) {
    priorityFields.forEach((item: string) => {
      transformedToRowItems.push(
        {
          id: i,
          cells: {
            field1: item,
            field2: '',
            field3: ''
          }
        })
      i++;
    });
  }
  return transformedToRowItems
}


export const addedGlobalPriorityFieldsItemSelectorData = (data: MergeRule): AddedPriorityFieldsDataGridRow[] => {
  var i = 1;
  var transformedToRowItems: AddedPriorityFieldsDataGridRow[] = []
  var prioriryFieldsunSplit = data?.prioritizePredictionsOnField;
  var priorityFields : string[] = []
  prioriryFieldsunSplit?.map((item: string) => {
    var temp = item.split(' ');
    priorityFields.push(temp[0]);
  })

  if (prioriryFieldsunSplit && priorityFields) {
    priorityFields.forEach((item: string) => {
      transformedToRowItems.push(
        {
          id: i,
          cells: {
            field1: item,
            field2: '',
            field3: ''
          }
        })
      i++;
    });
  }
  return transformedToRowItems
}