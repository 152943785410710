import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useSelector } from "react-redux";
import { Checkbox, Icon, FlyInPanel, Tooltip, Toggle, Callout } from "../../common/HarmonyEnablers";
import { DataGrid, Button, SearchBox, Dropdown } from '../merge-rules/MergeRulesScope'
import { updateCurrentMergeRuleSet } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { ModelListDataGridRow, addedModelsColumns, addedModelsItemSelectorData, isArraySame } from './MergeRuleSetModelsColumns'
import { ModelDataGridRow, modelColumns, modelsItemSelectorByData } from "./mergerule-panel/ModelListColumns";
import { EditMergeRuleMoveModels } from "./EditMergeRuleMoveModels";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import informationConfig from "../../shared-content/inputDescription";

export const MergeRuleSetModels: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
  const [addedModels, setAddedModels] = useState([] as ModelListDataGridRow[]);
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);

  const [openPanel, setOpenPanel] = useState(false)
  const [modelsListRows, setModelsListRows] = useState([] as ModelDataGridRow[]);
  const [selectedModels, setSelectedModels] = useState([] as string[])
  const [searchText, setSearchText] = useState("")
  const [allselectedModels,setAllSelectedModels] = useState([] as string[])
  const [totalModelCount, setTotalModelCount] = useState(0);

  useEffect(() => {
    if (searchText) {
      setAllSelectedModels(selectedModels)
      var finalRows: ModelDataGridRow[] = []
      modelsListRows.map((row) => {
        if (row.cells?.field1?.toLowerCase().includes(searchText)) {
          finalRows.push(row)
        }
      })
      setModelsListRows(finalRows)
    } else {
        var all = allselectedModels.filter(x => !selectedModels.includes(x));
        var join = selectedModels.concat(all)
        join = join.filter((v, i, a) => a.indexOf(v) == i)
        setSelectedModels(join)
        setModelsListRows(modelsItemSelectorByData(workflowsBySubKeys, currentMergeRuleSet,join)
      )
    }
  }, [searchText])

  useEffect(() => {
    setAddedModels(addedModelsItemSelectorData(currentMergeRuleSet, workflowsBySubKeys))
  }, [currentMergeRuleSet])

  useEffect(() => {
    if (openPanel) {
      var items = modelsItemSelectorByData(workflowsBySubKeys, currentMergeRuleSet,selectedModels)
      setModelsListRows(items)
      setTotalModelCount(items.length)
    }
  }, [openPanel])

  const handleIsPrimaryChange = (rowId: any) => {
    var local = addedModels.map((z) => { return z })
    var priority = currentMergeRuleSet.priority
    var prioritisedModel: string[] = []

    for (var i = 0; i < local.length; i++) {
      if (i === Number(rowId) - 1) {
        local[i].cells.field2 = '1'
        priority = priority.filter(x => x !== local[i].cells.field1)
        prioritisedModel.push(local[i].cells.field1)
      } else {
        local[i].cells.field2 = '-1'
      }
    }
    setAddedModels(local)
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, priority: prioritisedModel.concat(priority) }))
  }
  const handleDeleteModels = (row: any) => {
    var modelNameObj = workflowsBySubKeys.filter(x => { return x.config.workflowName === row.cells.field1 })

    if (modelNameObj.length > 0) {
      var modelName = modelNameObj[0].modelName
      var localMergeModel = currentMergeRuleSet.mergeModel
      localMergeModel = localMergeModel.filter(x => { return x !== modelName })
      var localPriority = currentMergeRuleSet.priority
      localPriority = localPriority.filter(x => { return x !== modelName })
      dispatch(updateCurrentMergeRuleSet({
        ...currentMergeRuleSet,
        mergeModel: localMergeModel,
        priority: localPriority,
      }))
    }
  }

  const handleAddModels = () => {
    setOpenPanel(true)
  }
  const handleRequestClose = (event: any) => {
    if (event.target !== event.currentTarget || event.target === event.currentTarget && event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      setOpenPanel(false)
      setSelectedModels([])
      setAllSelectedModels([])
    }
  }

  const onAddClick = () => {
    var localPriority = currentMergeRuleSet.priority
    var modelsRetainedInOrder = localPriority.filter(x => { return selectedModels.includes(x) })
    var modelsNewlyAdded = selectedModels.filter(x => { return !localPriority.includes(x) })
    var finalPriority = modelsRetainedInOrder.concat(modelsNewlyAdded)
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, mergeModel: finalPriority, priority: finalPriority }))
    setOpenPanel(false)
    setSelectedModels([])
    setAllSelectedModels([])
  }
  const onCancelClick = () => {
    setOpenPanel(false)
    setSelectedModels([])
    setAllSelectedModels([])
  }

  const onRowSelect = (e: any) => {
    var mergedModels = [] as string[]
    for (var i = 0; i < modelsListRows.length; i++) {
      if (modelsListRows[i].selected && modelsListRows[i].cells.field3) {
        mergedModels.push(modelsListRows[i].cells.field3)
      }
    }
      if(modelsListRows.length !== totalModelCount) {
        var allModels = modelsListRows.map(x => x.cells.field3)
        var all = allselectedModels.filter(x => !allModels.includes(x));
        var join = mergedModels.concat(all)
        join = join.filter((v, i, a) => a.indexOf(v) == i)
        setSelectedModels(join)
        var modelsFromListPartOfAllSelectedModels = allModels.filter(x => allselectedModels.includes(x))
        var modelsThatShouldntBeSelectedAnymore = modelsFromListPartOfAllSelectedModels.filter(x => !mergedModels.includes(x))
        var allselectedModelsUpdated = allselectedModels.filter(x => !modelsThatShouldntBeSelectedAnymore.includes(x))
        setAllSelectedModels(allselectedModelsUpdated)
      } else {
        setSelectedModels(mergedModels)
        setAllSelectedModels(mergedModels)
      }
  }

  const handleApplyAllModels = (e: any) => {
    var applyAllModels = currentMergeRuleSet.applyAllModels
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, applyAllModels: !applyAllModels }))
  }

  const checkIfModelsAreDifferent = () => {
    if(currentMergeRuleSet.mergeModel && currentMergeRuleSet.mergeModel.length){
      return isArraySame(currentMergeRuleSet.mergeModel, selectedModels);
    } else{
      return selectedModels.length <= 0
    }
  }
  var msg = currentMergeRuleSet.type === "primary" ? "Recommendations" : "Insights/Recommendations"
  var msgs = currentMergeRuleSet.type === "primary" ? "Recommendation" : "Insight/Recommendation"
  var move = { up: false, down: false, curModel: "" }

  return (
    <>
      <div style={{ flex: "1 1 0%" }}>
        <div style={{ marginBottom: "24px" }}>
          <InformationTextMergeRule text={informationConfig.editMergeRuleSetModels} type="Tab" />
        </div>
        {openPanel &&
          <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)' }}
            open={openPanel}
            heading={`Add ${msg} To Merge`}
            onHeRequestClose={handleRequestClose}>
            <div style={{ marginBottom: "10px", fontSize: "15px" }}>
              <span>Below is the list of {msg} available to choose to merge</span>
              <Icon id="info11" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
              <Tooltip anchor="info11" fixedPlacement={true} placement='end'>
                Insights can only be chosen to merge if RuleSet Type chosen is <b>Enrich Existing Rec</b>. Because Insights cannot generate recommendations on their own
              </Tooltip>
              <div style={{fontWeight:"350"}}><i>NOTE: </i>
              <span style={{fontSize:"14px", fontWeight:"350"}}>Insights/Recommendations unselected and disabled in below list have a configuration missing. Please contact Nebula team to resolve this.</span>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
              <SearchBox style={{ width: "300px", marginLeft: "0px" }} placeholder="Search by Name" onInput={(e: any) => { setSearchText(e.target.value) }} onHeClear={() => { setSearchText("") }}></SearchBox>
            </div>
            <DataGrid fixedHeading={true}
              style={{ marginBottom: "100px" }} rows={modelsListRows} columns={modelColumns} select="multiple" onHeRowSelectChange={(e) => { onRowSelect(e.detail) }}>
              <span slot="no-records">No Insights/Recommendations found.</span>
              {modelsListRows.map(row => {
                return (
                  <>
                    <span slot={`field1-${row.id}`} key={`field1-${row.id}`} >
                      {row.cells.field1}
                    </span>
                    <div slot={`field4-${row.id}`} key={`field4-${row.id}`}>
                      {row.cells.field4}
                    </div>
                  </>
                )
              })}
            </DataGrid>
            <div className="medium-flyinpanel-footer">
              <Button type='button' appearance='primary' disabled={(checkIfModelsAreDifferent()) || selectedModels.length === 0} onClick={onAddClick} >Add</Button>
              <span style={{ marginLeft: '20px' }}></span>
              {(<Button type='button' appearance='secondary' onClick={onCancelClick}>Cancel</Button>)}
              <span style={{ marginLeft: '20px' }}></span>
            </div>
          </FlyInPanel>
        }
        <div>
          <span style={{ marginLeft: "35px" }} >
            <table className="mergerule-form-table">
              <tr>
                <td>
                  Generate Recommendations only if all Insights/Recommendations chosen below contribute input data satisifying the merge criteria
                </td>
                <td>
                  <span style={{ marginLeft: "20px", marginTop: "100px" }}>
                    <Toggle label="" name="applyAllModels" checked={currentMergeRuleSet.applyAllModels} disabled={!access.canEdit} onHeChange={handleApplyAllModels} />
                  </span>
                </td>
              </tr>
            </table>
          </span>

        </div>
        <Button appearance="command" style={{ marginTop: "20px", fontSize: "14px" }} onClick={handleAddModels} disabled={!access.canEdit}>
          <Icon slot="start" name="add"></Icon>
          Add {msg} <Icon className='required-icon' name='actioncenterasterisk'></Icon>
        </Button>
        <Icon id="info10" name="info" style={{ marginTop: "20px", cursor: "pointer", color: "#0078D4" }} />
        <Tooltip anchor="info10" fixedPlacement={true} placement='end'>
          Atleast one {msgs} must be added.
          <br />When more than one Insights/Recommendations are chosen, specify the order in which these should be considered during merge.
        </Tooltip>

        <DataGrid fixedHeading={true}
          style={{ marginBottom: "10px", width: "50%" }} rows={addedModels} columns={addedModelsColumns} >
          <Callout slot="field2-column-header" heading="Specify Order" fixed-placement>
            <Button slot="anchor" appearance="link">
              <Icon name="info" label="More info" style={{ fontSize: '14px' }}></Icon>
            </Button>
            <p>
              When more than one Insights/Reommendations are added, arrange them in the order of priority.<br/>If order is not specified explicitly, the order in which Insights/Recommendations were added will be considered as the order of priority.<br/>All fields in the generated recommendation will be read from the Insight/Recommendation with highest priority.
            </p>
          </Callout>
          <span slot="no-records">No Model added.</span>
          {addedModels.map(row => {
            return (
              <>
                <span slot={`field2-${row.id}`} key={`field2-${row.id}`}>
                  <div style={{ color: 'var(--he-color-primary-700)', display: 'flex', alignItems: 'center' }}>
                    <Dropdown fixedPlacement={true}>
                      <Button appearance="command" slot="trigger" disabled={!access.canEdit}>
                        <Icon label="more info" slot="start" name='more' />
                      </Button>
                      <EditMergeRuleMoveModels subKey={mergeRule.subscriptionKey} moveEnabledProp={{
                        ...move,
                        up: currentMergeRuleSet.priority.indexOf(row.cells.field1) !== 0,
                        down: currentMergeRuleSet.priority.indexOf(row.cells.field1) !== currentMergeRuleSet.priority.length - 1,
                        curModel: row.cells.field1
                      }} />
                    </Dropdown>
                  </div>
                </span>
                <span slot={`field3-${row.id}`} key={`field3-${row.id}`}>
                  <Button appearance="command" style={{ fontSize: "14px" }} disabled={!access.canEdit} onClick={() => handleDeleteModels(row)} >
                    <Icon label="delete" slot="start" name="delete"></Icon>
                  </Button>
                </span>
              </>
            )
          })}
        </DataGrid>
      </div>
    </>
  )
}