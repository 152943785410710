import { ConnectionType } from "../model/connections/ConnectionType.model"

export const ConnectionTypeValue = {
    [ConnectionType.adlsConnections]: 'ADLS',
    [ConnectionType.adlsGen1Connections]: 'ADLSGEN1',
    [ConnectionType.apiConnections]: 'API',
    [ConnectionType.blobConnections]: 'BLOB',
    [ConnectionType.cosmosConnections]: 'COSMOS',
    [ConnectionType.eventConnections]: 'EVENT',
    [ConnectionType.sqlConnections]: 'SQL',
    [ConnectionType.sqlaadConnections]: 'SQLAAD',
    [ConnectionType.topicConnections]: 'Topic',
  }
  
  export const getConnectionTypeKeyFromValue = (connValue: string): ConnectionType | undefined => {
    if (connValue?.toUpperCase() === 'ADLSGEN1') {
      connValue = 'ADLS'
    }
  
    return Object.keys(ConnectionTypeValue).find(
      (key: string) => ConnectionTypeValue[key as ConnectionType]?.toUpperCase() === connValue?.toUpperCase()
    ) as ConnectionType
  }
  
  export const getConnectionTypeFilterValues = () => {
    const filterValues: {[x: string]: boolean} = {}
    Object.values(ConnectionType).forEach((value: ConnectionType) => {
      filterValues[ConnectionTypeValue[value]] = false
    })
    return filterValues
  }