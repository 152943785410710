import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getTestApi } from '../../services/GetTestApi.api'

interface TestApiState {
    status: RequestStatus,
    error: string | undefined,
    errorCode: string | undefined,
    customMsg: string | undefined,
    insights: Object,
    appId: string,
    aud: string,
    typeOftoken: string,
    errorMsg: string
}

const initialState: TestApiState = {
    status: RequestStatus.idle,
    error: '',
    errorCode: '',
    customMsg: '',
    insights: '',
    appId: '',
    aud: '',
    typeOftoken: '',
    errorMsg: ''
}
export const getTestApiSlice = createSlice({
    name: 'gettestapi',
    initialState,
    reducers: {
        resetTestApi: (state) => {
            state.status = RequestStatus.idle
            state.error = ''
            state.errorCode = ''
            state.customMsg = ''
            state.insights = ''
            state.appId = ''
            state.aud = ''
            state.typeOftoken = ''
            state.errorMsg = ''
        },
        updateErrorMsg: (state) => {
            state.errorMsg = ''
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTestApi.pending, (state, action) => {
                state.insights = {} as Object
                state.status = RequestStatus.loading
            })
            .addCase(getTestApi.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload.data?.OperationId) {
                    state.insights = payload.data
                    state.error = payload.data.Message
                    state.errorCode = payload.data.Code
                    state.customMsg = payload.data.CustomMessage
                    state.appId = payload.config.headers.appId
                    state.aud = payload.config.headers.aud
                    state.typeOftoken = payload.config.headers.typeOfToken
                    state.errorMsg = payload.config.headers.errorMsg.includes("Token") ? payload.config.headers.errorMsg : ""
                } else {
                    state.insights = payload.data
                    state.error = undefined
                    state.errorCode = undefined
                    state.customMsg = undefined
                    
                }
            })
            .addCase(getTestApi.rejected, (state, action) => {
                state.insights = {} as Object
                state.status = RequestStatus.failed
                state.error = action.error.message
                state.errorCode = action.error.code
                state.customMsg = undefined
                state.errorMsg = action.error.name?.includes("Token") ? action.error.name : ""
                var details  = action.error.stack?.split("|") || ["","",""]
                state.appId = details[0]
                state.aud = details[1]
                state.typeOftoken = details[2]
            })
    }
})

export const { resetTestApi, updateErrorMsg } = getTestApiSlice.actions


export const getTestApiSliceReducer = getTestApiSlice.reducer