import { MergeCondition } from "./MergeCondition.model"
import {Fields, emptyFields} from './Fields.model'

export class MergeOn {
    public fields!: Fields
    public mergeConditions!: MergeCondition[]
}

export const emptyMergeOn = {
    fields : emptyFields,
    mergeConditions: [] as MergeCondition[]
}