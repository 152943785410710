
export const DownloadCsvFile = async (response: any) => {
    try {
        // Assuming response.data.bytedata contains base64 encoded CSV data
        const base64String = response.data.bytedata;

        // Decode base64 string to a byte array
        const decodedString = atob(base64String);
        const byteArray = new Uint8Array(decodedString.length);
        for (let i = 0; i < decodedString.length; ++i) {
            byteArray[i] = decodedString.charCodeAt(i);
        }

        // Convert the byte array to an ArrayBuffer
        const arrayBuffer = byteArray.buffer;

        // Create a Blob from the ArrayBuffer with CSV content type
        const file = new Blob([arrayBuffer], { type: 'text/csv;charset=utf-8' });

        // Create a download URL for the Blob
        const downloadURL = URL.createObjectURL(file);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = response.data.name; // Set the file name
        link.click();
    } catch (err) {
        console.error('Error downloading CSV:', err);
    }
}

export const ConvertRecordsToCSV = async (records: any[], columns: any[], sourceKeys: any = {}, filename: string = 'datacartrecords.csv') => {
    try {
        if (!Array.isArray(columns) || columns.length === 0) {
            throw new Error('No columns to show in datacart');
        }

        if (!Array.isArray(records) || records.length === 0) {
            throw new Error('No records to download');
        }

        // Create CSV content
        let csvContent = '';

        // Add headers as the first row
        csvContent = columns.map(column => column.displayName).concat(['Source']).join(',') + '\n';

        // Add data rows
        records.forEach(record => {
            const rowData: string[] = columns.map(key => {
                const attribute = record.attributes.find((attr: any) => attr.key === key.fieldName);
                return attribute ? '"'+ attribute.value + '"' : '';
            });
            rowData.push(sourceKeys[record.source.sourceId.toLowerCase()] || '');
            csvContent += rowData.join(',')  + '\n';
        });

        // Create a Blob from the CSV content with the appropriate MIME type
        const file = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

        // Create a download URL for the Blob
        const downloadURL = URL.createObjectURL(file);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename; // Set the file name
        document.body.appendChild(link); // Append the link to the body
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove the link from the body

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(downloadURL);
    } catch (err) {
        console.error('Error downloading CSV:', err);
    }
}
