import { Dropdown, FilterPill } from "./DropdownFilterScope";

export const DropdownFilter: React.FC<{label: string, values: any, children: any, style?: any}> = ({ label, values, children, style }) => {
  // to prevent bubbling of close event for parent elements containing this dropdown
  const handleRequestClose = (event: any) => {
    if (event.target === event.currentTarget && 
      (event.detail.source === 'change' || event.detail.source === 'document' || event.detail.source === 'trigger')) {
      event.stopPropagation();
    }
  }  
  
  return (
    <Dropdown fixedPlacement onHeRequestClose={handleRequestClose} style={style}>    
      <FilterPill slot="trigger" caret values={values} id ="filterpilldropdown">
        {label}
      </FilterPill>
      {children}
    </Dropdown>

  );
}