import axios from 'axios';
import { BASE_URL } from './Constants';
import {v4 as uuidv4} from 'uuid';
import { AuthClientProvider } from '../../auth/AuthClientProvider';
import { Logger } from '../../utils/Logger';

export const deleteRequest = async<T> (apiPath: string) => {
  const correlationId = uuidv4();
  Logger.Instance.trackTrace(`start DeleteRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId,
  });
  try {
    const response = await axios.delete<T>(`${BASE_URL}/${apiPath}`, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId
      },
    });
    Logger.Instance.trackTrace(`Completed DeleteRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
    });
    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error DeleteRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
};
