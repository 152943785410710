
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const MoreSupportC: React.FC<any> = (props: any) => {

  return (
    <>
    
    <div style = {{background:"#f0f0f0", padding:"8px"}}>
        <div style = {{textAlign:"center"}}>
          <h5 id="moresupporTag">More Support Options</h5>
        </div>
        <div style = {{display:"flex", flexDirection:"row", marginTop:"24px", marginBottom:"24px", fontSize:"16px", justifyContent:"space-around"}}>

          <div style = {{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <span className="help-heading" style = {{fontSize:"16px"}}>Contact Support</span>
          <Button appearance="link"> Contact us </Button>
          <Button appearance="link"> Team Info</Button>           
          </div>

          <div style = {{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <span className="help-heading" style = {{fontSize:"16px"}}>Help us improve</span>
          <Button appearance="link"> Submit Feedback </Button>     
          <Button appearance="link"> Report a bug </Button>     
          </div>

          <div style = {{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <span className="help-heading" style = {{fontSize:"16px"}}>More Support</span>
          <Button appearance="link"> Developer's Guide </Button>   
          <Button appearance="link"> Troubleshooting </Button>     
          </div>
        </div>

      </div>

      <div>
        <NebulaInformation/>
      </div>
    </>
  );
};
export const MoreSupport = withErrorBoundary("MoreSupport content", MoreSupportC);