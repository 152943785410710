import { useCallback, useEffect, useState } from "react";
import {
  Select,
  Option,
  TextField,
  Radio,
  RadioGroup,
  DataGridView,
  Checkbox,
  Icon,
  Button,
  Tooltip,
  Accordion,
  AccordionItem,
  CommandBar,
} from "../../../common/HarmonyEnablers";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { Connection } from "../../../model/connections/Connection.model";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { PreviewWorkflow } from "../../../model/preview-workflow/PreviewWorkflow.model";
import { Delta, DeltaMap } from "../../../model/workflows/Delta.model";
import { AttributeDataGridRow, columns, getAttributeGridRows } from "../../publish-workflow/PublishPanelBodyColumn.selectors.redux";
import { setOpenAttributesPanel } from "../../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import React from "react";
import { PublishModel, PublishMap } from "../../../model/workflows/Publish.model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getPreviewWorkflow } from "../../../services/GetPreviewWorkflow.api";
import { validateInsightFamily } from "../../../services/ValidateInsightFamily.api";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Spinner } from "../../../shared-components/loader/Spinner";
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { ConnectionTypeValue, getConnectionTypeKeyFromValue } from "../../../utils/ConnectionTypeUtils";
import { ConnectionProps } from "../../connections/ConnectionProps";
import { ConnectionPanel } from "../../connections/connection-panel/ConnectionPanel";
import { ErrorNotification } from "../../notifications/ErrorNotification";
import { AttributesPanel } from "../../publish-workflow/AttributesPanel";
import { showPreviewLoader } from "../../publish-workflow/Publish.selectors.redux";
import { EditCampaignEmptyStage } from "./EditCampaignEmptyStage";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { saveCampaign, updateCampaignDelta, updateCampaignPublish, } from "../redux/CampaignById.redux";
import { connectionsSelector } from "../redux/Campaign.selector.redux";
import { Helper } from "../utils/Help";
import { DefaultPublishValues } from "../SPAConstants";
import { CheckCampaignPageAccessSelector } from "../redux/CheckCampaignAccess.selectors.redux";

const CampaignPublishC = React.memo(() => {
  // redux states
  const dispatch = useDispatch<AppDispatch>();
  const workflowType = "Campaign";
  const campaign = useSelector(
    (state: RootState): Campaign => state.campaignById.campaign!
  );
  const publishAttributes = useSelector(
    (state: RootState) => state.campaignById.publishAttributes
  );
  const originalWorkflow = useSelector((state: RootState): Campaign => state.campaignById.originalCampaign);
  const previewWorkflow = useSelector(
    (state: RootState): PreviewWorkflow =>
      state.previewWorkflow?.map[campaign.id]
  );
  const dataIngestionStepIdStatusForAttributes = useSelector((state: RootState) => state.previewWorkflow?.dataIngestionStepIdStatusForAtrributes);
  const preProcessingStepIdStatusForAttributes = useSelector((state: RootState) => state.previewWorkflow?.preProcessingStepIdStatusForAtrributes);
  const postProcessingStepIdStatusForAttributes = useSelector((state: RootState) => state.previewWorkflow?.postProcessingStepIdStatusForAtrributes);
  const dataIngestionStepIdStatusForTable = useSelector((state: RootState) => state.previewWorkflow?.dataIngestionStepIdStatusForTable);
  const preProcessingStepIdStatusForTable = useSelector((state: RootState) => state.previewWorkflow?.preProcessingStepIdStatusForTable);
  const postProcessingStepIdStatusForTable = useSelector((state: RootState) => state.previewWorkflow?.postProcessingStepIdStatusForTable);

  const campaignById = useSelector(
    (state: RootState) => state.campaignById
  );

  const currStepId = useSelector(
    (state: RootState): string => state.campaignById.currentStepId
  );
  const currIndex = useSelector(
    (state: RootState): number => state.campaignById.currentStepIndex
  );
  const access = CheckCampaignPageAccessSelector(campaign?.subscriptionKey);
  const connRequestStatus = useSelector(
    (state: RootState) => state.connections.status
  );
  const originalPublishMap = useSelector((state: RootState) => {
    return state.campaignById.originalCampaign?.publish?.find(
      (x) => x.stepID === state.workflowById.currentStepId
    )?.publishMap;
  });
  const appPersonas = useSelector((state: RootState) => state.appPersonas.list);
  const userTeams = useSelector(permissionsSelector).userTeams;

  const [currentDeltaItem, setcurrentDeltaItem] = useState<Delta>({} as Delta);
  const [currentPublishItem, setCurrentPublishItem] = useState(
    {} as PublishModel
  );
  const { connections, appScopes } = useSelector(connectionsSelector);
  const [attributesList, setAttributesList] = useState<[string, string][]>([]);
  const [attributeRows, setAttributeRows] = useState<AttributeDataGridRow[]>(
    []
  );
  const [partitionKey, setPartitionKey] = useState("");
  const [primayColumns, setPrimaryColumns] = useState({
    Reason: "",
    StartOn: "",
    DueInDays: "",
    ExpiryInDays: "",
    Text: "",
  });
  const [panelProps, setPanelProps] = useState({} as ConnectionProps);
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [invalidDatasetName, setInvalidDatasetName] = useState(false);
  const regexViewName = /^[a-zA-Z0-9-_]+$/;
  const regexFamilyName = /^[a-zA-Z0-9-_]+$/;
  const regexNumber = /^[0-9]+$/;
  const [invalidFamily, setInvalidFamily] = useState(false);
  const [currentFamilyName, setCurrentFamilyName] = useState("");
  const [invalidProductFamilyWithTPID, setInvalidProductFamilyWithTPID] = useState(false);

  const inputViewNames: string[] = [];
  const dataTypes = [
    "String",
    "Int32",
    "Int64",
    "Decimal",
    "Date",
    "Boolean",
    "Double",
    "Guid",
  ];

  const primayColumnsListRec = [
    "Text",
    "Reason",
    "StartOn",
    "DueInDays",
    "ExpiryInDays",
  ];
  const primayColumnsListInsight = ["Text"];
  const displayColumnNamesMap: { [key: string]: string } = {
    Text: `Recommendation Title`,
    Reason: "Rationale for Recommendation",
    StartOn: "Effective Date of Recommendation",
    DueInDays: "Days to Act on Recommendation",
    ExpiryInDays: "Days until Recommendation Expires",
  };


  campaign?.dataIngestion?.map((item) => {
    inputViewNames.push(item.outputViewName);
  });

  const [currentInputViewNames, setCurrentInputViewNames] =
    useState<string[]>(inputViewNames);
  //   const [currentInputView, setCurrentInputView] = useState("");
  const connTypeMenItems = Object.values(ConnectionTypeValue)
    .sort()
    .filter((item) => item !== "ADLS" && item !== "SQLAAD");
  const [selectedConnectionProps, setCurrentConnectionProps] = useState({
    id: "",
    name: "",
    type: "",
  });

  useEffect(() => {
    if (currStepId && currStepId.length > 0 && campaign) {
      const currStep = campaign.publish.find(
        (step: PublishModel) => step.stepID === currStepId
      );
      if (currStep) {
        const delta = campaign.delta?.filter(
          (item) => item.inputViewName === currStep!.inputViewName
        );
        if (delta && delta.length > 0) {
          setcurrentDeltaItem(delta[0]);
          updateAttributesList(currStep, delta[0]);
        }
        else {
          updateAttributesList(currStep, {} as Delta);
        }
      }

      setCurrentPublishItem(currStep || ({} as PublishModel));
      updateConnections(currStep || ({} as PublishModel));
    } else {
      setCurrentPublishItem({} as PublishModel);
    }
  }, [currStepId]);

  useEffect(() => {
    if (
      campaign &&
      campaign.publish &&
      currIndex >= 0 &&
      currIndex < campaign.publish.length
    ) {
      const currStep = campaign.publish[currIndex];
      if (currStep) {
        const delta = campaign.delta?.filter(
          (item) => item.inputViewName === currStep.inputViewName
        );
        const isDelta = currStep?.delta;
        if (delta && delta.length > 0) {
          setcurrentDeltaItem(delta[0]);
          updateAttributesList(currStep, delta[0]);
          setCurrentFamilyName(campaign.family);
        } else if (isDelta && !currentDeltaItem?.stepID) {
          const updatedDeltaItem = {
            ...currentDeltaItem,
            connectionId: connections?.blobConnections?.filter(
              (item) => item.connectionName?.toLowerCase() === "nebula-blob"
            )[0]?.connectionId,
            stepID: uuidv4().toString(),
            inputViewName: currentPublishItem.inputViewName,
            appModelName: currentPublishItem.appModelName,
          };

          if (!updatedDeltaItem?.deltaMap) {
            updatedDeltaItem.deltaMap = [] as DeltaMap[];
            if (currentPublishItem.primaryKeys) {
              const primaryKeys = currentPublishItem.primaryKeys.split(",");
              primaryKeys.forEach((key) => {
                const newDeltaMap: DeltaMap = {
                  columnId: uuidv4().toString(),
                  columnName: key,
                  isPrimary: true,
                };
                updatedDeltaItem.deltaMap = [...updatedDeltaItem.deltaMap, newDeltaMap];
              });
            }
          }

          dispatch(updateCampaignDelta(updatedDeltaItem));
          setcurrentDeltaItem(updatedDeltaItem);
          updateAttributesList(currStep, updatedDeltaItem);
        } else {
          updateAttributesList(currStep, {} as Delta);
          setCurrentFamilyName(campaign.family);
        }
      }
      setCurrentPublishItem(currStep || ({} as PublishModel));
      updateConnections(currStep || ({} as PublishModel));
    }
  }, [campaign]);

  useEffect(() => {
    if (
      campaign &&
      campaign.publish &&
      currIndex >= 0 &&
      currIndex < campaign.publish.length
    ) {
      const currStep = campaign.publish[currIndex];
      if (currStep) {
        const delta = campaign.delta?.filter(
          (item) => item.inputViewName === currStep.inputViewName
        );
        if (delta && delta.length > 0) {
          updateAttributesList(currStep, delta[0]);
          setCurrentFamilyName(campaign.family);
        } else {
          updateAttributesList(currStep, {} as Delta);
          setCurrentFamilyName(campaign.family);
        }
      }
    }
  }, [dataIngestionStepIdStatusForAttributes, 
    preProcessingStepIdStatusForAttributes, 
    postProcessingStepIdStatusForAttributes,
    dataIngestionStepIdStatusForTable, 
    preProcessingStepIdStatusForTable, 
    postProcessingStepIdStatusForTable]);

  useEffect(() => {
    if (attributesList.length > 0 && currentPublishItem?.publishMap?.length > 0) {
      var attributes: string[] = attributesList.map(x => x[0]);
      if (currentDeltaItem?.deltaMap?.length > 0) {
        const newDeltaMap = currentDeltaItem.deltaMap.filter(item => {
          return attributes.includes(item.columnName);
        })
        if (areMapsEqual(newDeltaMap.map(item => item.columnName), currentDeltaItem.deltaMap.map(item => item.columnName)) === false) {
          dispatch(
            updateCampaignDelta({
              ...currentDeltaItem,
              deltaMap: newDeltaMap
            })
          );
        }
      }
      const newPublishMap = currentPublishItem.publishMap.filter(item => {
        return attributes.includes(item.source) || item.destination === "Primary.DueInDays" || item.destination === "Primary.ExpiryInDays";
      })
      if (areMapsEqual(newPublishMap.map(item => item.source), currentPublishItem.publishMap.map(item => item.source)) === false) {
        dispatch(
          updateCampaignPublish({
            ...currentPublishItem,
            publishMap: newPublishMap
          })
        );
      }
    }

  }, [attributesList])

  function areMapsEqual(map1: string[], map2: string[]): boolean {
    if (map1.length !== map2.length) {
      return false;
    }

    const set = new Set<string>(map1);

    for (const value of map2) {
      if (!set.has(value)) {
        return false;
      }
    }
    return true;
  }

  const updateAttributesList = (
    publishItem: PublishModel,
    deltaItem: Delta
  ) => {
    if (
      campaign.dataIngestion?.filter(
        (item) => item.outputViewName === publishItem.inputViewName
      ).length > 0 || campaignById.isStandardSPA
    ) {
      // if (
      //   previewWorkflow?.postProcessing &&
      //   previewWorkflow?.postProcessing[publishItem.inputViewName]
      // ) {
      //   setAttributesList(
      //     previewWorkflow?.postProcessing[publishItem.inputViewName]
      //       ?.adbOutputAttributes
      //   );
      // }
      //  if(attributesList.length === 0)
      setAttributesList(publishAttributes);
    }

    const nonPrimaryMaps = publishItem?.publishMap?.filter((item) => {
      if (
        !primayColumnsListRec.includes(
          item.destination.replace("Primary.", "")
        ) &&
        item.destination !== "PartitionKey"
      ) {
        return item;
      }
    });

    if (
      campaign?.workFlowType === workflowType &&
      (!publishItem.delta ||
        !publishItem.connectionId ||
        !publishItem.blobConnectionId) &&
        !campaignById.isStandardSPA
    ) {
      dispatch(
        updateCampaignPublish({
          ...publishItem,
          delta: true,
          connectionId: connections?.cosmosConnections?.filter(
            (item) =>
              item.connectionName?.toLowerCase() === "nebula-prediction-cosmos"
          )[0]?.connectionId,
          blobConnectionId: connections?.blobConnections?.filter(
            (item) => item.connectionName?.toLowerCase() === "nebula-blob"
          )[0]?.connectionId,
        })
      );
    }



    if (deltaItem && !deltaItem.connectionId) {
      dispatch(
        updateCampaignDelta({
          ...deltaItem,
          connectionId: connections?.blobConnections?.filter(
            (item) => item.connectionName?.toLowerCase() === "nebula-blob"
          )[0]?.connectionId,
        })
      );
    }
    setAttributeRows(getAttributeGridRows(deltaItem?.deltaMap, nonPrimaryMaps));
    const partitionKeyMap = publishItem?.publishMap?.filter((item) => {
      if (item.destination === "PartitionKey") {
        return item;
      }
    });
    setPartitionKey(
      partitionKeyMap?.length > 0 && partitionKeyMap[0].fieldType !== "" && partitionKeyMap[0].fieldType !== "None" && partitionKeyMap[0].fieldType !== null && partitionKeyMap[0].fieldType !== undefined ? partitionKeyMap[0].source : ""
    );

    if (partitionKeyMap?.length > 0 && partitionKeyMap[0].source?.toLowerCase() === "tpid" && campaign.family?.toLowerCase() !== "product" && campaign.family?.toLowerCase() !== "investment" && campaign.workFlowType === workflowType) {
      setInvalidProductFamilyWithTPID(true);
    }

    const reasonMap = publishItem?.publishMap?.filter((item) => {
      if (item.destination === "Primary.Reason") {
        return item;
      }
    });

    const startOnMap = publishItem?.publishMap?.filter((item) => {
      if (item.destination === "Primary.StartOn") {
        return item;
      }
    });

    const dueInDaysMap = publishItem?.publishMap?.filter((item) => {
      if (item.destination === "Primary.DueInDays") {
        return item;
      }
    });

    const expiryInDaysMap = publishItem?.publishMap?.filter((item) => {
      if (item.destination === "Primary.ExpiryInDays") {
        return item;
      }
    });

    const textMap = publishItem?.publishMap?.filter((item) => {
      if (item.destination === "Primary.Text") {
        return item;
      }
    });
    setPrimaryColumns({
      Reason: reasonMap?.length > 0 && reasonMap[0].fieldType !== "" && reasonMap[0].fieldType !== "None" && reasonMap[0].fieldType !== null && reasonMap[0].fieldType !== undefined ? reasonMap[0].source : "",
      StartOn: startOnMap?.length > 0 && startOnMap[0].fieldType !== "" && startOnMap[0].fieldType !== "None" && startOnMap[0].fieldType !== null && startOnMap[0].fieldType !== undefined ? startOnMap[0].source : "",
      DueInDays: dueInDaysMap?.length > 0 && dueInDaysMap[0].fieldType !== "" && dueInDaysMap[0].fieldType !== "None" && dueInDaysMap[0].fieldType !== null && dueInDaysMap[0].fieldType !== undefined ? dueInDaysMap[0].source : "",
      ExpiryInDays:
        expiryInDaysMap?.length > 0 && expiryInDaysMap[0].fieldType !== "" && expiryInDaysMap[0].fieldType !== "None" && expiryInDaysMap[0].fieldType !== null && expiryInDaysMap[0].fieldType !== undefined ? expiryInDaysMap[0].source : "",
      Text: textMap?.length > 0 && textMap[0].fieldType !== "" && textMap[0].fieldType !== "None" && textMap[0].fieldType !== null && textMap[0].fieldType !== undefined ? textMap[0].source : "",
    });
  };

  const updateConnections = (currStep: PublishModel) => {
    let connectionType: ConnectionType | undefined;
    let connection: Connection | undefined;
    if (currStep) {
      connectionType = getConnectionTypeKeyFromValue(currStep?.type);
    }

    if (connections && connectionType && currStep) {
      connection = (connections[connectionType] as Connection[])?.find(
        (conn: Connection) => conn.connectionId === currStep.connectionId
      );
    }

    if (connectionType) {
      setCurrentConnectionProps({
        id: connection?.connectionId || "",
        name: connection?.connectionName || "",
        type: ConnectionTypeValue[connectionType],
      });
    }
  };

  const handleInputViewNameChange = (e: any) => {
    dispatch(
      updateCampaignPublish({
        ...currentPublishItem,
        inputViewName: e.target.value,
        publishMap: [],
      })
    );
    dispatch(
      updateCampaignDelta({ ...currentDeltaItem, inputViewName: e.target.value })
    );
  };
  const handleOutputViewNameChange = (e: any) => {
    if (!regexViewName.test(e.target.value)) {
      setErrorText(
        "For dataset names, only alpha numeric values are accepted along with hyphens and underscores. Spaces are not allowed."
      );
      setShowErrorNotif(true);
    }
    dispatch(
      updateCampaignPublish({ ...currentPublishItem, outputViewName: e.target.value })
    );
    setCurrentPublishItem({ ...currentPublishItem, outputViewName: e.target.value })
  };

  const handlePublishMapAttributes = (attributes: [string, string][]) => {
    const updatedPublishItem = { ...currentPublishItem };
    if (!updatedPublishItem?.publishMap) {
      updatedPublishItem.publishMap = [];
    }

    const updatedPublishMap: PublishMap[] = [];

    attributes.forEach((attribute) => {
      const publishMapitem = updatedPublishItem.publishMap.find((item) => {
        if (item.source === attribute[0]) {
          return item;
        }
      });
      updatedPublishMap.push({
        columnId: publishMapitem?.columnId || uuidv4().toString(),
        source: attribute[0],
        fieldType: attribute[1],//typeMapping[attribute[1]],
        destination: attribute[0],
        mapType: "",
        sourceFunction: "",
        isPrimary: publishMapitem?.isPrimary || false,
        dataRetainOnUpdate: publishMapitem?.dataRetainOnUpdate || false,
        deleteState: "",
      });
    });

    // keep the remaining primary columns
    updatedPublishItem.publishMap.forEach((item) => {
      if (
        item.destination === "PartitionKey" ||
        item.destination.includes("Primary")
      ) {
        updatedPublishMap.push(item);
      }
    });

    updatedPublishItem.publishMap = updatedPublishMap;
    setCurrentPublishItem(updatedPublishItem);
    dispatch(updateCampaignPublish(updatedPublishItem));
  };

  const handleRemovePublishMapAttribute = (attribute: string) => {
    const updatedPublishItem = { ...currentPublishItem };
    const newPublishMap: PublishMap[] = updatedPublishItem.publishMap?.filter(
      (item) => item.source != attribute
    );
    updatedPublishItem.publishMap = newPublishMap;
    dispatch(updateCampaignPublish(updatedPublishItem));
  };

  const handleDeltaPullChange = (delta: boolean) => {
    if (currentPublishItem?.delta === delta) {
      return;
    }
    if (delta) {
      dispatch(updateCampaignPublish({ ...currentPublishItem, delta: true }));
    } else {
      dispatch(updateCampaignPublish({ ...currentPublishItem, delta: false }));
      dispatch(updateCampaignDelta({} as Delta));
    }
  };

  const handleDeltaAttributeChange = (
    row: AttributeDataGridRow,
    add: boolean
  ) => {
    if (add) {
      const updatedDeltaItem = { ...currentDeltaItem };
      if (!updatedDeltaItem?.deltaMap) {
        updatedDeltaItem.deltaMap = [] as DeltaMap[];
      }
      const newDeltaMap: DeltaMap = {
        columnId: uuidv4().toString(),
        columnName: row.cells.field1,
        isPrimary: row.cells.field4 === "1",
      };

      updatedDeltaItem.deltaMap = [...updatedDeltaItem.deltaMap, newDeltaMap];
      dispatch(updateCampaignDelta(updatedDeltaItem));
    } else {
      const updatedDeltaItem = { ...currentDeltaItem };
      const mapToDelete = updatedDeltaItem.deltaMap.find(
        (item) => item.columnName === row.cells.field1
      );
      if (mapToDelete) {
        const index = updatedDeltaItem.deltaMap.indexOf(mapToDelete);
        let newMap = [...updatedDeltaItem.deltaMap];
        newMap.splice(index, 1);
        dispatch(updateCampaignDelta({ ...updatedDeltaItem, deltaMap: newMap }));
      }
    }
  };

  const handleIsPrimaryChange = (row: AttributeDataGridRow, isPrimary: boolean) => {
    if (row) {
      const updatedPublishItem = { ...currentPublishItem };
      const updatedDeltaItem = { ...currentDeltaItem };
      let primaryKeys = new Set();
      const updatedPublishMap = updatedPublishItem.publishMap.map((item) => {
        if (item.source === row.cells.field1) {
          return {
            ...item,
            isPrimary: isPrimary,
          };
        }
        return item;
      });
      updatedPublishItem.publishMap = updatedPublishMap;
      updatedPublishItem.publishMap?.forEach((element) => {
        if (element.isPrimary) {
          primaryKeys.add(element.source);
        }
      });

      if (
        currentPublishItem.delta &&
        currentDeltaItem?.inputViewName === currentPublishItem.inputViewName
      ) {
        if (updatedDeltaItem?.deltaMap?.filter((item) => item.columnName === row.cells.field1).length === 0 && row.cells.field4 === "0") {
          const newDeltaMap: DeltaMap = {
            columnId: uuidv4().toString(),
            columnName: row.cells.field1,
            isPrimary: false,
          };
          updatedDeltaItem.deltaMap = [...updatedDeltaItem.deltaMap, newDeltaMap];
        }
        const updatedDeltaMap = updatedDeltaItem.deltaMap?.map((item) => {
          if (item.columnName === row.cells.field1) {
            return {
              ...item,
              isPrimary: isPrimary,
            };
          }
          return item;
        });
        updatedDeltaItem.deltaMap = updatedDeltaMap;
      }
      updatedPublishItem.primaryKeys = "";
      if (primaryKeys.size > 0) {
        updatedPublishItem.primaryKeys = Array.from(primaryKeys).join(",");
      }
      dispatch(updateCampaignDelta(updatedDeltaItem));
      dispatch(updateCampaignPublish(updatedPublishItem));
    }
  };

  const handleDataTypeChange = (e: any, row: AttributeDataGridRow) => {
    if (row) {
      const updatedPublishItem = { ...currentPublishItem };
      const updatedPublishMap = updatedPublishItem.publishMap?.map((item) => {
        if (item.source === row.cells.field1) {
          return {
            ...item,
            fieldType: e.target.value,
          };
        }
        return item;
      });
      updatedPublishItem.publishMap = updatedPublishMap;
      dispatch(updateCampaignPublish(updatedPublishItem));
    }
  };
  const handlePublishToChange = (e: any) => {
    if (currentPublishItem?.publishTo === e.target.value) {
      return;
    }

    let updatedPublishItem = {
      ...currentPublishItem,
      publishTo: e.target.value,
    };
    if (e.target.value === "nebula" || e.target.value === "actioncenter") {
      updatedPublishItem.type = "COSMOS";
      updatedPublishItem.blobConnectionId =
        connections?.blobConnections?.filter(
          (item) => item.connectionName?.toLowerCase() === "nebula-blob"
        )[0]?.connectionId;
      updatedPublishItem.connectionId =
        campaign.workFlowType === "Insight"
          ? connections?.cosmosConnections?.filter(
            (item) =>
              item.connectionName?.toLowerCase() === "nebula-insight-cosmos"
          )[0]?.connectionId
          : connections?.cosmosConnections?.filter(
            (item) =>
              item.connectionName?.toLowerCase() === "nebula-prediction-cosmos"
          )[0]?.connectionId;
    }

    let newPublishStep = {} as PublishModel;

    const updatedWorkflow = { ...campaign };

    if (e.target.value === "actioncenter") {
      const actionPublishAPI = campaign.publish.filter(
        (item) => item.inputViewName === "vw_actionpublish"
      );
      if (actionPublishAPI.length === 0) {
        newPublishStep = {
          stepID: uuidv4().toString(),
          type: "API",
          inputViewName: "vw_actionpublish",
          blobConnectionId: connections?.blobConnections?.filter(
            (item) => item.connectionName?.toLowerCase() === "nebula-blob"
          )[0]?.connectionId,
          connectionId: connections?.apiConnections?.filter(
            (item) => item.connectionName?.toLowerCase() === "actionpublish"
          )[0]?.connectionId,
          publishMap: [],
          primaryKeys: "",
          publishTo: "custom",
          delta: false,
          tableName: "",
          modelId: campaign.config.workflowId,
          outputViewName: currentPublishItem.outputViewName,
          path: "",
          appModelName: "",
          scalaScript: "",
          query: "",
        };
      }
      updatedWorkflow.isActionPublish = true;
      updatedPublishItem.outputViewName = campaign.config.workflowName + "_actionpublish";
      updatedWorkflow.publish = [...updatedWorkflow.publish, newPublishStep];
    } else {
      updatedWorkflow.isActionPublish = false;
      const currIndex = updatedWorkflow.publish.indexOf(
        updatedWorkflow.publish.filter(
          (item) => item.inputViewName === "vw_actionpublish"
        )[0]
      );
      if (currIndex > -1) {
        updatedWorkflow.publish = [
          ...updatedWorkflow.publish.slice(0, currIndex),
          ...updatedWorkflow.publish.slice(currIndex + 1),
        ];
      }
    }
    dispatch(saveCampaign(updatedWorkflow));
    dispatch(updateCampaignPublish(updatedPublishItem));
  };

  async function handleProductFamilyChange(e: any) {
    setCurrentFamilyName(e.target.value.trim());
    if (!regexFamilyName.test(e.target.value.trim())) {
      setInvalidFamily(true);
      return;
    } else {
      setInvalidFamily(false);
    }
    const updatedWorkflow = { ...campaign };
    if (updatedWorkflow.workFlowType === "Insight") {
      const validateFamily = await dispatch(
        validateInsightFamily(e.target.value.trim())
      ).unwrap();
      if (!validateFamily.value) {
        updatedWorkflow.family = e.target.value.trim();
        dispatch(saveCampaign(updatedWorkflow));
      } else {
        setErrorText(
          "Sorry this product family is not available. Please choose another product family."
        );
        setShowErrorNotif(true);
      }
    }
    // if recommendation
    else {
      setInvalidProductFamilyWithTPID(false);
      if (e.target.value.trim()?.toLowerCase() === "product" || e.target.value.trim()?.toLowerCase() === "investment") {
        if (partitionKey?.toLowerCase() !== "tpid" && attributesList.filter(item => item[0]?.toLowerCase() === "tpid").length > 0) {
          const updatedPublishItem = { ...currentPublishItem };
          if (updatedPublishItem.publishMap?.filter(
            (item) => item.destination === "PartitionKey"
          ).length !== 0) {
            const updatedPublishMap = updatedPublishItem.publishMap?.map((item) => {
              if (item.destination === "PartitionKey") {
                return {
                  ...item,
                  source: attributesList.filter(item => item[0]?.toLowerCase() === "tpid")[0][0],
                };
              }
              return item;
            });
            updatedPublishItem.publishMap = updatedPublishMap;
          }
          if (
            updatedPublishItem.publishMap?.filter(
              (item) => item.source?.toLowerCase() === "tpid"
            ).length === 0
          ) {
            const newPublishMap: PublishMap = {
              columnId: uuidv4().toString(),
              source: "TPID",
              fieldType: attributesList.filter(item => item[0]?.toLowerCase() === "tpid")[0][1],
              destination: "TPID",
              mapType: "",
              sourceFunction: "",
              isPrimary: false,
              dataRetainOnUpdate: false,
              deleteState: "",
            };
            updatedPublishItem.publishMap = [
              ...updatedPublishItem.publishMap,
              newPublishMap,
            ];
          }

          updatedWorkflow.family = e.target.value.trim();
          dispatch(saveCampaign(updatedWorkflow));
          dispatch(updateCampaignPublish(updatedPublishItem));
        }
        else if (partitionKey?.toLowerCase() !== "tpid" && attributesList.filter(item => item[0]?.toLowerCase() === "tpid").length === 0) {
          setErrorText(
            "This product family can only be chosen if dataset has TPID column"
          );
          setShowErrorNotif(true);
        }
        else if (partitionKey?.toLowerCase() === "tpid") {
          updatedWorkflow.family = e.target.value.trim();
          dispatch(saveCampaign(updatedWorkflow));
        }
      }
      else {
        if (partitionKey?.toLowerCase() === "tpid") {
          setInvalidProductFamilyWithTPID(true);
        }
        updatedWorkflow.family = e.target.value.trim();
        dispatch(saveCampaign(updatedWorkflow));
      }

    }
  }

  const handleMandatoryColumns = (
    attribute: [string, string],
    destination: string
  ) => {
    const updatedPublishItem = { ...currentPublishItem };
    if (!updatedPublishItem?.publishMap) {
      updatedPublishItem.publishMap = [];
    }
    if (destination === "PartitionKey") {
      if (attribute[0]?.toLowerCase() !== "tpid" && (currentFamilyName?.toLowerCase() === "product" || currentFamilyName?.toLowerCase() === "investment")) {
        setErrorText(
          "The custom index key can only be chosen as TPID if family is Product or Investment"
        );
        setShowErrorNotif(true);
        setCurrentFamilyName("");
        const updatedWorkflow = { ...campaign };
        updatedWorkflow.family = "";
        dispatch(saveCampaign(updatedWorkflow));
      }
    }
    if (
      updatedPublishItem.publishMap?.filter(
        (item) => item.source === attribute[0]
      ).length === 0 && destination !== "Primary.DueInDays" && destination !== "Primary.ExpiryInDays"
    ) {
      const newPublishMap: PublishMap = {
        columnId: uuidv4().toString(),
        source: attribute[0],
        fieldType: attribute[1],
        destination: attribute[0],
        mapType: "",
        sourceFunction: "",
        isPrimary: false,
        dataRetainOnUpdate: false,
        deleteState: "",
      };
      updatedPublishItem.publishMap = [
        ...updatedPublishItem.publishMap,
        newPublishMap,
      ];
    }
    if (
      updatedPublishItem.publishMap?.filter(
        (item) => item.destination === destination
      ).length > 0
    ) {
      const updatedPublishMap = updatedPublishItem.publishMap?.map((item) => {
        if (item.destination === destination) {
          return {
            ...item,
            source: attribute[0],
            mapType: (destination === "Primary.DueInDays" || destination === "Primary.ExpiryInDays") && regexNumber.test(attribute[0]) ? "Static" : "",
            fieldType: destination === "Primary.DueInDays" || destination === "Primary.ExpiryInDays" ? "Int32" : attribute[1],
          };
        }
        return item;
      });
      updatedPublishItem.publishMap = updatedPublishMap;
    } else {
      const newPublishMap: PublishMap = {
        columnId: uuidv4().toString(),
        source: attribute[0],
        fieldType: destination === "Primary.DueInDays" || destination === "Primary.ExpiryInDays" ? "Int32" : dataTypes[0],
        destination: destination,
        mapType: (destination === "Primary.DueInDays" || destination === "Primary.ExpiryInDays") && regexNumber.test(attribute[0]) ? "Static" : "",
        sourceFunction: "",
        isPrimary: false,
        dataRetainOnUpdate: false,
        deleteState: "",
      };
      updatedPublishItem.publishMap = [
        ...updatedPublishItem.publishMap,
        newPublishMap,
      ];
    }

    dispatch(updateCampaignPublish(updatedPublishItem));
  };

  const handleDeleteSelectedAttribute = (attribute: string) => {
    let updatedAttributesList: [string, string][] = [];
    attributeRows.forEach((row) => {
      if (row.cells.field1 !== attribute) {
        updatedAttributesList.push([row.cells.field1, row.cells.field3]);
      }
    });
    // updatedAttributesList = updatedAttributesList.filter( (item) => item.cells.field1 !== attribute);
    // setAttributesList(updatedAttributesList);
    handlePublishMapAttributes(updatedAttributesList);
  };

  const getPrimaryColumnValue = (col: string) => {
    switch (col) {
      case "Reason":
        return primayColumns.Reason;
      case "DueInDays":
        return campaignById.isStandardSPA ? DefaultPublishValues.DaysToAct : primayColumns.DueInDays;
      case "ExpiryInDays":
        return campaignById.isStandardSPA ? DefaultPublishValues.DaysToExpire : primayColumns.ExpiryInDays;
      case "StartOn":
        return campaignById.isStandardSPA ? DefaultPublishValues.EffectiveDate : primayColumns.StartOn;
      case "Text":
        return primayColumns.Text;
    }
  };

  const handleConnTypeMenuChange = (e: any) => {
    setCurrentConnectionProps({ id: "", name: "", type: e.target.textContent });
    dispatch(
      updateCampaignPublish({
        ...currentPublishItem,
        type: e.target.textContent,
        blobConnectionId: connections?.blobConnections?.filter(
          (item) => item.connectionName?.toLowerCase() === "nebula-blob"
        )[0]?.connectionId,
        connectionId: "",
        outputViewName: e.target.textContent === "API" ? campaign.config.workflowName + "_API" : "",
      })
    );
  };

  const handleConnMenuChange = (e: any) => {
    // keeping the connection type same and updating only the connection name
    setCurrentConnectionProps({
      ...selectedConnectionProps,
      id: e.target.value,
      name: e.target.textContent,
    });
    if (currentPublishItem.type?.toUpperCase() === "BLOB") {
      dispatch(
        updateCampaignPublish({
          ...currentPublishItem,
          blobConnectionId: e.target.value,
          connectionId: e.target.value,
        })
      );
    } else {
      dispatch(
        updateCampaignPublish({ ...currentPublishItem, connectionId: e.target.value })
      );
    }
  };

  const handlePathChange = (e: any) => {
    dispatch(updateCampaignPublish({ ...currentPublishItem, path: e.target.value }));
  };

  const handleTableNameChange = (e: any) => {
    dispatch(
      updateCampaignPublish({ ...currentPublishItem, tableName: e.target.value })
    );
  };
  const editConnection = () => {
    let connectionType: ConnectionType | undefined;
    let connection: Connection | undefined;
    if (selectedConnectionProps) {
      connectionType = getConnectionTypeKeyFromValue(
        selectedConnectionProps.type
      );
    }

    if (connections && connectionType && selectedConnectionProps) {
      connection = (connections[connectionType] as Connection[])?.find(
        (conn: Connection) => conn.connectionId === selectedConnectionProps.id
      );

      if (connection) {
        setPanelProps({
          connectionData: {
            ...connection,
            subscriptionKey: campaign?.subscriptionKey,
          },
          connectionType,
          appScopes,
          appPersonas,
          userTeams,
        });
      }
    }
  };

  const connectionMenuItems = selectedConnectionProps.type && connections ? connections[
      getConnectionTypeKeyFromValue(selectedConnectionProps.type) as ConnectionType
    ] : [];

  if (!currentPublishItem || !currentPublishItem.stepID) {
    return <EditCampaignEmptyStage />;
  }

  let attributesLoaderText = <></>;
  if (showPreviewLoader(
    currentPublishItem, 
    dataIngestionStepIdStatusForAttributes, 
    preProcessingStepIdStatusForAttributes, 
    postProcessingStepIdStatusForAttributes,
    dataIngestionStepIdStatusForTable, 
    preProcessingStepIdStatusForTable, 
    postProcessingStepIdStatusForTable)) {
    attributesLoaderText = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Spinner type={"small"} />{" "}
        <span
          style={{
            marginLeft: "10px",
            fontSize: "14px",
            color: "var(--he-color-neutral-700)",
          }}
        >
          loading attributes...
        </span>
      </div>
    );
  } else if (!currentPublishItem || !currentPublishItem.inputViewName) {
    attributesLoaderText = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            marginLeft: "10px",
            fontSize: "14px",
            color: "var(--he-color-neutral-700)",
          }}
        >
          Select input dataset to load attributes
        </span>
      </div>
    )
  } else if (!attributesList || attributesList.length === 0) {
    <div>
      <span
        style={{
          fontSize: "14px",
          color: "var(--he-color-neutral-700)",
        }}
      >
        Attributes were not loaded. Reload attributes
      </span>
      <Button
        appearance="command"
        onClick={() => dispatch(getPreviewWorkflow({ runPrevStage: true, clickedNext: false, loadPreviewTable: false }))}
      >
        <Icon name="refresh" />
      </Button>
    </div>;
  }

  return (
    <>
      <div>
        {" "}
        <ErrorNotification
          errorNotif={showErrorNotif}
          seterrornotif={setShowErrorNotif}
          errorText={errorText}
          duration={100000}
        />{" "}
      </div>

      <div style={{ fontSize: "15px", fontWeight: "500" }}>
        Final Dataset:
      </div>
      <div className="label-input-wrapper-no-border">
        <div className="label-input">
          <div style={{ marginRight: "30px", width: '200px' }}>
            Select Dataset to Publish
            <Icon className="required-icon" name="actioncenterasterisk"></Icon>
          </div>
          <Icon
            name="leftarrowkeytime0"
            className="input-output-arrow-icon"
            style={{ marginRight: "10px" }}
          ></Icon>
          <Select
            disabled={!access.canEdit || campaignById.isStandardSPA}
            value={currentPublishItem?.inputViewName}
            slot="action"
            fixed-placement
            className="Text-fields"
            placeholder="select"
            name="inputViewName"
            required
          >
            {currentInputViewNames?.map((item, idx) => (
              <Option
                key={item}
                role="menuitemcheckbox"
                onHeSelected={handleInputViewNameChange}
                value={item}
                selected={campaignById.isStandardSPA && idx === 0}
              >
                {item}
              </Option>
            ))}
          </Select>
        </div>
        {currentPublishItem.publishTo === "actioncenter" ||
          currentPublishItem.type === "API" ?
          <div className="label-input" style={{ marginRight: "100px" }}>
            <div style={{ marginRight: "30px" }}>
              Name the final publish output data set
              {currentPublishItem.publishTo === "actioncenter" ||
                currentPublishItem.type === "API" ? (
                <Icon
                  className="required-icon"
                  name="actioncenterasterisk"
                ></Icon>
              ) : (
                <></>
              )}
            </div>
            <Icon
              name="rightarrowkeytime0"
              className="input-output-arrow-icon"
              style={{ marginRight: "10px" }}
            ></Icon>
            <TextField
              disabled={!access.canEdit}
              className={"Text-fields"}
              name="outputViewName"
              value={currentPublishItem?.outputViewName}
              onHeChange={handleOutputViewNameChange}
            />
          </div>
          : <></>}
      </div>

      <div style={{ display: "flex" }}>
        <div>
          <div>
            <Accordion>
              <AccordionItem>
                <div
                  slot="heading"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      marginLeft: "15px",
                    }}
                  >
                    Map these essential attributes to generate {campaign.workFlowType}
                    <Icon
                      className="required-icon"
                      name="actioncenterasterisk"
                    />
                  </div>
                  {!campaignById.isStandardSPA && attributesLoaderText}
                </div>
                <div
                  className="label-input-wrapper-no-border"
                  style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                >
                  <div className="label-input">
                    <div style={{ marginRight: "30px", fontWeight: "500", width: "350px" }}>
                      Attribute
                    </div>
                    <div style={{ fontWeight: "500" }}>
                      Select fields from input dataset
                    </div>
                  </div>
                </div>
                <div
                  className="label-input-wrapper-no-border"
                  style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                >
                  <div className="label-input">
                    <div style={{ marginRight: "30px", width: "350px" }}>
                      Custom Indexing Field
                      <Icon
                        className="required-icon"
                        name="actioncenterasterisk"
                      ></Icon>
                      <Icon
                        id="info35"
                        name="info"
                        style={{
                          marginLeft: "7px",
                          cursor: "pointer",
                          color: "#0078D4",
                        }}
                      />
                      <Tooltip
                        anchor="info35"
                        fixedPlacement={true}
                        placement="bottom"
                      >
                        Choose the field that would be used for indexing.
                      </Tooltip>
                    </div>
                    <Select
                      disabled={!access.canEdit || campaignById.isStandardSPA}//|| showPreviewLoader(currentPublishItem, dataIngestionStepIdStatus, preProcessingStepIdStatus, postProcessingStepIdStatus)}
                      value={campaignById.isStandardSPA ? DefaultPublishValues.IndexField : partitionKey}
                      slot="action"
                      fixed-placement
                      style={{ marginRight: "20px", width: "200px" }}
                      placeholder={partitionKey || "select"}
                      name="partitionKey"
                      required
                    >
                      {attributesList?.map((item) => (
                        <Option
                          key={item[0]}
                          role="menuitemcheckbox"
                          onHeSelected={() =>
                            handleMandatoryColumns(item, "PartitionKey")
                          }
                          value={item[0]}
                          selected={campaignById.isStandardSPA && item[0] === "TPID"}
                        >
                          {item[0]}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                {campaign.workFlowType === workflowType
                  ? primayColumnsListRec?.map((col) => (
                    <div
                      className="label-input-wrapper-no-border"
                      style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div className="label-input">
                        <div style={{ marginRight: "30px", width: "350px" }}>
                          {displayColumnNamesMap[col]}
                          <Icon
                            className="required-icon"
                            name="actioncenterasterisk"
                          ></Icon>
                          <Icon
                            id={col}
                            name="info"
                            style={{
                              marginLeft: "7px",
                              cursor: "pointer",
                              color: "#0078D4",
                            }}
                          />
                          <Tooltip
                            anchor={col}
                            fixedPlacement={true}
                            placement="bottom"
                          >
                            {col == "Text" ? "Select the field to present as the recommendation text."
                              : (col == "Reason" ? "Pick the field that will be displayed as the reason for the recommendation."
                                : (col == "StartOn" ? "Select the field that maps to the recommendation's effective date. E.g., [Date Field]"
                                  : (col == "DueInDays" ? "Enter the count of days from when the recommended action is due."
                                    : (col == "ExpiryInDays" ? "Enter the count of days until the recommended action expires. E.g., [90]"
                                      : ""))))}
                          </Tooltip>
                        </div>
                        {col === "DueInDays" || col === "ExpiryInDays" ? (
                          <TextField
                            disabled={!access.canEdit || campaignById.isStandardSPA}
                            value={getPrimaryColumnValue(col)}
                            style={{ marginRight: "20px", width: "200px" }}
                            placeholder={
                              getPrimaryColumnValue(col) || "Enter days"
                            }
                            name={"primary" + col}
                            required
                            onHeChange={(e: any) =>
                              handleMandatoryColumns(
                                [e.target.value, 'int64'],
                                "Primary." + col
                              )
                            }
                          />
                        ) : (
                          <Select
                            disabled={!access.canEdit || campaignById.isStandardSPA}//|| showPreviewLoader(currentPublishItem, dataIngestionStepIdStatus, preProcessingStepIdStatus, postProcessingStepIdStatus)}
                            value={getPrimaryColumnValue(col)}
                            slot="action"
                            fixed-placement
                            style={{ marginRight: "20px", width: "200px" }}
                            placeholder={
                              getPrimaryColumnValue(col) || "select"
                            }
                            name={"primary" + col}
                            required
                          >
                            {attributesList?.map((item) => (
                              <Option
                                key={item[0]}
                                role="menuitemcheckbox"
                                onHeSelected={() =>
                                  handleMandatoryColumns(
                                    item,
                                    "Primary." + col
                                  )
                                }
                                value={item[0]}
                                selected={campaignById.isStandardSPA && item[0] === 'StartOn' && col === 'StartOn'}
                              >
                                {item[0]}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </div>
                    </div>
                  ))
                  : primayColumnsListInsight?.map((col) => (
                    <div
                      className="label-input-wrapper-no-border"
                      style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div className="label-input">
                        <div style={{ marginRight: "30px", width: "350px" }}>
                          {col}
                          <Icon
                            className="required-icon"
                            name="actioncenterasterisk"
                          ></Icon>
                          <Icon
                            id={col}
                            name="info"
                            style={{
                              marginLeft: "7px",
                              cursor: "pointer",
                              color: "#0078D4",
                            }}
                          />
                          <Tooltip
                            anchor={col}
                            fixedPlacement={true}
                            placement="bottom"
                          >
                            {
                              campaign.workFlowType === workflowType
                                ? "Choose the field to be presented as the recommendation text."
                                : "Choose the field to be presented as the insight text."
                            }
                          </Tooltip>
                        </div>
                        <Select
                          disabled={!access.canEdit}//|| showPreviewLoader(currentPublishItem, dataIngestionStepIdStatus, preProcessingStepIdStatus, postProcessingStepIdStatus)}
                          value={getPrimaryColumnValue(col)}
                          slot="action"
                          fixed-placement
                          style={{ marginRight: "20px", width: "200px" }}
                          placeholder={getPrimaryColumnValue(col) || "select"}
                          name={"primary" + col}
                          required
                        >
                          {attributesList?.map((item) => (
                            <Option
                              key={item[0]}
                              role="menuitemcheckbox"
                              onHeSelected={() =>
                                handleMandatoryColumns(item, "Primary." + col)
                              }
                              value={item[0]}
                            >
                              {item[0]}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  ))}
                <div
                  className="label-input-wrapper-no-border"
                  style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                >
                  <div className="label-input">
                    <div style={{ marginRight: "30px", width: "350px" }}>
                      {
                        campaign.workFlowType === workflowType ? "Family" : "Route for Insight"

                      }

                      <Icon
                        className="required-icon"
                        name="actioncenterasterisk"
                      ></Icon>
                      <Icon
                        id="info36"
                        name="info"
                        style={{
                          marginLeft: "7px",
                          cursor: "pointer",
                          color: "#0078D4",
                        }}
                      />
                      <Tooltip
                        anchor="info36"
                        fixedPlacement={true}
                        placement="bottom"
                      >
                        {
                          campaign.workFlowType === workflowType
                            ? "Family defines the type of the recommendation. Choose a key crucial for Nebula's internal purposes. If Product/Investment is chosen as product family, then the unique key must be TPID."
                            : "Choose a key crucial for Nebula's internal purposes. ... [Fields Description]. Family once added cannot be edited"
                        }
                      </Tooltip>
                    </div>
                    <TextField
                      disabled={!access.canEdit || campaignById.isStandardSPA || (campaign.workFlowType === "Insight" && originalWorkflow.family !== "" && originalWorkflow.family !== null && originalWorkflow.family !== undefined)}
                      label="Route for Recommendation/Insight"
                      className="Text-fields"
                      style={{ width: "200px" }}
                      name="family"
                      value={campaignById.isStandardSPA ? 'Product' : currentFamilyName}
                      onHeChange={handleProductFamilyChange}
                    />

                  </div>

                </div>
                {invalidFamily && <p style={{ fontSize: "14px", fontWeight: "450", color: "#b10418", marginLeft: '380px' }}>Only alpha numeric values accepted along with hypens and underscores. Spaces are not allowed.</p>}
                {!invalidFamily && invalidProductFamilyWithTPID && <p style={{ fontSize: "14px", fontWeight: "450", color: "#b10418", marginLeft: '380px' }}>Supported values for Family are Product & Investment. <br />For any other values, please update Nebula Engineering Team.</p>}

                <div className="publish-radio" style={{ marginTop: '20px' }}>
                  <div>
                    Generate delta or full data refresh on each run of the
                    workflow{" "}
                    <Icon
                      className="required-icon"
                      name="actioncenterasterisk"
                    ></Icon>
                  </div>
                  <RadioGroup
                    value={currentPublishItem.delta || campaignById.isStandardSPA ? "delta" : "full"}
                  >
                    <div>
                      <Radio
                        disabled={campaign?.workFlowType === "Insight" || !access.canEdit || campaignById.isStandardSPA}
                        value="delta"
                        onHeSelected={() => handleDeltaPullChange(true)}
                      >
                        Delta
                      </Radio>
                      <Radio
                        value="full"
                        onHeSelected={() => handleDeltaPullChange(false)}
                        disabled={
                          campaign?.workFlowType === workflowType ||
                          !access.canEdit || campaignById.isStandardSPA
                        }
                      >
                        Full
                      </Radio>
                    </div>
                  </RadioGroup>
                </div>
              </AccordionItem>
              <AccordionItem>
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                    marginLeft: "15px",
                  }}
                  slot="heading"
                >
                  Add final set of attributes to publish and update constraints
                  <Icon className="required-icon" name="actioncenterasterisk" />
                  <Icon
                    id="publishattributesinfoicon"
                    name="info"
                    style={{
                      marginLeft: "7px",
                      cursor: "pointer",
                      color: "#0078D4",
                    }}
                  />
                  <Tooltip
                    anchor="publishattributesinfoicon"
                    fixedPlacement={true}
                    placement="bottom"
                  >
                    Set atleast one of the attributes as primary key which
                    should be included in delta if Delta is choosen.
                  </Tooltip>

                </div>
                <CommandBar>
                  <Button
                    disabled={!access.canEdit || campaignById.isStandardSPA} //|| showPreviewLoader(currentPublishItem, dataIngestionStepIdStatus, preProcessingStepIdStatus, postProcessingStepIdStatus)}
                    appearance="command"
                    onClick={() => dispatch(setOpenAttributesPanel(true))}
                  >
                    <Icon slot="start" name="add"></Icon>
                    Add attributes
                  </Button>
                </CommandBar>
                <hr />
                <DataGridView
                  style={{ marginLeft: "3px" }}
                  fixedHeading={true}
                  rows={attributeRows}
                  columns={columns}
                  className="attribute-grid"
                >
                  {attributeRows?.map((row) => {
                    return (
                      <>
                        <span
                          slot={`field2-${row.id}`}
                          key={`field2-${row.id}`}
                        >
                          <Checkbox
                            checked={row.cells.field2 === "1" || (campaignById.isStandardSPA && row.cells.field1 === 'TPID')}
                            onHeChange={() =>
                              handleDeltaAttributeChange(
                                row,
                                !(row.cells.field2 === "1")
                              )
                            }
                            disabled={
                              !currentPublishItem.delta || !access.canEdit || campaignById.isStandardSPA
                            }
                          >
                            {row.cells.field2 === "1" ? 'Selected attribute for data refresh' : 'Not selected attribute for data refresh'}
                          </Checkbox>
                        </span>

                        <span
                          slot={`field3-${row.id}`}
                          key={`field3-${row.id}`}
                        >
                          <div style={{ color: "var(--he-color-primary-700)" }}>
                            <Select
                              disabled={!access.canEdit || campaignById.isStandardSPA}
                              slot="action"
                              fixed-placement
                              style={{ marginRight: "20px", width: "200px" }}
                              label=""
                              placeholder="select"
                              value={
                                row.cells.field3
                              }
                              name={row.id.toString()}
                            >
                              {dataTypes?.map((item) => (
                                <Option
                                  key={item}
                                  role="menuitemcheckbox"
                                  onHeSelected={(e: any) =>
                                    handleDataTypeChange(e, row)
                                  }
                                  value={item}
                                >
                                  {item}
                                </Option>
                              ))}
                            </Select>
                            {dataTypes.find(
                              (item) => item === row.cells.field3
                            ) ? <></> : <span style={{ color: "#b10418" }}>
                              Please select a valid data type
                            </span>

                            }
                          </div>
                        </span>
                        <span
                          slot={`field4-${row.id}`}
                          key={`field4-${row.id}`}
                        >
                          <Checkbox
                            disabled={!access.canEdit || campaignById.isStandardSPA}
                            checked={row.cells.field4 === "1"}
                            onHeChange={() => handleIsPrimaryChange(row, !(row.cells.field4 === "1"))}
                          >
                            {row.cells.field4 === "1" ? 'Set as primary key' : 'Not set as primary key'}
                          </Checkbox>
                          <span style={{ marginLeft: '150px', fontSize: '20px', color: 'var(--he-color-primary-700)' }}>
                            <Button
                              appearance="command"
                              disabled={campaignById.isStandardSPA}
                              onClick={() =>
                                handleDeleteSelectedAttribute(row.cells.field1)
                              }
                            >
                              <Icon label="delete" name="delete" />
                            </Button>
                          </span>
                        </span>

                      </>
                    );


                  })}
                </DataGridView>
              </AccordionItem>
            </Accordion>

            <div className="publish-radio" style={{ margin: "20px 0 20px" }}>
              <div>
                Publish to?{" "}
                <Icon
                  className="required-icon"
                  name="actioncenterasterisk"
                ></Icon>
              </div>
              <RadioGroup value={currentPublishItem.publishTo}>
                <div>
                  <Radio
                    disabled={!access.canEdit || campaignById.isStandardSPA}
                    value="nebula"
                    style={{ marginRight: "20px" }}
                    onHeSelected={handlePublishToChange}
                  >
                    Nebula
                  </Radio>
                  <Radio
                    value="actioncenter"
                    style={{ marginRight: "20px" }}
                    onHeSelected={handlePublishToChange}
                    disabled={
                      campaign?.workFlowType === workflowType ||
                      !access.canEdit || campaignById.isStandardSPA
                    }
                  >
                    Action Store
                  </Radio>
                  <Radio value="custom" onHeSelected={handlePublishToChange} disabled={campaignById.isStandardSPA}>
                    Custom
                  </Radio>
                </div>
              </RadioGroup>
            </div>
            <br />
          </div>
        </div>
      </div>
      {currentPublishItem.publishTo === "custom" ? (
        <>
          <div
            style={{ fontSize: "15px", fontWeight: "500", marginLeft: "15px" }}
          >
            Configure custom publish:
          </div>
          <div className="label-input-wrapper-no-border">
            <div className="label-input">
              <div style={{ width: "200px" }}>
                Publish Connection Type
                <Icon
                  className="required-icon"
                  name="actioncenterasterisk"
                ></Icon>
              </div>
              <Select
                value={selectedConnectionProps?.type}
                slot="action"
                fixed-placement
                placeholder="select"
                style={{ marginRight: "10px", width: "200px" }}
                name="type"
                required
                disabled={!access.canEdit || campaignById.isStandardSPA}
              >
                {connTypeMenItems?.map((item) => (
                  <Option
                    key={item}
                    role="menuitemcheckbox"
                    onHeSelected={handleConnTypeMenuChange}
                  >
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="label-input">
              <div style={{ width: "200px" }}>
                Publish Connection Name
                <Icon
                  className="required-icon"
                  name="actioncenterasterisk"
                ></Icon>
              </div>
            
                <Select
                  value={selectedConnectionProps?.name}
                  slot="action"
                  fixed-placement
                  placeholder="select"
                  style={{ width: "200px" }}
                  required
                  disabled={campaignById.isStandardSPA || !access.canEdit}
                >
                  {connectionMenuItems?.map((item) => (
                    <Option
                      key={item.connectionId}
                      role="menuitemcheckbox"
                      onHeSelected={handleConnMenuChange}
                      value={item.connectionId}
                    >
                      {item.connectionName}
                    </Option>
                  ))}
                </Select>
          
            </div>
            <div
              className=""
              style={{ marginBottom: "15px", marginLeft: "-80px" }}
            >
              {Helper.isLoadingStatus(connRequestStatus) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Spinner type={"small"} />{" "}
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      color: "var(--he-color-neutral-600)",
                    }}
                  >
                    loading connections...
                  </span>
                </div>
              ) : (
                <>
                  <Button
                    id="edit-connection"
                    appearance="stealth"
                    className="Connection-button"
                    style={{ color: "var(--he-color-primary-700)" }}
                    disabled={campaignById.isStandardSPA}
                    onClick={editConnection}
                  >
                    <Icon name={access.canEdit ? "edit" : "view"} />
                  </Button>
                  <Tooltip anchor="edit-connection">
                    {access.canEdit ? "Edit connection" : "View connection"}
                  </Tooltip>
                  {access.canEdit && (
                    <>
                      <Button
                        id="add-connection"
                        appearance="stealth"
                        className="Connection-button"
                        disabled={campaignById.isStandardSPA}
                        style={{
                          marginLeft: "10px",
                          color: "var(--he-color-primary-700)",
                        }}
                        onClick={() =>
                          setPanelProps({
                            isNew: true,
                            appScopes,
                            appPersonas,
                            userTeams,
                          })
                        }
                      >
                        <Icon name="add" />
                      </Button>
                      <Tooltip anchor="add-connection">
                        Add a new connection
                      </Tooltip>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="label-input-wrapper-no-border">
            <div className="label-input">
              {selectedConnectionProps.type?.toUpperCase() === "BLOB" ? (
                <>
                  <div style={{ width: "200px" }}>
                    Data Path
                    <Icon
                      className="required-icon"
                      name="actioncenterasterisk"
                    ></Icon>
                    <Icon
                      id="info3511"
                      name="info"
                      style={{
                        marginLeft: "7px",
                        cursor: "pointer",
                        color: "#0078D4",
                      }}
                    />
                    <Tooltip
                      anchor="info3511"
                      fixedPlacement={true}
                      placement="bottom"
                    >
                      Provide the path for accurate data publish.
                    </Tooltip>
                  </div>
                  <TextField
                    name="path"
                    label="Data Path"
                    value={currentPublishItem?.path}
                    onHeChange={handlePathChange}
                    required
                    disabled={!access.canEdit || campaignById.isStandardSPA}
                  />
                </>
              ) : selectedConnectionProps.type === "SQL" ? (
                <>
                  <div style={{ width: "200px" }}>
                    Table Name
                    <Icon
                      className="required-icon"
                      name="actioncenterasterisk"
                    ></Icon>
                    <Icon
                      id="tablename"
                      name="info"
                      style={{
                        marginLeft: "7px",
                        cursor: "pointer",
                        color: "#0078D4",
                      }}
                    />
                    <Tooltip
                      anchor="tablename"
                      fixedPlacement={true}
                      placement="bottom"
                    >
                      Enter the name of the SQL table where data will be published.
                    </Tooltip>
                  </div>
                  <TextField
                    name="tablename"
                    value={currentPublishItem?.tableName}
                    onHeChange={handleTableNameChange}
                    required
                    disabled={!access.canEdit}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ConnectionPanel panelProps={panelProps} setPanelProps={setPanelProps} />
      <AttributesPanel
        attributesList={attributesList}
        onSelectCallback={handlePublishMapAttributes}
        publishMap={currentPublishItem.publishMap || []}
      />
    </>
  );
});
export const CampaignPublish = withErrorBoundary("Publish screen", CampaignPublishC);


