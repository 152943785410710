import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../root-redux/RootState";
import { Button, Card, Icon } from "../../../common/HarmonyEnablers";
import "./HelpContent.css";

export const HelpNote:React.FC<any> = (props: any) => {




  return (
    <div style = {{background:"#d2ecff", display:"flex", flexDirection:"column", marginTop:"18px", marginBottom:"18px", borderRadius:"8px"}}>
        <div style = {{marginBottom:"4px", padding:"8px"}}>
            <Icon name="info" style = {{marginRight:"8px"}}/>
           <span style = {{fontWeight:"500"}}>Note</span>
        </div>
        <div style = {{padding:"8px", fontSize:"14px"}}>
            <span>{props.text}</span>
        </div>
    
    </div>
  );
};