import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useSelector } from "react-redux";
import { Checkbox, Icon, FlyInPanel, TextField, Select, Option, TextArea, Callout } from "../../common/HarmonyEnablers";
import { DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { updateCurrentMergeRuleSet } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { AddedMergeConditionsDataGridRow, addedMergeConditionsColumnsForInsightsRecs, addedMergeConditionsColumnsForRecs, getFieldKey, getFieldValue, listOfConditions, listOfOperators } from "./MergeRuleSetMergeConditionsColumns";
import { MergeCondition } from "../../model/merge-rules/MergeCondition.model";
import { AddedMergeCondition } from "../../model/merge-rules/AddedMergeCondition.model";
import { Conditions } from "../../model/merge-rules/Conditions.model";
import { getModelMapping } from "../../services/GetModelMapping.api";
import { v4 as uuidv4 } from 'uuid';
import { MergeConditionListDataGridRow, mergeConditionsColumns } from "./mergerule-panel/MergeConditionsListColumns";
import FilterQueryBuilder from "./FilterQueryBuilder";
import { convertQueryToFilterFormat, sortByFieldsNameForFilter, sortByModelsNameForFilter } from "./MergeRuleSetHelper";
import informationConfig from "../../shared-content/inputDescription";
import { InformationText } from "../../shared-components/info-text/InformationText";
import { convertFilterToDisplayFormat } from "./MergeRuleSetListColumns";
import './edit-merge-rules.css'
export const MergeRuleSetMergeConditions: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
  const modelMappings = useSelector((state: RootState) => state.modelMapping.modelMappingObject);
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);

  const [addedMergeConditions, setAddedMergeConditions] = useState([] as AddedMergeConditionsDataGridRow[])
  const [openCriteriaPanel, setOpenCriteriaPanel] = useState(false)
  const [currentMergeCondition, setCurrentMergeCondition] = useState({} as MergeCondition)
  const [originalMergeCondition, setOriginalMergeCondition] = useState({} as MergeCondition)
  const [mergeConditionsList, setMergeConditionsList] = useState([] as MergeConditionListDataGridRow[])
  const [currentCondition, setCurrentCondition] = useState({} as Conditions)
  const [selectedCondition, setSelectedCondition] = useState('')
  const [editConditionBool, setEditConditionBool] = useState(false)
  const [currentListOfModels, setCurrentListOfModels] = useState([] as string[])
  const [currentListOfFields, setCurrentListOfFields] = useState([] as string[])
  const [currentModelPicked, setCurrentModelPicked] = useState('')
  const [enableConditionSave, setEnableConditionSave] = useState(false)
  const [fillAllPrompt, setFillAllPrompt] = useState(false)
  const [usedModels, setUsedModels] = useState([] as string[])
  const [enableCurrentConditionSave, setEnableCurrentConditionSave] = useState(false)
  const [openConditionRec, setOpenConditionRec] = useState(false)
  const [modelList, setModelList] = useState([] as [string, string][])
  const [fieldsNameList, setFieldsNameList] = useState([] as string[])
  const [fieldsList, setFieldsList] = useState([] as [string, string, string][])

  const [conditionRec, setConditionRec] = useState(currentMergeCondition.conditionRec || "")
  const [conditionRecString, setConditionRecString] = useState(currentMergeCondition.jsonQueryStringConditionRec || "")
  const [disableSaveFromFilter, setDiableSaveFromFilter] = useState(false)

  useEffect(() => {
    dispatch(getModelMapping({ name: "", id: "" }))
  }, [dispatch])

  useEffect(() => {
    var list: MergeConditionListDataGridRow[] = []
    var localUsedModels: string[] = []
    if (currentMergeCondition.conditions != null && currentMergeCondition.conditions.length > 0) {
      currentMergeCondition.conditions.forEach((eachCondition: Conditions) => {
        var localMergeConditionListDataGridRow = {} as MergeConditionListDataGridRow
        localMergeConditionListDataGridRow = getMergeRuleListFromCondition(eachCondition, localMergeConditionListDataGridRow, localUsedModels)
        list.push(localMergeConditionListDataGridRow)
      })
    }

    setUsedModels(localUsedModels)
    setMergeConditionsList(list)
    if (originalMergeCondition && currentMergeCondition && JSON.stringify(originalMergeCondition) !== JSON.stringify(currentMergeCondition) && currentMergeCondition.description && currentMergeCondition.conditions.length > 0) {
      setEnableConditionSave(true)
    } else {
      setEnableConditionSave(false)
    }
    if (currentMergeCondition.description && currentMergeCondition.conditions.length > 0) {
      setFillAllPrompt(false)
    } else {
      setFillAllPrompt(true)
    }
    setConditionRec(currentMergeCondition.conditionRec)
    setConditionRecString(currentMergeCondition.jsonQueryStringConditionRec)
  }, [currentMergeCondition])

  useEffect(() => {
    var list: MergeConditionListDataGridRow[] = []
    list = mergeConditionsList.map(x => { return x })
    var currentMergeRuleList = list.find(x => { return x.id === selectedCondition });

    if (currentMergeRuleList != undefined) {
      var index = list.indexOf(currentMergeRuleList)
      currentMergeRuleList = getMergeRuleListFromCondition(currentCondition, currentMergeRuleList, [])
      list[index] = structuredClone(currentMergeRuleList)
      setMergeConditionsList(list)
    }
  }, [currentCondition])

  useEffect(() => {
    if (openConditionRec) {
      var list = [] as [string, string][]
      //MRTODO
      var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })

      workflows.forEach((item: any) => {
        list.push([item.config.workflowName, "String"])
      })
      list = sortByModelsNameForFilter(list)
      setModelList(list)

      var localfieldsList = [] as [string, string, string][]
      var localfieldsNameList = [] as string[]
      workflows.forEach((item: any) => {
        var fields = item.fields
        if (fields != null) {
          Object.keys(fields).map((eachField) => {
            if (!localfieldsNameList.includes(eachField)) {
              localfieldsList.push([eachField, fields[eachField], item.config.workflowName])
              localfieldsNameList.push(eachField)
            }
          })
        }
      })
      localfieldsList = sortByFieldsNameForFilter(localfieldsList)
      setFieldsList(localfieldsList)
      setFieldsNameList(localfieldsNameList)
    } else {
      setFieldsList([])
      setFieldsNameList([])
    }
  }, [openConditionRec])

  useEffect(() => {
    if (openCriteriaPanel) {
      var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })

      var models = workflows.map(x => { return x.config.workflowName })
      models = models.filter(x => { return !usedModels.includes(x) })
      models = models.sort((a, b) => a > b ? 1 : b > a ? -1 : 0);
      setCurrentListOfModels(models)
    }
  }, [openCriteriaPanel, currentCondition])

  useEffect(() => {
    if (currentModelPicked) {
      var workflow = workflowsBySubKeys.find(x => x.config.workflowName === currentModelPicked)
      var fields = [] as string[]

      if (workflow && workflow.fields) {
        var local = workflow.fields
        Object.keys(local).map((item) => {
          fields.push(item)
        })
      }
      setCurrentListOfFields(fields);
    }
  }, [currentModelPicked])

  
  useEffect(() => {
    var addedList: AddedMergeConditionsDataGridRow[] = []

    if (currentMergeRuleSet?.mergeOn.mergeConditions && currentMergeRuleSet.mergeOn.mergeConditions.length > 0) {
      var i = 1;
      currentMergeRuleSet.mergeOn.mergeConditions.forEach((eachMergeCondition: MergeCondition) => {
        var newConditionAdded = new AddedMergeCondition()
        newConditionAdded.id = eachMergeCondition.id
        newConditionAdded.value = ""

        eachMergeCondition.conditions.forEach((eachCondition: Conditions) => {
          newConditionAdded = getAddedMergeConditions(eachCondition, newConditionAdded)
        })
        newConditionAdded.value = newConditionAdded.value != "" ? newConditionAdded.value.slice(0, -4) : newConditionAdded.value

        addedList.push({
          id: newConditionAdded.id.toString(),
          cells: {
            field1: i.toString(),
            field2: eachMergeCondition.description,
            field3: newConditionAdded.value,
            field34: convertFilterToDisplayFormat(eachMergeCondition.conditionRecToDisplay) || "",
            field4: ""
          }
        })
        i++;
      });
    }

    setAddedMergeConditions(addedList)
  }, [currentMergeRuleSet.mergeOn.mergeConditions])

  const handleDisableSave = (disabled: boolean) => {
    setDiableSaveFromFilter(disabled)
  }

  const handleQueryReturn = (query: string, jsonTree: string) => {
    setConditionRec(query)
    setConditionRecString(jsonTree)
    var newConditionRec = convertQueryToFilterFormat(query, fieldsList, workflowsBySubKeys)
    if (currentCondition?.id) {
      var localCurrentMergeCondition = structuredClone(currentMergeCondition)
      var conditions = localCurrentMergeCondition.conditions;
      var index = -1
      var currentConditionPresent = conditions.find((x: any) => { return x.id === currentCondition.id })

      if (currentConditionPresent !== undefined) {
        index = conditions.indexOf(currentConditionPresent)
        conditions[index] = { ...currentCondition }
      } else {
        conditions = [...conditions, currentCondition]
      }
      localCurrentMergeCondition.conditions = conditions
      setCurrentMergeCondition({ ...currentMergeCondition, conditions: localCurrentMergeCondition.conditions, conditionRec: newConditionRec, conditionRecToDisplay: query, jsonQueryStringConditionRec: jsonTree })
    } else {
      setCurrentMergeCondition({ ...currentMergeCondition, conditionRec: newConditionRec, jsonQueryStringConditionRec: jsonTree, conditionRecToDisplay: query })
    }
  }


  //MRTODO refactor
  const getAddedMergeConditions = (eachCondition: Conditions, newConditionAdded: AddedMergeCondition) => {
    var filter = eachCondition.filter
    var filterArray = filter.split("&&")
    var fieldFilter = "", sourceFilter = "";
    var fieldName = ""
    if (filterArray.length >= 1) { fieldFilter = filterArray[0].trim() }
    if (filterArray.length >= 2 && filterArray[1].includes("SourceId")) { sourceFilter = filterArray[1].trim() }
    var modelId = sourceFilter !== "" ? sourceFilter.replace(/<(.*?)>/g, '').replace(/SourceId.Equals\(/g, '').replace(/\)/g, '') : ""
    var modelName = "";
    var workflow = workflowsBySubKeys.find(x => x.modelId === modelId)
    if (workflow) {
      modelName = workflow.config.workflowName
    } else {
      modelName = modelId !== "" ? modelMappings.find(x => { return x.id === modelId })?.name || "" : ""
    }

    var opr = ""
    var negate = fieldFilter.includes("!")
    var values = []

    var filterShed = fieldFilter.replace(/<(.*?)>/g, '')
    if (filterShed.includes("||") || filterShed.startsWith("(")) {
      opr = negate ? "Not In" : "In"
      filterShed = filterShed.replace(/\(/g, '').replace(/\)/g, '')
      var valuesArray = filterShed.split("||")
      valuesArray.forEach((eachValue) => {
        if (eachValue.includes(".Equals")) {
          var eachValueArray = eachValue.split(".Equals")
          if (fieldName === "") {
            fieldName = eachValueArray[0] || ""
          }
          var localValue = eachValueArray[1].trim() || ""
          if (localValue != "") {
            localValue = localValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\,noCase/g, '')
            values.push(localValue)
          }
        }
      })
    } else {
      opr = negate ? "Not Equals" : "Equals"
      if (filterShed.includes(".Equals")) {
        var eachValueArray = filterShed.split(".Equals")
        if (fieldName === "") {
          fieldName = eachValueArray[0] || ""
        }
        var localValue = eachValueArray[1].trim() || ""
        if (localValue != "") {
          localValue = localValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\,noCase/g, '')
          values.push(localValue)
        }
      }
    }
    var finalValue = modelName + (modelName != "" ? "." : "") + fieldName.replace(/\!/g, '') + " " + opr + " (" + (values?.join(",") || "") + ")"
    newConditionAdded.value += finalValue + " And~"
    return newConditionAdded
  }

  const addNewMergeCriteria = () => {
    setOpenCriteriaPanel(true)
    setUsedModels([])
    var newMergeCondition = new MergeCondition()
    newMergeCondition.id = uuidv4().toString()
    var conditions: Conditions[] = []
    newMergeCondition.conditions = conditions
    setCurrentMergeCondition(newMergeCondition)
    setOriginalMergeCondition(newMergeCondition)
  }

  const deleteMergeCriteria = (rowId: any) => {
    var localVar = structuredClone(currentMergeRuleSet)
    var mergeConditions: MergeCondition[] = []
    if (localVar?.mergeOn?.mergeConditions != null && localVar?.mergeOn?.mergeConditions?.length > 0) {
      mergeConditions = localVar.mergeOn.mergeConditions
      mergeConditions = mergeConditions.filter((x: any) => x.id !== rowId)
    }
    localVar.mergeOn.mergeConditions = mergeConditions
    setCurrentMergeCondition({} as MergeCondition)
    setOriginalMergeCondition({} as MergeCondition)
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, mergeOn: localVar.mergeOn }))
  }

  const openMergeCondition = (rowId: any) => {
    setOpenCriteriaPanel(true)
    var mergeCondition = currentMergeRuleSet.mergeOn.mergeConditions.filter((x: { id: string; }) => { return x.id === rowId });
    if (mergeCondition.length === 1) {
      setCurrentMergeCondition(mergeCondition[0])
      setOriginalMergeCondition(mergeCondition[0])
    }
  }

  const handleRequestClose = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenCriteriaPanel(false)
      setUsedModels([])
      setMergeConditionsList([])
      setCurrentMergeCondition({} as MergeCondition)
      setOriginalMergeCondition({} as MergeCondition)
      setEditConditionBool(false)
      setSelectedCondition('')
      setOpenConditionRec(false)
    } else {
      event.preventDefault()
    }
  }

  const addNewCondition = () => {
    var newCondition = new Conditions()
    newCondition.id = uuidv4().toString()
    newCondition.filter = ""
    newCondition.name = "Equals"
    newCondition.description = ""
    setEnableCurrentConditionSave(false)
    setCurrentCondition(newCondition)

    setSelectedCondition(newCondition.id)
    setEditConditionBool(true)
    var localObj: any = {
      id: newCondition.id,
      cells: {
        field1: "And",
        field2: "",
        field3: "",
        field4: "Equals",
        field5: "",
        field6: "false",
        field7: ""
      }
    }
    var localList = mergeConditionsList.map((x) => { return x })
    localList.push(localObj)
    setMergeConditionsList(localList)
    // merge rule set save
  }


  const editCondition = (rowId: any, isSave: boolean, modelName: any, row: any) => {
    if (!isSave) {
      var localCondition = currentMergeCondition.conditions.filter((x: any) => { return x.id === rowId })
      if (localCondition != null && localCondition.length === 1) {
        setCurrentCondition(localCondition[0])
      }
      if (row.cells.field2 && row.cells.field3 && row.cells.field4) {
        setEnableCurrentConditionSave(true)
      } else {
        setEnableCurrentConditionSave(false)
      }
      setEditConditionBool(true)
      setSelectedCondition(rowId)
      setCurrentModelPicked(modelName)
    } else {
      // save into current merge condition and 
      var localCurrentMergeCondition = structuredClone(currentMergeCondition)
      var conditions = localCurrentMergeCondition.conditions;
      var index = -1
      var conditionToEdit = conditions.find((x: any) => { return x.id === rowId })

      if (conditionToEdit !== undefined) {
        index = conditions.indexOf(conditionToEdit)
        conditions[index] = { ...currentCondition }
      } else {
        conditions = [...conditions, currentCondition]
      }
      localCurrentMergeCondition.conditions = conditions
      setCurrentMergeCondition({ ...currentMergeCondition, conditions: localCurrentMergeCondition.conditions })
      setEditConditionBool(false)
      setSelectedCondition('')
      setCurrentCondition({} as Conditions)
      setCurrentModelPicked('')
      setCurrentListOfFields([])

      // merge rule set
    }
  }

  const deleteCondition = (rowId: any, row: any) => {
    var localCurrentMergeCondition = structuredClone(currentMergeCondition)
    var localConditions = localCurrentMergeCondition.conditions.filter((x: any) => { return x.id !== rowId })
    setCurrentMergeCondition({ ...currentMergeCondition, conditions: localConditions })
    setCurrentCondition({} as Conditions)
    setEditConditionBool(false)
    setSelectedCondition('')
    setCurrentModelPicked('')
    var localModels = usedModels
    var modelName = row.cells.field2
    if (modelName && localModels.includes(modelName)) {
      localModels = localModels.filter(x => { return x !== modelName })
      setUsedModels(localModels)
    }

    // also add in merge rule set
  }

  const getFieldType = (fieldName: any, fields: any) => {
    var fieldType = fields[fieldName]
    fieldType = fieldType || "String"
    return fieldType;
  }

  const getFilter = (row: { cells: { field2: any; field3: any; field4: any; field5: any; field6: any; }; }, newModelName: any = "", newFieldName: any = "", newOpr: any = "", newValues: any = "", newNoCase: any = "") => {
    var modelName = newModelName === "" ? row.cells.field2 : newModelName;
    var workflow = workflowsBySubKeys.find(x => x.config.workflowName === modelName)
    setCurrentModelPicked(modelName)

    var fieldName = newFieldName === "" ? row.cells.field3 : newFieldName;
    var opr = newOpr === "" ? row.cells.field4 : newOpr;
    var values = newValues === "" ? row.cells.field5 : newValues.trim();
    var noCase = newNoCase === "" ? row.cells.field6 : newNoCase;
    var modelId = ""
    var workflow = workflowsBySubKeys.find(x => { return x.config.workflowName === modelName })

    if (workflow) {
      modelId = workflow.modelId
    }

    var fieldType = workflow && workflow.fields && Object.keys(workflow.fields).length > 0 ? getFieldType(fieldName, workflow.fields) : "String"
    var openFieldKey = getFieldKey(fieldType, true);
    var closeFieldKey = getFieldKey(fieldType, false);
    var openFieldValue = getFieldValue(fieldType, true);
    var closeFieldValue = getFieldValue(fieldType, false);

    var noCaseValue = noCase === "true" ? ",noCase" : ""

    var filter = ""
    if (opr === "Equals") {
      filter += openFieldKey + fieldName + closeFieldKey + ".Equals(" + openFieldValue + values + closeFieldValue + noCaseValue + ")"
    } else if (opr === "Not Equals") {
      filter += "!" + openFieldKey + fieldName + closeFieldKey + ".Equals(" + openFieldValue + values + closeFieldValue + noCaseValue + ")"
    } else if (opr === "In") {
      var valuesArray = values.split(",")
      if (values === "" || valuesArray.length === 0) {
        filter += "(" + openFieldKey + fieldName + closeFieldKey + ".Equals(" + openFieldValue + values + closeFieldValue + noCaseValue + "))"
      } else {
        filter += "("
        valuesArray.forEach((eachValue: string) => {
          var eachValueConverted = eachValue === "" || eachValue === undefined ? "" : eachValue
          filter += "(" + openFieldKey + fieldName + closeFieldKey + ".Equals(" + openFieldValue + eachValueConverted + closeFieldValue + noCaseValue + ")) ||"
        });
        filter = filter.slice(0, -3) + ")"
      }
    } else if (opr === "Not In") {
      var valuesArray = values.split(",")
      if (values === "" || valuesArray.length === 0) {
        filter += "(!" + openFieldKey + fieldName + closeFieldKey + ".Equals(" + openFieldValue + values + closeFieldValue + noCaseValue + "))"
      } else {
        filter += "("
        valuesArray.forEach((eachValue: string) => {
          var eachValueConverted = eachValue === "" || eachValue === undefined ? "" : eachValue
          filter += "(!" + openFieldKey + fieldName + closeFieldKey + ".Equals(" + openFieldValue + eachValueConverted + closeFieldValue + noCaseValue + ")) ||"
        });
        filter = filter.slice(0, -3) + ")"
      }
    }
    filter += modelName === "" ? "" : (" && <strKey>SourceId</strKey>.Equals(<strVal>" + modelId + "</strVal>)")
    if (modelName && opr && fieldName) { setEnableCurrentConditionSave(true) } else { setEnableCurrentConditionSave(false) }
    setCurrentCondition({ ...currentCondition, filter: filter })
  }

  const onSaveMergeConditionClick = () => {
    var localVar = structuredClone(currentMergeRuleSet)
    var mergeConditions: MergeCondition[] = []
    if (localVar?.mergeOn?.mergeConditions !== null && localVar?.mergeOn?.mergeConditions?.length > 0) {
      mergeConditions = localVar.mergeOn.mergeConditions
      var localMergeCondition = mergeConditions.find((x: any) => x.id === currentMergeCondition.id)
      if (localMergeCondition !== undefined) {
        var index = localVar.mergeOn.mergeConditions.indexOf(localMergeCondition)
        mergeConditions[index] = currentMergeCondition
      } else {
        mergeConditions = [...mergeConditions, currentMergeCondition]
      }
    } else {
      mergeConditions = [...mergeConditions, currentMergeCondition]
    }

    localVar.mergeOn.mergeConditions = mergeConditions
    setCurrentMergeCondition({} as MergeCondition)
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, mergeOn: localVar.mergeOn }))
    setOpenCriteriaPanel(false)
    setMergeConditionsList([])
    setUsedModels([])
    setCurrentCondition({} as Conditions)
    setOriginalMergeCondition({} as MergeCondition)
    setSelectedCondition('')
    setOpenConditionRec(false)
  }

  const onCancelMergeConditionClick = () => {
    setOpenCriteriaPanel(false)
    setMergeConditionsList([])
    setCurrentMergeCondition({} as MergeCondition)
    setOriginalMergeCondition({} as MergeCondition)
    setEditConditionBool(false)
    setCurrentModelPicked('')
    setUsedModels([])
    setOpenConditionRec(false)
  }

  const onRowSelect = (e: any) => { }

  const getMergeRuleListFromCondition = (eachCondition: Conditions, currentMergeRuleList: MergeConditionListDataGridRow, localUsedModels: string[]) => {
    var modelId = "", modelName = "", fieldName = "", opr = "", values: string[] = [], noCase = false
    var newConditionAdded = new AddedMergeCondition()
    var filter = eachCondition.filter
    var filterShed = filter.replace(/<(.*?)>/g, '')
    newConditionAdded.value += filterShed
    opr = filter === "" ? "Equals" : filter != "" && filter.includes("!") ? filter.trim().startsWith("(") ? "Not In" : "Not Equals" : filter.trim().startsWith("(") ? "In" : "Equals"
    noCase = filter.includes("noCase") ? true : false

    if (filterShed.includes("!")) {
      filterShed = filterShed.replace(/!/g, '')
    }
    var filterShedArray = filterShed.split("&&")

    if (filterShedArray.length >= 2 && filterShedArray[1].trim().startsWith("SourceId")) {
      modelId = filterShedArray[1].trim()
      modelId = modelId.replace(/SourceId.Equals\(/, '')
      modelId = modelId.replace(/\)/, '')

      var workflow = workflowsBySubKeys.find(x => { return x.modelId === modelId })
      if (workflow) {
        modelName = workflow.config.workflowName
      }
    }
    var fieldsArray = filterShedArray.length >= 1 ? filterShedArray[0].trim().split("||") : []
    fieldsArray.forEach(eachFieldElement => {
      var ele = eachFieldElement.replace(/\(/g, '').split(".Equals")

      if (ele.length >= 1) {
        fieldName = ele[0].trim()
      }
      if (ele.length >= 2) {
        var value = ele[1].replace(/\(/g, '')
        value = value.replace(/\)/g, '').trim()
        value = value.replace(/,noCase/g, '').trim()
        values.push(value)
      }

    });

    currentMergeRuleList.id = eachCondition.id
    currentMergeRuleList.cells = {
      field1: "And",
      field2: modelName,
      field3: fieldName,
      field4: opr,
      field5: values.join(","),
      field6: noCase ? "true" : "false",
      field7: ""
    }

    if (modelName && !localUsedModels.includes(modelName)) {
      localUsedModels.push(modelName)
    }
    return currentMergeRuleList
  }

  const addConditionRec = () => {
    setOpenConditionRec(!openConditionRec)
    setDiableSaveFromFilter(false)
  }

  const handleDescription = (e: any) => {
    if (currentCondition?.id) {
      var localCurrentMergeCondition = structuredClone(currentMergeCondition)
      var conditions = localCurrentMergeCondition.conditions;
      var index = -1
      var currentConditionPresent = conditions.find((x: any) => { return x.id === currentCondition.id })

      if (currentConditionPresent !== undefined) {
        index = conditions.indexOf(currentConditionPresent)
        conditions[index] = { ...currentCondition }
      } else {
        conditions = [...conditions, currentCondition]
      }
      localCurrentMergeCondition.conditions = conditions
      setCurrentMergeCondition({ ...currentMergeCondition, conditions: localCurrentMergeCondition.conditions, description: e.target.value })
    } else {
      setCurrentMergeCondition({ ...currentMergeCondition, description: e.target.value })
    }
  }
  return (
    <>
      <div>
        {openCriteriaPanel &&
          <FlyInPanel style={{ '--size': 'var(--he-panel-size-large)', height: "100%" }}
            open={openCriteriaPanel}
            heading="Add specific value(s) of fields to merge"
            onHeRequestClose={handleRequestClose}>
            <div style={{ paddingBottom: "20px", fontSize: "15px" }}>
              <span>Add criteria which when satisified, the insights/input recommendations should merge to generate recommendations.</span>
              <span><br /><i>NOTE:</i></span>
              <span><br />1. Atleast one criteria needs to be added </span>
              <span><br />2. Maximum number of criteria you can add is same as number of Insights/Recommendations you chose in previoue step</span>
              <span><br />3. Recommendations are generated only if all the criteria match</span>
              <span><br />4. Number of Recommendations generated is same as number of matches found</span>
            </div>
            <table className='mergerule-form-table' >
              <tr>
                <td><span >Description: </span><Icon className='required-icon' name='actioncenterasterisk'></Icon></td>
                <td>
                  <TextField disabled={!access.canEdit} label="Description" name='description' value={currentMergeCondition.description || ""} className={'Text-fields'}
                    onHeInput={handleDescription} required />
                </td>
              </tr>
            </table>
            <div className="Merge-criteria" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <span>Criteria to be satified by Insights/Input Recommendations inorder to Merge: <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                <Button appearance="command" style={{ fontSize: "14px" }} disabled={editConditionBool || (usedModels.length >= currentMergeRuleSet.mergeModel.length)} onClick={() => { addNewCondition() }}>
                  <Icon slot="start" name="add"></Icon>
                  Add Criterion
                </Button>
              </span>
              <DataGrid fixedHeading={true}
                style={{ marginBottom: "100px" }}
                rows={mergeConditionsList} columns={mergeConditionsColumns} onHeRowSelectChange={(e) => { onRowSelect(e.detail) }}>
                <div slot="field1-column-header" fixed-placement>
                  <Icon slot="anchor" className='required-icon' name='actioncenterasterisk'></Icon>
                </div>
                <div slot="field2-column-header" fixed-placement>
                  <Icon slot="anchor" className='required-icon' name='actioncenterasterisk'></Icon>
                </div>
                <div slot="field3-column-header" fixed-placement>
                  <Icon slot="anchor" className='required-icon' name='actioncenterasterisk'></Icon>
                </div>
                <div slot="field4-column-header" fixed-placement>
                  <Icon slot="anchor" className='required-icon' name='actioncenterasterisk'></Icon>
                </div>
                <span slot="no-records">No Criteria Added.</span>
                {mergeConditionsList.map(row => {
                  return (
                    <>
                      {(selectedCondition === row.id) &&

                        <div slot={`field1-${row.id}`} key={`field1-${row.id}`} >
                          <Select
                            autocomplete="both"
                            style={{ maxWidth: "7em" }}
                            value={"And"}
                            slot="action"
                            fixed-placement
                            placeholder='And'
                          >
                            {
                              listOfConditions.map(item => (
                                <Option
                                  key={item}
                                  role="menuitemcheckbox"
                                  onHeSelected={(e: any) => {
                                    var selectedKey = e.target.textContent
                                  }}
                                >
                                  {item}
                                </Option>
                              ))
                            }
                          </Select>

                        </div>
                      }
                      {(selectedCondition === row.id) &&

                        <div slot={`field2-${row.id}`} key={`field2-${row.id}`} >
                          <Select
                            autocomplete="both"
                            style={{ width: "20em" }}
                            value={row.cells.field2}
                            slot="action"
                            fixed-placement
                            placeholder={row.cells.field2}
                            label="filed2"
                          >
                            {
                              currentListOfModels.map(item => (
                                <Option
                                  key={item}
                                  role="menuitemcheckbox"
                                  onHeSelected={(e: any) => {
                                    var selectedKey = e.target.textContent
                                    getFilter(row, selectedKey, "", "", "", "")
                                  }}
                                >
                                  {item}
                                </Option>
                              ))
                            }
                          </Select>
                        </div>
                      }
                      {(selectedCondition === row.id) &&

                        <div slot={`field3-${row.id}`} key={`field3-${row.id}`} >
                          <Select
                            autocomplete="both"
                            style={{ width: "20em" }}
                            value={row.cells.field3}
                            slot="action"
                            fixed-placement
                            placeholder={row.cells.field3}
                            label="field3"
                          >
                            {
                              currentListOfFields.map(item => (
                                <Option
                                  key={item}
                                  role="menuitemcheckbox"
                                  onHeSelected={(e: any) => {
                                    var selectedKey = e.target.textContent
                                    getFilter(row, "", selectedKey, "", "", "")
                                  }}
                                >
                                  {item}
                                </Option>
                              ))
                            }
                          </Select>
                        </div>
                      }
                      {(selectedCondition === row.id) &&

                        <div slot={`field4-${row.id}`} key={`field4-${row.id}`}>
                          <Select
                            autocomplete="both"
                            style={{ width: "10em" }}
                            value={row.cells.field4}
                            slot="action"
                            fixed-placement
                            placeholder={row.cells.field4}
                          >
                            {
                              listOfOperators.map(item => (
                                <Option
                                  key={item}
                                  role="menuitemcheckbox"
                                  onHeSelected={(e: any) => {
                                    var selectedKey = e.target.textContent
                                    getFilter(row, "", "", selectedKey, "", "")
                                  }}
                                >
                                  {item}
                                </Option>
                              ))
                            }
                          </Select>
                        </div>
                      }
                      {(selectedCondition === row.id) &&

                        <div slot={`field5-${row.id}`} key={`field5-${row.id}`}>
                          <TextArea label="field 5" name='name' style={{ width: "25em" }} resize="vertical" value={row.cells.field5} onHeChange={(e: any) => {
                            getFilter(row, "", "", "", e.target.value, "")
                          }} />
                        </div>
                      }
                      {(selectedCondition !== row.id) &&
                        <div slot={`field2-${row.id}`} key={`field2-${row.id}`} style={{ width: "15em" }}> {row.cells.field2}
                        </div>
                      }
                      {(selectedCondition !== row.id) &&
                        <div slot={`field3-${row.id}`} key={`field3-${row.id}`} style={{ width: "15em" }}> {row.cells.field3}
                        </div>
                      }
                      {(selectedCondition !== row.id) &&
                        <div slot={`field4-${row.id}`} key={`field4-${row.id}`} style={{ width: "15em" }}> {row.cells.field4}
                        </div>
                      }
                      {(selectedCondition !== row.id) &&
                        <div slot={`field5-${row.id}`} key={`field5-${row.id}`} style={{ width: "15em" }}>
                          {row.cells.field5 &&
                            <HoverCard role="button" slot={`field5-${row.id}`} key={`field5-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                              <div role="button" style={{ width: '15em' }} slot="anchor" key={`field5-${row.id}`}>{row.cells.field5 === null || row.cells.field5 === '' ? '' : row.cells.field5 != null && row.cells.field5.length > 30 ? row.cells.field5.substring(0, 30) + "..." : row.cells.field5}</div>
                              {row.cells.field5 && (row.cells.field4 === "Equals" || row.cells.field4 === "Not Equals") &&
                                <code style={{ color: "black" }}>{row.cells.field5}
                                </code>
                              }
                              {row.cells.field5 && (row.cells.field4 === "In" || row.cells.field4 === "Not In") &&
                                <code style={{ color: "black" }}>{row.cells.field5.split(",").map(eachRow => {
                                  return <div>{eachRow}</div>
                                })}
                                </code>
                              }
                              {!row.cells.field5 &&
                                <code style={{ color: "black" }}>
                                </code>
                              }
                            </HoverCard>
                          }
                        </div>
                      }
                      <span slot={`field6-${row.id}`} key={`field6-${row.id}`}>
                        <Checkbox label="checkbox" disabled={!editConditionBool || (selectedCondition !== "" && selectedCondition !== row.id)} checked={row.cells.field6 === "true" ? true : false} onHeChange={() => getFilter(row, "", "", "", "", row.cells.field6 === "true" ? "false" : "true")} />
                      </span>
                      <span slot={`field7-${row.id}`} key={`field7-${row.id}`}>
                        <div style={{ color: 'var(--he-color-primary-700)', display: 'flex', alignItems: 'center' }}>
                          <Button disabled={editConditionBool && ((selectedCondition !== "" && selectedCondition !== row.id) || (!enableCurrentConditionSave))} appearance="command" onClick={() => { editCondition(row.id, editConditionBool && selectedCondition === row.id, row.cells.field2, row) }}   >
                            <Icon label="save/edit" name={editConditionBool && selectedCondition != "" && selectedCondition === row.id ? "save" : "edit"} />
                          </Button>
                          <Button disabled={selectedCondition !== "" && selectedCondition !== row.id} appearance="command" onClick={() => { deleteCondition(row.id, row) }} >
                            <Icon label="delete" name="delete" />
                          </Button>
                        </div>
                      </span>
                    </>
                  )
                })}
              </DataGrid>
              {currentMergeRuleSet.type === "secondary" &&
                <div style={{ paddingBottom: "100px" }}>Criteria to be satisfied By Existing Recommendations inorder to Merge (Optional):
                  <Button appearance="command" style={{ fontSize: "14px" }} onClick={() => { addConditionRec() }}>
                    <Icon slot="start" name={openConditionRec ? "cancel" : "view"}></Icon>
                    {openConditionRec ? `Cancel` : `Show`}  
                  </Button>
                  {openConditionRec &&
                    <FilterQueryBuilder subKey={mergeRule.subscriptionKey} size={'large'} showSave={true} setOpenFilter={setOpenConditionRec} modelList={modelList} fieldsList={fieldsList} inputView={""} currentJsonTree={conditionRecString || ""} onQueryReturn={handleQueryReturn} disableSave={handleDisableSave} />
                  }
                </div>
              }
            </div>
            <div>
              <div className="large-flyinpanel-footer">
                {fillAllPrompt &&
                  <div className="mergerule-access-text ">Fill all mandatory fields to enable Save.</div>
                }
                {openConditionRec && !disableSaveFromFilter &&
                  <div className="mergerule-access-text ">Save the section `Criteria to be satisfied By Existing Recommendations inorder to Merge` to enable this Save button.</div>
                }
                <Button type='button' appearance='primary' disabled={!enableConditionSave || selectedCondition !== "" || (openConditionRec && !disableSaveFromFilter )} onClick={onSaveMergeConditionClick} >Save</Button>
                <span style={{ marginLeft: '20px' }}></span>
                {(<Button type='button' appearance='secondary' onClick={onCancelMergeConditionClick}>Cancel</Button>)}
                <span style={{ marginLeft: '20px' }}></span>
              </div>
            </div>
          </FlyInPanel>
        }
        <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={() => { addNewMergeCriteria() }} >
          <Icon slot="start" name="add"></Icon>
          Add Merge Criteria 
        </Button>
        <DataGrid fixedHeading={true}
          style={{ marginBottom: "10px" }} rows={addedMergeConditions} columns={currentMergeRuleSet.type === "secondary" ? addedMergeConditionsColumnsForInsightsRecs : addedMergeConditionsColumnsForRecs} >
          <span slot="no-records">No Merge Criteria Added.</span>
          {addedMergeConditions.map(row => {
            return (
              <>
                <span slot={`field1-${row.id}`} key={`field1-${row.id}`} style={{ color: "#0078D4", cursor: "pointer" }}
                  onClick={() => { openMergeCondition(row.id) }}
                >
                  {row.cells.field1}
                </span>
                <div slot={`field2-${row.id}`} key={`field2-${row.id}`} style={{ color: "#0078D4", cursor: "pointer", width: "10em" }}
                  onClick={() => { openMergeCondition(row.id) }}
                >
                  {row.cells.field2}
                </div>
                <div slot={`field3-${row.id}`} key={`field3-${row.id}`} style={{ width: "15em" }}
                >
                  {row.cells.field3 != undefined && row.cells.field3 != "" &&
                    <div> {
                      row.cells.field3.split("~").map((x) => {
                        return <div>{x}</div>
                      })}
                    </div>
                  }
                  {!(row.cells.field3) &&
                    <div></div>
                  }
                </div>
                {currentMergeRuleSet.type === "secondary" &&
                  <div slot={`field34-${row.id}`} key={`field34-${row.id}`} style={{ width: "15em" }}
                  >
                    {row.cells.field34}
                  </div>
                }
                <span slot={`field4-${row.id}`} key={`field4-${row.id}`}>
                  <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={() => { deleteMergeCriteria(row.id) }} >
                    <Icon label="delete" name="delete" />
                  </Button>
                </span>
              </>
            )
          })}
        </DataGrid>
      </div>
    </>
  )
}