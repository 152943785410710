import { useSelector } from 'react-redux';
import { RootState } from "../../root-redux/RootState";
import { APIResponseMappingObject } from '../../model/apiConfig/APIResponseMappingObject.model';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchRequest, postRequest } from '../utils/PostRequest';
import { EdmEntityTypeSettings } from '../../model/apiConfig/EdmEntityTypeSettings.model';
import { BASE_URL } from "../utils/Constants";


export const addNewRoute = createAsyncThunk<boolean, {routeType: string, payload: EdmEntityTypeSettings}, { state: RootState }>('apiConfig/addRoute', async ({ routeType, payload }, { getState }) => {
  const env = getState().env.selectedEnvItem
  const response = await postRequest<boolean, EdmEntityTypeSettings>(`${env}/APIResponseMapping/${routeType}`, payload)
  return response.data;
})

export const updateAPIConfig = createAsyncThunk<boolean, { routeType: string, payload: APIResponseMappingObject}, { state: RootState }>('apiConfig/updateConfig', async ({ routeType, payload }, { getState }) => {
  const env = getState().env.selectedEnvItem
  const response = await patchRequest<boolean, APIResponseMappingObject>(`${env}/APIResponseMapping/${routeType}`, payload)
  return response.data;
})

export const updateRecommendationAPIConfig = createAsyncThunk<boolean, { modelId: string, payload: EdmEntityTypeSettings }, { state: RootState }>('apiConfig/updateRecommendationConfig', async ({ modelId, payload }, { getState }) => {

  const selectedEnv = getState().env.selectedEnvItem

  const response = await postRequest<boolean, EdmEntityTypeSettings>(`${selectedEnv}/APIResponseMapping/recommendation/updateRecommendationRoute?modelId=${modelId}`, payload)
  return response.data;
})