import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid"
import { DeltaMap } from "../../model/workflows/Delta.model"
import { PublishMap } from "../../model/workflows/Publish.model"

export interface AttributeDataGridRow extends Row
{
  cells: {
    field1: string
    field2: string
    field3: string
    field4: string
  }
}

export const columns: Column[] = [
    {
      field: 'field1',
      content: 'Attribute'
    },
    {
        field: 'field2',
        content: 'Select Attributes triggering data refresh'
    },
    {
      field: 'field3',
      content: 'Map the Data type'
    },
    {
      field: 'field4',
      content: 'Select primary attributes'
    },
      
];

export const getAttributeGridRows = (deltaMap: DeltaMap[], publishMap: PublishMap[]): AttributeDataGridRow[] => {
  let index: number = 1;  
  const uniqueSources = new Set<string>(); 
  return publishMap?.map((item: PublishMap) => {
    if (!uniqueSources.has(item.source) && item.source !== "CurrentTimestamp" && item.destination !== "Source.SourceId" && item.destination !== "Source.Timestamp" && item.destination !== "Primary.Family" && item.destination !== "Primary.Status") {
      // If the source is not in the set of unique sources, add it and create the object
      uniqueSources.add(item.source);
      return {
        id: index++,
        cells: {
          field1: item.source,
          field2:
            deltaMap?.filter((x) => x.columnName === item.source).length > 0
              ? '1'
              : '0',
          field3: item.fieldType,
          field4: item.isPrimary ? '1' : '0',
        },
      };
    } else {
      return {
        id: -1,
        cells: {
          field1: item.source,
          field2:
            deltaMap?.filter((x) => x.columnName === item.source).length > 0
              ? '1'
              : '0',
          field3: item.fieldType,
          field4: item.isPrimary ? '1' : '0',
        }
      }; 
    }
  }).filter((item) => item.id !== -1);
}