import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { Dialog, Button } from "../../../common/HarmonyEnablers";
import { useNavigate } from "react-router-dom";
import { MergeRule } from "../../../model/merge-rules/MergeRule.model";
import { postMergeRule } from "../../../services/get-merge-rules/PostMergeRule.api";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Loader } from "../../../shared-components/loader/Loader";
import { addMergeRulesByModel, updateMergeRuleByModel } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux";
import { updateDefaultWarningMsg } from "../../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { updateMergeRule } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { Icon } from "../../workflows/WorkflowsScope";
import { addMergeRuleBySubKey } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleBySubKey.redux";

export const CreateMergeRuleConfirmation: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch();
  const { typeChange, setOpenTypeChangePopup, mergeRuleAddedList, setDisableCreate, setOpenCreateRuleConfirmPopup, defaultMergeRule,
    setShowSuccessNotif, setSuccessText, setShowErrorNotif, setErrorText, currentMergeType, nextTarget, setcurrentMergeType } = props
  const postMergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.status)

  const handleRequestClose = (event: any) => {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      if (typeChange) {
        setOpenTypeChangePopup(false)
      } else {
        setOpenCreateRuleConfirmPopup(false)
      }
    }
  };

  const handleSave = async () => {
    var output = await dispatchApp(postMergeRule({ mergeRule: defaultMergeRule }))
    if (output.payload === true) {
      setDisableCreate(true)
      dispatch(addMergeRuleBySubKey(defaultMergeRule))
      dispatch(addMergeRulesByModel(defaultMergeRule))
      dispatch(updateMergeRule(defaultMergeRule))
      dispatch(updateMergeRuleByModel(defaultMergeRule))
      setDisableCreate(true)
      setOpenCreateRuleConfirmPopup(false)
      setSuccessText(defaultMergeRule.mergeRuleId + " created successfully.")
      setShowSuccessNotif(true)
      dispatchApp(updateDefaultWarningMsg(false))
    } else {
      setDisableCreate(false)
      setErrorText(defaultMergeRule.mergeRuleId + " failed to create.")
      setShowErrorNotif(true)
    }
  }

  const handleYesTypeChange = () => {
    setcurrentMergeType(nextTarget)
    setOpenTypeChangePopup(false)
  }

  const handleNoTypeChange = () => {
    setOpenTypeChangePopup(false)
  }

  return (
    <>
      <Dialog heading={typeChange ? "Default Merge Confirmation" : "Create Default MergeRule"} open onHeRequestClose={handleRequestClose}
        onHeAfterHide={
          ({ target, currentTarget }) => (target === currentTarget ? (false) : null)
        }
      >
        {typeChange &&
          <>
            <p style={{ fontSize: "14px" }}> Choosing Default Merge helps you create a merge rule with default configurations for this Insight/Recommendation. But this Insight/Recommendation is already part of other merge rules.<br/> Going ahead with Default Merge can result in creating duplicate recommendations.</p>
            <p style={{ fontSize: "14px" }}> <b>Are you sure you still want to choose Default Merge?</b> </p>
            {
              mergeRuleAddedList && mergeRuleAddedList.length > 0 &&
              mergeRuleAddedList.map((eachMR: MergeRule) => {
                return <div style={{ fontSize: "14px" }} >{eachMR.mergeRuleId}</div>
              })
            }
            <Button slot="footer" appearance="primary" onClick={() => { handleYesTypeChange() }}>
              Yes
            </Button>
            <Button slot="footer" onClick={() => { handleNoTypeChange() }}>
              No
            </Button>
          </>
        }
        {!typeChange && <>
          {postMergeruleStatus === RequestStatus.loading &&
            <Loader shape={"rect"} label={"Creating MergeRule..."} />
          }
          {postMergeruleStatus !== RequestStatus.loading &&
            <>
              <p style={{ fontSize: "14px" }}> Workflow is already part of below merge rules. There is probability of creating duplicate recommendations if the rule sets in this merge rule matches with any of them in below list of merge rules.</p>
              <p style={{ fontSize: "14px" }}> Are you sure you still want to create default mergerule: <b>{defaultMergeRule.mergeRuleId}</b>? </p>
              {
                mergeRuleAddedList && mergeRuleAddedList.length > 0 &&
                mergeRuleAddedList.map((eachMR: MergeRule) => {
                  return <div style={{ fontSize: "14px" }} ><Icon slot="start" name="sendfill" style={{fontSize: "13px"}}></Icon> {eachMR.mergeRuleId}</div>
                })
              }
              <Button slot="footer" appearance="primary" onClick={() => { handleSave(); }}>
                Create
              </Button>
              <Button slot="footer" onClick={() => {
                setOpenCreateRuleConfirmPopup(false)
              }}>
                Cancel
              </Button>
            </>
          }
        </>
        }
      </Dialog>
    </>
  );
};
