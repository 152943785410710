import { PreviewWorkflowHeaderBar } from "../preview-workflow/PreviewWorkflowHeaderBar"
import { PreviewWorkflowPanel } from "../preview-workflow/PreviewWorkflowPanel"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../root-redux/RootState"
import { PreviewMergeRuleHeaderBar } from "./PreviewMergeRuleHeaderBar"
import { PreviewMergeRulePanel } from "./PreviewMergeRulePanel"
import React, { useEffect, useState } from "react"
import { RequestStatus } from "../../model/ServiceRequestStatus.model"
import { Loader } from "../../shared-components/loader/Loader";
import { PreviewOutputColumns, PreviewOutputGrid, previewOutputData } from "./PreviewOutputColumn"
import { DataGrid, HoverCard } from "../merge-rules/MergeRulesScope"
import { PreviewPredictionColumns, PreviewPredictionGrid, previewPredictionData } from "./PreviewPredictionOutput"
import { PreviewRecommendationColumns, PreviewRecommendationGrid, previewRecommendationData } from "./PreviewRecommendationOutput"
import { PreviewInsightColumns, PreviewInsightGrid, previewInsightData } from "./PreviewInsightOutput"
import { Accordion, AccordionItem } from "../../common/HarmonyEnablers"
import { getModelMapping } from "../../services/GetModelMapping.api"
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api"
import { PreviewMergeRuleUserInput } from "./PreviewMergeRuleUserInput"
import { updatePreviewMergeRuleUserInput } from "./preview-mergerule-redux/PreviewMergeRule.actions.redux"
import { getErrorResponse } from "./PreviewFunctions"
import { PreviewMergeRuleShowOutput } from "./PreviewMergeRuleShowOutput"
import { Insight } from "../../model/preview-mergerule/Insight.model"

export const PreviewMergeRuleBody: React.FC<any> = (props: any) => {
    const dispatchApp = useDispatch<AppDispatch>()
    const dispatch = useDispatch()
    const {source, subKey, mergeRuleName, workflowId, previewInput, setPreviewInput,workflowType, mergeruleSetId, localmergeRule } = props
    const requestStatus = useSelector((state: RootState) => state.previewMergeRule.status)
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule)
    const previewMergeRule = useSelector((state: RootState) => state.previewMergeRule.previewMergeRuleOutput)
    const previewMergeRuleUserInput = useSelector((state: RootState) => state.previewMergeRule.previewMergeRuleUserInput)
    const previewMergeRuleError = useSelector((state: RootState) => state.previewMergeRule.error)
    const previewMergeRuleErrorCode = useSelector((state: RootState) => state.previewMergeRule.errorCode)
    const previewMergeRuleErrorCustomMsg = useSelector((state: RootState) => state.previewMergeRule.customMsg)

    const models = useSelector((state: RootState) => state.modelMapping.modelMappingObject)
    const [previewOutput, setPreviewOutput] = useState([] as PreviewOutputGrid[]);
    const [previewOutputPred, setPreviewOutputPred] = useState([] as PreviewPredictionGrid[]);
    const [previewOutputRec, setPreviewOutputRec] = useState([] as PreviewRecommendationGrid[]);
    const [previewOutputIns, setPreviewOutputIns] = useState([] as PreviewInsightGrid[]);

    const [input, setInput] = useState("")
    const [predictionsMap, setPredictionsMap] = useState([] as [string, string][])
    const [insightsMap, setInsightsMap] = useState([] as [string, string, string][])
    const [recommendationsMap, setRecommendationsMap] = useState([] as [string, string][])

    const [inputpredictionsMap, setInputPredictionsMap] = useState([] as [string, string][])
    const [inputinsightsMap, setInputInsightsMap] = useState([] as [string, string,string][])
    const [previewInputPred, setPreviewInputPred] = useState([] as PreviewPredictionGrid[])
    const [previewInputIns, setPreviewInputIns] = useState([] as PreviewInsightGrid[])

    useEffect(() => {
        dispatchApp(getModelMapping({ name: "", id: "" }))
        // dispatchApp(getMergeRuleById(mergeRuleName))
    }, [dispatchApp])
    
    useEffect(() => {
        if(!localmergeRule) {
            dispatchApp(getMergeRuleById(mergeRuleName))
        }
    },[localmergeRule])

    useEffect(() => {
        if (!previewMergeRuleError && previewMergeRule?.tpidList) {
            var tpid = previewMergeRule.tpidList.length > 0 ? previewMergeRule.tpidList.join(",") : ""
            setInput(tpid)
            dispatch(updatePreviewMergeRuleUserInput({
                ...previewMergeRuleUserInput, 
                tpids: previewMergeRule.tpidList, 
                distinctField: previewMergeRule.distinctField,
                mergeRuleSetId: mergeruleSetId,
            }))
            var localPreds: [string, string][] = []
            var localRecs: [string, string][] = []
            var localIns: [string, string, string][] = []

            if (previewMergeRule.predictions && previewMergeRule.predictions.length > 0) {
                var i = 1;
                previewMergeRule.predictions.forEach((item) => {
                    localPreds.push([item.id, "P" + i])
                    i++
                })
            }
            setPredictionsMap(localPreds)

            if (previewMergeRule.recommendations && previewMergeRule.recommendations.length > 0) {
                var j = 1;
                previewMergeRule.recommendations.forEach((item) => {
                    localRecs.push([item.id, "R" + j])
                    j++
                })
            }
            setRecommendationsMap(localRecs)

            if (previewMergeRule.insights && previewMergeRule.insights.length > 0) {
                var k = 1;
                previewMergeRule.insights.forEach((item) => {
                    var digest = ""
                    var digestObj = item.systemAttributes.find(x => {return x.key === "Digest"})
                    if(digestObj) digest = digestObj.value
                    localIns.push([item.id, "I" + k, digest])
                    k++
                })
            }
            setInsightsMap(localIns)

            setPreviewOutput(previewOutputData(previewMergeRule, localPreds, localRecs, localIns, localmergeRule || mergeRule, previewMergeRuleUserInput))
            setPreviewOutputPred(previewPredictionData(previewMergeRule, localPreds, localRecs, localIns, models, localmergeRule || mergeRule, previewMergeRuleUserInput))
            setPreviewOutputRec(previewRecommendationData(previewMergeRule, localPreds, localRecs, localIns, models,localmergeRule || mergeRule, previewMergeRuleUserInput))
            setPreviewOutputIns(previewInsightData(previewMergeRule, localPreds, localRecs, localIns, models, localmergeRule || mergeRule, previewMergeRuleUserInput))

        } else {
            var localPreds: [string, string][] = []
            var localRecs: [string, string][] = []
            var localIns: [string, string,string][] = []
            
            if (previewInput.predictions && previewInput.predictions.length > 0) {
                var i = 1;
                previewInput.predictions.forEach((item: any) => {
                    localPreds.push([item.id, "P" + i])
                    i++
                })
            }
            setInputPredictionsMap(localPreds)

            if (previewInput.insights && previewInput.insights.length > 0) {
                var k = 1;
                previewInput.insights.forEach((item: Insight) => {
                    var digest = ""
                    var digestObj = item.systemAttributes.find(x => {return x.key === "Digest"})
                    if(digestObj) digest = digestObj.value
                    localIns.push([item.id, "I" + k, digest])
                    k++
                })
            }
            setInputInsightsMap(localIns)
            setPreviewInputPred(previewPredictionData(previewInput, localPreds, localRecs, localIns, models, localmergeRule || mergeRule, previewMergeRuleUserInput))
            setPreviewInputIns(previewInsightData(previewInput, localPreds, localRecs, localIns, models, localmergeRule || mergeRule, previewMergeRuleUserInput))
        }
    }, [previewMergeRule])

    if (requestStatus === RequestStatus.loading) {
        return <Loader className='mergerule-loader' shape='rect' label="Loading Preview..." />
    }

    var errorResponse = getErrorResponse(previewMergeRuleErrorCode,previewMergeRuleErrorCustomMsg || "", previewMergeRuleUserInput.inputFrom, workflowId, (previewInput.predictions.length > 0 || previewInput.insights.length > 0), workflowType, previewMergeRuleUserInput)

    return (
        <>
            {!previewMergeRuleError &&
                <div>
                    <PreviewMergeRuleUserInput source={source} previewInput={previewInput} setPreviewInput={setPreviewInput} workflowId={workflowId} mergeRuleName={mergeRuleName} subKey={subKey} mergeruleSetId={mergeruleSetId} mergeRule={localmergeRule || mergeRule} />
                    <PreviewMergeRuleShowOutput previewOutput={previewOutput} previewOutputPred={previewOutputPred} previewOutputIns={previewOutputIns} previewOutputRec={previewOutputRec} previewMergeRuleUserInput={previewMergeRuleUserInput} />
                </div>
            }
            {previewMergeRuleError &&
                <>
                    <PreviewMergeRuleUserInput source={source} previewInput={previewInput} setPreviewInput={setPreviewInput} workflowId={workflowId} mergeRuleName={mergeRuleName} subKey={subKey} mergeruleSetId={mergeruleSetId} mergeRule={localmergeRule || mergeRule}/>
                    <Accordion>
                        <AccordionItem>
                            <span slot="heading">Preview Failure Summary</span>
                            <table className='mergerule-form-table'>
                                {errorResponse?.error &&
                                    <tr>
                                        <td>Error:</td>
                                        <td>{errorResponse?.error || ""}</td>
                                    </tr>
                                }
                                {errorResponse?.reasons && errorResponse?.reasons.length > 0 &&
                                    <tr>
                                        <td>Reason:</td>
                                        <td>{errorResponse?.reasons.map((eachReason) => {
                                            return <div>{eachReason}</div>
                                        })}</td>
                                    </tr>
                                }
                                {errorResponse?.possibleSolutions && errorResponse?.possibleSolutions.length > 0 &&
                                    <tr>
                                        <td>Solution(s):</td>
                                        <td>{errorResponse?.possibleSolutions.map((eachSolution) => {
                                            return <div>{eachSolution}</div>
                                        })}</td>
                                    </tr>
                                }

                            </table>

                        </AccordionItem>

                    </Accordion>
                    <Accordion>
                        <AccordionItem>
                            <span slot="heading">Input Data on which Preview was run</span>
                            {
                                workflowId && (previewInput.predictions.length > 0 || previewInput.insights.length > 0) &&
                                <div>
                                    {previewInputPred.length > 0 &&
                                        <AccordionItem>
                                            <span slot="heading">Input Recommendations(s)</span>

                                            <DataGrid fixedHeading={true}
                                                rows={previewInputPred} columns={PreviewPredictionColumns}  >
                                                <span slot="no-records">No Input Recommendations found.</span>
                                                {previewOutputPred.map(row2 => {
                                                    return (
                                                        <>
                                                            <HoverCard slot={`field3-${row2.id}`} key={`field3-${row2.id}`} placement={"start"} fixedPlacement={true} heading="">
                                                                <div role="button" slot="anchor" key={`field3-${row2.id}`}>{row2.cells.field3 === null || row2.cells.field3 === '' ? '' : row2.cells.field3 != null && row2.cells.field3.length > 30 ? row2.cells.field4.substring(0, 30) + "..." : row2.cells.field3}</div>
                                                                {row2.cells.field3 != undefined &&
                                                                    <code style={{ color: "black" }}>{row2.cells.field3.split(",").map(eachRow2 => {
                                                                        return <div>{eachRow2}</div>
                                                                    })}
                                                                    </code>
                                                                }
                                                                {row2.cells.field3 == undefined &&
                                                                    <code style={{ color: "black" }}>
                                                                    </code>
                                                                }
                                                            </HoverCard>
                                                            <HoverCard slot={`field4-${row2.id}`} key={`field4-${row2.id}`} placement={"start"} fixedPlacement={true} heading="">
                                                                <div role="button" slot="anchor" key={`field4-${row2.id}`}>{row2.cells.field4 === null || row2.cells.field4 === '' ? '' : row2.cells.field4 != null && row2.cells.field4.length > 30 ? row2.cells.field4.substring(0, 30) + "..." : row2.cells.field4}</div>
                                                                {row2.cells.field4 != undefined &&
                                                                    <code style={{ color: "black" }}>{row2.cells.field4.split(",").map(eachRow21 => {
                                                                        return <div>{eachRow21}</div>
                                                                    })}
                                                                    </code>
                                                                }
                                                                {row2.cells.field4 == undefined &&
                                                                    <code style={{ color: "black" }}>
                                                                    </code>
                                                                }
                                                            </HoverCard>
                                                        </>)
                                                })}
                                            </DataGrid>
                                        </AccordionItem>
                                    }
                                    {previewInputIns.length > 0 &&
                                        <AccordionItem>
                                            <span slot="heading">Input Insight(s)</span>

                                            <DataGrid fixedHeading={true}
                                                rows={previewInputIns} columns={PreviewInsightColumns}  >
                                                <span slot="no-records">No Input Insights found.</span>
                                                {previewOutputIns.map(row => {
                                                    return (
                                                        <>
                                                        </>)
                                                })}
                                            </DataGrid>
                                        </AccordionItem>}
                                </div>
                            }
                        </AccordionItem>
                    </Accordion>
                </>
            }
        </>
    )
}