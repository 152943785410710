import { workflowByIdSlice } from "./WorkflowById.redux";

export const {
    updateRunJobType,
    setShowSchedulePanel,
    setOpenAttributesPanel,
    updateSchedule,
    updateScheduleClusterId,
    resetPostWorkflowStatus,
    updateIsSideNavOpen,
    resetWorkflowStatus,
    resetWorkflow,
    addNewWorkflow,
    updateCurrentStage,
    updateCurrentStepId,
    updateTypeOfMerge,
    updateEnableSave,
    updateDefaultWarningMsg,
    //updateDefaultMergeRule,
    addDataSet,
    deleteDataSet,
    moveDataSet,
    saveWorkflow,
    updateBasics,
    updateIngestion,
    updateTransformation,
    updateDelta,
    updatePublish,
    cloneWorkflow,
    updateUnsavedChanges,
    updatePublishMapChanged,
    resetPostScheduleWorkflowStatus,
    setWorkflowDatasetFileUploadState,
    setWorkflowName,
    setWorkflowDescription
} = workflowByIdSlice.actions;
