import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest } from "../utils/PostRequest"
import { IMergeRuleResponse, MergeRule } from "../../model/merge-rules/MergeRule.model"
import { RootState } from "../../root-redux/RootState"

export const postMergeRule = createAsyncThunk<any,{ mergeRule: MergeRule},{state: RootState}>('mergerules/postMergeRule', async ({mergeRule},{getState}) => {
    const selectedEnvItem = getState().env.selectedEnvItem;
    const payload = mergeRule
    const response = await postRequest<IMergeRuleResponse,MergeRule>(selectedEnvItem+'/mergerules', payload)
    return response.data
  })
