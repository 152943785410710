import { combineReducers } from "redux";
import { navReducer } from "../core-components/nav-panel/nav-panel-redux/NavPanel.redux";
import { workflowsReducer } from '../feature-components/workflows/workflows-redux/Workflows.redux'
import { connectionsReducer } from "../feature-components/connections/connections-redux/Connections.redux";
import { appPersonaReducer } from "../shared-components/app-personas/appPersona-redux";
import { workflowByIdReducer } from "../feature-components/workflows/workflow-by-id-redux/WorkflowById.redux";
import { previewWorkflowReducer } from "../feature-components/preview-workflow/preview-workflow-redux/PreviewWorkflow.redux";
import { userDetailsReducer } from "../shared-components/user-details/UserDetails.redux";
import { userGroupsReducer } from "../shared-components/user-groups/UserGroups.redux";
import { tenantStatsReducer } from "../feature-components/home/tenantStats-redux/TenantStats.redux";
import { tenantAdminReducer } from "../feature-components/tenant-admin/tenant-admin-redux/TenantAdmin.redux";
import { isSuperAdminReducer } from "../shared-components/super-admin/IsSuperAdmin.redux";
import { superAdminReducer } from "../shared-components/super-admin/GetSuperAdminEmail.redux";
import { apiConfigReducer } from "../feature-components/api-configuration/api-configuration-redux/ApiConfig.redux";
import { envReducer } from "../core-components/user-profile/user-profile-redux/UserProfile.redux";
import { catalogueConfigReducer } from "../feature-components/configs/CatalogueConfig.redux";
import { typeMappingConfigReducer } from "../feature-components/configs/TypeMappingConfig.redux";
import { feedbackReducer } from "../shared-components/feedback/Feedback.redux";
import { releaseDataReducer } from "../feature-components/release-management/ReleaseData.redux";
import { releasesReducer } from "../feature-components/release-management/Releases.redux";
import { modelMappingConfigReducer } from "../feature-components/configs/ModelMappingConfig.redux";
import { mergeRulesReducer } from "../feature-components/merge-rules/merge-rules-redux/MergeRules.redux";
import {  mergeRuleByModelReducer } from "../feature-components/merge-rules/merge-rules-by-id-redux/MergeRuleByModel.redux";
import { personaMappingConfigReducer } from "../feature-components/configs/PersonaMappingConfig.redux";
import { mergeRuleByIdReducer } from "../feature-components/merge-rules/merge-rules-by-id-redux/MergeRuleById.redux";
import { workflowsBySubKeyReducer } from "../feature-components/workflows/workflow-by-subKey-redux/WorkflowBySubKey.redux";
import { typeMappingConfigByKeyReducer } from "../feature-components/configs/TypeMappingConfigByKey.redux";
import { mergeRuleBySubKeyReducer } from "../feature-components/merge-rules/merge-rules-by-id-redux/MergeRuleBySubKey.redux";
import { previewMergeRuleReducer } from "../feature-components/preview-mergerule/preview-mergerule-redux/PreviewMergeRule.redux";
import { apiPreviewRecommendationsReducer } from "../feature-components/preview-api/preview-api-redux/PreviewRecommendations.redux";
import { apiPreviewInsightsReducer } from "../feature-components/preview-api/preview-api-redux/PreviewInsights.redux";
import { userObjectsReducer } from "../shared-components/user-object/AllUserObjects.redux";
import { userObjectsOfMergeRuleReducer } from "../shared-components/user-object/AllUserObjectsOfMergeRules.redux";
import { appPersonaBySubkeyReducer } from "../shared-components/app-personas/appPersonaBySubkey-redux";
import { getTestApiSliceReducer } from "../feature-components/test-api/TextApi.redux";
import { getTestApiGetEntityIdsSlice, getTestApiGetEntityIdsSliceReducer } from "../feature-components/test-api/TestApiToGetEntityIds.redux";
import { getEntityIdsSliceReducer } from "../feature-components/test-api/GetEntityIds.redux";
import { campaignListReducer } from "../feature-components/spa-admin/redux/CampaignList.redux";
import { CampaignByIdReducer } from "../feature-components/spa-admin/redux/CampaignById.redux";
import { campaignConfigReducer } from "../feature-components/spa-admin/redux/CampaignConfig.redux";
import { CampaignDataCartReducer } from "../feature-components/spa-admin/redux/CampaignDataCart.redux";
import { ListFilterReducer } from "../feature-components/spa-admin/redux/ListFilter.redux";
import { TaxonomySliceReducer } from "../feature-components/spa-admin/redux/Taxonomy.redux";
import { CacheHandlerReducer } from "../feature-components/spa-admin/redux/CacheHandler.redux";
import { FilterCampaignReducer } from "../feature-components/spa-admin/redux/CampaignQueryBuilder.redux";
import { UserSalesRolesReducer } from "../feature-components/spa-admin/redux/UserSalesRoles.redux";
import { DatasetFileUploadReducer} from "../feature-components/workflows/workflows-redux/DatasetFileUpload.redux";
import { datasetListReducer } from "../feature-components/spa-admin/redux/DatasetList.redux";
import { DatasetListFilterReducer } from "../feature-components/spa-admin/redux/DatasetListFilter.redux";
import { DatasetConfigReducer } from "../feature-components/spa-admin/redux/DatasetConfig.redux";
import { DatasetByIdReducer } from "../feature-components/spa-admin/redux/DatasetById.redux";

export const rootReducer = combineReducers({ 
    nav: navReducer, 
    env: envReducer, 
    workflows: workflowsReducer,
    connections: connectionsReducer,
    workflowById: workflowByIdReducer,
    previewWorkflow: previewWorkflowReducer,
    appPersonas: appPersonaReducer,
    userGroups: userGroupsReducer,
    userDetails: userDetailsReducer,
    userObjects: userObjectsReducer,
    userObjectsOfMergeRule: userObjectsOfMergeRuleReducer,
    tenantStats: tenantStatsReducer,
    isSuperAdmin: isSuperAdminReducer,
    superAdmin: superAdminReducer,
    tenantAdmin: tenantAdminReducer,
    apiConfig: apiConfigReducer,
    catalogueConfig: catalogueConfigReducer,
    typeMappingConfig: typeMappingConfigReducer,
    feedback: feedbackReducer,
    modelMappingConfig: modelMappingConfigReducer,
    mergeRules: mergeRulesReducer,
    mergeRuleById: mergeRuleByIdReducer,
    personaMappingConfig: personaMappingConfigReducer,
    mergeRuleByModel: mergeRuleByModelReducer,
    modelMapping: modelMappingConfigReducer,
    workflowsBySubKeys: workflowsBySubKeyReducer,
    typeMappingConfigByKey: typeMappingConfigByKeyReducer,
    mergeRuleBySubKey: mergeRuleBySubKeyReducer,
    previewMergeRule: previewMergeRuleReducer,
    releaseData: releaseDataReducer,
    releases: releasesReducer,
    apiPreviewRecommendations: apiPreviewRecommendationsReducer,
    apiPreviewInsights: apiPreviewInsightsReducer,
    getTestApi: getTestApiSliceReducer,
    appPersonaBySubkey : appPersonaBySubkeyReducer,
    getTestApiEntityIds : getTestApiGetEntityIdsSliceReducer,
    getEntityIds: getEntityIdsSliceReducer,
    campaignList: campaignListReducer,
    campaignById: CampaignByIdReducer,
    campaignConfig: campaignConfigReducer,
    campaignDataCart: CampaignDataCartReducer,
    listFilter: ListFilterReducer,
    taxonomy: TaxonomySliceReducer,
    cacheHandler: CacheHandlerReducer,
    filteredCampaign: FilterCampaignReducer,
    userSalesRoles: UserSalesRolesReducer,
    fileuploadState: DatasetFileUploadReducer,
    datasetList: datasetListReducer,
    datasetListFilter: DatasetListFilterReducer,
    datasetConfig: DatasetConfigReducer,
    datasetById: DatasetByIdReducer
});
