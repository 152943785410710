import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { CAMPAIGN_ROWS_PER_PAGE } from "../../../services/utils/Constants";
import { CampaignFields, QueryBuilderFields } from "../SPAConstants";

type pair = { key: string, value: string };
const prefix = (num: number) => (num < 10 ? "0" : "") + num;

export class Helper
{
    public static isEmpty(object: any | undefined) {
        return object === undefined || object === null || Object.keys(object).length === 0;
    }

    public static getDate() {
        const datetime = new Date();
        return prefix(datetime.getUTCMonth()+1) + "-" + prefix(datetime.getUTCDate()) + "-" + prefix(datetime.getUTCFullYear())+"_"+datetime.getUTCHours()+"-"+datetime.getUTCMinutes()+"-"+datetime.getUTCSeconds();
    }

    public static getCurrentDatetime() {
        const datetime = new Date();
        const date = prefix(datetime.getUTCMonth()+1) + "/" + prefix(datetime.getUTCDate()) + "/" + prefix(datetime.getUTCFullYear());
        return date + " " + prefix(datetime.getUTCHours()) + ":" + prefix(datetime.getUTCMinutes()) + ":" + prefix(datetime.getUTCSeconds());
    }

    public static notReadyStatus(...objs: any) {
        return Array.from(objs).some((obj: any) => [RequestStatus.loading, RequestStatus.idle].includes(obj));
    }

    public static isLoadingStatus(...objs: any) {
        return Array.from(objs).some((obj: any) => obj === RequestStatus.loading);
    }

    public static toObject(listOfPairs: pair[] | undefined): {[key:string]: string | undefined} {
        if (Helper.isEmpty(listOfPairs)) return {};
        const ret = listOfPairs!.reduce((a, b) => {
            const s: any = Object.hasOwn(a, "key") ? {[a.key]: a.value} : a ;
            return {...s, [b.key]: b.value};
        });
        return {...ret};
    }

    public static fetchCampaignFieldsToSummary(campaign: Campaign) {
        const summary = Helper.toObject(campaign.summary);
        if(campaign && campaign.config)
        {
            summary[CampaignFields.CampaignName] = campaign.config.workflowName;
            summary[CampaignFields.Description] = campaign.description;
            summary[CampaignFields.StartOn] = campaign.startOnDate;
            summary[CampaignFields.ExpiryDate] = campaign.expiryDate;
            summary[CampaignFields.ActionByDate] = campaign.actionByDate;
            summary[CampaignFields.Tags] = campaign.tags;
            summary[CampaignFields.SubscriptionKey] = campaign.subscriptionKey;
        }
        return summary;
    }

    public static getTotalPage(len: number) {
        if (len <= CAMPAIGN_ROWS_PER_PAGE) {
            return 1;
        }
        const pages = Math.floor(len / CAMPAIGN_ROWS_PER_PAGE);
        const remaining = len - (CAMPAIGN_ROWS_PER_PAGE * pages);
        return pages + (remaining  > 0 ? 1 : 0);
    }

    public static filterFields(fields: any|undefined) {
        const queryFields = Object.entries(QueryBuilderFields).map(([k, v]) => v.toString().toLowerCase());
        const filtered: any = {};
        for(const k in (fields||{})) {
            if (queryFields.includes(k.toLowerCase())) {
                filtered[k] = fields[k];
            }
        }
        return filtered;
    }

    public static isReadyToLoad = (userSalesRoles: any) => userSalesRoles.isApprover || userSalesRoles.isSpaUser || userSalesRoles.isSpaAdmin || userSalesRoles.otherUser;
}