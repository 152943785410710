import React from 'react';
import { Icon, Button, Tooltip } from '../../common/HarmonyEnablers';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import './nav-panel-styles.css'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../root-redux/RootState';
import { setIsNavPanelOpen } from './nav-panel-redux/NavPanel.actions.redux';
import { SpaImpersonation } from '../../feature-components/spa-admin/spa-Impersonation';
import { allLocalLinks } from '../../shared-content/AllLinks';

export const NavPanel: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>();
  const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
  const selectedNavItem = useSelector((state: RootState) => state.nav.selectedNavItem)
  const nebulaReportsUrl = allLocalLinks.nebulaReports;

  const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
    navigate("/"+ NavMenuItemType[navMenuItemType]);
  }

  const iconPath = '../assets/icons/'
  
  return (
    <div className={`nav-panel ${!isNavPanelOpen ? 'collapsed': ''}`}>
      
      <div className='nav-menu-icon'>
        <Button id = "hamburgersidenav" appearance="stealth" onClick={() => dispatch(setIsNavPanelOpen(!isNavPanelOpen))}>
          <Icon label="nav menu button" name="globalnavbutton" />
        </Button>
      </div>

      <div 
      onKeyDown={e => e.key === "Enter" &&  _onNavMenuItemClick(NavMenuItemType.Home)}
      className={`nav-item ${selectedNavItem === NavMenuItemType.Home ? 'selected': ''}`} 
      onClick={() => _onNavMenuItemClick(NavMenuItemType.Home)}
      tabIndex={0}
      >
        <div id='home'>
        <Icon slot='start' url={iconPath+'landing.svg'} id = "homesidenavicon"></Icon>
        {isNavPanelOpen && (<span id = "homesidenavtext">Home</span>)}      
        </div>
        <Tooltip anchor='home'>
          Home
        </Tooltip>
      </div>

      <div 
      onKeyDown={e => e.key === "Enter" &&  _onNavMenuItemClick(NavMenuItemType.WorkFlows)}
      className={`nav-item ${selectedNavItem === NavMenuItemType.WorkFlows ? 'selected': ''}`} 
      onClick={() => _onNavMenuItemClick(NavMenuItemType.WorkFlows)}
      tabIndex={0}
      >
        <div id='workflowList'>
        <Icon slot='start' url={iconPath+'onboarding.svg'} id = "workflowsidenavicon"></Icon>
        {isNavPanelOpen && (<span id = "workflowsidenavtext">Insights & Recommendations</span>)}
        </div> 
        <Tooltip anchor='workflowList'>
          Insights & Recommendations
        </Tooltip>     
      </div>

      <div 
      onKeyDown={e => e.key === "Enter" && _onNavMenuItemClick(NavMenuItemType.Configuration)}
      className={`nav-item ${selectedNavItem === NavMenuItemType.Configuration ? 'selected': ''}`} 
      onClick={() => _onNavMenuItemClick(NavMenuItemType.Configuration)}
      tabIndex={0}
      >
        <div id='mergeRuleList'>
        <Icon style={{color: "#0078D4"}} slot='start' name="branchmerge" id = "mergerulesidenavicon"></Icon>
        {isNavPanelOpen && (<span id = "mergerulesidenavtext">Configuration</span>)}
        </div> 
        <Tooltip anchor='mergeRuleList'>
          Define rules to Generate a recommendation
        </Tooltip>     
      </div>

      <div 
      onKeyDown={e => e.key === "Enter" &&  _onNavMenuItemClick(NavMenuItemType.TenantAdmin)}
      className={`nav-item ${selectedNavItem === NavMenuItemType.TenantAdmin ? 'selected': ''}`} 
      onClick={() => _onNavMenuItemClick(NavMenuItemType.TenantAdmin)}
      tabIndex={0}
      >
        <div id='tenantadmin'>
        <Icon slot='start' url={iconPath+'user-settings.svg'} id = "tenantadminsidenavicon"></Icon>        
        {isNavPanelOpen && (<span id = "tenantadminsidenavtext">Tenant Admin</span>)}
        </div>
        <Tooltip anchor='tenantadmin'>
          Tenant Admin
        </Tooltip>
      </div>

      <div 
      onKeyDown={e => e.key === "Enter" &&  window.open( nebulaReportsUrl,'_blank')}
      className={`nav-item ${selectedNavItem === NavMenuItemType.Reports ? 'selected': ''}`} 
      onClick={() => {window.open( nebulaReportsUrl,'_blank')}}
      tabIndex={0}
      >
        <div id='reports'>
        <Icon slot='start' url={iconPath+'reports.svg'} id = "resportsidenavicon"></Icon>    
        {isNavPanelOpen && (<span id = "resportsidenavtext">Reports</span>)}
        </div>
        <Tooltip anchor='reports'>
          Reports
        </Tooltip>
      </div>

      <SpaImpersonation />
      
    </div>
  );
}