import { ConnectionType } from "../../model/connections/ConnectionType.model";
const path = '../assets/icons'
export const ConnectionsIcons = {
    [ConnectionType.adlsConnections]: `${path}/adls.svg`,
    [ConnectionType.adlsGen1Connections]: `${path}/adls.svg`,
    [ConnectionType.apiConnections]: `${path}/api.svg`,
    [ConnectionType.blobConnections]: `${path}/blob.svg`,
    [ConnectionType.cosmosConnections]: `${path}/cosmos.svg`,
    [ConnectionType.eventConnections]: `${path}/event.svg`,
    [ConnectionType.sqlConnections]: `${path}/sql.svg`,
    [ConnectionType.sqlaadConnections]: `${path}/sqlaad.svg`,
    [ConnectionType.topicConnections]: `${path}/topic.svg`
}