import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { AppPersona } from '../../model/app-persona/AppPersona.model'
import { getAppPersonaBySubKey } from '../../services/GetAppPersonaMappingBySubkey.api'

interface AppPersonaState {
    appPersona: AppPersona,
    status: RequestStatus,
    error: string | undefined
}

const initialState: AppPersonaState = {
  appPersona: {} as AppPersona,
  status: RequestStatus.idle,
  error: ''
}

const appPersonaBySubkeySlice = createSlice({
  name: 'appPersonabySubkey',
  initialState,
  reducers: {
    addApppersonaBySubKey: (state) => {
        state.appPersona = {} as AppPersona
      }
  },
  extraReducers(builder) {
    builder
      .addCase(getAppPersonaBySubKey.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getAppPersonaBySubKey.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        state.appPersona = action.payload[0]
        state.error = undefined
      })
      .addCase(getAppPersonaBySubKey.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
  }
})


export const appPersonaBySubkeyReducer = appPersonaBySubkeySlice.reducer