import { WorkflowSummary } from "../../model/workflows/Workflow.model"

export const countBrackets = (str: string) => {
  let counter = 0;
    for (let i = 0; i < str.length; i++) {
        if (str[i] === '(' ) {
            counter++;
        } else if (str[i] === ')') {
          counter--;
        }
    }
    return counter;
}

//MRTODO - optimise this
export const convertQueryToFilterFormat = (filter: any, fieldsList: [string, string, string][], workflowsBySubKeys: WorkflowSummary[]) => {
  var newfilter = ""
  if (filter) {
    if (filter.includes("AND")) {
      var splitfilter = filter.split("AND")
      for (var i = 0; i < splitfilter.length; i++) {
        var eachStr = splitfilter[i].trim()
        if (i !== splitfilter.length - 1) {
          eachStr += " && "
        }
        if (eachStr.includes("OR")) {
          var splitEachString = eachStr.split("OR")
          for (var j = 0; j < splitEachString.length; j++) {
            var eachSubStr = splitEachString[j].trim()
            if (j !== splitEachString.length - 1) {
              eachSubStr += " || "
            }
            newfilter += convertToSubQuery(eachSubStr, fieldsList, workflowsBySubKeys)
          }
        } else {
          newfilter += convertToSubQuery(eachStr, fieldsList, workflowsBySubKeys)
        }
      }
    } else if (filter.includes("OR")) {
      var splitEachString = filter.split("OR")
      for (var k = 0; k < splitEachString.length; k++) {
        var eachSubStr = splitEachString[k].trim()
        if (k !== splitEachString.length - 1) {
          eachSubStr += " || "
        }
        newfilter += convertToSubQuery(eachSubStr, fieldsList, workflowsBySubKeys)
      }
    } else {
      newfilter += convertToSubQuery(filter, fieldsList, workflowsBySubKeys)
    }
  }
  return newfilter;
}

const convertToSubQuery = (subFilter: any, fieldsList: [string, string, string][], workflowsBySubKeys: WorkflowSummary[]) => {
  var newSubFilter = ""
  if (subFilter.includes("COALESCE")) {
    var coalescefilteredFilter = subFilter.replace(/[,']/g, "")
    if (coalescefilteredFilter.includes("NOT")) {
      var local = coalescefilteredFilter.split("NOT")
      var prefix = local.length >= 2 ? local[0].trim() : ""
      coalescefilteredFilter = local.length >= 2 ? local[1].trim() : ""
    }

    if (coalescefilteredFilter.includes("=") || coalescefilteredFilter.includes("<>")) {
      var coalescefilterArray = coalescefilteredFilter.includes("=") ? coalescefilteredFilter.split("=") : coalescefilteredFilter.split("<>")
      var coalescefieldValue = coalescefilterArray[0].trim()
      var coalescefieldSuffix = coalescefilterArray.length >= 2 ? coalescefilterArray[1] : ""
      var coalescefieldValueArray = coalescefieldValue.split("COALESCE")
      var finalCoalescePrefix = coalescefieldValueArray[0].trim()
      var finalCoalesceFieldName = coalescefieldValueArray[1].trim().replace(/[\(\)]/g, "")

      var typeOfCoalescedField = ""
      if (finalCoalesceFieldName === "SourceId") {
        typeOfCoalescedField = "String"
      } else {
        var coalescedfieldExists = fieldsList.find(x => { return x[0] === finalCoalesceFieldName })
        if (coalescedfieldExists) {
          typeOfCoalescedField = fieldsList.filter(x => { return x[0] === finalCoalesceFieldName })[0][1]
        }
        else { typeOfCoalescedField = "String" }
      }

      var coalescedfieldKey = getFieldKey(typeOfCoalescedField, finalCoalesceFieldName);
      var coalescedfieldValue = getFieldValue(typeOfCoalescedField, "");

      newSubFilter += finalCoalescePrefix
      newSubFilter += typeOfCoalescedField === "String" && coalescefilteredFilter.includes("<>") ? "!" : ""
      newSubFilter += coalescedfieldKey
      newSubFilter += typeOfCoalescedField === "String" ? ".Equals(" : (coalescefilteredFilter.includes("=") ? " == " : " != ")
      newSubFilter += coalescedfieldValue
      newSubFilter += typeOfCoalescedField === "String" ? ")" : ""
      newSubFilter += coalescefieldSuffix
    }
  } else if (subFilter.includes("NOT")) {
    var finalValue = subFilter.match(/'((?:\\.|[^'\\])*)'/)
    var finalValueOfField = ""
    if (finalValue) {
      finalValueOfField = finalValue[1]
      subFilter = subFilter.replace(/'((?:\\.|[^'\\])*)'/, "~")
    }

    var splitBySpace = subFilter.trim().split(" ")
    if(subFilter.trim().startsWith("(")) {
    newSubFilter += "(! "
    } else {
      newSubFilter += "! "
    }
    var field = splitBySpace[1].trim()
    newSubFilter += field.replace(/[\w+]/g, "")

    var actualField = field.replace(/[\(\)]/g, "")
    var typeOfField = ""
    if (actualField === "SourceId") {
      typeOfField = "String"
    } else {
      var fieldExists = fieldsList.find(x => { return x[0] === actualField })
      if (fieldExists) {
        typeOfField = fieldsList.filter(x => { return x[0] === actualField })[0][1]
      }
      else { typeOfField = "String" }
    }
    var fieldKey = getFieldKey(typeOfField, actualField);
    if (splitBySpace[2].trim() === "<>NoCase") { newSubFilter += "!" }
    newSubFilter += fieldKey

    if (splitBySpace[2].trim() === "=" && typeOfField === "String") { newSubFilter += ".Equals(" }
    if (splitBySpace[2].trim() === "=" && typeOfField !== "String") { newSubFilter += " == " }
    if (splitBySpace[2].trim() === "<>") { newSubFilter += " != " }
    if (splitBySpace[2].trim() === "<") { newSubFilter += " < " }
    if (splitBySpace[2].trim() === ">") { newSubFilter += " > " }
    if (splitBySpace[2].trim() === "<=") { newSubFilter += " <= " }
    if (splitBySpace[2].trim() === ">=") { newSubFilter += " >= " }
    if (splitBySpace[2].trim() === "=NoCase") { newSubFilter += ".Equals(" }
    if (splitBySpace[2].trim() === "<>NoCase") { newSubFilter += ".Equals(" }

    var fieldSuffix = splitBySpace[3].replace(/[^\)!?]/g, '')

    var fieldValue = actualField === "SourceId" ? getSourceId(finalValueOfField, workflowsBySubKeys) : (finalValueOfField ? getFieldValue(typeOfField, finalValueOfField) : getFieldValue(typeOfField, splitBySpace[3].replace(/[\)]/g, '')))
    newSubFilter += fieldValue
    if (splitBySpace[2].trim() === "=" && typeOfField === "String") { newSubFilter += ")" }
    if (splitBySpace[2].trim() === "=NoCase") { newSubFilter += ",noCase)" }
    if (splitBySpace[2].trim() === "<>NoCase") { newSubFilter += ",noCase)" }
    newSubFilter += fieldSuffix
    if (splitBySpace.length >= 5) {
      newSubFilter += ` ${splitBySpace[4].trim()} `
    }
  } else {
    var finalValue = subFilter.match(/'((?:\\.|[^'\\])*)'/)
    var finalValueOfField = ""
    if (finalValue) {
      finalValueOfField = finalValue[1]
      subFilter = subFilter.replace(/'((?:\\.|[^'\\])*)'/, "~")
    }
    var splitBySpace = subFilter.trim().split(" ")
    var field = splitBySpace[0].trim()
    newSubFilter += field.replace(/[\w+]/g, "")
    var actualField = field.replace(/[\()]/g, "")
    var typeOfField = ""

    if (actualField === "SourceId") {
      typeOfField = "String"
    } else {
      var fieldExists = fieldsList.find(x => { return x[0] === actualField })
      if (fieldExists) {
        typeOfField = fieldsList.filter(x => { return x[0] === actualField })[0][1]
      }
      else { typeOfField = "String" }
    }

    var fieldKey = getFieldKey(typeOfField, actualField);
    if (splitBySpace[1].trim() === "<>NoCase") { newSubFilter += "!" }
    newSubFilter += fieldKey

    if (splitBySpace[1].trim() === "=" && typeOfField === "String") { newSubFilter += ".Equals(" }
    if (splitBySpace[1].trim() === "=" && typeOfField !== "String") { newSubFilter += " == " }
    if (splitBySpace[1].trim() === "<>") { newSubFilter += " != " }
    if (splitBySpace[1].trim() === "<") { newSubFilter += " < " }
    if (splitBySpace[1].trim() === ">") { newSubFilter += " > " }
    if (splitBySpace[1].trim() === "<=") { newSubFilter += " <= " }
    if (splitBySpace[1].trim() === ">=") { newSubFilter += " >= " }
    if (splitBySpace[1].trim() === "=NoCase") { newSubFilter += ".Equals(" }
    if (splitBySpace[1].trim() === "<>NoCase") { newSubFilter += ".Equals(" }

    var fieldSuffix = splitBySpace.length >= 3 ? splitBySpace[2].replace(/[^\)!?]/g, '') : ""
    var fieldValue = actualField === "SourceId" ? getSourceId(finalValueOfField, workflowsBySubKeys) : (finalValueOfField ? getFieldValue(typeOfField, finalValueOfField) : getFieldValue(typeOfField, splitBySpace[2].replace(/[\)]/g, '')))
    newSubFilter += fieldValue
    if (splitBySpace[1].trim() === "=" && typeOfField === "String") { newSubFilter += ")" }
    if (splitBySpace[1].trim() === "=NoCase") { newSubFilter += ",noCase)" }
    if (splitBySpace[1].trim() === "<>NoCase") { newSubFilter += ",noCase)" }
    newSubFilter += fieldSuffix
    if (splitBySpace.length >= 4) {
      newSubFilter += ` ${splitBySpace[3].trim()} `
    }
  }
  return newSubFilter
}

const getSourceId = (value: any, workflowsBySubKeys: WorkflowSummary[]) => {
  var workflow = workflowsBySubKeys.find(x => {return x.config.workflowName === value})
  if(workflow) {
    return `<strVal>${workflow.modelId}</strVal>`
  }
}

const getFieldValue = (type: any, field: any) => {
  switch (type) {
    case "String": return `<strVal>${field}</strVal>`
    case "Boolean": return `<booleanVal>${field}</booleanVal>`
    case "Int64": return `<int64Val>${field}</int64Val>`
    case "Double": return `<doubleVal>${field}</doubleVal>` ///int64
    case "Date": return `<dateVal>${field}</dateVal>`
    case "Decimal": return `<decimalVal>${field}</decimalVal>`
    case "Int32": return `<int32Val>${field}</int32Val>`
    case "Guid": return `<strVal>${field}</strVal>`
  }
}

const getFieldKey = (type: any, field: any) => {
  switch (type) {
    case "String": return `<strKey>${field}</strKey>`
    case "Boolean": return `<booleanKey>${field}</booleanKey>`
    case "Int64": return `<int64Key>${field}</int64Key>`
    case "Double": return `<doubleKey>${field}</doubleKey>` ///int64
    case "Date": return `<dateKey>${field}</dateKey>`
    case "Decimal": return `<decimalKey>${field}</decimalKey>`
    case "Int32": return `<int32Key>${field}</int32Key>`
    case "Guid": return `<strKey>${field}</strKey>`
  }
}

export const sortByModelsNameForFilter = (set: [string,string][]) => {
  var sortedSet = set.sort((a, b) => {
      if (a[0].toLowerCase() > b[0].toLowerCase()) {
          return 1
      } else if (a[0].toLowerCase() < b[0].toLowerCase()) {
          return -1
      } else {
          return 0
      }
  });
  return sortedSet
}

export const addRecFields = (localFieldsList: [string, string][], localFields: string[]) => {
  if(!localFields.includes("Text")) localFieldsList.push(["Text","String"])
  if(!localFields.includes("Reason")) localFieldsList.push(["Reason","String"])
  if(!localFields.includes("DueInDays")) localFieldsList.push(["DueInDays","Int32"])
  if(!localFields.includes("StartOn")) localFieldsList.push(["StartOn","Date"])
  if(!localFields.includes("ExpiryInDays")) localFieldsList.push(["ExpiryInDays","Int32"])
  if(!localFields.includes("Status")) localFieldsList.push(["Status","String"])
  if(!localFields.includes("Owner")) localFieldsList.push(["Owner","Guid"])
  if(!localFields.includes("MsxOwner")) localFieldsList.push(["MsxOwner","Guid"])
  if(!localFields.includes("ActionByDate")) localFieldsList.push(["ActionByDate","Date"])
  if(!localFields.includes("BusinessKey")) localFieldsList.push(["BusinessKey","String"])
  if(!localFields.includes("Family")) localFieldsList.push(["Family","String"])
  if(!localFields.includes("SourceId")) localFieldsList.push(["SourceId","Guid"])
  if(!localFields.includes("CreatedDate")) localFieldsList.push(["CreatedDate","Date"])
  return localFieldsList;
}

export const sortByFieldsNameForFilter = (set: [string,string,string][]) => {
  var sortedSet = set.sort((a, b) => {
      if (a[0].toLowerCase() > b[0].toLowerCase()) {
          return 1
      } else if (a[0].toLowerCase() < b[0].toLowerCase()) {
          return -1
      } else {
          return 0
      }
  });
  return sortedSet
}

export const sortByWorkflowName = (set: WorkflowSummary[]) => {
  var sortedSet = set.sort((a, b) => {
      if (a.config['workflowName'].toLowerCase() > b.config['workflowName'].toLowerCase()) {
          return 1
      } else if (a.config['workflowName'].toLowerCase() < b.config['workflowName'].toLowerCase()) {
          return -1
      } else {
          return 0
      }
  });
  return sortedSet
}
