import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useSelector } from "react-redux";
import { Checkbox, Icon, FlyInPanel, Tooltip, Radio, RadioGroup, Select, Option } from "../../common/HarmonyEnablers";
import { DataGrid, Button, SearchBox, HoverCard, Dropdown, MenuItem, Menu } from '../merge-rules/MergeRulesScope'
import { updateCurrentMergeRuleSet, updateEnableSaveMR, updateMergeRule } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { ModelListDataGridRow, addedModelsColumns, addedModelsItemSelectorData, isArraySame } from './MergeRuleSetModelsColumns'
import { ModelDataGridRow, modelColumns, modelsItemSelectorByData } from "./mergerule-panel/ModelListColumns";
import { FieldListDataGridRow, allFieldsItemSelectorData, fieldsListColumns } from "./mergerule-panel/FieldListColumns";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { PriorityFieldListDataGridRow, addColumnsToWorkflows, allCommonGlobalPriorityFieldsItemSelectorData, allGlobalPriorityFieldsItemSelectorData, allPriorityFieldsItemSelectorData, priorityFieldsListColumns } from "./mergerule-panel/PriorityFieldListColumns";
import { AddedPriorityFieldsDataGridRow, addedGlobalPriorityFieldsItemSelectorData, addedPriorityFieldColumns, addedPriorityFieldsItemSelectorData } from "./MergeRuleSetPriorityFieldsColumns";
import { FieldOrder } from "../../model/merge-rules/PriorityFieldsToMerge.model";


const MergeRuleGlobalPriorityFieldsC: React.FC<any> = (props: any) => { 

  const dispatch = useDispatch<AppDispatch>()
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  //const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey);
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);

  const [openPriorityFieldsPanel, setOpenPriorityFieldsPanel] = useState(false);
  const [commonFields, setCommonFields] = useState(false);
  const [priorityFieldsListRow, setPriorityFieldsListRow] = useState([] as PriorityFieldListDataGridRow[]);
  const [addedFields, setAddedFields] = useState([] as AddedPriorityFieldsDataGridRow[])
  const [selectedFields, setSelectedFields] = useState([] as string[])
  const [allselectedFields, setAllSelectedFields] = useState([] as string[])
  const [descOrderFields, setDescOrderFields] = useState([] as string[])
  const [searchText, setSearchText] = useState("")
  const [totalFieldCount, setTotalFieldCount] = useState(0);


  useEffect(() => {
    if (searchText) {
      setAllSelectedFields(selectedFields)
      var finalRows: PriorityFieldListDataGridRow[] = []
      priorityFieldsListRow.map((row: PriorityFieldListDataGridRow) => {
        if (row.cells?.field1?.toLowerCase()?.includes(searchText) || row.cells?.field2?.toLowerCase()?.includes(searchText)) {
          finalRows.push(row)
        }
      })
      setPriorityFieldsListRow(finalRows)
    }    
    else if(commonFields == false)
    {
      var workflows = workflowsBySubKeys.filter(x => { return mergeRule?.sourceFilter?.includes(x.modelName) })
      var all = allselectedFields.filter(x => !selectedFields?.includes(x));
      var join = selectedFields.concat(all)
      join = join.filter((v, i, a) => a.indexOf(v) == i)
      setSelectedFields(join)
      setPriorityFieldsListRow(allCommonGlobalPriorityFieldsItemSelectorData(workflows, mergeRule,selectedFields))
    } 
        else {
          var workflows = workflowsBySubKeys.filter(x => { return mergeRule?.sourceFilter?.includes(x.modelName) })
          var all = allselectedFields.filter(x => !selectedFields?.includes(x));
          var join = selectedFields.concat(all)
          join = join.filter((v, i, a) => a.indexOf(v) == i)
          setSelectedFields(join)
          setPriorityFieldsListRow(allGlobalPriorityFieldsItemSelectorData(workflows, mergeRule,selectedFields))
        }
      }, [searchText, commonFields])

  useEffect(() => {
    setAddedFields(addedGlobalPriorityFieldsItemSelectorData(mergeRule))
  }, [mergeRule.prioritizePredictionsOnField])

  useEffect(() => {
    if (openPriorityFieldsPanel) {
      var workflows = workflowsBySubKeys.filter(x => { return mergeRule?.sourceFilter?.includes(x.modelName) })
      var items = allCommonGlobalPriorityFieldsItemSelectorData(workflows, mergeRule,selectedFields)
      setPriorityFieldsListRow(items)
      setTotalFieldCount(items.length)

      var oldPriorityFieldsUnSplit = mergeRule?.prioritizePredictionsOnField;
      var oldFieldData : FieldOrder[] = [];
      
      oldPriorityFieldsUnSplit?.map(x => {
        var temp = x.split(" ");
        var newField = new FieldOrder()
        newField.field = temp[0];
        newField.order = temp[2];
        oldFieldData.push(newField);
      });

      var descOrderFields = oldFieldData.filter(x => x.order === "DESC").map(x => x.field);
      setDescOrderFields(descOrderFields);
      
  }
}, [openPriorityFieldsPanel])


useEffect(() => {
  var oldPriorityFieldsUnSplit = mergeRule?.prioritizePredictionsOnField;
      var oldFieldData : FieldOrder[] = [];
      
      oldPriorityFieldsUnSplit?.map(x => {
        var temp = x.split(" ");
        var newField = new FieldOrder()
        newField.field = temp[0];
        newField.order = temp[2];
        oldFieldData.push(newField);
      });

      var descOrderFields = oldFieldData.filter(x => x.order === "DESC").map(x => x.field);
      setDescOrderFields(descOrderFields);
}, []);

  const onRowSelectFields = (e: any) => {

    var fields = [] as string[]
    for (var i = 0; i < priorityFieldsListRow.length; i++) {
      if (priorityFieldsListRow[i].selected) {
        fields.push(priorityFieldsListRow[i].cells.field1)
      }
    }
    if(priorityFieldsListRow.length !== totalFieldCount) {
      var allFields = priorityFieldsListRow.map(x => x.cells.field1)
      var all = allselectedFields.filter(x => !allFields?.includes(x));
      var join = fields.concat(all)
      join = join.filter((v, i, a) => a.indexOf(v) == i)
      setSelectedFields(join)
      var fieldsFromListPartOfAllSelectedFields = allFields.filter(x => allselectedFields?.includes(x))
      var fieldsThatShouldntBeSelectedAnymore = fieldsFromListPartOfAllSelectedFields.filter(x => !fields?.includes(x))
      var allselectedFieldsUpdated = allselectedFields.filter(x => !fieldsThatShouldntBeSelectedAnymore?.includes(x))
      setAllSelectedFields(allselectedFieldsUpdated)
    } else {
      setSelectedFields(fields)
      setAllSelectedFields(fields)
    }
  }

  const onOrderChange = (e: any, row: PriorityFieldListDataGridRow) => {
    if(e.target.value === "DESC" && !descOrderFields?.includes(row.cells.field1))
    {
      setDescOrderFields([...descOrderFields, row.cells.field1])
    }
    else if(e.target.value === "ASC" && descOrderFields?.includes(row.cells.field1))
    {
      setDescOrderFields(descOrderFields.filter(x => x !== row.cells.field1))
    }
}

  const handlePriorityFieldRequestClose = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenPriorityFieldsPanel(false)
      setSelectedFields([])
      setSelectedFields([])
    }
  }

   const checkIfFieldsAreDifferent = () => {
    if(mergeRule.prioritizePredictionsOnField && mergeRule.prioritizePredictionsOnField.length){
      var unsetdata = mergeRule?.prioritizePredictionsOnField;
      var data : string[] = [];
      unsetdata.map(x => {
        var temp = x.split(" ");
        data.push(temp[0]);
      })
      return isArraySame(data, selectedFields);
    } else{
      return selectedFields.length <= 0
    }
  }

  const onCancelFieldsClick = () => {
    setOpenPriorityFieldsPanel(false)
    setSelectedFields([])
    setAllSelectedFields([])
  }

  const onAddFieldsClick = () => {

    var localFieldsUnSplit = mergeRule?.prioritizePredictionsOnField;
    var localFields : string[] = [];
    localFieldsUnSplit?.map(x => {
      var temp = x.split(" ");
      localFields.push(temp[0]);
    })
    var fieldsRetainedInOrder = localFields.filter(x => { return selectedFields?.includes(x) })
    var fieldsNewlyAdded = selectedFields.filter(x => { return !localFields?.includes(x) })
    var finalFields = fieldsRetainedInOrder.concat(fieldsNewlyAdded)

    var finalPriorityFields : string[] = [];
    var workflows = workflowsBySubKeys.filter(x => { return mergeRule?.sourceFilter?.includes(x.modelName) })

    workflows = addColumnsToWorkflows(workflows);

    var allWorkflowsFields :string[] = []
    workflows.forEach(eachworkflow => {
      allWorkflowsFields = allWorkflowsFields.concat(Object.keys(eachworkflow.fields))
    });
    var allWorkflowsFieldsAndTypes = [] as any[];
    workflows.forEach(eachworkflow => {
      if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
        Object.keys(eachworkflow.fields).map((item) => {
          allWorkflowsFieldsAndTypes.push({name : item, type : eachworkflow.fields[item]})
        })
      }
    })

    finalFields.forEach(eachField => {
      if(allWorkflowsFields?.includes(eachField)){
        var fieldName = eachField;
        var fieldType = allWorkflowsFieldsAndTypes.find(x => x.name === eachField).type;
        var order = descOrderFields?.includes(eachField) ? "DESC" : "ASC"
        finalPriorityFields.push(fieldName + " " + fieldType + " " + order)
      }
    });

    dispatch(updateMergeRule({ ...mergeRule, prioritizePredictionsOnField: finalPriorityFields }))
    setOpenPriorityFieldsPanel(false)
    setSelectedFields([])
    setAllSelectedFields([])
  }

  const handleDeleteFields = (row: any) => {
    var localFieldsUnSplit = mergeRule?.prioritizePredictionsOnField;
    var fieldsRetainedInOrder = localFieldsUnSplit.filter(x => { return !x?.includes(row.cells.field1) })

    dispatch(updateMergeRule({ ...mergeRule, prioritizePredictionsOnField: fieldsRetainedInOrder }))
  
  }

  return (
    <>
    
    <div>
        {
          openPriorityFieldsPanel &&
          <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)', marginBottom: "100px" }}
            heading="Select Fields to Prioritize Predictions"
            open={openPriorityFieldsPanel} onHeRequestClose={handlePriorityFieldRequestClose}>
            <div style={{ marginBottom: "10px", fontSize: "15px" }}>Below is the list of Fields available to choose to prioritize a prediction from duplicate predictions to form the base for the resulting recommendations on</div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
              <SearchBox style={{ width: "300px", marginLeft: "0px" }} placeholder="Search Field/Source Name" onInput={(e: any) => { setSearchText(e.target.value) }} onHeClear={() => { setSearchText("") }}></SearchBox>
              <Checkbox style={{ width: "300px", marginLeft: "20px" }} onHeChange={ () => setCommonFields (!commonFields)}>Display all Fields</Checkbox>
            </div>

            <DataGrid fixedHeading={true}
                  rows={priorityFieldsListRow} columns={priorityFieldsListColumns} select="multiple" onHeRowSelectChange={(e) => { onRowSelectFields(e.detail) }}>
                  <span slot="no-records">No fields found. </span>
                  {priorityFieldsListRow.map(row => {
                    return <>

                    <span slot={`field3-${row.id}`} key={`field3-${row.id}`}>
                      <Select label="" value = {descOrderFields?.includes(row.cells.field1) ? "DESC" : "ASC"} style={{ width: '140px' }}>
                        <Option value = "ASC" onHeSelected={(e: any) =>
                                          onOrderChange(e, row)
                                        }>Ascending</Option>
                        <Option value = "DESC" onHeSelected={(e: any) =>
                                          onOrderChange(e, row)
                                        }>Descending</Option>
                      </Select>
                    </span>

                      <HoverCard slot={`field2-${row.id}`} key={`field2-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                        <div slot="anchor" role="button" key={`field2-${row.id}`}>{row.cells.field2 === null || row.cells.field2 === '' ? '' : row.cells.field2 != null && row.cells.field2.length > 30 ? row.cells.field2.substring(0, 30) + "..." : row.cells.field2}</div>
                        {row.cells.field2 &&
                          <code style={{ color: "black" }}>{row.cells.field2.split(",").map(eachRow => {
                            return <div>{eachRow}</div>
                          })}
                          </code>
                        }
                        {!row.cells.field2 &&
                          <code style={{ color: "black" }}>
                          </code>
                        }
                      </HoverCard>

                    </>
                  })}
                  </DataGrid>
            <div className="medium-flyinpanel-footer">
              <Button type='button' appearance='primary' disabled={(checkIfFieldsAreDifferent()) || selectedFields.length === 0} onClick={onAddFieldsClick} >Add</Button>
              <span style={{ marginLeft: '20px' }}></span>
              {(<Button type='button' appearance='secondary' onClick={onCancelFieldsClick}>Cancel</Button>)}
              <span style={{ marginLeft: '20px' }}></span>
            </div>
          </FlyInPanel>
        }

        <div style={{margin:"auto"}}>
          <Button appearance="command" style={{ fontSize: "14px" }} disabled={!access.canEdit} onClick={() => { setOpenPriorityFieldsPanel(true) }}>
          <Icon slot="start" name="add"></Icon>
            Add Fields
          </Button>
        </div>

        {
          addedFields.length > 0 &&

          <DataGrid fixedHeading={true}
          style={{ marginBottom: "20px"}} rows={addedFields} columns={addedPriorityFieldColumns} >
          <span slot="no-records">No Priority Fields added yet.</span>
          {addedFields.map(row => {
            return (
              <>

                <span slot={`field2-${row.id}`} key={`field2-${row.id}`}>
                  {descOrderFields?.includes(row.cells.field1) ? "Descending" : "Ascending"}
                </span>

                <span slot={`field3-${row.id}`} key={`field3-${row.id}`}>
                  <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={() => handleDeleteFields(row)} >
                    <Icon label="delete rule" slot="start" name="delete"></Icon>
                  </Button>
                </span>
              </>
            )
          })}
        </DataGrid>
        }

    </div>
    
    </>

  )
}


export const MergeRuleGlobalPriorityFields = withErrorBoundary("Merge rule Global Priority Fields", MergeRuleGlobalPriorityFieldsC);
