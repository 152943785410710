import { Attribute, Primary, Source } from "./Prediction.model"

export class Insight
{
    public id!: string
    public type!: string
    public partitionKey!: string
    public createdDate!: string
    public source!: Source
    public primary!: Primary<string[]>
    public attributes!: Attribute[]
    public systemAttributes!: Attribute[]
}
