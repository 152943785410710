import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { PersonaMapping } from "../../model/personaMapping/PersonaMapping.Model";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";

interface PersonaMappingConfig {
    personaMapping: PersonaMapping[],
    status: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean
}

const initialState: PersonaMappingConfig = {
    personaMapping: [],
    status: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false
}

export const personaMappingConfigSlice = createSlice({
    name: 'personaMapping',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPersonaMapping.pending, (state, action) => {
                state.status = RequestStatus.loading
              })
              .addCase(getPersonaMapping.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                state.personaMapping = action.payload
                state.error = undefined
              })
              .addCase(getPersonaMapping.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
                
              })
    }
});

export const personaMappingConfigReducer = personaMappingConfigSlice.reducer