import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Spinner } from "../../../shared-components/loader/Spinner";
import { Button, DataGrid, Dialog } from "../SpaAdminScope";
import { setShowConfigurationsSummaryPanel } from "../../release-management/ReleaseData.redux";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { CampaignStatusEnum, Environments, PowerBiLinks } from "../SPAConstants";
export const CampaignVersionSummary: React.FC<any> = React.memo((props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const devConfiguration = useSelector((state: RootState) =>  state.releaseData.configurationSummary );
    const loading = useSelector((state: RootState) => state.releaseData.configurationSummaryStatus === RequestStatus.loading);
    const showSummary = useSelector((state: RootState) => state.releaseData.showConfigurationSummaryPanel);
    const [displayCampaignVersion,setDisplayCampaignVersion]:any = useState<any>();
    const selectedEnv = useSelector((state: RootState) =>state.env.selectedEnvItem );
    const [campaignTag,setCampaignTag]:any = useState<string>();

    const releaseRows = props.releaseRows;
    const actionedVersion = props.selectedVersion;
   
    const [IsProdDeployed, setIsProdDeployed] = useState(false);
    const [IsUatDeployed, setIsUatDeployed] = useState(false);

    const columns = [
        { field: 'Name', content: 'Name' },
        { field: 'TotalUploaded', content: 'Total Uploaded' },
        { field: 'Removed', content: 'Removed' },
        { field: 'ExpiryDate', content: 'Expiry Date' },
        { field: 'ActionByDate', content: 'Action By Date' }
    ];

    const handleRequestClose = (event: any) => {
        
        dispatch(setShowConfigurationsSummaryPanel(false));
       
    }
    useEffect(() => {   
        if(devConfiguration && devConfiguration.config && devConfiguration.config.workflowName){
            var campaignDevConfiguration:Campaign = devConfiguration as Campaign;
            var displayCampaignVersionTemp:any = {};
            var displayCampaignVersionRow:any = {};

          //  displayCampaignVersionTemp["Version"] = campaignDevConfiguration.version;
            displayCampaignVersionTemp["Name"] = campaignDevConfiguration.config.workflowName;
            displayCampaignVersionTemp["TotalUploaded"] = campaignDevConfiguration.inCart.length;
            displayCampaignVersionTemp["Removed"] = campaignDevConfiguration.outOfCart?.length??0;
            displayCampaignVersionTemp["ExpiryDate"] = campaignDevConfiguration.expiryDate;
            displayCampaignVersionTemp["ActionByDate"] = campaignDevConfiguration.actionByDate;
            displayCampaignVersionRow["cells"] = displayCampaignVersionTemp;
            
            setDisplayCampaignVersion([displayCampaignVersionRow]);
            setCampaignTag(campaignDevConfiguration.tags);
        }
    },[devConfiguration])
    
  useEffect(() => {
    if(actionedVersion && releaseRows){
        const uatStatus = releaseRows.find((x:any) => x.cells.field1 === actionedVersion)?.cells.field4;
        const prodStatus = releaseRows.find((x:any) => x.cells.field1 === actionedVersion)?.cells.field6;
        setIsProdDeployed(((uatStatus ===  CampaignStatusEnum.DEPLOYED ) && (prodStatus=== CampaignStatusEnum.DEPLOYED))?true:false); 
        setIsUatDeployed(uatStatus ===  CampaignStatusEnum.DEPLOYED?true:false) ;   
    }
   
  }, [actionedVersion, releaseRows]);
        return (
            <Dialog
            style={{width: 'auto'}}
            heading={`Campaign summary for  ${devConfiguration?.config?.workflowName}`}
            open={showSummary}
            onHeRequestClose={handleRequestClose}
            className="release-diff-dialog"
            >            
            {
                loading && <div className='release-diff-loader'><Spinner type='medium' /></div>
            }
             { 
            !loading &&  <DataGrid 
                columns={columns}
                rows={displayCampaignVersion}
                /> 
             }
            <div>{!loading && campaignTag && campaignTag.length>0  && IsUatDeployed && <Button appearance="link" 
                  onClick={() => window.open(PowerBiLinks[selectedEnv]+"'"+campaignTag+"'", '_blank')!.focus()}>
                  Power BI Report (UAT) </Button>
            } </div>
             <div>{!loading && IsProdDeployed  && <Button appearance="link" 
                  onClick={() => window.open(PowerBiLinks[Environments.PROD]+"'"+campaignTag+"'", '_blank')!.focus()}>
                  Power BI Report (Prod) </Button>
            }</div> 
            </Dialog>

        )
    }
)
