import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Accordion, AccordionItem, RadioGroup, Radio } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateEnableSave, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeRuleSetBasics } from "./MergeRuleSetBasics";
import { MergeRuleSetModels } from "./MergeRuleSetModels";
import { MergeRuleSetFields } from "./MergeRuleSetFields";
import { MergeRuleSetMergeConditions } from "./MergeRuleSetMergeConditions";
import { MergeRuleSetResurface } from "./MergeRuleSetResurface";
import { EditMergeRuleSetHeader } from "./EditMergeRuleSetHeader";
import { MergeRuleSetFilter } from "./MergeRuleSetFilter";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { ChangeMergeOnPopup } from "./popups/ChangeMergeOnPopup";
import informationConfig from "../../shared-content/inputDescription";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import { MergeRuleSetPriorityFields } from "./MergeRuleSetPriorityFields";

export const MergeOnType: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation();
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
  const userTeams = useSelector(permissionsSelector).userTeams;
  const originalMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.originalMergeRuleSet);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
  const [openPopUp, setOpenPopUp] = useState(false)


  const getValue = () => {
    var isFields = currentMergeRuleSet.mergeOn.fields.default && currentMergeRuleSet.mergeOn.fields.default.length > 0
    var isConditions = currentMergeRuleSet.mergeOn.mergeConditions && currentMergeRuleSet.mergeOn.mergeConditions.length > 0
    if ((!isFields && !isConditions) || (isFields && !isConditions)) {
      return "fields"
    } else if (isConditions || (isFields && isConditions)) {
      return "conditions"
    }
  }

  const [value, setValue] = useState(getValue())


  const handleChange = (e: any) => {
    var value = e.target.value
    if (value === "conditions") {
      if (currentMergeRuleSet.mergeOn.fields.default && currentMergeRuleSet.mergeOn.fields.default.length > 0) {
        setOpenPopUp(true)
      } else {
        setValue(value)
      }
    } else if (value === "fields") {
      if (currentMergeRuleSet.mergeOn.mergeConditions && currentMergeRuleSet.mergeOn.mergeConditions.length > 0) {
        setOpenPopUp(true)
      } else {
        setValue(value)
      }
    }
  }

  return (
    <>
      <div>
        <div style={{ marginBottom: "24px" }}>
          <InformationTextMergeRule text={informationConfig.editMergeRuleSetFields} type="Tab" />
        </div>
        {openPopUp && <ChangeMergeOnPopup nextTarget={value === "fields" ? "conditions" : "fields"} setOpenPopUp={setOpenPopUp} setValue={setValue} />}
        <table className='mergerule-form-table' >
          <tr>
            <td style={{width: "16%", paddingTop: "10px"}}>
              Merge Insights/Recommendations 
            </td>
            <td>
              <RadioGroup name="selectType" value={value} >
                <div style={{ display: "flex" }} >
                  <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="fields" onHeSelected={handleChange}>On Fields</Radio>
                  <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="conditions" onHeSelected={handleChange}>When Specific Value(s) Of Fields Match</Radio>
                </div>
              </RadioGroup>
            </td>
          </tr>
        </table>

        <div style={{ marginTop: "20px" }}>
          {value === "fields" &&
            <MergeRuleSetFields />
          }
          {value === "conditions" &&
            <MergeRuleSetMergeConditions />
          }
          <MergeRuleSetPriorityFields />
        </div>
      </div>
    </>
  )
}