import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { EditMergeRulesEssentials } from "./EditMergeRulesEssentials";
import { EditMergeRuleSetsConfig } from "./EditMergeRuleSetsConfig";
import { updateTab } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const EditMergeRulesBodyC: React.FC<any> = (props: any) => {
    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation();
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
    const activeTab = useSelector((state: RootState) => state.mergeRuleById.activeTab);
    const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);
    const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
    const openMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.openMergeRuleSet);
    const userTeams = useSelector(permissionsSelector).userTeams;
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    const [tab, setTab] = useState("tab1");

    const { mergeRuleId, source } = props
    const [localMergeRuleData, setLocalMergeRuleData] = useState([])
    const [data, setData] = useState(mergeRule);
    const handle = (activeTab: any) => {
        if (activeTab != undefined) {
            dispatch(updateTab(activeTab.id))
        }
    }

    return (
        <>
            <div className={`tab-wrapper ${source !== 'default' ? '' : 'default'}`}>
                <Button id='tab1' disabled={openMergeRuleSet} appearance="command" className={tab === "tab1" ? "active-indicator": "inactive-indicator"} onClick={() => { setTab("tab1") }} >Essential Information <Icon className='required-icon' name='actioncenterasterisk'></Icon></Button>
                <Button id='tab2' appearance="command" className={tab === "tab2" ? "active-indicator": "inactive-indicator"} onClick={() => { setTab("tab2") }} >Merge Rulesets Configuration <Icon className='required-icon' name='actioncenterasterisk'></Icon></Button>
            </div>
            <div className={`mergerule-body-wrapper ${isNavPanelOpen ? '' : 'collapsed'}`}>
                {tab === "tab1" &&
                    <div className={`${source !== 'default' ? 'tab-style' : ''}`}>
                        <EditMergeRulesEssentials source={source} />
                    </div>
                }
                {tab === "tab2" &&
                    <div className={`${source !== 'default' ? 'tab-style' : ''}`}>
                        <EditMergeRuleSetsConfig source={source} />
                    </div>
                }
            </div>
        </>
    )
}
export const EditMergeRulesBody = withErrorBoundary("Edit mergerule body",EditMergeRulesBodyC);