import { useDispatch } from "react-redux";
import { Select, Option, TextField } from "../../../common/HarmonyEnablers";
import { Schedule } from "../../../model/workflows/Schedule.model";
import { AppDispatch } from "../../../root-redux/RootState";
import { updateSchedule } from "../workflow-by-id-redux/WorkflowById.actions.redux";
import React from "react";
import "./schedule-workflow-styles.css";
import { ScheduleWorkflowMinute } from "./ScheduleWorkflowMinute";
import { ScheduleWorkflowHour } from "./ScheduleWorkflowHour";
import { ScheduleWorkflowWeek } from "./ScheduleWorkflowWeek";
import { ScheduleWorkflowMonth } from "./ScheduleWorkflowMonth";

export const ScheduleWorkflowRecurrence: React.FC<{ scheduleObj: Schedule }> =
  React.memo(({ scheduleObj }) => {
    const dispatch = useDispatch<AppDispatch>();
    const recurrenceList = ["Minute", "Hour", "Day", "Week", "Month"];
    const frequencyMap: { [key: string]: number } = {
      Minute: 30,
      Hour: 12,
    };

    let startFromComponent = <></>;
    if (scheduleObj?.frequency) {
      switch (scheduleObj?.timePeriod) {
        case "Minute":
          startFromComponent =
            scheduleObj.frequency === "Every" ? (
              <></>
            ) : (
              <>
                <span className="inbetween-text">from</span>
                <ScheduleWorkflowMinute
                  scheduleObj={scheduleObj}
                  dynamic={true}
                />
              </>
            );
          break;
        case "Hour":
          startFromComponent =
            scheduleObj.frequency === "Every" ? (
              <>
                <span className="inbetween-text">at</span>
                <ScheduleWorkflowMinute scheduleObj={scheduleObj} />
              </>
            ) : (
              <>
                <span className="inbetween-text">from</span>
                <ScheduleWorkflowHour
                  scheduleObj={scheduleObj}
                  dynamic={true}
                />
                <span className="inbetween-text">:</span>
                <ScheduleWorkflowMinute scheduleObj={scheduleObj} />
              </>
            );
          break;
        case "Day":
          startFromComponent = (
            <>
              <span className="inbetween-text">at</span>
              <ScheduleWorkflowHour scheduleObj={scheduleObj} />
              <span className="inbetween-text">:</span>
              <ScheduleWorkflowMinute scheduleObj={scheduleObj} />
            </>
          );
          break;
        case "Week":
          startFromComponent = (
            <>
              <span className="inbetween-text">on</span>
              <ScheduleWorkflowWeek scheduleObj={scheduleObj} />
              <span className="inbetween-text">at</span>
              <ScheduleWorkflowHour scheduleObj={scheduleObj} />
              <span className="inbetween-text">:</span>
              <ScheduleWorkflowMinute scheduleObj={scheduleObj} />
            </>
          );
          break;
        case "Month":
          startFromComponent = (
            <>
              <span className="inbetween-text">on</span>
              <ScheduleWorkflowMonth scheduleObj={scheduleObj} />
              <span className="inbetween-text">at</span>
              <ScheduleWorkflowHour scheduleObj={scheduleObj} />
              <span className="inbetween-text">:</span>
              <ScheduleWorkflowMinute scheduleObj={scheduleObj} />
            </>
          );
          break;
        default:
          break;
      }
    }

    return (
      <div className="schedule-recur-wrapper">
        {scheduleObj?.timePeriod &&
        (scheduleObj.timePeriod === "Minute" ||
          scheduleObj.timePeriod === "Hour") ? (
          <Select
            value={scheduleObj?.frequency}
            slot="action"
            fixed-placement
            placeholder={"Select"}
            name="subkey"
            className="every"
          >
            {Array.from(
              { length: frequencyMap[scheduleObj.timePeriod as string] },
              (_, i) => i + 1
            ).map((item) => (
              <Option
                key={item}
                role="menuitemcheckbox"
                onHeSelected={(e: any) =>
                  dispatch(
                    updateSchedule({
                      ...scheduleObj,
                      frequency: e.target.value,
                    })
                  )
                }
                value={"Every" + (item == 1 ? "" : " " + item)}
              >
                {"Every" + (item == 1 ? "" : " " + item)}
              </Option>
            ))}
          </Select>
        ) : (
          <TextField readonly value={"Every"} />
        )}

        <Select
          value={scheduleObj?.timePeriod}
          slot="action"
          fixed-placement
          placeholder={"Select"}
          name="subkey"
          className="timeperiod"
        >
          {recurrenceList.map((item) => (
            <Option
              key={item}
              role="menuitemcheckbox"
              onHeSelected={(e: any) =>
                dispatch(
                  updateSchedule({
                    ...scheduleObj,
                    timePeriod: e.target.value,
                    frequency:
                      scheduleObj?.timePeriod === "Minute" ||
                      scheduleObj?.timePeriod === "Hour"
                        ? scheduleObj?.frequency
                        : "Every",
                  })
                )
              }
              value={item}
            >
              {item}
            </Option>
          ))}
        </Select>
        {startFromComponent}
      </div>
    );
  });
