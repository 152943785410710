import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TenantAdminNavMenuItemType } from "../TenantAdminNavMenuItem.model";

interface TenantAdminState {
    selectedNavItem: TenantAdminNavMenuItemType
}

const initialState: TenantAdminState = {
    selectedNavItem: TenantAdminNavMenuItemType.Connections
}

export const tenantAdminSlice = createSlice({
    name: 'tenantAdmin',
    initialState,
    reducers: {
      setNavState: (state, action: PayloadAction<TenantAdminNavMenuItemType>) => {
        state.selectedNavItem = action.payload
      }
    }
});

export const tenantAdminReducer = tenantAdminSlice.reducer;

export const { setNavState } = tenantAdminSlice.actions