
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const DataConsumeC: React.FC<any> = (props: any) => {

  return (
    <>
    
    <div className="help-mainHeading">
          <span id = "consumeTag" className="help-subheading">Consume</span>
          <div style = {{marginBottom:"8px"}}>
          {/* <span className="help-heading">Summary:</span> */}
          <span className="help-content"> {onboarding.consume.summary} </span>
          <br/><br/>
          <span className="help-content"> {onboarding.consume.appIDinfo} </span>
          </div>
          {/* <div style = {{marginBottom:"8px", display:"flex", flexDirection:"column"}}>
          <span className="help-heading" style = {{marginBottom:"2px"}}>Insights REST APIs </span>
          <span className="help-content"> In this step, select the final set  </span>
          </div> */}
          {/* <div style = {{marginBottom:"8px", display:"flex", flexDirection:"column"}}>
          <span className="help-heading" style = {{marginBottom:"2px"}}>Insights OData APIs  </span>
          <span className="help-content"> In this step, select the final set  </span>
          </div>
          <div style = {{marginBottom:"8px", display:"flex", flexDirection:"column"}}>
          <span className="help-heading" style = {{marginBottom:"2px"}}>Guidelines </span>
          <span className="help-content"> In this step, select the final set  </span>
          </div> */}
          <div style=  {{marginTop:"8px"}}>
          <span className="help-heading" style ={{marginBottom:"18px"}}>Difference between Recommendation and an Insight </span>
          <table>
              <tr>
                <th>Recommendations</th>
                <th>Insights</th>
              </tr>
                {onboarding.consume.insightsVSrecs.map(data =>
                <tr>
                <td>{data.recommendations}</td>
                <td>{data.insights}</td>
              </tr>
                )}
          </table>
          
          </div>
          </div>

    </>
  );
};
export const DataConsume = withErrorBoundary("DataConsume content", DataConsumeC);