import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Release } from "../../model/releases/Release.model";
import { getDevReleases, getDevReleasesForConfiguration, getProdReleases, getProdReleasesForConfiguration, getUatReleases, getUatReleasesForConfiguration } from "../../services/GetReleases.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";

interface ReleasesState {
  releasesDev: Release[];
  releasesUat: Release[];
  releasesProd: Release[];
  getReleasesStatus: {dev: RequestStatus, uat: RequestStatus, prod: RequestStatus}
}

const initialState: ReleasesState = {
    releasesDev: [],
    releasesUat: [],
    releasesProd:[],
    getReleasesStatus: {dev: RequestStatus.idle, uat: RequestStatus.idle, prod: RequestStatus.idle}
};

const releasesSlice = createSlice({
  name: "releases",
  initialState,
  reducers: {
    
    setReleaseStatus: (state, action: PayloadAction<{version: string, status: string, env: string}>) => {

      const status = action.payload.status;
      const version = action.payload.version;

      if(action.payload.env == "dev")
      {
        state.releasesDev.forEach(x => {
          x.status = x.version == version ? status : x.status
        });
    };

    if(action.payload.env == "uat")
      {
      state.releasesUat.forEach(x => {
        x.status = x.version == version ? status : x.status
      });
    };

    if(action.payload.env == "prod")
      {
        state.releasesProd.forEach(x => {
          x.status = x.version == version ? status : x.status
        });
    };
  },
  addRelease : (state, action: PayloadAction<{input : Release, env:string}>) => {
      if (action.payload.env == "dev")
        state.releasesDev = state.releasesDev.concat(action.payload.input);
      if (action.payload.env == "uat")
        state.releasesUat = state.releasesUat.concat(action.payload.input);
      if (action.payload.env == "prod")
        state.releasesProd = state.releasesProd.concat(action.payload.input);
  },

addRollbackRelease : (state, action: PayloadAction<{input : Release}>) => {
  const release = action.payload.input;
  const currentData = state.releasesDev;
  currentData.splice(0, 0, release);
  state.releasesDev = currentData;
}},
 

  extraReducers(builder) {
    builder
      .addCase(getDevReleases.fulfilled, (state, action) => {
        state.releasesDev = action.payload;
        state.getReleasesStatus.dev = RequestStatus.succeeded;
      })
      .addCase(getDevReleases.pending, (state) => {
        state.getReleasesStatus.dev = RequestStatus.loading;
      })
      .addCase(getDevReleases.rejected, (state) => {
        state.getReleasesStatus.dev = RequestStatus.failed;
      })
      .addCase(getUatReleases.fulfilled, (state, action) => {
        state.releasesUat = action.payload;
        state.getReleasesStatus.uat = RequestStatus.succeeded;
      })
      .addCase(getUatReleases.pending, (state) => {
        state.getReleasesStatus.uat = RequestStatus.loading;
      })
      .addCase(getUatReleases.rejected, (state) => {
        state.getReleasesStatus.uat = RequestStatus.failed;
      })
      .addCase(getProdReleases.fulfilled, (state, action) => {
        state.releasesProd = action.payload;
        state.getReleasesStatus.prod = RequestStatus.succeeded;
      })
      .addCase(getProdReleases.pending, (state) => {
        state.getReleasesStatus.prod = RequestStatus.loading;
      })
      .addCase(getProdReleases.rejected, (state) => {
        state.getReleasesStatus.prod = RequestStatus.failed;
      })
      .addCase(getDevReleasesForConfiguration.fulfilled, (state, action) => {
        state.releasesDev = action.payload;
        state.getReleasesStatus.dev = RequestStatus.succeeded;
      })
      .addCase(getDevReleasesForConfiguration.pending, (state) => {
        state.getReleasesStatus.dev = RequestStatus.loading;
      })
      .addCase(getDevReleasesForConfiguration.rejected, (state) => {
        state.getReleasesStatus.dev = RequestStatus.failed;
      })
      .addCase(getUatReleasesForConfiguration.fulfilled, (state, action) => {
        state.releasesUat = action.payload;
        state.getReleasesStatus.uat = RequestStatus.succeeded;
      })
      .addCase(getUatReleasesForConfiguration.pending, (state) => {
        state.getReleasesStatus.uat = RequestStatus.loading;
      })
      .addCase(getUatReleasesForConfiguration.rejected, (state) => {
        state.getReleasesStatus.uat = RequestStatus.failed;
      })
      .addCase(getProdReleasesForConfiguration.fulfilled, (state, action) => {
        state.releasesProd = action.payload;
        state.getReleasesStatus.prod = RequestStatus.succeeded;
      })
      .addCase(getProdReleasesForConfiguration.pending, (state) => {
        state.getReleasesStatus.prod = RequestStatus.loading;
      })
      .addCase(getProdReleasesForConfiguration.rejected, (state) => {
        state.getReleasesStatus.prod = RequestStatus.failed;
      })
  },
});

export const { setReleaseStatus , addRelease, addRollbackRelease} = releasesSlice.actions;

export const releasesReducer = releasesSlice.reducer;
