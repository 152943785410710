import { createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogueSchemaObject } from '../model/configModels/CatalogueSchemaObject.model';
import { getRequest } from './utils/GetRequest';


  export const getCatalogueConfig_dev = createAsyncThunk<CatalogueSchemaObject, string>('catalogueConfig/getCatalogueConfig_uat', async (insightId: string) => {
    const response = await getRequest<CatalogueSchemaObject>(`catalogue/dev/${insightId}`)
    return response.data;
  })

  export const getCatalogueConfig_uat = createAsyncThunk<CatalogueSchemaObject, string>('catalogueConfig/getCatalogueConfig_prod', async (insightId: string) => {
    const response = await getRequest<CatalogueSchemaObject>(`catalogue/uat/${insightId}`)
    return response.data;
  })