import { useSelector } from "react-redux";
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { RootState } from "../../../root-redux/RootState";

export const CheckCampaignPageAccessSelector = (subscriptionKey: string) => {
    const permissions = useSelector(permissionsSelector);
    const isSuperAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);

    return {
        canEdit: (isSuperAdmin || permissions.edit.includes(subscriptionKey) || userSalesRoles.isSpaAdmin),
        canApprove: isSuperAdmin || permissions.approve.includes(subscriptionKey) || userSalesRoles.isSpaAdmin,
        canRelease: isSuperAdmin || permissions.release.includes(subscriptionKey) || userSalesRoles.isSpaAdmin
    }
}