import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { Campaign } from "../../model/SPAModels/campaigns/campaign.Model"
import { RootState } from "../../root-redux/RootState"
import { postRequest } from "../utils/PostRequest"
import { Prediction } from "../../model/preview-mergerule/Prediction.model"

const getParam = (str: string) => {
    const textRegex = /(?<=\().+(?=,)/;
    let m;
    if ((m = textRegex.exec(str)) !== null) {
        return m[0];
    }
    return "";
}

const replaceableKeys: {[key: string]: string} = {
    "IS NOT NULL": "!= null",
    "IS NULL": "= null"
}

export const getCampaignsList = createAsyncThunk<any, void, { state: RootState }>('campaigns/getCampaignsList', async (_, {getState, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    const response = await getRequest<any>(`${env}/campaigns/summary`);
    const data = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    return response.data as Campaign[];
})

export const postCampaignFilterList = createAsyncThunk<any, { payload: any, subKey: string }, { state: RootState }>('filteredQueryModelData/filterData', async ({ payload, subKey }, { getState, dispatch, rejectWithValue }) => {
    const env = getState().env.selectedEnvItem;

    const regex = /COALESCE*\([^)]*, ''\) = ''/mg;
    const regex2 = /COALESCE*\([^)]*, ''\) <> ''/mg;

    let query: string = payload['Query'];
    for(const key in replaceableKeys) {
        query = query.replaceAll(key, replaceableKeys[key]);
    };
    let finalQuery = query + '';
    let m;
    while ((m = regex.exec(query)) !== null) {
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        const word = getParam(m[0]);
        finalQuery = finalQuery.replace(m[0], `${word} = null`);
    }
    while ((m = regex2.exec(query)) !== null) {
        if (m.index === regex2.lastIndex) {
            regex2.lastIndex++;
        }
        const word = getParam(m[0]);
        finalQuery = finalQuery.replace(m[0], `${word} != null`);
    }
    payload['Query'] = finalQuery;
    const response = await postRequest<any, any>(`${env}/campaigns/filterData`, payload, subKey);
    const data = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    return response.data;
});

const removeDuplicateRefIds = (result: Array<Prediction>) => {
    const refIds: Set<string> = new Set<string>();
    return result?.filter(record => {
        const attr = record.attributes.find(attr => attr.key === 'ReferenceId');
        if (attr !== undefined && attr.value !== undefined && !refIds.has(attr?.value)) {
            refIds.add(attr?.value);
            return true;
        }
        return false;
    });
}

export const fetchCampaignFilterCount = createAsyncThunk<any, { payload: any, subKey: string }, { state: RootState }>('filteredQueryModelData/filterDataCount', async ({ payload, subKey }, { getState, rejectWithValue }) => {
    const env = getState().env.selectedEnvItem;
    const subPayload = {...payload};
    subPayload['PageNum'] = -1;
    const response = await postRequest<any, any>(`${env}/campaigns/filterData`, subPayload, subKey);
    const data = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    return removeDuplicateRefIds(data);
});
