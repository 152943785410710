import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { MergeRule } from "../../model/merge-rules/MergeRule.model";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { RootState } from "../../root-redux/RootState";
import { Conditions } from "../../model/merge-rules/Conditions.model";
import { WorkflowSummary } from "../../model/workflows/Workflow.model";
import { AddedMergeConditionsDataGridRow } from "./MergeRuleSetMergeConditionsColumns";
import { MergeCondition } from "../../model/merge-rules/MergeCondition.model";
import { AddedMergeCondition } from "../../model/merge-rules/AddedMergeCondition.model";
export interface MergeRuleSetDataGridRow extends Row {
  cells: {
    field0: string,
    field1: string
    field2: string
    field6: string
    field20: string
    field21: string
    field3: string
    field4: string
    field22: string
    field23 : string
    field7: string
    field8: string
    field9: string
    field10: string
    field11: string
  }
}

export const columns: Column[] = [
  {
    field: 'field0',
    content: '',
  },
  {
    field: 'field1',
    content: 'Rank',
    sortable: true,
    display: {
      width: '100px'
    }
  },
  {
    field: 'field2',
    content: 'Name',
    sortable: true,
    display: {
      maxWidth: '150px'
    }
  },
  {
    field: 'field7',
    content: 'Description ',
    display: {
      maxWidth: '150px'
    }
  },
  {
    field: 'field6',
    content: 'Type',
    display: {
      maxWidth: '150px'
    }
  },
  {
    field: 'field20',
    content: 'Recommendations',
    sortable: true,
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field21',
    content: 'Insights',
    sortable: true,
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field3',
    content: 'Models',
    sortable: true,
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field4',
    content: 'Merge On/When',
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field22',
    content: 'Priority Fields',
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field23',
    content: 'Use MR level Priority Fields',
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field8',
    content: 'Filter on Insights/Input Recommendations',
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field9',
    content: 'Filter on Output Recommendations',
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field10',
    content: 'Resurface on Recommendation',
    display: {
      maxWidth: '300px'
    }
  },
  {
    field: 'field11',
    content: 'Transformation on Recommendation Fields',
    display: {
      maxWidth: '300px'
    }
  }
];

const getAddedMergeConditions = (workflowSummary: WorkflowSummary[], eachCondition: Conditions, newConditionAdded: AddedMergeCondition) => {
  var filter = eachCondition.filter
  var filterArray = filter.split("&&")
  var fieldFilter = "", sourceFilter = "";
  var fieldName = ""
  if (filterArray.length >= 1) { fieldFilter = filterArray[0].trim() }
  if (filterArray.length >= 2 && filterArray[1].includes("SourceId")) { sourceFilter = filterArray[1].trim() }
  var modelId = sourceFilter !== "" ? sourceFilter.replace(/<(.*?)>/g, '').replace(/SourceId.Equals\(/g, '').replace(/\)/g, '') : ""
  var modelName = "";
  var workflow = workflowSummary.find((x: { modelId: string; }) => x.modelId === modelId)

  if (workflow) {
    modelName = workflow.config.workflowName
  }

  var opr = ""
  var negate = fieldFilter.includes("!")
  var values = []

  var filterShed = fieldFilter.replace(/<(.*?)>/g, '')
  if (filterShed.includes("||") || filterShed.startsWith("(")) {
    opr = negate ? "Not In" : "In"
    filterShed = filterShed.replace(/\(/g, '').replace(/\)/g, '')
    var valuesArray = filterShed.split("||")
    valuesArray.forEach((eachValue) => {
      if (eachValue.includes(".Equals")) {
        var eachValueArray = eachValue.split(".Equals")
        if (fieldName === "") {
          fieldName = eachValueArray[0] || ""
        }
        var localValue = eachValueArray[1].trim() || ""
        if (localValue != "") {
          localValue = localValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\,noCase/g, '')
          values.push(localValue)
        }
      }
    })
  } else {
    opr = negate ? "Not Equals" : "Equals"
    if (filterShed.includes(".Equals")) {
      var eachValueArray = filterShed.split(".Equals")
      if (fieldName === "") {
        fieldName = eachValueArray[0] || ""
      }
      var localValue = eachValueArray[1].trim() || ""
      if (localValue != "") {
        localValue = localValue.replace(/\(/g, '').replace(/\)/g, '').replace(/\,noCase/g, '')
        values.push(localValue)
      }
    }
  }
  var finalValue = modelName + (modelName != "" ? "." : "") + fieldName.replace(/\!/g, '') + " " + opr + " (" + (values?.join(",") || "") + ")"
  newConditionAdded.value += finalValue + " And "
  return newConditionAdded
}

export const convertFilterToDisplayFormat = (filter: string) => {
  if (filter) {
    var changedFilter = filter.replace(/SourceId/g, 'InsightName/RecommendationName')
    var brandNewFilter = ""
    if (changedFilter.includes("AND")) {
      var splitsOnAnd = changedFilter.split("AND")
      splitsOnAnd.forEach((eachSplitOnAdd) => {
        if (eachSplitOnAdd.includes("OR")) {
          var splitsOnAndOr = eachSplitOnAdd.split("OR")
          splitsOnAndOr.forEach(eachSplitOnAddOr => {
            if (eachSplitOnAddOr.includes("COALESCE")) {
              var matchedValue = eachSplitOnAddOr.match(/COALESCE\((.*), ''\) /)
              var key = matchedValue?.length === 2 ? matchedValue[1].trim() : ""
              eachSplitOnAddOr = eachSplitOnAddOr.replace(/COALESCE\((.*), ''\)/, key)
              if (eachSplitOnAddOr.includes("<>")) {
                eachSplitOnAddOr = eachSplitOnAddOr.replace(/ <> ''/, " is not empty")
              } else if (eachSplitOnAddOr.includes("=")) {
                eachSplitOnAddOr = eachSplitOnAddOr.replace(/ = ''/, " is empty")
              }
            }
            brandNewFilter += eachSplitOnAddOr + " OR ";
          });
        } else {
          if(brandNewFilter.endsWith(" OR ")){
            brandNewFilter = brandNewFilter.slice(0, -4) + "AND"
          }
          if (eachSplitOnAdd.includes("COALESCE")) {
            var matchedValue = eachSplitOnAdd.match(/COALESCE\((.*), ''\) /)
            var key = matchedValue?.length === 2 ? matchedValue[1].trim() : ""
            eachSplitOnAdd = eachSplitOnAdd.replace(/COALESCE\((.*), ''\)/, key)
            if (eachSplitOnAdd.includes("<>")) {
              eachSplitOnAdd = eachSplitOnAdd.replace(/ <> ''/, " is not empty")
            } else if (eachSplitOnAdd.includes("=")) {
              eachSplitOnAdd = eachSplitOnAdd.replace(/ = ''/, " is empty")
            }
          }
          brandNewFilter += eachSplitOnAdd + " AND ";
        }
      })
      brandNewFilter = brandNewFilter.slice(0, -4)
    } else {
      if (changedFilter.includes("COALESCE")) {
        var matchedValue = changedFilter.match(/COALESCE\((.*), ''\) /)
        var key = matchedValue?.length === 2 ? matchedValue[1].trim() : ""
        changedFilter = changedFilter.replace(/COALESCE\((.*), ''\)/, key)
        if (changedFilter.includes("<>")) {
          changedFilter = changedFilter.replace(/ <> ''/, " is not empty")
        } else if (changedFilter.includes("=")) {
          changedFilter = changedFilter.replace(/ = ''/, " is empty")
        }
      }
      brandNewFilter = changedFilter
    }
    return brandNewFilter
  }
  return ""
}

export const mergeRuleSetItemSelectorData = (data: MergeRule, workflowSummary: WorkflowSummary[]): MergeRuleSetDataGridRow[] => {
  var transformedToRowItems: MergeRuleSetDataGridRow[] = []

  // need to create empty rows to show loader in those rows
  const loaderRows = 10 // need to get this value from config

  const MergeRuleSetItems = data.primaryMergeRuleSet
  var i = 1;
  var lengthOfPrimaryMergeruleSet = MergeRuleSetItems.length

  MergeRuleSetItems.forEach((item: MergeRuleSet) => {
    /* var mergeConditions = item.mergeOn.mergeConditions;
     if(mergeConditions != undefined && mergeConditions.length > 0 ){
       mergeConditions.forEach(mergeCondition => {
           var conditions = mergeCondition.conditions

         });
     }*/

    var models = item.priority
    var recommendationNames: string[] = []
    var insightNames: string[] = []
    var priorityFields = item.prioritizePredictionsOnField;

    if (models && models.length > 0) {
      models.forEach(element => {
        var workflow = workflowSummary.find(x => { return x.modelName === element })
        if (workflow && workflow.workFlowType === "Recommendation") {
          recommendationNames.push(workflow.config.workflowName)
        } else if (workflow && workflow.workFlowType === "Insight") {
          insightNames.push(workflow.config.workflowName)
        }
      });
    }

    var mergeOn = ""
    var mergeOnArray: string[] = []
    var fields = item.mergeOn.fields.default;
    var mergeConditions = item.mergeOn.mergeConditions
    if (fields && fields.length > 0) {
      mergeOn = fields.join("|")
    } else if (mergeConditions && mergeConditions.length > 0) {
      mergeConditions.forEach((eachMergeCondition: MergeCondition) => {
        var newConditionAdded = new AddedMergeCondition()
        newConditionAdded.id = eachMergeCondition.id
        newConditionAdded.value = ""

        eachMergeCondition.conditions.forEach((eachCondition: Conditions) => {
          newConditionAdded = getAddedMergeConditions(workflowSummary, eachCondition, newConditionAdded)
        })
        newConditionAdded.value = newConditionAdded.value != "" ? newConditionAdded.value.slice(0, -4) : newConditionAdded.value
        mergeOnArray.push(newConditionAdded.value)
      });
      mergeOn = mergeOnArray.join("|")
    }

    var resurface = ""
    if (item.create.length > 0) {
      var daysPastObject = item.create.find(x => { return x.key === 'DaysPast' })
      if (daysPastObject && daysPastObject.value) {
        resurface += "Days Past Recommendation was Actioned: " + daysPastObject.value + "|"
      }

      var expiryResurfaceObject = item.create.find(x => { return x.key === 'ExpiryResurface' })
      if (expiryResurfaceObject && expiryResurfaceObject.value) {
        var expiryDaysPastObject = item.create.find(x => { return x.key === 'DaysPastExpiry' })
        if (expiryDaysPastObject && expiryDaysPastObject.value) {
          resurface += "Days Past Recommendation was Expired: " + expiryDaysPastObject.value + "|"
        }
      }

      var actionResurfaceObject = item.create.find(x => { return x.key === 'ActionResurface' })
      if (actionResurfaceObject && actionResurfaceObject.value) {
        var actionDaysPastObject = item.create.find(x => { return x.key === 'DaysPastAction' })
        if (actionDaysPastObject && actionDaysPastObject.value) {
          resurface += "Days Past Recommendation was Actioned and Signaled from Source: " + actionDaysPastObject.value + ""
        }
      }
    }

    var transformation = ""
    item.output.map((eachOutput) => {
      var commandForDisplay = eachOutput.commandForDisplay && eachOutput.commandForDisplay.replace(/[{}']/g, '') || ""
      commandForDisplay =  commandForDisplay && commandForDisplay.replace(/ . /g, '.').replace(/,/g, ", ") || "";
      transformation += eachOutput.field + " : " + commandForDisplay + " | "
    })
    transformedToRowItems.push(
      {
        id: item.id,
        cells: {
          field0: "",
          field1: item.rank,
          field2: item.name,
          field20: recommendationNames.join(","),
          field21: insightNames.join(","),
          field3: item.priority.join(",") || "",
          field4: mergeOn || "",
          field22 : priorityFields?.join(" ") || "",
          field23 : item.useMRLevelConfigToPrioritizePredictions?.toString().length === 0 ? "true" : item.useMRLevelConfigToPrioritizePredictions?.toString(),
          field6: "Generate New Rec",
          field7: item.description,
          field8: convertFilterToDisplayFormat(item.filterToDisplay),// item.filter && item.filter.replace(/<(.*?)>/g,"") || "",
          field9: convertFilterToDisplayFormat(item.filterRecToDisplay),//item.filterRec && item.filterRec.replace(/<(.*?)>/g,"") || "",
          field10: resurface,
          field11: transformation
        },
      })
    i++;
  });

  const SecondaryMergeRuleSetItems = data.secondaryMergeRuleSet

  if (SecondaryMergeRuleSetItems != null) {
    SecondaryMergeRuleSetItems.forEach((item: MergeRuleSet) => {
      var models = item.priority
      var recommendationNames: string[] = []
      var insightNames: string[] = []
      var secPriorityFields = item.prioritizePredictionsOnField;

      if (models && models.length > 0) {
        models.forEach(element => {
          var workflow = workflowSummary.find(x => { return x.modelName === element })
          if (workflow && workflow.workFlowType === "Recommendation") {
            recommendationNames.push(workflow.config.workflowName)
          } else if (workflow && workflow.workFlowType === "Insight") {
            insightNames.push(workflow.config.workflowName)
          }
        });
      }

      var mergeOn = ""
      var mergeOnArray: string[] = []
      var fields = item.mergeOn.fields.default;
      var mergeConditions = item.mergeOn.mergeConditions
      if (fields && fields.length > 0) {
        mergeOn = fields.join("|")
      } else if (mergeConditions && mergeConditions.length > 0) {
        mergeConditions.forEach((eachMergeCondition: MergeCondition) => {
          var newConditionAdded = new AddedMergeCondition()
          newConditionAdded.id = eachMergeCondition.id
          newConditionAdded.value = ""

          eachMergeCondition.conditions.forEach((eachCondition: Conditions) => {
            newConditionAdded = getAddedMergeConditions(workflowSummary, eachCondition, newConditionAdded)
          })
          newConditionAdded.value = newConditionAdded.value != "" ? newConditionAdded.value.slice(0, -4) : newConditionAdded.value
          mergeOnArray.push(newConditionAdded.value)
        });
        mergeOn = mergeOnArray.join("|")
      }

      var resurface = ""
      if (item.create.length > 0) {
        var daysPastObject = item.create.find(x => { return x.key === 'DaysPast' })
        if (daysPastObject && daysPastObject.value) {
          resurface += "Days Past Recommendation was Actioned: " + daysPastObject.value + "|"
        }

        var expiryResurfaceObject = item.create.find(x => { return x.key === 'ExpiryResurface' })
        if (expiryResurfaceObject && expiryResurfaceObject.value) {
          var expiryDaysPastObject = item.create.find(x => { return x.key === 'DaysPastExpiry' })
          if (expiryDaysPastObject && expiryDaysPastObject.value) {
            resurface += "Days Past Recommendation was Expired: " + expiryDaysPastObject.value + "|"
          }
        }

        var actionResurfaceObject = item.create.find(x => { return x.key === 'ActionResurface' })
        if (actionResurfaceObject && actionResurfaceObject.value) {
          var actionDaysPastObject = item.create.find(x => { return x.key === 'DaysPastAction' })
          if (actionDaysPastObject && actionDaysPastObject.value) {
            resurface += "Days Past Recommendation was Actioned and Signaled from Source: " + actionDaysPastObject.value + ""
          }
        }
      }

      var transformation = ""
      item.output.map((eachOutput) => {
        var commandForDisplay = eachOutput.commandForDisplay && eachOutput.commandForDisplay.replace(/[{}']/g, '') || ""
      commandForDisplay =  commandForDisplay && commandForDisplay.replace(/ . /g, '.').replace(/,/g, ", ") || ""
      transformation += eachOutput.field + " : " + commandForDisplay + " | "
      })

      transformedToRowItems.push(
        {
          id: item.id,
          cells: {
            field0: "",
            field1: item.rank,
            field2: item.name,
            field20: recommendationNames.join(","),
            field21: insightNames.join(","),
            field3: item.priority.join(",") || "",
            field4: mergeOn || "",
            field22 : secPriorityFields?.join(" ") || "",
            field23 : item.useMRLevelConfigToPrioritizePredictions?.toString(),
            field6: "Enrich Existing Rec",
            field7: item.description,
            field8: convertFilterToDisplayFormat(item.filterToDisplay),// item.filter && item.filter.replace(/<(.*?)>/g,"") || "",
            field9: convertFilterToDisplayFormat(item.filterRecToDisplay),//item.filterRec && item.filterRec.replace(/<(.*?)>/g,"") || "",
            field10: resurface,
            field11: transformation
          },
        })
      i++;
    })
  }
  return transformedToRowItems;
}