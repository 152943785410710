import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../root-redux/RootState";
import { postRequest } from "./utils/PostRequest";
import { Release } from "../model/releases/Release.model";
import { postRequestReleaseManagement } from "./utils/PostRequestReleaseManagement";
import { Logger } from "../utils/Logger";

export const postRelease = createAsyncThunk<boolean, { payload: Release, env: string }, { state: RootState }>('post/workflows/Releases', async ( ReleaseInput, { getState }) => {

    const selectedEnv = getState().env.selectedEnvItem
    const environment = ReleaseInput.env == "" || ReleaseInput.env == null ? selectedEnv : ReleaseInput.env;
    const response = await postRequest<boolean, Release>(`${environment}/workflows/release`, ReleaseInput.payload)
    Logger.Instance.trackEvent("post workflow release", {
      "API" : `${environment}/workflows/release`, 
      "Payload": JSON.stringify(ReleaseInput.payload),   
      "Method Name": "postRelease",   
      "Environment": environment,
      "Area" : getState().userSalesRoles.userDetails?.area!,
    });
    return response.data;
    
  });

  export const postReleaseReleaseManagement = createAsyncThunk<boolean, { payload: Release, env: string }, { state: RootState }>('post/workflows/Releases', async ( ReleaseInput, { getState }) => {

    const selectedEnv = getState().env.selectedEnvItem
    const environment = ReleaseInput.env == "" || ReleaseInput.env == null ? selectedEnv : ReleaseInput.env;
    const response = await postRequestReleaseManagement<boolean, Release>(`${environment}/workflows/release`, ReleaseInput.payload)
    Logger.Instance.trackEvent("post workflow release management", {
      "API" : `${environment}/workflows/release`, 
      "Payload": JSON.stringify(ReleaseInput.payload),   
      "Method Name": "postRelease",   
      "Environment": environment,
      "Area" : getState().userSalesRoles.userDetails?.area!,
    });
    return response.data;
    
  });


