import { useSelector } from "react-redux";
import { RootState } from "../../../root-redux/RootState";
import { CheckPageAccessSelector } from "../../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { TeamsList } from "../../../model/role-based-access-control/RoleBasedAcessControl.model";
import { AppPersona } from "../../../model/app-persona/AppPersona.model";
import { Connection } from "../../../model/connections/Connection.model";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";

export const GetSubscriptionsAndEditModeSelector = (subscriptionKey: string, userTeams: TeamsList | undefined, appPersonas: AppPersona[] | undefined) => {
    const isSuperAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin)
    const isEditEnabled = CheckPageAccessSelector(subscriptionKey)?.canEdit || isSuperAdmin

    let subscriptions: {key: string, name: string}[] = []    
    if (isSuperAdmin) {
        subscriptions = appPersonas?.map(appPersona => ({key: appPersona.subscriptionKey, name: appPersona.appName})) || []
    } else {
        subscriptions = userTeams?.key.map(x => ({key: x, name: userTeams?.name[userTeams.key.indexOf(x)]})) || []
    }

    return {subscriptions, isEditEnabled}
}

export const getSelectedConnectionSelector = (state: RootState) => {
    let selectedConnection: Connection | undefined
    let type: ConnectionType | undefined 
    let subKey: string | undefined
    const selectedConnectionIds = state.connections.selectedConnectionIds
    const connectionsObj = state.connections.list
    if (selectedConnectionIds && selectedConnectionIds.length === 1 && connectionsObj) {
      const subscriptionKeys = Object.keys(connectionsObj)
      for(var subscriptionKey of subscriptionKeys) {
        const connectionItems = connectionsObj[subscriptionKey]
        const types = Object.values(ConnectionType)
        if(connectionItems){
        for(var connectionType of types) {
          if (connectionItems[connectionType] && connectionItems[connectionType].length > 0) {
            for (var connection of connectionItems[connectionType]) {
              if (connection.connectionId === selectedConnectionIds[0]) {
                selectedConnection = connection
                type = connectionType
                subKey = subscriptionKey
                break
              }
            }
          }

          if (selectedConnection) {
            break
          }
        }
      }

        if (selectedConnection) {
          break
        }
      }
    }
    return selectedConnection
}