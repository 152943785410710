import { MessageBar } from "../../common/HarmonyEnablers";

export const ErrorNotification: React.FC<any> = (props:any) => {

  return (
    <>
      <div>
        <MessageBar
          appearance="error"
          open = {props.errorNotif}
          duration={props.duration || 10000}
          onHeAfterHide={() => props.seterrornotif(false)}
        >
          {props.errorText}
        </MessageBar>
       
      </div>
    </>
  );
};
