import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Accordion, AccordionItem, RadioGroup, Radio, Tooltip } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateCurrentMergeRuleSet, updateEnableSave, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { MergeOverride } from "../../model/merge-rules/MergeOverride.model";
import FilterQueryBuilder from "./FilterQueryBuilder";
import informationConfig from "../../shared-content/inputDescription";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";

export const MergeRuleSetResurface: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation();
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
  const userTeams = useSelector(permissionsSelector).userTeams;
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)

  const getCurrentValue = (name: any) => {
    var value = ""
    switch (name) {
      case "daysPastAction": {
        var obj = currentMergeRuleSet.create?.find(x => { return x.key === "DaysPast" })
        if (obj) {
          value = obj.value || ""
        }
        break;
      }
      case "daysPastExpiry": {
        var obj = currentMergeRuleSet.create?.find(x => { return x.key === "ExpiryResurface" })
        if (obj?.value === "true") {
          var valueObj = currentMergeRuleSet.create?.find(x => { return x.key === "DaysPastExpiry" })
          value = valueObj ? valueObj.value : ""
        }
        break;
      }
      case "daysPastActionSource": {
        var obj = currentMergeRuleSet.create?.find(x => { return x.key === "ActionResurface" })
        if (obj?.value === "true") {
          var valueObj = currentMergeRuleSet.create?.find(x => { return x.key === "DaysPastAction" })
          value = valueObj ? valueObj.value : ""
        }
        break;
      }
    }
    return value;
  }

  const handleChange = (e: any) => {
    var name = e.target.name
    var value = e.target.value
    var localVar = [...currentMergeRuleSet.create]
    switch (name) {
      case "daysPastAction": {
        if (value) {
          var obj = localVar?.find((x: any) => { return x.key === "DaysPast" })
          if (obj) {
            var index = localVar.indexOf(obj)
            localVar[index] = {...localVar[index], value: value}
          } else {
            var addDaysPastObj = new MergeOverride()
            addDaysPastObj.key = "DaysPast"
            addDaysPastObj.value = value
            localVar.push(addDaysPastObj)
          }
        } else {
          localVar = localVar.filter(x => { return x.key !== "DaysPast" })
        }
        break;
      }
      case "daysPastExpiry": {
        if (value) {
          var obj = localVar?.find(x => { return x.key === "ExpiryResurface" })
          if (obj) {
            if (obj.value !== "true") {
              var index = localVar.indexOf(obj)
              localVar[index] = {...localVar[index], value: "true"}
            }
          } else {
            var addDaysPastObj = new MergeOverride()
            addDaysPastObj.key = "ExpiryResurface"
            addDaysPastObj.value = "true"
            localVar.push(addDaysPastObj)
          }

          var objexpiry = localVar?.find(x => { return x.key === "DaysPastExpiry" })
          if (objexpiry) {
            if (objexpiry.value !== value) {
              var index = localVar.indexOf(objexpiry)
              localVar[index] = {...localVar[index], value: value}
            }
          } else {
            var addDaysPastObj = new MergeOverride()
            addDaysPastObj.key = "DaysPastExpiry"
            addDaysPastObj.value = value
            localVar.push(addDaysPastObj)
          }
        } else {
          localVar = localVar.filter(x => { return x.key !== "ExpiryResurface" && x.key !== "DaysPastExpiry" })
        }
        break;
      }
      case "daysPastActionSource": {
        if (value) {
          var obj = localVar?.find(x => { return x.key === "ActionResurface" })
          if (obj) {
            if (obj.value !== "true") {
              var index = localVar.indexOf(obj)
              localVar[index] = {...localVar[index], value: "true"}
            }
          } else {
            var addDaysPastObj = new MergeOverride()
            addDaysPastObj.key = "ActionResurface"
            addDaysPastObj.value = "true"
            localVar.push(addDaysPastObj)
          }

          var objexpiry = localVar?.find(x => { return x.key === "DaysPastAction" })
          if (objexpiry) {
            if (objexpiry.value !== value) {
              var index = localVar.indexOf(objexpiry)
              localVar[index] = {...localVar[index], value: value}
            }
          } else {
            var addDaysPastObj = new MergeOverride()
            addDaysPastObj.key = "DaysPastAction"
            addDaysPastObj.value = value
            localVar.push(addDaysPastObj)
          }
        }
        else {
          localVar = localVar.filter(x => { return x.key !== "ActionResurface" && x.key !== "DaysPastAction" })
        }
        break;
      }
    }
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, create: localVar }))
  }



  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <InformationTextMergeRule text={informationConfig.editMergeRuleSetResurface} type="Tab" />
      </div>
      {/* <div className='mergerule-form-table'>
        <div  > */}
          <table className='mergerule-form-table-large' >
            <tr>
              <td><span>Resurface Recommendation after <b>X</b> Days Past it was Actioned</span>
                <Icon id="info110" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                <Tooltip anchor="info110" fixedPlacement={true} placement='top'>
                  Once a recommendation is actioned by user, it is not created again. But if tenant wishes to resurface such actioned recommendations after <b>X</b> number of days, then fill the textfield with number <b>X</b>
                </Tooltip>
              </td>
              <td>
                <TextField style={{ maxWidth: "100px", textAlign: "center" }} disabled={!access.canEdit} name='daysPastAction' placeholder="NA" value={getCurrentValue("daysPastAction")} onHeChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td><span >Resurface Recommendation after <b>X</b> Days Past it was Expired</span>
                <Icon id="info111" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                <Tooltip anchor="info111" fixedPlacement={true} placement='top'>
                  Once a recommendation is expired and tenant wishes to resurface such expired recommendations after <b>X</b> number of days, then fill the textfield with number <b>X</b>
                </Tooltip>
              </td>
              <td>
                <TextField style={{ maxWidth: "100px", textAlign: "center" }} disabled={!access.canEdit} name='daysPastExpiry' placeholder="NA" value={getCurrentValue("daysPastExpiry")} onHeChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td><span>Resurface Recommendations after <b>X</b> Days Past it was Actioned and Source of Data has sent a signal to do so</span>
                <Icon id="info112" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                <Tooltip anchor="info112" fixedPlacement={true} placement='top'>
                  Once a recommendation is actioned by user, it is not created again. But if tenant wishes to resurface such actioned recommendations after <b>X</b> number of days based on a positive signal from source data denoted by field <b>ActionResurface</b> in insight/recommendation, then fill the textfield with number <b>X</b>
                </Tooltip>
              </td>
              <td>
                <TextField style={{ maxWidth: "100px", textAlign: "center" }} disabled={!access.canEdit} name='daysPastActionSource' placeholder="NA" value={getCurrentValue("daysPastActionSource")} onHeChange={handleChange} />
              </td>
            </tr>
          </table>
        {/* </div>
      </div> */}
    </>
  )
}