import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Accordion, AccordionItem, RadioGroup, Radio, Tooltip, TextArea } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateCurrentMergeRuleSet, updateEnableSave, updateEnableSaveMR, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import informationConfig from "../../shared-content/inputDescription";
import './edit-merge-rules.css'
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const MergeRuleSetBasicsC: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation();
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
  const userTeams = useSelector(permissionsSelector).userTeams;
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)

  useEffect(() => {
    if (currentMergeRuleSet && currentMergeRuleSet.id &&
      currentMergeRuleSet.rank &&
      currentMergeRuleSet.name &&
      currentMergeRuleSet.type &&
      currentMergeRuleSet.mergeModel 
      // Commenting because field can be empty
      // &&
      // (currentMergeRuleSet.mergeOn.fields.default && currentMergeRuleSet.mergeOn.fields.default.length > 0 ||
      //   currentMergeRuleSet.mergeOn.mergeConditions && currentMergeRuleSet.mergeOn.mergeConditions.length > 0)
        ) {
      dispatch(updateEnableSaveMR(true))
    } else {
      dispatch(updateEnableSaveMR(false))
    }
  }, [currentMergeRuleSet])


  const handleChange = (e: any) => {
    switch (e.target.name) {
      case "ruleSetName":
        dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, name: e.target.value }))
        break;
      case "ruleSetDesc":
        dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, description: e.target.value }))
        break;
    }
  }

  const handleTypeChange = (e: any) => {
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, type: e.target.value }))
  }

  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <InformationTextMergeRule text={informationConfig.editMergeRuleSetConfiguration} type="Tab" />
      </div>
      <div style={{ display: "flex" }} >
        <div style={{ flex: "1 1 0%" }}>
          <table className='mergerule-form-table' >
            <tr>
              <td><span>Rank:</span><Icon className='required-icon' name='actioncenterasterisk'></Icon></td>
              <td>
                <TextField style={{ maxWidth: "50px", textAlign: "center" }} name='ruleSetRank'  label="Rank" disabled={true} value={currentMergeRuleSet.rank || ''} className={'Text-fields'}
                />
              </td>
            </tr>
            <tr>
              <td><span >Ruleset Name:</span><Icon className='required-icon' name='actioncenterasterisk'></Icon></td>
              <td>
                <TextField  label="Ruleset Name" disabled={!access.canEdit} name='ruleSetName' value={currentMergeRuleSet.name || ''} className={'Text-fields'} onHeInput={handleChange} required />
              </td>
            </tr>
          </table>
        </div>
        <div style={{ flex: "1 2 0%" }}>
          <table className='mergerule-form-table'>

            <tr>
              <td style={{paddingTop:"0.6em"}}>Recommendation Generation Type<Icon className='required-icon' name='actioncenterasterisk'></Icon>
                <Icon id="info9" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                <Tooltip anchor="info9" fixedPlacement={true} placement='end'>
                  Atleast <b>one</b> Ruleset with Recommendation Generation Type <b>Generate New Rec</b> should be created.
                </Tooltip>
              </td>
              <td>
                <RadioGroup  name="selectType" value={currentMergeRuleSet.type} >
                  <div style={{display: "flex"}}>
                  <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="primary" onHeSelected={handleTypeChange}>Generate New Rec
                    <Icon id="info1" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                    <Tooltip anchor="info1" fixedPlacement={true} placement='top'>
                      Generates recommendations newly/updates if already created when merge criteria are satisfied.
                    </Tooltip>
                  </Radio>
                  <Radio disabled={!access.canEdit || (currentMergeRuleSet.rank === '1')}  value="secondary" onHeSelected={handleTypeChange}>Enrich Existing Rec
                    <Icon id="info2" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                    <Tooltip anchor="info2" fixedPlacement={true} placement='top'>
                      Enriches recommendations generated by rule sets with Recommendation Generation Type <b>Generate New Rec</b> and creates new only if there is no match found
                    </Tooltip>
                  </Radio>
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td>Description</td>
              <td>
                <TextArea label="description" resize="vertical" disabled={!access.canEdit} name='ruleSetDesc' value={currentMergeRuleSet.description || ''} className={'Text-fields'} onHeInput={handleChange} />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </>
  )
}
export const MergeRuleSetBasics = withErrorBoundary("Merge rule set basics", MergeRuleSetBasicsC);