import { useEffect, useState } from 'react';
import { Icon, Button, FlyInPanel } from '../../common/HarmonyEnablers';
import '../onboarding-styles.css'
import { useDispatch, useSelector } from 'react-redux';
import { Workflow, WorkflowSummary } from '../../model/workflows/Workflow.model';
import { DefaultMergeRuleGridRow, addedModelsItemSelectorData, columns } from "./DefaultMergeColumn";
import { v4 as uuidv4 } from 'uuid';
import { saveWorkflow, updateDefaultWarningMsg } from '../workflows/workflow-by-id-redux/WorkflowById.actions.redux';
import { AppDispatch, RootState } from '../../root-redux/RootState';
import { MergeRule, emptyMergeRule } from '../../model/merge-rules/MergeRule.model';
import { CommandBar, DataGrid, SearchBox, Badge, HoverCard } from '../merge-rules/MergeRulesScope'
import { postMergeRule } from "../../services/get-merge-rules/PostMergeRule.api"
import { permissionsSelector } from '../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux';
import EditMergeRules  from '../edit-merge-rules/EditMergeRules';
import { MergeRuleSet, emptyMergeRuleSet } from '../../model/merge-rules/MergeRuleSet.model';
import { getMergeRuleById, getMergeRuleByModel } from '../../services/get-merge-rules/GetMergeRules.api';
import { SuccessNotification } from '../notifications/SuccessNotification';
import { EditMergeRuleMessageBar } from '../edit-merge-rules/EditMergeRuleMessageBar';
import { PreviewMergeRule } from '../preview-mergerule/PreviewMergeRule';
import { postMergeRulePreview } from '../../services/PostMergeRulePreview.api';
import { MergeRulePreviewInput, emptyMergeRulePreviewInput } from '../../model/preview-mergerule/MergeRulePreviewInput.model';
import { updateMergeRule, updateModelName } from '../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux';
import { addMergeRulesByModel, updateMergeRuleByModel } from '../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux';
import MergeRulesInWorkflows from '../merge-rules/MergeRulesInWorkflows';
import { AppPersona } from '../../model/app-persona/AppPersona.model';
import { CheckPageAccessSelector } from '../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux';
import { getModelMapping } from '../../services/GetModelMapping.api';
import { CreateMergeRuleConfirmation } from './popups/CreateMergeRuleConfirmation';
import { addMergeRuleBySubKey } from '../merge-rules/merge-rules-by-id-redux/MergeRuleBySubKey.redux';
import { RequestStatus } from '../../model/ServiceRequestStatus.model';
import { updatePreviewMergeRuleUserInput } from '../preview-mergerule/preview-mergerule-redux/PreviewMergeRule.actions.redux';
import { defaultPreviewUserInput } from '../../model/preview-mergerule/MergeRulePreviewUserInput.model';
import { HelpContent } from '../merge-rules/help-mergerules/HelpContent';
import { patchMergeRule } from '../../services/get-merge-rules/PatchMergeRule.api';
import { ResetDefaultMergeRulePopup } from './popups/ResetDefaultMergeRulePopup';
import React from 'react';
const MergeRules =  React.lazy(() => import('../../feature-components/merge-rules/MergeRules'));


export const MergeType: React.FC = () => {
  const dispatchApp = useDispatch<AppDispatch>()
  const dispatch = useDispatch()
  const typeMappingRequestStatus = useSelector((state: RootState) => state.typeMappingConfig.status);
  const typeMapping = useSelector((state: RootState) => state.typeMappingConfigByKey.typeMappingByKey.typeMapping);
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const mergeRuleStatus = useSelector((state: RootState) => state.mergeRuleById.status);
  const workflow = useSelector((state: RootState): Workflow => state.workflowById.workflow);
  const workflowList = useSelector((state: RootState): WorkflowSummary[] => state.workflows.list);
  const modelMappingConfig = useSelector((state: RootState) => state.modelMappingConfig.modelMappingObject)
  const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
  const mergeRuleAdded = useSelector((state: RootState) => state.mergeRuleByModel.mergeRule);
  const mergeRuleAddedList = useSelector((state: RootState) => state.mergeRuleByModel.list);
  const userDetails = useSelector((state: RootState) => state.userDetails);

  const [openEditMergeRule, setOpenEditMergeRule] = useState(false)
  const permissionsList = useSelector(permissionsSelector);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [mergeType, setMergeType] = useState(workflow.typeOfMerge || "SkipMerge")
  const [filterbar, setFilterbar] = useState(true);
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const userTeams = permissionsList.userTeams;
  const [disableCreate, setDisableCreate] = useState(false)
  const [modelName, setModelName] = useState("")
  const [defaultMergeRuleName, setDefaultMergeRuleName] = useState("")
  const [modelId, setModelId] = useState("")
  const [defaultMergeRule, setDefaultMergeRule] = useState(emptyMergeRule)
  const [rows, setRows] = useState([] as DefaultMergeRuleGridRow[])
  const [openPreviewPage, setOpenPreviewPage] = useState(false)
  const [openCreateRuleConfirmPopup, setOpenCreateRuleConfirmPopup] = useState(false)
  const access = CheckPageAccessSelector(workflow?.subscriptionKey)
  const [openHelpPanel, setOpenHelpPanel] = useState(false)
  const [popupShown, setPopUpShown] = useState(false)
  const [openResetPopup, setOpenResetPopup] = useState(false)

  useEffect(() => {
    if(mergeType === "Default") {
      dispatchApp(getMergeRuleById(defaultMergeRuleName))
    }
  },[mergeType])

  useEffect(() => {
    if (defaultMergeRule.type && defaultMergeRule.type !== workflow.family && !popupShown) {
      setOpenResetPopup(true)
    }
  }, [dispatch])

  useEffect(() => {
    scrollToTop()
  }, [openEditMergeRule])

  useEffect(() => {
    if (mergeRule && mergeRule.mergeRuleId === defaultMergeRuleName && mergeRule.type) {
      setDisableCreate(true)
    } else {
      setDisableCreate(false)
    }
  }, [mergeRule])

  useEffect(() => {
    var localModelName = ""
    var localModelId = ""
    var localWorkflow = workflowList.find(x => x.config.workflowId === workflow.config.workflowId)
    if (localWorkflow) {
      localModelName = localWorkflow.modelName || ""
    }
    if (localModelName && localModelName !== modelName) {
      setModelName(localModelName)
      dispatch(updateModelName(localModelName))
      setDefaultMergeRuleName("Default" + localModelName + "MR")
    } else {
      var cosmosPublish = workflow.publish.find(x => { return x.type === "COSMOS" })
      if (cosmosPublish) {
        localModelId = cosmosPublish.modelId
        if (localModelId && modelId !== localModelId) {
          setModelId(localModelId)
        }
      }
    }
  }, [workflowList, workflow])

  useEffect(() => {
    if (!modelName && modelId) {
      dispatchApp(getModelMapping({ name: "", id: modelId }))
    }
  }, [modelId])

  useEffect(() => {
    if (!modelName && modelMappingConfig && modelId && Object.keys(modelMappingConfig).length > 0) {
      var localModel = modelMappingConfig.find(x => { return x.id === modelId })
      if (localModel) {
        setModelName(localModel.name)
        dispatch(updateModelName(localModel.name))
        setDefaultMergeRuleName("Default" + localModel.name + "MR")
      }
    }
  }, [modelMappingConfig])

  useEffect(() => {
    setMergeType(workflow.typeOfMerge)
    if (workflow.typeOfMerge === "Default") {
      if (mergeRule && mergeRule.mergeRuleId === defaultMergeRuleName && mergeRule.type) {
        setDefaultMergeRule(mergeRule)
      } else {
        var localDefaultMergeRule = fillDefaultMergeRule();
        setDefaultMergeRule(localDefaultMergeRule)
      }
    }
  }, [workflow.typeOfMerge, mergeRule])

  useEffect(() => {
    setRows(addedModelsItemSelectorData(defaultMergeRule, allAppsList))
    if (defaultMergeRule.type && defaultMergeRule.type !== workflow.family && !popupShown) {
      setOpenResetPopup(true)
    }
  }, [defaultMergeRule])

  const saveMergeRule = async (reset: boolean = false) => {
    var isMergeModelAddedOtherThanDefault = false
    if (mergeRuleAddedList.length > 0) {
      var mergeRuleOtherThanDefault = mergeRuleAddedList.filter(x => { return x.mergeRuleId && x.mergeRuleId !== defaultMergeRuleName && x.type })
      isMergeModelAddedOtherThanDefault = mergeRuleOtherThanDefault && mergeRuleOtherThanDefault.length > 0
    }
    if (isMergeModelAddedOtherThanDefault) {
      setOpenCreateRuleConfirmPopup(true)
    } else {
      var output = null
      if (reset) {
        output = await dispatchApp(patchMergeRule({reset: false, mergeRule: defaultMergeRule }))
      } else {
        output = await dispatchApp(postMergeRule({ mergeRule: defaultMergeRule }))
      }
      if (output && output.payload === true) {
        setDisableCreate(true)
        dispatch(updateMergeRule(defaultMergeRule))
        dispatch(updateMergeRuleByModel(defaultMergeRule))
        dispatch(addMergeRulesByModel(defaultMergeRule))
        dispatch(addMergeRuleBySubKey(defaultMergeRule))
        setDisableCreate(true)
        if (reset) {
          setSuccessText(defaultMergeRule.mergeRuleId + " reset successfully.")
        } else {
          setSuccessText(defaultMergeRule.mergeRuleId + " created successfully.")
        }
        setShowSuccessNotif(true)
        dispatchApp(updateDefaultWarningMsg(false))
      } else {
        setDisableCreate(false)
        if (reset) {
          setErrorText(defaultMergeRule.mergeRuleId + " failed to reset.")
        } else {
          setErrorText(defaultMergeRule.mergeRuleId + " failed to create.")
        }
        setShowErrorNotif(true)
      }
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const fillDefaultMergeRule = () => {
    var localDefaultMergeRule = {} as MergeRule;
    var localFamily = "", localModelName = modelName, localMergeRuleName = defaultMergeRuleName, localDistinctField = ""
    var primaryKeyslocal: string[] = []

    workflow.publish.forEach(element => {
      if (element.type === "COSMOS" && element.publishTo.toLocaleLowerCase() === "nebula") {
        if (element.primaryKeys) {
          primaryKeyslocal = element.primaryKeys.split(",")
        } else
        // MRTODO Remove after migration is complete
        {
          element.publishMap.forEach(eachField => {
            if (eachField.isPrimary && eachField.destination !== "PartitionKey") {
              primaryKeyslocal.push(eachField.destination)
            }
            if (eachField.destination === "Primary.Family") {
              localFamily = eachField.source
            }
            if (eachField.destination === "PartitionKey") {
              localDistinctField = eachField.source
            }
          });
        }

        if (!localFamily) {
          localFamily = workflow.family
          // MRTODO Remove after migration is complete
          if (!localFamily) {
            var localFamilObj = element.publishMap.find(x => { return x.destination === "Primary.Family" })
            if (localFamilObj) {
              localDistinctField = localFamilObj.source
            }
          }
        }
        if (!localDistinctField) {
          var distinctFieldObj = element.publishMap.find(x => { return x.destination === "PartitionKey" })
          if (distinctFieldObj) {
            localDistinctField = distinctFieldObj.source
          }
        }
      }
    });

    var primaryMergeRuleSet: MergeRuleSet[] = []
    var obj = emptyMergeRuleSet;
    obj = {
      ...obj,
      id: uuidv4().toString(),
      rank: '1',
      name: 'Default Merge Ruleset',
      priority: [localModelName],
      mergeModel: [localModelName],
      mergeOn: { fields: { default: primaryKeyslocal || [] }, mergeConditions: [] }
    }

    primaryMergeRuleSet.push(obj)

    localDefaultMergeRule = {
      ...defaultMergeRule,
      mergeRuleId: localMergeRuleName,
      mergeRuleName: localMergeRuleName,
      subscriptionKey: workflow.subscriptionKey,
      tenantName: workflow.tenantName || userTeams.name[userTeams.key.indexOf(workflow.subscriptionKey)],
      version: defaultMergeRule.version || '1',
      modifiedBy: userDetails?.localAccountId,
      tags: localModelName,
      description: "Default merge rule for " + localModelName,
      primaryMergeRuleSet: primaryMergeRuleSet,
      type: localFamily,
      secondaryMergeRuleSet: [] as MergeRuleSet[],
      sourceFilter: [localModelName],
      distinctField: localDistinctField
    }
    return localDefaultMergeRule
  }

  const handleRequestClose = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenPreviewPage(false);
      dispatch(updatePreviewMergeRuleUserInput(defaultPreviewUserInput))
    }
  }

  const handleRequestCloseEditPopup = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenEditMergeRule(false)
    } else {
      event.preventDefault()
    }
  }
  return (
    <>
      {mergeType === "Default" &&
        <>
          <>
            {openCreateRuleConfirmPopup && (
              <CreateMergeRuleConfirmation defaultMergeRule={defaultMergeRule} mergeRuleAddedList={mergeRuleAddedList} setDisableCreate={setDisableCreate} setOpenCreateRuleConfirmPopup={setOpenCreateRuleConfirmPopup} setShowSuccessNotif={setShowSuccessNotif} setSuccessText={setSuccessText} setShowErrorNotif={setShowErrorNotif} setErrorText={setErrorText}
              />
            )}
            {openResetPopup && (
              <ResetDefaultMergeRulePopup setDefaultMergeRule={setDefaultMergeRule} defaultMergeRule={defaultMergeRule} mergeRuleAddedList={mergeRuleAddedList} setDisableCreate={setDisableCreate} setPopUpShown={setPopUpShown} setOpenResetPopup={setOpenResetPopup} setShowSuccessNotif={setShowSuccessNotif} setSuccessText={setSuccessText} setShowErrorNotif={setShowErrorNotif} setErrorText={setErrorText}
              />
            )}
            {!modelName &&
              <div></div>
            }
            {modelName &&
              <div style={{ paddingTop: "1em" }}>
                <Button disabled={!access.canEdit || disableCreate} appearance="command" onClick={() => { saveMergeRule(false) }}>
                  <Icon slot="start" name="add"></Icon>
                  Create Rule
                </Button>
                {defaultMergeRule.type && defaultMergeRule.type !== workflow.family &&
                  <Button disabled={!access.canEdit} appearance="command" style={{ marginLeft: "20px", fontSize: "14px" }} onClick={() => { setOpenResetPopup(true) }}>
                    <Icon slot="start" name="refresh"></Icon>
                    Reset
                  </Button>
                }
                <Button id='preview-button' style={{ marginLeft: "20px", fontSize: "14px" }} appearance='command' onClick={() => { setOpenPreviewPage(true) }}>
                  <Icon slot="start" name="redeye" />
                  Preview
                </Button>
                <Button id="mergetypeHelp" appearance="command" style={{ marginLeft: "20px", fontSize: "14px" }} onClick={() => { setOpenHelpPanel(!openHelpPanel); }}>
                  <Icon slot="start" name="help"></Icon>
                  Help
                </Button>
              </div>
            }
            {modelName &&
              <div className='mergerule-body-overall-default'>
                {openHelpPanel &&
                  <HelpContent openHelpPanel={openHelpPanel} setOpenHelpPanel={setOpenHelpPanel} />
                }
                <hr />
                <DataGrid rows={rows} columns={columns} loading={mergeRuleStatus === RequestStatus.loading}>
                  {rows.map(row => {
                    return (
                      <>
                        {disableCreate &&
                          <span slot={`field1-${row.id}`} key={`field1-${row.id}`} style={{ width: "200px", color: "#0078D4", cursor: "pointer" }}
                            onClick={() => {
                              setOpenEditMergeRule(true)
                            }} >
                            {row.cells.field1}
                          </span>
                        }
                        <span style={{ width: "200px" }}>{row.cells.field2}</span>
                        <HoverCard slot={`field3-${row.id}`} key={`field3-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                          <div role="button" style={{ width: "200px" }} slot="anchor" key={`field3-${row.id}`}>{row.cells.field3 === null || row.cells.field3 === '' ? '' : row.cells.field3 != null && row.cells.field3.length > 30 ? row.cells.field3.substring(0, 30) + "..." : row.cells.field3}</div>
                          {row.cells.field3 != undefined &&
                            <code style={{ color: "black" }}>{row.cells.field3.split(",").map(eachRow => {
                              return <div>{eachRow}</div>
                            })}
                            </code>
                          }
                          {row.cells.field3 == undefined &&
                            <code style={{ color: "black" }}>
                            </code>
                          }
                        </HoverCard>
                        <span style={{ width: "200px" }}>{row.cells.field4}</span>
                        <span style={{ width: "200px" }}>{row.cells.field5}</span>
                      </>
                    )
                  })}
                </DataGrid>
                {openPreviewPage &&
                  <FlyInPanel style={{ '--size': 'var(--he-panel-size-large)' }}
                    open={openPreviewPage}
                    onHeRequestClose={handleRequestClose}
                    heading={`Preview MergeRule - ${defaultMergeRuleName}`}>
                    <PreviewMergeRule localmergeRule={defaultMergeRule} mergeRuleName={defaultMergeRuleName} subKey={workflow.subscriptionKey} workflowId={workflow.id} />
                  </FlyInPanel>
                }
              </div>
            }
            {!modelName || (defaultMergeRule.primaryMergeRuleSet.length > 0 &&
              defaultMergeRule.primaryMergeRuleSet[0].mergeOn.fields.default.length === 0
              && defaultMergeRule.primaryMergeRuleSet[0].mergeOn.mergeConditions.length === 0) &&
              <div className='mergerule-body-overall-default'>
                <div>Mandatory model config required for merge rule creation not available with this workflow.<br />This might be because your workflow is in older version and need to migrate or some other issue exists.<br />Please reach out to Nebula team in case migrating to newer version doesnt work</div>
              </div>
            }
          </>
          {openEditMergeRule &&
            <FlyInPanel style={{ '--size': 'var(--he-panel-size-extra-large)' }}
              open={openEditMergeRule}
              onHeRequestClose={handleRequestCloseEditPopup}
              heading={`${defaultMergeRule.mergeRuleId && defaultMergeRule.mergeRuleId !== 'undefined' ? `Edit Merge Rule | ${defaultMergeRule.mergeRuleId}` : `Create New Merge Rule`}`}>
              <EditMergeRules setOpenEditMergeRule={setOpenEditMergeRule} openEditMergeRule={openEditMergeRule} source={'default'} defaultMergeRuleId={defaultMergeRule.mergeRuleId} />
            </FlyInPanel>
          }
          <div><SuccessNotification successNotif={showSuccessNotif} setsuccesnotif={setShowSuccessNotif} successText={successText} />  </div>
        </>
      }
      {mergeType === "NewUpdate" &&
        <MergeRulesInWorkflows subKey={workflow.subscriptionKey} workflowModelName={modelName} />
      }
    </>
  );
}