import { PreviewWorkflowHeaderBar } from "../preview-workflow/PreviewWorkflowHeaderBar"
import { PreviewWorkflowPanel } from "../preview-workflow/PreviewWorkflowPanel"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../root-redux/RootState"
import { PreviewMergeRuleHeaderBar } from "./PreviewMergeRuleHeaderBar"
import { PreviewMergeRulePanel } from "./PreviewMergeRulePanel"
import { PreviewMergeRuleBody } from "./PreviewMergeRuleBody"
import { useEffect, useState } from "react"
import { emptyMergeRulePreviewInput } from "../../model/preview-mergerule/MergeRulePreviewInput.model"
import { postMergeRulePreview } from "../../services/PostMergeRulePreview.api"
import { formDataInsights, formDataPredictions } from "./PreviewFunctions"
import { updatePreviewMergeRuleUserInput } from "./preview-mergerule-redux/PreviewMergeRule.actions.redux"
import { PreviewMergeRuleUserInput } from "./PreviewMergeRuleUserInput"

export const PreviewMergeRule: React.FC<any> = (props: any) => {
    const { mergeRuleName, rank, subKey, workflowId, mergeruleSetId, localmergeRule , source} = props
    const dispatchApp = useDispatch<AppDispatch>()
    const dispatch = useDispatch()
    const previewMap = useSelector((state: RootState) => state.previewWorkflow.map)
    const previewMergeRuleUserInput = useSelector((state: RootState) => state.previewMergeRule.previewMergeRuleUserInput)
    const workflow = useSelector((state: RootState) => state.workflowById.workflow)
    const [previewInput, setPreviewInput] = useState(emptyMergeRulePreviewInput)
    const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet)

    useEffect(() => {
        var input = { ...previewInput }
        var type = workflowId ? "both" : "existing"
        if (workflowId) {
            const previewData = previewMap[workflowId]
            var publishName = workflow.publish.filter(x => { return x.type === "COSMOS" && x.publishTo.toLowerCase() === "nebula" })
            var workflowType = workflow.workFlowType

            if (workflowType === "Recommendation") {
                var predictions = formDataPredictions(workflow, previewData)
                if (predictions.length > 0) {
                    var tpid = predictions[0].partitionKey
                    if (tpid) {
                        input = { ...input, tpidList: [tpid] }
                    }
                }
                input = { ...input, predictions: predictions }
            }
            if (workflowType === "Insight") {
                var insights = formDataInsights(workflow, previewData)
                if (insights.length > 0) {
                    var tpid = insights[0].partitionKey
                    if (tpid) {
                        input = { ...input, tpidList: [tpid] }
                    }
                }
                input = { ...input, insights: insights }
            }
        }
        input = { ...input }
        if(localmergeRule) {
            var manipulate = localmergeRule
            input = {...input, mergerule: localmergeRule}
        }
        setPreviewInput(input)
        dispatch(updatePreviewMergeRuleUserInput({
            ...previewMergeRuleUserInput,
            tpids: input.tpidList,
            inputFrom: type,
            isOnlyMerge: true,
            tpidLimit: "1",
            tpidSource: workflowId ? "workflow" : "user",
            onlyOneMergeRuleSet: source && source === "header" ? true : false
        }))
        if (mergeruleSetId) {
            dispatchApp(postMergeRulePreview({ id: mergeRuleName, subKey: subKey, mergeruleSetId: mergeruleSetId, limit: "1", type: type, input: input, onlyMergeruleSet: false }))
        } else {
            dispatchApp(postMergeRulePreview({ id: mergeRuleName, subKey: subKey, mergeruleSetId: "", limit: "1", type: type, input: input, onlyMergeruleSet: false }))
        }
    }, [dispatchApp])

    return (
        <>
            <div>
                <PreviewMergeRuleHeaderBar source={source} mergeruleSetId={mergeruleSetId} />
                <PreviewMergeRuleBody source={source} subKey={subKey} workflowId={workflowId} previewInput={previewInput} mergeRuleName={mergeRuleName} setPreviewInput={setPreviewInput} workflowType={workflow.workFlowType || ""} mergeruleSetId={mergeruleSetId} localmergeRule={localmergeRule} />
            </div>
        </>
    )
}