import { ReactNode, useState } from "react";
import { LayoutFields } from "../../../../model/SPAModels/campaigns/campaignConfig.Model";
import { DatePicker, Icon, Select, TextField, Option, TextArea, Tooltip } from "../../SpaAdminScope";
import { RequestStatus } from "../../../../model/ServiceRequestStatus.model";
import { Helper } from "../../utils/Help";
import { CampaignTypes, HelpTexts, SPA_CAMPAIGN } from "../../SPAConstants";
import { useSelector } from "react-redux";
import { permissionsSelector } from "../../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { AppPersona } from "../../../../model/app-persona/AppPersona.model";
import { RootState } from "../../../../root-redux/RootState";
import { UserMode } from "../../redux/CacheHandler.redux";

type stateType = { taxonomyDetails: { [key: string]: { [key: string]: Set<string> } }, status: RequestStatus };

interface IConfigRenderer {
    summary: { [key: string]: string | undefined },
    layoutConfigs: LayoutFields[] | undefined,
    selectionChange: (event: any) => boolean,
    taxonomyState: stateType,
    section: number,
    editCampaignFlag: boolean
}

interface IMandatoryFields {
    selectionChange: (event: any) => boolean, 
    layoutConfigs: LayoutFields[] | undefined, 
    summary: { [key: string]: string | undefined },
    editCampaignFlag: boolean
}

const getEditOptions = (summary: { [key: string]: string | undefined }, layoutConfig: LayoutFields, taxonomyState: stateType): ReactNode => {
    return Array.from((((taxonomyState.taxonomyDetails[layoutConfig.taxonomyKey!] || {})[layoutConfig.name!]) || new Set<String>()).values())
        .filter(f => ((f as string) || "").length > 0)
        .sort()
        .map(field => <Option selected={summary[layoutConfig.name] === (field as string)}>{field as string}</Option>)
}

const getFieldValue = (summary: any, field: string, type: string | undefined = undefined) => {
    return (summary && summary[field]) ? summary[field] : (type === 'date' ? undefined : '');
}

export const validateField = (pattern: string | undefined, value: string | undefined) => {
    if (pattern) {
        return (value || "").match(pattern);
    }
    return value && value.length > 0;
}

const validateAndApply = (selectionChange: (event: any) => void, layoutConfigs: LayoutFields[] | undefined, event: any, errorMessage: string | undefined) => {
    const target = event.target;
    const pattern = (layoutConfigs || []).find(config => config.name === target.name)?.regex;
    if (validateField(pattern, (target.value || "").trim())) {
        target.setCustomValidity('');
    } else {
        target.setCustomValidity(errorMessage);
    }
    selectionChange(event);
}

const getMaxDate = () => {
    // TODO: compute end date per fiscal year.
    const temp = new Date("03/31/2025");
    return (temp.toISOString().split('T')[0]);
}

const generateElements = (summary: { [key: string]: string | undefined }, layoutConfig: LayoutFields, taxonomyState: stateType, editCampaignFlag: boolean, selectionChange: (event: any) => void): ReactNode => {
    if (taxonomyState.status !== RequestStatus.succeeded || Helper.isEmpty(taxonomyState.taxonomyDetails)) {
        return <></>;
    }
    if (layoutConfig.type === 'textbox') {
        return (
            <td>
                <TextField name={layoutConfig.name} label={layoutConfig.name} className={'Text-fields'} placeholder={`Enter ${layoutConfig.displayValue} here`} value={getFieldValue(summary, layoutConfig.name)} disabled={editCampaignFlag && !layoutConfig.editable} onHeChange={(event) => validateAndApply(selectionChange, [layoutConfig], event, `${layoutConfig.displayValue} is less than (5) character in length or has invalid character's`)} />
            </td>
        )
    }
    else if (layoutConfig.type === 'textarea') {
        var value = getFieldValue(summary, layoutConfig.name).replaceAll('\\\\\\\\r\\\\\\\\n', layoutConfig.name === 'RecommendationTitle' ? '\n' : '\n\n').replaceAll('$$','$').replaceAll("\\\\'", "'");
        return (
            <td>
                <TextArea name={layoutConfig.name} label={layoutConfig.name} className={'Text-fields'} placeholder={`Enter ${layoutConfig.displayValue} here`} style={{ marginBottom: '30px' }} value={value} onHeChange={(event) => validateAndApply(selectionChange, [layoutConfig], event, `${layoutConfig.displayValue} is not within 5 to 2500 characters in length or has invalid character's`)} /> {/* disabled={editCampaignFlag && !layoutConfig.editable} /> => TODO: Uncomment before deployment*/}
            </td>
        )
    }
    else if (layoutConfig.type === "datetime") {
        return (
            <td className='spa-form'>
                <DatePicker name={layoutConfig.name} className={'Text-fields'} onHeChange={selectionChange} required={layoutConfig.required} placeholder={`Select ${layoutConfig.name} here`} value={getFieldValue(summary, layoutConfig.name, 'date')} disabled={editCampaignFlag && !layoutConfig.editable} />
            </td>
        )
    }
    else if (layoutConfig.type === 'dropdown') {
        return (
            <td>
                <Select name={layoutConfig.name} label={layoutConfig.name} className={'Text-fields'} onHeChange={selectionChange} required={true} placeholder={`Select ${layoutConfig.displayValue} here`} autocomplete="list" value={getFieldValue(summary, layoutConfig.name)} disabled={editCampaignFlag && !layoutConfig.editable}>
                    {getEditOptions(summary, layoutConfig, taxonomyState)}
                </Select>
            </td>
        )
    }
    return <></>;
}

export const RenderMandatoryFields: React.FC<IMandatoryFields> = ({selectionChange, layoutConfigs, summary, editCampaignFlag}) => {

    const permissionsList = useSelector(permissionsSelector);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const isSuperAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin);
    const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
    const campaignCache = useSelector((state: RootState) => state.cacheHandler.campaignCache);

    const todayDate = new Date().toISOString().split('T')[0];
    const [actionByMinDate, setActionByMinDate] = useState<string|undefined>(undefined);
    const [expiryMinDate, setExpiryMinDate] = useState<string|undefined>(undefined);
    const expiryMaxDate = getMaxDate();

    const userTeams = permissionsList.userTeams;
    const teamsList: [string, string][] = [];
    const allTeamsList: [string, string][] = [];

    userTeams.name.forEach((element: string) => teamsList.push([userTeams.key[userTeams.name.indexOf(element)], element]));
    allAppsList.forEach((app: AppPersona) => allTeamsList.push([app.subscriptionKey, app.appName]));
    
    return (
        <>
            <tr>
                <td>
                    SPA/Campaign Name <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'CampaignName'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'CampaignName'}>
                        {HelpTexts.CampaignName}
                    </Tooltip>
                </td>
                <td>
                    <TextField name={'CampaignName'} label={'CampaignName'} className={'Text-fields'} placeholder={`Enter Campaign Name here`} value={getFieldValue(summary, 'CampaignName')} onHeChange={(event) => validateAndApply(selectionChange, layoutConfigs, event, "Campaign Name is less than (5) character in length or has invalid character's")} />
                </td>
            </tr>
            <tr>
                <td>
                    Description <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'Description'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'Description'}>
                        {HelpTexts.Description}
                    </Tooltip>
                </td>
                <td>
                    <TextArea name={'Description'} label={'Description'} className={'Text-fields'} placeholder={`Enter Description here`} style={{ marginBottom: '30px' }} value={getFieldValue(summary, 'Description')} disabled={editCampaignFlag} onHeChange={(event) => validateAndApply(selectionChange, layoutConfigs, event, "Description is less than (5) character in length or has invalid character's")} />
                </td>
            </tr>
            <tr>
                <td>
                    SPA/Campaign Tag <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'Tags'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'Tags'}>
                        {HelpTexts.Tags}
                    </Tooltip>
                </td>
                <td className='spa-form'>
                    <TextField name={'Tags'} label={'CampaignName'} className={'Text-fields'} placeholder={`Enter Tag here`} value={getFieldValue(summary, 'Tags')} disabled={editCampaignFlag} onHeChange={(event) => validateAndApply(selectionChange, layoutConfigs, event, "Tag must be at least of length 3")} />
                </td>
            </tr>
            <tr style={{display: userSalesRoles.isSpaUser ? 'none' : 'contents'}}>
                <td>Tenant
                    <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon id="info401" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                    <Tooltip anchor="info401" fixedPlacement={true} placement='bottom'>
                        Team names Ex: SMC, PIE, Accounts 360. A user can be part of multiple teams.
                    </Tooltip>
                </td>
                <td>
                    <Select
                        label={'Tenant'}
                        disabled={userSalesRoles.isSpaUser || editCampaignFlag}
                        value={getFieldValue(summary, 'SubscriptionKey') || SPA_CAMPAIGN}
                        slot='action'
                        fixed-placement
                        className='Text-fields'
                        placeholder={'Select Tenant here'}
                        name='SubscriptionKey'
                        onHeChange={selectionChange}
                        required
                    >
                    {isSuperAdmin? 
                        allTeamsList.map(item => (
                            <Option
                                key={item[0]}
                                role='menuitemcheckbox'
                                value={item[0]}
                            >
                                {item[1]}
                            </Option>
                        ))
                        :
                        teamsList.map(item => (
                            <Option
                                key={item[0]}
                                role='menuitemcheckbox'
                                value={item[0]}
                                selected={item[1] === SPA_CAMPAIGN || item[0] === summary['SubscriptionKey']}
                            >
                                {item[1]}
                            </Option>
                        ))
                    }
                    </Select>
                </td>
            </tr>
            <tr>
                <td>
                    Go Live Date <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'StartOn'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'StartOn'}>
                        {HelpTexts.StartOn}
                    </Tooltip>
                </td>
                <td className='spa-form'>
                    <DatePicker name={'StartOn'} className={'Text-fields'} onHeChange={(e: any) => {
                        selectionChange(e);
                        const temp = new Date(e.target.value);
                        temp.setDate(temp.getDate() + 2);
                        setActionByMinDate(temp.toISOString().split('T')[0]);
                    }} min-date={todayDate} placeholder={`Select Start On here`} required={true} value={getFieldValue(summary, 'StartOn', 'date')} disabled={editCampaignFlag} />
                </td>
            </tr>
            <tr>
                <td>
                    Action By Date <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'ActionByDate'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'ActionByDate'}>
                        {HelpTexts.ActionByDate}
                    </Tooltip>
                </td>
                <td className='spa-form'>
                    <DatePicker name={'ActionByDate'} className={'Text-fields'} onHeChange={(e: any) => {
                        selectionChange(e);
                        const temp = new Date(e.target.value);
                        temp.setDate(temp.getDate() + 1);
                        setExpiryMinDate(temp.toISOString().split('T')[0]);
                    }} min-date={actionByMinDate} placeholder={`Select Action By Date here`} required={true} value={getFieldValue(summary, 'ActionByDate', 'date')} disabled={actionByMinDate === undefined}/>
                </td>
            </tr>
            <tr>
                <td>
                    Expiry Date <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'ExpiryDate'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'ExpiryDate'}>
                        {HelpTexts.ExpiryDate}
                    </Tooltip>
                </td>
                <td className='spa-form'>
                    <DatePicker name={'ExpiryDate'} className={'Text-fields'} onHeChange={selectionChange} disabled={(expiryMinDate === undefined) || (expiryMaxDate === undefined)} minDate={expiryMinDate} placeholder={`Select Expiry Date here`} required={true} value={getFieldValue(summary, 'ExpiryDate', 'date')} />
                </td>
            </tr>
            <tr>
                <td>
                    Type of Campaign <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'TypeOfCampaign'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'TypeOfCampaign'}>
                        Segment for this campaign (Field Ent, SMC or Digital sales)<br/>
                        Note: For MVP each campaign created in the tool can only be aligned to one of the segments above. Multiple campaign will need to be created with campaign is multi-segment.
                    </Tooltip>
                </td>
                <td>
                    <Select name='CampaignType' label='Campaign Type' className={'Text-fields'} onHeChange={selectionChange} placeholder={`Select Campaign Type here`} autocomplete="list" value={getFieldValue(summary, "CampaignType") || CampaignTypes[0]} disabled={editCampaignFlag || campaignCache?.mode === UserMode.EDIT}>
                    {
                        CampaignTypes.map(optionValue => (
                            <Option selected={summary["CampaignType"] === optionValue}>{optionValue}</Option>
                        ))
                    }
                    </Select>
                </td>
            </tr>
        </>
    )
}

const ConfigRendererComponent: React.FC<IConfigRenderer> = ({ summary, section, layoutConfigs, selectionChange, taxonomyState, editCampaignFlag }) => {
    return (
        <>
        {
            layoutConfigs && layoutConfigs.filter(layoutConfig => layoutConfig.section === section).map(layoutConfig => (
                layoutConfig.type !== "hidden" &&
                <tr>
                    <td>
                        {layoutConfig.displayValue} {layoutConfig.required && <Icon className='required-icon' name='actioncenterasterisk'></Icon>}
                        <Icon name='info' id={layoutConfig.name} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                        <Tooltip anchor={layoutConfig.name}>
                            {HelpTexts[`${layoutConfig.name}`]}
                        </Tooltip>
                    </td>
                    {generateElements(summary, layoutConfig, taxonomyState, editCampaignFlag, selectionChange)}
                </tr>
            ))
        }
        </>
    )
}

export const ConfigRenderer = ConfigRendererComponent;