import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { TypeMappingObject } from "../../model/configModels/TypeMappingObject.model";
import { updateTypeMapping } from "../../services/PostTypeMapping.api";

interface TypeMappingConfig {
    tyepMapping: TypeMappingObject,
    status: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean
}

const initialState: TypeMappingConfig = {
    tyepMapping: {} as TypeMappingObject,
    status: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false
}



export const typeMappingConfigSlice = createSlice({
    name: 'typeMapping',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(updateTypeMapping.pending, (state, action) => {
                state.status = RequestStatus.loading
            }).addCase(updateTypeMapping.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload            
                }
                state.error = undefined
            })
            .addCase(updateTypeMapping.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
    }
});

export const typeMappingConfigReducer = typeMappingConfigSlice.reducer