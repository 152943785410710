
import { postRequest } from './utils/PostRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TypeMappingObject } from '../model/configModels/TypeMappingObject.model';
import { RootState } from '../root-redux/RootState';
import { postRequestReleaseManagement } from './utils/PostRequestReleaseManagement';

export const updateTypeMapping = createAsyncThunk<boolean, { payload: TypeMappingObject }, { state: RootState }>('typeMapping/updateTypeMapping', async ({ payload }, { getState }) => {
  const selectedEnv = getState().env.selectedEnvItem
  const response = await postRequest<boolean, TypeMappingObject>(`${selectedEnv}/typemapping/update`, payload)
  return response.data;
});


export const updateTypeMapping_uat = createAsyncThunk<boolean, { payload: TypeMappingObject }>('uat/typeMapping/updateTypeMapping', async ({ payload }) => {
  const response = await postRequestReleaseManagement<boolean, TypeMappingObject>(`uat/typemapping/update`, payload)
  return response.data;
});

export const updateTypeMapping_prod = createAsyncThunk<boolean, { payload: TypeMappingObject }>('prod/typeMapping/updateTypeMapping', async ({ payload }) => {
  const response = await postRequestReleaseManagement<boolean, TypeMappingObject>(`prod/typemapping/update`, payload)
  return response.data;
});

