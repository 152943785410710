import { useSelector } from "react-redux";
import { permissionsSelector } from "./RoleBasedAccessControl.selectors.redux";
import { RootState } from "../../root-redux/RootState";

export const CheckPageAccessSelector = (subscriptionKey: string) => {
    const permissions = useSelector(permissionsSelector);
    const isSuperAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin);
    const selectedEnv = useSelector((state: RootState) => state.env.selectedEnvItem);

    return {
        canEdit: (isSuperAdmin || permissions.edit.includes(subscriptionKey)) && selectedEnv === 'dev',
        canEditAcrossAllEnv: (isSuperAdmin || permissions.edit.includes(subscriptionKey)),
        canApprove: isSuperAdmin || permissions.approve.includes(subscriptionKey),
        canRelease: isSuperAdmin || permissions.release.includes(subscriptionKey)
    }
}