import { useDispatch, useSelector } from "react-redux";
import { Select, Option, Button, Tooltip } from "../../../common/HarmonyEnablers";
import { EventConnection } from "../../../model/connections/EventConnection.model";
import { Icon, TextField } from "../ConnectionScope";
import "../connections.css"
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { postConnections } from "../../../services/PostConnections.api";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Connection } from "../../../model/connections/Connection.model";


const EventConnectionPanelC: React.FC<{connectionData: EventConnection, editMode: boolean, appScopes: string[] | undefined, connectionType: ConnectionType, subscriptions: {key: string, name: string}[], authType:string}> = ({connectionData, editMode, appScopes, connectionType, subscriptions, authType}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState<EventConnection>(connectionData);
    const selectedEnv =  useSelector((state: RootState) => state.env.selectedEnvItem);

    const adbScopeDev = "https://adb-5404863101054673.13.azuredatabricks.net/?o=5404863101054673#secrets/createScope";
    const adbScopUat = "https://adb-2987319840690271.11.azuredatabricks.net/?o=2987319840690271#secrets/createScope";
    const adbScopProd = "https://adb-5011763184037517.17.azuredatabricks.net/?o=5011763184037517#secrets/createScope";


    useEffect(() => {
        setData(connectionData)
    }, [connectionData])
    
    if (!connectionData && !editMode) {
        return <></>
    }

    const canEnableSave = () => {
        if (editMode) {
            if ( authType == "spn" && (!data.connectionName?.trim() || !data.appScope || !data.eventConnectionString?.trim())) {
                return false
            }
            if ( authType == "msi" && (!data.connectionName?.trim() || !data.appScope)) {
                return false
            }
            if (connectionData && JSON.stringify(connectionData) === JSON.stringify(data)) {
                return false
            }
            return true
        }
        return false
    }

    const onSaveClick = () => {

        var finalData = {} as EventConnection extends Connection ? EventConnection : Connection;
        if(authType == "spn")
            finalData = {...data, useMSI: 0};
        else
        finalData = {...data, useMSI: 1, eventConnectionString: ''};
    
        dispatch(postConnections({ newConnection: finalData, type: connectionType }))
    }

    let subscriptionName = ''
    if (!editMode) {
        subscriptionName = subscriptions.filter(item => item.key === data.subscriptionKey)[0]?.name || ''
    }

    return ( 
        <>       
                <div className='input-row'>
                    <div>Name</div>
                    <div>
                        <TextField id = "eventname" required disabled={!editMode} placeholder="Name" value={data.connectionName || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, connectionName: e.target.value})
                            
                        }}/>
                    </div>
                </div>
                <div className='input-row'>
                    <div>Tenant</div>
                    <Select
                            value= {data.subscriptionKey || ''}
                            slot="action"
                            fixed-placement
                            disabled={!editMode}
                            placeholder={subscriptionName || 'Select'}
                            id = "eventtenant"
                            >
                            {
                                subscriptions?.map((item) => (
                                    <Option
                                        key={item.key}
                                        role="menuitemcheckbox"
                                        value={item.key}
                                        onHeSelected={(e: any) => {
                                            setData({...data, subscriptionKey: e.target.value})
                                            
                                        }}
                                    >
                                        {item.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
                <div className='input-row'>
                    <div>App Scope
                    <Icon id="info75" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info75" fixedPlacement={true} placement='bottom'> Select the app scope. If creating, then create the app scope in all the three environments. </Tooltip>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Select
                                value= {data.appScope || ''}
                                slot="action"
                                fixed-placement
                                disabled={!editMode}
                                placeholder='Select'
                                style={{width: '500px'}}
                                id = "eventscope"
                                >
                                {
                                    appScopes?.map(item => (
                                        <Option
                                            key={item}
                                            role="menuitemcheckbox"
                                            onHeSelected={(e: any) => {
                                                setData({...data, appScope: e.target.textContent})
                                                
                                            }}
                                        >
                                            {item}
                                        </Option>
                                    ))
                                }
                            </Select>
                            {/* TODO add this to aka.ms */}
                            {
                                selectedEnv == "dev" ? <a id="eventdevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a> 
                                : selectedEnv == "uat" ? <a id="eventuatscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopUat}>Add New Scope</a> 
                                : selectedEnv == "prod" ? <a id="eventprodscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopProd}>Add New Scope</a> 
                                : <a id="eventdevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a>
                            }
                            <Icon id="infoaddscopeevent" name="info" style={{ marginLeft: "4px", cursor: "pointer", color: "#0078D4" }} tabIndex={0}/>
                            <Tooltip anchor="infoaddscopeevent" fixedPlacement={true} placement='bottom'>
                            The new scope needs to be created in all the three environments
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {authType == "spn" &&
                <div className='input-row'>
                    <div>Connection String
                    <Icon id="info76" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info76" fixedPlacement={true} placement='bottom'> Select the Connection String </Tooltip>
                    </div>
                    <div><TextField  id = "eventconnectionstring" required disabled={!editMode} placeholder="Connection String" value={data.eventConnectionString || ''} onHeInput={(e: any) => {
                            setData({...data, eventConnectionString: e.target.value})
                            
                        }}/>
                    </div>
                </div>
                }
            <div slot="footer" className="panel-footer">
                <Button  id = "eventsave" onClick={onSaveClick} disabled={!canEnableSave()} appearance={editMode ? 'primary': undefined}>
                    Save
                </Button>
            </div>
        </>     
    )
}
export const EventConnectionPanel = withErrorBoundary("EventConnectionPanel", EventConnectionPanelC);