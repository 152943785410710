import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getAppPersonas } from '../../services/GetAppPersonaMapping.api'
import { AppPersona } from '../../model/app-persona/AppPersona.model'
import { AppIdOnboardingResponse } from '../../model/app-persona/AppIdOnboardingResponse.model'
import { validateAppOnboarding } from '../../services/ValidateAppOnboarding.api'

interface AppPersonaState {
    list: AppPersona[],
    status: RequestStatus,
    error: string | undefined,
    putstatus: RequestStatus,
    puterror: string | undefined,
    appOnboardingStatus: AppIdOnboardingResponse|undefined
}

const initialState: AppPersonaState = {
  list: [],
  status: RequestStatus.idle,
  error: '',
  putstatus: RequestStatus.idle,
  puterror: '',
  appOnboardingStatus: {} as AppIdOnboardingResponse
}

const appPersonaSlice = createSlice({
  name: 'appPersona',
  initialState,
  reducers: {
    addApppersona: (state) => {
        state.list = []
      },
      resetValidateOnboard: (state) => {
        state.putstatus = RequestStatus.idle
        state.puterror = ''
        state.appOnboardingStatus = {} as AppIdOnboardingResponse
      }
  },
  extraReducers(builder) {
    builder
      .addCase(getAppPersonas.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getAppPersonas.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        state.list = state.list.concat(action.payload)
        state.error = undefined
      })
      .addCase(getAppPersonas.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
      .addCase(validateAppOnboarding.pending, (state, action) => {
        state.putstatus = RequestStatus.loading
        state.appOnboardingStatus = {} as AppIdOnboardingResponse
      })
      .addCase(validateAppOnboarding.fulfilled, (state, action) => {
        state.appOnboardingStatus = action.payload
        if((action.payload?.appId && action.payload.appId === "DoesntExist") ||
           (action.payload?.audience && action.payload.audience === "DoesntExist") ||
           (action.payload?.appTokenAuthUsers && action.payload.appTokenAuthUsers === "DoesntExist")) {
              if(action.payload?.configStatus && action.payload?.configStatus === "Added") {
                state.putstatus = RequestStatus.succeeded
                state.puterror = undefined
              } else if (!action.payload?.configStatus  || 
                (action.payload?.configStatus && action.payload?.configStatus === "FailedToAdd")) {
                  state.putstatus = RequestStatus.failed
                  state.puterror = "Failed To Add Onboarding Configuration. Please contact Nebula Team"
              }
            }else {
                state.putstatus = RequestStatus.succeeded
                state.puterror = undefined
            }
      })
      .addCase(validateAppOnboarding.rejected, (state, action) => {
        state.putstatus = RequestStatus.failed
        state.puterror = action.error.message
        state.appOnboardingStatus = {} as AppIdOnboardingResponse
      })
  }
})

export const { resetValidateOnboard } = appPersonaSlice.actions

export const appPersonaReducer = appPersonaSlice.reducer