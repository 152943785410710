export const allLocalLinks = {
  privacyNotice: "https://go.microsoft.com/fwlink/?LinkId=521839",
  nebulaReports : "https://aka.ms/nebulareporting",
  home_whatshappeninginnebula: "https://microsoftapc.sharepoint.com/:v:/t/UnifiedRecommendationPlatformEngineeringTeam/ES-LI4cK0hJDkblA9ahWO9cBpcnb_dRNTRO7UoG2i5eLbw?e=bpD2Yg",
  home_onboardinsight : "https://microsoftapc.sharepoint.com/teams/UnifiedRecommendationPlatformEngineeringTeam/_layouts/15/stream.aspx?id=%2Fteams%2FUnifiedRecommendationPlatformEngineeringTeam%2FShared%20Documents%2FGeneral%2FMarketing%2DVideos%2FHelp%2FOnboard%2Dan%2DInsight%2Emp4&referrer=Teams%2ETEAMS%2DELECTRON&referrerScenario=p2p%5Fns%2Dbim&ga=1",
  home_whatisnebula : "https://sway.office.com/7dfdFS4qBEBGPvH9?ref=Link",
  home_empoweringefficiency: "https://sway.office.com/9HNthOuczbsHbodC?ref=Link",
  home_getstarted : "https://sway.office.com/vG0qefCGAdnideK1?ref=Link",
  home_incontexthelp : "https://sway.office.com/FOfaA0Ju3xuaExKj?ref=Link",
  home_onboardrec: "https://microsoftapc.sharepoint.com/teams/UnifiedRecommendationPlatformEngineeringTeam/_layouts/15/stream.aspx?id=%2Fteams%2FUnifiedRecommendationPlatformEngineeringTeam%2FShared%20Documents%2FGeneral%2FMarketing%2DVideos%2FHelp%2FOnboarding%2Da%2DRecommendation%2Emp4&referrer=Teams%2ETEAMS%2DELECTRON&referrerScenario=p2p%5Fns%2Dbim&ga=1"

}