import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { MergeRule } from "../../../model/merge-rules/MergeRule.model";
import { RootState } from "../../../root-redux/RootState";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { createSelector } from "@reduxjs/toolkit";
import { allTeamsSelector } from "../../../shared-components/app-personas/AppPersona.selectors.redux";
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { allUsersOfMergerulesSelector, allUsersSelector } from "../../../shared-components/user-object/UserObject.selectors.redux";

export interface MergeRuleDataGridRow extends Row {
  cells: {
    field1: string
    field2: string
    field3: string
    field34: string
    // field4: string
    field5: string
    field6: string
    field7: string
    field8: string
    field9: string
    field10: string
  }
}

export const columns: Column[] = [
  {
    field: 'field1',
    content: 'Name',
    sortable: true,
    display: {
      width: "25px",
    },
  },
  {
    field: 'field2',
    content: 'Version',
    sortable: true,
  },
  {
    field: 'field3',
    content: 'Family',
    sortable: true,
  },
  {
    field: 'field34',
    content: 'Insights/Recommendations',
    sortable: true,
    display: {
      width: "10px",
    },
  },
  // {
  //   field: 'field4',
  //   content: 'Models',
  //   sortable: true,
  //   display: {
  //     width: "10px",
  //   },
  // },
  {
    field: 'field5',
    content: 'Tenant',
    sortable: true
  },
  {
    field: 'field6',
    content: 'Modified By',
    sortable: true
  },
  {
    field: 'field7',
    content: 'Modified On',
    sortable: true
  },
  {
    field: 'field8',
    content: 'Tags',
    sortable: true
  },
  {
    field: 'field9',
    content: 'Description',
    sortable: true
  },
];

export const allFamiliesSelector = (state: RootState): string[] => {
  const mergeRuleItems = state.mergeRules.list;
  const allFamilies: string[] = [];

  mergeRuleItems.forEach((item: MergeRule) => {
    if (item.type && !allFamilies.includes(item.type)) {
      allFamilies.push(item.type);
    }
  })
  return allFamilies;
}

export const allFamiliesSubKeySelector = (state: RootState): string[] => {
  const mergeRuleItems = state.mergeRuleBySubKey.list;
  const allFamilies: string[] = [];

  mergeRuleItems.forEach((item: MergeRule) => {
    if (item.type && !allFamilies.includes(item.type)) {
      allFamilies.push(item.type);
    }
  })
  return allFamilies;
}

export const allWorkflowsSelectorSubKey = (state: RootState): string[] => {

  const mergeRuleItems = state.mergeRuleBySubKey.list;
  const workflows = state.workflowsBySubKeys.list;

  const allWorkflows: string[] = [];

  mergeRuleItems.forEach((item: MergeRule) => {
    var sourceFilter = item.sourceFilter
    if (sourceFilter) {
      sourceFilter.map((eachSource) => {
        if (eachSource) {
          var workflow = workflows.find(x => { return x.modelName === eachSource })
          if (workflow && !allWorkflows.includes(workflow.config.workflowName)) {
            allWorkflows.push(workflow.config.workflowName);
          } else if (!workflow && !allWorkflows.includes(eachSource)) {
            allWorkflows.push(eachSource);
          }
        }
      })
    }
  })
  return allWorkflows;

}

export const allWorkflowsSelectorWithoutSubKey = (state: RootState): string[] => {

  const mergeRuleItems = state.mergeRules.list;
  const workflows = state.workflows.list;

  const allWorkflows: string[] = [];

  mergeRuleItems.forEach((item: MergeRule) => {
    var sourceFilter = item.sourceFilter
    if (sourceFilter) {
      sourceFilter.map((eachSource) => {
        if (eachSource) {
          var workflow = workflows.find(x => { return x.modelName === eachSource })
          if (workflow && !allWorkflows.includes(workflow.config.workflowName)) {
            allWorkflows.push(workflow.config.workflowName);
          } else if (!workflow && !allWorkflows.includes(eachSource)) {
            allWorkflows.push(eachSource);
          }
        }
      })
    }
  })
  return allWorkflows;

}

export const allFamilySelector = (state: RootState, subKey: string): string[] => {
  const mergeRuleItems = subKey ? state.mergeRuleBySubKey.list : state.mergeRules.list;
  const allFamilies: string[] = [];

  mergeRuleItems.forEach((item: MergeRule) => {
    if (item.type && !allFamilies.includes(item.type)) {
      allFamilies.push(item.type);
    }
  })
  return allFamilies;
}

export const allWorkflowsSelector = (state: RootState, subKey: string): string[] => {

  const mergeRuleItems = subKey ? state.mergeRuleBySubKey.list : state.mergeRules.list;
  const workflows = subKey ? state.workflowsBySubKeys.list : state.workflows.list;

  const allWorkflows: string[] = [];

  mergeRuleItems.forEach((item: MergeRule) => {
    var sourceFilter = item.sourceFilter
    if (sourceFilter) {
      sourceFilter.map((eachSource) => {
        if (eachSource) {
          var workflow = workflows.find(x => { return x.modelName === eachSource })
          if (workflow && !allWorkflows.includes(workflow.config.workflowName)) {
            allWorkflows.push(workflow.config.workflowName);
          } else if (!workflow && !allWorkflows.includes(eachSource)) {
            allWorkflows.push(eachSource);
          }
        }
      })
    }
  })
  return allWorkflows;

}

export const mergeRuleItemSelector = createSelector(
  (state: RootState) => state.mergeRules.list,
  (state: RootState) => state.userObjectsOfMergeRule.list,
  (state: RootState) => state.env.selectedEnvItem,
  (state: RootState) => state.mergeRules.status,
  (state: RootState) => state.userObjectsOfMergeRule.status,
  (state: RootState) => state.appPersonas.list,
  (state: RootState) => state.workflows.list,
  (mergeRuleItems, userObjects, env, mergeRuleStatus, userObjectsStatus, allAppsList, workflowSummary): { rows: MergeRuleDataGridRow[], loading: boolean } => {
    var transformedToRowItems: MergeRuleDataGridRow[] = []
    // need to create empty rows to show loader in those rows
    const loaderRows = 10 // need to get this value from config

    if (mergeRuleStatus === RequestStatus.loading) {
      for (let i = 0; i < loaderRows; i++) {
        transformedToRowItems.push(
          {
            id: i,
            cells: {
              field1: '',
              field2: '',
              field3: '',
              field34: '',
              // field4: '',
              field5: '',
              field6: '',
              field7: '',
              field8: '',
              field9: '',
              field10: ''
            },
          })
      }
      return { rows: transformedToRowItems, loading: true }
    }

    const usersList = new Map();

    userObjects.map((item) => {
      usersList.set(item['id'], item['displayName']);
    });

    mergeRuleItems.forEach((item: MergeRule) => {
      var modifiedDate = item.modifiedDate ? (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(item.modifiedDate))) : ""
      var models = item.sourceFilter
      var workflowNames: string[] = []
      if (models && models.length > 0) {
        models.forEach(element => {
          var workflow = workflowSummary.find(x => { return x.modelName === element })
          if (workflow) {
            workflowNames.push(workflow.config.workflowName)
          } else {
            workflowNames.push(element)
          }
        });
      }

      transformedToRowItems.push(
        {
          id: item.mergeRuleId,
          cells: {
            field1: item.mergeRuleId,
            field2: item.version,
            field3: item.type,
            field34: workflowNames.join(","),
            // field4: item.sourceFilter?.join(","),
            field5: item.tenantName || allAppsList.find((itemLocal) => itemLocal.subscriptionKey === item.subscriptionKey)?.appName || "",
            field6: usersList.get(item.modifiedBy),
            field7: modifiedDate,
            field8: item.tags,
            field9: item.description,
            field10: item.subscriptionKey
          },
        })
    });
    return { rows: transformedToRowItems, loading: false };
  })

export const mergeRuleItemBySubKeySelector = createSelector(
  (state: RootState) => state.mergeRuleBySubKey.list,
  (state: RootState) => state.userObjectsOfMergeRule.list,
  (state: RootState) => state.env.selectedEnvItem,
  (state: RootState) => state.mergeRuleBySubKey.status,
  (state: RootState) => state.userObjectsOfMergeRule.status,
  (state: RootState) => state.appPersonas.list,
  (state: RootState) => state.workflows.list,
  (mergeRuleItems, userObjects, env, mergeRuleByKeyStatus, userObjectsStatus, allAppsList, workflowSummary): { rows: MergeRuleDataGridRow[], loading: boolean } => {
    
  var transformedToRowItems: MergeRuleDataGridRow[] = []
  // need to create empty rows to show loader in those rows
  const loaderRows = 10 // need to get this value from config
  if (mergeRuleByKeyStatus === RequestStatus.loading) {
    for (let i = 0; i < loaderRows; i++) {
      transformedToRowItems.push(
        {
          id: i,
          cells: {
            field1: '',
            field2: '',
            field3: '',
            field34: '',
            // field4: '',
            field5: '',
            field6: '',
            field7: '',
            field8: '',
            field9: '',
            field10: ''
          },
        })
    }
    return { rows: transformedToRowItems, loading: true }
  }

  const usersList = new Map();

  userObjects.map((item) => {
    usersList.set(item['id'], item['displayName']);
  });

  mergeRuleItems.forEach((item: MergeRule) => {
    var modifiedDate = item.modifiedDate ? (new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(item.modifiedDate))) : ""
    var models = item.sourceFilter
    var workflowNames: string[] = []
    if (models && models.length > 0) {
      models.forEach(element => {
        var workflow = workflowSummary.find(x => { return x.modelName === element })
        if (workflow) {
          workflowNames.push(workflow.config.workflowName)
        } else {
          workflowNames.push(element)
        }
      });
    }

    transformedToRowItems.push(
      {
        id: item.mergeRuleId,
        cells: {
          field1: item.mergeRuleId,
          field2: item.version,
          field3: item.type,
          field34: workflowNames.join(","),
          // field4: item.sourceFilter?.join(","),
          field5: item.tenantName || allAppsList.find((itemLocal) => itemLocal.subscriptionKey === item.subscriptionKey)?.appName || "",
          field6: usersList.get(item.modifiedBy),
          field7: modifiedDate,
          field8: item.tags,
          field9: item.description,
          field10: item.subscriptionKey
        },
      })
  });
  return { rows: transformedToRowItems, loading: false };
})

export const tenantcheckedStateSelector = createSelector(
  (state: RootState) => state.mergeRules.tenantcheckedState,
  allTeamsSelector,
  permissionsSelector,
  (tenantcheckedState, allTenants, permissions) => {
    if (!tenantcheckedState || Object.keys(tenantcheckedState).length === 0) {
      const userTeams = permissions.userTeams.name

      var tenantObj = { [userTeams[0]]: false };
      allTenants.forEach((tenant) => {
        if (userTeams.includes(tenant))
          tenantObj[`${tenant}` as keyof typeof tenantObj] = true;
        else
          tenantObj[`${tenant}` as keyof typeof tenantObj] = false;
      });

      return tenantObj
    }
    return tenantcheckedState
  }
)


export const mergeruleFilteredItemsSelector = createSelector(
  mergeRuleItemSelector,
  allTeamsSelector,
  (state: RootState) => state.userDetails.name,
  permissionsSelector,
  allFamiliesSelector,
  allWorkflowsSelectorWithoutSubKey,
  allUsersOfMergerulesSelector,
  (state: RootState) => state.mergeRules.familycheckedState,
  (state: RootState) => state.mergeRules.workflowcheckedState,
  (state: RootState) => state.mergeRules.tenantcheckedState,
  (state: RootState) => state.mergeRules.usercheckedState,
  (state: RootState) => state.mergeRules.searchText,
  ({ rows, loading }, allTenants, username, permissions, allFamilies, allWorkflows, allUsers,
    familycheckedState, workflowcheckedState, tenantcheckedState, usercheckedState, searchText) => {
    if (loading) {
      return rows;
    }

    const userTeams = permissions.userTeams.name

    var tenantObj = { [userTeams[0]]: false };
    allTenants.forEach((tenant) => {
      if (userTeams.includes(tenant))
        tenantObj[`${tenant}` as keyof typeof tenantObj] = true;
      else
        tenantObj[`${tenant}` as keyof typeof tenantObj] = false;
    });

    var familiesObj = {[allFamilies[0]] : false};
    allFamilies.forEach((family) => {
      if(family.length >0)
        familiesObj[`${family}` as keyof typeof familiesObj] = false;
    });

    var workflowsObj = {[allWorkflows[0]] : false};
    allWorkflows.forEach((workflow) => {
      if(workflow.length >0)
        workflowsObj[`${workflow}` as keyof typeof workflowsObj] = false;
    });

    var usersObj = { [username]: false };
    allUsers.forEach((user) => {
      usersObj[`${user}` as keyof typeof usersObj] = false;
    });

    const appliedFilter = (searchText ? searchText : '').trim()?.toLowerCase();
    const eligibleTenant: string[] = [];
    const eligibleUser: string[] = [];
    const eligibleFamily: string[] = [];
    const eligibleWorkflow: string[] = [];

    allTenants.map((item) => {
      if (tenantcheckedState[item as keyof typeof tenantObj] == true)
        eligibleTenant.push(item)
    });

    allUsers.map((item) => {
      if (usercheckedState[item as keyof typeof usersObj] == true)
        eligibleUser.push(item);
    });

    allFamilies.map((item) => {
      if (familycheckedState[item as keyof typeof familiesObj] == true && !eligibleFamily.includes(item))
        eligibleFamily.push(item);
    });

    allWorkflows.map((item) => {
      if (workflowcheckedState[item as keyof typeof workflowsObj] == true && !eligibleWorkflow.includes(item))
        eligibleWorkflow.push(item);
    });

    const finalRows = [] as MergeRuleDataGridRow[]
    const allids: string[] = [];
    rows.forEach((row) => {
      allids.push(row.id.toString());
    });

    if (appliedFilter) {
      rows.map((row) => {
        if (!row.cells?.field1?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field3?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field34?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field5?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field6?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field8?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field9?.toLowerCase().includes(appliedFilter)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) allids.splice(index, 1);
        }
      })
    };

    if (eligibleTenant.length > 0) {
      rows.map((row) => {
        if (!eligibleTenant.includes(row.cells?.field5)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) allids.splice(index, 1);
        }
      })
    };

    if (eligibleUser.length > 0) {
      rows.map((row) => {
        if (!eligibleUser.includes(row.cells?.field6)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) allids.splice(index, 1);
        }
      })
    };

    if (eligibleWorkflow.length > 0) {
      rows.map((row) => {
        if (eligibleWorkflow.find(a => { return row.cells.field34.includes(a) }) === undefined) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) { allids.splice(index, 1); }
        }
      })
    };

    if (eligibleFamily.length > 0) {
      rows.map((row) => {
        if (!eligibleFamily.includes(row.cells?.field3)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) { allids.splice(index, 1); }
        }
      })
    };

    rows.map((row: any) => {
      if (allids.includes(row.id))
        finalRows.push(row);
    });

    // if (finalRows.length > 0) {
    //   return finalRows
    // };

    return finalRows
  }
)

export const mergeruleSubKeyFilteredItemsSelector = createSelector(
  mergeRuleItemBySubKeySelector,
  allTeamsSelector,
  (state: RootState) => state.userDetails.name,
  permissionsSelector,
  allFamiliesSubKeySelector,
  allWorkflowsSelectorSubKey,
  allUsersOfMergerulesSelector,
  (state: RootState) => state.mergeRules.familycheckedState,
  (state: RootState) => state.mergeRules.workflowcheckedState,
  (state: RootState) => state.mergeRules.tenantcheckedState,
  (state: RootState) => state.mergeRules.usercheckedState,
  (state: RootState) => state.mergeRules.searchText,
  ({ rows, loading }, allTenants, username, permissions, allFamilies, allWorkflows, allUsers,
    familycheckedState, workflowcheckedState, tenantcheckedState, usercheckedState, searchText) => {
    if (loading) {
      return rows;
    }

    const userTeams = permissions.userTeams.name

    var tenantObj = { [userTeams[0]]: false };
    allTenants.forEach((tenant) => {
      if (userTeams.includes(tenant))
        tenantObj[`${tenant}` as keyof typeof tenantObj] = true;
      else
        tenantObj[`${tenant}` as keyof typeof tenantObj] = false;
    });

    var familiesObj = {[allFamilies[0]] : false};
    allFamilies.forEach((family) => {
      if(family.length >0)
        familiesObj[`${family}` as keyof typeof familiesObj] = false;
    });

    var workflowsObj = {[allWorkflows[0]] : false};
    allWorkflows.forEach((workflow) => {
      if(workflow.length >0)
        workflowsObj[`${workflow}` as keyof typeof workflowsObj] = false;
    });

    var usersObj = { [username]: false };
    allUsers.forEach((user) => {
      usersObj[`${user}` as keyof typeof usersObj] = false;
    });

    const appliedFilter = (searchText ? searchText : '').trim()?.toLowerCase();
    const eligibleTenant: string[] = [];
    const eligibleUser: string[] = [];
    const eligibleFamily: string[] = [];
    const eligibleWorkflow: string[] = [];

    allTenants.map((item) => {
      if (tenantcheckedState[item as keyof typeof tenantObj] == true)
        eligibleTenant.push(item)
    });

    allUsers.map((item) => {
      if (usercheckedState[item as keyof typeof usersObj] == true)
        eligibleUser.push(item);
    });

    allFamilies.map((item) => {
      if (familycheckedState[item as keyof typeof familiesObj] == true && !eligibleFamily.includes(item))
        eligibleFamily.push(item);
    });

    allWorkflows.map((item) => {
      if (workflowcheckedState[item as keyof typeof workflowsObj] == true && !eligibleWorkflow.includes(item))
        eligibleWorkflow.push(item);
    });

    const finalRows = [] as MergeRuleDataGridRow[]
    const allids: string[] = [];
    rows.forEach((row) => {
      allids.push(row.id.toString());
    });

    if (appliedFilter) {
      rows.map((row) => {
        if (!row.cells?.field1?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field3?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field34?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field5?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field6?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field8?.toLowerCase().includes(appliedFilter)
          && !row.cells?.field9?.toLowerCase().includes(appliedFilter)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) allids.splice(index, 1);
        }
      })
    };

    if (eligibleTenant.length > 0) {
      rows.map((row) => {
        if (!eligibleTenant.includes(row.cells?.field5)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) allids.splice(index, 1);
        }
      })
    };

    if (eligibleUser.length > 0) {
      rows.map((row) => {
        if (!eligibleUser.includes(row.cells?.field6)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) allids.splice(index, 1);
        }
      })
    };

    if (eligibleWorkflow.length > 0) {
      rows.map((row) => {
        if (eligibleWorkflow.find(a => { return row.cells.field34.includes(a) }) === undefined) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) { allids.splice(index, 1); }
        }
      })
    };

    if (eligibleFamily.length > 0) {
      rows.map((row) => {
        if (!eligibleFamily.includes(row.cells?.field3)) {
          const index = allids.indexOf(row.id.toString());
          if (index !== -1) { allids.splice(index, 1); }
        }
      })
    };

    rows.map((row: any) => {
      if (allids.includes(row.id))
        finalRows.push(row);
    });

    // if (finalRows.length > 0) {
    //   return finalRows
    // };

    return finalRows
  }
)

