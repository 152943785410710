import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";

import { RootState } from "../../../root-redux/RootState";
import { FieldToMerge } from "../../../model/merge-rules/FieldsToMerge.model";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { MergeRuleSet } from "../../../model/merge-rules/MergeRuleSet.model";


export interface MergeConditionListDataGridRow extends Row {
    cells: {
        field1: string,
        field2: string,
        field3: string,
        field4: string,
        field5: string,
        field6: string,
        field7: string
    }
}

export const mergeConditionsColumns = [
    {
        field: 'field1',
        content: 'And',
    },
    {
        field: 'field2',
        content: 'Insight/Recommendation',
    },
    {
        field: 'field3',
        content: 'Field',
    },
    {
        field: 'field4',
        content: 'Operator',
    },
    {
        field: 'field5',
        content: 'Value',
    },
    {
        field: 'field6',
        content: 'Ignore Case',
    },
    {
        field: 'field7',
        content: '',
    },
];