import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "./utils/PostRequest";
import { PreviewWorkflowRequest } from "../model/preview-workflow/PreviewWorkflowRequest.model";
import {
  ADBStatus,
  PreviewOutputType,
  PreviewStatus,
  PreviewWorkflow,
  PreviewWorkflowStepOutput,
  getPreviewDataIngestionStepHash,
  getPreviewProcessingStepHash,
} from "../model/preview-workflow/PreviewWorkflow.model";
import { RootState } from "../root-redux/RootState";
import { WorkflowStageType } from "../model/preview-workflow/WorkflowStageType.model";
import { AxiosResponse } from "axios";
import { PreviewWorkflowResponse } from "../model/preview-workflow/PreviewWorkflowResponse.model";
import { previewWorkflowSlice } from "../feature-components/preview-workflow/preview-workflow-redux/PreviewWorkflow.redux";
import { Workflow } from "../model/workflows/Workflow.model";

export const getPreviewWorkflow = createAsyncThunk<
  { workflowId: string; data: PreviewWorkflow } | undefined,
  { runPrevStage: boolean; clickedNext: boolean; loadPreviewTable: boolean },
  { state: RootState }>("previewWorkflow/get", async ({ runPrevStage, clickedNext, loadPreviewTable }, { getState, dispatch }) => {
  const workflow = getState().workflowById.workflow;
  let transformationStageLoading =
    getState().previewWorkflow.transformationStageLoading &&
    getState().previewWorkflow.transformationStageLoading[workflow.id];
  const stageNnumber = getState().workflowById.currentStage;

  // do not trigger another preview call if there is an existing one which runs all the steps
  if (
    runPrevStage &&
    (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
  ) {
    if (transformationStageLoading) {
      return;
    } else {
      // this is like a lock to prevent multiple calls to the api to run all steps
      dispatch(
        previewWorkflowSlice.actions.updateTransformationStageLoading({
          workflowId: workflow.id,
          loading: true,
        })
      );
      transformationStageLoading = true;
    }
  }

  const originalWorkflow = getState().workflowById.originalWorkflow;
  const userobjId = getState().userDetails.localAccountId;

  var previewDetails = getState().previewWorkflow.map[workflow.id];
  var stepID = getState().workflowById.currentStepId;
  const selectedEnv = getState().env.selectedEnvItem;

  const payload = getPreviewPayload(
    workflow,
    originalWorkflow,
    stageNnumber,
    previewDetails,
    stepID,
    runPrevStage,
    clickedNext,
    loadPreviewTable
  );

  if (!payload) {
    if (
      runPrevStage &&
      (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
    ) {
      if (transformationStageLoading) {
        // remove the lock
        dispatch(
          previewWorkflowSlice.actions.updateTransformationStageLoading({
            workflowId: workflow.id,
            loading: false,
          })
        );
      }
    }
    return;
  }

  let payloadHasSteps =
    (payload.dataIngestion && payload.dataIngestion.length > 0) ||
    (payload.preProcessing && payload.preProcessing.length > 0) ||
    (payload.postProcessing && payload.postProcessing.length > 0);

  if (payloadHasSteps) {
    if (loadPreviewTable) {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForTable({
          dataIngestionStepIds: payload.dataIngestion,
          preProcessingStepIds: payload.preProcessing,
          postProcessingStepIds: payload.postProcessing,
          loading: true,
          error: undefined,
        })
      );
    } else {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForAttributes({
          dataIngestionStepIds: payload.dataIngestion,
          preProcessingStepIds: payload.preProcessing,
          postProcessingStepIds: payload.postProcessing,
          loading: true,
          error: undefined,
        })
      );
    }    

    let response = null;
    try {
      response = await postRequest<PreviewWorkflowResponse, PreviewWorkflowRequest>(
        `${selectedEnv}/workflow/preview`,
        payload,
        userobjId
      );
      // start polling
      if (response.status === 202) {
        // update adbContextId first
        if (!previewDetails) {
          previewDetails = new PreviewWorkflow();
        }
        previewDetails.adbContextId = response?.data?.adbContextId;

        // update redux
        dispatch(
          previewWorkflowSlice.actions.updatePreviewMapFromPolling({
            workflowId: workflow.id,
            data: previewDetails,
          })
        );

        // start polling
        await dispatch(
          getPreviewByPolling({
            stageNnumber,
            runPrevStage,
            clickedNext,
            transformationStageLoading,
            loadPreviewTable,
          })
        );
        return;
      }
    } catch (e: any) {
      if (loadPreviewTable) {
        dispatch(
          previewWorkflowSlice.actions.updateStepLoadingForTable({
            dataIngestionStepIds: payload.dataIngestion,
            preProcessingStepIds: payload.preProcessing,
            postProcessingStepIds: payload.postProcessing,
            loading: false,
            error: (e as Error)?.message + " " + e?.response?.data,
          })
        );
      } else {
        dispatch(
          previewWorkflowSlice.actions.updateStepLoadingForAttributes({
            dataIngestionStepIds: payload.dataIngestion,
            preProcessingStepIds: payload.preProcessing,
            postProcessingStepIds: payload.postProcessing,
            loading: false,
            error: (e as Error)?.message + " " + e?.response?.data,
          })
        );
      }

      if (
        runPrevStage &&
        (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
      ) {
        if (transformationStageLoading) {
          // remove the lock
          dispatch(
            previewWorkflowSlice.actions.updateTransformationStageLoading({
              workflowId: workflow.id,
              loading: false,
            })
          );
        }
      }
      throw e;
    }

    if (!previewDetails) {
      previewDetails = new PreviewWorkflow();
    }

    updatePreviewWorkflowResult(response, previewDetails, payload, dispatch, loadPreviewTable);
    previewDetails.adbContextId = response?.data?.adbContextId;
  }

  if (
    runPrevStage &&
    (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
  ) {
    if (transformationStageLoading) {
      // remove the lock
      dispatch(
        previewWorkflowSlice.actions.updateTransformationStageLoading({
          workflowId: workflow.id,
          loading: false,
        })
      );
    }
  }

  return { workflowId: workflow.id, data: previewDetails };
});

export const getPreviewByPolling = createAsyncThunk<
  { workflowId: string; data: PreviewWorkflow } | undefined,
  {
    stageNnumber: number;
    runPrevStage: boolean;
    clickedNext: boolean;
    transformationStageLoading: boolean;
    loadPreviewTable: boolean;
  },
  { state: RootState }
>(
  "previewWorkflow/poll",
  async (
    { stageNnumber, runPrevStage, clickedNext, transformationStageLoading, loadPreviewTable },
    { getState, dispatch }
  ) => {
    var workflow = getState().workflowById.workflow;
    var originalWorkflow = getState().workflowById.originalWorkflow;
    var previewDetails = getState().previewWorkflow.map[workflow.id];
    var stepID = getState().workflowById.currentStepId;
    const userobjId = getState().userDetails.localAccountId;
    const selectedEnv = getState().env.selectedEnvItem;

    var payload = getPreviewPayload(
      workflow,
      originalWorkflow,
      stageNnumber,
      previewDetails,
      stepID,
      runPrevStage,
      clickedNext,
      loadPreviewTable,
      true
    );

    if (!payload) {
      if (
        runPrevStage &&
        (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
      ) {
        if (transformationStageLoading) {
          // remove the lock
          dispatch(
            previewWorkflowSlice.actions.updateTransformationStageLoading({
              workflowId: workflow.id,
              loading: false,
            })
          );
        }
      }
      return;
    }

    let payloadHasSteps =
      (payload.dataIngestion && payload.dataIngestion.length > 0) ||
      (payload.preProcessing && payload.preProcessing.length > 0) ||
      (payload.postProcessing && payload.postProcessing.length > 0);

    let response = null;
    while (payloadHasSteps) {
      try {
        if (loadPreviewTable) {
          dispatch(
            previewWorkflowSlice.actions.updateStepLoadingForTable({
              dataIngestionStepIds: payload.dataIngestion,
              preProcessingStepIds: payload.preProcessing,
              postProcessingStepIds: payload.postProcessing,
              loading: true,
              error: undefined,
            })
          );
        } else {
          dispatch(
            previewWorkflowSlice.actions.updateStepLoadingForAttributes({
              dataIngestionStepIds: payload.dataIngestion,
              preProcessingStepIds: payload.preProcessing,
              postProcessingStepIds: payload.postProcessing,
              loading: true,
              error: undefined,
            })
          );
        }

        await new Promise((r) => setTimeout(r, 5000));
        response = await postRequest<PreviewWorkflowResponse, PreviewWorkflowRequest>(
          `${selectedEnv}/workflow/preview/poll`,
          payload,
          userobjId
        );

        if (!previewDetails) {
          previewDetails = new PreviewWorkflow();
        }

        updatePreviewWorkflowResult(response, previewDetails, payload, dispatch, loadPreviewTable);
        previewDetails.adbContextId = response?.data?.adbContextId;

        // update redux
        dispatch(
          previewWorkflowSlice.actions.updatePreviewMapFromPolling({
            workflowId: workflow.id,
            data: previewDetails,
          })
        );

        // re init payload
        workflow = getState().workflowById.workflow;
        originalWorkflow = getState().workflowById.originalWorkflow;
        previewDetails = getState().previewWorkflow.map[workflow.id];
        stepID = getState().workflowById.currentStepId;

        payload = getPreviewPayload(
          workflow,
          originalWorkflow,
          stageNnumber,
          previewDetails,
          stepID,
          runPrevStage,
          clickedNext,
          loadPreviewTable,
          true
        );

        if (!payload) {
          if (
            runPrevStage &&
            (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
          ) {
            if (transformationStageLoading) {
              // remove the lock
              dispatch(
                previewWorkflowSlice.actions.updateTransformationStageLoading({
                  workflowId: workflow.id,
                  loading: false,
                })
              );
            }
          }
          return;
        }

        payloadHasSteps =
          (payload.dataIngestion && payload.dataIngestion.length > 0) ||
          (payload.preProcessing && payload.preProcessing.length > 0) ||
          (payload.postProcessing && payload.postProcessing.length > 0);
      } catch (e: any) {
        // todo: handle error only for the ones which are not finished
        if (loadPreviewTable) {
          dispatch(
            previewWorkflowSlice.actions.updateStepLoadingForTable({
              dataIngestionStepIds: payload?.dataIngestion || [],
              preProcessingStepIds: payload?.preProcessing || [],
              postProcessingStepIds: payload?.postProcessing || [],
              loading: false,
              error: (e as Error)?.message + " " + e?.response?.data,
            })
          );
        } else {
          dispatch(
            previewWorkflowSlice.actions.updateStepLoadingForAttributes({
              dataIngestionStepIds: payload?.dataIngestion || [],
              preProcessingStepIds: payload?.preProcessing || [],
              postProcessingStepIds: payload?.postProcessing || [],
              loading: false,
              error: (e as Error)?.message + " " + e?.response?.data,
            })
          );
        }

        if (
          runPrevStage &&
          (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
        ) {
          if (transformationStageLoading) {
            // remove the lock
            dispatch(
              previewWorkflowSlice.actions.updateTransformationStageLoading({
                workflowId: workflow.id,
                loading: false,
              })
            );
          }
        }
        throw e;
      }
    }

    // if (loadPreviewTable) {
    //   dispatch(
    //     previewWorkflowSlice.actions.updateStepLoadingForTable({
    //       dataIngestionStepIds: payload.dataIngestion,
    //       preProcessingStepIds: payload.preProcessing,
    //       postProcessingStepIds: payload.postProcessing,
    //       loading: false,
    //       error: undefined,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     previewWorkflowSlice.actions.updateStepLoadingForAttributes({
    //       dataIngestionStepIds: payload.dataIngestion,
    //       preProcessingStepIds: payload.preProcessing,
    //       postProcessingStepIds: payload.postProcessing,
    //       loading: false,
    //       error: undefined,
    //     })
    //   );
    // }

    if (
      runPrevStage &&
      (stageNnumber === WorkflowStageType.Transformation || stageNnumber === WorkflowStageType.Publish)
    ) {
      if (transformationStageLoading) {
        // remove the lock
        dispatch(
          previewWorkflowSlice.actions.updateTransformationStageLoading({
            workflowId: workflow.id,
            loading: false,
          })
        );
      }
    }

    return { workflowId: workflow.id, data: previewDetails };
  }
);

const getPreviewPayload = (
  workflow: Workflow,
  originalWorkflow: Workflow,
  stageNnumber: number,
  previewDetails: PreviewWorkflow,
  stepID: string,
  runPrevStage: boolean,
  clickedNext: boolean,
  loadPreviewTable: boolean,
  calledFromPollApi: boolean = false
) => {
  let adbContextId = previewDetails?.adbContextId;

  const payload: PreviewWorkflowRequest = {
    subscriptionKey: workflow.subscriptionKey,
    environment: workflow.environment,
    config: workflow.config,
    selfServeVersion: workflow.selfServeVersion,
    adbContextId: adbContextId,
    stageName: stageNnumber,
    // these are needed for V1 workflows
    blobconnection: workflow.blobConnections,
    adlsconnection: workflow.adlsConnections,
    sqlaadConnections: workflow.sqlaadConnections,
    sqlConnections: workflow.sqlConnections,
    eventConnections: workflow.eventConnections,
    cosmosConnections: workflow.cosmosConnections,
    topicConnections: workflow.topicConnections,
    apiConnections: workflow.apiConnections,
  } as unknown as PreviewWorkflowRequest;

  const updatedStepsOutputViewNames: string[] = [];
  switch (stageNnumber) {
    case WorkflowStageType.DataIngestion:
      var dataIngestionStep = workflow.dataIngestion?.find((x) => x.stepID === stepID);
      if (dataIngestionStep) {
        const inputHash = getPreviewDataIngestionStepHash(dataIngestionStep);
        var originalStep = originalWorkflow?.dataIngestion?.find((y) => y.stepID === stepID);
        let originalStepHash = getPreviewDataIngestionStepHash(originalStep);
        const { status, shouldIncludeStep, forceReload } = dataIngestionStepRunDetails(
          previewDetails,
          dataIngestionStep.outputViewName,
          originalStepHash,
          inputHash,
          clickedNext,
          loadPreviewTable,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewDataIngestionStep = {
            ...dataIngestionStep,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable,
          };
          payload.dataIngestion = [previewDataIngestionStep];
        }
      }
      break;
    case WorkflowStageType.Transformation:
      // include all data ingestion steps that had some error in the output or not run yet
      payload.dataIngestion = [];
      workflow.dataIngestion?.forEach((x) => {
        const inputHash = getPreviewDataIngestionStepHash(x);
        var originalStep = originalWorkflow?.dataIngestion?.find((y) => y.stepID === x.stepID);
        let originalHash = getPreviewDataIngestionStepHash(originalStep);
        const { status, shouldIncludeStep, forceReload } = dataIngestionStepRunDetails(
          previewDetails,
          x.outputViewName,
          originalHash,
          inputHash,
          clickedNext,
          false,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewDataIngestionStep = {
            ...x,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable: false,
          };
          payload.dataIngestion.push(previewDataIngestionStep);
          updatedStepsOutputViewNames.push(x.outputViewName);
        }
      });

      // include all preprocessing steps that had some error in the output or not run yet
      payload.preProcessing = [];
      workflow.preProcessing?.forEach((x) => {
        const inputHash = getPreviewProcessingStepHash(x);
        var originalStep = originalWorkflow?.preProcessing?.find((y) => y.stepID === x.stepID);
        let originalHash = getPreviewProcessingStepHash(originalStep);
        // pass the loadPreviewTable flag only for the current step
        let previewTableToBeLoaded = x.stepID === stepID ? loadPreviewTable : false;
        const { status, shouldIncludeStep, forceReload } = preprocessingStepRunDetails(
          previewDetails,
          x.outputViewName,
          x.query,
          updatedStepsOutputViewNames,
          originalHash,
          inputHash,
          clickedNext,
          previewTableToBeLoaded,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewProcessingStep = {
            ...x,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable: previewTableToBeLoaded,
          };
          payload.preProcessing.push(previewProcessingStep);
          updatedStepsOutputViewNames.push(x.outputViewName);
        }
      });

      // include all postprocessing steps that had some error in the output or not run yet
      payload.postProcessing = [];
      workflow.postProcessing?.forEach((x) => {
        const inputHash = getPreviewProcessingStepHash(x);
        var originalStep = originalWorkflow?.postProcessing?.find((y) => y.stepID === x.stepID);
        // pass the loadPreviewTable flag only for the current step
        let previewTableToBeLoaded = x.stepID === stepID ? loadPreviewTable : false;
        let originalHash = getPreviewProcessingStepHash(originalStep);
        const { status, shouldIncludeStep, forceReload } = postprocessingStepRunDetails(
          previewDetails,
          x.outputViewName,
          x.query,
          updatedStepsOutputViewNames,
          originalHash,
          inputHash,
          clickedNext,
          previewTableToBeLoaded,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewProcessingStep = {
            ...x,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable: previewTableToBeLoaded,
          };
          payload.postProcessing.push(previewProcessingStep);
          updatedStepsOutputViewNames.push(x.outputViewName);
        }
      });

      break;

    case WorkflowStageType.Publish:
      let currInputViewName = "";
      if (loadPreviewTable) {
        currInputViewName = workflow.publish?.find((x) => x.stepID === stepID)?.inputViewName || "";
      }
      // include all data ingestion steps that had some error in the output or not run yet
      payload.dataIngestion = [];
      workflow.dataIngestion?.forEach((x) => {
        const inputHash = getPreviewDataIngestionStepHash(x);
        var originalStep = originalWorkflow?.dataIngestion?.find((y) => y.stepID === x.stepID);
        let originalHash = getPreviewDataIngestionStepHash(originalStep);
        let previewTableToBeLoaded =
          currInputViewName && x.outputViewName === currInputViewName ? loadPreviewTable : false;
        const { status, shouldIncludeStep, forceReload } = dataIngestionStepRunDetails(
          previewDetails,
          x.outputViewName,
          originalHash,
          inputHash,
          clickedNext,
          previewTableToBeLoaded,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewDataIngestionStep = {
            ...x,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable: previewTableToBeLoaded,
          };
          payload.dataIngestion.push(previewDataIngestionStep);
          updatedStepsOutputViewNames.push(x.outputViewName);
        }
      });

      // include all preprocessing steps that had some error in the output or not run yet
      payload.preProcessing = [];
      workflow.preProcessing?.forEach((x) => {
        const inputHash = getPreviewProcessingStepHash(x);
        var originalStep = originalWorkflow?.preProcessing?.find((y) => y.stepID === x.stepID);
        let originalHash = getPreviewProcessingStepHash(originalStep);
        let previewTableToBeLoaded =
          currInputViewName && x.outputViewName === currInputViewName ? loadPreviewTable : false;
        const { status, shouldIncludeStep, forceReload } = preprocessingStepRunDetails(
          previewDetails,
          x.outputViewName,
          x.query,
          updatedStepsOutputViewNames,
          originalHash,
          inputHash,
          clickedNext,
          previewTableToBeLoaded,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewProcessingStep = {
            ...x,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable: previewTableToBeLoaded,
          };
          payload.preProcessing.push(previewProcessingStep);
          updatedStepsOutputViewNames.push(x.outputViewName);
        }
      });

      // include all postprocessing steps that had some error in the output or not run yet
      payload.postProcessing = [];
      workflow.postProcessing?.forEach((x) => {
        const inputHash = getPreviewProcessingStepHash(x);
        var originalStep = originalWorkflow?.postProcessing?.find((y) => y.stepID === x.stepID);

        let originalHash = getPreviewProcessingStepHash(originalStep);
        let previewTableToBeLoaded =
          currInputViewName && x.outputViewName === currInputViewName ? loadPreviewTable : false;
        const { status, shouldIncludeStep, forceReload } = postprocessingStepRunDetails(
          previewDetails,
          x.outputViewName,
          x.query,
          updatedStepsOutputViewNames,
          originalHash,
          inputHash,
          clickedNext,
          previewTableToBeLoaded,
          calledFromPollApi
        );
        if (shouldIncludeStep) {
          var previewProcessingStep = {
            ...x,
            status: status,
            forceReload: forceReload,
            inputHash,
            loadPreviewTable: previewTableToBeLoaded,
          };
          payload.postProcessing.push(previewProcessingStep);
          updatedStepsOutputViewNames.push(x.outputViewName);
        }
      });

      payload.stageName = WorkflowStageType.Transformation;
      break;
  }

  return payload;
};

const doesQueryIncludeOutputViewName = (query: string, updatedStepsOutputViewNames: string[]) => {
  if (!query) {
    return false;
  }
  return updatedStepsOutputViewNames.some((x) => query.toUpperCase().includes(x.toUpperCase()));
};

const dataIngestionStepRunDetails = (
  previewDetails: PreviewWorkflow,
  outputViewName: string,
  originalHash: string,
  currentHash: string,
  clickedNext: boolean,
  loadPreviewTable: boolean,
  calledFromPollApi: boolean
) => {
  var status = PreviewStatus.Unsaved;
  var shouldIncludeStep = true;
  var forceReload = true;
  var dataIngestionStageOutput: PreviewWorkflowStepOutput | undefined = undefined;

  if (previewDetails?.dataIngestion && previewDetails.dataIngestion[outputViewName]) {
    dataIngestionStageOutput = previewDetails.dataIngestion[outputViewName];
  }

  if (originalHash && originalHash === currentHash) {
    status = PreviewStatus.Saved;
    if (
      dataIngestionStageOutput &&
      dataIngestionStageOutput.adbCommandId &&
      (dataIngestionStageOutput.adbStatus === ADBStatus.Finished ||
        (calledFromPollApi &&
          (dataIngestionStageOutput.adbStatus === ADBStatus.Queued ||
            dataIngestionStageOutput.adbStatus === ADBStatus.Running)))
    ) {
      shouldIncludeStep = false;
      forceReload = false;

      // preview still has some stale data
      if (dataIngestionStageOutput.previewInputHash !== currentHash) {
        shouldIncludeStep = true;
      }

      // if the preview table is not loaded yet
      if (loadPreviewTable && dataIngestionStageOutput.outputType !== PreviewOutputType.PreviewDataTable) {
        shouldIncludeStep = true;
      }
    } else {
      shouldIncludeStep = true;
      forceReload = false;
    }
  } else {
    status = PreviewStatus.Unsaved;
    shouldIncludeStep = true;
    forceReload = false;

    if (
      dataIngestionStageOutput &&
      dataIngestionStageOutput.adbCommandId &&
      (dataIngestionStageOutput.adbStatus === ADBStatus.Finished ||
        (calledFromPollApi &&
          (dataIngestionStageOutput.adbStatus === ADBStatus.Queued ||
            dataIngestionStageOutput.adbStatus === ADBStatus.Running))) &&
      dataIngestionStageOutput.previewInputHash &&
      dataIngestionStageOutput.previewInputHash === currentHash
    ) {
      shouldIncludeStep = false;

      // if the preview table is not loaded yet
      if (loadPreviewTable && dataIngestionStageOutput.outputType !== PreviewOutputType.PreviewDataTable) {
        shouldIncludeStep = true;
      }
    }

    if (clickedNext) {
      shouldIncludeStep = true;
      status = PreviewStatus.Save;
    }
  }

  return { status, shouldIncludeStep, forceReload };
};

const preprocessingStepRunDetails = (
  previewDetails: PreviewWorkflow,
  outputViewName: string,
  query: string,
  updatedStepsOutputViewNames: string[],
  originalHash: string,
  currentHash: string,
  clickedNext: boolean,
  loadPreviewTable: boolean,
  calledFromPollApi: boolean
) => {
  // PreviewStatsu.Unsaved -> orignal hash != current hash
  // PreviewStatsu.Save -> orignal hash != current hash && clickedNext
  // PreviewStatsu.Saved -> orignal hash === current hash
  // forereload -> orignal hash === current hash && previous steps edited (dependency on updatedOutputViewNames)

  var status = PreviewStatus.Unsaved;
  var shouldIncludeStep = true;
  var forceReload = true;
  var preProcessingStageOutput: PreviewWorkflowStepOutput | undefined = undefined;

  if (previewDetails?.preProcessing && previewDetails.preProcessing[outputViewName]) {
    preProcessingStageOutput = previewDetails.preProcessing[outputViewName];
  }

  if (originalHash && originalHash === currentHash) {
    status = PreviewStatus.Saved;

    if (
      preProcessingStageOutput &&
      preProcessingStageOutput.adbCommandId &&
      (preProcessingStageOutput.adbStatus === ADBStatus.Finished ||
        (calledFromPollApi &&
          (preProcessingStageOutput.adbStatus === ADBStatus.Queued ||
            preProcessingStageOutput.adbStatus === ADBStatus.Running)))
    ) {
      shouldIncludeStep = false;
      forceReload = false;

      // check if the current step refers to any edited data ingestion or previous edited preprocessing steps
      if (
        updatedStepsOutputViewNames.includes(outputViewName) ||
        doesQueryIncludeOutputViewName(query, updatedStepsOutputViewNames)
      ) {
        shouldIncludeStep = true;
        forceReload = true;
      }

      // preview still has some stale data
      if (preProcessingStageOutput.previewInputHash !== currentHash) {
        shouldIncludeStep = true;
      }

      // if the preview table is not loaded yet
      if (loadPreviewTable && preProcessingStageOutput.outputType !== PreviewOutputType.PreviewDataTable) {
        shouldIncludeStep = true;
      }
    } else {
      shouldIncludeStep = true;
      forceReload = false;
    }
  } else {
    status = PreviewStatus.Unsaved;
    shouldIncludeStep = true;
    forceReload = false;

    if (
      preProcessingStageOutput &&
      preProcessingStageOutput.adbCommandId &&
      (preProcessingStageOutput.adbStatus === ADBStatus.Finished ||
        (calledFromPollApi &&
          (preProcessingStageOutput.adbStatus === ADBStatus.Queued ||
            preProcessingStageOutput.adbStatus === ADBStatus.Running))) &&
      preProcessingStageOutput.previewInputHash &&
      preProcessingStageOutput.previewInputHash === currentHash
    ) {
      shouldIncludeStep = false;

      // if the preview table is not loaded yet
      if (loadPreviewTable && preProcessingStageOutput.outputType !== PreviewOutputType.PreviewDataTable) {
        shouldIncludeStep = true;
      }
    }

    if (
      updatedStepsOutputViewNames.includes(outputViewName) ||
      doesQueryIncludeOutputViewName(query, updatedStepsOutputViewNames)
    ) {
      shouldIncludeStep = true;
      forceReload = true;
    }

    if (clickedNext) {
      shouldIncludeStep = true;
      status = PreviewStatus.Save;
    }
  }

  return { status, shouldIncludeStep, forceReload };
};

const postprocessingStepRunDetails = (
  previewDetails: PreviewWorkflow,
  outputViewName: string,
  query: string,
  updatedStepsOutputViewNames: string[],
  originalHash: string,
  currentHash: string,
  clickedNext: boolean,
  loadPreviewTable: boolean,
  calledFromPollApi: boolean
) => {
  var status = PreviewStatus.Unsaved;
  var shouldIncludeStep = true;
  var forceReload = true;
  var postProcessingStageOutput: PreviewWorkflowStepOutput | undefined = undefined;

  if (previewDetails?.postProcessing && previewDetails.postProcessing[outputViewName]) {
    postProcessingStageOutput = previewDetails.postProcessing[outputViewName];
  }

  if (originalHash && originalHash === currentHash) {
    status = PreviewStatus.Saved;

    if (
      postProcessingStageOutput &&
      postProcessingStageOutput.adbCommandId &&
      (postProcessingStageOutput.adbStatus === ADBStatus.Finished ||
        (calledFromPollApi &&
          (postProcessingStageOutput.adbStatus === ADBStatus.Queued ||
            postProcessingStageOutput.adbStatus === ADBStatus.Running)))
    ) {
      shouldIncludeStep = false;
      forceReload = false;

      // check if the current step refers to any edited data ingestion or previous edited preprocessing steps
      if (
        updatedStepsOutputViewNames.includes(outputViewName) ||
        doesQueryIncludeOutputViewName(query, updatedStepsOutputViewNames)
      ) {
        shouldIncludeStep = true;
        forceReload = true;
      }

      // preview still has some stale data
      if (postProcessingStageOutput.previewInputHash !== currentHash) {
        shouldIncludeStep = true;
      }

      // if the preview table is not loaded yet
      if (loadPreviewTable && postProcessingStageOutput.outputType !== PreviewOutputType.PreviewDataTable) {
        shouldIncludeStep = true;
      }
    } else {
      shouldIncludeStep = true;
      forceReload = false;
    }
  } else {
    status = PreviewStatus.Unsaved;
    shouldIncludeStep = true;
    forceReload = false;

    // if the preview for unsaved step already exists but there were edits made
    if (
      postProcessingStageOutput &&
      postProcessingStageOutput.adbCommandId &&
      (postProcessingStageOutput.adbStatus === ADBStatus.Finished ||
        (calledFromPollApi &&
          (postProcessingStageOutput.adbStatus === ADBStatus.Queued ||
            postProcessingStageOutput.adbStatus === ADBStatus.Running))) &&
      postProcessingStageOutput.previewInputHash &&
      postProcessingStageOutput.previewInputHash === currentHash
    ) {
      shouldIncludeStep = false;

      // if the preview table is not loaded yet
      if (loadPreviewTable && postProcessingStageOutput.outputType !== PreviewOutputType.PreviewDataTable) {
        shouldIncludeStep = true;
      }
    }

    // if previous steps were updated
    if (
      updatedStepsOutputViewNames.includes(outputViewName) ||
      doesQueryIncludeOutputViewName(query, updatedStepsOutputViewNames)
    ) {
      shouldIncludeStep = true;
      forceReload = true;
    }

    if (clickedNext) {
      shouldIncludeStep = true;
      status = PreviewStatus.Save;
    }
  }

  return { status, shouldIncludeStep, forceReload };
};

const updatePreviewWorkflowResult = (
  response: AxiosResponse<PreviewWorkflowResponse, any>,
  previewDetails: PreviewWorkflow,
  payload: PreviewWorkflowRequest,
  dispatch: any,
  loadPreviewTable: boolean
) => {
  if (response.data?.dataIngestion?.length > 0) {
    if (!previewDetails.dataIngestion) {
      previewDetails.dataIngestion = {};
    }
    response.data.dataIngestion.forEach((x) => {
      const keys = Object.keys(previewDetails.dataIngestion);
      if (keys && keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          if (previewDetails.dataIngestion[keys[i]] && previewDetails.dataIngestion[keys[i]].stepID === x.stepID) {
            // remove this step and add with a new outputviewname as key
            delete previewDetails.dataIngestion[keys[i]];
            break;
          }
        }
      }
      const step = payload.dataIngestion?.find((y) => y.stepID === x.stepID);
      previewDetails.dataIngestion[x.outputViewName] = createPreviewWorkflowStageOutput(x, step?.inputHash);
    });
    if (loadPreviewTable) {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForTable({
          dataIngestionStepIds: response.data.dataIngestion,
          preProcessingStepIds: [],
          postProcessingStepIds: [],
          loading: false,
          error: undefined,
        })
      );
    } else {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForAttributes({
          dataIngestionStepIds: response.data.dataIngestion,
          preProcessingStepIds: [],
          postProcessingStepIds: [],
          loading: false,
          error: undefined,
        })
      );
    }
  }

  if (response.data?.preProcessing?.length > 0) {
    if (!previewDetails.preProcessing) {
      previewDetails.preProcessing = {};
    }
    response.data.preProcessing.forEach((x) => {
      const keys = Object.keys(previewDetails.preProcessing);
      if (keys && keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          if (previewDetails.preProcessing[keys[i]] && previewDetails.preProcessing[keys[i]].stepID === x.stepID) {
            // remove this step and add with a new outputviewname as key
            delete previewDetails.preProcessing[keys[i]];
            break;
          }
        }
      }
      const step = payload.preProcessing?.find((y) => y.stepID === x.stepID);
      previewDetails.preProcessing[x.outputViewName] = createPreviewWorkflowStageOutput(x, step?.inputHash);
    });
    if (loadPreviewTable) {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForTable({
          dataIngestionStepIds: [],
          preProcessingStepIds: response.data.preProcessing,
          postProcessingStepIds: [],
          loading: false,
          error: undefined,
        })
      );
    } else {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForAttributes({
          dataIngestionStepIds: [],
          preProcessingStepIds: response.data.preProcessing,
          postProcessingStepIds: [],
          loading: false,
          error: undefined,
        })
      );
    }
  }

  if (response.data?.postProcessing?.length > 0) {
    if (!previewDetails.postProcessing) {
      previewDetails.postProcessing = {};
    }
    response.data.postProcessing.forEach((x) => {
      const keys = Object.keys(previewDetails.postProcessing);
      if (keys && keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          if (previewDetails.postProcessing[keys[i]] && previewDetails.postProcessing[keys[i]].stepID === x.stepID) {
            // remove this step and add with a new outputviewname as key
            delete previewDetails.postProcessing[keys[i]];
            break;
          }
        }
      }
      const step = payload.postProcessing?.find((y) => y.stepID === x.stepID);
      previewDetails.postProcessing[x.outputViewName] = createPreviewWorkflowStageOutput(x, step?.inputHash);
    });
    if (loadPreviewTable) {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForTable({
          dataIngestionStepIds: [],
          preProcessingStepIds: [],
          postProcessingStepIds: response.data.postProcessing,
          loading: false,
          error: undefined,
        })
      );
    } else {
      dispatch(
        previewWorkflowSlice.actions.updateStepLoadingForAttributes({
          dataIngestionStepIds: [],
          preProcessingStepIds: [],
          postProcessingStepIds: response.data.postProcessing,
          loading: false,
          error: undefined,
        })
      );
    }
  }
};

const createPreviewWorkflowStageOutput = (x: PreviewWorkflowStepOutput, hash: string | undefined) => {
  const { attributes, output } = parseAttributesAndOutput(x.adbOutput);
  return {
    adbCommandId: x.adbCommandId || "",
    stepID: x.stepID || "",
    adbOutput: output || "",
    adbOutputAttributes: attributes,
    adbStatus: x.adbStatus,
    adbResultType: x.adbResultType,
    status: x.status,
    previewInputHash: hash,
    outputType: x.outputType,
    outputViewName: x.outputViewName,
  } as PreviewWorkflowStepOutput;
};

const parseAttributesAndOutput = (str: string) => {
  let attributes: [string, string][] = [];
  let output = str;

  if (!str || !str.trim()) {
    return { attributes, output };
  }

  let delimiter = "\n";
  let startStr = "start@at@map";
  let endStr = "end@at@map";
  let startIndex = str.indexOf(startStr) + startStr.length;
  let endIndex = str.indexOf(endStr);
  if (startIndex > -1 && endIndex > -1) {
    let extracted = str.slice(startIndex, endIndex)?.trim();

    extracted
      ?.split(delimiter)
      ?.filter((item) => item.trim() !== "")
      ?.forEach((item) => {
        let key = item.split(",")[0]?.trim();
        let value = item.split(",")[1]?.trim();
        attributes.push([key, value]);
      });

    output = str.slice(0, startIndex - startStr.length) + str.slice(endIndex + endStr.length);
  }

  return { attributes, output };
};
