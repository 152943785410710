

export const InformationTextMergeRule: React.FC<any> = (props:any) => {
  
  return (
    <>
    {
        props.type === "Heading" 
        ? 
        <p style = {{fontSize:"14px", textAlign:"center", fontWeight:"350", marginBottom : "8px"}}>{props.text}</p> 
        : 
        <p style = {{fontSize:"14px", fontWeight:"490", marginBottom : "8px"}}>{props.text}</p>
    }
    </>

  );
}