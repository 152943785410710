import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from '../../root-redux/RootState';
import { RequestStatus } from '../../model/ServiceRequestStatus.model';
import { getMergeRuleBySubKey } from '../../services/get-merge-rules/GetMergeRules.api';
import { useDispatch, useSelector } from 'react-redux';
import { CommandBar, DataGrid, Button, Icon, SearchBox, HoverCard } from './MergeRulesScope';
import { MergeRuleDataGridRow, columns, mergeRuleItemBySubKeySelector, mergeruleSubKeyFilteredItemsSelector } from './merge-rules-redux/MergeRulesListColumns.selectors.redux';
import "./merge-rules-styles.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { permissionsSelector } from '../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux';
import { FilterMergeRules } from './filter-merge-rules/FilterMergeRules';
import { SuccessNotification } from '../notifications/SuccessNotification';
import { ErrorNotification } from '../notifications/ErrorNotification';
import { DeleteConfirmation } from './popups/DeleteConfirmationPopup';
import { ClonePopUp } from './popups/ClonePopup'
import  EditMergeRules  from '../edit-merge-rules/EditMergeRules';
import { AppPersona } from '../../model/app-persona/AppPersona.model';
import { PreviewMergeRule } from '../preview-mergerule/PreviewMergeRule';
import { FlyInPanel, Tooltip } from '../../common/HarmonyEnablers';
import { getWorkflows } from '../../services/GetWorkflows.api';
import { CheckPageAccessSelector } from '../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux';
import { defaultPreviewUserInput } from '../../model/preview-mergerule/MergeRulePreviewUserInput.model';
import { updatePreviewMergeRuleUserInput } from '../preview-mergerule/preview-mergerule-redux/PreviewMergeRule.actions.redux';
import { HelpContent } from './help-mergerules/HelpContent';
import { setSearchText } from './merge-rules-redux/MergeRules.redux';
import { CreateNewMergeRulePopup } from './popups/CreateNewMergeRulePopup';


const MergeRulesInWorkflows: React.FC<any> = (props: any) => {

  const dispatchApp = useDispatch<AppDispatch>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { subKey, modelName } = props

  const requestStatus = useSelector((state: RootState) => state.mergeRuleBySubKey.status)
  const workflowRequestStatus = useSelector((state: RootState) => state.workflows.status)
  const [selectedMergeRuleId, setSelectedMergeRuleId] = useState("")
  const [selectedMergeRuleIds, setSelectedMergeRuleIds] = useState([] as string[])
  const workflow = useSelector((state: RootState) => state.workflowById.workflow)
  const selectedEnvItem = useSelector((state: RootState) => state.env.selectedEnvItem);

  const workflowSummary = useSelector((state: RootState) => state.workflowsBySubKeys.list)
  const permissionsList = useSelector(permissionsSelector);
  // component local states
  const filteredRows = useSelector(mergeruleSubKeyFilteredItemsSelector)

  const [selectedMergeRuleTenantName, setSelectedMergeRuleTenantName] = useState('')
  const [mergeRuleSubsKey, setMergeRuleSubsKey] = useState("");
  const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
  const [openHelpPanel, setOpenHelpPanel] = useState(false);

  const rbacProps = useSelector((state: RootState) => ({
    superAdmin: state.isSuperAdmin.isSuperAdmin
  }));

  const [openDeletePopup, setopenDeletePopup] = useState(false);
  const [openClonePopup, setOpenClonePopup] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [filterbar, setFilterbar] = useState(true);
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [openEditMergeRulePage, setOpenEditMergeRulePage] = useState(false)
  const [mergeRuleIdToEdit, setMergeRuleIdToEdit] = useState('')
  const [openPreviewPage, setOpenPreviewPage] = useState(false)
  const access = CheckPageAccessSelector(workflow?.subscriptionKey)
  const searchText = useSelector((state: RootState) => state.mergeRules.searchText);
  const [openCreateNewMergeRule, setopenCreateNewMergeRule] = useState(false)
  
  const handleSearch = (e: any) => {
    dispatch(setSearchText(e.target.value))
  };

  const handleCreateNewMergeRule = () => {
    setopenCreateNewMergeRule(true)
  }
  const handlePreviewForRest = () => {
    setOpenPreviewPage(true)
  }

  const handleDuplicateMergeRule = () => {
    if ((selectedMergeRuleId !== "" && permissionsList.edit.includes(subKey)) ||
      (selectedMergeRuleId !== "" && rbacProps.superAdmin)) {
      setOpenClonePopup(true);
    } else if (selectedMergeRuleId === "") {
      setOpenClonePopup(false);
    } else if (selectedMergeRuleId !== "" && !permissionsList.edit.includes(subKey)) {
      setErrorText(
        "You do not have permission to duplicate this merge rule"
      );
      setShowErrorNotif(true);
    }
  }

  const handleDeleteMergeRule = () => {
    if ((selectedMergeRuleId !== "" && permissionsList.edit.includes(subKey)) ||
      (selectedMergeRuleId !== "" && rbacProps.superAdmin)) {
        setopenDeletePopup(true);
    } else if (selectedMergeRuleId === "") {
      setopenDeletePopup(false);
    } else if (
      selectedMergeRuleId !== "" &&
      !permissionsList.edit.includes(mergeRuleSubsKey)
    ) {
      setErrorText(
        "You do not have permission to delete this merge rule"
      );
      setShowErrorNotif(true);
    }
  }

  const onRowSelect = (e: any) => {
    let selectedRows: string[] = [];
    selectedRows = filteredRows?.filter(x => x.selected)?.map(x => {
      return x.id.toString();
    });
    setSelectedMergeRuleIds(selectedRows);
    if (selectedRows.length === 1) {
      setSelectedMergeRuleId(filteredRows?.filter(x => x.selected)[0].id.toString());
      setSelectedMergeRuleTenantName(filteredRows?.filter(x => x.selected)[0].cells.field5)
    } else {
      setSelectedMergeRuleId('')
      setSelectedMergeRuleTenantName('')
    }
  };

  const handleRequestClose = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenPreviewPage(false);
      dispatch(updatePreviewMergeRuleUserInput(defaultPreviewUserInput))
    }
  }

  const handleRequestCloseEditPopup = (event: any) => {
    if ( event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenEditMergeRulePage(false)
    } else {
      event.preventDefault()
    }
  }

  var msg = `Below are existing MergeRules for the tenant ${allAppsList.find(item => item.subscriptionKey === subKey)?.appName || ""} selected in previous stages of workflow.`
  
  return (
    <>
      {/* {!openEditMergeRulePage && */}
        <>
          <div style={{ marginTop: "1em" }}>
            <Button disabled={!access.canEdit || selectedEnvItem !== "dev"} appearance="command" style={{ fontSize: "14px" }} onClick={handleCreateNewMergeRule}>
              <Icon slot="start" name="add"></Icon>
              Create New
            </Button>
            <Button disabled={!access.canEdit || selectedMergeRuleId === "" || selectedEnvItem !== "dev"} appearance="command" style={{ marginLeft: "40px", fontSize: "14px" }} onClick={handleDuplicateMergeRule}>
              <Icon slot="start" name="copy"></Icon>
              Duplicate
            </Button>
            <Button disabled={!access.canEdit || selectedMergeRuleId === "" || selectedEnvItem !== "dev"} appearance="command" style={{ marginLeft: "40px", fontSize: "14px" }} onClick={handleDeleteMergeRule}>
              <Icon slot="start" name="delete"></Icon>
              Delete
            </Button>
            <Button id='preview-button' disabled={selectedMergeRuleId === ""} appearance='command' style={{ marginLeft: "40px", fontSize: "14px" }} onClick={handlePreviewForRest}>
              <Icon slot="start" name="redeye" />
              Preview
            </Button>
            <Button id="workflowmergeHelp" appearance="command" style={{ marginLeft: "40px", fontSize: "14px" }} onClick={() => {setOpenHelpPanel(!openHelpPanel);}}>
              <Icon slot="start" name="help"></Icon>
              Help
            </Button>
          </div>
          <div className='mergerule-body-overall-default'>
            <div>
              <div style={{ display: "flex" }}>
                {openDeletePopup && (
                  <DeleteConfirmation source={subKey ? "default" : ""} subKey={subKey} modelName={modelName} selectedMergeRuleTenantName={selectedMergeRuleTenantName} setSelectedMergeRuleTenantName={setSelectedMergeRuleTenantName} openDeletePopUp={setopenDeletePopup} setsuccessNotif={setShowSuccessNotif} mergeRuleName={selectedMergeRuleId} tenantName={selectedMergeRuleTenantName} setSuccessText={setSuccessText} seterrornotif={setShowErrorNotif} seterrorText={setErrorText} setSelectedMergeRuleId={setSelectedMergeRuleId} setSelectedMergeRuleIds={setSelectedMergeRuleIds}
                  />
                )}
                {openClonePopup && (
                  <ClonePopUp source={subKey ? "default" : ""} selectedMergeRuleTenantName={setSelectedMergeRuleTenantName} openClonePopUp={setOpenClonePopup} mergeRuleName={selectedMergeRuleId} mergeRuleSubsKey={mergeRuleSubsKey} selectedMergeRuleId={selectedMergeRuleId} setSuccessText={setSuccessText} setsuccessNotif={setShowSuccessNotif} seterrornotif={setShowErrorNotif} seterrorText={setErrorText} setSelectedMergeRuleId={setSelectedMergeRuleId} setSelectedMergeRuleIds={setSelectedMergeRuleIds}
                  />
                )}
                {openHelpPanel &&
                <HelpContent openHelpPanel={openHelpPanel} setOpenHelpPanel={setOpenHelpPanel} />
                }
              </div>
              <div>
                <hr />
                <div>  <SuccessNotification successNotif={showSuccessNotif} setsuccesnotif={setShowSuccessNotif} successText={successText} />  </div>
                <div>  <ErrorNotification errorNotif={showErrorNotif} seterrornotif={setShowErrorNotif} errorText={errorText} />  </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                <SearchBox label='search in mergerule workflow' style={{ width: "300px", marginLeft: "0px" }} onInput={handleSearch} placeholder=""  onHeClear={() => dispatch(setSearchText(""))}
              value={searchText}
                ></SearchBox>
                <Icon id="info5590" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                <Tooltip anchor="info5590" fixedPlacement={true} placement='bottom'>
                  Search by Name or Family or Insights/Recommendations or Modified By or Tags or Description
                </Tooltip>
                <Icon
                  label='search in mergerule workflow'
                  name="filter"
                  style={{ marginLeft: "16px", cursor: "pointer", color: "#0078D4" }}
                  onClick={() => { setFilterbar(!filterbar) }}
                />
                {filterbar && (<FilterMergeRules subKey={subKey} />)}
              </div>
            </div>

            <DataGrid fixedHeading={true}
              loading={requestStatus === RequestStatus.loading}
              rows={filteredRows} columns={columns} select="multiple"
              onHeRowSelectChange={(e) => { onRowSelect(e.detail) }}>
              <span slot="no-records">No MergeRules found.</span>
              {filteredRows.map(row => {
                return (
                  <>
                    <span slot={`field1-${row.id}`} key={`field1-${row.id}`} style={{ color: "#0078D4", cursor: "pointer" }}
                      onClick={() => {
                        if (subKey && !openEditMergeRulePage) {
                          setOpenEditMergeRulePage(true)
                          setMergeRuleIdToEdit(row.id.toString())
                        } else if(!subKey){
                          navigate("/" + NavMenuItemType[NavMenuItemType.MergeRules] + "/edit", { state: { mergeRuleId: row.id } })
                        }
                      }}>
                      {row.cells.field1}
                    </span>
                    <HoverCard slot={`field34-${row.id}`} key={`field34-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                      <div role='button' slot="anchor" key={`field34-${row.id}`}>{row.cells.field34 === null || row.cells.field34 === '' ? '' : row.cells.field34 != null && row.cells.field34.length > 30 ? row.cells.field34.substring(0, 30) + "..." : row.cells.field34}</div>
                      {row.cells.field34 != undefined &&
                        <code style={{ color: "black" }}>{row.cells.field34.split(",").map(eachRow => {
                          return <div>{eachRow}</div>
                        })}
                        </code>
                      }
                      {row.cells.field34 == undefined &&
                        <code style={{ color: "black" }}>
                        </code>
                      }
                    </HoverCard>
                    {/* <HoverCard slot={`field4-${row.id}`} key={`field4-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                      <div slot="anchor" key={`field4-${row.id}`}>{row.cells.field4 === null || row.cells.field4 === '' ? '' : row.cells.field4 != null && row.cells.field4.length > 30 ? row.cells.field4.substring(0, 30) + "..." : row.cells.field4}</div>
                      {row.cells.field4 != undefined &&
                        <code style={{ color: "black" }}>{row.cells.field4.split(",").map(eachRow => {
                          return <div>{eachRow}</div>
                        })}
                        </code>
                      }
                      {row.cells.field4 == undefined &&
                        <code style={{ color: "black" }}>
                        </code>
                      }
                    </HoverCard> */}
                  </>
                )
              })}
            </DataGrid>
            {openPreviewPage &&
              <FlyInPanel style={{ '--size': 'var(--he-panel-size-large)' }}
                open={openPreviewPage}
                onHeRequestClose={handleRequestClose}
                heading={`Preview MergeRule - ${selectedMergeRuleId}`}>
                <PreviewMergeRule localmergeRule={null} mergeRuleName={selectedMergeRuleId} subKey={subKey}
                  workflowId={workflow.id}
                />
              </FlyInPanel>
            }
            {openCreateNewMergeRule && (
                <CreateNewMergeRulePopup setMergeRuleIdToEdit={setMergeRuleIdToEdit} source={"default"} openCreateNewMergeRulePopup={openCreateNewMergeRule}  setOpenCreateNewMergeRule={setopenCreateNewMergeRule}  setOpenEditMergeRulePage={setOpenEditMergeRulePage}  setSuccessText={setSuccessText} setsuccessNotif={setShowSuccessNotif} seterrornotif={setShowErrorNotif} seterrorText={setErrorText}      />
              )}
          </div>
        </>
      {/* } */}
      {openEditMergeRulePage &&
         <FlyInPanel style={{ '--size': 'var(--he-panel-size-extra-large)' }}
         open={openEditMergeRulePage}
         onHeRequestClose={handleRequestCloseEditPopup}
         heading={`${mergeRuleIdToEdit && mergeRuleIdToEdit !== 'undefined' ? `Edit Merge Rule | ${mergeRuleIdToEdit}` : `Create New Merge Rule`}`}>
        <EditMergeRules source={"default"} openEditMergeRule={openEditMergeRulePage} defaultMergeRuleId={mergeRuleIdToEdit} setOpenEditMergeRule={setOpenEditMergeRulePage} />
        </FlyInPanel>
      }
    </>

  );
};

export default MergeRulesInWorkflows;