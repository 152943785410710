import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { Workflow } from '../../../model/workflows/Workflow.model'
import { getWorkflowById } from '../../../services/GetWorkflows.api'
import { WorkflowStageType } from '../../../model/preview-workflow/WorkflowStageType.model'
import { DataIngestion } from '../../../model/workflows/DataIngestion.model'
import { WorkflowStage } from '../../../model/workflows/WorkflowStage.model'
import { v4 as uuidv4 } from 'uuid'
import { PublishModel } from '../../../model/workflows/Publish.model'
import { Delta } from '../../../model/workflows/Delta.model'
import { postWorkflow } from '../../../services/PostWorkflow.api'
import { Processing } from '../../../model/workflows/Processing.model'
import { postScheduleWorkflow } from '../../../services/PostScheduleWorkflow.api'
import { Schedule } from '../../../model/workflows/Schedule.model'
import { getPreviewDataIngestionStepHash, getPreviewProcessingStepHash } from '../../../model/preview-workflow/PreviewWorkflow.model'
import { updateScheduleJobStatus } from '../../../services/UpdateScheduleJobStatus.api'


interface WorkflowByIdState {
    workflow: Workflow,
    originalWorkflow: Workflow,
    currentStage: number,
    currentStepId: string,
    currentStepIndex: number,
    enableSave: boolean,
    unsavedChanges: boolean,
    type: 'new' | 'existing' | 'clone',
    status: RequestStatus,
    error: string | undefined,
    postWorkflowStatus: RequestStatus,
    postWorkflowError: string | undefined,
    isSideNavOpen: boolean,
    defaultWarningMsg: boolean,
    scheduleClusterId: string,
    postScheduleWorkflowStatus: RequestStatus,
    postScheduleWorkflowError: string | undefined
    openAttributesPanel: boolean
    showSchedulePanel: boolean
    runJobType: 'immediate' | 'later'
    publishMapChanged: boolean
}

const initialState: WorkflowByIdState = {
    workflow: {} as Workflow,
    originalWorkflow: {} as Workflow,
    currentStage: 0,
    currentStepId: '',
    currentStepIndex: 0,
    enableSave: false,
    unsavedChanges: false,
    type: 'existing',
    status: RequestStatus.idle,
    error: '',
    postWorkflowStatus: RequestStatus.idle,
    postWorkflowError: '',
    isSideNavOpen: true,
    defaultWarningMsg: false,
    scheduleClusterId: '',
    postScheduleWorkflowStatus: RequestStatus.idle,
    postScheduleWorkflowError: '',
    openAttributesPanel: false,
    showSchedulePanel: false,
    publishMapChanged: false,
    runJobType: 'immediate'
}

export const workflowByIdSlice = createSlice({
    name: 'workflowById',
    initialState,
    reducers: {
        setShowSchedulePanel: (state, action: PayloadAction<boolean>) => {
            state.showSchedulePanel = action.payload
        },
        setOpenAttributesPanel: (state, action: PayloadAction<boolean>) => {
            state.openAttributesPanel = action.payload
        },
        setWorkflowDatasetFileUploadState: (state, action: PayloadAction<any>) => {
            state.workflow.datasetFileUpload = action.payload;
        },
        resetWorkflowStatus: (state) => {
            state.status = RequestStatus.idle
        },
        // reset the workflow state
        setWorkflowName: (state, action: PayloadAction<string>) => {
            state.workflow.config = {
                ...state.workflow.config,
                workflowName: action.payload
            }
        },
        setWorkflowDescription: (state, action: PayloadAction<string>) => {
            state.workflow.description = action.payload;
        },
        updateScheduleClusterId: (state, action: PayloadAction<string>) => {
            state.scheduleClusterId = action.payload
        },
        resetWorkflow: (state) => {
            state.type = 'existing'
            state.currentStepId = ''
            state.currentStepIndex = 0
            state.currentStage = 0
            state.error = undefined
            state.defaultWarningMsg = false
        },
        resetPostWorkflowStatus: (state) => {
            state.postWorkflowStatus = RequestStatus.idle
            state.postWorkflowError = undefined
        },
        resetPostScheduleWorkflowStatus: (state) => {
            state.postScheduleWorkflowStatus = RequestStatus.idle
            state.postScheduleWorkflowError = undefined
            // resetting postworkflow status also as schedule workflow calls post workflow first before calling schedule
            state.postWorkflowStatus = RequestStatus.idle
            state.postWorkflowError = undefined
        },
        updateIsSideNavOpen: (state, action: PayloadAction<boolean>) => {
            state.isSideNavOpen = action.payload
        },
        cloneWorkflow: (state, action: PayloadAction<Workflow>) => {
            state.type = 'clone'
            state.workflow = action.payload
        },
        addNewWorkflow: (state, action: PayloadAction<{workflowId: string, subscriptionKey: string, environment: string, modifiedBy: string, workflowType: string}>) => {
            state.type = 'new'

            const {workflowId, subscriptionKey, environment, modifiedBy, workflowType} = action.payload

            const workflow = new Workflow()
            workflow.config = {workflowName: '', workflowId}
            workflow.version = 1
            workflow.type = "Develop"
            workflow.selfServeVersion = 'v3'
            workflow.partitionKey = workflowId
            workflow.id = uuidv4().toString()
            workflow.environment = environment
            workflow.status = 'New'
            workflow.subscriptionKey = subscriptionKey
            workflow.modifiedBy = modifiedBy
            workflow.dataIngestion = []
            workflow.preProcessing = []
            workflow.postProcessing = []
            workflow.publish = []
            workflow.family = ""
            workflow.workFlowType = workflowType

            state.workflow = workflow
            state.originalWorkflow = {} as Workflow

            // reset the workflow state
            state.currentStepId = ''
            state.currentStepIndex = 0
            state.currentStage = 0
            state.error = undefined
        },
        updateCurrentStage: (state, action: PayloadAction<number>) => {
            state.currentStage = action.payload
        },
        updateCurrentStepId: (state, action: PayloadAction<{stepId: string, stepIndex: number}>) => {
            state.currentStepId = action.payload.stepId
            state.currentStepIndex = action.payload.stepIndex
        },
        updateTypeOfMerge: (state, action: PayloadAction<string>) => {
            state.workflow.typeOfMerge = action.payload
        },
        updateEnableSave: (state, action:PayloadAction<boolean>) => {
            state.enableSave = action.payload

            if (state.originalWorkflow && state.workflow && JSON.stringify(state.originalWorkflow) !== JSON.stringify(state.workflow)) {
                state.unsavedChanges = true
            } else {
                state.unsavedChanges = false
            }
        },
        updateUnsavedChanges: (state, action:PayloadAction<boolean>) => {
            state.unsavedChanges = action.payload
        },
        updateDefaultWarningMsg: (state, action:PayloadAction<boolean>) => {
            state.defaultWarningMsg = action.payload
        },
        deleteDataSet: (state) => {
            let updated = false
            const currentIndex = state.currentStepIndex
            switch(state.currentStage) {
                case WorkflowStageType.DataIngestion:
                    state.workflow.dataIngestion.splice(currentIndex, 1)
                    state.currentStepId = state.workflow.dataIngestion && state.workflow.dataIngestion.length > 0 ? state.workflow.dataIngestion[0].stepID : ''
                    updated = true
                    break
                case WorkflowStageType.Transformation:
                    const postProcessingIndex = currentIndex - state.workflow.preProcessing.length
                    if (state.workflow.preProcessing && currentIndex < state.workflow.preProcessing.length) {
                        state.workflow.preProcessing.splice(currentIndex, 1)
                        updated = true
                    }
                    else if (state.workflow.postProcessing && postProcessingIndex >=0 && postProcessingIndex < state.workflow.postProcessing.length) {
                        state.workflow.postProcessing.splice(postProcessingIndex, 1)
                        updated = true
                    }

                    if (state.workflow.preProcessing && state.workflow.preProcessing.length > 0) {
                        state.currentStepId = state.workflow.preProcessing[0].stepID
                    } else if (state.workflow.postProcessing && state.workflow.postProcessing.length > 0) {
                        state.currentStepId = state.workflow.postProcessing[0].stepID
                    } else {
                        state.currentStepId = ''
                    }
                        
                    break
                case WorkflowStageType.Publish:
                    if(state.workflow.delta.filter(x => x.inputViewName === state.workflow.publish[currentIndex].inputViewName).length > 0) {
                        state.workflow.delta = state.workflow.delta.filter(x => x.inputViewName !== state.workflow.publish[currentIndex].inputViewName)
                    }
                    state.workflow.publish.splice(currentIndex, 1)
                    updated = true
                    state.currentStepId = state.workflow.publish && state.workflow.publish.length > 0 ? state.workflow.publish[0].stepID : ''
                    break
                default:
                    break
            }

            if (updated) {
                state.workflow = {...state.workflow}                
            }
        },
        addDataSet: (state, action: PayloadAction<boolean>) => {
            const newDataSet: WorkflowStage = {
                stepID: uuidv4().toString(),
                type: '',
                inputViewName: '',
                outputViewName: '',
                path: '',
                appModelName: '',
                scalaScript: '',
                query: ''
            }

            let updated = false
            switch(state.currentStage) {
                case WorkflowStageType.DataIngestion:
                    if (!state.workflow.dataIngestion) {
                        state.workflow.dataIngestion = []
                    }
                    // if add duplicate dataset
                    if (action.payload) {
                        state.workflow.dataIngestion.push({...state.workflow.dataIngestion[state.currentStepIndex], stepID: uuidv4().toString(), outputViewName: state.workflow.dataIngestion[state.currentStepIndex].outputViewName+"_copy"})
                    }
                    else {
                        newDataSet.type = 'ADLS'
                        const newIngestionData: DataIngestion = {
                            ...newDataSet,
                            connectionId: ''
                        }
                        state.workflow.dataIngestion.push(newIngestionData)                        
                    }
                    state.currentStepId = state.workflow.dataIngestion[state.workflow.dataIngestion.length - 1].stepID
                    state.currentStepIndex = state.workflow.dataIngestion.length - 1
                    
                    updated = true
                    break
                case WorkflowStageType.Transformation:
                    if (!state.workflow.postProcessing) {
                        state.workflow.postProcessing = []
                    }
                    if (!state.workflow.preProcessing) {
                        state.workflow.preProcessing = []
                    }
                    // if add duplicate dataset
                    if (action.payload) {
                        const postProcessingIndex = state.currentStepIndex - state.workflow.preProcessing.length
                        if (state.workflow.preProcessing && state.currentStepIndex < state.workflow.preProcessing.length) {
                            // pushing it into postprocessing because we in the UI we append postprocessing to end of preprocessing
                            state.workflow.postProcessing.push({...state.workflow.preProcessing[state.currentStepIndex], stepID: uuidv4().toString(), outputViewName: state.workflow.preProcessing[state.currentStepIndex].outputViewName+"_copy"})
                            updated = true
                        }
                        else if (state.workflow.postProcessing && postProcessingIndex >=0 && postProcessingIndex < state.workflow.postProcessing.length) {
                            state.workflow.postProcessing.push({...state.workflow.postProcessing[postProcessingIndex], stepID: uuidv4().toString(), outputViewName: state.workflow.postProcessing[postProcessingIndex].outputViewName+"_copy"})
                            updated = true
                        }
                        if (updated) {
                            state.currentStepId = state.workflow.postProcessing[state.workflow.postProcessing.length - 1].stepID
                            state.currentStepIndex = state.workflow.preProcessing.length + state.workflow.postProcessing.length - 1
                        }                       
                    }
                    else {
                        newDataSet.type = 'SQL'
                        newDataSet.inputViewName = state.workflow?.dataIngestion[0].outputViewName;
                        newDataSet.query = "Select * from " + state.workflow?.dataIngestion[0].outputViewName;
                        const newTransformationData: Processing = {
                            ...newDataSet,
                            jsonQueryString: ''
                        }
                        state.workflow.postProcessing.push(newTransformationData)
                        updated = true
                        state.currentStepId = state.workflow.postProcessing[state.workflow.postProcessing.length - 1].stepID
                        state.currentStepIndex = state.workflow.preProcessing.length + state.workflow.postProcessing.length - 1
                    }
                    break
                case WorkflowStageType.Publish:
                    if (!state.workflow.publish) {
                        state.workflow.publish = []
                    }
                    // if add duplicate dataset
                    if (action.payload) {
                        state.workflow.publish.push({...state.workflow.publish[state.currentStepIndex], stepID: uuidv4().toString()})
                    }
                    else {
                        const newPublishData: PublishModel = {
                            ...newDataSet, 
                            type: 'COSMOS',
                            blobConnectionId: '',
                            connectionId: '',
                            publishMap: [],
                            primaryKeys: '',
                            publishTo: state.workflow.workFlowType === 'Dataset' ? 'cosmos' : 'nebula',
                            delta: state.workflow.workFlowType === 'Dataset' ? true : false,
                            tableName: '',
                            modelId: state.workflow.config.workflowId.toUpperCase()
                        }
                        state.workflow.publish.push(newPublishData)
                    }                    
                    updated = true
                    state.currentStepId = state.workflow.publish[state.workflow.publish.length - 1].stepID
                    state.currentStepIndex = state.workflow.publish.length - 1
                    break    
                default:
                    break            
            }

            if (updated) {
                state.workflow = {...state.workflow}
            }
        },
        moveDataSet: (state, action: PayloadAction<boolean>) => {
            let updated = false
            const currentIndex = state.currentStepIndex
            const moveDown = action.payload
            const newIndex = moveDown ? currentIndex + 1 : currentIndex - 1
            switch(state.currentStage) {
                case WorkflowStageType.DataIngestion:
                    if (newIndex >= 0 && newIndex < state.workflow.dataIngestion.length) {
                        const temp = state.workflow.dataIngestion[currentIndex]
                        state.workflow.dataIngestion[currentIndex] = state.workflow.dataIngestion[newIndex]
                        state.workflow.dataIngestion[newIndex] = temp
                        state.currentStepIndex = newIndex
                        updated = true
                    }
                    break
                case WorkflowStageType.Transformation:
                    const postProcessingCurrentIndex = currentIndex - state.workflow.preProcessing.length
                    const postProcessingNewIndex = newIndex - state.workflow.preProcessing.length

                    // if current index is in pre-processing
                    if (state.workflow.preProcessing && currentIndex < state.workflow.preProcessing.length) {
                        // if new index is alos in pre-processing
                        if (newIndex >= 0 && newIndex < state.workflow.preProcessing.length) {
                            const temp = state.workflow.preProcessing[currentIndex]
                            state.workflow.preProcessing[currentIndex] = state.workflow.preProcessing[newIndex]
                            state.workflow.preProcessing[newIndex] = temp
                            updated = true
                            state.currentStepIndex = newIndex
                        }
                        // if new index is in post-processing
                        else if (postProcessingNewIndex >= 0 && postProcessingNewIndex < state.workflow.postProcessing.length) {
                            const temp = state.workflow.preProcessing[currentIndex]
                            state.workflow.preProcessing[currentIndex] = state.workflow.postProcessing[postProcessingNewIndex]
                            state.workflow.postProcessing[postProcessingNewIndex] = temp
                            updated = true
                            state.currentStepIndex = newIndex
                        }
                    } 
                    // if current index is in post-processing
                    else if (state.workflow.postProcessing && postProcessingCurrentIndex >=0 && postProcessingCurrentIndex < state.workflow.postProcessing.length) {
                        // if new index is in pre-processing
                        if (newIndex >= 0 && newIndex < state.workflow.preProcessing.length) {
                            const temp = state.workflow.postProcessing[postProcessingCurrentIndex]
                            state.workflow.postProcessing[postProcessingCurrentIndex] = state.workflow.preProcessing[newIndex]
                            state.workflow.preProcessing[newIndex] = temp
                            updated = true
                            state.currentStepIndex = newIndex
                        }
                        // if new index is in post-processing
                        else if (postProcessingNewIndex >= 0 && postProcessingNewIndex < state.workflow.postProcessing.length) {
                            const temp = state.workflow.postProcessing[postProcessingCurrentIndex]
                            state.workflow.postProcessing[postProcessingCurrentIndex] = state.workflow.postProcessing[postProcessingNewIndex]
                            state.workflow.postProcessing[postProcessingNewIndex] = temp
                            updated = true
                            state.currentStepIndex = newIndex
                        }
                    }
                    break
                case WorkflowStageType.Publish:
                    if (newIndex >= 0 && newIndex < state.workflow.publish.length) {
                        const temp = state.workflow.publish[currentIndex]
                        state.workflow.publish[currentIndex] = state.workflow.publish[newIndex]
                        state.workflow.publish[newIndex] = temp
                        updated = true
                        state.currentStepIndex = newIndex
                    }
                    break
                default:
                    break
            }

            if (updated) {
                state.workflow = {...state.workflow}
            }
        },
        saveWorkflow: (state, action: PayloadAction<Workflow>) => {
            state.workflow = action.payload;            
        },
        updateRunJobType: (state, action: PayloadAction<'immediate' | 'later'>) => {
            state.runJobType = action.payload
        },
        updateSchedule: (state, action: PayloadAction<Schedule>) => {
            if (!state.workflow.schedule) {
                state.workflow.schedule = new Schedule()
            }

            if (action.payload && action.payload.startFrom === '*') {
                state.workflow.schedule = action.payload
            }           
            
            if (action.payload && action.payload.startFrom === '00' 
            // first time creating schedule
            && (!state.originalWorkflow.schedule || !state.originalWorkflow.schedule.isScheduleCreated)) {
                state.workflow.schedule = action.payload                
                state.workflow.schedule.frequency = "Every"
                state.workflow.schedule.startFrom = "00"                                
            }            
            
            state.workflow.schedule.pause_Status = action.payload.pause_Status
        },
        updateBasics: (state, action: PayloadAction<Workflow>) => {
            state.workflow = action.payload
        },
        updateIngestion: (state, action: PayloadAction<DataIngestion>) => {
            
            const currIndex = state.currentStepIndex
            if (currIndex >= 0 && currIndex < state.workflow.dataIngestion.length) {
                state.workflow.dataIngestion[currIndex] = action.payload
                state.workflow = {...state.workflow}
            }
        },
        updateTransformation: (state, action: PayloadAction<Processing>) => {
 
            const currIndex = state.currentStepIndex
            const postProcessingCurrentIndex = currIndex - state.workflow?.preProcessing?.length
            if (currIndex >= 0 && currIndex < state.workflow?.preProcessing?.length) {
                state.workflow.preProcessing[currIndex] = action.payload
                state.workflow = {...state.workflow}
            }
            else if(postProcessingCurrentIndex >= 0 && postProcessingCurrentIndex < state.workflow?.postProcessing?.length) {
                state.workflow.postProcessing[postProcessingCurrentIndex] = action.payload
                state.workflow = {...state.workflow}
            }
        },
        // updateDefaultMergeRule: (state, action: PayloadAction<string>) => {
        //     state.workflow.defaultMergeRule = action.payload
        // },
        updateDelta: (state, action: PayloadAction<Delta>) => {
            const deltaItem = action.payload;
            let deltaIndex = -1
            const existingDelta = state.workflow.delta?.find((item, index) => {
                if (item.stepID === deltaItem.stepID){
                    deltaIndex = index;
                    return true;
                }
                return false;
            });
            if(existingDelta && deltaIndex >= 0){
                state.workflow.delta[deltaIndex] = deltaItem;
                state.workflow = {...state.workflow};
            }
            else{
                if (!state.workflow.delta) {
                    state.workflow.delta = [];
                }
                if (deltaItem && deltaItem.stepID) {
                    state.workflow.delta.push(deltaItem);
                }
                
            }
        },
        updatePublish: (state, action: PayloadAction<PublishModel>) => {
            // const actionPublishAPI = state.workflow.publish.filter(item => item.inputViewName === "vw_actionpublish");
            // if(state.workflow.isActionPublish && actionPublishAPI.length === 0){
            //     let newPublishStep = {} as PublishModel;
            //     newPublishStep = { 
            //         stepID: uuidv4().toString(), 
            //         type: "API",
            //         inputViewName: "vw_actionpublish",
            //         blobConnectionId: '',
            //         connectionId: '',
            //         publishMap: [],
            //         primaryKeys: '',
            //         publishTo: 'custom',
            //         delta: false,
            //         tableName: '',
            //         modelId: state.workflow.config.workflowId,
            //         outputViewName: '',
            //         path: '',
            //         appModelName: '',
            //         scalaScript: '',
            //         query: ''
            //     }
            //     state.workflow.publish.push(newPublishStep)
            // }
            // else if(!state.workflow.isActionPublish && actionPublishAPI.length > 0){
            //     const currIndex = state.workflow.publish.indexOf(actionPublishAPI[0]);
            //     state.workflow.publish.splice(currIndex, 1)
            // }

            const publishItem = action.payload

            if(!publishItem.delta){
                let deltaIndex = -1
                const deltaItem = state.workflow.delta?.find((item, index) => {
                    if (item.inputViewName === publishItem.inputViewName) {
                        deltaIndex = index;
                        return true                    
                    }
                    return false
                    
                });
                if(deltaItem && deltaIndex >= 0){
                    state.workflow.delta.splice(deltaIndex, 1);
                    state.workflow = {...state.workflow}
                }
            }
            const currIndex = state.currentStepIndex
            
            if (currIndex >=0 && currIndex < state.workflow.publish?.length){
                state.workflow.publish[currIndex] = action.payload
                state.workflow = {...state.workflow}
            }
        },
        updatePublishMapChanged: (state, action:PayloadAction<boolean>) => {
            state.publishMapChanged = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getWorkflowById.pending, (state, _) => {
                state.status = RequestStatus.loading
                // reset the workflow state
                state.scheduleClusterId = ''
                state.currentStepId = ''
                state.currentStepIndex = 0
                state.currentStage = 0
                state.error = undefined
                state.type = 'existing'
            })
            .addCase(getWorkflowById.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                state.workflow = action.payload
                state.originalWorkflow = action.payload
                state.error = undefined
                state.type = 'existing'
            })
            .addCase(getWorkflowById.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
                state.type = 'existing'
            })
            .addCase(postWorkflow.pending, (state, _) => {
                state.postWorkflowStatus = RequestStatus.loading
                state.postWorkflowError = undefined
            })
            .addCase(postWorkflow.fulfilled, (state, _) => {
                state.postWorkflowStatus = RequestStatus.succeeded
                state.originalWorkflow = state.workflow
                state.postWorkflowError = undefined

                // workflow is no longer new or cloned
                if (state.type !== 'existing') {
                    state.type = 'existing'
                }
                if (state.unsavedChanges) {
                    state.unsavedChanges = false
                }
            })
            .addCase(postWorkflow.rejected, (state, action) => {
                state.postWorkflowStatus = RequestStatus.failed
                state.postWorkflowError = action.error.message
            })
            .addCase(postScheduleWorkflow.pending, (state, action) => {
                state.postScheduleWorkflowStatus = RequestStatus.loading
                state.postScheduleWorkflowError = undefined
            })
            .addCase(postScheduleWorkflow.fulfilled, (state, action) => {
                if (action.payload) {
                    state.postScheduleWorkflowStatus = action.payload.success ? RequestStatus.succeeded : RequestStatus.failed
                    state.postScheduleWorkflowError = action.payload.error
                } else {
                    state.postScheduleWorkflowStatus = RequestStatus.idle
                    state.postScheduleWorkflowError = undefined                
                }
                
            })
            .addCase(postScheduleWorkflow.rejected, (state, action) => {
                state.postScheduleWorkflowStatus = RequestStatus.failed
                state.postScheduleWorkflowError = action.error.message
            })
            .addCase(updateScheduleJobStatus.pending, (state, action) => {
                state.postScheduleWorkflowStatus = RequestStatus.loading
                state.postScheduleWorkflowError = undefined
            })
            .addCase(updateScheduleJobStatus.fulfilled, (state, action) => {
                if (action.payload) {
                    state.postScheduleWorkflowStatus = action.payload.success ? RequestStatus.succeeded : RequestStatus.failed
                    state.postScheduleWorkflowError = action.payload.error
                } else {
                    state.postScheduleWorkflowStatus = RequestStatus.idle
                    state.postScheduleWorkflowError = undefined                
                }
                
            })
            .addCase(updateScheduleJobStatus.rejected, (state, action) => {
                state.postScheduleWorkflowStatus = RequestStatus.failed
                state.postScheduleWorkflowError = action.error.message
            })
    }
})

export const { 
    setWorkflowDatasetFileUploadState, saveWorkflow, setWorkflowName, setWorkflowDescription } = workflowByIdSlice.actions;
export const workflowByIdReducer = workflowByIdSlice.reducer