import React, { useState, useCallback, useEffect } from 'react';

import type { Config, ImmutableTree, Fields, JsonTree } from '@react-awesome-query-builder/ui';
import { Utils as QbUtils, Query, Builder, BuilderProps, BasicConfig } from '@react-awesome-query-builder/ui';

import { Helper } from '../../utils/Help';
import { CartFilterSelector } from '../../redux/CampaignCartList.Selector.redux';
import { useSelector } from 'react-redux';

const InitialConfig = BasicConfig;

let config: Config = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    showNot: false,
    forceShowConj: true,
  }
};

const FilterQuery: React.FC<{
  attributesList: { [key: string]: string },
  inputView: string,
  currentJsonTree: string,
  currentQuery: string,
  selectStatement: string,
  setSelectStatement: (inputView: string) => void,
  state: any,
  setState: (treeConfig: any) => void
}> = ({ attributesList, inputView, currentJsonTree, currentQuery, selectStatement, setSelectStatement, state, setState }) => {

  const [newconfig, setNewConfig] = useState({ ...config });

  const taxonomy = useSelector(CartFilterSelector);

  const typeMapping: { [key: string]: string } = {
    'String': 'text',
    'Int32': 'number',
    'Int64': 'number',
    'Date': 'datetime',
    'BooleanType': 'boolean',
    'LongType': 'number'
  }

  const parseJsonTree = (jsonTree: string) => {
    const parsedTree: JsonTree = jsonTree ? JSON.parse(jsonTree) : '';
    return parsedTree;
  }

  useEffect(() => {
    const newFields: Fields = {};
    for (const [key, value] of Object.entries(attributesList)) {
      const objKey = Object.keys(taxonomy.details).find(k => k.toLowerCase() === key.toLowerCase());
      if (objKey) {
        newFields[key] = {
          label: key,
          type: 'select',
          valueSources: ['value'],
          fieldSettings: {
            listValues: Array.from(taxonomy.details[objKey] || []).map(v => ({value: v, title: v})).filter(a => a != null && a.title != null).sort((a, b) => a.title.localeCompare(b.title))
          },
          excludeOperators: ['proximity']
        }
      } else {
        newFields[key] = {
          label: key,
          type: typeMapping[value],
          excludeOperators: ['proximity']
        }
      }
    };
    setNewConfig({ ...newconfig, fields: newFields });
  }, [attributesList, taxonomy]);


  useEffect(() => {
    const jsonTree: JsonTree = parseJsonTree(currentJsonTree);
    setState({
      tree: QbUtils.checkTree(QbUtils.loadTree(!Helper.isEmpty(jsonTree) ? jsonTree : { id: QbUtils.uuid(), type: 'group' }), newconfig),
      config: newconfig
    })
  }, [currentJsonTree, newconfig])


  useEffect(() => {
    setSelectStatement('Select * from ' + inputView);
  }, [inputView]);


  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState((prevState: any) => ({ ...prevState, tree: immutableTree, config: config }));
    const jsonTree = QbUtils.getTree(immutableTree);
  }, []);

  
  const renderBuilder = useCallback((props: BuilderProps) => (
    <div className='query-builder-container' style={{ padding: '10px' }}>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  ), []);

  return (
    <div>
      <Query
        {...newconfig}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />

      <br />

      {JSON.stringify(QbUtils.sqlFormat(state.tree, state.config)) ?  
      
      <div style={{ display: 'flex', width: '100%' }}>
        <div className='query-builder-result' style={{ flex: '1', width: '90px' }}>
          <div className='spa-queryview'>
            <b style={{marginBottom: "10px"}}>Query:</b> <br />
            {selectStatement}
            <p style={{ overflowWrap: 'break-word' }}>
              {JSON.stringify(QbUtils.sqlFormat(state.tree, state.config)) ? ' where ' + QbUtils.sqlFormat(state.tree, state.config) : '' /* TODO asknebula */}
            </p>
          </div>
        </div>
      </div> :
        <></>
      }
    </div>
  );
};
export default FilterQuery;