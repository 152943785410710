import axios from 'axios';
import { BASE_URL } from './Constants';
import { v4 as uuidv4 } from 'uuid';
import { AuthClientProvider } from '../../auth/AuthClientProvider';
import { Logger } from '../../utils/Logger';

export const postRequestReleaseManagement = async<T, D>(apiPath: string, payload: D, userObjID?: string, nobase?: boolean) => {
  const correlationId = uuidv4();
  const environment = apiPath.includes("prod/") ? "prod" : apiPath.includes("uat/") ? "uat" : "dev";

  Logger.Instance.trackTrace(`start postRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId,
    reqPayload:payload
  });
  try {
    const url = nobase ? apiPath : `${BASE_URL}/${apiPath}`;
    const response = await axios.post<T>(url, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken_releaseManagement(environment),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
        "user-oid": userObjID
      },
    }
    );
    Logger.Instance.trackTrace(`completed PostRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      response:response
    });

    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PostRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });   
    throw error;
  }
};

export const patchRequestReleaseManagement = async<T, D>(apiPath: string, payload: D) => {
  const correlationId = uuidv4();
  const environment = apiPath.includes("prod/") ? "prod" : apiPath.includes("uat/") ? "uat" : "dev";
  Logger.Instance.trackTrace(`start patchRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId,
    reqPayload:payload
  });
  try {
    const response = await axios.patch<T>(`${BASE_URL}/${apiPath}`, payload, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken_releaseManagement(environment),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId
      },
    });
    Logger.Instance.trackTrace(`completed PatchRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId,
      response:response
    });

    return response;
  } catch (error) {
    Logger.Instance.trackException({error: error as Error}, {
      event: `error PatchRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
};
