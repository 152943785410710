import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "../workflows/WorkflowsScope"
import { NavMenuItemType } from "../../model/NavMenu.model";
import { useDispatch } from "react-redux";
import { resetMergeRule, resetMergeRuleSet } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const EditMergeRuleBreadcrumbC: React.FC<{mergeRuleName: string}> = ({mergeRuleName}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    const onBreadcrumbItemClick = (navMenuItemType: NavMenuItemType) => {
        dispatch(resetMergeRule())
        dispatch(resetMergeRuleSet())
        navigate("/"+ NavMenuItemType[navMenuItemType]);
    };
    
    return (
        <Breadcrumb>
            <BreadcrumbItem
                style={{ color: "#0078D4", fontWeight: "400" }}
                onClick={() => onBreadcrumbItemClick(NavMenuItemType.Home)}
            >
                Home
            </BreadcrumbItem>
            <BreadcrumbItem
                style={{ color: "#0078D4", fontWeight: "400" }}
                onClick={() => onBreadcrumbItemClick(NavMenuItemType.MergeRules)}
            >
                Merge Rules
            </BreadcrumbItem>
            <BreadcrumbItem
                style={{ color: "#0078D4", fontWeight: "400" }}
            >
                {mergeRuleName ? `Update ${mergeRuleName}` : 'Create new'}
            </BreadcrumbItem>
        </Breadcrumb>
    )
}
export const EditMergeRuleBreadcrumb = withErrorBoundary("Edit merge rule breadcrumb", EditMergeRuleBreadcrumbC);