import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TenantStats } from '../../../model/tenant-stats/TenantStats.model'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { getTenantStats } from '../../../services/GetTenantStats.api'

interface TenantStatsState {
    list: TenantStats[],
    status: RequestStatus,
    error: string | undefined
}

const initialState: TenantStatsState = {
  list: [],
  status: RequestStatus.idle,
  error: ''
}

const tenantStatsSlice = createSlice({
  name: 'tenantStats',
  initialState,
  reducers: {
    addTenantStats: (state, action) => {
        state.list.push(action.payload)
      }
  },
  extraReducers(builder) {
    builder
      .addCase(getTenantStats.pending, (state, _) => {
        state.status = RequestStatus.loading
      })
      .addCase(getTenantStats.fulfilled, (state, action: PayloadAction<TenantStats[]> ) => {
        state.status = RequestStatus.succeeded
        state.list = action.payload
        state.error = undefined
      })
      .addCase(getTenantStats.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
  }
})

export const tenantStatsReducer = tenantStatsSlice.reducer