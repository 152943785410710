import React, { useState, useCallback, useEffect } from "react";

import type { JsonGroup, Config, ImmutableTree, BuilderProps, Fields, JsonTree, ListValues, ListItem, Operator } from '@react-awesome-query-builder/ui';
import { Utils as QbUtils, Query, Builder, BasicConfig } from '@react-awesome-query-builder/ui';
import '../common/querybuilder-styles.css'

import { Button, Icon, Radio, RadioGroup, TextArea } from "../../common/HarmonyEnablers";
import { useSelector } from "react-redux";
import { RootState } from "../../root-redux/RootState";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
const InitialConfig = BasicConfig;

let config: Config = {
  ...InitialConfig,
  operators: {
    ...InitialConfig.operators,
    equal_NoCase: {
      ...InitialConfig.operators["equal"],
      label: "==IgnoreCase",
      reversedOp: "not_equal_NoCase",
      sqlOp: "=NoCase"
    },
    not_equal_NoCase: {
      ...InitialConfig.operators["not_equal"],
      label: "!=IgnoreCase",
      reversedOp: "equal_NoCase",
      sqlOp: "<>NoCase"
    }
  },
  types: {
    ...InitialConfig.types,
    select: {
      ...InitialConfig.types["select"],
      widgets: {
        multiselect: {
          operators: []
        },
        select: {
          operators: ["select_equals", "select_not_equals"]
        }
      }
    },
    text: {
      ...InitialConfig.types["text"],
      widgets: {
        ...InitialConfig.types["text"].widgets,
        text: {
          operators: ["equal", "not_equal", "equal_NoCase", "not_equal_NoCase", "is_empty", "is_not_empty"]
        }
      }
    }
  },
  fields: {
  }
};

const FilterQueryBuilder: React.FC<any> = (props: any) => {

  const {subKey, disableSave, showSave, setOpenFilter, modelList, fieldsList, currentJsonTree, inputView, onQueryReturn,size, commandError,setcommandError} = props;
  const [newconfig, setNewConfig] = useState({ ...config });
  const [limiter, setLimiter] = useState<string>("");
  const currentMergeRuleList = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet)
  const access = CheckPageAccessSelector(subKey)
  

  const typeMapping: { [key: string]: string } = {
    "String": "text",
    "Date": "date",
    "Int32": "number",
    "Int64": "number",
    "Boolean": "boolean",
    "Double": "number",
    "Decimal": "number",
    "Guid": "text"
  }

  const operator: { [key: string]: string[] } = {
    "String": ["equal", "not_equal", "is_empty", "is_not_empty", "equal_NoCase", "not_equal_NoCase"],
    "Date": ["equal", "not_equal", "greater", "greater_or_equal", "less", "less_or_equal"],
    "Int64": ["equal", "not_equal", "greater", "greater_or_equal", "less", "less_or_equal"],
    "Int32": ["equal", "not_equal", "greater", "greater_or_equal", "less", "less_or_equal"],
    "Boolean": ["equal", "not_equal"],
    "Double": ["equal", "not_equal", "greater", "greater_or_equal", "less", "less_or_equal"],
    "Decimal": ["equal", "not_equal", "greater", "greater_or_equal", "less", "less_or_equal"],
    "Guid": ["equal", "not_equal", "is_empty", "is_not_empty", "equal_NoCase", "not_equal_NoCase"],
  }


  const parseJsonTree = (jsonTree: string) => {
    const parsedTree: JsonTree = jsonTree ? JSON.parse(jsonTree) : "";
    return parsedTree;
  }

  useEffect(() => {
    const newFields: Fields = { ...config.fields }
    var values: Array<ListItem> = []
    modelList.forEach((element: any[]) => {
      var eachValue = {} as ListItem
      eachValue = { ...eachValue, value: element[0], title: element[0] }
      values = [...values, eachValue]
    });

    newFields["SourceId"] = {
      label: 'InsightName/RecommendationName',
      valueSources: ['value'],
      type: 'select',
      fieldSettings: { listValues: values, showSearch: true }
    }

    fieldsList.forEach((element: any[]) => {
      if (!fieldsList.includes("SourceId")) {
        newFields[element[0]] = {
          label: element[0],
          valueSources: ['value'],
          operators: operator[element[1]],
          type: typeMapping[element[1]], ///MRTODO check
        }
      }
    });
    setNewConfig({ ...newconfig, fields: newFields });

  }, [fieldsList, modelList])

  useEffect(() => {
    const jsonTree: JsonTree = parseJsonTree(currentJsonTree);

    setState({
      tree: QbUtils.checkTree(QbUtils.loadTree(Object.keys(jsonTree).length !== 0 ? jsonTree : { id: QbUtils.uuid(), type: "group" }), newconfig),
      config: newconfig
    })
  }, [newconfig])

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(Object.keys(parseJsonTree(currentJsonTree)).length !== 0 ? parseJsonTree(currentJsonTree) : { id: QbUtils.uuid(), type: "group" }), newconfig),
    config: newconfig
  });

  const [disableSavebutton, setDisableSaveButton] = useState(false)
  useEffect(() => {
    setDisableSaveButton(
      currentJsonTree && 
      state.tree && 
      currentJsonTree === JSON.stringify(QbUtils.getTree(state.tree) as JsonTree))
  }, [state, currentJsonTree])

  useEffect(() => {
    disableSave(disableSavebutton)
  },[disableSavebutton])

  const updateQuery = () => {
    onQueryReturn(QbUtils.sqlFormat(state.tree, state.config)?.replace("\"", "") || "", JSON.stringify(QbUtils.getTree(state.tree) as JsonTree));
  }

  const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
    setcommandError("")
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setState(prevState => ({ ...prevState, tree: immutableTree, config: config }));

    const jsonTree = QbUtils.getTree(immutableTree);
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }, []);

  const renderBuilder = useCallback((props: BuilderProps) => (
    <div className="query-builder-container" style={{ padding: "10px" }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  ), []);

  var msg = currentMergeRuleList.type === "primary" ? "Recommendation" : "Insight/Recommendation"

  return (
    <span>
      <div style={{ fontSize: "13px", color: "red", fontStyle: "italic" }}>{commandError}</div>
      {showSave &&
        <Button appearance="command" style={{ fontSize: "14px" }} onClick={updateQuery}
          disabled={disableSavebutton || !access.canEdit}
        >
          <Icon slot="start" name="save"></Icon>
          Save
        </Button>
      }
      <Query
        {...newconfig}
        value={state.tree}
        onChange={onChange}
        renderBuilder={renderBuilder}
      />
      <div style={{ display: 'flex' }}>
        <div className="query-builder-result" style={{ flex: '1' }}>
          <div> Filter Configured
            <TextArea disabled={!access.canEdit} label="Filter Configured" style={{width: size === "medium" ? '45em' : '69em', marginTop: `${commandError.length === 0 ? `20px` : `0px`}`, paddingBottom: "100px", overflowWrap: 'break-word' }} resize="vertical" value={QbUtils.sqlFormat(state.tree, state.config)?.replace("\"", "").replace("SourceId", msg) || ""} />
          </div>
        </div>
      </div>
      {!showSave &&
        <div>
          <div className="medium-flyinpanel-footer">
            <Button type='button' appearance='primary' onClick={updateQuery} disabled={!access.canEdit || (currentJsonTree && state.tree && currentJsonTree === JSON.stringify(QbUtils.getTree(state.tree) as JsonTree))} >Save</Button>
            <span style={{ marginLeft: '20px' }}></span>
            {(<Button type='button' appearance='secondary' onClick={() => { setOpenFilter(false); setcommandError("") }} >Cancel</Button>)}
            <span style={{ marginLeft: '20px' }}></span>
          </div>
        </div>
      }
    </span>
  );
};
export default FilterQueryBuilder;