import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../root-redux/RootState";


export const allUsersSelector = createSelector(
    ( state: RootState) => state.userObjects.list,
    (userObject) => {
    var allUsers : string[] =[];
    userObject.map((object) => {
        if(object.displayName !== undefined)
        allUsers.push(object.displayName)
    });
    return allUsers;
})

export const allUsersOfMergerulesSelector = createSelector(
    ( state: RootState) => state.userObjectsOfMergeRule.list,
    (userObject) => {
    var allUsers : string[] =[];
    userObject.map((object) => {
        if(object.displayName !== undefined)
        allUsers.push(object.displayName)
    });
    return allUsers;
})