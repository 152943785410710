import { mergeRuleByIdSlice } from "./MergeRuleById.redux";


export const {
    updateEssentials,
    updateEnableSave,
    updateTab,
    updateOpenMergeRuleSet,
    updateCurrentMergeRuleSet,
    addUpdateMergeRuleSetInMergeRule,
    createNewMergeRuleSet,
    updateExistingMergeRuleSet,
    resetOpenedMergeRuleSet,
    resetPostMergeRuleStatus,
    updateEnableSaveMR,
    resetMergeRuleSet,
    resetMergeRule,
    moveMergeRuleSetUp,
    moveMergeRuleSetDown,
    updateMergeRule,
    updateDeleteMergeRule,
    updateModelName,
    deleteExistingMergeRuleSet,
    moveModelDown,
    moveModelUp,
    removeMergeRuleById,
    updateOriginalMergeRule,
    updateAdditionalModels
} = mergeRuleByIdSlice.actions;