import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getDatasetUploadTemplate, automateNextSteps } from "../../../services/GetDatasetUploadTemplate.api";
import { uploadDatasetFile } from "../../../services/UploadDatasetFile.api";
import { DatasetResponseType, DatasetUploadResponse } from "../../../model/workflows/DatasetFileUpload.model";
import { Workflow } from "../../../model/workflows/Workflow.model";

export interface DatasetFileUploadState {
    status: RequestStatus,
    error: string | undefined,
    fileUploadResponse: DatasetUploadResponse,
    workflow : Workflow
}

const initialState: DatasetFileUploadState = {
    fileUploadResponse: {} as DatasetUploadResponse,
    status: RequestStatus.idle,
    error: '',
    workflow : {} as Workflow
}

export const DatasetFileUploadSlice = createSlice({
    name: 'fileUploadDataset',
    initialState,
    reducers: {
        setCurrentDatasetFileUploadState: (state, action: PayloadAction<DatasetUploadResponse>) => {
            state.fileUploadResponse = action.payload;
            state.error = undefined;
        },
        setUpdatedWorkflow: (state, action: PayloadAction<Workflow>) => {
            state.workflow = action.payload;
        }
    },
    extraReducers(builder) {
        builder
        .addCase(getDatasetUploadTemplate.pending, (state, _: any) => {
            state.status = RequestStatus.loading;
            state.error = undefined;
        })
        .addCase(getDatasetUploadTemplate.fulfilled, (state, action) => {
            state.status = RequestStatus.succeeded
            if (action.payload?.Response === "ERROR") {
              state.error = "Download Template: " + action.payload?.Error;
            }
            else {
             state.error = undefined;
            }
        })
        .addCase(uploadDatasetFile.rejected, (state, action) => {
             state.status = RequestStatus.failed
             state.error = action.error?.message
        })
        .addCase(uploadDatasetFile.pending, (state, _) => {
            state.status = RequestStatus.loading;
            state.error = undefined;
        })
        .addCase(uploadDatasetFile.fulfilled, (state, action) => {
            state.status = RequestStatus.succeeded
            if (action.payload?.responseType == DatasetResponseType.Failed) {
                state.error = "File Upload Failed : Please check for any Data issues or contact support.";
            }
            else {
                state.error = undefined;
            }
        })
        .addCase(automateNextSteps.rejected, (state, action) => {
            state.status = RequestStatus.failed
            state.error = action.error?.message
        })
        .addCase(automateNextSteps.pending, (state, _) => {
           state.status = RequestStatus.loading;
           state.error = undefined;
        })
        .addCase(automateNextSteps.fulfilled, (state, action) => {
            state.status = RequestStatus.succeeded
            if (action.payload?.Response?.status != 200) {
                state.error = "File Publish :" + action.payload?.Response;
            }
            else {
                state.error = undefined;
            }
        })
    }
});

export const { 
    setCurrentDatasetFileUploadState, setUpdatedWorkflow } = DatasetFileUploadSlice.actions;
  
export const DatasetFileUploadReducer = DatasetFileUploadSlice.reducer