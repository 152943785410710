import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { MergeRule, emptyMergeRule } from '../../../model/merge-rules/MergeRule.model'
import { getMergeRuleById } from '../../../services/get-merge-rules/GetMergeRules.api'
import { MergeRuleSet, emptyMergeRuleSet } from '../../../model/merge-rules/MergeRuleSet.model'
import { v4 as uuidv4 } from 'uuid';
import { postMergeRule } from '../../../services/get-merge-rules/PostMergeRule.api'
import { deleteMergeRule } from '../../../services/get-merge-rules/DeleteMergeRule.api'

interface MergeRuleByIdState {
    mergeRule: MergeRule,
    originalMergeRule: MergeRule,
    enableSave: boolean,
    unsavedChanges: boolean,
    status: RequestStatus,
    error: string | undefined,
    activeTab: string,
    openMergeRuleSet: boolean,
    currentMergeRuleSet: MergeRuleSet,
    originalMergeRuleSet: MergeRuleSet,
    postMergeRuleStatus: RequestStatus,
    enableSaveMergeRuleSet: boolean,
    unsavedMergeRuleSetChanges: boolean,
    deleteMergeRule: boolean,
    deleteMergeRuleStatus: RequestStatus,
    modelName: string,
    additionalModels: string
}

const sortSet = (set: MergeRuleSet[]) => {
    var sortedSet = set.sort((a, b) => {
        if (Number(a['rank']) > Number(b['rank'])) {
            return 1
        } else if (Number(a['rank']) < Number(b['rank'])) {
            return -1
        } else {
            return 0
        }
    });
    return sortedSet
}

const getAdditionalModel = (local: MergeRule) => {
    var sourceFilter: string[] = []
    if (local.primaryMergeRuleSet != null && local.primaryMergeRuleSet.length > 0) {
        local.primaryMergeRuleSet.forEach((item: any) => {
            sourceFilter = sourceFilter.concat(item.mergeModel)
        })
    }
    if (local.secondaryMergeRuleSet != null && local.secondaryMergeRuleSet.length > 0) {
        local.secondaryMergeRuleSet.forEach((item: any) => {
            sourceFilter = sourceFilter.concat(item.mergeModel)
        })
    }
    sourceFilter = sourceFilter.filter((v, i, a) => a.indexOf(v) == i)
    var additionalModelsData = local.sourceFilter && local.sourceFilter.filter(item => !sourceFilter.includes(item))
    if(additionalModelsData && additionalModelsData.length > 0) {
        return additionalModelsData.join(",")
    } else {
        return ""
    }
}

const initialState: MergeRuleByIdState = {
    mergeRule: emptyMergeRule,
    originalMergeRule: emptyMergeRule,
    enableSave: false,
    unsavedChanges: false,
    status: RequestStatus.idle,
    error: '',
    activeTab: "tab1",
    openMergeRuleSet: false,
    currentMergeRuleSet: {} as MergeRuleSet,
    postMergeRuleStatus: RequestStatus.idle,
    enableSaveMergeRuleSet: false,
    unsavedMergeRuleSetChanges: false,
    originalMergeRuleSet: {} as MergeRuleSet,
    deleteMergeRule: false,
    deleteMergeRuleStatus: RequestStatus.idle,
    modelName: "",
    additionalModels: ""
}
export const mergeRuleByIdSlice = createSlice({
    name: 'mergeRuleById',
    initialState,
    reducers: {
        resetMergeRule: (state) => {
            state.enableSave = false
            state.unsavedChanges = false
            state.error = ''
            state.activeTab = "tab1"
            state.openMergeRuleSet = false
            state.currentMergeRuleSet = {} as MergeRuleSet
            state.enableSaveMergeRuleSet = false
            state.unsavedMergeRuleSetChanges = false
            state.deleteMergeRuleStatus = RequestStatus.idle
            state.modelName = ""
            state.mergeRule = emptyMergeRule  
            state.additionalModels = ""  
        },
        resetMergeRuleSet: (state) => {
            state.openMergeRuleSet = false
            state.currentMergeRuleSet = {} as MergeRuleSet
            state.enableSaveMergeRuleSet = false
            state.originalMergeRuleSet = {} as MergeRuleSet
            state.unsavedMergeRuleSetChanges = false
        },
        removeMergeRuleById: (state, action: PayloadAction<string>) => {
            state.mergeRule = {...emptyMergeRule, mergeRuleId: action.payload, mergeRuleName: action.payload}
        },
        updateModelName: (state, action: PayloadAction<string>) => {
            state.modelName = action.payload
        },
        updateDeleteMergeRule: (state, action: PayloadAction<boolean>) => {
            state.deleteMergeRule = action.payload
        },
        updateEssentials: (state, action: PayloadAction<MergeRule>) => {
            state.mergeRule = action.payload
        },
        updateOriginalMergeRule: (state, action: PayloadAction<MergeRule>) => {
            state.originalMergeRule = action.payload
        },
        updateEnableSave: (state, action: PayloadAction<boolean>) => {
            state.enableSave = action.payload

            if (state.originalMergeRule && state.mergeRule && JSON.stringify(state.originalMergeRule) !== JSON.stringify(state.mergeRule)) {
                state.unsavedChanges = true
            } else {
                state.unsavedChanges = false
            }
        },
        updateAdditionalModels: (state, action: PayloadAction<string>) => {
            var currMergeRule = current(state.mergeRule)
            var sourceFilter: string[] = []

            state.additionalModels = action.payload
            
            if (currMergeRule.primaryMergeRuleSet != null && currMergeRule.primaryMergeRuleSet.length > 0) {
                currMergeRule.primaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if (currMergeRule.secondaryMergeRuleSet != null && currMergeRule.secondaryMergeRuleSet.length > 0) {
                currMergeRule.secondaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if(action.payload) {
                sourceFilter = sourceFilter.concat(action.payload.split(",").map(x => x && x.trim()))
            }
            sourceFilter = sourceFilter.filter((v, i, a) => a.indexOf(v) == i)
            state.mergeRule = { ...currMergeRule ,sourceFilter: sourceFilter}
        },
        updateEnableSaveMR: (state, action: PayloadAction<boolean>) => {
            state.enableSaveMergeRuleSet = action.payload
            if (state.originalMergeRuleSet && state.currentMergeRuleSet && JSON.stringify(state.originalMergeRuleSet) !== JSON.stringify(state.currentMergeRuleSet)) {
                state.unsavedMergeRuleSetChanges = true
            } else {
                state.unsavedMergeRuleSetChanges = false
            }
        },
        updateTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload
        },
        createNewMergeRuleSet: (state) => {
            state.openMergeRuleSet = true
            var primaryLength = state.mergeRule.primaryMergeRuleSet.length
            var localMergeRuleSet = emptyMergeRuleSet
            localMergeRuleSet = {
                ...localMergeRuleSet,
                rank: (primaryLength + 1).toString(),
                id: uuidv4().toString(),
                type: "primary"
            }
            state.currentMergeRuleSet = localMergeRuleSet
            state.originalMergeRuleSet = localMergeRuleSet
        },
        deleteExistingMergeRuleSet: (state, action: PayloadAction<String>) => {
            var currMergeRule = state.mergeRule
            var mergeRuleSetIdToBeDeleted = action.payload
            var localPrimarySet = currMergeRule.primaryMergeRuleSet.filter((x: { id: any; }) => {return x.id !== mergeRuleSetIdToBeDeleted})
            var localSecondarySet = currMergeRule.secondaryMergeRuleSet.filter((x: { id: any; }) => {return x.id !== mergeRuleSetIdToBeDeleted})
            var lengthOfPrimarySet = localPrimarySet.length;
            for(var i = 0; i< localPrimarySet.length;i++){
                localPrimarySet[i].rank = (i + 1).toString()
            }
            for(var j = 0; j< localSecondarySet.length;j++){
                localSecondarySet[j].rank = (lengthOfPrimarySet + j + 1).toString()
            }
            currMergeRule = {...currMergeRule, primaryMergeRuleSet: localPrimarySet, secondaryMergeRuleSet: localSecondarySet}

            var sourceFilter: string[] = []
            if (currMergeRule.primaryMergeRuleSet != null && currMergeRule.primaryMergeRuleSet.length > 0) {
                currMergeRule.primaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if (currMergeRule.secondaryMergeRuleSet != null && currMergeRule.secondaryMergeRuleSet.length > 0) {
                currMergeRule.secondaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if(state.additionalModels) {
                sourceFilter = sourceFilter.concat(state.additionalModels.split(",").map(x => x && x.trim()))
            }
            currMergeRule.sourceFilter = sourceFilter.filter((v, i, a) => a.indexOf(v) == i)
            state.mergeRule = currMergeRule
        },
        updateExistingMergeRuleSet: (state, action: PayloadAction<String>) => {
            var currMergeRule = current(state.mergeRule)
            state.openMergeRuleSet = true
            var id = action.payload
            var localMergeRuleSet = currMergeRule.primaryMergeRuleSet.find((x: any) => { return x.id === id })
            if (!localMergeRuleSet) {
                localMergeRuleSet = currMergeRule.secondaryMergeRuleSet.find((x: any) => { return x.id === id })
            }
            if (localMergeRuleSet) {
                state.currentMergeRuleSet = { ...localMergeRuleSet }
                state.originalMergeRuleSet = { ...localMergeRuleSet }
            } else {
                state.currentMergeRuleSet = {} as MergeRuleSet
                state.originalMergeRuleSet = {} as MergeRuleSet
            }
        },
        resetOpenedMergeRuleSet: (state) => {
            state.openMergeRuleSet = false
            state.currentMergeRuleSet = {} as MergeRuleSet
        },
        updateOpenMergeRuleSet: (state) => {
            state.openMergeRuleSet = !state.openMergeRuleSet
        },
        updateCurrentMergeRuleSet: (state, action: PayloadAction<MergeRuleSet>) => {
            state.currentMergeRuleSet = action.payload
        },
        addUpdateMergeRuleSetInMergeRule: (state) => {
            var currMergeRuleSet = current(state.currentMergeRuleSet);
            
            if(currMergeRuleSet?.useMRLevelConfigToPrioritizePredictions?.toString()?.length === 0 || currMergeRuleSet?.useMRLevelConfigToPrioritizePredictions === undefined || currMergeRuleSet?.useMRLevelConfigToPrioritizePredictions === null)
            {
                currMergeRuleSet = { ...currMergeRuleSet, useMRLevelConfigToPrioritizePredictions: true }
            }
            var currMergeRule = state.mergeRule
            var mergeruleSets = currMergeRule.primaryMergeRuleSet
            mergeruleSets = mergeruleSets.concat(currMergeRule.secondaryMergeRuleSet)
            var isPresent = mergeruleSets.find(x => { return x.id === currMergeRuleSet.id })
            // If new
            if (!isPresent) {
                // and primary, then push to primary ruleset and increment ranks of secondary sets
                if (currMergeRuleSet.type === "primary") {
                    currMergeRule.primaryMergeRuleSet.push(currMergeRuleSet)
                    var lengthOfSecondary = currMergeRule.secondaryMergeRuleSet.length
                    var localSecondarySet = currMergeRule.secondaryMergeRuleSet
                    for (var i = 0; i < lengthOfSecondary; i++) {
                        localSecondarySet[i].rank = (Number(localSecondarySet[i].rank) + 1).toString();
                    }
                    currMergeRule.secondaryMergeRuleSet = localSecondarySet
                }
                // and secondary, then correct the rank and push to secondary 
                else {
                    var totalLength = mergeruleSets.length
                    currMergeRuleSet = {...currMergeRuleSet, rank : (totalLength + 1).toString()}
                    currMergeRule = {
                        ...currMergeRule,
                        secondaryMergeRuleSet: [...currMergeRule.secondaryMergeRuleSet, currMergeRuleSet]
                    }
                    currMergeRuleSet = { ...currMergeRuleSet, rank: (totalLength + 1).toString() }
                }
            }
            // If already present
            else {
                var typeInOld = isPresent.type
                // and types in old and new are different
                if (typeInOld !== currMergeRuleSet.type) {
                    if (typeInOld === "primary" && currMergeRuleSet.type === "secondary") {
                        currMergeRule.primaryMergeRuleSet = currMergeRule.primaryMergeRuleSet.filter((x: any) => { return x.id !== currMergeRuleSet.id })
                        var primaryLength = currMergeRule.primaryMergeRuleSet.length
                        currMergeRule.secondaryMergeRuleSet.unshift(currMergeRuleSet)
                        var localSecondarySet = currMergeRule.secondaryMergeRuleSet
                        for (var i = 1; i < localSecondarySet.length; i++) {
                            localSecondarySet[i] = { ...localSecondarySet[i], rank: (primaryLength + i).toString() }
                            i++
                        };
                        currMergeRule.secondaryMergeRuleSet = localSecondarySet
                    } else if (typeInOld === "secondary" && currMergeRuleSet.type === "primary") {
                        currMergeRule.secondaryMergeRuleSet = currMergeRule.secondaryMergeRuleSet.filter((x: any) => { return x.id !== currMergeRuleSet.id })
                        var primaryLength = currMergeRule.primaryMergeRuleSet.length
                        currMergeRuleSet= {...currMergeRuleSet, rank : ((primaryLength) + 1).toString()}
                        currMergeRule.primaryMergeRuleSet = [...currMergeRule.primaryMergeRuleSet, currMergeRuleSet]
                        var i = 1;
                        var localSecondarySet = currMergeRule.secondaryMergeRuleSet
                        for (var i = 1; i < localSecondarySet.length; i++) {
                            localSecondarySet[i] = {
                                ...localSecondarySet[i],
                                rank: (primaryLength + i + 1).toString()
                            }
                            i++
                        };
                        currMergeRule.secondaryMergeRuleSet = localSecondarySet
                    }
                }
                // and types are same in old and new, find index and replace
                else if (typeInOld === currMergeRuleSet.type) {
                    if (currMergeRuleSet.type === "primary") {
                        var local = currMergeRule.primaryMergeRuleSet.filter((x: any) => { return x.id !== currMergeRuleSet.id })
                        currMergeRule.primaryMergeRuleSet = [...local, currMergeRuleSet]
                    } else {
                        var local = currMergeRule.secondaryMergeRuleSet.filter(x => { return x.id !== currMergeRuleSet.id })
                        currMergeRule.secondaryMergeRuleSet = [...local, currMergeRuleSet]
                    }
                }
            }
            if (currMergeRule.primaryMergeRuleSet != null && currMergeRule.secondaryMergeRuleSet != null) {
                var localPrimarySetToSort = sortSet(currMergeRule.primaryMergeRuleSet)
                var localSecondarySetToSort = sortSet(currMergeRule.secondaryMergeRuleSet)
                currMergeRule.primaryMergeRuleSet = localPrimarySetToSort
                currMergeRule.secondaryMergeRuleSet = localSecondarySetToSort
            }
            var sourceFilter: string[] = []
            if (currMergeRule.primaryMergeRuleSet != null && currMergeRule.primaryMergeRuleSet.length > 0) {
                currMergeRule.primaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if (currMergeRule.secondaryMergeRuleSet != null && currMergeRule.secondaryMergeRuleSet.length > 0) {
                currMergeRule.secondaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if(state.additionalModels) {
                sourceFilter = sourceFilter.concat(state.additionalModels.split(",").map(x => x && x.trim()))
            }
            currMergeRule.sourceFilter = sourceFilter.filter((v, i, a) => a.indexOf(v) == i)

            state.mergeRule = { ...currMergeRule }
            state.currentMergeRuleSet = {} as MergeRuleSet
            state.openMergeRuleSet = false
        },
        resetPostMergeRuleStatus: (state) => {
            state.postMergeRuleStatus = RequestStatus.idle
        },
        moveModelUp: (state, action: PayloadAction<String>) => {
            var priority = [...state.currentMergeRuleSet.priority]
            var isModelPresent = priority.find((x:any) => {return x ===  action.payload})
            if(isModelPresent){
                var index = priority.indexOf(isModelPresent)
                if(index > 0) {
                    var swapIndex = index - 1
                    var temp = priority[index]
                    priority[index] = priority[swapIndex]
                    priority[swapIndex] = temp
                }
            }
            state.currentMergeRuleSet = {...state.currentMergeRuleSet, priority: priority}
        },
        moveModelDown: (state, action: PayloadAction<String>) => {
            var priority = [...state.currentMergeRuleSet.priority]
            var isModelPresent = priority.find((x:any) => {return x ===  action.payload})
            if(isModelPresent){
                var index = priority.indexOf(isModelPresent)
                var totalLength = priority.length
                if(index < totalLength - 1) {
                    var swapIndex = index + 1
                    var temp = priority[index]
                    priority[index] = priority[swapIndex]
                    priority[swapIndex] = temp
                }
            }
            state.currentMergeRuleSet = {...state.currentMergeRuleSet, priority: priority}
        },
        moveMergeRuleSetUp: (state, action: PayloadAction<String>) => {
            var currMergeRule = state.mergeRule
            var curRank = Number(action.payload)

            var primaryLength = currMergeRule.primaryMergeRuleSet?.length || 0
            var secondaryLength = currMergeRule.secondaryMergeRuleSet?.length || 0
            var totalLength = primaryLength + secondaryLength

            if (curRank === primaryLength + 1 || curRank === 1) {
                return;
            }
            var swapRank = Number(curRank) - 1
            var isPrimary = curRank <= primaryLength
            if (isPrimary) {
                var currRuleSet = currMergeRule.primaryMergeRuleSet.find(x => { return x.rank === curRank.toString() })
                var swapRuleSet = currMergeRule.primaryMergeRuleSet.find(x => { return x.rank === swapRank.toString() })
                if (currRuleSet && swapRuleSet) {
                    var currIndex = currMergeRule.primaryMergeRuleSet.indexOf(currRuleSet)
                    var swapIndex = currMergeRule.primaryMergeRuleSet.indexOf(swapRuleSet)
                    if (currIndex !== -1 && swapIndex !== -1) {
                        var localPrimarySet = [...currMergeRule.primaryMergeRuleSet]
                        currRuleSet = { ...currRuleSet, rank: swapRank.toString() }
                        swapRuleSet = { ...swapRuleSet, rank: curRank.toString() }
                        localPrimarySet[swapIndex] = currRuleSet
                        localPrimarySet[currIndex] = swapRuleSet
                        currMergeRule.primaryMergeRuleSet = localPrimarySet
                    }
                }
            } else {
                var currRuleSet = currMergeRule.secondaryMergeRuleSet.find(x => { return x.rank === curRank.toString() })
                var swapRuleSet = currMergeRule.secondaryMergeRuleSet.find(x => { return x.rank === swapRank.toString() })
                if (currRuleSet && swapRuleSet) {
                    var currIndex = currMergeRule.secondaryMergeRuleSet.indexOf(currRuleSet)
                    var swapIndex = currMergeRule.secondaryMergeRuleSet.indexOf(swapRuleSet)
                    if (currIndex !== -1 && swapIndex !== -1) {
                        var localSecondarySet = [...currMergeRule.secondaryMergeRuleSet]
                        currRuleSet = { ...currRuleSet, rank: swapRank.toString() }
                        swapRuleSet = { ...swapRuleSet, rank: curRank.toString() }
                        localSecondarySet[swapIndex] = currRuleSet
                        localSecondarySet[currIndex] = swapRuleSet
                        currMergeRule.secondaryMergeRuleSet = localSecondarySet
                    }
                }
            }
            state.mergeRule = {
                ...state.mergeRule,
                primaryMergeRuleSet: currMergeRule.primaryMergeRuleSet,
                secondaryMergeRuleSet: currMergeRule.secondaryMergeRuleSet
            }
        },
        moveMergeRuleSetDown: (state, action: PayloadAction<String>) => {
            var currMergeRule = state.mergeRule
            var curRank = Number(action.payload)

            var primaryLength = currMergeRule.primaryMergeRuleSet?.length || 0
            var secondaryLength = currMergeRule.secondaryMergeRuleSet?.length || 0
            var totalLength = primaryLength + secondaryLength

            if (curRank === primaryLength || curRank === totalLength) {
                return;
            }
            var swapRank = Number(curRank) + 1
            var isPrimary = curRank <= primaryLength
            if (isPrimary) {
                var currRuleSet = currMergeRule.primaryMergeRuleSet.find(x => { return x.rank === curRank.toString() })
                var swapRuleSet = currMergeRule.primaryMergeRuleSet.find(x => { return x.rank === swapRank.toString() })
                if (currRuleSet && swapRuleSet) {
                    var currIndex = currMergeRule.primaryMergeRuleSet.indexOf(currRuleSet)
                    var swapIndex = currMergeRule.primaryMergeRuleSet.indexOf(swapRuleSet)
                    if (currIndex !== -1 && swapIndex !== -1) {
                        var localPrimarySet = [...currMergeRule.primaryMergeRuleSet]
                        currRuleSet = { ...currRuleSet, rank: swapRank.toString() }
                        swapRuleSet = { ...swapRuleSet, rank: curRank.toString() }
                        localPrimarySet[swapIndex] = currRuleSet
                        localPrimarySet[currIndex] = swapRuleSet
                        currMergeRule.primaryMergeRuleSet = localPrimarySet
                    }
                }
            } else {
                var currRuleSet = currMergeRule.secondaryMergeRuleSet.find(x => { return x.rank === curRank.toString() })
                var swapRuleSet = currMergeRule.secondaryMergeRuleSet.find(x => { return x.rank === swapRank.toString() })
                if (currRuleSet && swapRuleSet) {
                    var currIndex = currMergeRule.secondaryMergeRuleSet.indexOf(currRuleSet)
                    var swapIndex = currMergeRule.secondaryMergeRuleSet.indexOf(swapRuleSet)
                    if (currIndex !== -1 && swapIndex !== -1) {
                        var localSecondarySet = [...currMergeRule.secondaryMergeRuleSet]
                        currRuleSet = { ...currRuleSet, rank: swapRank.toString() }
                        swapRuleSet = { ...swapRuleSet, rank: curRank.toString() }
                        localSecondarySet[swapIndex] = currRuleSet
                        localSecondarySet[currIndex] = swapRuleSet
                        currMergeRule.secondaryMergeRuleSet = localSecondarySet
                    }
                }
            }
            state.mergeRule = {
                ...state.mergeRule,
                primaryMergeRuleSet: currMergeRule.primaryMergeRuleSet,
                secondaryMergeRuleSet: currMergeRule.secondaryMergeRuleSet
            }
        },
        updateMergeRule: (state, action: PayloadAction<MergeRule>) => {
            state.mergeRule = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getMergeRuleById.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(getMergeRuleById.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                var changedMergeRule = action.payload[0]
                if (!changedMergeRule.mergeRuleName) {
                    changedMergeRule.mergeRuleName = changedMergeRule.mergeRuleId
                }
                state.mergeRule = changedMergeRule
                state.originalMergeRule = changedMergeRule
                state.additionalModels = getAdditionalModel(changedMergeRule)
                state.error = undefined
            })
            .addCase(getMergeRuleById.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.mergeRule = emptyMergeRule
                state.error = action.error.message
            })
            .addCase(postMergeRule.pending, (state, action) => {
                state.postMergeRuleStatus = RequestStatus.loading
            })
            .addCase(postMergeRule.fulfilled, (state, action) => {
                state.postMergeRuleStatus = RequestStatus.succeeded
                if (action.payload === true) {
                    state.postMergeRuleStatus = RequestStatus.succeeded
                } else {
                    state.postMergeRuleStatus = RequestStatus.failed
                }
            })
            .addCase(postMergeRule.rejected, (state, action) => {
                state.postMergeRuleStatus = RequestStatus.failed
            })
    }
})



export const mergeRuleByIdReducer = mergeRuleByIdSlice.reducer