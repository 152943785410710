import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ListFilterState {
  campaignFilters: any;
}

const initialState: ListFilterState = {
  campaignFilters: {}
}

export const ListFilterSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaignCheckedFilter: (state, action: PayloadAction<any>) => {
      state.campaignFilters = action.payload;
    }
  },
  extraReducers: {}
});

export const {
  setCampaignCheckedFilter
} = ListFilterSlice.actions;

export const ListFilterReducer = ListFilterSlice.reducer;