import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { getCampaignById, postCampaignDetails } from "../../../services/SPAAdmin/CampaignById.api"
import { Attribute, Campaign, ReviewList } from "../../../model/SPAModels/campaigns/campaign.Model";
import { Helper } from "../utils/Help";
import { v4 as uuidv4 } from 'uuid'
import { CampaignStageType } from "../../../model/SPAModels/campaigns/CampaignStageType.Model";
import { PublishMap, PublishModel } from "../../../model/workflows/Publish.model";
import { WorkflowStage } from "../../../model/workflows/WorkflowStage.model";
import { DataIngestion } from "../../../model/workflows/DataIngestion.model";
import { Delta } from "../../../model/workflows/Delta.model";
import { CampaignFilterQuery } from '../../../model/SPAModels/campaigns/campaign.Model';
import { CampaignTypeToBlobPathMap, CampaignTypesEnum, SPA_CAMPAIGN_TYPE_ATTRIBUTE } from "../SPAConstants";
import { getAllRecords } from "../../../services/SPAAdmin/CampaignDataCart.api";
export interface CampaignByIdState {
    campaign: Campaign|undefined ,
    originalCampaign: Campaign,
    summary: {[key: string]: string | undefined}
    isReviewer: boolean,
    addReviewList: string[],
    deleteReviewList: string[],
    requestStatus: RequestStatus,
    error: string | undefined,
    currentStage: number,
    currentStepId: string,
    currentStepIndex: number,
    publishAttributes:[string, string][],
    unsavedChanges: boolean,
    isStandardSPA: boolean,
    editCampaignFlag: boolean,
    enableSave: boolean,
    isSideNavOpen: boolean,
    defaultWarningMsg: boolean,
    type: 'new' | 'existing' | 'clone',
    publishMapChanged: boolean,
    showPreSummary: boolean

}

const initialState: CampaignByIdState = {
    campaign: undefined,
    originalCampaign: {} as Campaign,
    summary: {},
    isReviewer: false,
    addReviewList: [],
    deleteReviewList: [],
    requestStatus: RequestStatus.idle,
    error: undefined,
    currentStage: 0,
    currentStepId: '',
    currentStepIndex: 0,
    publishAttributes: [],
    unsavedChanges: false,
    isStandardSPA: false,
    editCampaignFlag: false,
    enableSave: false,
    isSideNavOpen: true,
    defaultWarningMsg:false,
    type: 'new',
    publishMapChanged: false,
    showPreSummary: false
};

const formPathString = (str: string|undefined, type: string|undefined) => (
    '/' +(str || "").replaceAll(' ', '').toLowerCase() + '_' + Helper.getDate() + '.csv'
   // DefaultPublishValues.DataPath + '/' + (str || "").replaceAll(' ', '').toLowerCase() + '_' + Helper.getDate() + '.csv'
);

const getReviewList = (campaign: Campaign|undefined, reviewList: ReviewList[]|undefined, reviewer: boolean) => {
    let filtered: any[] = [];
    if (reviewer) {
        const userSalesRoles = JSON.parse(localStorage.getItem('userSalesRoles') || '{}');
        filtered = (reviewList || []).filter(rList => rList.attributes.some(attr => attr.value === userSalesRoles['area'])).map(rList => rList.list);
        filtered = filtered.at(0);
    } else {
        (reviewList || []).map(rList => filtered = filtered.concat(rList.list));
    }
    const dlist: string[] = [], alist: string[] = [];
    (filtered || []).forEach(id => {
        if (campaign && campaign.inCart.includes(id)) {
            dlist.push(id);
        } else {
            alist.push(id);
        }
    });
    return [alist, dlist];
}

export const CampaignByIdSlice = createSlice({
    name: 'campaignById',
    initialState,
    reducers: {
        setCampaignSummary: (state, action: PayloadAction<Attribute>) => {
            state.summary[action.payload.key] = action.payload.value;
        },
        setInCartAndOutOfCart: (state, action: PayloadAction<{[key: string]: string[]}>) => {
            state.campaign!.inCart = action.payload["InCart"];
            state.campaign!.dataSets = action.payload["DataSets"];
        },
        filterReviewList: (state, _: PayloadAction<void>) => {
            state.isReviewer = true;
            getReviewList(state.campaign, state.campaign?.reviewList, true);
        },
        addFilter: (state, action: PayloadAction<{[key: string]: CampaignFilterQuery[]}>) => {
            state.campaign!.filters = action.payload["filters"];
        },
        newCampaign: (state, _: PayloadAction<void>) => {
            state.campaign = undefined;
            state.summary = {};
            state.requestStatus = RequestStatus.idle;
            state.error = undefined;
        },
        addToReviewList: (state, action: PayloadAction<string>) => {
            if (!state.addReviewList.includes(action.payload)) {
                state.addReviewList.push(action.payload);
                state.unsavedChanges = true;
            }
            const idx = state.deleteReviewList.indexOf(action.payload);
            if (idx > -1) {
                state.deleteReviewList.splice(idx, 1);
                state.unsavedChanges = true;
            }
        },
        deleteFromReviewList: (state, action: PayloadAction<string>) => {
            if (!state.deleteReviewList.includes(action.payload) && state.campaign?.inCart.includes(action.payload)) {
                state.deleteReviewList.push(action.payload);
                state.unsavedChanges = true;
            }
            const idx = state.addReviewList.indexOf(action.payload);
            if (idx > -1) {
                state.addReviewList.splice(idx, 1);
                state.unsavedChanges = true;
            }
        },
        clearReviewList: (state, action: PayloadAction<string[]|undefined>) => {
            if (state.campaign !== undefined) {
                const reviewList = [...(state.campaign?.reviewList || [])];
                state.campaign.reviewList = reviewList.map(rList => {
                    rList.list = action.payload ? rList.list.filter(val => !action.payload?.includes(val)) : [];
                    return {...rList};
                })
            }
        },
        resolveReviewList: (state, action: PayloadAction<string[]>) => {
            const addToReviewList = !Helper.isEmpty(action.payload) ? state.addReviewList.filter(id => action.payload.includes(id)) : state.addReviewList;
            const deleteReviewList = !Helper.isEmpty(action.payload) ? state.deleteReviewList.filter(id => action.payload.includes(id)) : state.deleteReviewList;
            addToReviewList.forEach(id => {
                if (!state.campaign?.inCart.includes(id)) {
                    state.campaign?.inCart.push(id);
                }
            });
            deleteReviewList.forEach(id => {
                const idx = state.campaign?.inCart.indexOf(id)!;
                if (idx > -1) state.campaign?.inCart.splice(idx, 1);
            });
            state.addReviewList = state.addReviewList.filter(id => !addToReviewList.includes(id));
            state.deleteReviewList = state.deleteReviewList.filter(id => !deleteReviewList.includes(id));

            const newReviewList: ReviewList[] = [];
            state.campaign?.reviewList.forEach(rList => {
                const rls = {...rList};
                rls.list = rList.list.filter(id => state.addReviewList.includes(id) || state.deleteReviewList.includes(id));
                newReviewList.push(rls);
            });
            state.campaign!.reviewList = newReviewList;
        },
        setUnSavedChanges: (state, action: PayloadAction<boolean>) => {
            state.unsavedChanges = action.payload;
        },
        setStandardSPAFlag: (state, action: PayloadAction<boolean>) => {
            state.isStandardSPA = action.payload;
        },
        setEditCampaignFlag: (state, action: PayloadAction<any>) => {
            state.editCampaignFlag = action.payload;
        },
        addSPADataSet: (state, action: PayloadAction<{publishMap:PublishMap[],blobConnectionId:string,primaryKeys:string[]}>) => {
            const newPublishData = {
                stepID: uuidv4().toString(),
                inputViewName: 'final_vw_campaign',
                outputViewName: '',
                path: formPathString(state.campaign?.config.workflowName,state.summary[SPA_CAMPAIGN_TYPE_ATTRIBUTE]),
                appModelName: '',
                scalaScript: '',
                query: '',
                type: 'BLOB',
                blobConnectionId: action.payload.blobConnectionId,           // Need to pass the connectionIds from campaignFinalView
                connectionId: action.payload.blobConnectionId,               // Need to pass the connectionIds from campaignFinalView
                publishMap: action.payload.publishMap,
                primaryKeys: Array.from(action.payload.primaryKeys).join(','),
                publishTo: 'custom',
                delta: false,
                tableName: '',
                modelId: state.campaign!.config.workflowId
            };
            state.campaign!.publish[0]=newPublishData;
            state.currentStepId = state.campaign!.publish[state.campaign!.publish.length - 1].stepID
            state.currentStepIndex = state.campaign!.publish.length - 1;
        },
        addDataSrcToCampaign: (state, action: PayloadAction<string>) => {
            state.campaign?.dataSets.push(action.payload);
        },
        addDataSet: (state, action: PayloadAction<boolean>) => {
            const newDataSet: WorkflowStage = {
                stepID: uuidv4().toString(),
                type: '',
                inputViewName: '',
                outputViewName: '',
                path: '',
                appModelName: '',
                scalaScript: '',
                query: ''
            }

            switch(state.currentStage) {
                case CampaignStageType.Publish:
                    if (!state.campaign!.publish) {
                        state.campaign!.publish = []
                    }
                    // if add duplicate dataset
                    if (action.payload) {
                        state.campaign!.publish.push({...state.campaign!.publish[state.currentStepIndex], stepID: uuidv4().toString()})
                    }
                    else {
                        const newPublishData: PublishModel = {
                            ...newDataSet, 
                            type: 'BLOB',
                            blobConnectionId: '',
                            connectionId: '',
                            publishMap: [],
                            primaryKeys: '',
                            publishTo: 'custom',
                            delta: false,
                            tableName: '',
                            modelId: state.campaign!.config.workflowId
                        }
                        state.campaign!.publish.push(newPublishData);
                    }
                    state.currentStepId = state.campaign!.publish[state.campaign!.publish.length - 1].stepID
                    state.currentStepIndex = state.campaign!.publish.length - 1;
                    break    
                default:
                    break  
            }
        },
        saveCampaign: (state, action: PayloadAction<Campaign>) => {
            state.campaign = action.payload;            
        },
        ingestCartQueryAndFinalView: (state, action: PayloadAction<DataIngestion[]>) => {
            const dataIngArr:Array<DataIngestion> = action.payload;
            if (!state.campaign!.dataIngestion) {
                state.campaign!.dataIngestion = []
            }
            state.campaign!.dataIngestion[0]= dataIngArr[0];
            state.campaign!.dataIngestion[1]= dataIngArr[1];
            state.currentStage = CampaignStageType.Publish;
        },
        ingestFinalView: (state, action: PayloadAction<DataIngestion>) => {
            if (!state.campaign!.dataIngestion) {
                state.campaign!.dataIngestion = []
            }
            state.campaign!.dataIngestion[0]= action.payload;
            // state.currentStepId = state.campaign!.dataIngestion[state.campaign!.dataIngestion.length - 1].stepID
            // state.currentStepIndex = state.campaign!.dataIngestion.length - 1
            state.currentStage = CampaignStageType.Publish

        },
        updatePublishAttributes: (state, action: PayloadAction<[string, string][]>) => {
            state.publishAttributes = action.payload;
        },
        updateCampaignPublish: (state, action: PayloadAction<PublishModel>) => {
           
            const publishItem = action.payload

            if(!publishItem.delta){
                let deltaIndex = -1
                const deltaItem = state.campaign!.delta?.find((item, index) => {
                    if (item.inputViewName === publishItem.inputViewName) {
                        deltaIndex = index;
                        return true                    
                    }
                    return false
                    
                });
                if(deltaItem && deltaIndex >= 0){
                    state.campaign!.delta.splice(deltaIndex, 1);
                    state.campaign = {...state.campaign} as Campaign;
                }
            }
            const currIndex = state.currentStepIndex

            if (currIndex >= 0 && currIndex < (state.campaign?.publish?.length ?? 0)) {
                state.campaign!.publish[currIndex] = action.payload
                state.campaign = { ...state.campaign } as Campaign;
            }
        },
        updateCampaignDelta: (state, action: PayloadAction<Delta>) => {
            const deltaItem = action.payload;
            let deltaIndex = -1
            const existingDelta = state.campaign!.delta?.find((item, index) => {
                if (item.stepID === deltaItem.stepID){
                    deltaIndex = index;
                    return true;
                }
                return false;
            });
            if(existingDelta && deltaIndex >= 0){
                state.campaign!.delta[deltaIndex] = deltaItem;
                state.campaign = {...state.campaign} as Campaign;
            }
            else{
                if (!state.campaign!.delta) {
                    state.campaign!.delta = [];
                }
                if (deltaItem && deltaItem.stepID) {
                    state.campaign!.delta.push(deltaItem);
                }
                
            }
        },
        updateEnableSave: (state, action:PayloadAction<boolean>) => {
            state.enableSave = action.payload

            if (state.originalCampaign && state.campaign && JSON.stringify(state.originalCampaign) !== JSON.stringify(state.campaign)) {
                state.unsavedChanges = true
            } else {
                state.unsavedChanges = false
            }
        },
        updateUnsavedChanges: (state, action:PayloadAction<boolean>) => {
            state.unsavedChanges = action.payload
        },
        updateCurrentStepId: (state, action: PayloadAction<{stepId: string, stepIndex: number}>) => {
            state.currentStepId = action.payload.stepId
            state.currentStepIndex = action.payload.stepIndex
        },
        deleteDataSet: (state) => {
            const currentIndex = state.currentStepIndex
            switch(state.currentStage) {
                case CampaignStageType.Publish:
                    if(state.campaign!.delta.filter(x => x.inputViewName === state.campaign!.publish[currentIndex].inputViewName).length > 0) {
                        state.campaign!.delta = state.campaign!.delta.filter(x => x.inputViewName !== state.campaign!.publish[currentIndex].inputViewName)
                    }
                    state.campaign!.publish.splice(currentIndex, 1)
                    state.currentStepId = state.campaign!.publish && state.campaign!.publish.length > 0 ? state.campaign!.publish[0].stepID : ''
                    break
                default:
                    break
            }
        },
        updateIsSideNavOpen: (state, action: PayloadAction<boolean>) => {
            state.isSideNavOpen = action.payload
        },
        updatePublishMapChanged: (state, action:PayloadAction<boolean>) => {
            state.publishMapChanged = action.payload
        },
        updateCurrentStage: (state, action: PayloadAction<number>) => {
            state.currentStage = action.payload
        },
        setPreSummary: (state, action: PayloadAction<boolean>) => {
            state.showPreSummary = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(postCampaignDetails.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading
                state.error = undefined
            })
            .addCase(postCampaignDetails.fulfilled, (state, action: PayloadAction<Campaign>) => {
                state.requestStatus = RequestStatus.succeeded
                state.campaign = action.payload;
                state.summary = Helper.fetchCampaignFieldsToSummary(action.payload);
                const [alist, dlist] = getReviewList(state.campaign, action.payload.reviewList, state.isReviewer);
                state.addReviewList = alist;
                state.deleteReviewList = dlist;
                state.unsavedChanges = false;
                state.error = undefined
            })
            .addCase(postCampaignDetails.rejected, (state, action: any) => {
                state.requestStatus = RequestStatus.failed
                state.error = "Post Campaign: " + (action.payload['Message'] || action.error.message);
            })
            .addCase(getCampaignById.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading
                state.error = undefined
            })
            .addCase(getCampaignById.fulfilled, (state, action: PayloadAction<Campaign>) => {
                state.requestStatus = RequestStatus.succeeded
                state.campaign = action.payload;
                state.summary = Helper.fetchCampaignFieldsToSummary(action.payload);
                const [alist, dlist] = getReviewList(state.campaign, action.payload.reviewList, state.isReviewer);
                state.addReviewList = alist;
                state.deleteReviewList = dlist;
                state.unsavedChanges = false;
                state.error = undefined
            })
            .addCase(getCampaignById.rejected, (state, action: any) => {
                state.requestStatus = RequestStatus.failed
                state.error = "Get Campaign: " + (action.payload['Message'] || action.error.message);
            })
            .addCase(getAllRecords.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined;
            })
            .addCase(getAllRecords.fulfilled, (state, action) => {
                state.requestStatus = RequestStatus.succeeded;
                state.campaign = action.payload;
                state.summary = Helper.fetchCampaignFieldsToSummary(action.payload);
                const [alist, dlist] = getReviewList(state.campaign, action.payload.reviewList, state.isReviewer);
                state.addReviewList = alist;
                state.deleteReviewList = dlist;
                state.unsavedChanges = false;
                state.error = undefined;
            })
            .addCase(getAllRecords.rejected, (state, action: any) => {
                state.requestStatus = RequestStatus.failed;
                state.error = "Add all Records to Campaign: " + (action.payload['Message'] || action.error.message);
            })
          
    }
});


export const {
    setCampaignSummary,
    setInCartAndOutOfCart,
    filterReviewList,
    newCampaign,
    setStandardSPAFlag,
    setEditCampaignFlag,
    addToReviewList,
    deleteFromReviewList,
    clearReviewList,
    resolveReviewList,
    setUnSavedChanges,
    addSPADataSet,
    addDataSrcToCampaign,
    addDataSet,
    saveCampaign,
    ingestFinalView,
    updatePublishAttributes,
    updateCampaignDelta,
    updateCampaignPublish,
    updateCurrentStepId,
    updateEnableSave,
    updateUnsavedChanges,
    updatePublishMapChanged,
    updateCurrentStage,
    updateIsSideNavOpen,
    deleteDataSet,
    addFilter,
    ingestCartQueryAndFinalView,
    setPreSummary
} = CampaignByIdSlice.actions;


export const CampaignByIdReducer = CampaignByIdSlice.reducer

