import React from "react";
import "../Home.css";
import { Skeleton } from "../../../common/HarmonyEnablers";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";

const WorkspaceOnboardedC: React.FC<any> = (props: any) => {
  const showLoader = (props.status & (RequestStatus.succeeded | RequestStatus.failed)) === 0
  //TODO - Get MAU and likes, dislikes number from API. (as of now they are hardcoded)
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection:"column",
        }}
      >

        <div style={{ display: "flex", flexDirection: "column" ,marginBottom:"8px", marginTop:"8px"}}>
          <span style={{ display: "block", fontSize: "14px", marginBottom:"6px" }}>
            Workspaces Onboarded
          </span>
          <div className="workspaceStyle">
            {showLoader ?
              <Skeleton
                style={{ borderRadius: '4px', marginTop: '10px', height: '10px', width: '70%' }}
                shape="rect"
                shimmer
              />
              : (<span id = "insonboarded">{props.insWorkspaces}</span>)
            }
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" ,marginBottom:"8px", marginTop:"8px"}}>
          <span style={{ display: "block", fontSize: "14px", marginBottom:"6px" }}>
            Workspaces Onboarded
          </span>
          <div className="workspaceStyle">
            {showLoader ?
              <Skeleton
                style={{ borderRadius: '4px', marginTop: '10px', height: '10px', width: '70%' }}
                shape="rect"
                shimmer
              />
              : (<span id = "recsonboarded">{props.recsWorkspaces}</span>)
            }
          </div>
        </div>


      </div>
    </>
  );
};
export const WorkspaceOnboarded = withErrorBoundary("WorkspaceOnboarded", WorkspaceOnboardedC);

