import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { getTaxonomy } from "../../../services/Taxonomy/Taxonomy.api";

export interface TaxonomyState {
    taxonomyDetails: {[key:string]: any},
    requestStatus: RequestStatus,
    error: string | undefined
}

const initialState: TaxonomyState = {
    taxonomyDetails: {},
    requestStatus: RequestStatus.idle,
    error: undefined
}

export const TaxonomySlice = createSlice({
    name: 'taxonomy',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getTaxonomy.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined;
            })
            .addCase(getTaxonomy.fulfilled, (state, action) => {
                state.requestStatus = RequestStatus.succeeded;
                state.taxonomyDetails = action.payload;
                state.error = undefined;
            })
            .addCase(getTaxonomy.rejected, (state, action: any) => {
                state.requestStatus = RequestStatus.failed;
                state.error = "Taxonomy: " + (action.payload["Message"] || action.error.message);
            })
    }
});

export const TaxonomySliceReducer = TaxonomySlice.reducer