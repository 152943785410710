import { Button } from "../../merge-rules/MergeRulesScope"
import { Dialog } from "../../../common/HarmonyEnablers";

export const CancelConfirmationPopup: React.FC<any> = (props:any) => {
  function handleRequestClose(event:any) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }
  }

  return (
    <>
      <Dialog
        heading="Discard Changes Confirmation"
        open
        onHeRequestClose={handleRequestClose}
        onHeAfterHide={({ target, currentTarget }) =>
          target === currentTarget ? props.closePopup(false) : null
        }
      >
        <p>There are some unsaved changes in this page.<br/> Are you sure you want to discard these changes?</p>
        <Button
          slot="footer"
          appearance="primary"
          onClick={() => {
            props.setCancelConfirmed(true);
            props.closePopup(false);
          }}
        >
          Yes
        </Button>
        <Button slot="footer" onClick={() => props.closePopup(false)}>
          No
        </Button>
      </Dialog>
    </>
  );
};
