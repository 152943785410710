import { useDispatch, useSelector } from "react-redux";
import { Button } from "../WorkflowsScope";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { Dialog } from "../../../common/HarmonyEnablers";
import { removeMergeRule } from "../merge-rules-redux/MergeRules.redux";
import { deleteMergeRule } from "../../../services/get-merge-rules/DeleteMergeRule.api";
import { getMergeRuleById, getMergeRuleByModel } from "../../../services/get-merge-rules/GetMergeRules.api";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Loader } from "../../../shared-components/loader/Loader";
import { removeMergeRuleBySubKey } from "../merge-rules-by-id-redux/MergeRuleBySubKey.redux";
import { removeMergeRuleById } from "../merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { removeMergeRuleFromModel, removeMergeRuleFromModelList } from "../merge-rules-by-id-redux/MergeRuleByModel.actions.redux";

export const DeleteConfirmation: React.FC<any> = (props: any) => {
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch();
  const { subKey, modelName } = props
  const { mergeRuleName, selectedMergeRuleTenantName, setSelectedMergeRuleId,setSelectedMergeRuleIds, setSelectedMergeRuleTenantName, openDeletePopUp, setsuccessNotif, setSuccessText, seterrorText, seterrornotif } = props;
  const deleteMergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.postMergeRuleStatus)

  function handleRequestClose(event: any) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      openDeletePopUp(false);
      // setSelectedMergeRuleTenantName("")
      // setSelectedMergeRuleId("")
      // setSelectedMergeRuleIds([])
    }
  }

  const handleDeleteMergeRule = async () => {
    var output = await dispatchApp(deleteMergeRule({ mergeRuleId: mergeRuleName }));
    if (output.payload === true) {
      if (subKey) {
        dispatch(removeMergeRuleById(mergeRuleName))
        dispatch(removeMergeRuleFromModelList(mergeRuleName))
        dispatch(removeMergeRuleFromModel(mergeRuleName))
        dispatch(removeMergeRuleBySubKey(mergeRuleName))
      } else {
        dispatch(removeMergeRule(mergeRuleName));
      }
      openDeletePopUp(false);
      setsuccessNotif(true);
      setSuccessText(`${props.mergeRuleName} deleted successfully.`)
      setSelectedMergeRuleTenantName("")
      setSelectedMergeRuleId("")
      setSelectedMergeRuleIds([])
    } else {
      openDeletePopUp(true);
      var message = output.payload?.Message || ""
      seterrorText(`${mergeRuleName}- merege rule deletion failed. ${message}`)
      seterrornotif(true);
    }
  }

  const handleCancel = () => {
    openDeletePopUp(false)
    // setSelectedMergeRuleTenantName("")
    // setSelectedMergeRuleId("")
    // setSelectedMergeRuleIds([])
  }
  return (
    <>
      <Dialog
        heading="Delete Confirmation"
        open
        onHeRequestClose={handleRequestClose}
        onHeAfterHide={({ target, currentTarget }) =>
          target === currentTarget ? openDeletePopUp(false) : null
        }
      >
        {deleteMergeruleStatus === RequestStatus.loading && 
          <Loader shape={"rect"} label={"Deleting MergeRule..."} />
        }
        {deleteMergeruleStatus !== RequestStatus.loading && 
        <>
        <p>Are you sure you want to delete the mergerule: {props.mergeRuleName}</p>
        <Button
          slot="footer"
          appearance="primary"
          onClick={handleDeleteMergeRule}
          id="deleteMergeRulebutton"
        >
          Delete
        </Button>
        <Button slot="footer" onClick={handleCancel} id="canceldeleteMergeRulebutton">
          Cancel
        </Button>
        </>
        }
      </Dialog>
    </>
  );
};
