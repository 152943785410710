import React from "react"
import { PreviewOutputColumns } from "./PreviewOutputColumn"
import { DataGrid, HoverCard } from "../merge-rules/MergeRulesScope"
import { PreviewPredictionColumns } from "./PreviewPredictionOutput"
import { PreviewRecommendationColumns } from "./PreviewRecommendationOutput"
import { PreviewInsightColumns } from "./PreviewInsightOutput"
import { Accordion, AccordionItem } from "../../common/HarmonyEnablers"

export const PreviewMergeRuleShowOutputPerPartitionKey: React.FC<any> = (props: any) => {
    const {eachTpid,distinctField, previewOutput, previewOutputPred,previewOutputIns, previewOutputRec} = props
    
    return (
        <>
            <Accordion>
            <AccordionItem> 
            <span slot="heading">Preview Details for {distinctField} {eachTpid}</span>
                        <Accordion>
                        
                        <AccordionItem>
                    <span slot="heading">Recommendations Generation Process</span>

                    <DataGrid fixedHeading={true}
                        rows={previewOutput} columns={PreviewOutputColumns} >
                        <span slot="no-records">No Recommendations Generated.</span>
                        {previewOutput.map((row1:any) => {
                            return (
                                <>
                                </>)
                        })}
                    </DataGrid>
                </AccordionItem>
                <AccordionItem>
                    <span slot="heading">Input Recommendations Participated in Merge</span>

                    <DataGrid fixedHeading={true}
                        rows={previewOutputPred} columns={PreviewPredictionColumns}  >
                        <span slot="no-records">No Input Recommendations found.</span>
                        {previewOutputPred.map((row2:any) => {
                            return (
                                <>
                                    <HoverCard slot={`field3-${row2.id}`} key={`field3-${row2.id}`} placement={"start"} fixedPlacement={true} heading="">
                                        <div role="button" slot="anchor" key={`field3-${row2.id}`}>{row2.cells.field3 === null || row2.cells.field3 === '' ? '' : row2.cells.field3 != null && row2.cells.field3.length > 30 ? row2.cells.field4.substring(0, 30) + "..." : row2.cells.field3}</div>
                                        {row2.cells.field3 != undefined &&
                                            <code style={{ color: "black" }}>{row2.cells.field3.split(",").map((eachRow2:any) => {
                                                return <div>{eachRow2}</div>
                                            })}
                                            </code>
                                        }
                                        {row2.cells.field3 == undefined &&
                                            <code style={{ color: "black" }}>
                                            </code>
                                        }
                                    </HoverCard>
                                    <HoverCard  slot={`field4-${row2.id}`} key={`field4-${row2.id}`} placement={"start"} fixedPlacement={true} heading="">
                                        <div role="button" slot="anchor" key={`field4-${row2.id}`}>{row2.cells.field4 === null || row2.cells.field4 === '' ? '' : row2.cells.field4 != null && row2.cells.field4.length > 30 ? row2.cells.field4.substring(0, 30) + "..." : row2.cells.field4}</div>
                                        {row2.cells.field4 != undefined &&
                                            <code style={{ color: "black" }}>{row2.cells.field4.split(",").map((eachRow21:any) => {
                                                return <div>{eachRow21}</div>
                                            })}
                                            </code>
                                        }
                                        {row2.cells.field4 == undefined &&
                                            <code style={{ color: "black" }}>
                                            </code>
                                        }
                                    </HoverCard>
                                </>)
                        })}
                    </DataGrid>
                </AccordionItem>
                <AccordionItem>
                    <span slot="heading">Insights Participated in Merge</span>

                    <DataGrid fixedHeading={true}
                        rows={previewOutputIns} columns={PreviewInsightColumns}  >
                        <span slot="no-records">No Input Insights found.</span>
                        {previewOutputIns.map((row:any) => {
                            return (
                                <>
                                </>)
                        })}
                    </DataGrid>
                </AccordionItem>
                <AccordionItem>
                    <span slot="heading">Output Recommendations (Existing/Newly Generated)</span>
                    <DataGrid fixedHeading={true}
                        rows={previewOutputRec} columns={PreviewRecommendationColumns}  >
                        <span slot="no-records">No Output Recommendations found.</span>
                        {previewOutputRec.map((row:any) => {
                            return (
                                <>
                                    <HoverCard slot={`field4-${row.id}`} key={`field4-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                                        <div role="button" slot="anchor" key={`field4-${row.id}`}>{row.cells.field4 === null || row.cells.field4 === '' ? '' : row.cells.field4 != null && row.cells.field4.length > 30 ? row.cells.field4.substring(0, 30) + "..." : row.cells.field4}</div>
                                        {row.cells.field4 != undefined &&
                                            <code style={{ color: "black" }}>{row.cells.field4.split(",").map((eachRow:any) => {
                                                return <div>{eachRow}</div>
                                            })}
                                            </code>
                                        }
                                        {row.cells.field4 == undefined &&
                                            <code style={{ color: "black" }}>
                                            </code>
                                        }
                                    </HoverCard>
                                    <HoverCard slot={`field5-${row.id}`} key={`field5-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                                        <div role="button" slot="anchor" key={`field5-${row.id}`}>{row.cells.field5 === null || row.cells.field5 === '' ? '' : row.cells.field5 != null && row.cells.field5.length > 30 ? row.cells.field5.substring(0, 30) + "..." : row.cells.field5}</div>
                                        {row.cells.field5 != undefined &&
                                            <code style={{ color: "black" }}>{row.cells.field5.split(",").map((eachRow:any) => {
                                                return <div>{eachRow}</div>
                                            })}
                                            </code>
                                        }
                                        {row.cells.field5 == undefined &&
                                            <code style={{ color: "black" }}>
                                            </code>
                                        }
                                    </HoverCard>
                                </>)
                        })}
                    </DataGrid>
                </AccordionItem>
                            </Accordion>
                            </AccordionItem>
                
            </Accordion>
        </>
    )
}