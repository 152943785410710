
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const DataPublishC: React.FC<any> = (props: any) => {

  return (
    <>
    
    <div className="help-mainHeading">
          <span id="publishTag" className="help-subheading">Map data & publish </span>

          <div style={{marginTop:"8px"}}>
          <span className="help-heading">Summary:</span>
          <span className="help-content"> {onboarding.publish.summary} </span>
          </div>

          <div style={{marginTop:"8px"}}>
          <span className="help-heading">Finalize the dataset to publish:</span>
          <ol>
            {onboarding.publish.finalizedata.map(info =>
              <li key={info} style={{marginBottom:"6px"}}><span className="help-content">{info}</span></li>
            )}
          </ol>
          </div>

          <div style={{marginTop:"8px"}}>
          <span className="help-heading">Map fields from input datasets:</span>
          <span className="help-content"> {onboarding.publish.mapfields} </span>
          <ol>
            <li key = "recsinfo" style={{marginBottom:"6px", fontWeight:"500"}}><span className="help-content" style={{fontWeight:"500"}}>Recommendation fields required mapping</span></li>
            <ul>
            {onboarding.publish.recfields.map(data =>
              <li key={data} style={{marginBottom:"6px"}}><span className="help-content">{data}</span></li>
            )}
          </ul>
          <li key = "insinfo" style={{marginBottom:"6px", fontWeight:"500"}}><span className="help-content" style={{fontWeight:"500"}}>Insight fields required mapping</span></li>
              <ul>
              {onboarding.publish.insfields.map(data =>
                <li key={data} style={{marginBottom:"6px"}}><span className="help-content">{data}</span></li>
              )}
          </ul>
            </ol>
            <span className="help-content"> Ensure accurate mapping to generate meaningful insights within Nebula. </span>
          </div>

          <div style={{marginTop:"8px"}}>
          <span className="help-heading" >Generate delta or full refresh on schedule run</span>
          <div>
          <span className="help-content">During each scheduled run, Nebula provides the option to choose between a delta refresh or a full refresh of the data.</span>
          <ul>
            {onboarding.publish.delta.map(data =>
              <li key={data} style={{marginBottom:"6px"}}><span className="help-content">{data}</span></li>
            )}
          </ul>
          </div>
            {/* <span className="help-content">You can configure these settings in the 'Add Attributes and Update Constraints' section, providing flexibility and control over data updates.</span> */}
          <HelpNote text = "You can configure these settings in the 'Add Attributes and Update Constraints' section, providing flexibility and control over data updates."/>
          </div>

          <div style={{marginTop:"8px"}}>
          <span className="help-heading">Add attributes and update constraints</span>
          <div>
          <span className="help-content">{onboarding.publish.attributes}</span>
          </div>
          </div>

          <div style={{marginTop:"8px"}}>
          <span className="help-heading">Publish to Nebula / Action center / Custom</span>
          <ul>
            {onboarding.publish.publishto.map(data =>
              <li key={data} style={{marginBottom:"6px"}}><span className="help-content">{data}</span></li>
            )}
          </ul>
          <div id = "onboarding_merge">
          {/* <span className="help-content">Choose the most suitable option based on your specific requirements for seamless data presentation and utilization.</span> */}
          <HelpNote text = "Choose the most suitable option based on your specific requirements for seamless data presentation and utilization."/>
          </div>
          </div>

          </div>
          <hr/>

    </>
  );
};
export const DataPublish = withErrorBoundary("DataPublish content", DataPublishC);