import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "../edit-merge-rules/EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "../edit-merge-rules/EditMergeRulesFooter";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { MergeRuleSetDataGridRow, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { useNavigate } from 'react-router-dom';
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { EditMergeRulesBody } from "./EditMergeRulesBody";
import { EditMergeRuleBreadcrumb } from "./EditMergeRuleBreadcrumb";
import './mergerules-styles.css'
import { getWorkflows, getWorkflowsBySubKeys } from "../../services/GetWorkflows.api";
import { getTypeByKeyMapping } from "../../services/GetTypeMapping.api";
import { EditMergeRuleMessageBar } from "./EditMergeRuleMessageBar";
import { resetMergeRule, resetMergeRuleSet, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { Button, Icon } from "../workflows/WorkflowsScope";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const EditMergeRulesC: React.FC<any> = (props: any) => {
    // redux states
    const { source, defaultMergeRuleId, setOpenEditMergeRule, openEditMergeRule } = props
    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation();
    const navigate = useNavigate()

    var { mergeRuleId, type } = location.state;

    const userTeams = useSelector(permissionsSelector).userTeams;
    const isExpanded = useSelector((state: RootState) => state.previewWorkflow.isExpanded)
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    const isSideNavOpen = useSelector((state: RootState) => state.workflowById.isSideNavOpen)
    const mergeRuleRequestStatus = useSelector((state: RootState) => state.mergeRuleById.status);
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
    const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);
    const workflowsBySubKeysStatus = useSelector((state: RootState) => state.workflowsBySubKeys.status);
    const workflow = useSelector((state: RootState) => state.workflowById.workflow);
    const typeMappingRequestStatus = useSelector((state: RootState) => state.typeMappingConfig.status);
    const personaMappingRequestStatus = useSelector((state: RootState) => state.personaMappingConfig.status);
    const typeMappingByKey = useSelector((state: RootState) => state.typeMappingConfigByKey.typeMappingByKey.typeMapping);
    const personaMapping = useSelector((state: RootState) => state.personaMappingConfig.personaMapping);
    const [filteredRows, setFilteredRows] = useState([] as MergeRuleSetDataGridRow[]);
    const unsavedChanges = useSelector((state: RootState) => state.mergeRuleById.unsavedChanges);
    const [data, setData] = useState(mergeRule);
    const [enableSave, setEnableSave] = useState(false)

    if (source === "default") {
        mergeRuleId = defaultMergeRuleId
    }

    useEffect(() => {
        if (source === "default") {
            scrollToTop()
        }
        dispatch(resetMergeRule())
        dispatch(getMergeRuleById(mergeRuleId))
        dispatch(resetMergeRule())
        dispatch(resetMergeRuleSet())
    }, [mergeRuleId, dispatch])

    useEffect(() => {
        if (mergeRule.type) {
            dispatch(getTypeByKeyMapping(mergeRule.type))
        }
    }, [mergeRule.type])

    useEffect(() => {
        if (mergeRule.subscriptionKey) {
            dispatch(getWorkflowsBySubKeys({ subKeys: mergeRule.subscriptionKey }))
        }
    }, [mergeRule.subscriptionKey])


    useEffect(() => {
        setData(mergeRule)
    }, [mergeRule])

    useEffect(() => {
        if (data.mergeRuleId != null && data.mergeRuleName != null && data.tenantName != null && data.type != null && data.primaryMergeRuleSet != null && data.primaryMergeRuleSet.length >= 1 &&
            data.primaryMergeRuleSet[0].mergeModel != null && data.primaryMergeRuleSet[0].mergeModel.length > 0) {
            setEnableSave(true)
        } else {
            setEnableSave(false)
        }
        setFilteredRows(mergeRuleSetItemSelectorData(data, workflowsBySubKeys))
    }, [data])

    if (mergeRuleRequestStatus === RequestStatus.loading) {
        return <Loader className='mergerule-loader' shape='rect' label="Loading MergeRule..." />
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onCancelIconClick = (navMenuItemType: NavMenuItemType) => {
        dispatch(resetMergeRule())
        dispatch(resetMergeRuleSet())
        navigate("/" + NavMenuItemType[navMenuItemType]);
    };


    var defaultClassName = `mergerule-wrapper-workflow-default ${isExpanded ? 'expanded' : ''} ${isNavPanelOpen ? '' : 'nav-collapsed'} ${isSideNavOpen ? '' : 'sidenav-collapsed'}`

    return (
        <>
            <EditMergeRuleMessageBar />

            {source !== "default" &&
                <EditMergeRuleBreadcrumb mergeRuleName={mergeRuleId} />
            }
            <div>
                <div>
                    {source !== "default" &&
                        <EditMergeRulesHeader id="editMergeRule" source={source} mergeRuleName={mergeRuleId} />
                    }
                    {!openEditMergeRule &&
                        <div className="mergerule-cancel-button">
                            <Button appearance="link"
                                onClick={() => {
                                    if (unsavedChanges) {
                                        window.confirm("Your unsaved edits will be discarded.") &&
                                            onCancelIconClick(NavMenuItemType.MergeRules);
                                    } else {
                                        onCancelIconClick(NavMenuItemType.MergeRules);
                                    }
                                }}
                            >
                                <Icon label="cancel button of merge rules" name="calculatormultiply" style={{ width: "24px", height: "24px" }} />
                            </Button>
                        </div>
                    }
                </div>

            </div>

            {source === "default" &&
                <div className='mergerule-body-overall-default'>
                    <div className={`mergerule-body-default`}>
                        <EditMergeRulesBody source={source} mergeRuleId={mergeRuleId} />
                    </div>
                    <EditMergeRulesFooter source={source} setOpenEditMergeRule={setOpenEditMergeRule} openEditMergeRule={openEditMergeRule} />
                </div>
            }
            {source !== "default" && <>
                <div className={`mergerule-body-container`}>
                    <EditMergeRulesBody source={source} mergeRuleId={mergeRuleId} />
                </div>
                <EditMergeRulesFooter source={source} setOpenEditMergeRule={setOpenEditMergeRule} openEditMergeRule={openEditMergeRule} />
            </>}
        </>
    )
}
const EditMergeRules = withErrorBoundary("Edit merge rules component", EditMergeRulesC);
export default EditMergeRules;