import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model"
import { previewInsightsOData, previewInsightsVanilla } from "../../../services/PostInsightsApiPreview.api"



interface InsightsPreviewObject {
    insightsAPIOutputOData : Object 
    insightsAPIOutputVanilla : Object , 
    statusOData: RequestStatus,
    statusVanilla: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean
}

const initialState: InsightsPreviewObject = {
    insightsAPIOutputOData: {} as JSON,
    insightsAPIOutputVanilla: {} as JSON,
    statusOData: RequestStatus.idle,
    statusVanilla: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false
}

export const previewInsightsApiSlice = createSlice({
    name: 'apiPreviewInsights',
    initialState,
    reducers: {
        resetInsightsPreviewObject: (state) => {
            state.insightsAPIOutputOData = {} as JSON
            state.insightsAPIOutputVanilla = {} as JSON
            state.statusOData = RequestStatus.idle
            state.statusVanilla = RequestStatus.idle
            state.error = ''
            state.updateSuccessStatus = false
        }
    },
    extraReducers(builder) {
        builder
        .addCase(previewInsightsOData.pending, (state, action) => {
            state.statusOData = RequestStatus.loading
        })
        .addCase(previewInsightsOData.fulfilled, (state, action) => {
            state.statusOData = RequestStatus.succeeded
            const payload = action.payload
            if (payload) {

                state.insightsAPIOutputOData = payload
            }
            state.error = undefined
        })
        .addCase(previewInsightsOData.rejected, (state, action) => {
            state.statusOData = RequestStatus.failed
            state.error = action.error.message
        })
        .addCase(previewInsightsVanilla.pending, (state, action) => {
            state.statusVanilla = RequestStatus.loading
        })
        .addCase(previewInsightsVanilla.fulfilled, (state, action) => {
            state.statusVanilla = RequestStatus.succeeded
            const payload = action.payload
            if (payload) {

                state.insightsAPIOutputVanilla = payload
            }
            state.error = undefined
        })
        .addCase(previewInsightsVanilla.rejected, (state, action) => {
            state.statusVanilla = RequestStatus.failed
            state.error = action.error.message
        })
    }
})

export const apiPreviewInsightsReducer = previewInsightsApiSlice.reducer