import { useDispatch, useSelector } from "react-redux";
import { Button } from "../WorkflowsScope";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { Dialog, Select, TextField, Option, Icon } from "../../../common/HarmonyEnablers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { emptyMergeRule } from "../../../model/merge-rules/MergeRule.model";
import { getMergeRuleById } from "../../../services/get-merge-rules/GetMergeRules.api";
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { postMergeRule } from "../../../services/get-merge-rules/PostMergeRule.api";
import { addMergeRule } from "../merge-rules-redux/MergeRules.redux";
import { addMergeRuleBySubKey } from "../merge-rules-by-id-redux/MergeRuleBySubKey.redux";
import { AppPersona } from "../../../model/app-persona/AppPersona.model";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Loader } from "../../../shared-components/loader/Loader";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { addMergeRulesByModel, updateMergeRuleByModel } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux";

export const ClonePopUp: React.FC<any> = (props: any) => {
  const navigate = useNavigate();
  const dispatchApp = useDispatch<AppDispatch>();
  const dispatch = useDispatch();
  const { source } = props

  const mergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.status)
  const postMergeruleStatus = useSelector((state: RootState) => state.mergeRuleById.postMergeRuleStatus)

  const { selectedMergeRuleTenantName, setSelectedMergeRuleIds } = props;
  const userTeams = useSelector(permissionsSelector).userTeams;
  const [enableSave, setEnableSave] = useState(false);
  const parentMergeRuleId = props.selectedMergeRuleId;
  const workflowSubsKey = useSelector((state: RootState) => state.workflowsBySubKeys.list)
  const { openClonePopUp, setsuccessNotif, setSuccessText, setSelectedMergeRuleId, mergeRuleName, seterrorText, seterrornotif } = props
  const [typeMapping, setTypeMapping] = useState([] as string[]);
  const parentMergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const [data, setData] = useState(emptyMergeRule)
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
  const personaMapping = useSelector((state: RootState) => state.personaMappingConfig.personaMapping);
  const [newMergeRuleName, setNewMergeRuleName] = useState(mergeRuleName + "-Clone")
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (parentMergeRuleId) {
      dispatchApp(getMergeRuleById(parentMergeRuleId))
    }
  }, [parentMergeRuleId, dispatchApp]);

  useEffect(() => {
    if (newMergeRuleName) {
      setEnableSave(true)
    } else { setEnableSave(false) }
  }, [newMergeRuleName])

  const handleCloneMergeRule = async () => {
    var tenantName = allAppsList.find((item) => item.subscriptionKey === parentMergeRule.subscriptionKey)?.appName || ""

    var newMergeRule = {
      ...parentMergeRule,
      modifiedBy: userDetails?.localAccountId,
      tenantName: tenantName,
      version: "1",
      mergeRuleName: newMergeRuleName,
      mergeRuleId: newMergeRuleName
    }

    var output = await dispatchApp(postMergeRule({ mergeRule: newMergeRule }));
    if (output.payload === true) {
      openClonePopUp(false);
      setsuccessNotif(true);
      setSuccessText(`${newMergeRuleName} cloned successfully.`)
      if(source === "default") {
      setSelectedMergeRuleId("");
      setSelectedMergeRuleIds([])
      selectedMergeRuleTenantName("")
      }
      if (source) {
        dispatch(addMergeRuleBySubKey(newMergeRule))
        dispatch(addMergeRulesByModel(newMergeRule))
        dispatch(updateMergeRuleByModel(newMergeRule))
      } else {
        dispatch(addMergeRule(newMergeRule))
      }
      if (!source) {
        navigate("/" + NavMenuItemType[NavMenuItemType.MergeRules] + "/edit", { state: { mergeRuleId: newMergeRule.mergeRuleId } })
      }
    } else {
      openClonePopUp(true);
      var message = output.payload.Message.includes("MergeRule already exists") ? output.payload.Message + ", please provide a different name." : ""

      setErrorMsg(message)
      seterrorText(`${newMergeRuleName}- ${output.payload.Message}${message}`)
      seterrornotif(true);
    }
  }

  const handleRequestClose = (event: any) => {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      props.openClonePopUp(false);
      // selectedMergeRuleTenantName("")
      // setSelectedMergeRuleId("")
      // setSelectedMergeRuleIds([])
    }
  };

  return (
    <>
      <Dialog heading="Duplicate MergeRule" open onHeRequestClose={handleRequestClose}
        onHeAfterHide={
          ({ target, currentTarget }) => (target === currentTarget ? props.openClonePopUp(false) : null)
        }
      >
        {mergeruleStatus === RequestStatus.loading &&
          <Loader shape={"rect"} label={"Loading..."} />
        }
        {postMergeruleStatus === RequestStatus.loading &&
          <Loader shape={"rect"} label={"Cloning MergeRule..."} />
        }
        {mergeruleStatus !== RequestStatus.loading && postMergeruleStatus !== RequestStatus.loading &&
          <>
            <p style={{ fontSize: "14px" }}> Cloning existing merge rules and keeping the configuration same will result in duplicate recommendations. Are you sure you want to clone the mergerule: <b>{props.mergeRuleName}</b>?</p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <div>MergeRuleName<Icon className='required-icon' name='actioncenterasterisk'></Icon></div>
              <TextField className={'Text-fields'} label="" value={newMergeRuleName} validationMessage={errorMsg} autofocus onHeInput={(e: any) => { setErrorMsg(""); setNewMergeRuleName(e.target.value) }}></TextField>
            </div>
            <span style={{ fontSize: "12px", color: "red" }}>{errorMsg}</span>

            <Button id="duplicatemergerulebutton" slot="footer" appearance="primary" disabled={!enableSave} onClick={() => {
              handleCloneMergeRule();
            }}>
              Duplicate
            </Button>
            <Button id="cancelduplicatemergerulebutton" slot="footer" onClick={() => {
              props.openClonePopUp(false)
              // selectedMergeRuleTenantName("")
              // setSelectedMergeRuleId("")
              // setSelectedMergeRuleIds([])
            }}>
              Cancel
            </Button>
          </>
        }
      </Dialog>
    </>
  );
};
