import { createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogueSchemaObject } from '../model/configModels/CatalogueSchemaObject.model';
import { postRequest } from './utils/PostRequest';
import { RootState } from '../root-redux/RootState';
import { postRequestReleaseManagement } from './utils/PostRequestReleaseManagement';



  export const updateCatalogueConfig = createAsyncThunk<boolean, CatalogueSchemaObject, { state: RootState }>('catalogueConfig/updateCatalogueConfig', async (payload, {getState}) => {
    const selectedEnv = getState().env.selectedEnvItem
   
    const response = await postRequest<boolean, CatalogueSchemaObject>(`catalogue/${selectedEnv}/updateCatalogue`, payload)
    return response.data;
  })

  export const updateCatalogueConfig_uat = createAsyncThunk<boolean, CatalogueSchemaObject>('catalogueConfig/updateCatalogueConfig_uat', async (payload) => {
   
    const response = await postRequestReleaseManagement<boolean, CatalogueSchemaObject>("catalogue/uat/updateCatalogue", payload)
    return response.data;
  })

  export const updateCatalogueConfig_prod = createAsyncThunk<boolean, CatalogueSchemaObject>('catalogueConfig/updateCatalogueConfig_prod', async (payload) => {
    const response = await postRequestReleaseManagement<boolean, CatalogueSchemaObject>("catalogue/prod/updateCatalogue", payload)
    return response.data;
  })