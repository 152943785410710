import { APIResponseMappingObject } from '../../model/apiConfig/APIResponseMappingObject.model';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { EdmEntityTypeSettings } from '../../model/apiConfig/EdmEntityTypeSettings.model';
import { postRequestReleaseManagement } from '../utils/PostRequestReleaseManagement';

export const propagateRoute = createAsyncThunk<boolean, { routeType: string, payload: EdmEntityTypeSettings, env: string }>('apiConfig/propagateRoute', async ({routeType, payload, env }) => {
    const response = await postRequestReleaseManagement<boolean, EdmEntityTypeSettings>(`${env}/APIResponseMapping/${routeType}/propagate`, payload)
    return response.data;
  })

