import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "./utils/GetRequest"
import { AppPersona } from "../model/app-persona/AppPersona.model"
import { RootState } from "../root-redux/RootState";
import { getRequestReleaseManagement } from "./utils/GetRequestReleaseManagement";

export const getAppPersonas = createAsyncThunk<AppPersona[], null, {state: RootState}>('apppersonamappings/getAppPersonas', async (_, { getState }) => {
  const selectedEnv = getState().env.selectedEnvItem  
  const response = await getRequest<AppPersona[]>(`${selectedEnv}/apppersonamappings`);
    return response.data
  });

  export const getAppPersonas_dev = createAsyncThunk<AppPersona[]>('dev/apppersonamappings/getAppPersonas', async () => {
    const response = await getRequestReleaseManagement<AppPersona[]>('dev/apppersonamappings');
    return response.data
  });

  export const getAppPersonas_uat = createAsyncThunk<AppPersona[]>('uat/apppersonamappings/getAppPersonas', async () => {
    const response = await getRequestReleaseManagement<AppPersona[]>('uat/apppersonamappings');
    return response.data
  });

  export const getAppPersonas_prod = createAsyncThunk<AppPersona[]>('prod/apppersonamappings/getAppPersonas', async () => {
    const response = await getRequestReleaseManagement<AppPersona[]>('prod/apppersonamappings');
    return response.data
  });


