import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getTestApi, getTestApiDuplicate } from '../../services/GetTestApi.api'

interface TestApiGetEntityIdsState {
    data: Object,
    status: RequestStatus,

}

const initialState: TestApiGetEntityIdsState = {
    data: '',
    status: RequestStatus.idle
}
export const getTestApiGetEntityIdsSlice = createSlice({
    name: 'gettestapientityids',
    initialState,
    reducers: {
        resetTestApiDup: (state) => {
            state.data = ''
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getTestApiDuplicate.pending, (state, action) => {
                state.data =  ''
                state.status= RequestStatus.loading
            })
            .addCase(getTestApiDuplicate.fulfilled, (state, action) => {
                const payload = action.payload
                if (payload.data?.OperationId) {
                    state.status= RequestStatus.failed
                    state.data = ''
                } else {
                    state.status= RequestStatus.succeeded
                    state.data = payload.data
                }
            })
            .addCase(getTestApiDuplicate.rejected, (state, action) => {
                state.data = ''
                state.status= RequestStatus.failed
            })
    }
})

export const { resetTestApiDup } = getTestApiGetEntityIdsSlice.actions


export const getTestApiGetEntityIdsSliceReducer = getTestApiGetEntityIdsSlice.reducer