import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid"
import { DeltaMap } from "../../model/workflows/Delta.model"
import { PublishMap } from "../../model/workflows/Publish.model"
import { MergeRule } from "../../model/merge-rules/MergeRule.model"
import { AppPersona } from "../../model/app-persona/AppPersona.model"

export interface DefaultMergeRuleGridRow extends Row {
  id: string,
  cells: {
    field1: string
    field2: string
    field3: string
    field4: string,
    field5: string
  }
}

export const columns = [
  {
    field: 'field1',
    content: 'MergeRule Name',
    sortable: true,
    display: {
      width: '200px'
    }
  },
  {
    field: 'field2',
    content: 'Merge On Recommendation',
    sortable: true,
    display: {
      width: '200px'
    }
  },
  {
    field: 'field3',
    content: 'Merge On Fields',
    sortable: true,
    display: {
      width: '200px'
    }
  },
  {
    field: 'field4',
    content: 'Tenant',
    sortable: true,
    display: {
      width: '200px'
    }
  },
  {
    field: 'field5',
    content: 'Family',
    display: {
      width: '200px'
    }
  },
];

export const addedModelsItemSelectorData = (defaultMergeRule: MergeRule, allAppsList: AppPersona[]): DefaultMergeRuleGridRow[] => {
  var i = 1;
  var transformedToRowItems: DefaultMergeRuleGridRow[] = []
  if (defaultMergeRule != undefined) {
    transformedToRowItems.push(
      {
        id: "1",
        cells: {
          field1: defaultMergeRule?.mergeRuleName || '',
          field2: defaultMergeRule?.primaryMergeRuleSet?.length > 0 ?  defaultMergeRule?.primaryMergeRuleSet[0]?.mergeModel.join(",") || '' : "",
          field3: defaultMergeRule?.primaryMergeRuleSet?.length > 0 ?  defaultMergeRule?.primaryMergeRuleSet[0]?.mergeOn.fields.default.join(",") || '' : "",
          field4: defaultMergeRule?.tenantName || allAppsList.find(item => item.subscriptionKey === defaultMergeRule.subscriptionKey)?.appName || "",
          field5: defaultMergeRule?.type
        }
      })
  }
  return transformedToRowItems
}