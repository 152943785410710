import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Accordion, AccordionItem, RadioGroup, Radio, FlyInPanel, Tooltip } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateCurrentMergeRuleSet, updateEnableSave, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { MergeOverride } from "../../model/merge-rules/MergeOverride.model";
import FilterQueryBuilder from "./FilterQueryBuilder";
import { CommandQueryBuilder } from "./CommandQueryBuilder";
import { OutputRow } from "../../model/merge-rules/OutputRow.model";
import { LearnMoreColumns, LearnMoreData, LearnMoreDataGrid, OutputRowDataGrid, addOutputRowData, convertQueryToFilterFormat, outputRowColumns } from "./MergeRuleSetOutputColumns";
import { v4 as uuidv4 } from 'uuid';
import informationConfig from "../../shared-content/inputDescription";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import { addRecFields, sortByModelsNameForFilter, sortByWorkflowName } from "./MergeRuleSetHelper";
import { CommandQueryBuilderAllPredictions } from "./CommandQueryBuilderAllPredictions";

export const MergeRuleSetOutput: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation();
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list)
  const [currentCommandFilter, setCurrentCommandFilter] = useState("")
  const [currentCommandString, setCurrentCommandString] = useState("")
  const [currentCommand, setCurrentCommand] = useState({} as OutputRow)
  const [allCommandsRows, setAllCommandsRows] = useState([] as OutputRowDataGrid[])
  const [allCommands, setAllCommands] = useState([] as OutputRow[])
  const [openCommand, setOpenCommand] = useState(false)
  const [fieldsList, setFieldsList] = useState([] as [string, string][])
  const [currentCommandType, setCurrentCommandType] = useState("")
  const [leanrMoreDate, setLearnMoreData] = useState(LearnMoreData())
  const [openLearnMore, setOpenLearnMore] = useState(false)
  const [commandGenerationType, setCommandGenerationType] = useState("")
  const [plainModelList, setPlainModelList] = useState([] as string[])
  const [plainFieldList, setPlainFieldList] = useState([] as string[])
  const [selectedPlainModel, setselectedPlainModel] = useState("")
  const [selectedPlainModelToBeShown, setselectedPlainModelToBeShown] = useState("")
  const [selectedPlainField, setselectedPlainField] = useState("")
  const [constantVal, setConstantVal] = useState("")
  const [isDisabled, setIsDisabled] = useState(false);
  const [useAllPredictionsForMerge, setUseAllPredictionsForMerge] = useState(false);

  useEffect(() => {
    var isDisabledLocal = false
    var localOutput = [...currentMergeRuleSet.output]
    var outputObj = localOutput.find(x => { return x.id === currentCommand.id })
    if (commandGenerationType === "constant") {
      isDisabledLocal = constantVal === "" || (outputObj !== undefined && outputObj.command === currentCommand.command && outputObj.commandForDisplay === currentCommand.command && outputObj.jsonQueryStringCommand === "")
    } else if (commandGenerationType === "plain") {
      isDisabledLocal = !selectedPlainField || !selectedPlainModel || !selectedPlainModelToBeShown || (outputObj !== undefined && outputObj.command === "{" + selectedPlainModelToBeShown + "." + selectedPlainField + "}"
        && outputObj.commandForDisplay === selectedPlainModel + "." + selectedPlainField && outputObj.jsonQueryStringCommand === "")
    }
    setIsDisabled(isDisabledLocal)
  }, [currentCommand, constantVal, selectedPlainField, selectedPlainModel, selectedPlainModelToBeShown])

  useEffect(() => {
    setAllCommandsRows(addOutputRowData(currentMergeRuleSet.output, workflowsBySubKeys))
  }, [currentMergeRuleSet.output])

  useEffect(() => {
    var workflow = workflowsBySubKeys.filter(x => { return mergeRule.sourceFilter.includes(x.modelName) })
    var localFieldsList: [string, string][] = []
    var localFields: string[] = []
    workflow.forEach(eachWorkflow => {
      if (eachWorkflow.fields && Object.keys(eachWorkflow.fields).length > 0) {
        Object.keys(eachWorkflow.fields).map((item) => {
          if (!localFields.includes(item)) {
            localFields.push(item)
            localFieldsList.push([item, eachWorkflow.fields[item]])
          }
        })
      }
    });
    localFieldsList = addRecFields(localFieldsList, localFields);
    localFieldsList = sortByModelsNameForFilter(localFieldsList)
    setFieldsList(localFieldsList);
  }, [workflowsBySubKeys, currentMergeRuleSet])

  const fillModelsList = () => {
    var workflow = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })
    var localModels: string[] = []
    workflow = sortByWorkflowName(workflow)
    workflow.forEach(eachWorkflow => {
      if (!localModels.includes(eachWorkflow.config.workflowName)) {
        localModels.push(eachWorkflow.config.workflowName)
      }
    });
    setPlainModelList(localModels);
  }

  useEffect(() => {
    fillModelsList();
  }, [workflowsBySubKeys])

  const fillFieldsList = (workflowSelected: string) => {
    var workflow = workflowsBySubKeys.filter(x => { return x.config.workflowName === workflowSelected })
    var localFields = [] as string[]

    if (workflow.length > 0) {
      var eachWorkflow = workflow[0]
      if (eachWorkflow.fields && Object.keys(eachWorkflow.fields).length > 0) {
        Object.keys(eachWorkflow.fields).map((item) => {
          if (!localFields.includes(item)) {
            localFields.push(item)
          }
        })
      }
    }
    setPlainFieldList(localFields)
  }

  useEffect(() => {
    if (selectedPlainModel) {
      fillFieldsList(selectedPlainModel)
    }
  }, [selectedPlainModel])

  const handlecommandGenerationType = (e: any) => {
    if (commandGenerationType !== e.target.value) {
      if (commandGenerationType) {
        setCurrentCommand({
          ...currentCommand,
          command: "",
          jsonQueryStringCommand: "",
          commandForDisplay: ""
        })
      }
      if (e.target.value !== "constant") {
        setConstantVal("")
      }
      if (e.target.value !== "plain") {
        setselectedPlainField("")
        setselectedPlainModel("")
        setselectedPlainModelToBeShown("")
        setPlainFieldList([])
      }
      setCommandGenerationType(e.target.value)
    }
  }

  const handleQueryReturn = (query: string, jsonTree: string) => {
    setCurrentCommandFilter(query)
    setCurrentCommandString(jsonTree)
    var convertedQuery = convertQueryToFilterFormat(query, workflowsBySubKeys)

    var localCommand = {
      ...currentCommand,
      command: convertedQuery,
      jsonQueryStringCommand: jsonTree,
      commandForDisplay: query
    }
    var localOutput = [...currentMergeRuleSet.output]

    var outputObj = localOutput.find(x => { return x.id === localCommand.id })
    if (outputObj) {
      var index = localOutput.indexOf(outputObj)
      if (index !== -1) {
        localOutput[index] = localCommand
      }
    } else {
      localOutput = [...localOutput, localCommand]
    }

    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, output: localOutput }))
    setCurrentCommand({} as OutputRow)
    setOpenCommand(false)
    setCurrentCommandType("")
  }

  const handlePlainField = (e: any) => {
    setselectedPlainField(e.target.textContent)
  }

  const handlePlainModel = (e: any) => {
    setselectedPlainModel(e.target.textContent)
    var workflow = workflowsBySubKeys.filter(x => { return e.target.textContent === x.config.workflowName })
    if (workflow.length > 0) {
      setselectedPlainModelToBeShown(workflow[0].modelName)
    }
    fillFieldsList(e.target.textContent)
  }

  const handleConstantValue = (e: any) => {
    setConstantVal(e.target.value)
  }

  const handleRequestClose = (event: any) => {
    if (event.target !== event.currentTarget || event.target === event.currentTarget && event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      setOpenCommand(false)
      setCurrentCommand({} as OutputRow)
      setCurrentCommandString("")
      setCurrentCommandType("")
      setConstantVal("")
      setselectedPlainField("")
      setselectedPlainModel("")
      setselectedPlainModelToBeShown("")
    }
  }

  const handleBasicDetailsChange = (e: any) => {
    setCurrentCommand({ ...currentCommand, field: e.target.textContent, command: "", commandForDisplay: "", jsonQueryStringCommand: "" })
    var type = fieldsList.filter(x => { return x[0] === e.target.textContent })[0]
    if (type && type[1]) {
      setCurrentCommandType(type[1])
    } else {
      setCurrentCommandType("String")
    }
  }

  const handleAddCommand = () => {
    var newCommand = new OutputRow()
    newCommand.id = uuidv4().toString()
    setCommandGenerationType("constant")
    setCurrentCommand(newCommand)
    setUseAllPredictionsForMerge(false);
    setOpenCommand(true)
  }

  const handleEditCommand = (row: any) => {
    var commands = ["FIRST", "LIST", "CONCAT", "MAX", "MIN", "DATEADD", "DATEDIFF", "REPLACE", "SUBSTRING", "JOIN"]
    var local = currentMergeRuleSet.output.filter(x => { return x.id === row.id })[0];
    if(local.command.includes("JOIN")){
      setUseAllPredictionsForMerge(true);
    }
    else
    {
      setUseAllPredictionsForMerge(false);
    }

    if (Object.keys(local).length > 0 && local.command && commands.find(x => { return local.command.trim().startsWith(x) })) {
      setCommandGenerationType("command")
      setCurrentCommandString(local.jsonQueryStringCommand || "")
    } else if (Object.keys(local).length > 0 && local.command && local.command.trim().startsWith("{")) {
      setCommandGenerationType("plain")
      var commandSplit = local.command.split(".")
      if (commandSplit.length === 2) {
        var model = commandSplit[0].replace("{", "")
        var field = commandSplit[1].replace("}", "")
        var workflow = workflowsBySubKeys.filter(x => { return model === x.modelName })
        if (workflow.length > 0) {
          var workflowName = workflow[0].config.workflowName
          setselectedPlainModel(workflowName)
          setselectedPlainModelToBeShown(model)
          setselectedPlainField(field)
          if (!local.commandForDisplay) {
            local = {
              ...local,
              commandForDisplay: workflowName + "." + field
            }
          }
          if (!local.jsonQueryStringCommand) {
            local = {
              ...local,
              jsonQueryStringCommand: ""
            }
          }
          fillModelsList()
          fillFieldsList(workflowName)
        }
      }
    } else {
      setCommandGenerationType("constant")
      if (local.command) {
        setConstantVal(local.command)
        if (!local.commandForDisplay) {
          local = {
            ...local,
            commandForDisplay: local.command
          }
        }
        if (!local.jsonQueryStringCommand) {
          local = {
            ...local,
            jsonQueryStringCommand: ""
          }
        }
      }
    }
    var type = fieldsList.filter(x => { return x[0] === row.cells.field1 })
    if (type.length > 0) {
      var typeVal = type[0]
      setCurrentCommandType(typeVal[1])
    } else {
      setCurrentCommandType("String")
    }
    setCurrentCommand(local)
    setOpenCommand(true)
  }
  const handleDeleteCommands = (row: any) => {
    var local = currentMergeRuleSet.output.filter(x => { return x.id !== row.id })
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, output: local }))
  }

  const handleSave = () => {
    var localCommand = { ...currentCommand }
    var localOutput = [...currentMergeRuleSet.output]

    if (commandGenerationType === "constant") {
      localCommand = {
        ...currentCommand,
        command: constantVal,
        jsonQueryStringCommand: "",
        commandForDisplay: constantVal
      }
    } else if (commandGenerationType === "plain") {
      localCommand = {
        ...currentCommand,
        command: "{" + selectedPlainModelToBeShown + "." + selectedPlainField + "}",
        jsonQueryStringCommand: "",
        commandForDisplay: selectedPlainModel + "." + selectedPlainField
      }
    }
    var outputObj = localOutput.find(x => { return x.id === localCommand.id })
    if (outputObj) {
      var index = localOutput.indexOf(outputObj)
      if (index !== -1) {
        localOutput[index] = localCommand
      }
    } else {
      localOutput = [...localOutput, localCommand]
    }

    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, output: localOutput }))
    setCurrentCommand({} as OutputRow)
    setOpenCommand(false)
    setCurrentCommandType("")
    setConstantVal("")
    setselectedPlainField("")
    setselectedPlainModel("")
    setselectedPlainModelToBeShown("")
  }

  const TransformAllPredictions = (e: any) => {
    setUseAllPredictionsForMerge(true)
  }

  const TransformSinglePrediction = (e: any) => {
    setUseAllPredictionsForMerge(false)
  }


  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <InformationTextMergeRule text={informationConfig.editMergeRuleSetOutput} type="Tab" />
      </div>
      <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={handleAddCommand} >
        <Icon slot="start" name="add"></Icon>
        Add Transformation on Fields
      </Button>
      <DataGrid fixedHeading={true}
        rows={allCommandsRows} columns={outputRowColumns}  >
        <span slot="no-records">No Data Transformation Added.</span>
        {allCommandsRows.map(row => {
          return (
            <>
              <div slot={`field1-${row.id}`} key={`field1-${row.id}`} style={{ color: "#0078D4", cursor: "pointer", width: "10em" }}
                onClick={() => { handleEditCommand(row) }}
              >
                {row.cells.field1}
              </div>
              <div slot={`field2-${row.id}`} key={`field2-${row.id}`} style={{ width: "30em" }}>
                {row.cells.field2}
              </div>
              <span slot={`field3-${row.id}`} key={`field3-${row.id}`}>
                <Button appearance="command" style={{ fontSize: "14px" }} disabled={!access.canEdit} onClick={() => handleDeleteCommands(row)} >
                  <Icon slot="start" name="delete"></Icon>
                </Button>
              </span>
            </>)
        })}
      </DataGrid>

      {/* MRTODO check line #94 - we need to mae it modelName */}
      {openCommand &&
        <FlyInPanel style={{ '--size': '900px' }}
          open={openCommand}
          heading="Configure Transformation on Output fields"
          onHeRequestClose={handleRequestClose}>
          <div style={{ paddingBottom: "20px", fontSize: "15px" }}>
            <span>Add fields and the transformation for each field</span>
          </div>
          <table className='mergerule-form-table mergerule-basics-table'>
            <tr>
              <td>Name of field to be transformed <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                <Icon id="namebasics" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                <Tooltip anchor="namebasics" fixedPlacement={true} placement='top'>
                  Choose a field that you want to transform.
                </Tooltip>
              </td>
              <td>
                <Select disabled={!access.canEdit} autocomplete="both" className={'Text-fields'} placeholder="Select Field Name" value={currentCommand.field || ""} required={true}
                >
                  {fieldsList.map((item) => {
                    return <Option onHeSelected={handleBasicDetailsChange}>{item[0]}</Option>
                  })}
                </Select>
              </td>
            </tr>
            <tr>
              <td>
                Data Type of above selected field
              </td>
              <td>
                <TextField placeholder="No field selected yet" label="" value={currentCommandType || ""} className={'Text-fields'} disabled={true} />
              </td>
            </tr>
            {currentCommandType !== undefined && currentCommandType !== "" &&
              <tr>
                <td>
                  Choose input type to transform above selected field <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                </td>
                <td>
                  <RadioGroup name="selectCommandType" value={commandGenerationType} >
                    <div style={{ display: "flex" }}>
                      <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="constant" onHeSelected={handlecommandGenerationType}>Constant Value
                        <Icon id="info1001" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info1001" fixedPlacement={true} placement='top'>
                          Input a constant value for the selected field.
                        </Tooltip>
                      </Radio>
                      <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="plain" onHeSelected={handlecommandGenerationType}>Plain Field
                        <Icon id="info1002" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info1002" fixedPlacement={true} placement='top'>
                          Choose a field from a model that should be used to set value for selected field.
                        </Tooltip>
                      </Radio>
                      <Radio disabled={!access.canEdit} value="command" onHeSelected={handlecommandGenerationType}>Use Commands
                        <Icon id="info1003" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info1003" fixedPlacement={true} placement='top'>
                          Use available commands like LIST,CONCAT, JOIN etc.. to do some transformation on different fields to set value for selected field.
                        </Tooltip>
                      </Radio>
                    </div>
                  </RadioGroup>
                </td>
              </tr>
            }
            {currentCommandType !== undefined && currentCommandType !== "" && commandGenerationType === "constant" &&
              <tr>
                <td>
                  Constant Value <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                  <Icon id="constantValue" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                  <Tooltip anchor="constantValue" fixedPlacement={true} placement='bottom'>
                    Input a constant value in the text field.
                  </Tooltip>
                </td>
                <td>
                  <TextField placeholder="Input constant value" label="" className={'Text-fields'} onInput={handleConstantValue} value={constantVal} required />
                </td>
              </tr>
            }
            {currentCommandType !== undefined && currentCommandType !== "" && commandGenerationType === "plain" &&
              <tr>
                <td>
                  Name of Model containing Field to be used to transform <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                  <Icon id="modelName" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                  <Tooltip anchor="modelName" fixedPlacement={true} placement='top'>
                    Choose a model whose field's value you want to set as value of selected field above.
                  </Tooltip>
                </td>
                <td>
                  <Select disabled={!access.canEdit} autocomplete="both" className={'Text-fields'} label="" placeholder="Select Model Name" value={selectedPlainModel || ""} required
                  >
                    {plainModelList.map((item) => {
                      return <Option onHeSelected={handlePlainModel}>{item}</Option>
                    })}
                  </Select>
                </td>
              </tr>
            }
            {currentCommandType !== undefined && currentCommandType !== "" && commandGenerationType === "plain" &&
              <tr>
                <td>
                  Name of Field to be used to transform <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                  <Icon id="fieldName" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                  <Tooltip anchor="fieldName" fixedPlacement={true} placement='top'>
                    Choose a field you want to choose from.
                  </Tooltip>
                </td>
                <td>
                  <Select disabled={!access.canEdit} autocomplete="both" className={'Text-fields'} label="" placeholder={plainFieldList.length === 0 ? "Choose Model Name To Fill this List" : "Select Field Name"} value={selectedPlainField || ""} required={true}
                  >
                    {plainFieldList.map((item) => {
                      return <Option onHeSelected={handlePlainField}>{item}</Option>
                    })}
                  </Select>
                </td>
              </tr>
            }
          </table>
          {currentCommandType !== undefined && currentCommandType !== "" && commandGenerationType === "command" &&
            <div>

              <div style={{ marginTop: "20px", fontWeight: "600" }}>Configure transformation
                <Accordion><AccordionItem>
                  <span slot="heading" style={{ fontWeight: "400", color: "var(--he-color-primary-700)" }}>
                    Learn More
                  </span>
                  {
                    <DataGrid fixedHeading={true}
                      rows={leanrMoreDate} columns={LearnMoreColumns}  >
                      <span slot="no-records">No Information found.</span>
                      {leanrMoreDate.map(row => {
                        return (
                          <>
                            <span slot={`field3-${row.id}`} key={`field3-${row.id}`} style={{ color: "limegreen" }}>
                              {row.cells.field3}
                            </span>
                            <span slot={`field4-${row.id}`} key={`field4-${row.id}`}>
                              {row.cells.field4 != undefined &&
                                <span>{row.cells.field4.split(",").map(eachRow => {
                                  return <div>{eachRow}</div>
                                })}
                                </span>
                              }
                            </span>
                            <span slot={`field5-${row.id}`} key={`field5-${row.id}`}>
                              {row.cells.field5 != undefined &&
                                <span>{row.cells.field5.split("|").map(eachRow => {
                                  return <div><Icon name="circlefillbadge12" style={{ fontSize: "8px", "textAlign": "center" }}></Icon> {eachRow}</div>
                                })}
                                </span>
                              }
                            </span>
                            <span slot={`field6-${row.id}`} key={`field6-${row.id}`} style={{ color: "limegreen" }}>
                              {row.cells.field6 != undefined &&
                                <span>{row.cells.field6.split("|").map(eachRow => {
                                  return <div>{eachRow}</div>
                                })}
                                </span>
                              }
                            </span>
                          </>)
                      })}
                    </DataGrid>

                  }
                </AccordionItem></Accordion>

              </div>

              <div style={{ display: "inline-block", marginTop:"20px" }} >
                <RadioGroup  name="selectPredictionMergeType" value = {useAllPredictionsForMerge ? "allPreds" : "singlePred"} >
                  <div style={{display: "flex", justifyContent:"left"}}>
                  <Radio disabled={!access.canEdit}  value="singlePred" onHeSelected={TransformSinglePrediction}>One Prediction per Model
                    <Icon id="predmerge1" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                    <Tooltip anchor="predmerge1" fixedPlacement={true} placement='top'>
                    One prediction per model will be considered while transformation of the selected field
                    </Tooltip>
                  </Radio>
                  <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="allPreds" onHeSelected={TransformAllPredictions}>All Predictions per Model
                    <Icon id="predmerge2" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                    <Tooltip anchor="predmerge2" fixedPlacement={true} placement='top'>
                    All predictions (including duplicates) per model will be considered while transformation of the selected field
                    </Tooltip>
                  </Radio>
                  </div>
                </RadioGroup>
              </div>


              <div>
                {!useAllPredictionsForMerge &&
                <CommandQueryBuilder 
                subKey={mergeRule.subscriptionKey} 
                currentCommandType={currentCommandType} 
                workflowsBySubKeys={workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })} 
                currentJsonTree={currentCommandString} 
                onQueryReturn={handleQueryReturn} 
                handleRequestClose={handleRequestClose} />
              }

              {useAllPredictionsForMerge &&
                <CommandQueryBuilderAllPredictions 
                subKey={mergeRule.subscriptionKey} 
                currentCommandType={currentCommandType} 
                workflowsBySubKeys={workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })} 
                currentJsonTree={currentCommandString} 
                onQueryReturn={handleQueryReturn} 
                handleRequestClose={handleRequestClose} />
              }

              </div>

            </div>
          }
          {commandGenerationType !== "command" &&
            <div className="custom-flyinpanel-footer">
              <Button type='button' appearance='primary' onClick={handleSave} disabled={!access.canEdit || isDisabled}>Save</Button>
              <span style={{ marginLeft: '20px' }}></span>
              {(<Button type='button' appearance='secondary' onClick={handleRequestClose}>Cancel</Button>)}
              <span style={{ marginLeft: '20px' }}></span>
            </div>
          }
        </FlyInPanel>
      }
    </>
  )
}
