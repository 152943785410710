import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../../root-redux/RootState"
import { getRequest } from "../utils/GetRequest";
import { Workflow } from "../../model/workflows/Workflow.model";
import { saveWorkflow } from "../../feature-components/workflows/workflow-by-id-redux/WorkflowById.redux";

export const getDatasetById = createAsyncThunk<any, string, { state: RootState }>('datasetById/getDatasetById', async (id: string, {getState, dispatch, rejectWithValue}) => {
    const env = getState().env.selectedEnvItem;
    const response = await getRequest<Workflow>(`${env}/workflows/${id}`);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        return rejectWithValue(data);
    }
    dispatch(saveWorkflow(response.data));
    return response.data;
})