import { useState } from "react";
import { Button, Dialog, Text, TextArea } from "../../common/HarmonyEnablers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { setShowDialog } from "./Feedback.redux";
import './feedback-styles.css'
import { appInsights } from "../../config/appInsights";

const styles = {
    ratingContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(10, 11fr)',
      gap: '0.5rem',
      margin: '2rem 0 1rem',
    },
    ratingButton: {
      '--padding-x': '0',
      width: '36px',
      height: '36px',
    },
    labelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 'var(--he-spacing-2x-large)',
    },
    label: {
      flex: '0 0 auto',
      marginBottom: '5px'
    },
  };

export const FeedbackDialog = () => {
  const dispatch = useDispatch<AppDispatch>();
  const showDialog = useSelector((state: RootState) => state.feedback.showDialog);
  const userName = useSelector((state: RootState) => state.userDetails.username);
const [rating, setRating] = useState<number | null>(null);
const [description, setDescription] = useState<string>('' as string);

const onSubmit = () => {
  appInsights.trackEvent({
    name: "Feedback submitted",
    properties: {
      user: userName,
      rating: rating,
      description: description
    },
  });
  dispatch(setShowDialog(false)) 
}

  return (
    <>
      <Dialog
        className="feedback-dialog"
        heading="How satisfied are you with Nebula Self-serve?"
        open={showDialog}
        onHeAfterHide={
          // verify target is dialog before setting state
          ({ target, currentTarget }) => (target === currentTarget ? dispatch(setShowDialog(false)) : null)
        }
      >

        <div style={styles.ratingContainer}>
          {[...Array(10)].map((v, i) => {
            const value = i + 1;

            return (
              <Button
                key={`rating${value}`}
                aria-pressed={rating === value ? 'true' : 'false'}
                appearance={rating === value ? 'primary' : 'secondary'}
                onClick={() => setRating(value)}
                style={styles.ratingButton}
              >
                {value}
              </Button>
            );
          })}
        </div>

        <div style={styles.labelContainer}>
          <Text appearance="small" style={styles.label}>
            Not satisfied at all
          </Text>
          <Text appearance="small" style={styles.label}>
            Very satisfied
          </Text>
        </div>

        <div>
          <Text appearance="paragraph" style={styles.label}>
            Do you have any additional feedback to help us improve?
          </Text>
          <TextArea name='description' value={description || ''} placeholder="Type here..." className={'Text-fields'} onHeChange={(e: any) => setDescription(e.target.value)} />
        </div>

        <Button
          slot="footer"
          appearance="primary"
          onClick={onSubmit}
        >
          Submit
        </Button>
      </Dialog>
    </>
  );
}