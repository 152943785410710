import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { CampaignHeader } from './EditCamapaignHeader';
import { EditCampaignTabs } from './EditCampaignTabs';
import informationConfig from '../../../shared-content/inputDescription';
import { InformationText } from '../../../shared-components/info-text/InformationText';
import { Button, Dialog, MessageBar, ProgressRing, Tab, TabPanel, Tabs } from "../SpaAdminScope";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { QueryBuilder } from "./CampaignQueryBuilder";
import { DataCart } from "./DataCart";
import { tabs } from '@harmony/enablers/react';
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getPredictions, getViewResultsCount } from "../../../services/SPAAdmin/CampaignDataCart.api";
import { setCartPageNum } from "../redux/CampaignDataCart.redux";
import { MessageTypes } from "../SPAConstants";
import { UserMode } from "../redux/CacheHandler.redux";

const CampaignRefineDataC: React.FC = () => {

    const navigate = useNavigate();
    const tabRef = useRef<tabs>(null);
    const dispatchApp = useDispatch<AppDispatch>();

    const [currentStage, setCurrentStage] = useState<number>(1);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<{type: MessageTypes, message: string, isOpen: boolean}|undefined>(undefined);

    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen);
    const campaignDetails = useSelector((state: RootState) => state.campaignById);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const userDetails = useSelector((state: RootState) => state.userDetails);
    const cacheHandler = useSelector((state: RootState) => state.cacheHandler);
    const campaignDataCart = useSelector((state: RootState) => state.campaignDataCart);
    const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys);

    const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
        navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
    }, [navigate]);

    const gotoNextTab = (idx: number) => {
        tabRef.current?.adjust(idx);
        setCurrentStage(currentStage + idx);
    }

    if (campaignDetails.requestStatus === RequestStatus.idle) {
        _onNavMenuItemClick(NavMenuItemType.Configuration);
    }

    useEffect(() => {
        if (userSalesRoles.isApprover) {
            tabRef.current?.adjust(1);
            setCurrentStage(2);
            if (campaignDataCart.requestStatus === RequestStatus.idle && campaignDetails.requestStatus === RequestStatus.succeeded &&
                workflowsBySubKeys.status === RequestStatus.succeeded) {
                dispatchApp(getPredictions({}));
                dispatchApp(getViewResultsCount({}));
            }
        }
    }, [userSalesRoles, campaignDetails.requestStatus, workflowsBySubKeys.status, dispatchApp, campaignDataCart]);

    const dataCartTab = () => {
        dispatchApp(setCartPageNum(1));
        dispatchApp(getPredictions({}));
        gotoNextTab(1);
    }

    useEffect(() => {
        if ((campaignDetails.campaign?.inCart || []).length > 0 && currentStage === 1 && !userSalesRoles.isApprover) {
            dataCartTab();
        }
    }, campaignDetails.campaign?.inCart);

    const isItNotReviewed = () => {
        return (campaignDetails.campaign?.reviewList || []).length === 0 || campaignDetails.campaign?.reviewList.some(rList => rList.list.length > 0);
    }

    const isNotOwner = () => {
        return cacheHandler.campaignCache?.mode === UserMode.EDIT && userDetails.localAccountId !== campaignDetails.campaign?.createdBy && !userSalesRoles.isSpaAdmin;
    }

    const handleNextClick = () => {
        if (currentStage === 1) {
            dataCartTab();
        } else if (isItNotReviewed() && !isNotOwner()) {
            setIsOpen(true);
        } else {
            goToNextPage();
        }
    }
    const goToNextPage = () => {
        _onNavMenuItemClick(NavMenuItemType.Campaign,"publish");
    }

    const handlePreviousClick = () => {
        if (userSalesRoles.isApprover) {
            _onNavMenuItemClick(NavMenuItemType.Configuration);
        }
        else if (currentStage === 1) {
            _onNavMenuItemClick(NavMenuItemType.Campaign, "summary");
        } else {
            gotoNextTab(-1);
        }
    }

    const anyErrors = () => {
        return (
            campaignDataCart.error || 
            userSalesRoles.error || 
            campaignDetails.error
        );
    }

    const renderErrorMessage = () => {
        const error = anyErrors();
        if (error) {
            return (
                <MessageBar appearance="error" open>
                    {error}
                </MessageBar>
            );
        }
    }
    
    return (
        <>
            {
                renderErrorMessage()
            }
            {
                message &&
                <MessageBar appearance="error" open={message?.isOpen} onHeAfterHide={() => setMessage(undefined)}>
                    {message.message}
                </MessageBar>
            }

            <div className={`spa-sticky-header ${isNavPanelOpen ? '': 'collapsed'}`}>
                <CampaignHeader title={campaignDetails.summary["CampaignName"] || "Import & Refine"} currentPage={1} workflowName=""/>
                
                <div className='spa-workspace'>
                    <EditCampaignTabs stage={currentStage}/>
                </div>

                <div className="spa-informationtext">
                    <InformationText text={informationConfig.campaignRefine} type="Heading"/>
                </div>
            </div>

            <div className='workflows-wrapper spa-wrapper'>

            {
                (userSalesRoles.requestStatus === RequestStatus.loading) ?
                <div className='spa-progressring'>
                    <ProgressRing label="Loading..." indeterminate></ProgressRing>
                </div>
                :
                <div className="spa-refine-space">
                    <Tabs ref={tabRef}>
                        <Tab style={{zIndex: 1, color: userSalesRoles.isApprover ? 'silver' : 'black'}} disabled={userSalesRoles.isApprover} onClick={() => {
                            if (!userSalesRoles.isApprover && currentStage === 2) {
                                gotoNextTab(-1);
                            }
                        }}>Query Builder</Tab>
                        <Tab style={{zIndex: 1}} onClick={() => {
                            if (!userSalesRoles.isApprover && currentStage === 1 && (campaignDetails.campaign?.inCart || []).length > 0) {
                                dataCartTab();
                            }
                        }} disabled={(campaignDetails.campaign?.inCart || []).length === 0}>Data Cart {userSalesRoles.isApprover ? `(${campaignDataCart.viewResultsCount.inCartCount})` : `(${(campaignDetails.campaign?.inCart || []).length})`}</Tab>

                        <TabPanel><QueryBuilder nextPageEvent={gotoNextTab} refreshCart={() => dispatchApp(getPredictions({}))}/></TabPanel>
                        <TabPanel><DataCart nextPageEvent={gotoNextTab}/></TabPanel>
                    </Tabs>
                </div>
            }

                <div className={`workflow-footer ${isNavPanelOpen ? '': 'collapsed'}`}>
                    <Button type='button' appearance='secondary' onClick={handlePreviousClick} style={{ marginRight: '20px' }}>Previous</Button>
                    {!userSalesRoles.isApprover && <Button type='button' appearance='primary' onClick={handleNextClick} disabled={anyErrors() !== undefined || ((campaignDetails.campaign?.inCart || []).length === 0)}>Next</Button>}
                    {campaignDetails.unsavedChanges && <span style={{fontStyle: 'italic', paddingLeft: '5px' }}><span style={{color: 'red'}}>Unsaved changes</span>: Please click on "{userSalesRoles.isApprover ? 'Save changes' : 'Next'}"</span>}
                </div>
            </div>

            <Dialog heading="Pending Review:" open={isOpen} onHeAfterHide={() => setIsOpen(false)}>
                <p>{(campaignDetails.campaign?.reviewList || []).length === 0 ? 'Account(s) are not reviewed by Area-reviewers, ' : 'There are items pending for review, '}
                Would you still like to continue ?</p>
                <Button slot="footer" appearance="primary" onClick={goToNextPage}>Ok</Button>
                <Button slot="footer" onClick={() => setIsOpen(false)}>Close</Button>
            </Dialog>
        </>
    )
}

export const CampaignRefineData = withErrorBoundary("Refine Data - Campaign", CampaignRefineDataC);