import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../root-redux/RootState";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Helper } from "../utils/Help";
import { taxonomyExcludeFilters } from "../SPAConstants";

export const taxonomySelector = createSelector(
    (state: RootState) => state.taxonomy,
    (state: RootState) => state.campaignConfig,
    (state: RootState) => state.campaignById,
    (state: RootState) => state.userSalesRoles,
    (state: RootState) => state.filteredCampaign.modelId,
    (taxonomy, config, campaignById, userSalesRoles, selectedModelId): any => {
        const details: {[key:string]: {[key:string]: Set<string>}} = {}

        if (Helper.isLoadingStatus(taxonomy.requestStatus, config.requestStatus, userSalesRoles.requestStatus)) {
            return { taxonomyDetails: details, status: RequestStatus.loading };
        } else if (Helper.isEmpty(taxonomy.taxonomyDetails) || Helper.isEmpty(config.campaignConfig) || Helper.isEmpty(config.campaignConfig?.layoutFields)) {
            return { taxonomyDetails: details, status: RequestStatus.loading };
        }

        const taxonomyDetails = {...taxonomy.taxonomyDetails};

        if (!userSalesRoles.isSpaAreaPickerOpen && userSalesRoles.isApprover && userSalesRoles.userDetails?.area) {
            const layoutConfig = (config.campaignConfig?.layoutFields || []).filter(layout => layout.name === 'Area').at(0);
            const territories = (taxonomyDetails['territories'] || []).filter((row: any) => {
                return row[layoutConfig?.taxonomyField!] === userSalesRoles.userDetails?.area
            });
            taxonomyDetails['territories'] = territories;
        }

        taxonomyDetails['solutionPlayBook'] = (taxonomyDetails['solutionPlayBook'] || []).filter((row: any) => {
            //const org = (campaignById.summary['CampaignType'] || '').startsWith('SMC') ? 'SMC' : 'Enterprise';
            return (row['MarketingPlay'] == null || row['MarketingPlay'].length === 0) && (row['SalesPlay'] !== 'Not Applicable');// && (row['Org'] === org);
        });

        const getConfigName = (taxonomyField: string[]|undefined) => {
            return taxonomyField ? (config.campaignConfig?.layoutFields || []).filter(l => taxonomyField.includes(l.taxonomyField!)).map(t => t.name) : undefined;
        }

        Object.keys(taxonomyExcludeFilters).forEach(filterKey => {
            const layout = (config.campaignConfig?.layoutFields || []).find(layout => layout.taxonomyKey === filterKey)
            taxonomyDetails[filterKey] = taxonomyDetails[filterKey].filter((options: any) => {
                return !taxonomyExcludeFilters[filterKey].includes(options[(layout && layout.taxonomyField) || 'option']);
            })
        });

        for(const field of (config.campaignConfig?.layoutFields || [])) {
            if (field.taxonomyKey && field.taxonomyField) {
                if (!Object.hasOwn(details, field.taxonomyKey)) {
                    details[field.taxonomyKey] = {};
                }
                details[field.taxonomyKey][field.name] = new Set<string>();
                for(const row of (taxonomyDetails[field.taxonomyKey] || [])) {
                    const fieldKey: string[]|undefined = getConfigName(field.taxonomyFilterKey);
                    if (window.location.pathname.endsWith('refine') && selectedModelId) {
                        details[field.taxonomyKey][field.name].add(row[field.taxonomyField]);
                    }
                    else if (row[field.taxonomyField] != null && fieldKey && fieldKey.length > 0 && fieldKey.every((key, idx) => row[field.taxonomyFilterKey![idx]] === campaignById.summary[key])) {
                        details[field.taxonomyKey][field.name].add(row[field.taxonomyField]);
                    }
                    else if (!(fieldKey || []).some(key => Object.hasOwn(campaignById.summary, key)) && row[field.taxonomyField] != null && (row[field.taxonomyField] || "").length > 0) {
                        details[field.taxonomyKey][field.name].add(row[field.taxonomyField]);
                    }
                }
            }
        }
        
        return { taxonomyDetails: details, status: taxonomy.requestStatus };
    }
);