import { WorkflowConfig } from "./WorkflowConfig.model"
import { WorkflowStatus } from "./WorkflowStatus.model"
import { DataIngestion, emptyDataIngestionList } from "./DataIngestion.model"
import { Delta } from "./Delta.model"
import { BlobConnection } from "../connections/BlobConnection.model"
import { AdlsConnection } from "../connections/AdlsConnection.model"
import { Processing, emptyProcessingList } from "./Processing.model"
import { PublishModel } from "./Publish.model"
import { SqlAadConnection } from "../connections/SqlAadConnection.model"
import { SqlConnection } from "../connections/SqlConnection.model"
import { EventConnection } from "../connections/EventConnection.model"
import { CosmosConnection } from "../connections/CosmosConnection.model"
import { TopicConnection } from "../connections/TopicConnection.model"
import { WorkflowStage } from "./WorkflowStage.model"
import { DEFAULT_BROWSER_CACHE_MANAGER } from "@azure/msal-browser/dist/cache/BrowserCacheManager"
import { Schedule } from "./Schedule.model"
import { DatasetFileUploadModel } from "./DatasetFileUpload.model"

export class WorkflowSummary {

    public config!: WorkflowConfig
    public status!: string
    public subscriptionKey!: string
    public modifiedDate!: string
    public modifiedBy!: string
    public version!: number
    public family!: string
    public selfServeVersion!: string
    public workFlowType !: string
    public datasetModelType?: string
    public hasApproved!: string[]
    public tenantName !: string
    public description!: string
    public tags!: string
    public modelName!: string
    public fields!: any
    public modelId!: string
    public modelProvider!: string

}
export interface IWorkflowResponse {
    workflow: Workflow
}

export class Workflow {

    public id!: string
    public partitionKey!: string
    public subscriptionKey!: string
    public type!: string
    public status!: string
    public data!: string
    public version!: number
    public environment!: string
    public config!: WorkflowConfig
    public modifiedBy!: string
    public modifiedDate!: string
    public family!: string
    public workFlowType!: string
    public datasetModelType!: string
    public selfServeVersion!: string
    public tenantName !: string
    public isActionPublish!: boolean
    public description!: string
    public tags!: string
    public dataIngestion!: DataIngestion[]
    public datasetFileUpload!: DatasetFileUploadModel
    public preProcessing!: Processing[]
    public postProcessing!: Processing[]
    public delta!: Delta[]
    public publish!: PublishModel[]
    public blobConnections!: BlobConnection[]
    public adlsConnections!: AdlsConnection[]
    public sqlaadConnections!: SqlAadConnection[]
    public sqlConnections!: SqlConnection[]
    public eventConnections!: EventConnection[]
    public cosmosConnections!: CosmosConnection[]
    public topicConnections!: TopicConnection[]
    public apiConnections!: TopicConnection[]
    public hasApproved!:string[]
    public modelName!: string
    public modelProvider!: string
    public fields!: any
    public typeOfMerge!: string
    public schedule!: Schedule
    public isFlighted!: boolean
    public flightingParentWorkflowId!: string    
}


export class CosmosDataCollection
{
    public key!: string
    public value!: string
    public subPartitionKeyCount!: number
}
