import { Button, Card, Icon, Tooltip } from "../../common/HarmonyEnablers";
import "./Card.css";

const CardComponent_Interactive = (props: any) => {
  return (
    <a href={props.titlelink} target="_blank" className="no-underline">
      <div className="container-card1">
        <div className="inner-container-card1">
          <div className="imageholder-card1">
            <img src={props.imageSrc} id = {props.uniqueId} />
          </div>
          <div className="textholder-card1">
            <div className="headingText" >{props.title}</div>
            {/* <div className="subtitle-cropped" id={props.title}>{props.subtitle}</div>
              <Tooltip anchor={props.title} placement="start">
              {props.subtitle}
              </Tooltip> */}
          </div>
        </div>
      </div>
    </a>
  );
};

export { CardComponent_Interactive };
