import { createSelector } from "@reduxjs/toolkit";
import { RoleBasedAccessControlModel } from "../../model/role-based-access-control/RoleBasedAcessControl.model";
import { Teams } from "../../model/role-based-access-control/userTeams.model";
import { RootState } from "../../root-redux/RootState";

export const permissionsSelector = createSelector(
  (state: RootState) => state.appPersonas.list,
  (state: RootState) => state.userGroups.list,
  (appPersonaItems, groupsUserIsIn) => {
 
  const userTeamsList: Teams = {
    key: [],
    name: [],
  };

  const permissionsList: RoleBasedAccessControlModel = {
    edit: [],
    approve: [],
    release: [],
    userTeams: userTeamsList,
    primaryTeam:""
  };

  appPersonaItems.map((appPersonaObject) => {
    appPersonaObject.appRoles.edit.map((editSG) => {
      if (
        groupsUserIsIn.includes(editSG) &&
        !permissionsList.edit.includes(appPersonaObject.subscriptionKey)
      ) {
        permissionsList.edit.push(appPersonaObject.subscriptionKey);
        if (
          !permissionsList.userTeams.key.includes(
            appPersonaObject.subscriptionKey
          )
        ) {
          permissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
          permissionsList.userTeams.name.push(appPersonaObject.appName);
        }
      }
    });

    //Getting and storing all subscriptions keys (team IDs) on which user has approve privilege
    appPersonaObject.appRoles.approve.map((approveSG) => {
      if (
        groupsUserIsIn.includes(approveSG) &&
        !permissionsList.approve.includes(appPersonaObject.subscriptionKey)
      ) {
        permissionsList.approve.push(appPersonaObject.subscriptionKey);
        if (
          !permissionsList.userTeams.key.includes(
            appPersonaObject.subscriptionKey
          )
        ) {
          permissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
          permissionsList.userTeams.name.push(appPersonaObject.appName);
        }
      }
    });

    //Getting and storing all subscriptions keys (team IDs) on which user has release privilege
    appPersonaObject.appRoles.release.map((releaseSG) => {
      if (
        groupsUserIsIn.includes(releaseSG) &&
        !permissionsList.release.includes(appPersonaObject.subscriptionKey)
      ) {
        permissionsList.release.push(appPersonaObject.subscriptionKey);
        if (
          !permissionsList.userTeams.key.includes(
            appPersonaObject.subscriptionKey
          )
        ) {
          permissionsList.userTeams.key.push(appPersonaObject.subscriptionKey);
          permissionsList.userTeams.name.push(appPersonaObject.appName);
        }
      }
    });
  });
  // const sortedUserTeams = permissionsList.userTeams.name.sort(function (a, b) { return ('' + a).localeCompare(b) })

  permissionsList.primaryTeam = permissionsList.userTeams.name[0];
  return permissionsList;
})
