import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { userObject } from '../../model/user-object/UserObject.model';
import { RequestStatus } from '../../model/ServiceRequestStatus.model';
import { getAllUserObjects } from '../../services/GetUserObjects.api';

interface UserObjectsState {
  list : userObject[],
  status: RequestStatus,
  error: string | undefined
}

const initialState: UserObjectsState = {
  list : [],
  status: RequestStatus.idle,
  error: ''
}

export const userObjectsSlice  = createSlice({
  name: 'userObjects',
  initialState,
  reducers: {
    addUserObject: (state, action) => {
        state.list.push(action.payload)
      }
  },
  extraReducers(builder) {
    builder
    .addCase(getAllUserObjects.pending, (state, action) => {
      state.status = RequestStatus.loading
    })
    .addCase(getAllUserObjects.fulfilled, (state, action) => {
      state.status = RequestStatus.succeeded
      state.list = action.payload
      state.error = undefined
    })
    .addCase(getAllUserObjects.rejected, (state, action) => {
      state.status = RequestStatus.failed
      state.error = action.error.message
    })
  }
});


export const userObjectsReducer = userObjectsSlice.reducer