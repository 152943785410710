import { useState } from "react";
import { BlobConnection } from "../../../model/connections/BlobConnection.model";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { Icon, Menu, MenuItem } from "../ConnectionScope";
import { ConnectionProps } from "../ConnectionProps";
import { DropdownFilter } from "../../../shared-components/dropdown-filter/DropdownFilter";
import { AdlsConnection } from "../../../model/connections/AdlsConnection.model";
import { ConnectionTypeValue, getConnectionTypeKeyFromValue } from "../../../utils/ConnectionTypeUtils";
import { BlobConnectionPanel } from "../connection-types/BlobConnectionPanel";
import { AdlsConnectionPanel } from "../connection-types/AdlsConnectionPanel";
import { CosmosConnection } from "../../../model/connections/CosmosConnection.model";
import { CosmosConnectionPanel } from "../connection-types/CosmosConnectionPanel";
import { SqlAadConnection } from "../../../model/connections/SqlAadConnection.model";
import { SqlAadConnectionPanel } from "../connection-types/SqlAadConnectionPanel";
import { SqlConnectionPanel } from "../connection-types/SqlConnectionPanel";
import { SqlConnection } from "../../../model/connections/SqlConnection.model";
import { EventConnectionPanel } from "../connection-types/EventConnectionPanel";
import { EventConnection } from "../../../model/connections/EventConnection.model";
import { TopicConnectionPanel } from "../connection-types/TopicConnectionPanel";
import { TopicConnection } from "../../../model/connections/TopicConnection.model";
import { ApiConnectionPanel } from "../connection-types/ApiConnectionPanel";
import { ApiConnection } from "../../../model/connections/ApiConnection.model";
import { Connection } from "../../../model/connections/Connection.model";
import { getSelectedConnectionSelector, GetSubscriptionsAndEditModeSelector } from "./ConnectionPanel.selectors.redux";
import { useSelector } from "react-redux";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Radio, RadioGroup, Tooltip } from "../../../common/HarmonyEnablers";

const ConnectionPanelBodyC: React.FC<{panelProps: ConnectionProps}> = ({panelProps}) => {
    const selectedConnectionData = useSelector(getSelectedConnectionSelector)
    const {connectionData, isNew} = panelProps
    const connection = isNew && (!connectionData || !connectionData.connectionId) && selectedConnectionData ? selectedConnectionData : connectionData
    
    const [selectedConnType, setSelectedConnType] = useState(ConnectionTypeValue[ConnectionType.adlsConnections])

    const {subscriptions, isEditEnabled} = GetSubscriptionsAndEditModeSelector((connection as Connection)?.subscriptionKey, panelProps?.userTeams, panelProps?.appPersonas)
    
    const connTypeMenItems = Object.values(ConnectionTypeValue).sort();

    const [authType, setAuthType] = useState("msi");

    const handleAuthTypeChange = (e: any) => {
        setAuthType(e.target.value);
    }

    const handleFilterMenuChange = ({target}: any) => {
        setSelectedConnType(target.textContent)
    };    

    // add connection component and dropdown filter in useEffect
    const connectionType = panelProps?.connectionType || getConnectionTypeKeyFromValue(selectedConnType)
    let connectionTypeComponent = <></>
    switch(connectionType as ConnectionType)
    {
        case ConnectionType.blobConnections:
            connectionTypeComponent = <BlobConnectionPanel 
                                        connectionData={connection as BlobConnection || new BlobConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType} 
                                        subscriptions={subscriptions}
                                        authType ={authType}/>            
            break

        case ConnectionType.adlsConnections:
            connectionTypeComponent = <AdlsConnectionPanel 
                                        connectionData={connection as AdlsConnection || new AdlsConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ={authType}/> 
            break

        case ConnectionType.cosmosConnections:
            connectionTypeComponent = <CosmosConnectionPanel 
                                        connectionData={connection as CosmosConnection || new CosmosConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ="spn"/>  
            break
        
        case ConnectionType.apiConnections:
            connectionTypeComponent = <ApiConnectionPanel 
                                        connectionData={connection as ApiConnection || new ApiConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ="spn"/>   
            break

        case ConnectionType.sqlaadConnections:
            connectionTypeComponent = <SqlAadConnectionPanel
                                        connectionData={connection as SqlAadConnection || new SqlAadConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ={authType}/>    
            break

        case ConnectionType.sqlConnections:
            connectionTypeComponent = <SqlConnectionPanel
                                        connectionData={connection as SqlConnection || new SqlConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ={authType}/>      
            break

        case ConnectionType.eventConnections:
            connectionTypeComponent = <EventConnectionPanel
                                        connectionData={connection as EventConnection || new EventConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ="spn"/>      
            break

        case ConnectionType.topicConnections:
            connectionTypeComponent = <TopicConnectionPanel
                                        connectionData={connection as TopicConnection || new TopicConnection()} 
                                        editMode={isEditEnabled || isNew as boolean}
                                        appScopes={panelProps.appScopes}
                                        connectionType={connectionType as ConnectionType}
                                        subscriptions={subscriptions}
                                        authType ="spn"/>   
            break

        default:
            break
    }   

    
    
    return (
        <>
            {/* <div className="panel-edit" hidden={isNew}>
                <span>Edit</span>
                <Toggle onHeChange={() => setIsEditEnabled(!isEditEnabled)}/>
            </div> */}
            {isNew && (<DropdownFilter
                label="Connection Type"
                values={[selectedConnType]}
                style={{marginBottom: "20px"}}
            >
                <Menu>
                    {connTypeMenItems.map(item => (
                        <MenuItem
                            key={item}
                            role="menuitemcheckbox"
                            checked={item === selectedConnType}
                            onClick={handleFilterMenuChange}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Menu>
            </DropdownFilter>)}

           { (connectionType == ConnectionType.adlsConnections || connectionType == ConnectionType.adlsGen1Connections ||connectionType == ConnectionType.blobConnections ||connectionType == ConnectionType.sqlConnections || connectionType == ConnectionType.sqlaadConnections ) &&
             <div style={{padding:"24px", paddingLeft:"0px"}}>

             <RadioGroup 
             label="Select Auth Type"  
             name="authType" 
             value = {isNew ? "msi" : (connection?.useMSI !== null && (connection?.useMSI != undefined && connection?.useMSI == 1)) ? "msi" : "spn"} >

             <div style={{display: "flex"}}>
             <Radio disabled={!(isEditEnabled || isNew as boolean)} style={{ marginRight: "20px" }} value="spn" onHeSelected={handleAuthTypeChange}>Service Principal Name (SPN)
             <Icon id="info" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
             <Tooltip anchor="info11111" fixedPlacement={true} placement='top'>
             SPNs are manually created identifiers used for authentication between services and Azure Active Directory. Choose SPNs for fine-grained control and manual management of credentials.
             </Tooltip>
             </Radio>
             <Radio disabled={!(isEditEnabled || isNew as boolean)}  value="msi" onHeSelected={handleAuthTypeChange}>Managed Service Identity (MSI)
             <Icon id="info22222" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
             <Tooltip anchor="info22222" fixedPlacement={true} placement='top'>
             MSI provides automatically managed identities for resources, simplifying authentication and eliminating the need for manual credential management.
             </Tooltip>
             </Radio>
             </div>
         </RadioGroup>
       </div>
           }

            {connectionTypeComponent}            
        </> 
    )
}
export const ConnectionPanelBody = withErrorBoundary("ConnectionPanelBody", ConnectionPanelBodyC)