import { Card } from "../../common/HarmonyEnablers";
import config from "../../config/config-dev";
import { allLocalLinks } from "../../shared-content/AllLinks";

export const NebulaInformation: React.FC<any> = (props: any) => {
  return (
    // <Card
    //   style={{
    //     boxShadow: "1px 2px 6px grey",
    //     height: "100%",
    //     overflow: "auto",
    //   }}
    // >
      <div style = {{ marginBottom:"18px", marginTop:"18px"}}>
        <div>
          <span style={{ alignContent: "center", fontWeight: "600" }}>
            Contact Us
          </span>
        </div>

        <div style={{ marginTop: "12px", fontSize: "14px" }}>
          <span style={{ fontWeight: "500" }}>Email: </span>
          <span>nebulaengg@microsoft.com</span>
        </div>
        <div style={{ marginTop: "12px", fontSize: "14px" }}>
          <span style={{ fontWeight: "500" }}>DRI: </span>
          <span>CSMERECRPDRI@microsoft.com</span>
        </div>
        <div style={{ marginTop: "12px", fontSize: "14px" }}>
          {/* <span
            style={{ color: "#0078D4", cursor: "pointer" }}
            onClick={() => {
              window.open(allLocalLinks.privacyNotice, "_blank");
            }}
          >
            Privacy Notice
          </span> */}
          <a
          href={allLocalLinks.privacyNotice}
          onClick={() => {
            window.open(allLocalLinks.privacyNotice, "_blank");
          }}
          >
Privacy & Cookies
          </a>
        </div>
      </div>
    // </Card>
  );
};
