import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { TextDiff } from "../text-diff/TextDiff";
import { Dialog } from "../../common/HarmonyEnablers";
import { setShowWorkflowsDiffPanel } from "../release-management/ReleaseData.redux";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { Spinner } from "../../shared-components/loader/Spinner";
import './diff-panel.css'
import { getAllUserObjects } from "../../services/GetUserObjects.api";

export const DiffPanel: React.FC = React.memo(() => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedWorkflowVersions = useSelector((state: RootState) => state.releaseData.selectedWorkflowVersions);
    const selectedWorkflows = useSelector((state: RootState) => state.releaseData.selectedWorkflows);
    const showDiff = useSelector((state: RootState) => state.releaseData.showWorkflowsDiffPanel);
    const loading = useSelector((state: RootState) => state.releaseData.selectedWorkflowsStatus === RequestStatus.loading);
    const userObjects = useSelector((state: RootState) => state.userObjects.list);
    let oldText = "", newText = ""

    useEffect(() => {
        if (selectedWorkflows && selectedWorkflows.length > 0) {
            dispatch(getAllUserObjects(selectedWorkflows))
        }
    }, [selectedWorkflows])

    const handleRequestClose = (event: any) => {
        dispatch(setShowWorkflowsDiffPanel(false))
    }
    
    const leftTitle = `Version ${selectedWorkflowVersions[1]} was modified by ${userObjects.find((userObject) => userObject.id === selectedWorkflows[1]?.modifiedBy)?.displayName}`
    const rightTitle = `Version ${selectedWorkflowVersions[0]} was modified by ${userObjects.find((userObject) => userObject.id === selectedWorkflows[0]?.modifiedBy)?.displayName}`

    if (selectedWorkflows && selectedWorkflows.length === 2) {
        oldText = JSON.stringify(selectedWorkflows[1], null, "\t")
        newText = JSON.stringify(selectedWorkflows[0], null, "\t")
    }    

    return (
        <Dialog
            style={{width: '100%'}}
            heading={`Comparing versions ${selectedWorkflowVersions[1]} and ${selectedWorkflowVersions[0]} of ${selectedWorkflows[0]?.config?.workflowName}`}
            open={showDiff}
            onHeRequestClose={handleRequestClose}
        >            
            {
                loading && <div className='release-diff-loader'><Spinner type='medium' /></div>
            }
            { 
                !loading && <TextDiff leftTitle={leftTitle} rightTitle={rightTitle} oldText={oldText} newText={newText} /> 
            }
        </Dialog>
    )
})