import { Button, Card, Icon, Tooltip } from "../../common/HarmonyEnablers";
import "./Card.css";

const CardComponent_Interactive_Bigger = (props: any) => {
  return (
    <a href={props.titlelink} target="_blank" className="no-underline">
      <div className="container-card2">
        <div className="inner-container-card2">
          <div className="imageholder-card2">
            <img src={props.imageSrc} />
          </div>
          {/* <div className="textholder-card2"> */}
            {/* <div className="headingText" >{props.title}</div> */}
            {/* <div className="subtitle-cropped" id={props.title}>{props.subtitle}</div>
              <Tooltip anchor={props.title} placement="start">
              {props.subtitle}
              </Tooltip> */}
          {/* </div> */}
        </div>
      </div>
    </a>
  );
};

export { CardComponent_Interactive_Bigger };
