import { createAsyncThunk } from "@reduxjs/toolkit";
import { Insight } from "../model/preview-mergerule/Insight.model";
import { postRequest, postRequestServiceAPI } from "./utils/PostRequest";
import { RootState } from "../root-redux/RootState";
import { BASE_URL_API_DEV, BASE_URL_API_PROD, BASE_URL_API_UAT } from "./utils/Constants";


export const previewInsightsOData = createAsyncThunk<Object, { payload: Insight[], family: string, entityType: string, subKey: string }, {state: RootState}>('apiPreviewInsights/previewInsightsOData', async ({ payload, family, entityType, subKey }, {getState} ) => { 
  const selectedEnv = getState().env.selectedEnvItem
  const baseUrl=() => {
    switch(selectedEnv){
      case 'dev':
        return BASE_URL_API_DEV;
        case 'uat':
          return BASE_URL_API_UAT;
          case 'prod':
            return BASE_URL_API_PROD;  
          default: 
          return BASE_URL_API_DEV;
     }
  }
    const response = await postRequestServiceAPI<Object, Insight[]>(baseUrl(), `odata/insights/preview_${family}?entityType=${entityType}`, payload, subKey)
    return response.data;
  })

  export const previewInsightsVanilla = createAsyncThunk<Object, { payload: Insight[], subKey: string}, {state: RootState}>('apiPreviewInsights/previewInsightsVanilla', async ({ payload, subKey }, {getState}) => { 
    const selectedEnv = getState().env.selectedEnvItem
    const baseUrl=() => {
      switch(selectedEnv){
        case 'dev':
          return BASE_URL_API_DEV;
          case 'uat':
            return BASE_URL_API_UAT;
            case 'prod':
              return BASE_URL_API_PROD;  
            default: 
            return BASE_URL_API_DEV;
       }
    }
    const response = await postRequestServiceAPI<Object, Insight[]>(baseUrl(), "insights/preview", payload, subKey)
    return response.data;
  })
