import { RequestStatus } from "../ServiceRequestStatus.model"
import { WorkflowStage } from "./WorkflowStage.model"

export class DatasetFileUploadModel {   
    public inputFileName!: string
    public inputFileBlobURI!: string
    public erroredFileName!: string
    public erroredFileBlobURI!: string
    public requestTimeStamp!: Date
    public fileValidationState!: FileValidationState
}

export class DatasetUploadResponse {   
    public fileUploadObj!: DatasetFileUploadModel
    public responseType!: DatasetResponseType
}

export enum DatasetResponseType
{
    Partial,
    Complete,
    Failed,
    Idle  
}
export enum FileValidationState
{
   Success,
   Failed,
   Errored
}
