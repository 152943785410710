import { Menu, MenuItem } from "../workflows/WorkflowsScope"
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../common/HarmonyEnablers"
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { getPreviewWorkflow } from "../../services/GetPreviewWorkflow.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { setIsPanelOpen } from "../preview-workflow/preview-workflow-redux/PreviewWorkflow.actions.redux";
import { addDataSet, deleteDataSet, moveDataSet } from "../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { Workflow } from "../../model/workflows/Workflow.model";
import { useEffect, useState } from "react";
import { WorkflowStageType } from "../../model/preview-workflow/WorkflowStageType.model";
import { moveMergeRuleSetDown, moveMergeRuleSetUp, moveModelDown, moveModelUp } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const EditMergeRuleMoveModelsC: React.FC<any> = (props: any) => {
    // redux states
    const dispatch = useDispatch<AppDispatch>()
    const {moveEnabledProp, subKey} = props
    const access = CheckPageAccessSelector(subKey)
    
    const [moveButtonEnabled, setMoveButtonEnabled] = useState(moveEnabledProp)

    const stopBubbling = (event: any) => {
        if (event.target === event.currentTarget) {
            event.stopPropagation();
        }
    }

    useEffect(()=>{
        setMoveButtonEnabled(moveEnabledProp)
    },[moveEnabledProp])

    return (
        <Menu>
            <MenuItem onClick={(e: any) => {stopBubbling(e) 
                dispatch(moveModelUp(moveButtonEnabled.curModel))}} disabled={!access.canEdit || !moveButtonEnabled.up}>
                <Icon slot='start' name='up'></Icon>
                Move Up
            </MenuItem>
            <MenuItem onClick={() => dispatch(moveModelDown(moveButtonEnabled.curModel))} disabled={!access.canEdit || !moveButtonEnabled.down}>
                <Icon slot='start' name='down'></Icon> 
                Move Down
            </MenuItem>
        </Menu>
    )
}
export const EditMergeRuleMoveModels = withErrorBoundary("Edit mergerule move model",EditMergeRuleMoveModelsC);