import { CampaignFilterQuery } from "./campaign.Model"

export class CampaignFilterRequest
{
    pageNum!: number
    query!: string
    queryName?: string
    fields!: {[key: string]: string }
    queryType!: QueryTypes
    inCart!: Array<string>
    outOfCart!: Array<string>
    filters!: Array<CampaignFilterQuery>
    reviewList!: Array<string>
    datasetId?: string

    public CampaignFilterRequest() { }
}

export enum QueryTypes
{
    VIEW_RESULTS = 1,
    IN_DATA_CART = 2,
    OUT_DATA_CART = 3,
    REVIEW_CART = 4
}