import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { DatasetConfig } from "../../../model/SPAModels/Dataset/datasetConfig.Model";
import { getDatasetConfigDetails } from "../../../services/Dataset/DatasetConfig.api";


interface DatasetConfigState  {
    datasetLayout: DatasetConfig | undefined,
    requestStatus: RequestStatus,
    error: string | undefined
}

const initialState: DatasetConfigState = {
    datasetLayout: undefined,
    requestStatus: RequestStatus.idle,
    error: undefined
};

export const DatasetConfigSlice = createSlice({
    name: 'datasetConfig',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getDatasetConfigDetails.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined
            })
            .addCase(getDatasetConfigDetails.fulfilled, (state, action) => {
                state.requestStatus = RequestStatus.succeeded
                state.error = undefined
                state.datasetLayout = action.payload;
            })
            .addCase(getDatasetConfigDetails.rejected, (state, action) => {
                state.requestStatus = RequestStatus.failed
                state.error = action.error.message
            })
    }
})

export const DatasetConfigReducer = DatasetConfigSlice.reducer;