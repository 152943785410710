import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../root-redux/RootState";
import { CAMPAIGN_ROWS_PER_PAGE } from "../../../services/utils/Constants";
import { FilterFields } from "../../../model/SPAModels/campaigns/campaignConfig.Model";
import { Row } from "@harmony/enablers/components/data-grid/data-grid";
import { taxonomySelector } from "./Taxonomy.selector.redux";
import { Helper } from "../utils/Help";
import { Prediction } from "../../../model/preview-mergerule/Prediction.model";
import { ATTRIBUTE_REFERENCE_ID_KEY, DATACART_COLUMN_SOURCE, DATACART_COLUMN_STATUS } from "../SPAConstants";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";

export const campaignCartListSelector = createSelector(
    (state: RootState) => state.campaignDataCart,
    (state: RootState) => state.campaignConfig,
    (state: RootState) => state.userSalesRoles,
    (state: RootState) => state.campaignById,
    (state: RootState) => state.workflowsBySubKeys,
    (campaignCartListState, configState, userSalesRoles, campaignById, workflowsBySubKeys) : { columns?: any[], rows: any[], totalPage: number, loading: boolean, error: string|undefined } => {
        
        let transformedToRowItems: Row[] = [];
        
        if (Helper.notReadyStatus(configState.requestStatus)) {
            return { columns: [], rows: transformedToRowItems, totalPage: 0, loading: true, error: undefined }
        }

        const columns = [{field: DATACART_COLUMN_STATUS, content: DATACART_COLUMN_STATUS}];
        
        configState.campaignConfig?.accountsCartFields.forEach((fields: FilterFields) => columns.push({
            field: fields.columnMap,
            content: fields.displayName,
        }));

        columns.push({field: DATACART_COLUMN_SOURCE, content: DATACART_COLUMN_SOURCE});

        if (Helper.notReadyStatus(campaignCartListState.requestStatus, userSalesRoles.requestStatus, workflowsBySubKeys.status, campaignById.requestStatus)) {
            Array.from({length: CAMPAIGN_ROWS_PER_PAGE}, (x: number) => {
                return transformedToRowItems.push({
                    id: (x + 1),
                    cells: {},
                    customData: x
                });
            });
            return { columns: columns, rows: transformedToRowItems, totalPage: 0, loading: true, error: undefined }
        }

        if (campaignCartListState.requestStatus === RequestStatus.failed) {
            return { columns: [], rows: [], totalPage: 0, loading: false, error: campaignCartListState.error }
        }
        
        if (campaignCartListState.requestStatus === RequestStatus.succeeded && Helper.isEmpty(campaignCartListState.predictions)) {
            return { columns: columns, rows: transformedToRowItems, totalPage: 0, loading: true, error: undefined }
        }

        const getAccountStatus = (id: string) => {
            const arList = campaignById.addReviewList.includes(id);
            const drList = campaignById.deleteReviewList.includes(id);
            const inCart = campaignById.campaign?.inCart.includes(id);
            if (userSalesRoles.isApprover) {
                return (inCart && !drList) ? 'Add' : (drList ? 'Delete' : arList ? 'Pending Review' : '');
            }
            return arList ? 'Add' : drList ? 'Delete' : '';
        }

        let dbColumnKeys: {[source: string]: string[]} = {};
        const getCoulmnMap = (param: string, source: string) => dbColumnKeys[source] ? dbColumnKeys[source].find(col => col.toLowerCase() === param.toLowerCase()) || param : param;

        const sourceKeys: any = {};
        workflowsBySubKeys.list.forEach(w => sourceKeys[w.config.workflowId.toLowerCase()] = w.config.workflowName);

        transformedToRowItems = campaignCartListState.predictions
            .filter((p: Prediction) => {
                const id = p.attributes.find(attr => attr.key === ATTRIBUTE_REFERENCE_ID_KEY)?.value || p.id;
                return !campaignCartListState.softDelete.includes(id);
            })
            .map((p: Prediction, idx: number) => {
                const id = p.attributes.find(attr => attr.key === ATTRIBUTE_REFERENCE_ID_KEY)?.value || p.id;
                const d: any = {};
                p.attributes.forEach((a: any) => {
                    d[a.key] = a.value;
                });
                d[DATACART_COLUMN_STATUS] = getAccountStatus(id);
                d[DATACART_COLUMN_SOURCE] = sourceKeys[p.source.sourceId.toLowerCase()] || '';
                if (Helper.isEmpty(dbColumnKeys) || Helper.isEmpty(dbColumnKeys[d[DATACART_COLUMN_SOURCE]])) {
                    dbColumnKeys[d[DATACART_COLUMN_SOURCE]] = Object.keys(d);
                }
                return {
                    id: (idx + 1),
                    cells: columns?.reduce((cell: any, column: any) => {
                        cell[column.field] = d[getCoulmnMap(column.field, d[DATACART_COLUMN_SOURCE])];
                        return cell;
                    }, {}),
                    customData: id
                }
            });

        const added = transformedToRowItems.filter(row => campaignById.campaign?.inCart.includes(row.id.toString()));
        const deleted = transformedToRowItems.filter(row => !campaignById.campaign?.inCart.includes(row.id.toString()));
        const allRows = added.concat(deleted);

        if (!userSalesRoles.isApprover && !campaignCartListState.pendingReview) {
            columns.splice(0, 1);
        }

        const totalPage = Helper.getTotalPage(allRows.length);
        const pageNo = campaignCartListState.pageNum - 1;

        transformedToRowItems = allRows.slice(pageNo * CAMPAIGN_ROWS_PER_PAGE, (pageNo * CAMPAIGN_ROWS_PER_PAGE) + CAMPAIGN_ROWS_PER_PAGE);

        return {columns: columns, rows: transformedToRowItems, totalPage: totalPage, loading: false, error: undefined}
    }
);

export const CartFilterSelector = createSelector(
    taxonomySelector,
    (state: RootState) => state.workflowsBySubKeys,
    (state: RootState) => state.campaignById.campaign?.dataSets,
    (taxonomy, workflowsBySubKeys, dataSets) => {
        const details: {[key:string]: Set<string>} = {};
        for(const key in taxonomy.taxonomyDetails) {
            for(const head in taxonomy.taxonomyDetails[key]) {
                details[head] = taxonomy.taxonomyDetails[key][head];
            }
        }
        const datasetsList: string[] = (dataSets || [])
            .map(ds => workflowsBySubKeys.list.find(w => w.config.workflowId.toLowerCase() === ds.toLowerCase())?.config.workflowName || "")
            .filter(source => source !== undefined && source !== "");

        details['Source'] = new Set(datasetsList);
        return { details: details }
    }
);