import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../root-redux/RootState";
import { CAMPAIGN_ROWS_PER_PAGE } from "../../../services/utils/Constants";
import { FilterFields } from "../../../model/SPAModels/campaigns/campaignConfig.Model";
import { Row } from "@harmony/enablers/components/data-grid/data-grid";
import { Helper } from "../utils/Help";
import { ATTRIBUTE_REFERENCE_ID_KEY } from "../SPAConstants";
import { Prediction } from "../../../model/preview-mergerule/Prediction.model";

export const filteredQueryDataListSelector = createSelector(
    (state: RootState) => state.filteredCampaign,
    (state: RootState) => state.campaignConfig,
    (state: RootState) => state.campaignById,
    (filteredCampaignState, configState, campaignById) : { columns?: any[], rows: any[], totalPage: number, allRows: any[], totalRows: number, loading: boolean } => {

        let transformedToRowItems: Row[] = [];

        if (Helper.notReadyStatus(configState.requestStatus, configState.requestStatus, campaignById.requestStatus)) {
            return { columns: [], rows: transformedToRowItems, totalPage: 1, allRows: [], totalRows: 0, loading: true }
        }
        
        const columns = configState.campaignConfig?.accountsCartFields.map((fields: FilterFields) => ({
            field: fields.columnMap,
            content: fields.displayName,
        }));

        if (Helper.notReadyStatus(filteredCampaignState.status)) {
            Array.from({length: CAMPAIGN_ROWS_PER_PAGE}, (x: number) => {
                return transformedToRowItems.push({
                    id: (x + 1),
                    cells: {},
                    customData: { "status": 'Draft', campaignId: '' }
                });
            });
            return { columns: columns, rows: transformedToRowItems, totalPage: 0, allRows: [], totalRows: 0, loading: true }
        }
        else if (filteredCampaignState.filterCampaignList.length === 0) {
            return { columns: columns, rows: [], totalPage: 0, allRows: [],  totalRows: 0, loading: false }
        }

        let totalRows = filteredCampaignState.filterCampaignList.length;

        let dbColumnKeys: string[] = [];
        const getCoulmnMap = (param: string) => dbColumnKeys.find(col => col.toLowerCase() === param.toLowerCase()) || param;

        const allRows = filteredCampaignState.filterCampaignList.map((p: Prediction, idx: number) => {
            const id = p.attributes.find(attr => attr.key === ATTRIBUTE_REFERENCE_ID_KEY)?.value || p.id;
            const d: any = {};
            p.attributes.forEach((a: any) => {
                d[a.key] = a.value;
            });
            if (Helper.isEmpty(dbColumnKeys)) {
                dbColumnKeys = Object.keys(d);
            }
            const disabled = campaignById.campaign?.inCart.includes(id);
            totalRows = totalRows - (disabled ? 1 : 0);
            return {
                id: (idx + 1),
                cells: columns?.reduce((cell: any, column: any) => {
                    cell[column.field] = d[getCoulmnMap(column.field)];
                    return cell;
                }, {}),
                customData: id,
                disabled: disabled
            }
        });

        const totalPage = Helper.getTotalPage(allRows.length);
        const pageNo = filteredCampaignState.currPage - 1;

        transformedToRowItems = allRows.slice(pageNo * CAMPAIGN_ROWS_PER_PAGE, (pageNo * CAMPAIGN_ROWS_PER_PAGE) + CAMPAIGN_ROWS_PER_PAGE);

        return {columns: columns, rows: transformedToRowItems, totalPage: totalPage, allRows: allRows, totalRows: totalRows, loading: false};
    }
);