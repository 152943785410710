import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { CatalogueSchemaObject } from "../../model/configModels/CatalogueSchemaObject.model";
import { updateCatalogueConfig } from "../../services/PostCatalogueConfig.api";

interface CatalogueConfig {
    catalogueScehma: CatalogueSchemaObject,
    status: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean
}

const initialState: CatalogueConfig = {
    catalogueScehma: {} as CatalogueSchemaObject,
    status: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false
}

export const catalogueConfigSlice = createSlice({
    name: 'catalogueConfig',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(updateCatalogueConfig.pending, (state, action) => {
                state.status = RequestStatus.loading
            }).addCase(updateCatalogueConfig.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload            
                }
                state.error = undefined
            })
            .addCase(updateCatalogueConfig.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
    }
});

export const catalogueConfigReducer = catalogueConfigSlice.reducer