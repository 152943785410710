import { BrowserRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { useAccount, useMsal } from "@azure/msal-react";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { useEffect } from "react";
import { getAppPersonas } from "../../services/GetAppPersonaMapping.api";
import { getSuperAdminEmail } from "../../services/GetSuperAdminEmail.api";
import { setUserDetails } from "../../shared-components/user-details/UserDetails.actions.redux";
import { UserDetails } from "../../model/user-details/UserDetails.model";
import { getUserGroups } from "../../services/GetUserGroups.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { setIsSuperAdmin } from "../../shared-components/super-admin/IsSuperAdmin.actions.redux";
import { AppBody } from "../app-body/AppBody";
import { AppHeader } from "../app-header/AppHeader";

export const AuthorizedApp: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { accounts, instance }  = useMsal();
    const account = useAccount(accounts[0]);
    const stateProps = useSelector((state: RootState) => ({
        AppPersonaItem: state.appPersonas.list,
        appPersonaStatus: state.appPersonas.status,
        UserGroups: state.userGroups.list,
        userGroupsStatus: state.userGroups.status,
        superAdminEmail: state.superAdmin.superAdminEmail,
        superAdminEmailStatus: state.superAdmin.status,
        tenantStatsStatus: state.tenantStats.status,
        selectedEnvironment: state.env.selectedEnvItem
    }
    ));
    const permissions = useSelector(permissionsSelector);

    // capturing the logged in user's details in the redux store
    useEffect(() => {
        const userDetailsData : UserDetails = {
            name: account?.name!,
            username: account?.username!,
            localAccountId: account?.localAccountId!
        }
        if (window.clarity) {
            window.clarity('set', 'user-id', userDetailsData.localAccountId);
            window.clarity("identify", userDetailsData.localAccountId, "custom-session-123", "custom-page-123")
          }
        dispatch(setUserDetails(userDetailsData));
    }, [account]);

    // dispatching app personas at the start of the application as RBAC needs to be implemented
    useEffect(() => {
        // const fetchData = async () => {
        // try {
        //     const graphToken = await instance.acquireTokenSilent({
        //     scopes: ["api://1aaf894a-0fdb-487c-ace5-cc90e423ff5c/.default"],
        //     account: account as AccountInfo
        //     });
        //     window.localStorage.setItem("api_token", graphToken.accessToken);
        // } catch (error) {
        //     console.error("Error:", error);
        // }
        // };
        
        // fetchData();

        if (stateProps.appPersonaStatus !== RequestStatus.loading && stateProps.AppPersonaItem.length === 0) {
            dispatch(getAppPersonas(null)); 
        }
        if (stateProps.superAdminEmailStatus !== RequestStatus.loading && !stateProps.superAdminEmail) {
            dispatch(getSuperAdminEmail());
        }
    }, [stateProps.selectedEnvironment]); 

    // getting all the groups, the logged in user is a part of
    useEffect(() => {
        const userEmailId = account?.username
        if (userEmailId && stateProps.userGroupsStatus !== RequestStatus.loading) {
        dispatch(getUserGroups(userEmailId))
        }
    }, [account?.username]);  

    useEffect(() => {
        // assigning super admin role, if user is eligible 
        if(stateProps.UserGroups?.includes(stateProps.superAdminEmail)) {
        dispatch(setIsSuperAdmin(true))
        };

        // if (permissions && permissions.userTeams.key.length > 0 && stateProps.tenantStatsStatus !== RequestStatus.loading) {
        // dispatch(getTenantStats(permissions.userTeams.key));
        // }  
    }, [stateProps.UserGroups, stateProps.selectedEnvironment]);

    return (
        <BrowserRouter>
            <AppHeader />
            <AppBody /> 
        </BrowserRouter>  
    )
}
