import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { CampaignStageType } from "../../../model/SPAModels/campaigns/CampaignStageType.Model";
import { addDataSet } from "../redux/CampaignById.redux";
import { Icon } from "../SpaAdminScope";

const EditCampaignEmptyStageC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const currStage = useSelector((state: RootState): number => state.campaignById.currentStage);

    const newDataSetMap = {
        [CampaignStageType.Input]:'',
        [CampaignStageType.ImportAndRefine]:'',
        [CampaignStageType.ValidateAndApprove]:'',
        [CampaignStageType.Publish]: 'Add publish step'
    }

    const text = newDataSetMap[currStage as CampaignStageType]

    if (!text) {
        return <></>
    }

    return (
        <div className="workflow-empty-stage">
            <div onClick={() => dispatch(addDataSet(false))}>
                <Icon className="workflow-empty-icon" name='addto'/>
                <div className="workflow-empty-text">{newDataSetMap[currStage as CampaignStageType]}</div>
            </div>            
        </div>
    )
}
export const EditCampaignEmptyStage = withErrorBoundary("Edit workflow empty stage",EditCampaignEmptyStageC);