import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { Spinner } from "../../../shared-components/loader/Spinner";
import { Button, DataGrid, Dialog, Icon } from "../SpaAdminScope";
import { PreSummaryStatus } from "../../../model/SPAModels/campaigns/campaign.Model";
import { ADBRunStatus, PreSummaryPowerBiLink } from "../SPAConstants";
import { getADBRunStatus, getCampaignById, refreshPreSummary } from "../../../services/SPAAdmin/CampaignById.api";
import { setPreSummary } from "../redux/CampaignById.redux";
export const CampaignPreSummary: React.FC<any> = React.memo((props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const campaignById = useSelector((state: RootState) => state.campaignById);
    const loading = useSelector((state: RootState) => state.campaignById.requestStatus === RequestStatus.loading );
    const showPreSummary = useSelector((state: RootState) => state.campaignById.showPreSummary);
    const selectedEnv = useSelector((state: RootState) =>state.env.selectedEnvItem );

    const [refreshPreSummaryLoading,setRefreshPreSummaryLoading]:any = useState<boolean>(false);

    const [refreshADBFailed,setRefreshADBFailed]:any = useState<boolean>(false);


    const handleRequestClose = (event: any) => {
        
        dispatch(setPreSummary(false));
       
    }
    
    const handleRefreshPreSummary = () => {
        setRefreshPreSummaryLoading(true);
        dispatch(refreshPreSummary({id: campaignById?.campaign?.config ?.workflowId?? ''})).unwrap()
        .then((response) => { if(response) {
            dispatch(getCampaignById(campaignById?.campaign?.config ?.workflowId??'')).unwrap().then((result) => {
                setRefreshPreSummaryLoading(false);
            });
        }

        });
       
    }
const isReportRefreshed = (modifiedDate:string) => {
    var isReportRefreshed = false;
    isReportRefreshed=modifiedDate?.length>0?new Date().getTime() - new Date(modifiedDate).getTime() > (2*60*60*1000) ?  true :  false :  false;
    return isReportRefreshed;
}

    useEffect(()=>{
        const preSummary = campaignById?.campaign?.preSummary;
                if(preSummary && preSummary.suppression && preSummary.suppression.status === PreSummaryStatus.IN_PROGRESS){
                    if(preSummary.suppression.adbRunId){
                        dispatch(getADBRunStatus(preSummary.suppression.adbRunId)).unwrap().then((response) => {
                            if(response === ADBRunStatus.FAILED){
                                setRefreshADBFailed(true);
                            }                    
                        });
                    }
                }
    },[campaignById]);   
    useEffect(() => {
        if(showPreSummary){
            //Refresh is not triggered
                if(campaignById?.campaign && (campaignById?.campaign?.preSummary === undefined || campaignById?.campaign?.preSummary === null || campaignById.campaign.preSummary.suppression?.status?.length === 0 ))
                {
                    handleRefreshPreSummary();
                }
                
        }
    },[showPreSummary])

        return (
            <Dialog
            style={{width: 'auto'}}
            heading={`Campaign pre summary for  ${campaignById?.campaign?.config?.workflowName}`}
            open={showPreSummary}
            onHeRequestClose={handleRequestClose}
            className="release-diff-dialog"
            >            
            {
                (loading || refreshPreSummaryLoading) && <div className='release-diff-loader'><Spinner type='medium' /></div>
            }
            <div>
                {
                    campaignById?.campaign && campaignById?.campaign?.preSummary?.suppression && campaignById?.campaign?.preSummary.suppression.status === PreSummaryStatus.IN_PROGRESS 
                    && (refreshADBFailed ?<Button appearance="link" 
                    onClick={handleRefreshPreSummary}>
                    Try refresh again</Button>: <span>Pre Summary In Progress</span>
                    )
                }

            </div>
            <div>
                {
                    campaignById?.campaign && campaignById?.campaign?.preSummary?.suppression && campaignById?.campaign?.preSummary.suppression.status === PreSummaryStatus.PUBLISHED 
                    && <Button appearance="link" 
                    onClick={() => window.open(PreSummaryPowerBiLink[selectedEnv]+"'"+campaignById?.campaign?.tags+"'", '_blank')!.focus()}>
                    Pre Summary Report </Button>
                }
               
            </div>
            
            <div style={{float:"right"}}>
            <span>{
                    campaignById?.campaign && campaignById?.campaign?.preSummary?.suppression && (campaignById?.campaign?.preSummary.suppression.status === PreSummaryStatus.PUBLISHED )
                    && <Button onClick={handleRefreshPreSummary} title="Refresh report again"><Icon name="refresh"/> </Button>
            }
            </span>
            </div>
            </Dialog>

        )
    }
)
