
import { useDispatch, useSelector } from "react-redux";
import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model";
import { CampaignStageType } from "../../../model/SPAModels/campaigns/CampaignStageType.Model";
import { useState, useEffect, useCallback } from "react";
import { WorkflowStageType } from "../../../model/preview-workflow/WorkflowStageType.model";
import { WorkflowStage } from "../../../model/workflows/WorkflowStage.model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Merge } from "../../merge-workflow/Merge";
import { updateCurrentStepId } from "../../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { Icon, Tooltip, Dropdown, Button } from "../SpaAdminScope";
import { EditCampaignSideBarMenu } from "./EditCampaignSideBarMenu";
import { updateIsSideNavOpen } from "../redux/CampaignById.redux";
import { CampaignTypes } from "../SPAConstants";
import { CheckCampaignPageAccessSelector } from "../redux/CheckCampaignAccess.selectors.redux";

const EditCampaignSideBarC = () => {
    // redux states
    const dispatch = useDispatch<AppDispatch>()
    const campaign = useSelector((state: RootState): Campaign => state.campaignById.campaign || {} as Campaign);
    const currStage = useSelector((state: RootState): number => state.campaignById.currentStage);
    const currStepId = useSelector((state: RootState): string => state.campaignById.currentStepId);
    const isSideNavOpen = useSelector((state: RootState): boolean => state.campaignById.isSideNavOpen);
    const summary = useSelector((state: RootState) => state.campaignById.summary);
    const [currStageSteps, setCurrStageSteps] = useState([] as WorkflowStage[])
    const access = CheckCampaignPageAccessSelector(campaign?.subscriptionKey)

    const sideBarHeaderMap = {

        [CampaignStageType.Input]: '',
        [CampaignStageType.ImportAndRefine]: '',
        [CampaignStageType.ValidateAndApprove]: '',
        [CampaignStageType.Publish]: 'Publish Datasets'
    }

    const isStandardSPA = useCallback(() => CampaignTypes.includes(summary['CampaignType']!), []);

    // set the current step details in local state to render the side tabs with those details whenever stage or step id changes
    useEffect(() => {
        if (!campaign) return

        const currStageNumber = currStage
        switch (currStageNumber) {

            case WorkflowStageType.Publish:
                setCurrStageSteps(campaign.publish?.length > 0 ? campaign.publish : [])
                break;

        }
    }, [currStage, campaign])

    const onClickStep = (event: any, item: WorkflowStage, index: number) => {
        if (event.target.className === 'workflow-side-tab-type' || event.target.className === 'workflow-side-tab-output-viewname'
            || event.target === event.currentTarget || event.target.name === 'more') {
            dispatch(updateCurrentStepId({ stepId: item.stepID, stepIndex: index }))
        }
    }

    return (
        <>
            <div className="workflow-side-bar-wrapper" style={{ overflow: "auto", display: isSideNavOpen ? 'block' : 'none' }} >
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div style={{display: "flex", width: "95%"}}>
                        <div className='workflow-side-bar-header' style={{ flex: "50%" }}>{sideBarHeaderMap[currStage as CampaignStageType]}
                            {currStage === WorkflowStageType.Merge &&
                                <span>
                                    <Icon id="info100" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                                    <Tooltip anchor="info100" placement='end'>
                                        Merge configuration curates recommendations from insights/input recommendations published in each insight(s)/recommendation(s), using the merge rules created.
                                    </Tooltip>
                                </span>
                            }
                        </div>
                    
                        <Button slot="trigger" appearance="stealth" style={{width: "32px", paddingRight: "10px", borderRadius: "20px"}} onClick={() => dispatch(updateIsSideNavOpen(!isSideNavOpen))}>
                            <Icon style={{ fontSize: '12px' }} name='doublechevronleftmed' slot='end' />
                        </Button>
                    </div>
                </div>
                <div style={{ marginTop: '14px' }}>
                    {currStepId && currStageSteps && currStageSteps.map((item, index) => {
                        const viewName = currStage !== WorkflowStageType.Publish ? item.outputViewName : item.inputViewName
                        if (viewName !== 'vw_actionpublish') {
                            return (
                                <div className={`workflow-side-tab ${item.stepID !== currStepId ? '' : 'workflow-side-tab-selected'}`} onClick={(e: any) => { onClickStep(e, item, index) }}>
                                    <div>
                                        <span className="workflow-side-tab-type" style={{ fontWeight: '500', marginRight: '5px' }}>{item.type}:</span>
                                        <span title={viewName} className='workflow-side-tab-output-viewname'>{viewName}</span>
                                    </div>
                                    {access.canEdit && !isStandardSPA() && <Dropdown>
                                        <Icon slot='trigger' name='more' />
                                        <EditCampaignSideBarMenu />
                                    </Dropdown>}
                                </div>
                            )
                        }
                        return <></>;
                    })}
                    {currStage === WorkflowStageType.Merge &&
                        <Merge />}
                </div>
            </div>
            <div className="workflow-side-bar-wrapper-collapsed" style={{ display: isSideNavOpen ? 'none' : 'block', borderRight: isSideNavOpen ? "1px solid var(--he-color-neutral-200)" : "0px" }} >
                <div style={{ display: 'flex', marginTop: '10px' }}>
                    <Button slot="trigger" appearance="stealth" style={{width: "32px", paddingRight: "10px", borderRadius: "20px"}} onClick={() => dispatch(updateIsSideNavOpen(!isSideNavOpen))}>
                        <Icon style={{ fontSize: '12px' }} name='doublechevronleftmedmirrored' slot='end' />
                    </Button>
                </div>
            </div>
        </>
    )
}
export const EditCampaignSideBar = withErrorBoundary("Edit workflowsidebar", EditCampaignSideBarC);