import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../../common/HarmonyEnablers"
import { AppDispatch, RootState } from "../../root-redux/RootState";
import "./preview-mergerule-styles.css"
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { } from "./preview-mergerule-redux/PreviewMergeRule.actions.redux";

export const PreviewMergeRuleHeaderBar: React.FC<any> = (props: any) => {
    // redux states
    const dispatch = useDispatch<AppDispatch>()
    const { mergeruleSetId ,source} = props

    return (
        <div>
            Shows preview of how merge rule {mergeruleSetId && 'set'} will be applied on insights/input recommendations to generate recommendations.
            <div style={{ fontWeight: "350", margin: "5px 10px 0px 0px", fontSize: "14px" }}>
                <i>NOTE: </i><br />
                The recommendations shown below are not saved to Cosmos.<br />
                {mergeruleSetId && !source &&
                    <div>All the rulesets with rank less than or equal to rank of selected merge ruleset will be applied by default. 
                        You can toggle on <i>Apply only selected Merge Ruleset</i> if you wish to apply only the selected merge ruleset.<br />
                        If selected merge ruleset is of recommendation generation type <i>Enrich existing rec</i>, then all rule sets of  recommendation generation type <i>Generate new rec</i> are applied as well. 
                    </div>
                }
                {mergeruleSetId && source && source === "header" && 
                    <div>
                        If selected merge ruleset is of recommendation generation type <i>Enrich existing rec</i>, then all rule sets of  recommendation generation type <i>Generate new rec</i> are applied as well.<br /> 
                        Else only current merge ruleset is applied.
                    </div>
                }
            </div>
        </div>
    )
}