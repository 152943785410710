import { APIResponseMappingObject } from '../../model/apiConfig/APIResponseMappingObject.model';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from '../utils/GetRequest';
import { RootState } from '../../root-redux/RootState';

export const getApiConfig = createAsyncThunk<APIResponseMappingObject, {routeType: string, subsKey: string}, { state: RootState }>('apiConfig/getApiConfig', async ({routeType, subsKey}, {getState} ) => {
  const selectedEnv = getState().env.selectedEnvItem
  //let path = env + '/APIResponseMapping/' + routeType + '?subscriptionKey=' + subsKey
  const response = await getRequest<APIResponseMappingObject>(`${selectedEnv}/APIResponseMapping/${routeType}?subscriptionKey=${subsKey}`)
    return response.data;
  })

  export const getAllApiConfig = createAsyncThunk<APIResponseMappingObject, {routeType: string}, { state: RootState }>('apiConfig/getAllApiConfig', async ({routeType},{getState} ) => {
    const env = getState().env.selectedEnvItem
    const response = await getRequest<APIResponseMappingObject>(`${env}/APIResponseMapping/${routeType}`)
    return response.data;
  })

  export const getApiConfig_dev = createAsyncThunk<APIResponseMappingObject, {routeType: string, subsKey: string}>('apiConfig/getApiConfig_dev', async ({routeType, subsKey}) => {
      const response = await getRequest<APIResponseMappingObject>(`dev/APIResponseMapping/${routeType}?subscriptionKey=${subsKey}`)
      return response.data;
    })

    export const getApiConfig_uat = createAsyncThunk<APIResponseMappingObject, {routeType: string, subsKey: string}>('apiConfig/getApiConfig_uat', async ({routeType, subsKey}) => {
        const response = await getRequest<APIResponseMappingObject>(`uat/APIResponseMapping/${routeType}?subscriptionKey=${subsKey}`)
        return response.data;
   })

   export const getApiConfigRecom = createAsyncThunk<APIResponseMappingObject, {subsKey: string}, { state: RootState }>('apiConfig/getApiConfigRecom', async ({subsKey}, {getState} ) => {
    const selectedEnv = getState().env.selectedEnvItem
    const response = await getRequest<APIResponseMappingObject>(`${selectedEnv}/APIResponseMapping/Recommendation?subscriptionKey=${subsKey}`)
      return response.data;
    })

    export const getApiConfigRecom_dev = createAsyncThunk<APIResponseMappingObject, {subsKey: string}>('apiConfig/getApiConfigRecom_dev', async ({subsKey}) => {
      const response = await getRequest<APIResponseMappingObject>(`dev/APIResponseMapping/Recommendation?subscriptionKey=${subsKey}`)
      return response.data;
    })

    export const getApiConfigRecom_uat = createAsyncThunk<APIResponseMappingObject, {subsKey: string}>('apiConfig/getApiConfigRecom_uat', async ({subsKey}) => {
        const response = await getRequest<APIResponseMappingObject>(`uat/APIResponseMapping/Recommendation?subscriptionKey=${subsKey}`)
        return response.data;
   })

   export const getApiConfigInsight = createAsyncThunk<APIResponseMappingObject, {subsKey: string}, { state: RootState }>('apiConfig/getApiConfigInsight', async ({subsKey}, {getState} ) => {
    const selectedEnv = getState().env.selectedEnvItem
    const response = await getRequest<APIResponseMappingObject>(`${selectedEnv}/APIResponseMapping/Insight?subscriptionKey=${subsKey}`)
      return response.data;
    })

    export const getApiConfigInsight_dev = createAsyncThunk<APIResponseMappingObject, {subsKey: string}>('apiConfig/getApiConfigInsight_dev', async ({subsKey}) => {
      const response = await getRequest<APIResponseMappingObject>(`dev/APIResponseMapping/Insight?subscriptionKey=${subsKey}`)
      return response.data;
    })

    export const getApiConfigInsight_uat = createAsyncThunk<APIResponseMappingObject, {subsKey: string}>('apiConfig/getApiConfigInsight_uat', async ({subsKey}) => {
        const response = await getRequest<APIResponseMappingObject>(`uat/APIResponseMapping/Insight?subscriptionKey=${subsKey}`)
        return response.data;
   })
    