
import { FlyInPanel } from "../../../common/HarmonyEnablers";
import "./HelpContent.css";

export const HelpContent: React.FC<any> = (props: any) => {

  const handleRequestCloseEditPopup = (event: any) => {
    if ( event.target === event.currentTarget && event.detail.source === 'close-button') {
      props.setOpenHelpPanel(false)
    } else {
      event.preventDefault()
    }
  }
  return (
    <>
      <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)' }}
        open={props.openHelpPanel}
        onHeRequestClose={handleRequestCloseEditPopup}
        heading="Help"
      >
        <div>
          <div>
            <h5 id="allmergerules">All Merge Rules</h5>
          </div>

          <div className="help-mainHeading">
            <span className="help-heading">Summary:</span>
            <span className="help-content">TBD</span>
          </div>
        </div>
      </FlyInPanel>
    </>
  );
};