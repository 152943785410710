import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../root-redux/RootState";
import { postRequest } from "./utils/PostRequest";
import { postWorkflow } from "./PostWorkflow.api";
import { Job, Schedule, Settings } from "../model/workflows/Schedule.model";
import { workflowByIdSlice } from "../feature-components/workflows/workflow-by-id-redux/WorkflowById.redux";

export const updateScheduleJobStatus = createAsyncThunk<{success: boolean, error: string | undefined} | undefined, {pause_status: 'pause' | 'unpause' | 'trigger', updateWorkflow?: boolean}, {state: RootState}>('workflows/updateScheduleJobStatus', async ({pause_status, updateWorkflow}, { getState, dispatch }) => {
    const schedule  = getState().workflowById.workflow?.schedule
    const workflow = getState().workflowById.workflow
    const workflowId = workflow?.config?.workflowId
    const selectedEnv = getState().env.selectedEnvItem


    if (!workflowId || !schedule.jobId) {
      return
    }

    const job = new Job()
    job.job_id = Number(schedule.jobId)
    job.settings = new Settings()
    job.settings.schedule = new Schedule()
    job.settings.schedule.pause_Status = pause_status
    
    const response = await postRequest<boolean | {Code: string, Message: string}, Job>(`${selectedEnv}/workflows/job`, job)
  
    const error = response.data as {Code: string, Message: string}

    if (error && error.Code) {
      return {success: false, error: error.Message}
    }

    await dispatch(
      workflowByIdSlice.actions.updateSchedule({
        ...schedule,
        pause_Status: pause_status === 'trigger' ? schedule.pause_Status : (pause_status + "d"),
      })
    )

    if (updateWorkflow) {
      await dispatch(postWorkflow({workflow: null, env: null}))
    }    

    return {success: true, error: undefined}
  })