import { useEffect, useState } from "react";
import { FilterComponent } from "./FilterPill";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { MergeRuleDataGridRow, allFamilySelector, allWorkflowsSelector, mergeRuleItemBySubKeySelector, mergeRuleItemSelector, tenantcheckedStateSelector } from "../merge-rules-redux/MergeRulesListColumns.selectors.redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { allTeamsSelector } from "../../../shared-components/app-personas/AppPersona.selectors.redux";
import { allUsersOfMergerulesSelector, allUsersSelector } from "../../../shared-components/user-object/UserObject.selectors.redux";
import { permissionsSelector } from "../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { mergeRuleBySubKeyReducer } from "../merge-rules-by-id-redux/MergeRuleBySubKey.redux";
import { useDispatch } from "react-redux";
import { IKeyValueCheckedState, setFamilyCheckedState, setTenantCheckedState, setUserCheckedState, setWorkflowCheckedState } from "../merge-rules-redux/MergeRules.redux";

export const FilterMergeRules = (props: any) => {
    const { subKey } = props
    const dispatch = useDispatch<AppDispatch>();

    const familycheckedState = useSelector((state:RootState) => state.mergeRules.familycheckedState);
    const workflowcheckedState = useSelector((state:RootState) => state.mergeRules.workflowcheckedState);
    const tenantcheckedState = useSelector(tenantcheckedStateSelector);
    const usercheckedState = useSelector((state:RootState) => state.mergeRules.usercheckedState);
  
    const allTenants = useSelector(allTeamsSelector);
    const allUsers = useSelector(allUsersOfMergerulesSelector);
    var allFamilies= useSelector((state: RootState) => allFamilySelector(state, subKey))
    var allWorkflows= useSelector((state:RootState) => allWorkflowsSelector(state, subKey))

    useEffect(() => {
        if(Object.keys(usercheckedState).length === 0) {
        const usersObj: IKeyValueCheckedState = {};
        allUsers.map((user) => {
            usersObj[user] = false;
        });
        updateUserCheckedState(usersObj)
        }
    }, [allUsers]);

    useEffect(() => {
        if(Object.keys(familycheckedState).length === 0) {
        const familiesObj: IKeyValueCheckedState = {};
        allFamilies.map((family) => {
            familiesObj[family] = false;
        });
        updateFamilyCheckedState(familiesObj)
        }
    }, [allFamilies]);

    useEffect(() => {
        if(Object.keys(workflowcheckedState).length === 0) {
        const workflowsObj: IKeyValueCheckedState = {};
        allWorkflows.map((workflow) => {
            workflowsObj[workflow] = false;
        });
        updateWorkflowCheckedState(workflowsObj)
        }
    }, [allWorkflows]);

    const updateTenantCheckedState = (tenant: {[key: string]: boolean}) => {
        dispatch(setTenantCheckedState(tenant))
    }

    const updateUserCheckedState = (user: {[key: string]: boolean}) => {
        dispatch(setUserCheckedState(user))
    }

    const updateWorkflowCheckedState = (workflow: {[key: string]: boolean}) => {
        dispatch(setWorkflowCheckedState(workflow))
    }

    const updateFamilyCheckedState = (family: {[key: string]: boolean}) => {
        dispatch(setFamilyCheckedState(family))
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", marginLeft: '10px' }}>
                {!subKey && <FilterComponent checkedState={tenantcheckedState} setCheckedState={updateTenantCheckedState} filterlabel="Tenant: " searchBarVisible={true} allData={allTenants} />}
                <FilterComponent checkedState={familycheckedState} setCheckedState={updateFamilyCheckedState} filterlabel="Family: " searchBarVisible={true} allData={allFamilies} />
                <FilterComponent checkedState={workflowcheckedState} setCheckedState={updateWorkflowCheckedState} filterlabel="Insight/Recommendation: " searchBarVisible={true} allData={allWorkflows} />
                {/* <FilterComponent stateObj={modelsObj} checkedState={modelcheckedState} setCheckedState={setModelCheckedState} filterlabel="Model: " searchBarVisible={true} allData={allModels} /> */}
                <FilterComponent checkedState={usercheckedState} setCheckedState={updateUserCheckedState} filterlabel="Modified By: " searchBarVisible={false} allData={allUsers} />
            </div>
        </>

    );
};