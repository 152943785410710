import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useDispatch, useSelector } from "react-redux";
import { UserProfileCard } from "../user-profile/UserProfileCard";
import { UserEmailComponent } from "../user-profile/userEmailComponent";
import "./app-header-styles.css";
import { Button, Icon, Tooltip } from "../../common/HarmonyEnablers";
import { Dropdown } from "../../shared-components/dropdown-filter/DropdownFilterScope";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { useNavigate } from "react-router-dom";
import { setShowDialog } from "../../shared-components/feedback/Feedback.redux";
import { NebulaInformation } from "../nebula-info/NebulaInformationCard";
import { useState } from "react";
import  HelpContent  from "../../feature-components/workflows/help-workflows/HelpContent";

export const AppHeader: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const stateProps = useSelector((state: RootState) => ({
    selectedNavItem: state.nav.selectedNavItem,
    selectedEnvItem: state.env.selectedEnvItem,
    isNavPanelOpen: state.nav.isNavPanelOpen,
    selectedAppPersonaItem: state.appPersonas.list,
    userEmail: state.userDetails.username,
  }));
  const navigate = useNavigate();
  const [openHelpPanel, setOpenHelpPanel] = useState(false);

  const envMap = {
    dev: "Development",
    uat: "Staging",
    prod: "Production",
  };

  return (
    <div className="app-header-wrapper">
      <div className="app-header">
        <div className="app-header-title">
          <span
            style={{
              fontWeight: "600",
              color: "white",
              cursor: "pointer",
              fontSize: "18px",
            }}
            onClick={() =>
              navigate("/" + NavMenuItemType[NavMenuItemType.Home])
            }
          >
            Nebula
          </span>
          <span 
            id = "appenvironment"
            style={{
              fontWeight: "500",
              color: "white",
              marginLeft: "6px",
              fontSize: "14px",
            }}
          >
             |{" "}
            {stateProps.selectedEnvItem
              ? envMap[stateProps.selectedEnvItem as "dev" | "uat" | "prod"]
              : ""}
          </span>
        </div>
        <div className="app-header-actions">

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Dropdown fixed-placement>
                <Button
                  slot="trigger"
                  appearance="stealth"
                  className="headerButton"
                  onClick={() => {
                    setOpenHelpPanel(!openHelpPanel);
                  }}
                  id = "helpappheader"
                >
                  <Icon
                    name="help"
                    label="help"
                    style={{ color: "white" }}
                  >
                    {" "}
                  </Icon>
                  <Tooltip anchor="helpappheader">Help</Tooltip>
                </Button>
              </Dropdown>
            </div>

            <div>
              <Button
                appearance="stealth"
                className="headerButton"
                onClick={() => dispatch(setShowDialog(true))}
                id = "nebulafeedbackappheader"
              >
                <Icon
                  name="feedback"
                  label="feedback"
                  style={{ color: "white" }}
                ></Icon>
              </Button>
            </div>

            <div>
              <Dropdown fixed-placement>
                <Button
                  slot="trigger"
                  appearance="stealth"
                  className="headerButton"
                  id = "userprofileappheader"
                >
                  <UserEmailComponent />
                </Button>
                <UserProfileCard />
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <HelpContent
        openHelpPanel={openHelpPanel}
        setOpenHelpPanel={setOpenHelpPanel}
      />
    </div>
  );
};
