
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { NavMenuItemType } from '../../../model/NavMenu.model';
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { 
    Breadcrumb, BreadcrumbItem, Button, Icon, PageTitle
} from '../SpaAdminScope';
import { Initialize } from '../utils/Initialize';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../../root-redux/RootState';
import { UserMode, setTabCache } from '../redux/CacheHandler.redux';
import { setEditCampaignFlag } from '../redux/CampaignById.redux';

interface ICampaignHeader {
    title: string | undefined,
    workflowName: string| undefined,
    currentPage: number
}

const EditCampaignHeader: React.FC<ICampaignHeader> = (props: ICampaignHeader) => {

    const navigate = useNavigate();
    const dispatchApp = useDispatch<AppDispatch>();
    const cacheHandler = useSelector((state: RootState) => state.cacheHandler);
    const [selectedTab, setSelectedTab] = useState<string>(cacheHandler.tabCache);

    const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
        navigate("/" + NavMenuItemType[navMenuItemType]);
    };

    const onCloseClick = () => {
        if(props.title === "Dataset")
        {
            setSelectedTab("datasetTab");
            dispatchApp(setTabCache("datasetTab"));
        }
        else
        {
            setSelectedTab("spaTab");
            dispatchApp(setTabCache("spaTab"));
            dispatchApp(setEditCampaignFlag(false));
        }
        _onNavMenuItemClick(NavMenuItemType.Configuration);
    };

    return (
        <>
            <Initialize />
            
            <Breadcrumb className='spa-workspace'>
                <BreadcrumbItem
                    style={{ color: "#0078D4", fontWeight: "400" }}
                    onClick={() => _onNavMenuItemClick(NavMenuItemType.Home)}
                >
                    Home
                </BreadcrumbItem>
                <BreadcrumbItem
                    style={{ color: "#0078D4", fontWeight: "400" }}
                    onClick={() => _onNavMenuItemClick(NavMenuItemType.Configuration)}
                >
                    Configuration
                </BreadcrumbItem>
            </Breadcrumb>

            <div className='spa-workspace'>
                <div className='sticky-header spa-header-style' style={{ marginBottom: "20px" }}>
                    <PageTitle>
                        {props.title === "Dataset" ? 
                        <>
                        {cacheHandler.datasetCache?.mode === UserMode.CREATE ? 'Create New Dataset' : 'Edit Dataset'}
                        {props.workflowName && <span slot="active-title">{props.workflowName}</span>}
                        </> : 
                        <>
                        {cacheHandler.campaignCache?.mode === UserMode.CREATE ? 'Create New Campaign' : 'Edit Campaign'}
                        {props.title && <span slot="active-title">{props.title}</span>}
                        </>}
                    </PageTitle>
                </div>
            </div>

            <div className="workflow-cancel-button">
                <Button
                    appearance="link"
                    onClick={onCloseClick}
                >
                    <Icon
                        label="workflow-cancel-button"
                        name="calculatormultiply"
                        style={{ width: "24px", height: "24px" }}
                    />
                </Button>
            </div>
        </>
    );
}

export const CampaignHeader = withErrorBoundary("Campaign Header", EditCampaignHeader);