import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { PreviewWorkflow } from '../../../model/preview-workflow/PreviewWorkflow.model'
import { getPreviewWorkflow } from '../../../services/GetPreviewWorkflow.api'
import { postMergeRulePreview } from '../../../services/PostMergeRulePreview.api'
import { postMergePreviewForRecommendationApi } from '../../../services/PostMergeRulePreview.api'
import { MergeRulePreviewOutput } from '../../../model/preview-mergerule/MergeRulePreviewOutput.model'
import { MergeRulePreviewUserInput } from '../../../model/preview-mergerule/MergeRulePreviewUserInput.model'

interface PreviewMergeRuleState {
    previewMergeRuleOutput: MergeRulePreviewOutput,
    status: RequestStatus,
    statusForRecom: RequestStatus,
    error: string | undefined,
    previewMergeRuleUserInput: MergeRulePreviewUserInput,
    errorCode: string | undefined,
    customMsg: string | undefined
}

const initialState: PreviewMergeRuleState = {
    previewMergeRuleOutput: {} as MergeRulePreviewOutput,
    status: RequestStatus.idle,
    statusForRecom: RequestStatus.idle,
    error: '',
    previewMergeRuleUserInput: {} as MergeRulePreviewUserInput,
    errorCode: '',
    customMsg: ''
}
export const previewMergeRuleSlice = createSlice({
    name: 'previewMergeRule',
    initialState,
    reducers: {
        updatePreviewMergeRuleUserInput: (state, action: PayloadAction<MergeRulePreviewUserInput>) => {
            state.previewMergeRuleUserInput = action.payload
        },
        resetStatusForMergePreviewForRecommendationApi: (state) => { 
        state.statusForRecom = RequestStatus.idle
        // Can remove if its causing issue
        state.previewMergeRuleOutput = {} as MergeRulePreviewOutput
       }

    },
    extraReducers(builder) {
        builder
            .addCase(postMergeRulePreview.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(postMergeRulePreview.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload?.OperationId) {
                    state.previewMergeRuleOutput = {} as MergeRulePreviewOutput
                    state.error = payload.Message
                    state.errorCode = payload.Code
                    state.customMsg = payload.CustomMessage
                } else {
                    state.previewMergeRuleOutput = payload
                    state.error = undefined
                    state.errorCode = undefined
                    state.customMsg = undefined
                }
            })
            .addCase(postMergeRulePreview.rejected, (state, action) => {
                state.statusForRecom = RequestStatus.failed
                state.error = action.error.message
                state.errorCode = undefined
                state.customMsg = undefined
            })
            .addCase(postMergePreviewForRecommendationApi.pending, (state, action) => {
                state.statusForRecom = RequestStatus.loading
            })
            .addCase(postMergePreviewForRecommendationApi.fulfilled, (state, action) => {
                state.statusForRecom = RequestStatus.succeeded
                const payload = action.payload
                if (payload?.OperationId) {
                    state.previewMergeRuleOutput = {} as MergeRulePreviewOutput
                    state.error = payload.Message
                    state.errorCode = payload.Code
                    state.customMsg = payload.CustomMessage
                } else {
                    state.previewMergeRuleOutput = payload
                    state.error = undefined
                    state.errorCode = undefined
                    state.customMsg = undefined
                }
            })
            .addCase(postMergePreviewForRecommendationApi.rejected, (state, action) => {
                state.statusForRecom = RequestStatus.failed
                state.error = action.error.message
                state.errorCode = undefined
                state.customMsg = undefined
            })
            
    }
})

export const {  } = previewMergeRuleSlice.actions


export const previewMergeRuleReducer = previewMergeRuleSlice.reducer