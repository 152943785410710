import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../root-redux/RootState";


export const allTeamsSelector = createSelector(( state: RootState) => state.appPersonas.list, (appPersonaItems) => {
    var allTeams : string[] =[];
    appPersonaItems.map((item) => {
        allTeams.push(item.appName)
    });
    return allTeams;
})