import { createAsyncThunk } from "@reduxjs/toolkit";
import { Recommendation } from "../model/preview-mergerule/Recommendation.model";
import { postRequest, postRequestServiceAPI } from "./utils/PostRequest";
import { RootState } from "../root-redux/RootState";
import { BASE_URL_API_DEV, BASE_URL_API_PROD, BASE_URL_API_UAT } from "./utils/Constants";


export const previewRecommendationsOData = createAsyncThunk<Object, { payload: Recommendation[], family: string, subKey: string, productFamily: string }, {state: RootState}>('apiPreviewRecommendations/previewRecommendationsOData', async ({ payload, family, subKey, productFamily }, {getState} ) => { 
  
  const selectedEnv = getState().env.selectedEnvItem
  const baseUrl=() => {
    switch(selectedEnv){
      case 'dev':
        return BASE_URL_API_DEV;
        case 'uat':
          return BASE_URL_API_UAT;
          case 'prod':
            return BASE_URL_API_PROD;  
          default: 
          return BASE_URL_API_DEV;
     }
  }
    const response = await postRequestServiceAPI<Object, Recommendation[]>(baseUrl(), `odata/recommendations/preview_${family}?type=${productFamily}`, payload, subKey)
    return response.data;
  })

  export const previewRecommendationsVanilla = createAsyncThunk<Object, { payload: Recommendation[], partitionKey: string, subKey: string}, {state: RootState}>('apiPreviewRecommendations/previewRecommendationsVanilla', async ({ payload, partitionKey, subKey }, {getState}) => { 
    const selectedEnv = getState().env.selectedEnvItem
    const baseUrl=() => {
      switch(selectedEnv){
        case 'dev':
          return BASE_URL_API_DEV;
          case 'uat':
            return BASE_URL_API_UAT;
            case 'prod':
              return BASE_URL_API_PROD;  
            default: 
            return BASE_URL_API_DEV;
       }
    }
    const response = await postRequestServiceAPI<Object, Recommendation[]>(baseUrl(), `/recommendations/preview?groupBy=${partitionKey}`, payload, subKey)
    return response.data;
  })
