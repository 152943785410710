import {IPublicClientApplication, PublicClientApplication, AccountInfo,RedirectRequest, SilentRequest} from "@azure/msal-browser";
import {msalConfig} from "../auth/authConfig";
import { useSelector } from "react-redux";
import { RootState } from "../root-redux/RootState";

export class AuthClientProvider {
    /** The singleton instance of this class. */
  private static instance: AuthClientProvider;

  /** MSAL Client instance. */
  private msalClient: IPublicClientApplication;

  private constructor() {
    this.msalClient = new PublicClientApplication(msalConfig);
    // const cachedAccount = this.getCachedAccount();
    // if (cachedAccount) {
    //   this.updateActiveAccount(cachedAccount);
    // }

    // this.msalClient.addEventCallback(this.onEvent);

    // // Handle the redirect flows
    // this.msalClient
    //   .handleRedirectPromise()
    //   .then((response) => this.handleRedirectPromise(response))
    //   .catch((err) => {
    //     consoleLogger.warn(`AuthClientProvider.constructor: Handle Redirect Promise Error ${err}`);
    //     serviceLogger.log({
    //       name: 'AuthClientProvider.constructor: Handle Redirect Promise Error',
    //       properties: [err],
    //     });
    //   });
  }

  public static getInstance() {
    if (!AuthClientProvider.instance) {
      AuthClientProvider.instance = new AuthClientProvider();
    }

    return AuthClientProvider.instance;
  }

  public getAuthToken = async (env = 'none') => {
    let token = window.localStorage.getItem('api_token');
    let selectedEnv = env === 'none' ? window.localStorage.getItem('env') : env;
    // if token expires then fetch from msal and store it in local storage 
    if (selectedEnv === 'prod') {
      token = await this.fetchTokenFromMsalAndStoreInLocalStorageProd();
    }
    else{
      token = await this.fetchTokenFromMsalAndStoreInLocalStorage();
    } 
  return `Bearer ${token}`        
  };

  public getAuthToken_releaseManagement = async (environment:string) => {
    let token = window.localStorage.getItem('api_token');
    // if token expires then fetch from msal and store it in local storage 
    if (environment === 'prod') {
      token = await this.fetchTokenFromMsalAndStoreInLocalStorageProd();
    }
    else{
      token = await this.fetchTokenFromMsalAndStoreInLocalStorage();
    } 
  return `Bearer ${token}`        
  };


  private fetchTokenFromMsalAndStoreInLocalStorage = async () => {
    try {

        const graphToken = await this.msalClient.acquireTokenSilent({
        scopes: ["api://1aaf894a-0fdb-487c-ace5-cc90e423ff5c/.default"],
        account: this.msalClient.getAllAccounts()[0],
        });
        window.localStorage.setItem("api_token", graphToken.accessToken);
        return graphToken.accessToken;
    } catch (error) {
        console.error("Error:", error);
    }
    return '';
};
private fetchTokenFromMsalAndStoreInLocalStorageProd = async () => {
  try {

      const graphToken = await this.msalClient.acquireTokenSilent({
      scopes: ["api://fc8c87b7-2b16-4641-b29d-f8e668c9838e/default"],
      account: this.msalClient.getAllAccounts()[0],
      });
      window.localStorage.setItem("api_token", graphToken.accessToken);
      return graphToken.accessToken;
  } catch (error) {
      console.error("Error:", error);
  }
  return '';
};
public getGraphAuthToken = async () => {
  let token = window.localStorage.getItem('graph_api_token');
  // if token expires then fetch from msal and store it in local storage 
  token = await this.microsoftGraphApiTokenFetch();
return `Bearer ${token}`        
};

//getting beareer token for microsoft graph api
private microsoftGraphApiTokenFetch = async () => {
  try {
      const graphToken = await this.msalClient.acquireTokenSilent({
      scopes: ["https://graph.microsoft.com/.default"],
      account: this.msalClient.getAllAccounts()[0],
      });
      window.localStorage.setItem("graph_api_token", graphToken.accessToken);
      return graphToken.accessToken;
  } catch (error) {
      console.error("Error:", error);
  }
  return '';
};
}




