import { createAsyncThunk } from "@reduxjs/toolkit"
import { Release } from "../model/releases/Release.model";
import { getRequestReleaseManagement } from "./utils/GetRequestReleaseManagement";
import { ConfigurationType } from "../feature-components/spa-admin/SPAConstants";

  export const getDevReleases = createAsyncThunk<Release[], string>('dev/workflows/releases', async (workflowId:string) => {
    const response = await getRequestReleaseManagement<Release[]>(`dev/workflows/release/${workflowId.toLowerCase()}`);
    return response.data;
  });

  export const getUatReleases = createAsyncThunk<Release[], string>('uat/workflows/releases', async (workflowId:string) => {
    const response = await getRequestReleaseManagement<Release[]>(`uat/workflows/release/${workflowId.toLowerCase()}`);
    return response.data;
  });

  export const getProdReleases = createAsyncThunk<Release[], string>('prod/workflows/releases', async (workflowId:string) => {
    const response = await getRequestReleaseManagement<Release[]>(`prod/workflows/release/${workflowId.toLowerCase()}`);
    return response.data;
  });

  export const getDevReleasesForConfiguration= createAsyncThunk<Release[], {configurationId:string,configurationType:string}>('dev/configuration/releases', async (configuration:any) => {
    var releases:Release[] = []; 
    if(configuration.configurationType === ConfigurationType.CAMPAIGN || configuration.configurationType === ConfigurationType.DATASET)
    {
     const response = await getRequestReleaseManagement<Release[]>(`dev/campaigns/release/${configuration.configurationId.toLowerCase()}/${configuration.configurationType}`);
     releases = response.data;

    }
     return releases;
  });

  export const getUatReleasesForConfiguration = createAsyncThunk<Release[], {configurationId:string,configurationType:string}>('uat/configuration/releases', async (configuration:any) => {
    var releases:Release[] = []; 
    if(configuration.configurationType === ConfigurationType.CAMPAIGN || configuration.configurationType === ConfigurationType.DATASET){
      const response = await getRequestReleaseManagement<Release[]>(`uat/campaigns/release/${configuration.configurationId.toLowerCase()}/${configuration.configurationType}`);
      releases = response.data;
    }
    return releases;
  });

  export const getProdReleasesForConfiguration = createAsyncThunk<Release[], {configurationId:string,configurationType:string}>('prod/configuration/releases', async (configuration:any) => {
    var releases:Release[] = []; 
    if(configuration.configurationType === ConfigurationType.CAMPAIGN || configuration.configurationType === ConfigurationType.DATASET){
      const response = await getRequestReleaseManagement<Release[]>(`prod/campaigns/release/${configuration.configurationId.toLowerCase()}/${configuration.configurationType}`);
      releases = response.data;
    }
    return releases;
  });