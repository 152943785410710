
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const AllInsightsAndRecommendationsC: React.FC<any> = (props: any) => {

  return (
    <>
     <div>
 
 <div style = {{marginBottom:"18px"}}>
 <h3 >All Insights & Recommendations</h3>
 </div>
 
 <div className="help-mainHeading">
   <span id="insrecsSummaryTag" className="help-heading" style = {{fontSize:"18px"}}>Summary:</span>
   <span className="help-content">{allInsightsAndRecommendations.summary}</span>
 </div>
 <hr/>
 <div className="help-mainHeading">
   <span className="help-heading" style = {{fontSize:"18px"}}>Discover:</span>
   <div style = {{marginBottom:"10px"}}>
   <Icon name = "search" className="help-icon"/>
   <span className="help-heading">Search: </span>
   <span className="help-content"> {allInsightsAndRecommendations.discover.search} </span>
   </div>
   <div style = {{marginBottom:"10px"}}>
   <Icon name = "filter" className="help-icon"/>
   <span className="help-heading">Filter: </span>
   <ol>
     <li key = "tenant" style={{marginBottom:"6px"}}><span className="help-content"> <b style={{fontWeight:"500"}}>Tenant: </b>{allInsightsAndRecommendations.discover.filters.tenant}</span></li>
     <li key = "type" style={{marginBottom:"6px"}}><span className="help-content"> <b style={{fontWeight:"500"}}>Type: </b> {allInsightsAndRecommendations.discover.filters.type}</span></li>
     <li key = "Status" style={{marginBottom:"6px"}}><span className="help-content"> <b style={{fontWeight:"500"}}>Status: </b> </span></li>
     <ul>
       {allInsightsAndRecommendations.discover.filters.status.map(status =>
         <li key={status} style={{marginBottom:"4px"}}>
           <span className="help-content" dangerouslySetInnerHTML={{ __html: status }} />
         </li>
       )}
   </ul>
   <li key = "type"><span className="help-content"> <b style={{fontWeight:"500"}}>Modified: </b> {allInsightsAndRecommendations.discover.filters.modified}</span></li>
   </ol>
   </div>
    </div>
    <hr/>

 <div className="help-mainHeading">
   <span className="help-heading" style = {{fontSize:"18px"}}>Create New or Edit:</span>
   <div style = {{marginBottom:"10px"}}>
   <Icon name = "add" className="help-icon"/>
   <span className="help-heading">Create New: </span>
   <span className="help-content"> {allInsightsAndRecommendations.createneworedit.createnew} </span>
   </div>
   <div style = {{marginBottom:"10px"}}>
   <span className="help-heading">Edit: </span>
   <span className="help-content"> {allInsightsAndRecommendations.createneworedit.edit} </span>
   </div>
  
   <hr/>

   <div>
   <span className="help-heading">Duplicate: </span>
   <span className="help-content"> {allInsightsAndRecommendations.duplicate} </span>
   <HelpNote text = "Duplicating an insight or recommendation will create a new insight or recommendation with the same content as the original. However, for cloning an insight, family name has to be provided. The new insight or recommendation will be in draft mode and will need to be published."/>
   </div>
   <hr/>
   <div style = {{marginBottom:"10px"}}>
   <span className="help-heading">Release: </span>
   <span className="help-content"> {allInsightsAndRecommendations.release} </span>
   </div>
   <hr/>
   <div style = {{marginBottom:"10px"}}>
   <span className="help-heading">Schedule: </span>
   <span className="help-content"> {allInsightsAndRecommendations.schedule} </span>
   </div>
   <hr/>
   <div style = {{marginBottom:"10px"}}>
   <span className="help-heading">Delete: </span>
   <span className="help-content"> {allInsightsAndRecommendations.delete} </span>
   </div>
   <hr/>

   <div id = "onboarding_essentialInfo" className="help-mainHeading">
   <span className="help-heading">Fields Descriptions</span>
   <ul>
       {allInsightsAndRecommendations.infoIconTooltips.map(info =>
         <li key={info} style={{marginBottom:"6px"}}>
           <span className="help-content" dangerouslySetInnerHTML={{ __html: info }} />
           </li>
       )}
   </ul>
 </div>
 </div>
 </div>

 <div>
      <hr className="bigHr" />
    </div>
 


    </>
  );
};
export const AllInsightsAndRecommendations = withErrorBoundary("AllInsightsAndRecommendations content", AllInsightsAndRecommendationsC);