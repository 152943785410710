import { memo, useEffect, useState } from "react";
import { FlyInPanel } from "../ConnectionScope"
import { ConnectionDataGridRow } from "../connections-redux/ConnectionsListColumn.selectors.redux";
import { ConnectionPanelBody } from "./ConnectionPanelBody";
import { ConnectionPanelHeader } from "./ConnectionPanelHeader";
import "../connections.css"
import { ConnectionProps } from "../ConnectionProps";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../root-redux/RootState";
import { setSelectedConnectionIds } from "../connections-redux/Connections.redux";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { ConnectionMessageBar } from "../ConnectionMessageBar";

interface ConnectionsPanelProps {
    setPanelProps: (props: ConnectionProps) => void
    panelProps: ConnectionProps
}

const ConnectionPanelC: React.FC<ConnectionsPanelProps> = ({panelProps, setPanelProps}) => {
    const dispatch = useDispatch<AppDispatch>()
    const isPanelOpen = (panelProps && (panelProps.connectionData?.connectionId || panelProps.isNew)) ? true : false

    // do not close the panel when clicked outside the panel
    const handleRequestClose = (event: any) => {
        if ( event.target !== event.currentTarget || event.target === event.currentTarget && event.detail.source === 'overlay') {
          event.preventDefault();
        } else {
            setPanelProps({} as ConnectionProps)
            dispatch(setSelectedConnectionIds([]))
        }
    }

    return (   
        <>
            <ConnectionMessageBar />                    
            <FlyInPanel
                style={{ '--size': 'var(--he-panel-size-medium)' }}
                open={isPanelOpen}
                onHeRequestClose={handleRequestClose}
                id = "connectionsPanelFlyIn"
            >
                <ConnectionPanelHeader panelProps={panelProps} />
                <ConnectionPanelBody panelProps={panelProps} />
            </FlyInPanel>
        </>
    )
}

export const ConnectionPanel = withErrorBoundary("connections panel", memo(ConnectionPanelC));