import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";

import { RootState } from "../../../root-redux/RootState";
import { FieldToMerge } from "../../../model/merge-rules/FieldsToMerge.model";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { MergeRuleSet } from "../../../model/merge-rules/MergeRuleSet.model";
import {
  PriorityFieldToMerge,
  fieldsCommonToAllModels,
} from "../../../model/merge-rules/PriorityFieldsToMerge.model";
import { MergeRule } from "../../../model/merge-rules/MergeRule.model";

export interface PriorityFieldListDataGridRow extends Row {
  cells: {
    field1: string;
    field2: string;
    field3: string;
  };
}

export const priorityFieldsListColumns: Column[] = [
  {
    field: "field1",
    content: "Name",
    sortable: true,
    display: {
      width: "100px",
    },
  },
  {
    field: "field2",
    content: "Source of Field",
    sortable: true,
    display: {
      width: "100px",
    },
  },
  {
    field: "field3",
    content: "Order",
    sortable: true,
    display: {
      width: "100px",
    },
  },
];

const sortByPriorityFieldsName = (set: PriorityFieldListDataGridRow[]) => {
  var sortedSet = set.sort((a, b) => {
    if (a.cells["field1"] > b.cells["field1"]) {
      return 1;
    } else if (a.cells["field1"] < b.cells["field1"]) {
      return -1;
    } else {
      return 0;
    }
  });
  return sortedSet;
};

export const addColumnsToWorkflows = (workflows : WorkflowSummary[]) => {
  for (var i = 0; i < workflows.length; i++) {
    var eachworkflow = workflows[i];
    if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
      var updatedFields = {
        ...eachworkflow.fields,
        ...fieldsCommonToAllModels,
      };
      eachworkflow = { ...eachworkflow, fields: updatedFields };
      workflows[i] = eachworkflow;
    }
  }
  return workflows;
}

export const allPriorityFieldsItemSelectorData = (
  workflows: WorkflowSummary[],
  currentMergeRuleSet: MergeRuleSet,
  mergeRule : MergeRule,
  selectedFields: string[]
): PriorityFieldListDataGridRow[] => {
  var i = 1;
  var fieldsList: PriorityFieldToMerge[] = [];

  workflows = addColumnsToWorkflows(workflows);
  
  workflows.forEach((eachworkflow) => {
    if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
      Object.keys(eachworkflow.fields).map((item) => {
        var isPresent = fieldsList.find((x) => {
          return x.key === item;
        });
        if (isPresent) {
          var index = fieldsList.indexOf(isPresent);
          fieldsList[index].value = [
            ...fieldsList[index].value,
            eachworkflow.config.workflowName,
          ];
          fieldsList[index].order = "ASC";
        } else {
          var newField = new PriorityFieldToMerge();
          newField.key = item;
          newField.value = [eachworkflow.config.workflowName];
          newField.order = "ASC";
          fieldsList.push(newField);
        }
      });
    }
  });

var unProcessedData : string[] = [];

  currentMergeRuleSet?.useMRLevelConfigToPrioritizePredictions?.toString() == "true" ? 
  unProcessedData = mergeRule?.prioritizePredictionsOnField?.concat(currentMergeRuleSet?.prioritizePredictionsOnField) :
  unProcessedData = currentMergeRuleSet?.prioritizePredictionsOnField;

  var data: string[] = [];
  if (unProcessedData) {
    unProcessedData.forEach((x) => {
      var temp = x?.split(" ");
      if(temp && temp.length > 0)
      {
        data.push(temp[0]);
        if (fieldsList.find((x) => x.key === temp[0])) {
          var index = fieldsList.findIndex((x) => x.key === temp[0]);
          fieldsList[index].order = temp[2]?.toUpperCase();
      }
      }
      
    });
  }

  var transformedToRowItems: PriorityFieldListDataGridRow[] = [];
  if (fieldsList) {
    fieldsList.forEach((item: PriorityFieldToMerge) => {
      transformedToRowItems.push({
        id: i,
        selected: data
          ? data.includes(item.key) || selectedFields.includes(item.key)
          : selectedFields.includes(item.key) ||false,
        disabled: data ? data.includes(item.key) : false,
        cells: {
          field1: item.key,
          field2: item.value.join(","),
          field3: item.order ? item.order : "ASC",
        },
      });
      i++;
    });
  }
  transformedToRowItems = sortByPriorityFieldsName(transformedToRowItems);

  return transformedToRowItems;
};

export const allGlobalPriorityFieldsItemSelectorData = (
  workflows: WorkflowSummary[],
  mergeRule: MergeRule,
  selectedFields: string[]
): PriorityFieldListDataGridRow[] => {
  var i = 1;
  var fieldsList: PriorityFieldToMerge[] = [];

  workflows = addColumnsToWorkflows(workflows);

  workflows.forEach((eachworkflow) => {
    if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
      Object.keys(eachworkflow.fields).map((item) => {
        var isPresent = fieldsList.find((x) => {
          return x.key === item;
        });
        if (isPresent) {
          var index = fieldsList.indexOf(isPresent);
          fieldsList[index].value = [
            ...fieldsList[index].value,
            eachworkflow.config.workflowName,
          ];
          fieldsList[index].order = "ASC";
        } else {
          var newField = new PriorityFieldToMerge();
          newField.key = item;
          newField.value = [eachworkflow.config.workflowName];
          newField.order = "ASC";
          fieldsList.push(newField);
        }
      });
    }
  });

  var unProcessedData = mergeRule?.prioritizePredictionsOnField;
  var data: string[] = [];
  if (unProcessedData) {
    unProcessedData.forEach((x) => {
      var temp = x?.split(" ");
      if(temp && temp.length > 0)
      {
        data.push(temp[0]);

        if (fieldsList.find((x) => x.key === temp[0])) {
          var index = fieldsList.findIndex((x) => x.key === temp[0]);
          fieldsList[index].order = temp[2]?.toUpperCase();
        }
      }
    });
  }

  var transformedToRowItems: PriorityFieldListDataGridRow[] = [];
  if (fieldsList) {
    fieldsList.forEach((item: PriorityFieldToMerge) => {
      transformedToRowItems.push({
        id: i,
        selected: data
          ? data.includes(item.key) || selectedFields.includes(item.key)
          : selectedFields.includes(item.key) || false,
        disabled: data ? data.includes(item.key) : false,
        cells: {
          field1: item.key,
          field2: item.value.join(","),
          field3: item.order ? item.order : "ASC",
        },
      });
      i++;
    });
  }
  transformedToRowItems = sortByPriorityFieldsName(transformedToRowItems);

  return transformedToRowItems;
};

export const allCommonGlobalPriorityFieldsItemSelectorData = (
  workflows: WorkflowSummary[],
  mergeRule: MergeRule,
  selectedFields: string[]
): PriorityFieldListDataGridRow[] => {
  var i = 1;
  var totalWorkflowCount = workflows.length;
  var fieldsList: PriorityFieldToMerge[] = [];

  workflows = addColumnsToWorkflows(workflows);

  workflows.forEach((eachworkflow) => {
    if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
      Object.keys(eachworkflow.fields).map((item) => {
        var isPresent = fieldsList.find((x) => {
          return x.key === item;
        });
        if (isPresent) {
          var index = fieldsList.indexOf(isPresent);
          fieldsList[index].value = [
            ...fieldsList[index].value,
            eachworkflow.config.workflowName,
          ];
          fieldsList[index].order = "ASC";
        } else {
          var newField = new PriorityFieldToMerge();
          newField.key = item;
          newField.value = [eachworkflow.config.workflowName];
          newField.order = "ASC";
          fieldsList.push(newField);
        }
      });
    }
  });

  var unProcessedData = mergeRule?.prioritizePredictionsOnField;
  var data: string[] = [];
  if (unProcessedData) {
    unProcessedData.forEach((x) => {
      var temp = x?.split(" ");
      if(temp && temp.length > 0)
      {
        data.push(temp[0]);

      if (fieldsList.find((x) => x.key === temp[0])) {
        var index = fieldsList.findIndex((x) => x.key === temp[0]);
        fieldsList[index].order = temp[2]?.toUpperCase();
      }
      }
    });
  }

  var transformedToRowItems: PriorityFieldListDataGridRow[] = [];
  if (fieldsList) {
    fieldsList.forEach((item: PriorityFieldToMerge) => {
      if (item.value.length === totalWorkflowCount) {
        transformedToRowItems.push({
          id: i,
          selected: data
            ? data.includes(item.key) || selectedFields.includes(item.key)
            : selectedFields.includes(item.key) || false,
          disabled: data ? data.includes(item.key) : false,
          cells: {
            field1: item.key,
            field2: item.value.join(","),
            field3: item.order ? item.order : "ASC",
          },
        });
        i++;
      }
    });
  }
  transformedToRowItems = sortByPriorityFieldsName(transformedToRowItems);

  return transformedToRowItems;
};
