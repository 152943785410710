export const allInsightsAndRecommendations = {
  summary:
    "Explore insights and recommendations from all teams, including external model providers. Duplicate for reuse or create new. Screen defaults to filtering your team's contributions.",

  discover: {
    search:
      "Effortlessly discover and explore insights/recommendations across teams using the search functionality. Search by name, model, model provider, tags, or description to find precisely what you need.",
    filters: {
      tenant:
        "Defaulted to display insights/recommendations owned by your team(s). Tenant can select insights/recommendations owned by a specific team.",
      type: "Choose between 'Insights' or 'Recommendations' to narrow down the results based on the content type.",
      status: [
        '<strong>"Draft": </strong> Work in progress items yet to be finalized.',
        '<strong>"Deployed": </strong>: Configuration released to higher environment.',
        '<strong>"Awaiting Approval": </strong>: Released to higher environment, pending approval for deployment.',
      ],
      modified:
        "List insights/recommendations based on the owner's name for easy tracking and reference.",
    },
  },

  createneworedit: {
    createnew:
      "Source a ML/rule-based model from external model provider teams or raw signals generated by internal teams. Curate and craft insightful recommendations or insights to present sellers with intelligent information on MSX workspaces.",
    edit: "Modify insights or recommendations owned by your team, creating a new version to preserve the configurations of the deployed content.",
  },

  duplicate:
    "Easily duplicate work items owned by others (within your or other teams) to reuse existing configurations and build upon innovative ideas effortlessly.",

  release:
    "Initialize an insight/recommendation and trigger its release for deployment. For instance, after creating/editing an insight in the Dev environment and being ready for deployment, click the release button. This version will appear in the Release Management screen for further actions such as Deploy, Approve/Reject by authorized team members.",

  schedule:
    "Set up a dataflow schedule to automate the movement of data from the source data to Nebula at specified intervals.",

  delete:
    "Remove one or more unwanted insights/recommendations at a time, enabling a tidy and focused curation experience. Easily clean up any trial-and-error creations made during the ideation process, ensuring a clutter-free platform.",

  infoIconTooltips: [
    "<b style = {{fontweight:500}}> Name: </b> Name of the insight or recommendation",
    "<strong> Type: </strong> Insight or recommendation",
    "<strong>Model/Signal: </strong> Source data from an ML or rule-based model or signal data generated by a workspace.",
    "<strong>Model provider: </strong> Owner of the Model / Signal  ",
    "<strong>Tenant: </strong> Team names Ex: SMC, PIE, Accounts 360. A user can be part of multiple teams. ",
    "<strong>Status: </strong> Deployment status Draft/deployed/Awaiting Approval. Click for more details. ",
    "<strong>Tags: </strong> Tags referenced by owner or atomically added by Nebula to help discoverability ",
  ],
};

export const onboarding = {
  essentialInformation: {
    summary:
      "This tab captures input for an insight or recommendation and source model/signal data. All details entered here contribute to the discoverability of your insights and recommendations by your team or for others.",
    infoicons: [
      "<strong> Insight/Recommendation: </strong> Learn more to understand insight v/s recommendation. (On clicking learn more navigate to help, where the Insight and Recommendation are explained in detail with comparison and examples)",
      "<strong> Name: </strong>  Enter a descriptive name. This will help you easily identify and manage your creations. ",
      "<strong> Description: </strong> Clearly state the objective and benefits to sellers, enhancing discoverability and reusability for other users.",
      "<strong> Tags: </strong> Add relevant tags that can enhance discoverability and categorization of this entry. ",
      "<strong> Tenant: </strong> Choose the team that will own this entry",
      "<strong> Model / Signal data provider: </strong> Team name or reference of the team who owns the source model or signal data.",
    ],
    
  },

  datasources: {
    summary:
      "Establish connections with model/signal data sources and transfer data to destination datasets on Nebula platform for further processing.",
    getdata:
      "Enables you establish connection with multiple data sources and import to targeted datasets Nebula for curation",
    sourcemodeldata:
      "Nebula enables connections to diverse data sources for data fetch/import. Users must obtain connectivity details from the model/signal data owner and establish a connection. Existing data connections from your team can be selected if available for the chosen connection type to reuse.",
    importeddata:
      "Import data for curation into Nebula using this dataset. Users can add multiple datasets to aggregate data and generate intelligent insights/recommendations. All added datasets will be displayed in the left navigation pane with Dataset Names prefixed with their respective Data Connection Types.",
    uploadFile:
      "Users can add upload custom file to generate dataset. All added datasets will be displayed in the left navigation pane with Dataset Names prefixed with their respective Data Connection Types.",
    preview:
      "Preview serves to validate the data intended for curation. It displays the top few records and the data definition of the source data, ensuring accuracy before curating the complete dataset. Please note that the full data will be available in the Imported datasets only after one successful job run of Insight/Recommendation creation or at scheduled intervals. For more details, explore the Schedule Job feature.",
  },

  refine: {
    summary:
      "Refine is an optional step that allows you to add conditions to the data using SQL or SCALA language. Here, you can transform columns of the imported dataset for presentation, apply various filters, or enrich the data by joining it with other datasets.",
    refineoptions: [
      "If you have expertise in SQL or SCALA query language, you can manually create the query in the query editor.",
      "Alternatively, you can use the Query Builder, which automatically generates the query for you.",
    ],
    extrainfo:
      "You can add multiple refine steps to enhance different Input datasets from the previous step or the Output Refined datasets from this step. All refinement steps are displayed in the left navigation pane with Output Refine dataset names prefixed with the query language used.",
    preview:
      "After each condition is applied, you can preview the data to validate the accuracy of the applied conditions.",
  },
  publish: {
    summary:
      "Finalize the data for publication and periodic data refreshes by selecting the final datasets and required attributes. Map the attributes to your workspace schema for efficient data rendering. Additionally, select attributes that trigger data refreshes for seamless and up-to-date updates. You can add multiple publish steps to finalize the data to be published. Each step empowers you to manage and tailor your data precisely according to the destination where you want to publish.",
    finalizedata: [
      "Select an Input dataset from the list of output datasets that were processed and stored in previous steps. This dataset will appear in the 'Publish Datasets’ left panel as 'Input Dataset' with the name prefixed with the 'Data source' type.",
      "The data will be processed according to the actions specified and stored in the output dataset you created in this step.",
    ],
    mapfields:
      "To curate insights or recommendations, certain required fields must be selected from the chosen input dataset. For example, 'Text' and 'Reason' are essential fields to generate a recommendation. In this step, the user will map specific fields for 'Recommendation Text' and 'Recommendation Reason.'Please note that these fields vary between recommendations and insights. Ensure accurate mapping to create meaningful insights and recommendations for your analysis.",
    recfields: [
      "Unique Key: Choose a key essential for Nebula's internal purposes, organizing and accelerating data retrieval. E.g., Tenant ID, Opportunity ID, etc.",

      "Text: Select the field to present as the recommendation text. ",

      "Reason: Pick the field that will be displayed as the reason for the recommendation.",

      "Start On: Select the field that maps to the recommendation's effective date. E.g., [Date Field]",

      "Due in Days: Choose the field that maps to the count of days from when the recommended action is due. E.g., [Number Field] ",

      "Expiry in Days: Pick the field that maps to the count of days until the recommended action expires. E.g., [Number Field] ",

      "Family: Choose a key crucial for Nebula's internal purposes. ... [Fields Description] ",
    ],
    insfields: [
      "Unique Key: Select a key essential for Nebula's internal purposes, enabling data organization and faster retrieval (e.g., Tenant ID, Opportunity ID).",

      "Text: Choose the field to be presented as the insight text.",

      "Family: Select a key crucial for Nebula's internal purposes. ... [Fields Description]",
    ],
    delta: [
      "Delta Refresh: Only the records that have been updated in the selected set of fields will be refreshed.",
      "Full Refresh: All the data will be refreshed based on the time schedule, regardless of specific field updates.",
    ],
    attributes:
      "In this step, select the final set of attributes that need to be published and update their data types, primary fields, and attributes triggering data refresh. The table will be presented with default constraints for your review. Once you have reviewed and made any necessary changes, save your selections to proceed with the data curation process. This ensures that the published data is accurate and aligned with your requirements.",
    publishto: [
      "Nebula: The default option is to publish to Nebula, where the processed data is stored in the Nebula database and published as APIs. These APIs can be consumed in respective workspaces, particularly in Dataverse for SMC workspace.",
      "Action Center: Published data can be mapped to Action Center UX and directly presented in Action Center surfaces, such as the notification center, email/Teams notifications, without requiring any additional configuration or data mapping exercise.",
    ],
  },
  merge: [
    "Default Merge: Utilize the default merge configuration to seamlessly transform the ingested insights/input recommendations dataset into output recommendation. A Nebula default rule is created to merge the insights/input recommendations you just ingested, ensuring a straightforward process. You can further customize this merge rule configuration if needed but cannot merge with other insights/recommendations.",
    "Custom Merge: Empower yourself with flexibility by creating a new custom merge rule. Combine insights/recommendations and generate personalized recommendations by merging the insights/input recommendations dataset you ingested with existing insights/recommendations. Whether you create a new merge rule or update an existing one, the choice is yours to achieve tailored results.",
    "Skip Merge: If you wish to keep the insights/input recommendations as is without generating a recommendation, this option allows you to proceed without any merging process.",
  ],
  consume: {
    summary :"No specific action required in this step. This is a conclusion of the Recommendation/Insight you have generated. REST and ODATA API endpoints with payload information is shared in this step. You can preview the APIs for a final validation.",
    appIDinfo : "The app ID provided in the consume step has to be whitelisted, which means that the app id will be grated to access to certain identified entities, resources and data under nebula.",
    insightsVSrecs : 
  [
    {
      recommendations:
        "Suggestive steps based on aggregated data points across multiple signals usually prioritized based on sequence for better ROI ",
      insights:
        "Are findings or data points which drives patterns based on analytics and can drive proactive actions ",
    },
    {
      recommendations:
        "Merged across multiple signals (recommendations and/or insights) ",
      insights: "Stand Alone",
    },
    {
      recommendations:
        "Complex Life cycle based on Tenant configuration (Extensible). New-> Active-> Accept/Reject, Snooze, Resurface, Cooldown, Deleted, System Deleted etc.",
      insights: "Simple life cycle. New/Active -> Delete/Complete  ",
    },
    {
      recommendations:
        "User Actions are captured and stored along with recommendation (Shared across users)",
      insights:
        "Feedbacks are captured. (Standalone and not shared across users)",
    },
    {
      recommendations: "Supported volume < 10M ",
      insights: "Supported volume ~ 400M ",
    },
    {
      recommendations: "Support for API, Sync (Message, MSX) ",
      insights: "Support for API, Sync (Message, MSX)",
    },
    {
      recommendations: "Access - App, User",
      insights: "Access - App, User ",
    },
    {
      recommendations: "Auth - CALC Assignment MS Sales ",
      insights: "Auth - CALC Assignment MS Sales ",
    },
    {
      recommendations: "",
      insights: "Can be published as actions into Action Center",
    }
  ]}
};
