import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { ConnectionsIcons } from "../ConnectionIcons";
import { ConnectionProps } from "../ConnectionProps";
import { Icon } from "../ConnectionScope";

const ConnectionPanelHeaderC: React.FC<{panelProps: ConnectionProps}> = ({panelProps}) => {
    const {connectionData, connectionType} = panelProps

    var headerComponent = <></>

    if (panelProps.isNew) {
        headerComponent = <div>New Data Connector</div>
    } else if (connectionData?.connectionId) {
        headerComponent = 
        <>
            <div className="panel-main-header">
                <Icon url={`${ConnectionsIcons[connectionType as ConnectionType]}`} style={{marginRight: '10px'}} />
                <div>{connectionData?.connectionName}</div>
            </div>            
            <div style={{fontSize: '12px'}}>{connectionData?.connectionId}</div>
        </>
    }
    
    return (
        <>  
            <div slot="heading">
                {headerComponent}
                <span style={{fontSize: '12px', fontWeight: 'normal'}}>
                    {'Add a new connection to the data source. NOTE: Do not add sensitive information, use Keyvault keys as values'}
                </span>
            </div>        
        </>
    )
}
export const ConnectionPanelHeader = withErrorBoundary("connection panel header", ConnectionPanelHeaderC);