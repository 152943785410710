import { createAsyncThunk } from "@reduxjs/toolkit"
import { RootState } from "../root-redux/RootState";
import { getRequest } from "./utils/GetRequest"
import { DownloadCsvFile } from "./utils/ExcelHandler";
import { postRequest } from "./utils/PostRequest";
import { saveWorkflow } from "../feature-components/workflows/workflow-by-id-redux/WorkflowById.redux";
import { postWorkflow } from "./PostWorkflow.api";
import { setUpdatedWorkflow } from "../feature-components/workflows/workflows-redux/DatasetFileUpload.redux";
import { Workflow } from "../model/workflows/Workflow.model";

export const getDatasetUploadTemplate = createAsyncThunk<any, any, { state: RootState}>('fileUploadDataset/downloadTemplate', async (_, {getState}) => {
    try{
      const selectedEnvItem  = getState().env.selectedEnvItem;
      const response = (await getRequest<string>(`${selectedEnvItem}/fileupload/downloadTemplate`));
      if(response.status == 200)
      {
        DownloadCsvFile(response);
      }
    }catch(e){
        console.log(e);
        return e;
    }
});

export const getDatasetValidatedFile = createAsyncThunk<
  void, // Change the return type to void
  void, // Add the missing getState argument
  { state: RootState } // Add the RootState type to the AsyncThunkConfig
>('fileUploadDataset/downloadValidatedFile', async (_, { getState }) => {
  try {
    const selectedEnvItem  = getState().env.selectedEnvItem;
    const workflow = getState().workflowById.workflow;
    const response = await postRequest<Workflow, Workflow>(`${selectedEnvItem}/fileupload/downloadValidatedFile`, workflow);
    if(response.status == 200)
    {
        DownloadCsvFile(response);
    }
  } catch (e) {
    console.log(e);
  }
});

export const automateNextSteps = createAsyncThunk<any, any, { state: RootState}>('fileUploadDataset/automateNextSteps', async (payload, {getState, dispatch}) => {
    try{
      const selectedEnvItem  = getState().env.selectedEnvItem;
      const response = await postRequest<any, any>(`${selectedEnvItem}/fileupload/automateNextSteps`, payload)
      dispatch(saveWorkflow(response.data)); 
      dispatch(setUpdatedWorkflow(response.data));
      await dispatch(postWorkflow({workflow: getState().workflowById.workflow, env: "uat"})).unwrap();
      return response.data
    }
    catch(e){
      console.log(e);
    }
});