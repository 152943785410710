export class Prediction
{
    public id!: string
    public type!: string
    public partitionKey!: string
    public createdDate!: string
    public source!: Source
    public primary!: Primary<string>
    public attributes!: Attribute[]
    public systemAttributes!: Attribute[]
}

export class Source {
    public sourceId!: string
}

export class Primary<T> {
    public text!: T
    public reason!: T
    public status!: string
    public startOn!: string
    public family!: string
    public dueInDays!: number
    public expiryInDays!: number
}

export class Attribute {
    public key!: string
    public value!: string
}


    