
import { postRequest } from '../utils/PostRequest';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from "../../root-redux/RootState";
import { v4 as uuidv4 } from 'uuid';
import { Logger } from '../../utils/Logger';
import { CampaignAreaReviewerMailConfig, CampaignVendorMailConfig, ICampaignMailConfigParams, MsxLinks, SPA_ADMIN_MAIL } from '../../feature-components/spa-admin/SPAConstants';

const getRedirectHost = (host: string, id: string, env: string) => {
    return host + (host.endsWith('/') ? "redirect/" : "/redirect/") + env  + "/Campaign/" + id;
}

export const sendSPANotificationKickOff = 
  createAsyncThunk<any, {workflowId: string}, { state: RootState }>
  ('campaigns/notification/SPA', 
  async ({workflowId}, { getState }) => {
    const host = process.env.REACT_APP_REDIRECT_URL || "";
    const selectedEnv = getState().env.selectedEnvItem;
    const campaignById = getState().campaignById;
    const userDetails = getState().userDetails;
    const TEMPLATE_ID = "70d5cffc-ca5f-4230-8d3a-7a44d66a8d5a";
    const isTestMail = selectedEnv === 'dev' || getState().userGroups.list.includes(SPA_ADMIN_MAIL);

    const segment = (campaignById.summary["CampaignType"] || "").startsWith("Enterprise") ? "Enterprise" : 'SMC';

    const templateObjectId = segment === 'SMC' ? "ed7a0c8d-69fa-4f42-b95d-0a03452cab7a" : "78703f67-1649-4ea3-80e0-f68f2e829f22";
    const templateUserEmail = segment === 'SMC' ? "ASPA-SMCC_DSE-Govern@microsoft.com" : "ASPA-Ent-Governance@microsoft.com";
    const templateUserName = segment === 'SMC' ? "ASPA-SMCC/DSE Governance" : "ASPA-Ent-Governance";

    const guid = uuidv4();
    const spaNotiDetails = {
        "correlationId": guid,
        "items": [
            {
                "dataValues": {
                    "SPAName": campaignById?.summary["CampaignName"]!,
                    "SPADRIName": userDetails.name,
                    "Alias": userDetails.username.split('@')[0],
                    "SolutionArea": campaignById?.summary["SolutionArea"]!,
                    "SalesMotion": campaignById?.summary["SalesMotion"]!,
                    "SolutionPlay": campaignById?.summary["SolutionPlay"]!,
                    "Description": campaignById?.summary["Description"]!,
                    "DirectSPALink": getRedirectHost(host, workflowId, selectedEnv),
                    "unsubscribeLink": "unsubscribeLink",
                    "manageNotificationLink": "manageNotificationLink",
                    "id": TEMPLATE_ID,
                    "To": `[{"ObjectId":"${templateObjectId}","EmailAddress":"${templateUserEmail}"}]`,
                    "UserName": templateUserName
                },
                "priority": 10,
                "referenceid": uuidv4(),
                "state": "New"
            }
        ],
        "notificationConfigurationId": TEMPLATE_ID,
        "source": host
    }
    var environment = selectedEnv;
    const response = await postRequest<any, any>(`${environment}/campaigns/notification/SPA?test=${isTestMail}`, spaNotiDetails);
    const data: any = response.data;
    if ((data["Code"] || "").startsWith('ERR')) {
        Logger.Instance.trackException(data["Code"], {
            env: environment,
            "API": `${environment}/campaigns/notification/SPA`,            
            "CampaignId": campaignById.campaign?.config.workflowId!,
            "CampaignName": campaignById?.summary["CampaignName"]!,
            "Success": "false",
            "Area" : getState().userSalesRoles.userDetails?.area!,
            "correlationId": guid,
            "Method" : "sendSPANotificationKickOff"
        });

    } else {
        Logger.Instance.trackEvent(data["Code"], {
            env: environment,
            "API": `${environment}/campaigns/notification/SPA`,            
            "CampaignId": campaignById.campaign?.config.workflowId!,
            "CampaignName": campaignById?.summary["CampaignName"]!,
            "Success": "true",
            "Area" : getState().userSalesRoles.userDetails?.area!,
            "correlationId": guid,
            "Method" : "sendSPANotificationKickOff"
        });

    }
    return response.data;
});

export const sendAreaReviewerNotification = createAsyncThunk<any, void, { state: RootState }>('campaigns/notification/AreaReviewer', async (_, { getState, rejectWithValue }) => {
    const selectedEnv = getState().env.selectedEnvItem;
    const campaignById = getState().campaignById;
    const userDetails = getState().userDetails;
    const workflowId = getState().campaignById.campaign?.config.workflowId || "";
    const host = process.env.REACT_APP_REDIRECT_URL || "";
    const TEMPLATE_ID = "3cfd3025-3f67-4c30-ac67-4ed825695002";
    const isTestMail = selectedEnv === 'dev' || getState().userGroups.list.includes(SPA_ADMIN_MAIL);

    let templateConfig: ICampaignMailConfigParams|undefined = undefined;
    const segment = (campaignById.summary["CampaignType"] || "").startsWith("Enterprise") ? "Enterprise" : 'SMC';
    const solutionArea = campaignById.summary["SolutionArea"];

    for(const key in CampaignAreaReviewerMailConfig) {
        const segmentConfig = CampaignAreaReviewerMailConfig[key]['Segment'], SolutionAreaConfig = CampaignAreaReviewerMailConfig[key]['SolutionArea'];
        if(segmentConfig === segment && SolutionAreaConfig === solutionArea) {
            templateConfig = CampaignAreaReviewerMailConfig[key];
        }
    };

    if(!templateConfig) {
        return rejectWithValue(`Template not found for '${segment}' and '${solutionArea}'`);
    }

    const guid = uuidv4();
    const spaNotiDetails = {
        "correlationId": guid,
        "items": [
            {
                "dataValues": {
                    "SPAName": campaignById?.summary["CampaignName"]!,
                    "SPADRIName": userDetails.name,
                    "Alias": userDetails.username.split('@')[0],
                    "SolutionArea": campaignById?.summary["SolutionArea"]!,
                    "SalesMotion": campaignById?.summary["SalesMotion"]!,
                    "SolutionPlay": campaignById?.summary["SolutionPlay"]!,
                    "Description": campaignById?.summary["Description"]!,
                    "MSXEnvlink": getRedirectHost(host, workflowId, selectedEnv),
                    "NumberoFDaystoReview": 14,
                    "unsubscribeLink": "unsubscribeLink",
                    "manageNotificationLink": "manageNotificationLink",
                    "id": TEMPLATE_ID,
                    "To": `[{"ObjectId":"${templateConfig.UserObjectId}","EmailAddress":"${templateConfig.UserEmail}"}]`,
                    "UserName": templateConfig.Username
                },
                "priority": 10,
                "referenceid": guid,
                "state": "New"
            }
        ],
        "notificationConfigurationId": TEMPLATE_ID,
        "source": host
    }
    var environment = selectedEnv;
    const response = await postRequest<any, any>(`${environment}/campaigns/notification/SPA?test=${isTestMail}`, spaNotiDetails);
    const data: any = response.data;
        if ((data["Code"] || "").startsWith('ERR')) {
            Logger.Instance.trackException(data["Code"], {
                env: environment,
                "API": "campaigns/notification/AreaReviewer",
                "Method": "sendAreaReviewerNotification",
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "false",
                "correlationId": guid,
                "Area" : getState().userSalesRoles.userDetails?.area!,
            });
        } else {
            Logger.Instance.trackEvent(data["Code"], {
                env: environment,
                "API": "campaigns/notification/AreaReviewer",
                "Method": "sendAreaReviewerNotification",
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "true",
                "correlationId": guid,
                "Area" : getState().userSalesRoles.userDetails?.area!,
            });
        }
    return response.data;
});


export const sendVTeamReviewNotification = createAsyncThunk<any, void, { state: RootState }>('campaigns/notification/VTeamReviewer', async (_, { getState, rejectWithValue }) => {
    const selectedEnv = getState().env.selectedEnvItem;
    const campaignById = getState().campaignById;
    const userDetails = getState().userDetails;
    const workflowId = getState().campaignById.campaign?.config.workflowId || "";
    const host = process.env.REACT_APP_REDIRECT_URL || "";
    const TEMPLATE_ID = "d8a8ead0-5726-40db-ad86-4cdf5f6dad77";
    const isTestMail = selectedEnv === 'dev' || getState().userGroups.list.includes(SPA_ADMIN_MAIL);

    let templateConfig: ICampaignMailConfigParams|undefined = undefined;
    const segment = (campaignById.summary["CampaignType"] || "").startsWith("Enterprise") ? "Enterprise" : 'SMC';
    const solutionArea = campaignById.summary["SolutionArea"];

    for(const key in CampaignVendorMailConfig) {
        const segmentConfig = CampaignVendorMailConfig[key]['Segment'], SolutionAreaConfig = CampaignVendorMailConfig[key]['SolutionArea'];
        if(segmentConfig === segment && SolutionAreaConfig === solutionArea) {
            templateConfig = CampaignVendorMailConfig[key];
        }
    };

    if(!templateConfig) {
        return rejectWithValue(`Template not found for '${segment}' and '${solutionArea}'`);
    }

    const spaNotiDetails = {
        "correlationId": uuidv4(),
        "items": [
            {
                "dataValues": {
                    "SPAName": campaignById?.summary["CampaignName"]!,
                    "SPADRIName": userDetails.name,
                    "Alias": userDetails.username.split('@')[0],
                    "SolutionArea": campaignById?.summary["SolutionArea"]!,
                    "SalesMotion": campaignById?.summary["SalesMotion"]!,
                    "SolutionPlay": campaignById?.summary["SolutionPlay"]!,
                    "Description": campaignById?.summary["Description"]!,
                    "MSXEnvlink": getRedirectHost(host, workflowId, selectedEnv),
                    "unsubscribeLink": "unsubscribeLink",
                    "manageNotificationLink": "manageNotificationLink",
                    "id": TEMPLATE_ID,
                    "To": `[{"ObjectId":"${templateConfig.UserObjectId}","EmailAddress":"${templateConfig.UserEmail}"}]`,
                    "UserName": templateConfig.Username
                },
                "priority": 10,
                "referenceid": uuidv4(),
                "state": "New"
            }
        ],
        "notificationConfigurationId": TEMPLATE_ID,
        "source": host
    }
    var environment = selectedEnv;
    const response = await postRequest<any, any>(`${environment}/campaigns/notification/SPA?test=${isTestMail}`, spaNotiDetails);
    const data: any = response.data;
        if ((data["Code"] || "").startsWith('ERR')) {
            Logger.Instance.trackException(data["Code"], {
                env: environment,
                "API": "campaigns/notification/SPA",                
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "false",
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "sendVTeamReviewNotification"
            });
        } else {
            Logger.Instance.trackEvent(data["Code"], {
                env: environment,
                "API": "campaigns/notification/SPA",                
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "true",
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "sendVTeamReviewNotification"
            });
        }
    return response.data;
    
});


export const sendNotificationAboutNewLaunch = createAsyncThunk<any, {workflowId: string}, { state: RootState }>('campaigns/notification/VTeamReviewer', async ( { workflowId }, { getState, rejectWithValue }) => {
    const selectedEnv = getState().env.selectedEnvItem;
    const campaignById = getState().campaignById;
    const host = process.env.REACT_APP_REDIRECT_URL || "";
    const TEMPLATE_ID = "8540725f-0537-4221-ab66-587a0a44399e";
    const isTestMail = selectedEnv === 'dev' || getState().userGroups.list.includes(SPA_ADMIN_MAIL);

    let templateConfig: ICampaignMailConfigParams|undefined = undefined;
    const segment = (campaignById.summary["CampaignType"] || "").startsWith("Enterprise") ? "Enterprise" : 'SMC';
    const solutionArea = campaignById.summary["SolutionArea"];

    for(const key in CampaignVendorMailConfig) {
        const segmentConfig = CampaignVendorMailConfig[key]['Segment'], SolutionAreaConfig = CampaignVendorMailConfig[key]['SolutionArea'];
        if(segmentConfig === segment && SolutionAreaConfig === solutionArea) {
            templateConfig = CampaignVendorMailConfig[key];
        }
    };

    if(!templateConfig) {
        return rejectWithValue(`Template not found for '${segment}' and '${solutionArea}'`);
    }

    const guid = uuidv4();
    const spaNotiDetails = {
        "correlationId": guid,
        "items": [
            {
                "dataValues": {
                    "SPAName": campaignById?.summary["CampaignName"]!,
                    "SolutionArea": campaignById?.summary["SolutionArea"]!,
                    "SalesMotion": campaignById?.summary["SalesMotion"]!,
                    "SolutionPlay": campaignById?.summary["SolutionPlay"]!,
                    "Description": campaignById?.summary["Description"]!,
                    "MSXEnvlink": getRedirectHost(host, workflowId, selectedEnv),
                    "unsubscribeLink": "unsubscribeLink",
                    "manageNotificationLink": "manageNotificationLink",
                    "id": TEMPLATE_ID,
                    "To": `[{"ObjectId":"${templateConfig.UserObjectId}","EmailAddress":"${templateConfig.UserEmail}"}]`,
                    "UserName": templateConfig.Username
                },
                "priority": 10,
                "referenceid": guid,
                "state": "New"
            }
        ],
        "notificationConfigurationId": TEMPLATE_ID,
        "source": host
    }
    var environment = selectedEnv;
    const response = await postRequest<any, any>(`${environment}/campaigns/notification/SPA?test=${isTestMail}`, spaNotiDetails);
    const data: any = response.data;
        if ((data["Code"] || "").startsWith('ERR')) {
            Logger.Instance.trackException(data["Code"], {
                env: environment,
                "API": "campaigns/notification/SPA",                
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "false",
                "correlationId": guid,
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "sendNotificationAboutNewLaunch"
            });
        } else {
            Logger.Instance.trackEvent(data["Code"], {
                env: environment,
                "API": "campaigns/notification/SPA",                
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "true",
                "correlationId": guid,
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "sendNotificationAboutNewLaunch"
            });
        }
    return response.data;
});

export const sendNotificationAboutUpdate = createAsyncThunk<any, {workflowId: string}, { state: RootState }>('campaigns/notification/VTeamReviewer', async ({workflowId}, { getState, rejectWithValue }) => {
    const selectedEnv = getState().env.selectedEnvItem;
    const campaignById = getState().campaignById;
    const host = process.env.REACT_APP_REDIRECT_URL || "";
    const TEMPLATE_ID = "11ff0109-a651-4673-8eb3-4184d5f0356c";
    const isTestMail = selectedEnv === 'dev' || getState().userGroups.list.includes(SPA_ADMIN_MAIL);

    let templateConfig: ICampaignMailConfigParams|undefined = undefined;
    const segment = (campaignById.summary["CampaignType"] || "").startsWith("Enterprise") ? "Enterprise" : 'SMC';
    const solutionArea = campaignById.summary["SolutionArea"];

    for(const key in CampaignVendorMailConfig) {
        const segmentConfig = CampaignVendorMailConfig[key]['Segment'], SolutionAreaConfig = CampaignVendorMailConfig[key]['SolutionArea'];
        if(segmentConfig === segment && SolutionAreaConfig === solutionArea) {
            templateConfig = CampaignVendorMailConfig[key];
        }
    };

    if(!templateConfig) {
        return rejectWithValue(`Template not found for '${segment}' and '${solutionArea}'`);
    }

    const guid = uuidv4();
    const spaNotiDetails = {
        "correlationId": guid,
        "items": [
            {
                "dataValues": {
                    "SPAName": campaignById?.summary["CampaignName"]!,
                    "SPAUpdates": "Summary Changes",
                    "SolutionArea": campaignById?.summary["SolutionArea"]!,
                    "SalesMotion": campaignById?.summary["SalesMotion"]!,
                    "SolutionPlay": campaignById?.summary["SolutionPlay"]!,
                    "Description": campaignById?.summary["Description"]!,
                    "MSXEnvlink": getRedirectHost(host, workflowId, selectedEnv),
                    "unsubscribeLink": "unsubscribeLink",
                    "manageNotificationLink": "manageNotificationLink",
                    "id": TEMPLATE_ID,
                    "To": `[{"ObjectId":"${templateConfig.UserObjectId}","EmailAddress":"${templateConfig.UserEmail}"}]`,
                    "UserName": templateConfig.Username
                },
                "priority": 10,
                "referenceid": guid,
                "state": "New"
            }
        ],
        "notificationConfigurationId": TEMPLATE_ID,
        "source": host
    }
    var environment = selectedEnv;
    const response = await postRequest<any, any>(`${environment}/campaigns/notification/SPA?test=${isTestMail}`, spaNotiDetails);
    const data: any = response.data;
        if ((data["Code"] || "").startsWith('ERR')) {
            Logger.Instance.trackException(data["Code"], {
                env: environment,
                "API": "campaigns/notification/SPA",                
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "false",
                "correlationId": guid,
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "sendNotificationAboutUpdate"
            });
        } else {
            Logger.Instance.trackEvent(data["Code"], {
                env: environment,
                "API": "campaigns/notification/SPA",                
                "CampaignId": campaignById.campaign?.config.workflowId!,
                "CampaignName": campaignById?.summary["CampaignName"]!,
                "Success": "true",
                "correlationId": guid,
                "Area" : getState().userSalesRoles.userDetails?.area!,
                "Method" : "sendNotificationAboutUpdate"
            });
        }
        
    return response.data;
});
