import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid"
import { DeltaMap } from "../../model/workflows/Delta.model"
import { PublishMap } from "../../model/workflows/Publish.model"
import { MergeRule } from "../../model/merge-rules/MergeRule.model"
import { RootState } from "../../root-redux/RootState"
import { MergeRulePreviewOutput } from "../../model/preview-mergerule/MergeRulePreviewOutput.model"
import { ModelMapping } from "../../model/configModels/ModelMapping.model"
import { MergeRulePreviewUserInput } from "../../model/preview-mergerule/MergeRulePreviewUserInput.model"
import { fillFields } from "./PreviewRecommendationOutput"
import { Primary } from "../../model/preview-mergerule/Prediction.model"

export interface PreviewPredictionGrid extends Row {
  id: string,
  cells: {
    field0: string
    field1: string
    field2: string
    field3: string
    field4: string
    field5: string
    field81: string
    field82: string
    field83: string
    field84: string
    field85: string
    field86: string
    field87: string
    field88: string
    field89: string
    field810: string
    field811: string
    field812: string
    field813: string
    field814: string
    field815: string
    field816: string
    field817: string
    field818: string
    field819: string
    field820: string
  }
}

export var PreviewPredictionColumns = [
  {
    field: 'field1',
    content: 'Name',
  },
  {
    field: 'field2',
    content: 'Insight/Recommendation',
  },
  {
    field: 'field3',
    content: 'Text',
  },
  {
    field: 'field4',
    content: 'Reason',
  },
  {
    field: 'field5',
    content: 'Input Recommendation Id',
  },
];

const getValueFromPrimaryAttributes = (eachField: string, primary: Primary<string>) => {
  switch (eachField) {
    case "Text": return primary.text
    case "Reason": return primary.reason
    case "StartOn": return primary.startOn
    case "Status": return primary.status
    case "Family": return primary.family
    case "DueInDays": return primary.dueInDays.toString()
    case "ExpiryInDays": return primary.expiryInDays.toString()

  }
}

export const previewPredictionData = (previewMergeRuleOutput: MergeRulePreviewOutput, predsMap: [string, string][], recsMap: [string, string][], insMap: [string, string,string][], modelMapping: ModelMapping[] | undefined, mergerule: MergeRule, previewMergeRuleUserInput: MergeRulePreviewUserInput): PreviewPredictionGrid[] => {
  var isFound = PreviewPredictionColumns.find(x => { return x.field === 'field0' })
  if (!isFound) {
    PreviewPredictionColumns.unshift({
      field: 'field0',
      content: previewMergeRuleOutput.distinctField || "TPID",
    })
  }
  var i = 1;
  var transformedToRowItems: PreviewPredictionGrid[] = []
  var output = previewMergeRuleOutput.predictions
  if (output != undefined && output.length > 0) {
    var outputAndFields = [] as string[]
    var PrimaryFields = ["StartOn", "Text", "Reason", "DueInDays", "ExpiryInDays", "Status", "Owner", "MsxOwner", "Family", "BusinessKey"]
    var obj = mergerule.primaryMergeRuleSet.find(x => { return x.id === previewMergeRuleUserInput.mergeRuleSetId })
    if (obj) {
      if (previewMergeRuleUserInput.onlyOneMergeRuleSet) {
        outputAndFields = fillFields(obj, outputAndFields)
      } else {
        var primarySets = mergerule.primaryMergeRuleSet.filter(x => { return Number(x.rank) <= Number(obj?.rank) })
        primarySets.forEach((set) => {
          outputAndFields = fillFields(set, outputAndFields)
        })
      }
    } else {
      var secObj = mergerule.secondaryMergeRuleSet.find(x => { return x.id === previewMergeRuleUserInput.mergeRuleSetId })
      if (secObj) {
        if (previewMergeRuleUserInput.onlyOneMergeRuleSet) {
          var primarySets = mergerule.primaryMergeRuleSet.filter(x => { return Number(x.rank) <= Number(secObj?.rank) })
          primarySets.forEach((set) => {
            outputAndFields = fillFields(set, outputAndFields)
          })
          outputAndFields = fillFields(secObj, outputAndFields)
        } else {
          var primarySets = mergerule.primaryMergeRuleSet.filter(x => { return Number(x.rank) <= Number(secObj?.rank) })
          primarySets.forEach((set) => {
            outputAndFields = fillFields(set, outputAndFields)
          })

          var secondarySets = mergerule.secondaryMergeRuleSet.filter(x => { return Number(x.rank) <= Number(secObj?.rank) })
          secondarySets.forEach((set) => {
            outputAndFields = fillFields(set, outputAndFields)
          })
        }
      }
    }
    for (var indexReset = 0; indexReset < 20; indexReset++) {
      var eachField = outputAndFields[indexReset]
      if (PreviewPredictionColumns.filter(x => { return x.content === eachField }).length <= 0) {
        PreviewPredictionColumns.push({
          field: "field8" + (indexReset + 1).toString(),
          content: eachField,
        })
      }
    }
    output.forEach((item) => {
      var predId = item.id || ""
      if (predId) {
        var shortName = predsMap.find(x => { return x[0] === predId })
        if (shortName) {
          predId = shortName[1]
        }
      }

      var modelName = item.source.sourceId || ""
      if (modelName) {
        var models = modelMapping && modelMapping.find(x => x.id === item.source.sourceId) || {} as ModelMapping
        if (models) {
          modelName = models.name
        }
      }

      var field81 = "", field82 = "", field83 = "", field84 = "", field85 = "",
        field86 = "", field87 = "", field88 = "", field89 = "", field810 = "",
        field811 = "", field812 = "", field813 = "", field814 = "", field815 = "",
        field816 = "", field817 = "", field818 = "", field819 = "", field820 = "";

      for (var index = 0; index < 20; index++) {
        var eachField = outputAndFields[index]
        if (PrimaryFields.includes(eachField)) {
          eachField = eachField.toString()
          if (index === 0) field81 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 1) field82 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 2) field83 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 3) field84 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 4) field85 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 5) field86 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 6) field87 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 7) field81 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 8) field82 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 9) field83 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 10) field84 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 11) field85 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 12) field86 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 13) field87 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 14) field82 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 15) field83 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 16) field84 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 17) field85 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 18) field86 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
          if (index === 19) field87 = getValueFromPrimaryAttributes(eachField, item.primary) || ""
        } else {
          if (index === 0) field81 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 1) field82 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 2) field83 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 3) field84 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 4) field85 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 5) field86 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 6) field87 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 7) field88 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 8) field89 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 9) field810 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 10) field811 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 11) field812 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 12) field813 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 13) field814 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 14) field815 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 15) field816 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 16) field817 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 17) field818 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 18) field819 = item.attributes.find(x => { return x.key === eachField })?.value || ""
          if (index === 19) field820 = item.attributes.find(x => { return x.key === eachField })?.value || ""
        }
      }


      transformedToRowItems.push(
        {
          id: i.toString(),
          cells: {
            field0: item.partitionKey,
            field1: predId,
            field2: modelName,
            field3: item.primary.text,
            field4: item.primary.reason,
            field5: item.id,
            field81: field81,
            field82: field82,
            field83: field83,
            field84: field84,
            field85: field85,
            field86: field86,
            field87: field87,
            field88: field88,
            field89: field89,
            field810: field810,
            field811: field811,
            field812: field812,
            field813: field813,
            field814: field814,
            field815: field815,
            field816: field816,
            field817: field817,
            field818: field818,
            field819: field819,
            field820: field820,
          }
        })
      i++;
    })
  }
  return transformedToRowItems
}