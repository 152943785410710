export class PriorityFieldToMerge {
    public key!: string
    public value!: string[]
    public order!: string
}


export class FieldOrder {
    public field!: string
    public order!: string
}

export var fieldsCommonToAllModels = {
    "CreatedDate": "String",
    "ModifiedDate": "String",
    "PartitionKey": "String",
    "Type": "String",
    "SourceId": "String",
    "Timestamp": "String",
    "Text": "String",
    "Reason": "String",
    "StartOn": "String",
    "DueInDays": "Int32",
    "ExpiryInDays": "Int32",
    "Status": "String",
    "Family": "String",
    "Object": "String",
    "Tags": "String"
  }