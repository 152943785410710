import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../root-redux/RootState";
import { Button, CommandBar, Icon } from "../../common/HarmonyEnablers";
import { useDispatch } from "react-redux";
import informationConfig from "../../shared-content/inputDescription";
import { InformationText } from "../../shared-components/info-text/InformationText";

export const EditMergeRulesHeader: React.FC<any> = (props: any) => {
    const dispatch = useDispatch()
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)

    const { source, mergeRuleName } = props
    var title = mergeRuleName && mergeRuleName !== 'undefined' ? 'Edit Merge Rule |' : 'Create New Merge Rule'
    var subtitle = mergeRuleName && mergeRuleName !== 'undefined' ? "" + mergeRuleName : ""
    var infoText = mergeRuleName && mergeRuleName !== 'undefined' ? informationConfig.editMergeRule : informationConfig.createNewMergeRule
    
    return (
        <>
            {source === "default" &&
                <div style={{paddingTop:"1em"}}>
                    {/* <Button appearance="command" onClick={handleClick}>
                        <Icon slot="start" name="back" style={{ marginBottom: "5px", fontSize: "20px", alignItems: 'center', color: 'var(--he-color-primary-700)' }} />
                    </Button> */}
                    <span style={{ fontSize: '20px', fontWeight: '500' }}>{title}</span>
                    <span style={{ fontSize: '15px', margin: '0 10px' }}>{subtitle}</span>
                </div>
            }
            {source !== "default" &&
                <div className={`mergerule-header-wrapper-edit ${isNavPanelOpen ? '' : 'collapsed'}`}>
                    <div className="mergerule-header-edit">
                        <span style={{ fontWeight: '500' }}>{title}</span>
                        <span style={{ margin: '0 10px' }}>{subtitle}</span>
                        <div style={{fontSize: "0.5em"}}>
                            <InformationText text={infoText} type="Heading" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}