import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, TextArea, Toggle, Tooltip } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateAdditionalModels, updateEnableSave, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { AppPersona } from "../../model/app-persona/AppPersona.model";
import './mergerules-styles.css'
import informationConfig from "../../shared-content/inputDescription";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import { HelpContent } from "../merge-rules/help-mergerules/HelpContent";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { MergeRuleGlobalPriorityFields } from "./MergeRuleGlobalPriorityFields";

const EditMergeRulesEssentialsC: React.FC<any> = (props: any) => {
    const dispatch = useDispatch()
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
    const originalMergeRule = useSelector((state: RootState) => state.mergeRuleById.originalMergeRule);
    const typeMappingByKey = useSelector((state: RootState) => state.typeMappingConfigByKey.typeMappingByKey);
    const additionalModels = useSelector((state: RootState) => state.mergeRuleById.additionalModels);

    const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);
    const permissionsList = useSelector(permissionsSelector)
    const userTeams = permissionsList.userTeams;
    const primaryTeam = permissionsList.primaryTeam
    const { mergeRuleId, source, defaultMergeRule } = props
    const [localMergeRuleData, setLocalMergeRuleData] = useState([])
    const [currentEssentialData, setcurrentEssentialData] = useState(mergeRule);
    const [listOfFamilies, setListOfFamilies] = useState([] as string[])
    const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
    const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
    const isSuperAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin)
    const workflow = useSelector((state: RootState) => state.workflowById.workflow)
    const [tagInputText, setTagInputText] = useState('')
    const [openHelpPanel, setOpenHelpPanel] = useState(false)
    const [openFieldsPanel, setOpenFieldsPanel] = useState(false)
    const [localAdditionalModels, setLocalAdditionalModels] = useState("");

    useEffect(() => {
        if (mergeRule) {
            var local = { ...mergeRule }
            if (source === "default" && (mergeRule.mergeRuleId === "" || mergeRule.mergeRuleId === "undefined")) {
                //MRTODO - remove all this - direct read from workflwosummary
                var publishStep = workflow.publish.filter(x => { return x.type === "COSMOS" && x.publishTo.toLowerCase() === "nebula" })
                var localDistinctField = publishStep.length > 0 ? publishStep[0].publishMap.filter(x => { return x.destination !== null && x.destination === "PartitionKey" }) : null
                var localDistinctFieldString = ""
                if (localDistinctField && localDistinctField.length > 0) {
                    localDistinctFieldString = localDistinctField[0].source
                }
                local = {
                    ...local,
                    subscriptionKey: workflow.subscriptionKey,
                    type: workflow.family,
                    distinctField: localDistinctFieldString
                }
            }
            setcurrentEssentialData(local)

            var sourceFilter: string[] = []
            if (local.primaryMergeRuleSet != null && local.primaryMergeRuleSet.length > 0) {
                local.primaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            if (local.secondaryMergeRuleSet != null && local.secondaryMergeRuleSet.length > 0) {
                local.secondaryMergeRuleSet.forEach((item: any) => {
                    sourceFilter = sourceFilter.concat(item.mergeModel)
                })
            }
            sourceFilter = sourceFilter.filter((v, i, a) => a.indexOf(v) == i)
            var additionalModelsData = local.sourceFilter && local.sourceFilter.filter(item => !sourceFilter.includes(item))
            if(additionalModelsData && additionalModelsData.length > 0) {
                setLocalAdditionalModels(additionalModelsData.join(","))
            } else {
                setLocalAdditionalModels("")
            }
        }
    }, [mergeRule])

    useEffect(() => {
        if (workflowsBySubKeys) {
            var localFamilies = workflowsBySubKeys.filter(x => { return x.family && x.workFlowType === "Recommendation" }).map(x => x.family)
            localFamilies = localFamilies.filter((value, index, array) => array.indexOf(value) === index);
            localFamilies = sortArrayOfString(localFamilies)
            setListOfFamilies(localFamilies)
        }
    }, [workflowsBySubKeys])

    useEffect(() => {
        if (typeMappingByKey) {
            var distinctField = Object.values(typeMappingByKey).map(x => { return x })
            if (distinctField && distinctField.length > 0) {
                dispatch(updateEssentials({ ...currentEssentialData, distinctField: distinctField.join("") }))
            }
        }
    }, [typeMappingByKey])

    useEffect(() => {

        if (currentEssentialData.mergeRuleId && currentEssentialData.mergeRuleId !== "undefined" &&
            currentEssentialData.mergeRuleName &&
            currentEssentialData.subscriptionKey &&
            currentEssentialData.type
            // && currentEssentialData.primaryMergeRuleSet && currentEssentialData.primaryMergeRuleSet.length >= 1
        ) {
            dispatch(updateEnableSave(true))
        } else {
            dispatch(updateEnableSave(false))
        }
    }, [currentEssentialData])

    const sortArrayOfString = (set: string[]) => {
        var sortedSet = set.sort((a, b) => {
            if (a.toLowerCase() > b.toLowerCase()) {
                return 1
            } else if (a.toLowerCase() < b.toLowerCase()) {
                return -1
            } else {
                return 0
            }
        });
        return sortedSet
    }


    const handleBasicDetailsChange = (e: any) => {
        switch (e.target.name) {
            case "mergeruleName":
                dispatch(updateEssentials({ ...currentEssentialData, mergeRuleId: e.target.value, mergeRuleName: e.target.value }))
                break;
            case "description":
                dispatch(updateEssentials({ ...currentEssentialData, description: e.target.value }))
                break;
            case 'tags':
                if (e.target.value) {
                    const newTagsList = currentEssentialData.tags ? currentEssentialData.tags.split(",") : [];
                    newTagsList.push(e.target.value);
                    dispatch(updateEssentials({ ...currentEssentialData, tags: newTagsList.join(",") }))
                    setTagInputText('');
                }
                break;
            case "uniqueMerge":
                dispatch(updateEssentials({ ...currentEssentialData, uniqueMerge: !currentEssentialData.uniqueMerge }))
                break
            case "additionalModels":{
                dispatch(updateAdditionalModels(e.target.value))
                setLocalAdditionalModels(e.target.value)
            }
        }
    }

    const handleTenantChange = (e: any) => {
        if (!isSuperAdmin) {
            dispatch(updateEssentials({ ...currentEssentialData, subscriptionKey: userTeams.key[userTeams.name.indexOf(e.target.textContent)], tenantName: e.target.textContent }))
        } else {
            dispatch(updateEssentials({ ...currentEssentialData, subscriptionKey: allAppsList.find(item => item.appName === e.target.textContent)?.subscriptionKey || "", tenantName: e.target.textContent }))
        }
    }

    const handleFamilyChange = (e: any) => {
        dispatch(updateEssentials({ ...currentEssentialData, type: e.target.textContent }))
    }

    const handleRemoveTags = (tag: string) => {
        const newTagsList = currentEssentialData.tags ? currentEssentialData.tags.split(",") : [];
        const index = newTagsList.indexOf(tag);
        newTagsList.splice(index, 1);
        dispatch(updateEssentials({ ...currentEssentialData, tags: newTagsList.join(",") }));
    }

    return (
        <>
            {openHelpPanel &&
                <HelpContent openHelpPanel={openHelpPanel} setOpenHelpPanel={setOpenHelpPanel} />
            }
            
            <table className='mergerule-form-table mergerule-basics-table'>
                <tr>
                    <td>
                        <InformationTextMergeRule text={informationConfig.editMergeRuleEssentialInformation} type="Tab" />
                    </td>
                    <td style={{ justifyContent: "flex-end", display: "flex", maxWidth: "600px" }}>
                        <Button id="basicsmergeHelp" appearance="command" style={{ marginLeft: "40px", fontSize: "14px" }} onClick={() => { setOpenHelpPanel(!openHelpPanel); }}>
                            <Icon slot="start" name="help"></Icon>
                            Help
                        </Button></td>
                </tr>
                <tr>
                    <td>Name<Icon className='required-icon' name='actioncenterasterisk'></Icon>
                        <Icon id="namebasics" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="namebasics" fixedPlacement={true} placement='bottom'>
                            Enter a descriptive name. This will help you easily identify and manage your creations.
                        </Tooltip>
                    </td>
                    <td>
                        <TextField name='mergeruleName' placeholder="Enter Merge Rule Name" value={mergeRule.mergeRuleId !== 'undefined' ? mergeRule.mergeRuleId : ''} className={'Text-fields'} required
                            disabled={!access.canEdit || originalMergeRule.mergeRuleId !== 'undefined'}
                            onHeChange={handleBasicDetailsChange} />
                    </td>
                </tr>
                <tr className='mergerule-form-desc'>
                    <td>Description
                        <Icon id="descinfo" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="descinfo" fixedPlacement={true} placement='bottom'>
                            Clearly state the objective of merge rule, enhancing discoverability and reusability for other users.
                        </Tooltip></td>
                    <td>
                        <TextArea resize="vertical" name='description' placeholder="Add Description" value={currentEssentialData.description || ''} className={'Text-fields'} required
                            disabled={!access.canEdit}
                            onHeInput={handleBasicDetailsChange} />
                    </td>
                </tr>
                <tr>
                    <td>Tags
                        <Icon id="info404" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info404" fixedPlacement={true} placement='bottom'>
                            Tags referenced by owner or automatically added by Nebula to help discoverability
                        </Tooltip></td>
                    <td>
                        <TextField label="Tags" disabled={!access.canEdit} name='tags' className={'Text-fields'} onHeChange={handleBasicDetailsChange} value={tagInputText} />
                        <br />
                        <div className='label-input tag-list'>
                            {
                                currentEssentialData.tags ?
                                    currentEssentialData.tags?.split(",")?.map(item => (
                                        (
                                            <Button disabled={!access.canEdit} appearance='command' value='' className='Attribute-buttons' onClick={() => handleRemoveTags(item)}>
                                                {item}
                                                <Icon slot='start' name='cancel'></Icon>
                                            </Button>
                                        ))) : <></>
                            }
                        </div>
                    </td>
                </tr>
                <br />

                <tr>
                    <td>Tenant<Icon className='required-icon' name='actioncenterasterisk'></Icon>
                        <Icon id="info401" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info401" fixedPlacement={true} placement='bottom'>
                            Choose the team under which you want to create the Merge Rule. This choice is very important as only those Insights/Recommendations will be available to merge that belong to the chosen team.
                        </Tooltip>
                    </td>
                    <td>
                        {isSuperAdmin &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                value={allAppsList.find(item => item.subscriptionKey === currentEssentialData.subscriptionKey)?.appName || ""}
                                slot="action"
                                fixed-placement
                                placeholder={allAppsList.find(item => item.subscriptionKey === currentEssentialData.subscriptionKey)?.appName}
                                className={'Text-fields'}
                                name="tenant"
                                disabled={!access.canEdit || (originalMergeRule.subscriptionKey !== undefined && originalMergeRule.subscriptionKey !== "") || source === "default"}
                                required
                            >
                                {
                                    allAppsList.map(item => (
                                        <Option
                                            key={item.subscriptionKey}
                                            role="menuitemcheckbox"
                                            onHeSelected={handleTenantChange}
                                        >
                                            {item.appName}
                                        </Option>
                                    ))
                                }
                            </Select>
                        }
                        {!isSuperAdmin &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                value={userTeams.name[userTeams.key.indexOf(currentEssentialData.subscriptionKey)] || ""}
                                slot="action"
                                fixed-placement
                                placeholder={allAppsList.filter(item => item.subscriptionKey === currentEssentialData.subscriptionKey)[0]?.appName}
                                className={'Text-fields'}
                                name="tenant"
                                disabled={!access.canEdit || (originalMergeRule.subscriptionKey !== undefined && originalMergeRule.subscriptionKey !== "") || source === "default"}
                                required
                            >
                                {
                                    Object.keys(userTeams.name)?.map(item => (
                                        <Option
                                            key={userTeams.name[Number(item)]}
                                            role="menuitemcheckbox"
                                            onHeSelected={handleTenantChange}
                                        >
                                            {userTeams.name[Number(item)]}
                                        </Option>
                                    ))
                                }
                            </Select>
                        }
                    </td>
                </tr>
                <tr>
                    <td>Family<Icon className='required-icon' name='actioncenterasterisk'></Icon>
                        <Icon id="info4011" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info4011" fixedPlacement={true} placement='bottom'>
                            Pre-requisite for this is to choose Tenant from above dropdown. Choose the family rightly as only those Insights/Recommendations will be available for merging which have chosen family associated with them.
                        </Tooltip>
                    </td>
                    <td>
                        {listOfFamilies.length > 0 &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                className={'Text-fields'}
                                value={currentEssentialData.type || ''}
                                slot="action"
                                fixed-placement
                                placeholder={currentEssentialData.type || 'Select Family'}
                                name="family"
                                required
                                disabled={!access.canEdit}
                                validationMessage="Please choose a Tenant"
                            >
                                {
                                    listOfFamilies.map(item => (
                                        <Option
                                            key={item}
                                            role="menuitemcheckbox"
                                            onHeSelected={handleFamilyChange}
                                        >
                                            {item}
                                        </Option>
                                    ))

                                }
                            </Select>
                        }
                        {listOfFamilies.length === 0 &&
                            <Select
                                autocomplete="both"
                                style={{ marginBottom: '25px' }}
                                value={currentEssentialData.type || ''}
                                slot="action"
                                fixed-placement
                                placeholder='Select Family'
                                className={'Text-fields'}
                                name="family"
                                disabled={!access.canEdit}
                                required
                            >
                                {

                                }
                            </Select>
                        }
                    </td>
                </tr>
                <tr>
                    <td>Recommendations created should be unique
                        <Icon id="info4049" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info4049" fixedPlacement={true} placement='bottom'>
                            Toggle this on if you want recommendations generated to be unique in all cases i.e, even if actioned
                        </Tooltip>
                    </td>
                    <td><Toggle label="" name="uniqueMerge" checked={currentEssentialData.uniqueMerge} onClick={handleBasicDetailsChange} disabled={!access.canEdit}/></td>
                </tr>
                <tr className="mergerule-form-desc">
                    <td>Additional Insight/Recommendation model names that should participate in merge but are not part of any of the configured merge rule sets
                        <Icon id="additionalmodels" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="additionalmodels" fixedPlacement={true} placement='bottom'>
                            Enter comma separated model names of the Insights/Recommendations that you want to participate in merge but are not part of any of your merge rule sets. You can get model name from first tab of respective insight/recommendation.
                        </Tooltip>
                    </td>
                    <td>
                        <TextArea resize="vertical" name='additionalModels' label="Add Additional Models" placeholder="Add Additional Models" value={localAdditionalModels || ''} className={'Text-fields'} 
                            disabled={!access.canEdit}
                            onHeInput={handleBasicDetailsChange} />
                    </td>
                </tr>
                <tr>
                    <td style={{whiteSpace:"nowrap"}}>
                    
          <span style={{display:"inline-block", whiteSpace:"nowrap", paddingRight:"8px", height:"28px", lineHeight:"28px"}}>Select Fields used to prioritize predictions</span>
          <Icon id="info404922" name="info" style={{ cursor: "pointer", color: "#0078D4" }} />
          <Tooltip anchor="info404922" fixedPlacement={true} placement='bottom'>
              Select fields which will be used to prioritize one prediction chosen as the base for resulting recommendation from multiple predictions.
          </Tooltip>
       
                    </td>
                    <td>
                    <MergeRuleGlobalPriorityFields/>

                    </td>


                </tr>
                <tr>
                    <td>Version
                        <Icon id="info40491" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                        <Tooltip anchor="info40491" fixedPlacement={true} placement='bottom'>
                            Shows the current version of Merge Rule
                        </Tooltip>
                    </td>
                    <td>
                        <TextField label="Version" name='version' disabled={true} placeholder="" value={mergeRule.version || '1'} style={{ maxWidth: "50px" }} />
                    </td>
                </tr>
            </table>
        </>
    )
}
export const EditMergeRulesEssentials = withErrorBoundary("EditMergeRulesEssentials", EditMergeRulesEssentialsC);