import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../model/ServiceRequestStatus.model'
import { getEntityIds, getTestApi } from '../../services/GetTestApi.api'

interface GetEntityIdsState {
    status: RequestStatus,
    error: string | undefined,
    data: Map<string,Map<string,string>[]>
}

const initialState: GetEntityIdsState = {
    status: RequestStatus.idle,
    error: '',
    data: {} as Map<string,Map<string,string>[]>
}
export const getEntityIdsSlice = createSlice({
    name: 'getentityidsapi',
    initialState,
    reducers: {
        resetGetEntityIdsState: (state) => {
            state.status = RequestStatus.idle
            state.error = ''
            state.data = {} as Map<string,Map<string,string>[]>
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getEntityIds.pending, (state, action) => {
                state.data = {} as Map<string,Map<string,string>[]>
                state.status = RequestStatus.loading
            })
            .addCase(getEntityIds.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload.data?.OperationId) {
                    state.data = {} as Map<string,Map<string,string>[]>// payload.data
                    state.error = payload.data.Message
                } else {
                    state.data = payload.data
                    state.error = undefined
                    
                }
            })
            .addCase(getEntityIds.rejected, (state, action) => {
                state.data = {} as Map<string,Map<string,string>[]>
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
    }
})

export const { resetGetEntityIdsState } = getEntityIdsSlice.actions


export const getEntityIdsSliceReducer = getEntityIdsSlice.reducer