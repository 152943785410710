import { useEffect, useState } from 'react';
import { Dropdown, Menu, MenuItem, SearchBox, FilterPill, CheckboxGroup, Checkbox } from '../../SpaAdminScope';
import { withErrorBoundary } from '../../../../shared-components/ErrorBoundary';

const DropdownFilter = ({ label, values, children }: any) => (
  <Dropdown>
    <FilterPill slot="trigger" values={values} caret>
      {label}
    </FilterPill>
    {children}
  </Dropdown>
);

const FilterComponentC = (props:any) => {
  
  const [searchText, setSearchText] = useState('');
  const data = props.allData;

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
};

  useEffect(()=>{
    const appliedFilter = (searchText?searchText:'').trim()?.toLowerCase();
    var newFilteredObj = Object.assign({});

    (data || []).forEach((key: string) => {
      if (key?.toLowerCase()?.includes(`${appliedFilter}`)) {
        newFilteredObj[key] = props.checkedState[key];
      }
    });

    props.setCheckedState(newFilteredObj);

  }, [searchText])

  const handleChange = ({ target }:any) =>
  {
    props.setCheckedState({ ...props.checkedState, [target.textContent]: target.checked });
  }
   
  return (<>
     <DropdownFilter
      label={props.filterlabel}
      values={Object.keys(props.selectedChecks).filter((key: string) => props.selectedChecks[key])}
    >
     {props.searchBarVisible && <SearchBox style = {{padding:"8px"}} onInput={handleSearch} placeholder="Search"
            onHeClear={() => setSearchText("")}/>}
      <CheckboxGroup style={{ padding: '15px'}}>
        {Object.keys(props.checkedState).sort().map(key => (
          <Checkbox
            key={key}
            role="menuitemcheckbox"
            checked={props.checkedState[key]}
            onHeChange={handleChange}
            className='filterCheckbox'
          >
            {key}
          </Checkbox>
        ))}
      </CheckboxGroup>

    </DropdownFilter>
  </>
 
  );
};
export const FilterComponent = withErrorBoundary("Filter Component", FilterComponentC);