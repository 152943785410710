import { MergeRuleSet } from "./MergeRuleSet.model"
export class MergeRule {
    public subscriptionKey!: string
    public mergeRuleId!: string
    public mergeRuleName!: string
    public type!: string
    public version!: string
    public distinctField!: string
    public uniqueMerge!: boolean
    public sourceFilter!: string[]
    public primaryMergeRuleSet!: MergeRuleSet[]
    public secondaryMergeRuleSet!: MergeRuleSet[]
    public tenantName!: string
    public modifiedDate!: string
    public modifiedBy!: string
    public tags!:string
    public description!: string
    public prioritizePredictionsOnField !: string[]
    public id!:string
    public isFlightedRule!: boolean
    public parentRuleForFlighting !: string
}
export const emptyMergeRule: MergeRule = {
    mergeRuleId: '',
    mergeRuleName: '',
    subscriptionKey: '',
    type: '',
    version: '1',
    distinctField: '',
    uniqueMerge: false,
    sourceFilter: [],
    primaryMergeRuleSet: [],
    secondaryMergeRuleSet: [],
    tenantName: '',
    modifiedDate: '',
    modifiedBy: '',
    tags: '',
    description: '',
    prioritizePredictionsOnField: [],
    id: '00000000-0000-0000-0000-000000000000',
    isFlightedRule: false,
    parentRuleForFlighting: ''
}

export interface IMergeRuleResponse {
    mergeRule: MergeRule
}