import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { getWorkflowsBySubKeys } from "../../../services/GetWorkflows.api";

export interface WorkflowsBySubKeyState {
  list: WorkflowSummary[];
  status: RequestStatus;
  error: string | undefined;
}

const initialState: WorkflowsBySubKeyState = {
  list: [],
  status: RequestStatus.idle,
  error: "",
};

export const workflowBySubKeySlice = createSlice({
  name: "workflowsBySubKey",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkflowsBySubKeys.pending, (state, action) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getWorkflowsBySubKeys.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded;
        var output = action.payload
        if (output instanceof Array){
          state.list = output
        } else {state.list = []}
        state.error = undefined;
      })
      .addCase(getWorkflowsBySubKeys.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.error = action.error.message;
      })
  },
});

export const {  } =
  workflowBySubKeySlice.actions;

export const workflowsBySubKeyReducer = workflowBySubKeySlice.reducer;
