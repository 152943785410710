import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { RequestMessageBar } from "../../shared-components/message-bar/RequestMessageBar";
import { resetPostMergeRuleStatus } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";

export const EditMergeRuleMessageBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const postMergeRuleStatus = useSelector((state: RootState) => state.mergeRuleById.postMergeRuleStatus);
    
    return <RequestMessageBar status={postMergeRuleStatus} callback={() => dispatch(resetPostMergeRuleStatus())}/>
}