
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const DataSourcesC: React.FC<any> = (props: any) => {

  return (
    <>
    
    <div className="help-mainHeading">
          <span id="importTag" className="help-subheading">Import model data </span>
          <div style = {{marginBottom:"8px"}}>
          <span className="help-heading">Summary:</span>
          <span className="help-content"> {onboarding.datasources.summary} </span>
          </div>
          <div style = {{marginBottom:"8px"}}>
          <Icon name = "add" className="help-icon"/>
          <span className="help-heading">Get Data: </span>
          <span className="help-content"> {onboarding.datasources.getdata} </span>
          </div>
          </div>
          <div style = {{marginTop:"12px"}}>
          <div style = {{marginBottom:"8px"}}>
          <span className="help-heading">Source model/signal data:</span>
          <span className="help-content"> {onboarding.datasources.sourcemodeldata} </span>
          </div>
          <div style = {{marginBottom:"8px"}}>
          <span className="help-heading">Imported Datasets:</span>
          <span className="help-content"> {onboarding.datasources.importeddata} </span>
          </div>
          <div id ="onboarding_refine" style = {{marginBottom:"8px"}}>
          <span className="help-heading">Preview:</span>
          <span className="help-content"> {onboarding.datasources.preview} </span>
          </div>
          </div>
          <HelpNote text = {<span>Nebula supports importing of data sources from various streams - <b style = {{fontWeight:"500"}}> ADLS, ADLSGen1, BLOB, SQL, SQLAAD, API, COSMOS. </b> <br/> <a href = "">Click here</a> to learn more about these data sources type.</span>} />
          <hr/>

    </>
  );
};
export const DataSources = withErrorBoundary("DataSources content", DataSourcesC);