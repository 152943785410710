import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SuperAdminEmail } from '../../model/super-admin-email/SuperAdminEmail.model';
import { RequestStatus } from '../../model/ServiceRequestStatus.model';
import { getSuperAdminEmail } from '../../services/GetSuperAdminEmail.api';

interface SuperAdminState {
    superAdminEmail : string,
    status: RequestStatus,
    error: string | undefined

}

const initialState: SuperAdminState = {
    superAdminEmail : "",
    status: RequestStatus.idle,
    error: ''
}

export const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    addSuperAdmin (state)  {
      state.superAdminEmail = ""
    }
  },
  extraReducers(builder){
    builder
    .addCase(getSuperAdminEmail.pending, (state) => {
        state.status = RequestStatus.loading
    })
    .addCase(getSuperAdminEmail.fulfilled, (state, action: PayloadAction<SuperAdminEmail>) => {
        state.status = RequestStatus.succeeded
        state.superAdminEmail = action.payload.SuperAdminEmail
        state.error = undefined
    })
    .addCase(getSuperAdminEmail.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
  }
});

export const superAdminReducer = superAdminSlice.reducer;
