import { useSelector } from 'react-redux';
import { RootState } from "../../root-redux/RootState";
import { APIResponseMappingObject } from '../../model/apiConfig/APIResponseMappingObject.model';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteRequest } from '../utils/DeleteRequest';



export const deleteApiConfig = createAsyncThunk<boolean, {routeType: string, routeName: string}, {state: RootState}>('apiConfig/deleteApiConfig', async ({routeType, routeName}, {getState} ) => {
  const env = getState().env.selectedEnvItem
  const response = await deleteRequest<boolean>(`${env}/APIResponseMapping/${routeType}/${routeName}`)
    return response.data;
  })
