import { OutputRow } from "./OutputRow.model"
import { MergeOn, emptyMergeOn } from "./MergeOn.model"
import { MergeOverride } from "./MergeOverride.model"

export class MergeRuleSet {
    public id!: string
    public rank!: string
    public name!: string
    public type!: string
    public description!: string
    public priority!: string[]
    public mergeModel!: string[]
    public mergeOn!: MergeOn
    public useMRLevelConfigToPrioritizePredictions !: boolean
    public prioritizePredictionsOnField !: string[]
    public filter!: string
    public filterRec!: string
    public applyAllModels!: boolean
    public output!: OutputRow[]
    public update!: MergeOverride[]
    public create!: MergeOverride[]
    public jsonQueryStringFilter!: string
    public jsonQueryStringFilterRec!: string
    public filterToDisplay!: string 
    public filterRecToDisplay!: string
}
const emptyStringArray : string[]=[]
const emptyOutputArray : OutputRow[]=[]
const emptyUpdateArray : MergeOverride[]=[]
const defaultUpdateArray: MergeOverride[] = []
var mergeOverride = new MergeOverride()
mergeOverride.key = "DataChange"
mergeOverride.value = "true"

var mergeOverrideRule = new MergeOverride()
mergeOverrideRule.key = "RuleChange"
mergeOverrideRule.value = "true"
defaultUpdateArray.push(mergeOverride)
defaultUpdateArray.push(mergeOverrideRule)

export const emptyMergeRuleSet = {
    id : '',
    rank: '',
    name: '',
    type: 'primary',
    description: '',
    priority: emptyStringArray ,
    mergeModel: emptyStringArray,
    mergeOn: emptyMergeOn,
    useMRLevelConfigToPrioritizePredictions: true,
    prioritizePredictionsOnField: emptyStringArray,
    filter: '',
    filterRec: '',
    applyAllModels: false,
    output: emptyOutputArray,
    update: defaultUpdateArray,
    create: emptyUpdateArray,
    jsonQueryStringFilter: "",
    jsonQueryStringFilterRec: "",
    filterToDisplay: "",
    filterRecToDisplay: ""
}