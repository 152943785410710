import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { getConnections } from '../../../services/GetConnections.api'
import { ConnectionsCollection } from '../../../model/connections/ConnectionsCollection.model'
import { cloneConnection, deleteConnections, postConnections } from '../../../services/PostConnections.api'

interface ConnectionsState {
    list: {[subscriptionKey: string]: ConnectionsCollection},
    status: RequestStatus,
    error: string | undefined,
    selectedConnectionIds: string[],
    showClonePopup: boolean,
    postStatus: RequestStatus,
    env: string    
}

const initialState: ConnectionsState = {
    list: {},
    status: RequestStatus.idle,
    error: undefined,
    selectedConnectionIds: [],
    showClonePopup: false,
    postStatus: RequestStatus.idle,
    env: 'dev'
}

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setSelectedConnectionIds(state, action: PayloadAction<string[]>) {
      state.selectedConnectionIds = action.payload
    },
    setShowClonePopup(state, action: PayloadAction<boolean>) {
      state.showClonePopup = action.payload      
    },
    resetPostConnectionStatus(state) {
      state.postStatus = RequestStatus.idle
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getConnections.pending, (state, _) => {
        state.status = RequestStatus.loading
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded
        state.list = action.payload.connections
        state.env = action.payload.env
        state.error = undefined
      })
      .addCase(getConnections.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
      .addCase(cloneConnection.pending, (state, _) => {
        state.postStatus = RequestStatus.loading
      })
      .addCase(cloneConnection.fulfilled, (state, action) => {
        state.postStatus = RequestStatus.succeeded
        if (action.payload) {
          state.list = action.payload
        }        
        state.error = undefined
      })
      .addCase(cloneConnection.rejected, (state, action) => {
        state.postStatus = RequestStatus.failed
        state.error = action.error.message
      })
      .addCase(deleteConnections.pending, (state, _) => {
        state.postStatus = RequestStatus.loading
      })
      .addCase(deleteConnections.fulfilled, (state, action) => {
        state.postStatus = RequestStatus.succeeded
        if (action.payload) {
          state.list = action.payload
        }        
        state.error = undefined
      })
      .addCase(deleteConnections.rejected, (state, action) => {
        state.postStatus = RequestStatus.failed
        state.error = action.error.message
      })
      .addCase(postConnections.pending, (state, _) => {
        state.postStatus = RequestStatus.loading
      })
      .addCase(postConnections.fulfilled, (state, action) => {
        if (!state.showClonePopup && action.meta?.arg?.newConnection) {
          state.selectedConnectionIds = [action.meta.arg.newConnection.connectionId]
        }
        state.postStatus = RequestStatus.succeeded
        if (action.payload) {
          state.list = action.payload
        }        
        state.error = undefined
      })
      .addCase(postConnections.rejected, (state, action) => {
        state.postStatus = RequestStatus.failed
        state.error = action.error.message
      })
  }
})

export const { setSelectedConnectionIds, setShowClonePopup, resetPostConnectionStatus } = connectionsSlice.actions

export const connectionsReducer = connectionsSlice.reducer