import { useRef } from "react";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";
import { Button, Card, Icon, DataGrid, Pagination, HoverCard, ProgressRing, MessageBar } from "../../SpaAdminScope";
import { useDispatch, useSelector } from "react-redux";
import { filteredQueryDataListSelector } from "../../redux/CampaignQueryBuilder.selector.redux";
import { dataGrid } from "@harmony/enablers/react";
import { AppDispatch, RootState } from "../../../../root-redux/RootState";
import { RequestStatus } from "../../../../model/ServiceRequestStatus.model";
import { resetResultList } from "../../redux/CampaignQueryBuilder.redux";
import { WorkflowSummary } from "../../../../model/workflows/Workflow.model";

interface IViewResultCart {
    setSelectedRows: (rows: Array<any>) => void;
    setRenderViewResults: (flag: boolean) => void;
    handlePageChange: (e: any) => void;
    getAddAllCount: (workflowSummary: WorkflowSummary|undefined) => void;
}

const ViewResultsC: React.FC<IViewResultCart> = ({ setSelectedRows, setRenderViewResults, handlePageChange, getAddAllCount }) => {

    const dispatch = useDispatch<AppDispatch>();

    const dataGridRef = useRef<dataGrid>(null);
    const filteredQueryDataCartList = useSelector(filteredQueryDataListSelector);
    const filteredCampaign = useSelector((state: RootState) => state.filteredCampaign);

    const onRowSelect = (e: any) => {
        setSelectedRows((dataGridRef.current!.selectedRows || []).map(detail => detail.customData as string))
    }

    const renderErrorMessage = (message: string | undefined) => {
        if (!message) {
            return <></>;
        }
        return (
            <MessageBar appearance="error" open>
                {message}
            </MessageBar>
        );
    }

    return (
        <>
            {renderErrorMessage(filteredCampaign.error)}
            <Card className='spa-query-card'>
                <span slot="heading">
                    <div className="spa-query-card-command">
                        <>
                            {/* <SearchBox/> */}
                            <Button 
                                appearance="command"
                                onClick={() => {
                                    dispatch(resetResultList());
                                    setRenderViewResults(false);
                                    getAddAllCount(undefined);
                                }}
                                style={{marginRight: '10px'}}
                                disabled={filteredCampaign.countStatus === RequestStatus.loading}
                            >
                                <Icon name='back' style={{ cursor: "pointer", color: "#0078D4" }}/>
                            </Button>
                            <HoverCard heading="Add all Records">
                                <Button slot="anchor" appearance="stealth" style={{ marginRight: '10px'}}>
                                    <Icon name='info' style={{ cursor: "pointer", color: "#0078D4", paddingRight: '0' }}/>
                                </Button>
                                <br/>To add all the accounts to campaign, Please click on <code>Add all to Cart</code>. 
                            </HoverCard>
                            <span style={{ color: 'gray', top: '4px', position: 'relative', fontSize: '16px'}}>
                                {filteredCampaign.countStatus === RequestStatus.loading && <ProgressRing indeterminate></ProgressRing> } 
                                {filteredCampaign.countStatus === RequestStatus.succeeded && `(Found: ${filteredCampaign.totalRowCount} records)` } 
                            </span>
                            <p style={{ marginLeft: 'auto', color: 'silver', top: '10px', fontSize: '13px', position: 'relative'}}>(Rows: {filteredQueryDataCartList.rows.length})</p>
                        </>
                    </div>
                </span>

                <div>
                    <DataGrid
                        rows={filteredQueryDataCartList.rows}
                        columns={filteredQueryDataCartList.columns}
                        select="multiple"
                        loading={filteredQueryDataCartList.loading}
                        onHeRowSelectChange={onRowSelect}
                        onHeSelectAllChange={onRowSelect}
                        ref={dataGridRef}
                    >
                        <span slot="no-records">No results found.</span>
                    </DataGrid>
                    <Pagination
                        slot="pagination"
                        value={filteredCampaign.currPage}
                        pageCount={filteredQueryDataCartList.totalPage}
                        onHePageChange={handlePageChange}
                    />
                </div>
            </Card>
        </>
    )
};

export const ViewQueryResults = withErrorBoundary('ViewQueryResults: DataCart', ViewResultsC);