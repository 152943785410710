import { useEffect, useState } from 'react';
import { FilterPill, VisuallyHidden } from '../../connections/ConnectionScope';
import { Dropdown, Menu, MenuItem, SearchBox } from '../MergeRulesScope';

const DropdownFilter = ({ label, values, children }: any) => (
  <Dropdown>
    <FilterPill slot="trigger" values={values} caret>
      {label}
    </FilterPill>
    {children}
  </Dropdown>
);

export const FilterComponent = (props:any) => {
  
  const [searchText, setSearchText] = useState('');
  const data = props.allData;
  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
};

useEffect(()=>{
  const appliedFilter = (searchText?searchText:'').trim()?.toLowerCase();
  var newFilteredObj = Object.assign({});

  data.forEach((key: string) => {
    if (key && key.toLowerCase().includes(`${appliedFilter}`)) {
      newFilteredObj[key] = props.checkedState[key];
    }
  });
  props.setCheckedState(newFilteredObj);

}, [searchText])

  const handleChange = ({ target }:any) =>
    props.setCheckedState({ ...props.checkedState, [target.textContent]: target.checked });

  return (<>
     <DropdownFilter
      label={props.filterlabel}
      values={Object.keys(props.checkedState).filter((key: string) => props.checkedState[key])}
    >
     {props.searchBarVisible && <SearchBox style = {{padding:"8px"}} onInput={handleSearch} placeholder="Search"
            onHeClear={() => setSearchText("")}/>}
      <Menu>
        {Object.keys(props.checkedState).map(key => (
          <MenuItem
            key={key}
            role="menuitemcheckbox"
            checked={props.checkedState[key]}
            onHeChange={handleChange}
          >
            {key}
          </MenuItem>
        ))}
      </Menu>

    </DropdownFilter>
  </>
 
  );
};