import { Connection } from "../../../model/connections/Connection.model"
import { ConnectionType } from "../../../model/connections/ConnectionType.model"
import { ConnectionsCollection } from "../../../model/connections/ConnectionsCollection.model"
import { RootState } from "../../../root-redux/RootState"
import { getConnections } from "../../../services/GetConnections.api"

export const connectionsSelector = (state: RootState): {connections: ConnectionsCollection, appScopes: string[]} => {
    const subscriptionKey = state.campaignById.campaign!.subscriptionKey //TO=DO need to remove this sandbox sub key//"3F2504E0-4F89-11D3-9A0C-0305E82C3301";
    const connectionsObj = state.connections.list
    const appScopes = new Set<string>()

    if (connectionsObj) {
      Object.keys(connectionsObj).forEach((key: string) => {
        const connectionItems = connectionsObj[key]
        if (connectionItems) {
          Object.values(ConnectionType).forEach((value: ConnectionType) => {
            connectionItems[value]?.forEach((item: Connection) => {
                appScopes.add(item.appScope)
            })
          });
        }                
      })
    }   
    return {connections: connectionsObj[subscriptionKey], appScopes: [...appScopes]}
}