import { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem, DataGrid } from "../workflows/WorkflowsScope";
import { useLocation, useNavigate } from "react-router";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { getMultipleWorkflowsByIdandVersion, getWorkflowById, getWorkflowByIdandVersion_dev, getWorkflowByIdandVersion_prod, getWorkflowByIdandVersion_uat } from "../../services/GetWorkflows.api";
import { getDevReleases, getProdReleases, getUatReleases } from "../../services/GetReleases.api";
import { ReleaseActionsUAT } from "./release-actions-comand-bar/ReleaseActionsUAT";
import { ReleaseActionsPROD } from "./release-actions-comand-bar/ReleaseActionsPROD";
import { ReleaseActionsDEV } from "./release-actions-comand-bar/ReleaseActionsDEV";
import { RollBack } from "./release-actions-comand-bar/Rollback";
import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { getConnections_dev, getConnections_prod, getConnections_uat } from "../../services/GetConnections.api";
import { getAppPersonas_dev, getAppPersonas_prod, getAppPersonas_uat } from "../../services/GetAppPersonaMapping.api";
import ScheduleWorkflow from "../workflows/schedule-workflow/ScheduleWorkflow";
import { Button, Icon } from "../../common/HarmonyEnablers";
import { setShowSchedulePanel } from "../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import informationConfig from '../../shared-content/inputDescription';
import { InformationText } from "../../shared-components/info-text/InformationText";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { ReleaseCallsStatus } from "./ReleaseCallsStatus";
import { ReleasesHeader } from "./ReleasesHeader";
import "./ReleaseManagement.css"
import { setSelectedWorflowVersions } from "./ReleaseData.redux";
import { DiffPanel } from "../diff-panel/DiffPanel";

export interface ReleaseDataGridRow extends Row {
  id: string,
  cells: {
    field1: string;
    field2: string;
    field3: string;
    field4: string;
    field5: string;
    field6: string;
    field7: string;
  };
};

export const columns: Column[] = [
  {
    field: "field1",
    content: "Version"
  },
  {
    field: "field2",
    content: "DEV Deployment"
  },
  {
    field: "field3",
    content: "DEV Schedule",
  },
  {
    field: "field4",
    content: "UAT Deployment"
  },
  {
    field: "field5",
    content: "UAT Schedule"
  },
  {
    field: "field6",
    content: "PROD Deployment"
  },
  {
    field: "field7",
    content: "PROD Schdeule"
  }
];

const ReleaseManagementC = () => {

    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { workflowId } = location.state;
    const { workflowName } = location.state;
    const { subscriptionKey } = location.state;
    const [releaseRows, setReleaseRows] = useState([] as ReleaseDataGridRow[]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [releaseId, setReleaseId] = useState('');
    const [selectedReleaseIds, setSelectedReleaseIds] = useState([] as string[]);
    const getConfigStatus = useSelector((state: RootState) => state.releaseData.getConfigStatus);
    const getReleasesStatus = useSelector((state: RootState) => state.releases.getReleasesStatus);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const selectedWorkflowVersions = useSelector((state: RootState) => state.releaseData.selectedWorkflowVersions);
    const [loadingState, setLoadingState] = useState(true);


    useEffect(() => {
      if (
        getConfigStatus.getWorkflowStatus.dev === RequestStatus.loading || getConfigStatus.getWorkflowStatus.uat === RequestStatus.loading || getConfigStatus.getWorkflowStatus.prod === RequestStatus.loading || 
        getConfigStatus.getConnectionsStatus.dev === RequestStatus.loading || getConfigStatus.getConnectionsStatus.uat === RequestStatus.loading || getConfigStatus.getConnectionsStatus.prod === RequestStatus.loading || 
        getConfigStatus.getAppPersonaStatus.dev === RequestStatus.loading ||  getConfigStatus.getAppPersonaStatus.uat === RequestStatus.loading ||  getConfigStatus.getAppPersonaStatus.prod === RequestStatus.loading || 
        getConfigStatus.getModelMappingStatus.dev === RequestStatus.loading || getConfigStatus.getModelMappingStatus.uat === RequestStatus.loading ||
        getConfigStatus.getMergeRulesStatus.dev === RequestStatus.loading || getConfigStatus.getMergeRulesStatus.uat === RequestStatus.loading ||
        getReleasesStatus.dev === RequestStatus.loading || getReleasesStatus.uat === RequestStatus.loading || getReleasesStatus.prod === RequestStatus.loading ||
        getConfigStatus.getApiConfigStatus.dev === RequestStatus.loading || getConfigStatus.getApiConfigStatus.uat === RequestStatus.loading ||
        getConfigStatus.getCatalogueConfigStatus.dev === RequestStatus.loading || getConfigStatus.getCatalogueConfigStatus.uat === RequestStatus.loading
        ) 
        setLoadingState(true);
      else
          setLoadingState(false);
    }, [getConfigStatus, getReleasesStatus]);

    useEffect(() => {
      dispatch(getDevReleases(workflowId));
      dispatch(getUatReleases(workflowId));
      dispatch(getProdReleases(workflowId));
      dispatch(getAppPersonas_dev());
      dispatch(getAppPersonas_uat());
      dispatch(getAppPersonas_prod());
    }, [dispatch]);

    let releaseItems:{
      version: string, releaseId:string, workflowId:string, id_dev:string, id_uat:string, id_prod:string, time_dev:string, time_uat:string, time_prod:string, dev:boolean, uat:boolean, prod:boolean,
      status_dev: string, status_uat: string, status_prod:string, scheduled_dev:boolean, scheduled_uat:boolean, scheduled_prod:boolean
      }[] = [];

    const devReleases = useSelector((state: RootState) => state.releases.releasesDev);
    const uatReleases = useSelector((state: RootState) => state.releases.releasesUat);
    const prodReleases = useSelector((state: RootState) => state.releases.releasesProd);

    useEffect(() => {
      dispatch(getWorkflowByIdandVersion_dev({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : devReleases.length != 0 ? devReleases[0].version : ''}));
      dispatch(getWorkflowByIdandVersion_uat({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : uatReleases.length != 0 ? uatReleases[0].version : ''}));
      dispatch(getWorkflowByIdandVersion_prod({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : prodReleases.length != 0 ? prodReleases[0].version : ''}));
    }, [dispatch, selectedVersion, workflowId]);

    useEffect(() => {
          const subscriptionKeys = [];
          subscriptionKeys.push(subscriptionKey);
          dispatch(getConnections_dev({subscriptionKeys}));
          dispatch(getConnections_uat({subscriptionKeys}));
          dispatch(getConnections_prod({subscriptionKeys}));
  }, [dispatch, subscriptionKey]);

    let devWorkflow = useSelector((state: RootState) => state.releaseData.devWorkflow);
    let uatWorkflow = useSelector((state: RootState) => state.releaseData.uatWorkflow);
    let prodWorkflow = useSelector((state: RootState) => state.releaseData.prodWorkflow);
    
        useEffect(() => {
          releaseItems = [];
            devReleases?.map((release) => {

              let uat_ind = uatReleases.length == 0 ? -1 : uatReleases.findIndex(item  => item.version == release.version);
              let prod_ind = prodReleases.length == 0 ? -1 : prodReleases.findIndex(item  => item.version == release.version);

              if(releaseItems.findIndex(item => item.version == release.version) == -1)
              {
                
                releaseItems.push({
                  version: release.version,
                  releaseId: release.releaseId,
                  workflowId: release.workflowId,
                  id_dev: release?.id,
                  id_uat: uat_ind > -1 ? uatReleases[uat_ind]?.id : "",
                  id_prod: prod_ind > -1 ? prodReleases[prod_ind]?.id : "",
                  time_dev: release.timestamp,
                  time_uat: uat_ind > -1 ? uatReleases[uat_ind].timestamp : "",
                  time_prod: prod_ind > -1 ? prodReleases[prod_ind].timestamp : "",
                  dev: true,
                  uat: uat_ind > -1 ? true : false,
                  prod: prod_ind > -1 ? true : false,
                  status_dev: release.status,
                  status_uat: uat_ind > -1 ? uatReleases[uat_ind].status : "",
                  status_prod: prod_ind > -1 ? prodReleases[prod_ind].status : "",
                  scheduled_dev: release.scheduleCreated,
                  scheduled_uat: uat_ind > -1 ? uatReleases[uat_ind].scheduleCreated : false,
                  scheduled_prod: prod_ind > -1 ? prodReleases[prod_ind].scheduleCreated : false
                  })
                }});
               
          }, [devReleases, uatReleases, prodReleases]);

        useEffect(() => {

          const transformedReleaseRows : ReleaseDataGridRow[] = [];

          releaseItems.map((item:any) => {

            transformedReleaseRows.push({
              id: item.version,
              cells:{
                field1: item.version,
                field2: item.status_dev == "Published" ? "Deployed" : "Draft",
                field3: item.scheduled_dev ? "Scheduled" : "",
                field4: item.uat == true ?  (item.status_uat == "Submitted" ? "Awaiting Approval" : item.status_uat == "Published" ? "Deployed" : "Draft") : "",
                field5: item.scheduled_uat ? "Scheduled" : "",
                field6: item.prod == true ? (item.status_prod == "Submitted" ? "Awaiting Approval" : item.status_prod == "Published" ? "Deployed"  : "Draft") : "",
                field7: item.scheduled_prod ? "Scheduled" : "",
              }
            });
            setReleaseRows(transformedReleaseRows);
          });

        }, [releaseItems]);

        const handleRowChange = (e:any) => {
          let selectedRows : string[] = [];
          selectedRows = releaseRows?.filter(x => x.selected)?.map(x => {
              return x.id.toString();
          });
          setSelectedReleaseIds(selectedRows);
          if(selectedRows.length === 1)
          {
            setSelectedVersion(releaseRows?.filter(x => x.selected)[0].cells.field1);
            const releaseId = devReleases.find(x => x?.version == e.id)?.releaseId;
            setReleaseId(releaseId!);
          }
          else{
            setSelectedVersion('');
            setReleaseId('');  
          }
          const selectedVersions = releaseRows?.filter(x => x.selected)?.map(x => {
            return x.cells.field1.toString();
          })
          dispatch(setSelectedWorflowVersions(selectedVersions));
      };

  return (
    <>

    <ReleasesHeader workflowName = {workflowName} />
      
      <div>
        <div style={{ marginLeft: "8px" }}>
          <div
            style={{ display: "flex", marginTop: "36px", alignItems: "center", overflowX:"scroll", width:"100%" }}
          >
             {!userSalesRoles.isSpaUser && !userSalesRoles.isApprover &&
            <>
              <ReleaseActionsDEV
              selectedReleaseIds = {selectedReleaseIds}
                workflowId={workflowId}
                devWorkflow={devWorkflow}
                selectedVersion={selectedVersion}
                releaseRows={releaseRows}
                subscriptionKey={subscriptionKey}
              />
              <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
            </>
            }
            <ReleaseActionsUAT
            selectedReleaseIds = {selectedReleaseIds}
              workflowId={workflowId}
              workflowName = {workflowName}
              devWorkflow={devWorkflow}
              uatWorkflow={uatWorkflow}
              selectedVersion={selectedVersion}
              subscriptionKey={subscriptionKey}
              releaseId={releaseId}
              releaseRows={releaseRows}
            />
            <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
            <ReleaseActionsPROD
            selectedReleaseIds = {selectedReleaseIds}
              workflowId={workflowId}
              workflowName = {workflowName}
              prodWorkflow={prodWorkflow}
              uatWorkflow={uatWorkflow}
              selectedVersion={selectedVersion}
              subscriptionKey={subscriptionKey}
              releaseId={releaseId}
              releaseRows={releaseRows}
            />
            <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
            <RollBack
            selectedReleaseIds = {selectedReleaseIds}
              workflowId={workflowId}
              latestVersion={devReleases[0]?.version}
              selectedVersion={selectedVersion}
            />
            <Button
              onClick={() => dispatch(setShowSchedulePanel(true))}
              appearance="command"
              style={{ fontSize: "14px" }}
            >
              <Icon slot="start" name="timerart64" />
              Schedule
            </Button>
            <Button
              disabled={selectedReleaseIds.length !== 2}
              onClick={() => dispatch(getMultipleWorkflowsByIdandVersion({workflowId: workflowId, versions: selectedWorkflowVersions}))}
              appearance="command"
              style={{ fontSize: "14px" }}
            >
              <Icon slot="start" name="compare" />
              Compare
            </Button>
          </div>
          <hr />
        </div>
        {
          loadingState ? 
          <ReleaseCallsStatus/> 
          :
        <DataGrid
          style={{
            height: "calc(100vh - 270px)",
            marginLeft: "8px",
            position: "relative",
            overflowY: "hidden",
          }}
          className="releaseDataGrid"
          fixedHeading={true}
          rows={releaseRows}
          columns={(userSalesRoles.isSpaUser || userSalesRoles.isApprover) ? columns.filter(c => !c.content.startsWith('DEV')) : columns}
          select="multiple"
          onHeRowSelectChange={(e) => {
            handleRowChange(e.detail)
          }}
          onHeSelectAllChange={(e) => {
            handleRowChange(e.detail)
          }}
        ></DataGrid>
}
      </div>
      <ScheduleWorkflow workflowId={workflowId} showEnv={true} />
      <DiffPanel />
    </>
  );
};

const ReleaseManagement= withErrorBoundary("release management main", ReleaseManagementC);
export default ReleaseManagement;
