import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DatasetListFilterState {
  datasetFilters: any;
}

const initialState: DatasetListFilterState = {
  datasetFilters: {}
}

export const DatasetListFilterSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    setDatasetCheckedFilter: (state, action: PayloadAction<any>) => {
      state.datasetFilters = action.payload;
    }
  },
  extraReducers: {}
});

export const {
  setDatasetCheckedFilter
} = DatasetListFilterSlice.actions;

export const DatasetListFilterReducer = DatasetListFilterSlice.reducer;