import { useDispatch, useSelector } from "react-redux";
import { Select, Option, Button, Tooltip } from "../../../common/HarmonyEnablers";
import { SqlAadConnection } from "../../../model/connections/SqlAadConnection.model";
import { Icon, TextField } from "../ConnectionScope";
import "../connections.css"
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { postConnections } from "../../../services/PostConnections.api";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { useEffect, useState } from "react";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Connection } from "../../../model/connections/Connection.model";


const SqlAadConnectionPanelC: React.FC<{connectionData: SqlAadConnection, editMode: boolean, appScopes: string[] | undefined, connectionType: ConnectionType, subscriptions: {key: string, name: string}[], authType:string}> = ({connectionData, editMode, appScopes, connectionType, subscriptions, authType}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState<SqlAadConnection>(connectionData);
    const selectedEnv =  useSelector((state: RootState) => state.env.selectedEnvItem);

    const adbScopeDev = "https://adb-5404863101054673.13.azuredatabricks.net/?o=5404863101054673#secrets/createScope";
    const adbScopUat = "https://adb-2987319840690271.11.azuredatabricks.net/?o=2987319840690271#secrets/createScope";
    const adbScopProd = "https://adb-5011763184037517.17.azuredatabricks.net/?o=5011763184037517#secrets/createScope";


    useEffect(() => {
        setData(connectionData)
    }, [connectionData])
    
    if (!connectionData && !editMode) {
        return <></>
    }

    const canEnableSave = () => {
        if (editMode) {
            if (authType == "spn" && (!data.connectionName?.trim() || !data.appScope || !data.serverName?.trim() || !data.database?.trim() || !data.servicePrincipalId?.trim() || !data.servicePrincipalPwd?.trim())){
                return false
            }
            if (authType == "msi" && (!data.connectionName?.trim() || !data.serverName?.trim() || !data.database?.trim())){
                return false
            }
            if (connectionData && JSON.stringify(connectionData) === JSON.stringify(data)) {
                return false
            }
            return true
        }
        return false
    }

    const onSaveClick = () => {

        var finalData = {} as SqlAadConnection extends Connection ? SqlAadConnection : Connection;
        if(authType == "spn")
            finalData = {...data, useMSI: 0};
        else
        finalData = {...data, useMSI: 1, servicePrincipalId: '', servicePrincipalPwd: ''};

        dispatch(postConnections({ newConnection: finalData, type: connectionType }))
    }

    let subscriptionName = ''
    if (!editMode) {
        subscriptionName = subscriptions.filter(item => item.key === data.subscriptionKey)[0]?.name || ''
    }

    return ( 
        <>       
                <div className='input-row'>
                    <div>Name</div>
                    <div>
                        <TextField id = "sqlaadname" required disabled={!editMode} placeholder="Name" value={data.connectionName || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, connectionName: e.target.value})
                            
                        }}/>
                    </div>
                </div>
                <div className='input-row'>
                    <div>Tenant</div>
                    <Select
                            value= {data.subscriptionKey || ''}
                            slot="action"
                            fixed-placement
                            disabled={!editMode}
                            placeholder={subscriptionName || 'Select'}
                            id = "sqlaadtenant"
                            >
                            {
                                subscriptions?.map((item) => (
                                    <Option
                                        key={item.key}
                                        role="menuitemcheckbox"
                                        value={item.key}
                                        onHeSelected={(e: any) => {
                                            setData({...data, subscriptionKey: e.target.value})
                                            
                                        }}
                                    >
                                        {item.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
                <div className='input-row'>
                    <div>App Scope
                    <Icon id="info76" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info76" fixedPlacement={true} placement='bottom'> Select the app scope. If creating, then create the app scope in all the three environments. </Tooltip>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Select
                                value= { authType == "msi" ? data.appScope || 'nebula' : data.appScope || '' }
                                slot="action"
                                fixed-placement
                                disabled={!editMode}
                                placeholder='Select'
                                style={{width: '500px'}}
                                id = "sqlaadscope"
                                >
                                {
                                    appScopes?.map(item => (
                                        <Option
                                            key={item}
                                            role="menuitemcheckbox"
                                            onHeSelected={(e: any) => {
                                                setData({...data, appScope: e.target.textContent})
                                                
                                            }}
                                        >
                                            {item}
                                        </Option>
                                    ))
                                }
                            </Select>
                            {/* TODO add this to aka.ms */}
                            {
                                selectedEnv == "dev" ? <a id="sqlaaddevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a> 
                                : selectedEnv == "uat" ? <a id="sqlaaduatscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopUat}>Add New Scope</a> 
                                : selectedEnv == "prod" ? <a id="sqlaadprodscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopProd}>Add New Scope</a> 
                                : <a id="sqlaaddevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a>
                            }
                            <Icon id="infoaddscopesqlaad" name="info" style={{ marginLeft: "4px", cursor: "pointer", color: "#0078D4" }} tabIndex={0}/>
                            <Tooltip anchor="infoaddscopesqlaad" fixedPlacement={true} placement='bottom'>
                            The new scope needs to be created in all the three environments
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className='input-row'>
                    <div>Server Name
                    <Icon id="info77" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info77" fixedPlacement={true} placement='bottom'> Select the server name </Tooltip>
                    </div>
                    <div><TextField id = "sqlaadserver" required disabled={!editMode} placeholder="Server Name" value={data.serverName || ''} onHeInput={(e: any) => {
                            setData({...data, serverName: e.target.value})
                            
                        }}/>
                    </div>
                </div>
                <div className='input-row'>
                    <div>Database
                    <Icon id="info78" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info78" fixedPlacement={true} placement='bottom'> Select the Database </Tooltip>
                    </div>
                    <div><TextField id = "sqlaaddatabase" required disabled={!editMode} placeholder="Database" value={data.database || ''} onHeInput={(e: any) => {
                        setData({...data, database: e.target.value})
                            
                    }}/>
                    </div>
                </div>
                {authType == "spn" &&
                <div className='input-row'>
                    <div>Service Principal Id
                    <Icon id="info79" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info79" fixedPlacement={true} placement='bottom'> Select the service principal ID </Tooltip>
                    </div>
                    <div><TextField id = "sqlaadserviceprincipleid" required disabled={!editMode} placeholder="Service Principal Id" value={data.servicePrincipalId || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, servicePrincipalId: e.target.value})
                            
                        }}/>
                    </div>
                </div>
            }
            {authType == "spn" &&
                <div className='input-row'>
                    <div>Service Principal Password
                    <Icon id="info80" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info80" fixedPlacement={true} placement='bottom'> Select the service principal password </Tooltip>
                    </div>
                    <div><TextField id = "sqlaadserviceprinciplepassword" required disabled={!editMode} placeholder="Service Principal Password" value={data.servicePrincipalPwd || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, servicePrincipalPwd: e.target.value})
                            
                        }}/>
                    </div>
                </div>
            }
            <div slot="footer" className="panel-footer">
                <Button id = "sqlaadsave" onClick={onSaveClick} disabled={!canEnableSave()} appearance={editMode ? 'primary': undefined}>
                    Save
                </Button>
            </div>
        </>     
    )
}
export const SqlAadConnectionPanel = withErrorBoundary("SqlAadC onnection Panel", SqlAadConnectionPanelC);