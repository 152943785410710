import './common/styles/global-styles.css';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { reactPlugin } from "./config/appInsights";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import withAppInsights from './config/appInsights';
import { ErrorBoundary, withErrorBoundary } from './shared-components/ErrorBoundary';
import { rootStore } from './root-redux/RootState';
import { Provider } from 'react-redux';
import { msalConfig } from './auth/authConfig';
import { SigningIn } from './core-components/signing-in/SigningIn';
import { AuthorizedApp } from './core-components/authorized-app/AuthorizedApp';
import { Suspense } from 'react';

const Main: React.FC = () => {

  useMsalAuthentication(InteractionType.Redirect);

  return (   
    
      // <ErrorBoundary componentName={"Main"} fallbackUi={<div>Could not load content.</div>}>
      <>
        <AuthenticatedTemplate>
          <AppInsightsContext.Provider value={reactPlugin}>
           
          <Suspense>
            <AuthorizedApp />
          </Suspense>           
          </AppInsightsContext.Provider>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <SigningIn />
        </UnauthenticatedTemplate>

      </>  
    
  );
};

const AppC: React.FC = () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  return (
    <Provider store={rootStore}>
      <MsalProvider instance={msalInstance}>
      <Suspense fallback={<div>Loading...</div>}>
      <Main />
      </Suspense>
      
      </MsalProvider>
    </Provider>
  )  
}

export const App = withErrorBoundary("App main", withAppInsights(AppC))
