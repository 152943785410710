import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { WorkflowSummary } from "../../model/workflows/Workflow.model";

export interface AddedMergeConditionsDataGridRow extends Row {
  cells: {
      field1: string,
      field2: string,
      field3: string,
      field4: string
      field34: string
  }
}

export const addedMergeConditionsColumnsForInsightsRecs = [{
  field: 'field1',
  content: 'S.No',
},
{
  field: 'field2',
  content: 'Description',
},
{
  field: 'field3',
  content: 'Criteria To Be Satisfied By Input Recommendations',
},
{
  field: 'field34',
  content: 'Criteria To Be Satified By Existing Output Recommendations',
},
{
  field: 'field4',
  content: '',
},
]

export const addedMergeConditionsColumnsForRecs = [{
  field: 'field1',
  content: 'S.No',
},
{
  field: 'field2',
  content: 'Description',
},
{
  field: 'field3',
  content: 'Criteria To Be Satisfied By Input Recommendations',
},
{
  field: 'field4',
  content: '',
},
]

export const getFieldKey = (fieldType: any, open: boolean) => {
  var fieldString = open ? "<strKey>" : "</strKey>"
  if(fieldType) {
  switch(fieldType) {
    case "Int32": fieldString = open ? "<int32Key>" : "</int32Key>"; break;
    case "Int64": fieldString = open ? "<int64Key>" : "</int64Key>"; break;
    case "Decimal": fieldString = open ? "<decimalKey>" : "</decimalKey>"; break;
    case "Double": fieldString = open ? "<doubleKey>" : "</doubleKey>"; break;
    case "Boolean": fieldString = open ? "<booleanKey>" : "</booleanKey>"; break;
    case "Date": fieldString = open ? "<dateKey>" : "</dateKey>"; break;
    case "Guid": fieldString = open ? "<strKey>" : "</strKey>"; break;
  }
  }
  return fieldString;
}

export const getFieldValue = (fieldType: any, open: boolean) => {
  var fieldString = open ? "<strVal>" : "</strVal>"
  if(fieldType) {
  switch(fieldType) {
    case "Int32": fieldString = open ? "<int32Val>" : "</int32Val>"; break;
    case "Int64": fieldString = open ? "<int64Val>" : "</int64Val>"; break;
    case "Decimal": fieldString = open ? "<decimalVal>" : "</decimalVal>"; break;
    case "Double": fieldString = open ? "<doubleVal>" : "</doubleVal>"; break;
    case "Boolean": fieldString = open ? "<booleanVal>" : "</booleanVal>"; break;
    case "Date": fieldString = open ? "<dateVal>" : "</dateVal>"; break;
    case "Guid": fieldString = open ? "<strVal>" : "</strVal>"; break;
  }
  }
  return fieldString;
}

export const listOfOperators = ["Equals", "Not Equals", "In", "Not In"]
export const listOfConditions = ["And"]