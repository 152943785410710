import { useDispatch, useSelector } from "react-redux";
import { Toggle } from "../../common/HarmonyEnablers";
import { RootState } from "../../root-redux/RootState";
import { setIsSuperAdmin } from "../../shared-components/super-admin/IsSuperAdmin.actions.redux";

export const SuperAdminToggle:React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    const superAdminstatus = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin);

  return (
    <>
    <span style = {{padding:"2px", fontSize: '14px', marginRight: '5px'}}>Admin</span>
    <Toggle id = "superadmintoggle" onHeChange = {()=>{
        dispatch(setIsSuperAdmin(!superAdminstatus));
    }}>
    <span id = "checkedsuperadmin" slot="checked-message" ></span>
    <span id = "uncheckedsuperadmin" slot="unchecked-message"></span>
  </Toggle>
  <span style={{fontSize: '14px'}}>User</span>
    </>
  );
};

