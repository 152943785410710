import { useEffect, useState } from "react";
import { MessageBar } from "../../common/HarmonyEnablers"
import { RequestStatus } from "../../model/ServiceRequestStatus.model";

export const RequestMessageBar: React.FC<{status: RequestStatus, successMessage?: string, errorMessage?: string, callback?: () => unknown}> = ({status, successMessage, errorMessage, callback}) => {
    const [messageBar, setMessageBar] = useState<{show: boolean, appearance: 'info' | 'notice' | 'success' | 'warning' | 'error'}>({ show: false, appearance: "success" });
    
    useEffect(() => {
        if (status === RequestStatus.succeeded) {
            setMessageBar({show: true, appearance: "success"})
        } else if (status === RequestStatus.failed) {
            setMessageBar({show: true, appearance: "error"})
        } else {
            setMessageBar({show: false, appearance: "success"})
        }        
    }, [status])

    const onHeAfterHide = () => {
        setMessageBar({...messageBar, show: false})
        if (callback) {
            callback()
        }
    }
    
    return (
        <MessageBar
            appearance={messageBar.appearance}
            open={messageBar.show}
            onHeAfterHide={onHeAfterHide}
            noCloseButton
            duration={3000}
      >
        {messageBar.appearance === 'success'? (successMessage || "Your changes have been saved!") : (errorMessage || "Sorry we were not able to save your changes.")}
      </MessageBar>
    )
}