import { useEffect, useState } from "react"
import { Icon, TextField } from "../../common/HarmonyEnablers"
import './textfield-table.css'

export interface IUpdatedCellValue {
    header: string
    cellValue: string
}

interface ITextFieldTableProps {
    headers: {headerKey: string, headerText: string}[]
    rows: {[headerKey: string]: string}[]
    updateCellValue: (rowIndex: number, values: IUpdatedCellValue[]) => void
}

export const TextFieldTable: React.FC<ITextFieldTableProps> = (props) => {
    const [rows, setRows] = useState(props.rows? props.rows : [])

    useEffect(() => {setRows(props.rows? props.rows : [])}, [props.rows])

    if (!props.headers || props.headers.length === 0) {
        return <></>
    }

    const deleteRow = (rowIndex: number) => {
        const newRows = [...rows]
        const deletedRow = newRows[rowIndex]
        newRows.splice(rowIndex, 1)
        setRows(newRows)
        props.updateCellValue(rowIndex, props.headers.map((header) => { return {header: header.headerKey, cellValue: ''}} ))
    }

    const addRow = () => {
        const emptyRow: {[headerKey: string]: string} = {}        
        props.headers.forEach((header) => emptyRow[header.headerKey] = '')
        const newRows = [...rows, emptyRow]
        setRows(newRows)  
    }

    const updateRow = (rowIndex: number, updatedHeaderKey: string, updatedValue: string) => {
        const newValues: IUpdatedCellValue[] = []
        Object.keys(rows[rowIndex]).forEach((headerKey) => {
            if (headerKey === updatedHeaderKey) {
                newValues.push({header: headerKey, cellValue: updatedValue})
            } else {
                newValues.push({header: headerKey, cellValue: rows[rowIndex][headerKey]})
            }
        })
        props.updateCellValue(rowIndex, newValues)
    }

    return (
        <table className="textfield-table">
            <tr>
                {props.headers?.map((header) => <th>{header.headerText}</th>)}
                <th style={{textAlign: 'end'}} onClick={addRow}><Icon name="add" style={{color: "#0078D4", cursor: 'pointer'}}/></th>
            </tr>
            {rows?.map((row, rowIndex) => {
                return (
                    <tr>
                        {props.headers?.map((header) => {
                            return (
                                <td>
                                    <TextField value={row[header.headerKey]} onHeInput={(e: any) => updateRow(rowIndex, header.headerKey, e.target.value)} />
                                </td>
                            )
                        })}
                        <td style={{textAlign: 'end', paddingRight: '0', paddingTop: '10px', verticalAlign: 'middle'}} onClick={() => deleteRow(rowIndex)}><Icon name="delete" style={{color: "#0078D4", cursor: 'pointer'}}/></td>
                    </tr>
                )
            })}
            
        </table>  
    )
}