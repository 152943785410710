import { Spinner } from "../../shared-components/loader/Spinner";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";


const ReleaseCallsStatusC = (props: any) => {

  return (
    <>
      <div style={{display:"grid", placeItems:"center"}}>
      <Spinner type="large"/>
      </div>
    </>
  );
};
export const ReleaseCallsStatus = withErrorBoundary(
  "releases status",
  ReleaseCallsStatusC
);
