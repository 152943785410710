import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import { Campaign } from "../../../model/SPAModels/campaigns/campaign.Model"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { fetchCampaignFilterCount, postCampaignFilterList } from "../../../services/SPAAdmin/CampaignsList.api";

export interface FilterCampaignListState {
    filterCampaignList: any[],
    modelId: string|undefined,
    maxPageNum: number,
    totalRowCount: number,
    status: RequestStatus,
    countStatus: RequestStatus,
    currPage: number,
    error: string | undefined
}

const initialState: FilterCampaignListState = {
    filterCampaignList: [],
    modelId: undefined,
    maxPageNum: 1,
    totalRowCount: 0,
    status: RequestStatus.idle,
    countStatus: RequestStatus.idle,
    currPage: 1,
    error: ''
}

export const FilterCampaignListSlice = createSlice({
    name: 'filteredQueryModelData',
    initialState,
    reducers: {
      changePageNumber: (state, action: PayloadAction<number>) => {
        state.currPage = action.payload
      },
      setMaxPageNumber: (state, action: PayloadAction<number>) => {
        state.maxPageNum = action.payload
      },
      setModelId: (state, action: PayloadAction<string>) => {
        state.modelId = action.payload
      },
      resetResultList: (state, _: PayloadAction<void>) => {
        state.filterCampaignList = [];
        state.totalRowCount = 0;
        state.currPage = 1;
        state.maxPageNum = 1;
      },
    },
    extraReducers(builder) {
        builder
            .addCase(postCampaignFilterList.pending, (state, action) => {
              state.status = RequestStatus.loading
            })
            .addCase(postCampaignFilterList.fulfilled, (state, action) => {
              state.status = RequestStatus.succeeded
              state.filterCampaignList = state.filterCampaignList.concat(action.payload);
              state.error = undefined
            })
            .addCase(postCampaignFilterList.rejected, (state, action: any) => {
              state.status = RequestStatus.failed
              state.error = "view result: " + (action.payload["Message"] || action.error.message);
            })
            .addCase(fetchCampaignFilterCount.pending, (state, action) => {
              state.countStatus = RequestStatus.loading
              state.totalRowCount = 0;
            })
            .addCase(fetchCampaignFilterCount.fulfilled, (state, action) => {
              state.countStatus = RequestStatus.succeeded
              state.totalRowCount = (action.payload || []).length
              state.error = undefined
            })
            .addCase(fetchCampaignFilterCount.rejected, (state, action) => {
              state.countStatus = RequestStatus.failed
              state.totalRowCount = 0;
              state.error = action.error.message
            })
    }
});

export const {
  changePageNumber,
  setMaxPageNumber,
  setModelId,
  resetResultList
} = FilterCampaignListSlice.actions;

export const FilterCampaignReducer = FilterCampaignListSlice.reducer