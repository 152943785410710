import { createAsyncThunk } from "@reduxjs/toolkit";
import { ModelMapping } from "../model/configModels/ModelMapping.model";
import { RootState } from "../root-redux/RootState";
import { postRequest } from "./utils/PostRequest";
import { postRequestReleaseManagement } from "./utils/PostRequestReleaseManagement";

export const updateModelMapping = createAsyncThunk<boolean, { payload: ModelMapping }, { state: RootState }>('modelMapping/updateModelMapping', async ({ payload }, { getState }) => {

    const selectedEnv = getState().env.selectedEnvItem
  
    const response = await postRequest<boolean, ModelMapping>(`${selectedEnv}/modelmapping/update`, payload)
    return response.data;
  });

  
  export const updateModelMapping_uat = createAsyncThunk<boolean, { payload: ModelMapping }, { state: RootState }>('uat/modelMapping/updateModelMapping', async ({ payload }, { getState }) => {
  
    const response = await postRequestReleaseManagement<boolean, ModelMapping>(`uat/modelmapping/update`, payload)
    return response.data;

  });

  export const updateModelMapping_prod = createAsyncThunk<boolean, { payload: ModelMapping }, { state: RootState }>('prod/modelMapping/updateModelMapping', async ({ payload }, { getState }) => {
  
    const response = await postRequestReleaseManagement<boolean, ModelMapping>(`prod/modelmapping/update`, payload)
    return response.data;

  });