import { Skeleton } from "../../common/HarmonyEnablers"

export const ShimmerLines = () => {
    return (
        <>
            <Skeleton
                style={{ borderRadius: '4px', marginTop: '10px', height: '10px', width: '50%' }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{ borderRadius: '4px', marginTop: '10px', height: '10px', width: '70%' }}
                shape="rect"
                shimmer
            />
            <Skeleton
                style={{ borderRadius: '4px', marginTop: '10px', height: '10px', width: '90%' }}
                shape="rect"
                shimmer
            />
        </>
    )
}