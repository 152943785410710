import { useEffect, useState } from "react";
import { DataGrid } from "../../workflows/WorkflowsScope";
import { useDispatch, useSelector } from "react-redux";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getAppPersonas_uat, getAppPersonas_prod } from "../../../services/GetAppPersonaMapping.api";
import { getConnections_uat, getConnections_prod } from "../../../services/GetConnections.api";
import { getUatReleasesForConfiguration, getProdReleasesForConfiguration } from "../../../services/GetReleases.api";
import { Button, Icon } from "../../spa-admin/SpaAdminScope";
import ScheduleWorkflow from "../../workflows/schedule-workflow/ScheduleWorkflow";
import { ReleaseCallsStatus } from "../ReleaseCallsStatus";
import { ReleaseDataGridRow, columns } from "../Release";
import { setSelectedWorflowVersions } from "../ReleaseData.redux";
import { Dialog } from "../../../common/HarmonyEnablers";
import { getWorkflowByIdandVersion_prod, getWorkflowByIdandVersion_uat } from "../../../services/GetWorkflows.api";
import { ReleaseActionsPROD } from "../release-actions-comand-bar/ReleaseActionsPROD";
import { NonPropagateDatasetReleaseActionsUAT } from "../release-actions-comand-bar/NonPropagateDatasetReleaseActionsUAT";
import { InformationText } from "../../../shared-components/info-text/InformationText";
import informationConfig from "../../../shared-content/inputDescription";


export const DatasetReleasePopup : React.FC<any> = (props: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const workflowId = props.releaseObj.workflowId;
    const workflowName = props.releaseObj.workflowName;
    const workflowType = props.releaseObj.workflowType;
    const subscriptionKey = props.releaseObj.subscriptionKey;
    const [releaseRows, setReleaseRows] = useState([] as ReleaseDataGridRow[]);
    const [selectedVersion, setSelectedVersion] = useState('');
    const [releaseId, setReleaseId] = useState('');
    const [selectedReleaseIds, setSelectedReleaseIds] = useState([] as string[]);
    const getConfigStatus = useSelector((state: RootState) => state.releaseData.getConfigStatus);
    const getReleasesStatus = useSelector((state: RootState) => state.releases.getReleasesStatus);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const [loadingState, setLoadingState] = useState(true);   
    const [isPublished, setIsPublished] = useState(false);   
    const selectedEnv = useSelector((state: RootState) =>state.env.selectedEnvItem );
    const {setOpenDatasetRelease} = props;

    useEffect(() => {
      
      if (
        getConfigStatus.getWorkflowStatus.uat === RequestStatus.loading || getConfigStatus.getWorkflowStatus.prod === RequestStatus.loading || 
        getConfigStatus.getConnectionsStatus.uat === RequestStatus.loading || getConfigStatus.getConnectionsStatus.prod === RequestStatus.loading || 
        getConfigStatus.getAppPersonaStatus.uat === RequestStatus.loading ||  getConfigStatus.getAppPersonaStatus.prod === RequestStatus.loading || 
        getConfigStatus.getModelMappingStatus.uat === RequestStatus.loading ||
        getConfigStatus.getMergeRulesStatus.uat === RequestStatus.loading ||
        getReleasesStatus.uat === RequestStatus.loading || getReleasesStatus.prod === RequestStatus.loading ||
        getConfigStatus.getApiConfigStatus.uat === RequestStatus.loading ||
        getConfigStatus.getCatalogueConfigStatus.uat === RequestStatus.loading
        ) 
          setLoadingState(true);
      else
          setLoadingState(false);
      
    }, [getConfigStatus, getReleasesStatus]);

    useEffect(() => {
        dispatch(getUatReleasesForConfiguration({configurationId:workflowId, configurationType: workflowType}));
        dispatch(getProdReleasesForConfiguration({configurationId:workflowId, configurationType: workflowType}));

        dispatch(getAppPersonas_uat());
        dispatch(getAppPersonas_prod());
    }, [dispatch]);

    let releaseItems:{
      version: string, releaseId:string, workflowId:string, id_dev:string, id_uat:string, id_prod:string, time_dev:string, time_uat:string, time_prod:string, dev:boolean, uat:boolean, prod:boolean,
      status_dev: string, status_uat: string, status_prod:string, scheduled_dev:boolean, scheduled_uat:boolean, scheduled_prod:boolean
      }[] = [];

    const uatReleases = useSelector((state: RootState) => state.releases.releasesUat);
    const prodReleases = useSelector((state: RootState) => state.releases.releasesProd);

    useEffect(() => {
          dispatch(getWorkflowByIdandVersion_uat({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : uatReleases.length != 0 ? uatReleases[0].version : ''}));
          dispatch(getWorkflowByIdandVersion_prod({workflowId:workflowId, version: selectedVersion.length != 0 ? selectedVersion : prodReleases.length != 0 ? prodReleases[0].version : ''}));  
      }, [dispatch, selectedVersion, workflowId]);

    useEffect(() => {
          const subscriptionKeys = [];
          subscriptionKeys.push(subscriptionKey);
          dispatch(getConnections_uat({subscriptionKeys}));
          dispatch(getConnections_prod({subscriptionKeys}));
  }, [dispatch, subscriptionKey]);


    let uatWorkflow = useSelector((state: RootState) => state.releaseData.uatWorkflow);
    let prodWorkflow = useSelector((state: RootState) => state.releaseData.prodWorkflow);
    
    
        useEffect(() => {
          releaseItems = [];
              uatReleases?.map((release) => {

                let prod_ind = prodReleases.length == 0 ? -1 : prodReleases.findIndex(item  => item.version == release.version);
  
                if(releaseItems.findIndex(item => item.version == release.version) == -1)
                {
                  
                  releaseItems.push({
                    version: release.version,
                    releaseId: release.releaseId,
                    workflowId: release.workflowId,
                    id_dev: "",
                    id_uat: release?.id,
                    id_prod: prod_ind > -1 ? prodReleases[prod_ind]?.id : "",
                    time_dev: "",
                    time_uat: release.timestamp,
                    time_prod: prod_ind > -1 ? prodReleases[prod_ind].timestamp : "",
                    dev: true,
                    uat: true,
                    prod: prod_ind > -1 ? true : false,
                    status_dev: "",
                    status_uat: release.status,
                    status_prod: prod_ind > -1 ? prodReleases[prod_ind].status : "",
                    scheduled_dev: false,
                    scheduled_uat: release.scheduleCreated,
                    scheduled_prod: prod_ind > -1 ? prodReleases[prod_ind].scheduleCreated : false
                    })
                  }});
            }, [uatReleases, prodReleases]);    

        useEffect(() => {

          const transformedReleaseRows : ReleaseDataGridRow[] = [];

          releaseItems.map((item:any) => {

            transformedReleaseRows.push({
              id: item.version,
              cells:{
                field1: item.version,
                field2: item.status_dev == "Published" ? "Deployed" : "Draft",
                field3: item.scheduled_dev ? "Scheduled" : "",
                field4: item.uat == true ?  (item.status_uat == "Submitted" ? "Awaiting Approval" : item.status_uat == "Published" ? "Deployed" : "Draft") : "",
                field5: item.scheduled_uat ? "Scheduled" : "",
                field6: item.prod == true ? (item.status_prod == "Submitted" ? "Awaiting Approval" : item.status_prod == "Published" ? "Deployed"  : "Draft") : "",
                field7: item.scheduled_prod ? "Scheduled" : "",
              }
            });
            setReleaseRows(transformedReleaseRows);            
          });

        }, [releaseItems]);

        useEffect(() => {
        const uatStatus = releaseRows.find((x:any) => x.cells.field1 == selectedVersion)?.cells.field4;
        const isUatDeployed = uatStatus == "Deployed" ? true : false;
        setIsPublished(isUatDeployed ? true : false);

        },[releaseRows,selectedVersion]);
       
        const handleRowChange = (e:any) => {
          let selectedRows : string[] = [];
          selectedRows = releaseRows?.filter(x => x.selected)?.map(x => {
              return x.id.toString();
          });
          setSelectedReleaseIds(selectedRows);
          if(selectedRows.length === 1)
          {
            setSelectedVersion(releaseRows?.filter(x => x.selected)[0].cells.field1);
            const releaseId = uatReleases.find(x => x?.version == e.id)?.releaseId;
            setReleaseId(releaseId!);
          }
          else{
            setSelectedVersion('');
            setReleaseId('');  
          }
          const selectedVersions = releaseRows?.filter(x => x.selected)?.map(x => {
            return x.cells.field1.toString();
          })
          dispatch(setSelectedWorflowVersions(selectedVersions));
      };

      const handleRequestClose = (event: any) => {
        if (event.detail.source === 'overlay') {
          event.preventDefault();
        } else {
          setOpenDatasetRelease(false);
        }
      };

  return (
    <>
      <Dialog open className={'datasetreleasepopup'} onHeRequestClose={handleRequestClose}
        onHeAfterHide={
          ({ target, currentTarget }) => (target === currentTarget ? setOpenDatasetRelease(false) : null)
        }
      >
      <div>
        <h2 style={{ textAlign: "center", margin: "12px 0 0" }}>
          Deploy | {workflowName}
        </h2>
        <InformationText text = {informationConfig.onboardingRelease} type = "Heading"/>
        <div style = {{right:"28px", top:"28px", zIndex:"7", position:"absolute"}} aria-label = "releaseManagementClose">
          <Button appearance="link" 
            onClick={() => {
              setOpenDatasetRelease(false);
            }}
            aria-label="releaseManagementCancel"
            name = "releaseManagementCancel">
        <Icon name="calculatormultiply" aria-label="releaseManagementCancelIcon"/>
        </Button>
        </div>
      </div>
      
      <div>
        <div style={{ marginLeft: "8px" }}>
          <div
            style={{ display: "flex", marginTop: "36px", alignItems: "center" }}
          >
              <>
                {
                  <NonPropagateDatasetReleaseActionsUAT
                    selectedReleaseIds = {selectedReleaseIds}
                    workflowId={workflowId}
                    workflowName = {workflowName}
                    uatWorkflow={uatWorkflow}
                    selectedVersion={selectedVersion}
                    subscriptionKey={subscriptionKey}
                    releaseId={releaseId}
                    releaseRows={releaseRows}
                  />
                }
              </>
            <span style={{ fontSize: "28px", fontWeight: "300" }}>|</span>
            <>
              <ReleaseActionsPROD
                selectedReleaseIds = {selectedReleaseIds}
                workflowId={workflowId}
                workflowName = {workflowName}
                prodWorkflow={prodWorkflow}
                uatWorkflow={uatWorkflow}
                selectedVersion={selectedVersion}
                subscriptionKey={subscriptionKey}
                releaseId={releaseId}
                releaseRows={releaseRows}
              />
            </> 
          </div>
          <hr />
        </div>
        {
          loadingState ? 
          <ReleaseCallsStatus/> 
          :
        <DataGrid
          style={{
            marginLeft: "8px",
            position: "relative",
            overflowY: "hidden",
            marginBottom: "50px",
          }}
          fixedHeading={true}
          rows={releaseRows}
          columns={ (userSalesRoles.isSpaUser || userSalesRoles.isApprover) ? columns.filter(c => !c.content.startsWith('DEV')) : columns}
          select="multiple"
          onHeRowSelectChange={(e) => {
            handleRowChange(e.detail)
          }}
          onHeSelectAllChange={(e) => {
            handleRowChange(e.detail)
          }}
        >
        </DataGrid>
}
      </div>
      <ScheduleWorkflow workflowId={workflowId} showEnv={true} />
      </Dialog>   
    </>
  );
};