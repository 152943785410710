import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { RequestMessageBar } from "../../shared-components/message-bar/RequestMessageBar";
import { resetPostConnectionStatus } from "./connections-redux/Connections.redux";

export const ConnectionMessageBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const status = useSelector((state: RootState) => state.connections.postStatus);
    
    return <RequestMessageBar status={status} callback={() => dispatch(resetPostConnectionStatus())}/>
}