import { createSlice } from '@reduxjs/toolkit'

interface IsSuperAdminState {
 isSuperAdmin: boolean
}

const initialState: IsSuperAdminState = {
    isSuperAdmin : false
}

export const issuperAdminSlice = createSlice({
  name: 'isSuperAdmin',
  initialState,
  reducers: {
    setIsSuperAdmin (state, action)  {
      state.isSuperAdmin = action.payload
    }
  }
});

export const isSuperAdminReducer = issuperAdminSlice.reducer;


