import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "./utils/GetRequest";
import { Cluster } from "../model/cluster/Cluster";
import { RootState } from "../root-redux/RootState";
import { AxiosResponse } from "axios";

export const getAllClusters = createAsyncThunk<{[env: string]: Cluster[]}, null, {state: RootState}>('workflows/getAllClusters', async (_) => {
    const envList = ['dev', 'uat', 'prod']
    const responses = await Promise.all(envList.map((env: string) => getRequest<Cluster[]>(`${env}/workflows/clusters`, env)))
    
    let result: {[env: string]: Cluster[]} = {}
    responses?.forEach((res: AxiosResponse<Cluster[]>, index: any) => {
      result[envList[index]] = res.data
    })

    return result
  })