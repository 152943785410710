
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const DataMergeC: React.FC<any> = (props: any) => {

  return (
    <>
 <div className="help-mainHeading">
          <span id="mergeTag" className="help-subheading">Merge</span>
          <div id = "onboarding_consume">
          <span className="help-content">The Merge configuration generates personalized recommendations from ingested insights/input recommendations. You have three options on the Merge tab: </span>
          </div>
          <ol>
          {onboarding.merge.map(mergedata =>
            <li key={mergedata} style={{marginBottom:"6px"}}><span className="help-content">{mergedata}</span></li>
          )}
          </ol>
          {/* <span className="help-content">Choose the merge option that best aligns with your specific requirements for generating and customizing recommendations from insights/input recommendations datasets in Nebula.</span> */}
          <HelpNote text = "Choose the merge option that best aligns with your specific requirements for generating and customizing recommendations from insights/input recommendations datasets in Nebula."/>
          </div>
          <hr/>
    </>
  );
};
export const DataMerge = withErrorBoundary("DataMerge content", DataMergeC);