import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { RootState } from "../../../root-redux/RootState";
import { Workflow, WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { MergeRuleSet } from "../../../model/merge-rules/MergeRuleSet.model";


export interface ModelDataGridRow extends Row {
  cells: {
    field1: string
    field2: string
    field3: string
    field4: string
    field5: string
    field6: string
  }
}

export const modelColumns: Column[] = [
  {
    field: 'field1',
    content: 'Name',
    sortable: true,
    display: {
      width: '15px'
    }
  },
  {
    field: 'field2',
    content: 'Type',
    sortable: true,
  },
  {
    field: 'field3',
    content: 'Model',
    sortable: true,
  },
  {
    field: 'field4',
    content: 'Status'
  },
  {
    field: 'field5',
    content: 'Tenant'
  },
  {
    field: 'field6',
    content: 'Description'
  }
];

const sortByModelsName = (set: ModelDataGridRow[]) => {
  var sortedSet = set.sort((a, b) => {
      if (a.cells['field1'] > b.cells['field1']) {
          return 1
      } else if (a.cells['field1'] < b.cells['field1']) {
          return -1
      } else {
          return 0
      }
  });
  return sortedSet
}

export const modelsItemSelector = (state: RootState): ModelDataGridRow[] => {
  var transformedToRowItems: ModelDataGridRow[] = []

  // need to create empty rows to show loader in those rows
  const loaderRows = 10 // need to get this value from config
  if (state.workflowsBySubKeys.status === RequestStatus.loading) {
    for (let i = 0; i < loaderRows; i++) {
      transformedToRowItems.push({
        id: i,
        cells: {
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
          field6: "",
        },
      });
    }
    return transformedToRowItems;
  }
  const workflowItems = state.workflowsBySubKeys.list

  var i = 1;
  workflowItems.forEach((item: WorkflowSummary) => {
    transformedToRowItems.push(
      {
        id: item.config.workflowId,
        cells: {
          field1: item.config.workflowName,
          field2: item.workFlowType,
          field3: item.modelName,
          field4: item.status,
          field5: item.tenantName,
          field6: item.description,
        },
      })
    i++;
  });
  transformedToRowItems = sortByModelsName(transformedToRowItems)

  return transformedToRowItems;
}

export const modelsItemSelectorByData = (workflowItems: WorkflowSummary[], mergeRuleSet: MergeRuleSet, selectedModels: string[]): ModelDataGridRow[] => {
  var transformedToRowItems: ModelDataGridRow[] = []

  // need to create empty rows to show loader in those rows
  const loaderRows = 10 // need to get this value from config

  //MRTODO - uncomment 
  var i = 1;
  if (mergeRuleSet.type === "primary") {
    workflowItems = workflowItems.filter(x => { return x.workFlowType === "Recommendation" })
  }
  workflowItems.forEach((item: WorkflowSummary) => {
    var modelName = item.modelName
    transformedToRowItems.push(
      {
        id: item.config.workflowId,
        selected: mergeRuleSet.mergeModel.includes(modelName) || selectedModels.includes(modelName),
        disabled: mergeRuleSet.mergeModel.includes(modelName) || !modelName,
        cells: {
          field1: item.config.workflowName,
          field2: item.workFlowType,
          field3: modelName,
          field4: item.status,
          field5: item.tenantName,
          field6: item.description,
        },
      })
    i++;
  });

  transformedToRowItems = sortByModelsName(transformedToRowItems)

  return transformedToRowItems;
}