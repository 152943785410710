import { useDispatch, useSelector } from "react-redux";
import { Select, Option, Button, Tooltip } from "../../../common/HarmonyEnablers";
import { BlobConnection } from "../../../model/connections/BlobConnection.model";
import { Icon, TextField } from "../ConnectionScope";
import "../connections.css"
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { postConnections } from "../../../services/PostConnections.api";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { useEffect, useState } from "react";
import { TeamsList } from "../../../model/role-based-access-control/RoleBasedAcessControl.model";
import { AppPersona } from "../../../model/app-persona/AppPersona.model";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Connection } from "../../../model/connections/Connection.model";


const BlobConnectionPanelC: React.FC<{connectionData: BlobConnection, editMode: boolean, appScopes: string[] | undefined, connectionType: ConnectionType, subscriptions: {key: string, name: string}[], authType: string}> = ({connectionData, editMode, appScopes, connectionType, subscriptions, authType}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState<BlobConnection>(connectionData);
    const selectedEnv =  useSelector((state: RootState) => state.env.selectedEnvItem);

    const adbScopeDev = "https://adb-5404863101054673.13.azuredatabricks.net/?o=5404863101054673#secrets/createScope";
    const adbScopUat = "https://adb-2987319840690271.11.azuredatabricks.net/?o=2987319840690271#secrets/createScope";
    const adbScopProd = "https://adb-5011763184037517.17.azuredatabricks.net/?o=5011763184037517#secrets/createScope";


    useEffect(() => {
        setData(connectionData)
    }, [connectionData])
    
    if (!connectionData && !editMode) {
        return <></>
    }

    const canEnableSave = () => {
        if(editMode)
            {
                if( authType == "spn" && (!data.connectionName?.trim() || !data.appScope || !data.blobStorageAccount?.trim() || !data.blobStorageAccountSecret?.trim() || !data.blobContainer?.trim() || !data.fileFormat?.trim() || !data.columnDelimiter?.trim()))
                    return false;
                if( authType == "msi" && (!data.connectionName?.trim() || !data.blobStorageAccount?.trim() || !data.blobContainer?.trim() || !data.fileFormat?.trim() || !data.columnDelimiter?.trim() ))
                    return false;
                if(connectionData && JSON.stringify(connectionData) === JSON.stringify(data))
                    return false;
                return true;
            }
        return false;
    }

    const onSaveClick = () => {
        var finalData = {} as BlobConnection extends Connection ? BlobConnection : Connection;
        if(authType == "spn")
            finalData = {...data, useMSI: 0};
        else
        finalData = {...data, useMSI: 1, blobStorageAccountSecret: ''};
        dispatch(postConnections({ newConnection: finalData, type: connectionType }))
    }

    let subscriptionName = ''
    if (!editMode) {
        subscriptionName = subscriptions.filter(item => item.key === data.subscriptionKey)[0]?.name || ''
    }

    return ( 
        <>       
                <div className='input-row' >
                    <div>Name<Icon className='required-icon' name='actioncenterasterisk' label='required' /> </div>
                        <TextField id = "blobname" required disabled={!editMode} placeholder="Name"  value={data.connectionName || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, connectionName: e.target.value})
                            
                        }}/>
                </div>
                <div className='input-row'>
                    <div>Tenant</div>
                    <Select
                            value= {data.subscriptionKey || ''}
                            slot="action"
                            fixed-placement
                            disabled={!editMode}
                            placeholder={subscriptionName || 'Select'}
                            id = "blobtenant"
                            >
                            {
                                subscriptions?.map((item) => (
                                    <Option
                                        key={item.key}
                                        role="menuitemcheckbox"
                                        value={item.key}
                                        onHeSelected={(e: any) => {
                                            setData({...data, subscriptionKey: e.target.value})
                                            
                                        }}
                                    >
                                        {item.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
                <div className='input-row'>
                    <div>App Scope
                    <Icon id="info60" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info60" fixedPlacement={true} placement='bottom'>
                            Select the app scope. If creating, then create the app scope in all the three environments.
                            </Tooltip>
                    </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Select
                                value= { authType == "msi" ? data.appScope || 'nebula' : data.appScope || '' }
                                slot="action"
                                fixed-placement
                                disabled={!editMode}
                                placeholder='Select'
                                style={{width: '500px'}}
                                id = "blobscope"
                                >
                                {
                                    appScopes?.map(item => (
                                        <Option
                                            key={item}
                                            role="menuitemcheckbox"
                                            onHeSelected={(e: any) => {
                                                setData({...data, appScope: e.target.textContent})
                                                
                                            }}
                                        >
                                            {item}
                                        </Option>
                                    ))
                                }
                            </Select>
                            {/* TODO add this to aka.ms */}
                            {
                                selectedEnv == "dev" ? <a id="blobdevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a> 
                                : selectedEnv == "uat" ? <a id="blobuatscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopUat}>Add New Scope</a> 
                                : selectedEnv == "prod" ? <a id="blobprodscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopProd}>Add New Scope</a> 
                                : <a id="blobdevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a>
                            }
                            <Icon id="infoaddscopeblob" name="info" style={{ marginLeft: "4px", cursor: "pointer", color: "#0078D4" }} tabIndex={0}/>
                            <Tooltip anchor="infoaddscopeblob" fixedPlacement={true} placement='bottom'>
                            The new scope needs to be created in all the three environments
                            </Tooltip>
                        </div>
                    </div>
                <div className='input-row'>
                    <div>Storage Account
                    <Icon id="info61" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info61" fixedPlacement={true} placement='bottom'>
                            Select Storage Account
                            </Tooltip>
                    </div>
                    <TextField id = "blobstorage" required disabled={!editMode} placeholder="Storage Account" value={data.blobStorageAccount || ''} onHeInput={(e: any) => {
                            setData({...data, blobStorageAccount: e.target.value})
                            
                        }}/>
                   
                </div>
                {authType == "spn" && 
                <div className='input-row'>
                    <div>Storage Account Secret
                    <Icon id="info62" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info62" fixedPlacement={true} placement='bottom'>
                            Select Storage Account Secret
                            </Tooltip>
                    </div>
                   <TextField id = "blobstoragesecret" required disabled={!editMode} placeholder="Storage Account Secret" value={data.blobStorageAccountSecret || ''} onHeInput={(e: any) => {
                        setData({...data, blobStorageAccountSecret: e.target.value})
                            
                    }}/>
                    
                </div>
            }
                <div className='input-row'>
                    <div>Container
                    <Icon id="info63" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info63" fixedPlacement={true} placement='bottom'>
                            Select Container
                            </Tooltip>
                    </div>
                    <TextField id = "blobcontainer" required disabled={!editMode} placeholder="Container" value={data.blobContainer || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, blobContainer: e.target.value})
                            
                        }}/>
                    </div>
               
                <div className='input-row'>
                    <div>File Format
                    <Icon id="info64" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info64" fixedPlacement={true} placement='bottom'>
                            Select the file format
                            </Tooltip>
                    </div>
                    <TextField id = "blobfileformat" disabled={!editMode} placeholder="File Format" value={data.fileFormat || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, fileFormat: e.target.value})
                            
                        }}/>
                   
                </div>
                <div className='input-row'>
                    <div>Column Delimiter
                    <Icon id="info65" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info65" fixedPlacement={true} placement='bottom'>
                            Select the column Delimiter
                            </Tooltip>
                    </div>
                   <TextField id = "blobcolumndel" disabled={!editMode} placeholder="Column Delimiter" value={data.columnDelimiter || ''} 
                        onHeInput={(e: any) => {
                            setData({...data, columnDelimiter: e.target.value})
                            
                        }}/>
                    
                </div>
            <div slot="footer" className="panel-footer">
                <Button id = "blobsave" onClick={onSaveClick} disabled={!canEnableSave()} appearance={editMode ? 'primary': undefined}>
                    Save
                </Button>
            </div>
        </>     
    )
}
export const BlobConnectionPanel = withErrorBoundary("Blob Connection panel", BlobConnectionPanelC);