import { Schedule } from "../../../model/workflows/Schedule.model";
import { Select, Option } from "../../../common/HarmonyEnablers";
import { updateSchedule } from "../workflow-by-id-redux/WorkflowById.actions.redux";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../root-redux/RootState";
export const ScheduleWorkflowMinute: React.FC<{
  scheduleObj: Schedule;
  dynamic?: boolean;
}> = React.memo(({ scheduleObj, dynamic }) => {
  const dispatch = useDispatch<AppDispatch>();
  if (dynamic) {
    return (
      <Select
        value={scheduleObj?.minute}
        slot="action"
        fixed-placement
        placeholder={"Select"}
        name="subkey"
      >
        {Array.from(
          { length: Number(scheduleObj.frequency.split(" ")[1]) },
          (_, i) => i
        ).map((item) => (
          <Option
            key={item}
            role="menuitemcheckbox"
            onHeSelected={(e: any) =>
              dispatch(
                updateSchedule({
                  ...scheduleObj,
                  minute: e.target.value,
                })
              )
            }
            value={item < 10 ? "0" + item : item}
          >
            {item < 10 ? "0" + item : item}
          </Option>
        ))}
      </Select>
    );
  }
  return (
    <Select
      value={scheduleObj?.minute}
      slot="action"
      fixed-placement
      placeholder={"Select"}
      name="subkey"
    >
      {Array.from({ length: 60 }, (_, i) => i).map((item) => (
        <Option
          key={item}
          role="menuitemcheckbox"
          onHeSelected={(e: any) =>
            dispatch(
              updateSchedule({
                ...scheduleObj,
                minute: e.target.value,
              })
            )
          }
          value={item < 10 ? "0" + item : item}
        >
          {item < 10 ? "0" + item : item}
        </Option>
      ))}
    </Select>
  );
});
