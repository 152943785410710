import { MessageBar } from "../../common/HarmonyEnablers";

export const SuccessNotification: React.FC<any> = (props:any) => {
    
  return (
    <>
      <div>
        <MessageBar
          appearance="success"
          open = {props.successNotif}
          duration={props.duration || 6000}
          onHeAfterHide={() => props.setsuccesnotif(false)}
        >
          {props.successText}
        </MessageBar>
        
      </div>
    </>
  );
};
