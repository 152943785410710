import { CommandBar, Button, Icon, Dropdown, Menu, MenuItem } from '../../workflows/WorkflowsScope';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../root-redux/RootState';
import { setWorkflowSummaryStatus } from '../../workflows/workflows-redux/Workflows.redux';
import { postWorkflow, postWorkflowReleaseManagement } from '../../../services/PostWorkflow.api';
import { addConnection, patchApiConfig, patchCatalogueConfig, patchMergeRule, patchModelMapping, patchReleaseWorkflow, setReleaseWorkflowStatus } from '../ReleaseData.redux';
import { useEffect, useState } from 'react';
import { addRelease, setReleaseStatus } from '../Releases.redux';
import { ConnectionsCollection } from '../../../model/connections/ConnectionsCollection.model';
import { propagateConnectionReleaseManagement } from '../../../services/PropagateConnections.api';
import { Environment, Release } from '../../../model/releases/Release.model';
import { v4 as uuidv4 } from 'uuid';
import { publishWorkflow, publishWorkflowReleaseManagement } from '../../../services/PublishWorkflow.api';
import { unwrapResult } from '@reduxjs/toolkit';
import { uatReleasePermissionsSelector } from '../ReleaseRBAC.selectors.redux';
import { SuccessNotification } from '../../notifications/SuccessNotification';
import { ErrorNotification } from '../../notifications/ErrorNotification';
import { postInsightFamily, postInsightFamilyReleaseManagement } from '../../../services/PostInsightFamily.api';
import { withErrorBoundary } from '../../../shared-components/ErrorBoundary';
import { getModelMapping_dev } from '../../../services/GetModelMapping.api';
import { Schedule } from '../../../model/workflows/Schedule.model';
import { postReleaseReleaseManagement } from '../../../services/PostRelease.api';
import { ModelMapping } from '../../../model/configModels/ModelMapping.model';
import { updateModelMapping_uat } from '../../../services/PostModelMapping.api';
import { TypeMappingObject } from '../../../model/configModels/TypeMappingObject.model';
import { updateTypeMapping_uat } from '../../../services/PostTypeMapping.api';
import { validateInsightFamily_uat } from '../../../services/ValidateInsightFamily.api';
import { getApiConfig_dev } from '../../../services/APIConfig/GetApiConig.api';
import { propagateRoute } from '../../../services/APIConfig/PropagateApiConfig.api';
import { EdmEntityTypeSettings } from '../../../model/apiConfig/EdmEntityTypeSettings.model';
import { MergeRule } from '../../../model/merge-rules/MergeRule.model';
import { getMergeRules_dev } from '../../../services/get-merge-rules/GetMergeRules.api';
import { propagateMergeRule_uat } from '../../../services/get-merge-rules/PropagateMergerule.api';
import { getCatalogueConfig_dev } from '../../../services/GetCatalogueConfig.api';
import { updateCatalogueConfig_uat } from '../../../services/PostCatalogueConfig.api';
import { get } from 'http';

export const handlePostRelease = (props:any) => {

  const environments: Environment[] = [
    { environment: "dev", isDeployed: false },
    { environment: "uat", isDeployed: true },
    { environment: "prod", isDeployed: false },
  ];

  const releaseData: Release = {
    environments: environments,
    timestamp: "",
    releaseId: props.releaseId,
    workflowId: props.workflowId,
    version: props.version,
    id: uuidv4().toString(),
    type: "Propagate",
    status: "Published",
    scheduleCreated: false
  };
  
  return releaseData;
};

export const handlePropagateConnections = (props:any) => {
  //here dev means lower environment and uat means higher environment
  const devConnections = props.devConnections;
  const uatConnections = props.uatConnections;
  const devWorkflow = props.devWorkflow;
  let connData : {type: string, id:string}[] = [];

  let dataIngestion = devWorkflow.dataIngestion;
  dataIngestion?.map((item: any) => {
    if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
      connData.push({
        type: item.type,
        id: item.connectionId,
      });
    }
  });

  let delta = devWorkflow.delta;
  delta?.map((item:any) => {
    if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
      connData.push({
        type: "BLOB",
        id: item.connectionId,
      });
    }
  });

  let publish = devWorkflow.publish;
    publish?.map((item:any) => {
      if(item.type != "SQL")
      {
        if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
          connData.push({ type: item.type, id: item.connectionId });
        }
        if (connData.findIndex((data) => data.id == item.blobConnectionId) == -1) {
          connData.push({ type: "BLOB", id: item.blobConnectionId });
        }
      }
      else{
        if (connData.findIndex((data) => data.id == item.connectionId) == -1) {
          connData.push({ type: "BLOB",id: item.connectionId });
        }
      }
    });

    let connectionItemsUatFinal = {} as ConnectionsCollection

    Object.keys(devConnections).forEach((key: string) => {

      let connectionItemsDev = devConnections[key];
      let connectionItemsUat = uatConnections[key];
     
      connData.map((connDataItem) => {

        if(connectionItemsDev && connDataItem.id.length != 0 && connDataItem.type.length != 0)
        {
           if(connDataItem.type.toUpperCase() == "ADLS" || connDataItem.type.toUpperCase() == "ADLSGEN1")
        {
          const devData = connectionItemsDev.adlsConnections.find((data:any)=> data.connectionId == connDataItem?.id);
          const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.adlsConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

            if(uatIndex > -1 && devData)
            {
                let updatedAdlsConn = [...connectionItemsUat.adlsConnections];
                updatedAdlsConn[uatIndex] = devData;
                connectionItemsUat = {...connectionItemsUat, adlsConnections: updatedAdlsConn};
            }
            else if (devData)
            { 
              if(connectionItemsUat != undefined )
                {
                  const totalConnections = connectionItemsUat.adlsConnections.length;
                  let updatedAdlsConn = [...connectionItemsUat.adlsConnections];
                  updatedAdlsConn[totalConnections] = devData;
                  connectionItemsUat = {...connectionItemsUat, adlsConnections: updatedAdlsConn};
                }
            }
        }
      }
      if(connDataItem.type.toUpperCase() == "BLOB")
      {
        const devData = connectionItemsDev.blobConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.blobConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
            
              let updatedBlobConn = [...connectionItemsUat.blobConnections];
              updatedBlobConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, blobConnections: updatedBlobConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.blobConnections.length;
              let updatedBlobConn = [...connectionItemsUat.blobConnections];
              updatedBlobConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, blobConnections: updatedBlobConn};
            }
           
          }
      }


      if(connDataItem.type.toUpperCase() == "COSMOS")
      {
        const devData = connectionItemsDev.cosmosConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined? connectionItemsUat.cosmosConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedCosmosConn = [...connectionItemsUat.cosmosConnections];
              updatedCosmosConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, cosmosConnections: updatedCosmosConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.cosmosConnections.length;
              let updatedCosmosConn = [...connectionItemsUat.cosmosConnections];
              updatedCosmosConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, cosmosConnections: updatedCosmosConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "API")
      {
        const devData = connectionItemsDev.apiConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.apiConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedApiConn = [...connectionItemsUat.apiConnections];
              updatedApiConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, apiConnections: updatedApiConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.apiConnections.length;
              let updatedApiConn = [...connectionItemsUat.apiConnections];
              updatedApiConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, apiConnections: updatedApiConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "SQL")
      {
        const devData = connectionItemsDev.sqlConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.sqlConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedSqlConn = [...connectionItemsUat.sqlConnections];
              updatedSqlConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlConnections: updatedSqlConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.sqlConnections.length;
              let updatedSqlConn = [...connectionItemsUat.sqlConnections];
              updatedSqlConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlConnections: updatedSqlConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "SQLAAD")
      {
        const devData = connectionItemsDev.sqlaadConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.sqlaadConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedSqlaadConn = [...connectionItemsUat.sqlaadConnections];
              updatedSqlaadConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlaadConnections: updatedSqlaadConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined)
            {
              const totalConnections = connectionItemsUat.sqlaadConnections.length;
              let updatedSqlaadConn = [...connectionItemsUat.sqlaadConnections];
              updatedSqlaadConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, sqlaadConnections: updatedSqlaadConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "TOPIC")
      {
        const devData = connectionItemsDev.topicConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.topicConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedTopicConn = [...connectionItemsUat.topicConnections];
              updatedTopicConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, topicConnections: updatedTopicConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined )
            {
              const totalConnections = connectionItemsUat.topicConnections.length;
              let updatedTopicConn = [...connectionItemsUat.topicConnections];
              updatedTopicConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, topicConnections: updatedTopicConn};
            }
          }
      }

      if(connDataItem.type.toUpperCase() == "EVENT")
      {
        const devData = connectionItemsDev.eventConnections.find((data:any)=> data.connectionId == connDataItem?.id);
        const uatIndex = connectionItemsUat != null || connectionItemsUat != undefined ? connectionItemsUat.eventConnections.findIndex((data:any)=> data.connectionId == connDataItem?.id) : -1

          if(uatIndex > -1 && devData)
          {
              let updatedEventConn = [...connectionItemsUat.eventConnections];
              updatedEventConn[uatIndex] = devData;
              connectionItemsUat = {...connectionItemsUat, eventConnections: updatedEventConn};
          }
          else if (devData)
          {
            if(connectionItemsUat != undefined )
            {
              const totalConnections = connectionItemsUat.eventConnections.length;
              let updatedEventConn = [...connectionItemsUat.eventConnections];
              updatedEventConn[totalConnections] = devData;
              connectionItemsUat = {...connectionItemsUat, eventConnections: updatedEventConn};
            }
          }
      }

      connectionItemsUatFinal = connectionItemsUat;

      });
   });
   return connectionItemsUatFinal;

};

export const handlePropagateModelMapping = (props:any) => {

  var modelMapping_dev = props.modelMapping_dev;
  const devWorkflow = props.devWorkflow;
  const modelName = devWorkflow?.modelName;
  var modelId = "";

  var modelMappingItemToBePropagated = {} as ModelMapping;

  if(devWorkflow?.publish.length > 0)
  {
    modelId = devWorkflow?.publish[0].modelId;
    
    modelMapping_dev.map((modelmappingitem : ModelMapping)=>{
      if(modelmappingitem?.name == modelName || modelmappingitem?.id == modelId)
      {
        modelMappingItemToBePropagated = modelmappingitem;
      }
    });

  }
  
  return modelMappingItemToBePropagated;

}

export const handlePropagateTypeMapping = (props:any) => {

  const devWorkflow = props.devWorkflow;
  var family = devWorkflow.family;
  const selectedPublish = devWorkflow.publish.find((x:any) => x.type === 'COSMOS')
  const partitionKey = selectedPublish != null ? (selectedPublish?.publishMap.find((x:any) => x.destination === 'PartitionKey') != null ? selectedPublish?.publishMap.find((x:any) => x.destination === 'PartitionKey')?.source : '') : ''
  let typeMap = new Map<string, string>();
  let mapValues = null;
  if (family && partitionKey)
      typeMap.set(family, partitionKey);
  if (devWorkflow.workFlowType === "Insight" && partitionKey) {
      // This below entry is added as it maybe needed in some cases. since we are automating we cant predict
      // we can remove it if its not needed. This is based on current design
      typeMap.set(partitionKey, partitionKey);
  }
  if (typeMap.size > 0) mapValues = Object.fromEntries(typeMap);

  if (mapValues != null) {
      let typeMappingSchema: TypeMappingObject = {
          typeMapping: mapValues,
      };
      return typeMappingSchema;
  }
};
export const handlePropagateApiConfig = (props: any) => {
    var apiConfig = props.apiConfig_dev
    var routeName = props.routeName
    var family = props.workflow.family
    var workflowType = props.workflow.workFlowType
    var edmEntityTypeSettings: EdmEntityTypeSettings[] = []
    for (var setting of apiConfig.edmEntityTypeSettings) {
        if (workflowType === "Recommendation") {
            if (setting.routeName === routeName || setting.routeName === routeName + 'actions') {
                if (setting.type === family) {
                    edmEntityTypeSettings.push(setting)
                }
            }
        }
        else if (setting.routeName === routeName) {
            edmEntityTypeSettings.push(setting)
        }
    }
    return edmEntityTypeSettings;
};

export const handleDefaultMergeRulePropagation = (props: any) => {
  const devWorkflow = props.devWorkflow;
  const workflowname = props.workflowname;
  const mergerules_dev = props.mergerules_dev;
  const modelMapping_dev = props.modelMapping_dev;
  const modelId = devWorkflow?.publish[0].modelId;
  var modelname = ""; 
  var mergerRuleToBePropagated = {} as MergeRule;
  if(modelId)
  {
    modelMapping_dev.map((modelmappingitem : ModelMapping)=>{
      if(modelmappingitem?.id == modelId)
      {
        modelname = modelmappingitem?.name;
      }
    });
  }
 
  if (devWorkflow?.typeOfMerge == "Default") {

    const mergeruleName_prop =  "Default" + (modelname ? modelname : workflowname) + "MR";

    mergerules_dev?.data.map((item: MergeRule) => {
      if (item.mergeRuleName == mergeruleName_prop) {
        mergerRuleToBePropagated = item;
      }
    });
    
  }
  return mergerRuleToBePropagated;
};


const ReleaseActionsUATC: React.FC<any> = (props: any) => {

  const userId = useSelector((state: RootState) => state.userDetails.localAccountId);
  const dispatch = useDispatch();
  const dispatchApp = useDispatch<AppDispatch>();
  const devConnections = useSelector((state: RootState) => state.releaseData.devConnections );
  const uatConnections = useSelector((state: RootState) => state.releaseData.uatConnections );
  const uatAppPersona = useSelector((state: RootState) => state.releaseData.uatAppPersonas) ;
  const catalogueConfig_dev = useSelector((state: RootState) => state.releaseData.catalogueConfig.devCatalogueConfig);
  const devWorkflow = props.devWorkflow;
  const actionedVersion = props.selectedVersion;
  const subscriptionKey = props.subscriptionKey;
  const workflowId = props.workflowId;
  const releaseId = props.releaseId;
  const selectedReleaseIds = props.selectedReleaseIds;
  const [uatApproveReject, setuatApproveReject] = useState(true);
  const [uatDeploy, setuatDeploy] = useState(true);
  const releaseRows = props.releaseRows;
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [insightWorkflow, setinsightWorkflow] = useState("Insight");
  const [familyName, setfamilyName] = useState("");
  const [routeName, setRouteName] = useState("");
  const modelMapping_dev = useSelector((state: RootState) => state.releaseData.modelMappings.devModelMapping );
  const [insightFamilyExists, setinsightFamilyExists] = useState(true);
  const apiConfig_dev = useSelector((state: RootState) => state.releaseData.apiConfig.devApiConfig);
  const workflowName = props.workflowName; 

  const releasePermUat = useSelector(uatReleasePermissionsSelector).release;
  const approvePermUat = useSelector(uatReleasePermissionsSelector).approve;
  const superAdmin = useSelector((state: RootState) => state.isSuperAdmin.isSuperAdmin);
  const mergerules_dev = useSelector((state: RootState) => state.releaseData.mergeRules.devMergeRules );


  useEffect(() => {


    const devStatus = releaseRows.find((x:any) => x.cells.field1 == actionedVersion)?.cells.field2;
    const uatStatus = releaseRows.find((x:any) => x.cells.field1 == actionedVersion)?.cells.field4;
    const uatApprove = releaseRows.find((x:any) => x.id == actionedVersion)?.cells.field4;
    
    setuatDeploy(actionedVersion ? (devStatus == "Deployed" && (uatStatus == "Draft" || uatStatus == "") ? false : true) : true);
    setuatApproveReject(uatApprove == "Awaiting Approval" ? false : true);

  }, [actionedVersion, releaseRows]);
 
  useEffect(()=>{
    setinsightWorkflow(devWorkflow.workFlowType);
    setfamilyName(devWorkflow.family);
    if (devWorkflow.workFlowType === "Insight")
    {
      setRouteName(devWorkflow.family);
    }
    else {
       {
        for(var element of uatAppPersona) {
        if (element.subscriptionKey === subscriptionKey)
        {
          var routeName = element.appName.replace(/\s+/g, '').toLowerCase()
          setRouteName(routeName);
          break;
        }
      }
      };
    }
  }, [devWorkflow]);

  useEffect(() => {
    dispatchApp(getModelMapping_dev({ name: "", id: "" }));
    dispatchApp(getMergeRules_dev(""));
    if (devWorkflow.workFlowType === "Insight")
    {
        var insightId = getInsightId(devWorkflow)

      dispatchApp(getApiConfig_dev({routeType: "Insight", subsKey: subscriptionKey}));

    if (insightId)
    dispatchApp(getCatalogueConfig_dev(insightId))
    //if (devWorkflow.typeOfMerge != 'SkipMerge') 
    // dispatchApp(getApiConfig_dev({routeType: "Recommendation", subsKey: subscriptionKey}));
    }
    else {
    dispatchApp(getApiConfig_dev({routeType: "Recommendation", subsKey: subscriptionKey}));
    }
  }, [dispatchApp, actionedVersion, workflowId]);


  return (
    <div 
    style={{display:"flex", border:"none", outline:"none", boxShadow:"none", padding:"4px", fontSize:"14px", alignItems:"center", marginRight:"12px", marginLeft:"12px"}}>

      <div> <SuccessNotification successNotif={showSuccessNotif} setsuccesnotif={setShowSuccessNotif} successText = {successText} /> </div>
      <div> <ErrorNotification errorNotif={showErrorNotif} seterrornotif={setShowErrorNotif} errorText = {errorText} /> </div>

      <div>
      <span style={{fontWeight:"700", marginRight:"8px"}}>UAT</span>
      </div>

      <div>
      <CommandBar>
      <Button appearance="command" disabled={uatDeploy || selectedReleaseIds.length != 1}
      onClick={ async() =>
        {
          if (releasePermUat.includes(subscriptionKey) || superAdmin) {

          const updatedWorkflow = {...devWorkflow, status:"Submitted", environment: "uat" };
          dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));
          
          const propagateRelease = handlePostRelease({workflowId: workflowId , version : actionedVersion , releaseId : releaseId });
          const updatedPropagateRelease = {...propagateRelease, status:"Submitted", environment: "uat" }
          dispatchApp(postReleaseReleaseManagement({payload: updatedPropagateRelease, env:"uat"}));
          dispatch(addRelease({input: updatedPropagateRelease, env:"uat"}));
          dispatch(setReleaseStatus({version: actionedVersion, status: "Submitted", env: "uat"}));

          const propagateConnections =  handlePropagateConnections( {devWorkflow : props.devWorkflow, devConnections : devConnections, uatConnections:uatConnections} )
          if(propagateConnections)
          {
            dispatchApp(propagateConnectionReleaseManagement({payload:propagateConnections, env:"uat", subscriptionKey: subscriptionKey}));
            dispatch(addConnection({subskey: subscriptionKey, input: propagateConnections, env:"uat"}));
          }

            if(insightWorkflow == "Insight" && devWorkflow?.family != undefined && devWorkflow?.family != null && devWorkflow?.family != "")
          {
            const validateFamily = await dispatchApp(
              validateInsightFamily_uat(devWorkflow?.family)
            ).unwrap();
            if (!validateFamily.value) {
              setinsightFamilyExists(false);
            }
            else
            {
              setinsightFamilyExists(true);
            }     
          }

            setuatDeploy(true);
            setShowSuccessNotif(true); setSuccessText("Submitted for Approval");
            
          }
          else
          {
            setErrorText("You do not have permission to deploy this workflow"); setShowErrorNotif(true);
          }
        }
      }>
        <Icon slot="start" name="rocket"></Icon>
        Deploy
      </Button>
      
      <Button appearance="command" 
      disabled = {uatApproveReject || selectedReleaseIds.length != 1} 
      onClick= { async() => 
       { 
        if(approvePermUat.includes(subscriptionKey) || superAdmin)
        { 
          const hasApprovedList = [];
          hasApprovedList.push(userId);
          const updatedWorkflow = {...devWorkflow, hasApproved:hasApprovedList, environment: "uat", schedule: {} as Schedule };
          dispatch(patchReleaseWorkflow({input: updatedWorkflow, env: "uat"}));
  
          dispatch(setWorkflowSummaryStatus({workflowId: workflowId, status: "Published"}));
          dispatch(setReleaseWorkflowStatus({status: "Published", env:"uat"}));
  
          dispatch(setReleaseStatus({version: actionedVersion, status: "Published", env: "uat"}));
          const propagateRelease = handlePostRelease({workflowId: workflowId , version : actionedVersion , releaseId : releaseId });
          dispatchApp(postReleaseReleaseManagement({payload: propagateRelease, env:"uat"}));

          const modelmapping = handlePropagateModelMapping({modelMapping_dev: modelMapping_dev, devWorkflow: devWorkflow});
          if(modelmapping && modelmapping.id)
          {
            dispatchApp(updateModelMapping_uat({payload: modelmapping}));
            dispatch(patchModelMapping({input: modelmapping, env:"uat"}) );
          }
          const typemapping = handlePropagateTypeMapping({devWorkflow: devWorkflow});
          if(typemapping)
          { 
            dispatchApp(updateTypeMapping_uat({payload: typemapping}));
          }
          
          dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));
          
          const edmSettings = handlePropagateApiConfig({ apiConfig_dev: apiConfig_dev, routeName: routeName, workflow: devWorkflow });
          if(edmSettings && edmSettings.length > 0)
          {
            if (insightWorkflow && insightWorkflow === "Insight")
          {
            if(insightFamilyExists === false)
            {
              dispatchApp(postInsightFamilyReleaseManagement({family: familyName , env:"uat"}));
            }
            dispatchApp(postInsightFamilyReleaseManagement({ family: familyName, env: "uat" }));
            await dispatchApp(propagateRoute({ routeType: "Insight", payload: edmSettings[0], env: "uat" }))  
            dispatch(patchApiConfig({input: edmSettings[0], env:"uat", routeType: "Insight"}));       
            if (catalogueConfig_dev) 
            {
            await dispatchApp(updateCatalogueConfig_uat(catalogueConfig_dev))
            dispatch(patchCatalogueConfig({input: catalogueConfig_dev, env:"uat"}) );
          }
}
          if(insightWorkflow == "Recommendation")
          {
            
            const mergeruleToBePropagated = handleDefaultMergeRulePropagation({devWorkflow: devWorkflow, mergerules_dev: mergerules_dev, modelMapping_dev:modelMapping_dev, workflowname:workflowName});
            if(mergeruleToBePropagated && mergeruleToBePropagated.mergeRuleId && mergeruleToBePropagated.mergeRuleId != 'undefined')
            {
              dispatchApp(propagateMergeRule_uat({mergeRule: mergeruleToBePropagated}));
              dispatch(patchMergeRule({input: mergeruleToBePropagated, env:"uat"}) );
            }
            await dispatchApp(propagateRoute({ routeType: "Recommendation", payload: edmSettings[0], env: "uat" }))
            await dispatchApp(propagateRoute({ routeType: "Recommendation", payload: edmSettings[1], env: "uat" }))
            dispatch(patchApiConfig({input: edmSettings[0], env:"uat", routeType: "Recommendation"}));
            dispatch(patchApiConfig({input: edmSettings[1], env:"uat", routeType: "Recommendation"}));
          }
          }
          
          await dispatchApp(publishWorkflowReleaseManagement({workflowId: workflowId , env:"uat"})).then(unwrapResult).then((result)=>{ 
            if(result == true)
            {
              setShowSuccessNotif(true); setSuccessText("UAT Deployment completed")
            }
            else
            {
              setErrorText("UAT deployment failed, Invalid workflow"); setShowErrorNotif(true);
              const updatedWorkflow = {...devWorkflow, status:"New", environment: "uat" };
              dispatch(patchReleaseWorkflow({input: updatedWorkflow, env: "uat"}));
              dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));

              dispatch(setWorkflowSummaryStatus({workflowId: workflowId, status: "New"}));
              dispatch(setReleaseWorkflowStatus({status: "New", env:"uat"}));

              dispatch(setReleaseStatus({version: actionedVersion, status: "New", env: "uat"}));
            } 
          });
          setuatApproveReject(true);
        }
        else
        {
          setErrorText("You do not have permission to approve this workflow"); setShowErrorNotif(true);
        }}
         }>
        <Icon slot="start" name="accept"></Icon>
        Approve
      </Button>
      <Button appearance="command" disabled = {uatApproveReject || selectedReleaseIds.length != 1} 
      onClick={() => {
         if(approvePermUat.includes(subscriptionKey) || superAdmin )
         {
          
          const updatedWorkflow = {...devWorkflow, status:"New", environment: "uat" };
          dispatch(patchReleaseWorkflow({input: updatedWorkflow, env: "uat"}));
          dispatchApp(postWorkflowReleaseManagement( { workflow: updatedWorkflow, env: "uat" }));

          dispatch(setWorkflowSummaryStatus({workflowId: workflowId, status: "New"}));
          dispatch(setReleaseWorkflowStatus({status: "New", env:"uat"}));

          dispatch(setReleaseStatus({version: actionedVersion, status: "New", env: "uat"}));
          
          setShowSuccessNotif(true); setSuccessText("Workflow Rejected");
          setuatDeploy(false);
         }
         else
         {
           setErrorText("You do not have permission to reject in UAT");  setShowErrorNotif(true);
         }
      }}>
        <Icon slot="start" name="calculatormultiply"></Icon>
        Reject
      </Button>

      
    </CommandBar>
      </div>
    </div>

  );
}
export const ReleaseActionsUAT = withErrorBoundary("Release Actions UAT", ReleaseActionsUATC);

export function getInsightId(devWorkflow: any) {
  let insightId = null;
  const selectedPublish = devWorkflow.publish?.find((x: { type: string; }) => x.type === 'COSMOS');
  if (selectedPublish != null)
    insightId = selectedPublish.modelId;
  if (!insightId)
    insightId = devWorkflow?.config?.workflowId;
  return insightId;
}

