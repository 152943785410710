import axios from 'axios';
import { BASE_URL } from './Constants';
import {v4 as uuidv4} from 'uuid';
import { AuthClientProvider } from '../../auth/AuthClientProvider';
import { Logger } from '../../utils/Logger';

export const getRequestReleaseManagement = async<T> (apiPath: string) => {
  const correlationId = uuidv4();
  const environment = apiPath.includes("prod/") ? "prod" : apiPath.includes("uat/") ? "uat" : "dev";

  Logger.Instance.trackTrace(`start getRequest for release management ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId
  });

  try {
    const response = await axios.get<T>(`${BASE_URL}/${apiPath}`, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken_releaseManagement(environment),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
      },
    });

    Logger.Instance.trackTrace(`completed getRequest for release management ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId
    });

    return response;
  } catch (error) {

    Logger.Instance.trackException({error: error as Error}, {
      event: `error getRequest for release management ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
};
