import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../root-redux/RootState";
import { postRequest } from "./utils/PostRequest";
import { JobCluster, NewClusterConfig } from "../model/cluster/JobCluster";
import { v4 as uuidv4 } from 'uuid';
import { postWorkflow } from "./PostWorkflow.api";
import { RequestStatus } from "../model/ServiceRequestStatus.model";
import { Schedule } from "../model/workflows/Schedule.model";
import { workflowByIdSlice } from "../feature-components/workflows/workflow-by-id-redux/WorkflowById.redux";
import { getWorkflowById } from "./GetWorkflows.api";
import { updateScheduleJobStatus } from "./UpdateScheduleJobStatus.api";
import { truncate } from "fs/promises";

export const postScheduleWorkflow = createAsyncThunk<{success: boolean, error: string | undefined} | undefined, {showEnv?: boolean}, {state: RootState}>('workflows/postScheduleWorkflow', async ({showEnv}, { getState, dispatch }) => {
  let schedule  = getState().workflowById.workflow?.schedule
  const originalSchedule = getState().workflowById.originalWorkflow?.schedule
  const selectedEnv = getState().env.selectedEnvItem
  const runJobType = getState().workflowById.runJobType

  if (!schedule) {
    schedule = new Schedule()
  } else {
    schedule = {...schedule}
  }
  
  if (!schedule.timePeriod) {
    schedule.timePeriod = "day"
  }
  if (!schedule.startFrom) {
    schedule.startFrom = "00"
  }
  if (!schedule.timezone_Id) {
    schedule.timezone_Id = "IST"
  }
  if (!schedule.minute) {
    schedule.minute = new Date().getMinutes().toString()
  }
  if (!schedule.hour) {
    schedule.hour = new Date().getHours().toString()
  }
  if (!schedule.dayOfWeek) {
    schedule.dayOfWeek = "00"
  }
  if (!schedule.dayOfMonth) {
    schedule.dayOfMonth = "00"
  }
  if (!schedule.maxRetries) {
    schedule.maxRetries = 0
  }

  schedule.isScheduleCreated = true

  dispatch(workflowByIdSlice.actions.updateSchedule(schedule))

  // if later or first time run for immediate
  if (runJobType === "later" || (runJobType === "immediate" && (!originalSchedule || !originalSchedule.isScheduleCreated))) {
    await dispatch(postWorkflow({workflow: null, env: null}))
    const workflowStatus = getState().workflowById.status

    if (workflowStatus === RequestStatus.failed) {
      return {success: false, error: undefined}
    }

    const workflow = getState().workflowById.workflow
    const env = showEnv ? getState().workflows.clusterEnv.toLowerCase() : getState().env.selectedEnvItem.toLowerCase()
    const clusters = getState().workflows.clusterList[env]
    const workflowId = workflow?.config?.workflowId
    const clusterId = getState().workflowById.scheduleClusterId
    const cluster = clusters.find(cluster => cluster.cluster_id === clusterId)

    if (!workflowId || !cluster) {
      return
    }

    const jobCluster = new JobCluster()
    jobCluster.id = uuidv4().toString()
    jobCluster.newCluster = new NewClusterConfig()
    jobCluster.newCluster.clusterId = cluster.cluster_id
    jobCluster.newCluster.clusterName = cluster.cluster_name
    jobCluster.newCluster.nodeTypeId = cluster.node_type_id
    jobCluster.newCluster.sparkVersion = cluster.spark_version
    jobCluster.newCluster.numWorkers = cluster.num_workers

    
    const response = await postRequest<boolean | {Code: string, Message: string}, JobCluster>(`/${selectedEnv}/workflows/${workflowId}/schedule`, jobCluster)
  
    const error = response.data as {Code: string, Message: string}

    if (error && error.Code) {
      return {success: false, error: error.Message}
    }

    // get the workflow again to get the job id
    await dispatch(getWorkflowById(workflowId))
  }

  // run now for immediate
  if (runJobType === "immediate") {
    await dispatch(updateScheduleJobStatus({pause_status: 'trigger', updateWorkflow: false}))
    if (!originalSchedule || !originalSchedule.isScheduleCreated) {
      await dispatch(updateScheduleJobStatus({pause_status: 'pause', updateWorkflow: true}))
    }
  }

  return {success: true, error: undefined}
})