import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import { ComponentType } from "react";

//const browserHistory:History = createBrowserHistory({basename:""});
const browserHistory={basename:""};
const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY, //'',//process.env.REACT_APP_APPINSIGHTS_KEY, getSecret("InstrumentationKey")
    //endpointUrl: Configurations.appInsightsEndpoint == "0" ? undefined : Configurations.appInsightsEndpoint,
    // connectionString:"",
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true, // logged as PageVisitTime:time a user spends on each page is tracked.
    // enableCorsCorrelation: true,
   // enableAutoRouteTracking: true,// enable automatic route change tracking for SPA // use only if you are not using the React plugin
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
ai.loadAppInsights();
// appInsights.trackPageView();
// export { reactPlugin, appInsights };
// eslint-disable-next-line import/no-anonymous-default-export
export default (Component: ComponentType<any>) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
export { reactPlugin };
