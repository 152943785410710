import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "./utils/GetRequest"
import { putRequestServiceAPI } from "./utils/PostRequest"
import { RootState } from "../root-redux/RootState"
import { AppIdOnboardingResponse } from "../model/app-persona/AppIdOnboardingResponse.model"
import { AppIdOnboarding } from "../model/app-persona/AppIdOnboarding.model"

  export const validateAppOnboarding = createAsyncThunk<AppIdOnboardingResponse, {payload: AppIdOnboarding}, {state: RootState}>('apppersonamappings/validateAppOnboarding', async ({ payload}, { getState }) => {
    const selectedEnv = getState().env.selectedEnvItem
    
    const response = await putRequestServiceAPI<AppIdOnboardingResponse, AppIdOnboarding>(`${selectedEnv}/apppersonamappings`,payload, payload.subscriptionKey)
    return response.data
  });