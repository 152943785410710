import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";

import { RootState } from "../../../root-redux/RootState";
import { FieldToMerge } from "../../../model/merge-rules/FieldsToMerge.model";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { MergeRuleSet } from "../../../model/merge-rules/MergeRuleSet.model";

export interface FieldListDataGridRow extends Row {
  cells: {
    field1: string,
    field2: string
  }
}

export const fieldsListColumns: Column[] = [
  {
    field: 'field1',
    content: 'Name',
    sortable: true,
    display: {
      width: '100px'
    }
  },
  {
    field: 'field2',
    content: 'Source of Field',
    sortable: true,
    display: {
      width: '100px'
    }
  }
];

const sortByFieldsName = (set: FieldListDataGridRow[]) => {
  var sortedSet = set.sort((a, b) => {
      if (a.cells['field1'] > b.cells['field1']) {
          return 1
      } else if (a.cells['field1'] < b.cells['field1']) {
          return -1
      } else {
          return 0
      }
  });
  return sortedSet
}

export const allFieldsItemSelectorData = (workflows: WorkflowSummary[], currentMergeRuleSet: MergeRuleSet, selectedFields: string[]): FieldListDataGridRow[] => {
  var i = 1;
  var fieldsList: FieldToMerge[] = []
  var fieldsToExclude = ["CreatedDate", "ModifiedDate", "PartitionKey", "Type", "SourceId", "Timestamp", "Text", "Reason", "StartOn", "DueInDays", "ExpiryInDays", "Status", "Family", "Object", "Tags"]
  var fieldsToExclude :string[] = []
  workflows.forEach(eachworkflow => {
    if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
      Object.keys(eachworkflow.fields).map((item) => {
        if (!fieldsToExclude.includes(item)) {
          var isPresent = fieldsList.find(x => { return x.key === item })
          if (isPresent) {
            var index = fieldsList.indexOf(isPresent)
            fieldsList[index].value = [...fieldsList[index].value, eachworkflow.config.workflowName]
          } else {
            var newField = new FieldToMerge()
            newField.key = item
            newField.value = [eachworkflow.config.workflowName]
            fieldsList.push(newField)
          }
        }
      })
    }
  });

  var data = currentMergeRuleSet?.mergeOn?.fields?.default
  var transformedToRowItems: FieldListDataGridRow[] = []
  if (fieldsList) {
    fieldsList.forEach((item: FieldToMerge) => {
      transformedToRowItems.push(
        {
          id: i,
          selected: data ? data.includes(item.key) || selectedFields.includes(item.key) : selectedFields.includes(item.key) || false,
          disabled: data ? data.includes(item.key) : false,
          cells: {
            field1: item.key,
            field2: item.value.join(",")
          }
        })
      i++;
    });
  }
  transformedToRowItems = sortByFieldsName(transformedToRowItems)
  
  return transformedToRowItems
}













// MRTODO to be deleted
export interface FieldsDataGridRow extends Row {
  cells: {
    field1: string
    field2: string
  }
}

export const fieldColumns: Column[] = [
  {
    field: 'field1',
    content: 'Name',
    sortable: true,
    display: {
      width: '100px'
    }
  },
  {
    field: 'field2',
    content: 'Source',
    sortable: true,
    display: {
      width: '100px'
    }
  }
];
export const fieldsItemSelector = (fields: FieldToMerge[]): FieldsDataGridRow[] => {
  var transformedToRowItems: FieldsDataGridRow[] = []

  // need to create empty rows to show loader in those rows
  const loaderRows = 10 // need to get this value from config

  const MergeRuleSetItems = fields
  var i = 1;
  if (MergeRuleSetItems != undefined) {
    for (var i = 0; i < MergeRuleSetItems.length; i++) {
      transformedToRowItems.push(
        {
          id: "1" + MergeRuleSetItems[i],
          cells: {
            field1: MergeRuleSetItems[i].key,
            field2: MergeRuleSetItems[i].value?.join(",") || ""
          },
        })
    }
  }
  return transformedToRowItems;
}