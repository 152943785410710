import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FeedbackState {
  showDialog: boolean
}

const initialState: FeedbackState = {
  showDialog: false
}

export const feedbackSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    setShowDialog: (state, action: PayloadAction<boolean>) => {
      state.showDialog = action.payload
    }
  }
});

const feedbackReducer = feedbackSlice.reducer;

export const { setShowDialog } = feedbackSlice.actions

export {feedbackReducer}