import { Connection } from "./Connection.model";

export class CosmosConnection extends Connection
{
    public databaseName!: string
    public cosmosContainer!: string
    public batchSize!: string
    public region!: string
    public cosmosConnectionString!: string
    public type!: string
    public productFamily!: string
}