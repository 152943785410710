export enum ConnectionType {
    blobConnections = 'blobConnections',
    adlsConnections = 'adlsConnections',
    adlsGen1Connections = 'adlsGen1Connections',
    cosmosConnections = 'cosmosConnections',
    apiConnections = 'apiConnections',
    sqlaadConnections = 'sqlaadConnections',
    sqlConnections = 'sqlConnections',
    eventConnections = 'eventConnections',
    topicConnections = 'topicConnections'
}