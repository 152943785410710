import { createAsyncThunk } from "@reduxjs/toolkit"
import { Workflow, IWorkflowResponse} from "../model/workflows/Workflow.model"
import { postRequest } from "./utils/PostRequest"
import { RootState } from "../root-redux/RootState"
import { WorkflowStageType } from "../model/preview-workflow/WorkflowStageType.model"
import { postRequestReleaseManagement } from "./utils/PostRequestReleaseManagement"

export const postWorkflow = 
createAsyncThunk<{workflow: Workflow, type: 'new' | 'existing' | 'clone'},{ workflow : Workflow | null, env : string | null }, {state: RootState}>
('workflows/postWorkflow', async (workflowInput, { getState }) => {

  const workflow = getState().workflowById.workflow
  const userDetails = getState().userDetails
  const type = getState().workflowById.type
  const appPersonas = getState().appPersonas.list;
  const selectedEnv = getState().env.selectedEnvItem ;
  var environment = workflowInput.env === "" || workflowInput.env === null ? selectedEnv : workflowInput.env;
  

  let workflowPayload = workflow
  if(workflowInput.workflow){
    workflowPayload = workflowInput.workflow
  }

  if (!workflowPayload.modifiedBy || workflowPayload.modifiedBy !== userDetails?.localAccountId) {
    workflowPayload = { ...workflowPayload, modifiedBy: userDetails?.localAccountId }
  }

  const response = await postRequest<IWorkflowResponse, Workflow>(`${environment}/workflows/add`, workflowPayload)
  if (!workflowPayload.tenantName) {
    workflowPayload = { ...workflowPayload, tenantName: appPersonas?.find((appPersona:any) => appPersona.subscriptionKey === workflow.subscriptionKey)?.appName || '' }
  }
  
  return {workflow: workflowPayload, type}  
});


export const postWorkflowReleaseManagement = 
createAsyncThunk<{workflow: Workflow, type: 'new' | 'existing' | 'clone'},{ workflow : Workflow | null, env : string | null }, {state: RootState}>
('workflows/postWorkflow', async (workflowInput, { getState }) => {

  const workflow = getState().workflowById.workflow
  const userDetails = getState().userDetails
  const type = getState().workflowById.type
  const appPersonas = getState().appPersonas.list;
  const selectedEnv = getState().env.selectedEnvItem ;
  var environment = workflowInput.env === "" || workflowInput.env === null ? selectedEnv : workflowInput.env;
  

  let workflowPayload = workflow
  if(workflowInput.workflow){
    workflowPayload = workflowInput.workflow
  }

  if (!workflowPayload.modifiedBy || workflowPayload.modifiedBy !== userDetails?.localAccountId) {
    workflowPayload = { ...workflowPayload, modifiedBy: userDetails?.localAccountId }
  }

  const response = await postRequestReleaseManagement<IWorkflowResponse, Workflow>(`${environment}/workflows/add`, workflowPayload)
  if (!workflowPayload.tenantName) {
    workflowPayload = { ...workflowPayload, tenantName: appPersonas?.find((appPersona:any) => appPersona.subscriptionKey === workflow.subscriptionKey)?.appName || '' }
  }
  
  return {workflow: workflowPayload, type}  
});
