import * as React from 'react'
import { Logger } from '../utils/Logger'

interface IErrorBoundaryProps {
    componentName: string
    fallbackUi: React.ReactNode
    fallbackCallback?: (e: Error, errorInfo: React.ErrorInfo) => void
}

interface IErrorBoundaryState {
    hasError: boolean
}

/**
 * Setup error boundary for a React subtree.
 * Any error captured is considered unexpected thus logged. Provide fallbackUi prop
 * to display a alternative UI if error happens. DO NOT attempt to use this as a control flow.
 */
export class ErrorBoundary extends React.PureComponent<any, IErrorBoundaryState> {
    public static getDerivedStateFromError(): IErrorBoundaryState {
        return { hasError: true }
    }

    public constructor(props: IErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }
    
    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        Logger.Instance.trackException({error: error as Error}, {
            event: errorInfo,
            component: this.props.componentName
        });

        if (this.props.fallbackCallback) {
                this.props.fallbackCallback(error, errorInfo)
        }
    }

    public render(): React.ReactNode {
        const x = this.props.componentName
        if (this.state.hasError) {
            // can render any custom fallback UI
            return this.props.fallbackUi
        }
        return this.props.children
    }
}

export const withErrorBoundary = <P extends object>(ComponentName: string, Component: React.ComponentType<P>): React.FC<P> => ({
    ...props
  }: P) =>
  {
    return (
      <ErrorBoundary componentName={ComponentName} fallbackUi={<div>Could not load content.</div>}>
        <Component {...props as P} />
      </ErrorBoundary>
    );
  }