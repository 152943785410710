import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface EnvState {
    selectedEnvItem: string
}
  
const envInitialState: EnvState = {
    selectedEnvItem: "dev"
}

const setInitialEnv = () => {
  window.localStorage.setItem("env", "dev");
  window.localStorage.setItem("previousEnv", "dev");
  return envInitialState
}

export const envSlice = createSlice({
    name: 'env',
    initialState : setInitialEnv(),
    reducers: {
      setEnvState: (state, action: PayloadAction<string>) => {
        state.selectedEnvItem = action.payload
        // to access this in auth client provider
        window.localStorage.setItem("env", action.payload)
      }
    }
});

export const envReducer = envSlice.reducer;