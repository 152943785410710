import { createAsyncThunk } from "@reduxjs/toolkit"
import { ConnectionsCollection, ConnectionsRequestPayload, IConnectionsResponse } from "../model/connections/ConnectionsCollection.model"
import { postRequest } from "./utils/PostRequest"
import { Connection } from "../model/connections/Connection.model"
import { ConnectionType } from "../model/connections/ConnectionType.model"
import { BlobConnection } from "../model/connections/BlobConnection.model"
import { AdlsConnection } from "../model/connections/AdlsConnection.model"
import { CosmosConnection } from "../model/connections/CosmosConnection.model"
import { ApiConnection } from "../model/connections/ApiConnection.model"
import { SqlAadConnection } from "../model/connections/SqlAadConnection.model"
import { TopicConnection } from "../model/connections/TopicConnection.model"
import { EventConnection } from "../model/connections/EventConnection.model"
import { SqlConnection } from "../model/connections/SqlConnection.model"
import { RootState } from "../root-redux/RootState"
import { v4 as uuidv4 } from 'uuid';

export const postConnections = createAsyncThunk<{[subscriptionKey: string]: ConnectionsCollection}, {newConnection: Connection, type: ConnectionType}, { state: RootState }>('connections/postConnections', async ({newConnection, type}, { getState }) => {
    const connections = getState().connections.list
    const selectedEnv = getState().env.selectedEnvItem

    // add all connections because this api just for creating 1 single connection needs all other connections else it will delete others - 
    // TODO need to update this api    
    const payload = {...connections[newConnection.subscriptionKey], subscriptionKey: newConnection.subscriptionKey, partitionKey: newConnection.subscriptionKey} as ConnectionsRequestPayload

    let isNew = false
    if (!newConnection.connectionId) {
      isNew = true
      newConnection.connectionId = uuidv4().toString()
    }    

    switch(type)
    {
      case ConnectionType.blobConnections:
        if (!payload.blobConnections) {
          payload.blobConnections = []
        }
        if (isNew){
          payload.blobConnections = [...payload.blobConnections, {...newConnection as BlobConnection}]
        } else {
          payload.blobConnections = payload.blobConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as BlobConnection}
            }
            return item
          })
        }
        
        break
      case ConnectionType.adlsConnections:
        if (!payload.adlsConnections) {
          payload.adlsConnections = []
        }        
        if (isNew){
          payload.adlsConnections = [...payload.adlsConnections, {...newConnection as AdlsConnection}]
        } else {
          payload.adlsConnections = payload.adlsConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as AdlsConnection}
            }
            return item
          })
        }
        break
      case ConnectionType.cosmosConnections:
        if (!payload.cosmosConnections) {
          payload.cosmosConnections = []
        }
        if (isNew){
          payload.cosmosConnections = [...payload.cosmosConnections, {...newConnection as CosmosConnection}]
        } else {
          payload.cosmosConnections = payload.cosmosConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as CosmosConnection}
            }
            return item
          })
        }
        break
      case ConnectionType.apiConnections:
        if (!payload.apiConnections) {
          payload.apiConnections = []
        }
        if (isNew){
          payload.apiConnections = [...payload.apiConnections, {...newConnection as ApiConnection}]
        } else {
          payload.apiConnections = payload.apiConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as ApiConnection}
            }
            return item
          })
        }
        break
      case ConnectionType.sqlaadConnections:
        if (!payload.sqlaadConnections) {
          payload.sqlaadConnections = []
        }
        if (isNew){
          payload.sqlaadConnections = [...payload.sqlaadConnections, {...newConnection as SqlAadConnection}]
        } else {
          payload.sqlaadConnections = payload.sqlaadConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as SqlAadConnection}
            }
            return item
          })
        }
        break
      case ConnectionType.topicConnections:
        if (!payload.topicConnections) {
          payload.topicConnections = []
        }
        if (isNew){
          payload.topicConnections = [...payload.topicConnections, {...newConnection as TopicConnection}]
        } else {
          payload.topicConnections = payload.topicConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as TopicConnection}
            }
            return item
          })
        }        
        break
      case ConnectionType.eventConnections:
        if (!payload.eventConnections) {
          payload.eventConnections = []
        }
        if (isNew){
          payload.eventConnections = [...payload.eventConnections, {...newConnection as EventConnection}]
        } else {
          payload.eventConnections = payload.eventConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as EventConnection}
            }
            return item
          })
        }        
        break
      case ConnectionType.sqlConnections:
        if (!payload.sqlConnections) {
          payload.sqlConnections = []
        }
        if (isNew){
          payload.sqlConnections = [...payload.sqlConnections, {...newConnection as SqlConnection}]
        } else {
          payload.sqlConnections = payload.sqlConnections.map((item) => {
            if (item.connectionId === newConnection.connectionId) {
              return {...newConnection as SqlConnection}
            }
            return item
          })
        }
        break
      default:
        break // TODO error handling
    }
  
    const response = await postRequest<IConnectionsResponse, ConnectionsCollection>(`${selectedEnv}/connections/${newConnection.subscriptionKey}/publish`, payload)

    let result: {[key: string]: ConnectionsCollection}

    if (!connections) {
      result = {}
    } else {
      result = {...connections}
    }

    if (!result[newConnection.subscriptionKey]) {
      result[newConnection.subscriptionKey] = new ConnectionsCollection()
    }

    result[newConnection.subscriptionKey] = payload//response.data?.connections
    
    return result
  })
  
  export const cloneConnection = createAsyncThunk<{[subscriptionKey: string]: ConnectionsCollection } | undefined, {newConnectionName: string, newConnectionId: string}, { state: RootState }>('connections/cloneConnection', async ({newConnectionName, newConnectionId}, { getState }) => {
    const selectedConnectionIds = getState().connections.selectedConnectionIds
    const connectionsObj = getState().connections.list  
    const selectedEnv = getState().env.selectedEnvItem

    let selectedConnection: Connection | undefined
    let type: ConnectionType | undefined 
    let subKey: string | undefined
    if (selectedConnectionIds && selectedConnectionIds.length === 1 && connectionsObj) {
      const subscriptionKeys = Object.keys(connectionsObj)
      for(var subscriptionKey of subscriptionKeys) {
        const connectionItems = connectionsObj[subscriptionKey]
        const types = Object.values(ConnectionType)
        for(var connectionType of types) {
          if (connectionItems[connectionType] && connectionItems[connectionType].length > 0) {
            for (var connection of connectionItems[connectionType]) {
              if (connection.connectionId === selectedConnectionIds[0]) {
                selectedConnection = connection
                type = connectionType
                subKey = subscriptionKey
                break
              }
            }
          }

          if (selectedConnection) {
            break
          }
        }

        if (selectedConnection) {
          break
        }
      }
    }
    
    if (!selectedConnection || !type || !subKey) {
      return
    }

    // add all connections because this api just for creating 1 single connection needs all other connections else it will delete others - 
    // TODO need to update this api    
    const payload = {...connectionsObj[subKey], subscriptionKey: subKey, partitionKey: subKey} as ConnectionsRequestPayload

    switch(type)
    {
      case ConnectionType.blobConnections:
        if (!payload.blobConnections) {
          payload.blobConnections = []
        }
        payload.blobConnections = [...payload.blobConnections, {...selectedConnection as BlobConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.adlsConnections:
        if (!payload.adlsConnections) {
          payload.adlsConnections = []
        }
        payload.adlsConnections = [...payload.adlsConnections, {...selectedConnection as AdlsConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.cosmosConnections:
        if (!payload.cosmosConnections) {
          payload.cosmosConnections = []
        }
        payload.cosmosConnections = [...payload.cosmosConnections, {...selectedConnection as CosmosConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.apiConnections:
        if (!payload.apiConnections) {
          payload.apiConnections = []
        }
        payload.apiConnections = [...payload.apiConnections, {...selectedConnection as ApiConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.sqlaadConnections:
        if (!payload.sqlaadConnections) {
          payload.sqlaadConnections = []
        }
        payload.sqlaadConnections = [...payload.sqlaadConnections, {...selectedConnection as SqlAadConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.topicConnections:
        if (!payload.topicConnections) {
          payload.topicConnections = []
        }
        payload.topicConnections = [...payload.topicConnections, {...selectedConnection as TopicConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.eventConnections:
        if (!payload.eventConnections) {
          payload.eventConnections = []
        }
        payload.eventConnections = [...payload.eventConnections, {...selectedConnection as EventConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      case ConnectionType.sqlConnections:
        if (!payload.sqlConnections) {
          payload.sqlConnections = []
        }
        payload.sqlConnections = [...payload.sqlConnections, {...selectedConnection as SqlConnection, connectionId: newConnectionId, connectionName: newConnectionName}]
        break
      default:
        break // TODO error handling
    }
  
    const response = await postRequest<IConnectionsResponse, ConnectionsCollection>(`${selectedEnv}/connections/${subKey}/publish`, payload)

    let result: {[key: string]: ConnectionsCollection}

    if (!connectionsObj) {
      result = {}
    } else {
      result = {...connectionsObj}
    }

    if (!result[subKey]) {
      result[subKey] = new ConnectionsCollection()
    }

    result[subKey] = response.data?.connections
    
    return result
  })

  export const deleteConnections = createAsyncThunk<{[subscriptionKey: string]: ConnectionsCollection } | undefined, null, { state: RootState }>('connections/deleteConnections', async (_, { getState }) => {
    const selectedConnectionIds = getState().connections.selectedConnectionIds
    const connectionsObj = getState().connections.list  
    const selectedEnv = getState().env.selectedEnvItem

    const newConnectionsObj = {...connectionsObj}
    const updatedSubkeys: {[key: string]: boolean} = {} // to keep track of which subkeys have been updated

    if (selectedConnectionIds && selectedConnectionIds.length > 0 && newConnectionsObj) {
      const subscriptionKeys = Object.keys(newConnectionsObj)
      for(var subscriptionKey of subscriptionKeys) {
        const connectionItems = newConnectionsObj[subscriptionKey]
        const types = Object.values(ConnectionType)
        for(var connectionType of types) {
          if (connectionItems[connectionType] && connectionItems[connectionType].length > 0) {
            const connections = connectionItems[connectionType] as Connection[]
            const newConnectionItems = connections?.filter((connection: Connection) => {
              return !selectedConnectionIds.includes(connection.connectionId)
            })
            
            if (newConnectionItems.length !== connections.length) {
              let newConnections = {...newConnectionsObj[subscriptionKey]}
              updateConnectionsBasedOnType(connectionType, newConnections, newConnectionItems)
              newConnectionsObj[subscriptionKey] = newConnections
              // include this subscription only if any of its connections is removed              
              updatedSubkeys[subscriptionKey] = true
            }
          }
        }
      }
    }

    const subscriptionKeys = Object.keys(updatedSubkeys).filter((subKey) => updatedSubkeys[subKey])
       
    const responses = await Promise.all(subscriptionKeys.map(async (subKey) => {
      return postRequest<IConnectionsResponse, ConnectionsCollection>(`${selectedEnv}/connections/${subKey}/publish`, newConnectionsObj[subKey])
    }))

    // TODO - error handling - what if some of the requests fail?

    return newConnectionsObj
  })

  const updateConnectionsBasedOnType = (type: ConnectionType, connections: ConnectionsCollection, newConnectionItems: Connection[]) => {
    switch(type)
    {
      case ConnectionType.blobConnections:
        connections.blobConnections = [...newConnectionItems as BlobConnection[]]
        break
      case ConnectionType.adlsConnections:
        connections.adlsConnections = [...newConnectionItems as AdlsConnection[]]
        break
      case ConnectionType.cosmosConnections:
        connections.cosmosConnections = [...newConnectionItems as CosmosConnection[]]
        break
      case ConnectionType.apiConnections:
        connections.apiConnections = [...newConnectionItems as ApiConnection[]]
        break
      case ConnectionType.sqlaadConnections:
        connections.sqlaadConnections = [...newConnectionItems as SqlAadConnection[]]
        break
      case ConnectionType.topicConnections:
        connections.topicConnections = [...newConnectionItems as TopicConnection[]]
        break
      case ConnectionType.eventConnections:
        connections.eventConnections = [...newConnectionItems as EventConnection[]]
        break
      case ConnectionType.sqlConnections:
        connections.sqlConnections = [...newConnectionItems as SqlConnection[]]
        break
      default:
        break // TODO error handling
    }
  }