import { createAsyncThunk } from "@reduxjs/toolkit";
import { ModelMapping } from "../model/configModels/ModelMapping.model";
import { RootState } from "../root-redux/RootState";
import { getRequest } from "./utils/GetRequest";


export const getModelMapping = createAsyncThunk<ModelMapping[], { name:string, id:string }, { state: RootState }>('modelMapping/getModelMapping', async ({name, id }, {getState}) => {

    const selectedEnv = getState().env.selectedEnvItem
  
    const response = await getRequest<ModelMapping[]>(`${selectedEnv}/modelmapping?name=${name}&id=${id}`)
    return response.data;
  });

  export const getModelMapping_dev = createAsyncThunk<ModelMapping[], { name:string, id:string }, { state: RootState }>('dev/modelMapping/getModelMapping', async ({name, id }, {getState}) => {
  
    const response = await getRequest<ModelMapping[]>(`dev/modelmapping?name=${name}&id=${id}`)
    return response.data;

  });

  export const getModelMapping_uat = createAsyncThunk<ModelMapping[], { name:string, id:string }, { state: RootState }>('uat/modelMapping/getModelMapping', async ({name, id }, {getState}) => {
  
    const response = await getRequest<ModelMapping[]>(`uat/modelmapping?name=${name}&id=${id}`)
    return response.data;

  });

