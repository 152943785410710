import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { ShimmerLines } from "../../../shared-components/loader/ShimmerLines";
import {
  CommandBar,
  Icon,
  Select,
  Option,
  Button,
  TextField,
} from "../../../common/HarmonyEnablers";
import {
  updateRunJobType,
  updateSchedule,
  updateScheduleClusterId,
} from "../workflow-by-id-redux/WorkflowById.actions.redux";
import timezonesList from "../../../shared-content/timezones.json";
import { ScheduleWorkflowRecurrence } from "./ScheduleWorkflowRecurrence";
import React, { useEffect } from "react";
import { updateScheduleJobStatus } from "../../../services/UpdateScheduleJobStatus.api";
import { setClusterEnv } from "../workflows-redux/Workflows.redux";

export const ScheduleWorkflowBody: React.FC<{ showEnv?: boolean }> = React.memo(
  ({ showEnv }) => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedEnv = useSelector((state: RootState) => state.env.selectedEnvItem)
    const workflowStatus = useSelector(
      (state: RootState) => state.workflowById.status
    );
    const clusterRequestStatus = useSelector(
      (state: RootState) => state.workflows.clusterStatus
    );
    const scheduleObj = useSelector(
      (state: RootState) => state.workflowById.workflow?.schedule
    );
    const originalScheduleObj = useSelector((state: RootState) => state.workflowById.originalWorkflow?.schedule)
    const clusters = useSelector(
      (state: RootState) => state.workflows.clusterList
    );
    const clusterId = useSelector(
      (state: RootState) => state.workflowById.scheduleClusterId
    );
    const runJobType = useSelector((state: RootState) => state.workflowById.runJobType)
    const clusterEnv = useSelector((state: RootState) => state.workflows.clusterEnv)
    const runJobList = ["Immediate", "Later"];
    const envList = ["Dev", "UAT", "Prod"];

    useEffect(() => {
      if (!clusterId) {
        const e = showEnv && clusterEnv ? clusterEnv.toLowerCase() : selectedEnv?.toLowerCase()
        if (e && clusters[e]?.length > 0) {
          const newId = clusters[e][0].cluster_id
          if (!clusterId || clusterId !== newId) {
            dispatch(updateScheduleClusterId(newId));
          }
        } 
      }      
    },[clusterId, clusters, selectedEnv, clusterEnv])

    if (
      workflowStatus === RequestStatus.loading ||
      workflowStatus === RequestStatus.idle ||
      clusterRequestStatus === RequestStatus.loading ||
      clusterRequestStatus === RequestStatus.idle
    ) {
      return <ShimmerLines />;
    }

    return (
      <>
        <CommandBar className="schedule-cmd-bar">
          <Button disabled={!scheduleObj || !scheduleObj.isScheduleCreated} appearance="command" onClick={() =>
                      !scheduleObj || !scheduleObj.pause_Status || scheduleObj.pause_Status.toUpperCase() === "UNPAUSED" ? 
                      dispatch(updateScheduleJobStatus({pause_status: "pause", updateWorkflow: true})) : 
                      dispatch(updateScheduleJobStatus({pause_status: "unpause", updateWorkflow: true}))
                    }>
            {              
              !scheduleObj || !scheduleObj.pause_Status || scheduleObj.pause_Status.toUpperCase() === "UNPAUSED" ?
              (<><Icon slot="start" name="pause" />Pause</>) : (<><Icon slot="start" name="play" />Resume Job</>)
            }
          </Button>
          <Button appearance="command" disabled={!scheduleObj || !scheduleObj.isScheduleCreated || !scheduleObj.pause_Status || scheduleObj.pause_Status.toUpperCase() !== "UNPAUSED"} onClick={() =>
                      dispatch(updateScheduleJobStatus({pause_status: "pause", updateWorkflow: false}))                      
                    }>
            <Icon slot="start" name="blocked" />
            Stop Job
          </Button>
        </CommandBar>
        <hr />
        <table className="schedule-table">
          {showEnv && (
            <tr>
              <td>Environment</td>
              <td>
                <Select
                  value={clusterEnv}
                  slot="action"
                  fixed-placement
                  placeholder={"Select"}
                  name="subkey"
                >
                  {envList.map((item, index) => (
                    <Option
                      key={item}
                      role="menuitemcheckbox"
                      onHeSelected={() => dispatch(setClusterEnv(item))}
                      value={item}
                    >
                      {item}
                    </Option>
                  ))}
                </Select>
              </td>
            </tr>
          )}
          <tr>
            <td>Run job</td>
            <td>
              <Select
                value={
                  runJobType === "immediate"
                    ? runJobList[0]
                    : runJobList[1]
                }
                slot="action"
                fixed-placement
                placeholder={"Select"}
                name="subkey"
              >
                {runJobList.map((item, index) => (
                  <Option
                    key={item}
                    role="menuitemcheckbox"
                    onHeSelected={() =>
                      {
                        dispatch(
                        updateSchedule({
                          ...scheduleObj,
                          startFrom: index === 0 ? "00" : "*",
                        })
                        )
                        dispatch(updateRunJobType(index === 0 ? "immediate" : "later"))}
                    }
                    value={item}
                  >
                    {item}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <td>Cluster</td>
            <td>
              <Select
                value={clusterId}
                slot="action"
                fixed-placement
                placeholder={"Select"}
                name="subkey"
              >
                {clusters[showEnv && clusterEnv ? clusterEnv.toLowerCase() : selectedEnv?.toLowerCase()].map((item) => (
                  <Option
                    key={item.cluster_id}
                    role="menuitemcheckbox"
                    onHeSelected={(e: any) =>
                      dispatch(updateScheduleClusterId(e.target.value))
                    }
                    value={item.cluster_id}
                  >
                    {item.cluster_name}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
          {runJobType === "later" && (
            <tr>
              <td>Time Zone</td>
              <td>
                <Select
                  value={scheduleObj?.timezone_Id || ""}
                  slot="action"
                  fixed-placement
                  placeholder={"Select"}
                  name="subkey"
                  autocomplete="both"
                  freeform
                >
                  {timezonesList.map((item) => (
                    <Option
                      key={item}
                      role="menuitemcheckbox"
                      onHeSelected={(e: any) =>
                        dispatch(
                          updateSchedule({
                            ...scheduleObj,
                            timezone_Id: e.target.value,
                          })
                        )
                      }
                      value={item}
                    >
                      {item}
                    </Option>
                  ))}
                </Select>
              </td>
            </tr>
          )}
          {runJobType === "later" && (
            <tr>
              <td>Recurrence</td>
              <td>
                <ScheduleWorkflowRecurrence scheduleObj={scheduleObj} />
              </td>
            </tr>
          )}

          <tr>
            <td>Max retries</td>
            <td>
              <TextField
                value={scheduleObj?.maxRetries?.toString() || ""}
                placeholder="Enter max retries"
                onHeChange={(e: any) =>
                  dispatch(
                    updateSchedule({
                      ...scheduleObj,
                      maxRetries: e.target.value,
                    })
                  )
                }
              />
            </td>
          </tr>
        </table>
      </>
    );
  }
);
