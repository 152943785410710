import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { PublishMap } from "../../model/workflows/Publish.model";

export interface AttributePanelDataGridRow extends Row {
  cells: {
    field1: string;
  };
}

export const columns: Column[] = [
  {
    field: "field1",
    content: "Field Name",
  },
];

export const getAttributePanelGridRows = (
  attributesList: [string, string][],
  publishMap: PublishMap[],
  selectedAttributes: [string, string][],
  updatedSelections: boolean
): {rows: AttributePanelDataGridRow[], initialSelectedAttributes: [string, string][]} => {
  let index: number = 1;
  const rows: AttributePanelDataGridRow[] = []
  let initialSelectedAttributes: [string, string][] = selectedAttributes

  // initialize rows with attribute list or publish map if atrributes from preview are not loaded
  if (attributesList?.length > 0) {
    attributesList.forEach((item: [string, string]) => {
      rows.push({
        id: index++,
        selected: publishMap?.find(
          (map: PublishMap) =>
            map.source === item[0] &&
            !map.destination.includes("Primary") &&
            map.destination !== "PartitionKey" && 
            map.destination != "CreatedDate" && 
            map.destination != "ModifiedDate" && 
            map.destination != "Source.SourceId" && 
            map.destination != "Source.Timestamp" && 
            map.destination != "Primary.Status" && 
            map.destination != "Primary.Family"
        )
          ? true
          : false,
        cells: {
          field1: item[0],
        },
        customData: item,
      })
    });
  }
  
  if (rows.length === 0) {
    publishMap?.forEach((item: PublishMap) => {
      if (
        !item.destination.includes("Primary") &&
        item.destination !== "PartitionKey"
      ) {
        rows.push({
          id: index++,
          selected: true,
          cells: {
            field1: item.source,
          },
          customData: [item.source, item.fieldType],
        })
      }
    })
  }

  // update rows with selected attributes
  if (updatedSelections) {
    rows.forEach((row: AttributePanelDataGridRow) => {
      row.selected = selectedAttributes?.find((item: [string, string]) => item[0] === row.cells.field1) ? true : false
    })
  } else if (!updatedSelections && publishMap?.length > 0) {
    initialSelectedAttributes = publishMap.filter(item => !item.destination.includes("Primary") && item.destination !== "PartitionKey")?.map((item: PublishMap) => [item.source, item.fieldType])
    if (!initialSelectedAttributes || initialSelectedAttributes.length === 0) {
      initialSelectedAttributes = selectedAttributes
    }
  }

  return {rows, initialSelectedAttributes};
};
