import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ScheduleWorkflowBody } from "./ScheduleWorkflowBody";
import { ScheduleWorkflowHeader } from "./ScheduleWorkflowHeader";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getWorkflowById } from "../../../services/GetWorkflows.api";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { FlyInPanel } from "../../../common/HarmonyEnablers";
import "./schedule-workflow-styles.css";
import { ScheduleWorkflowFooter } from "./ScheduleWorkflowFooter";
import React from "react";
import { setShowSchedulePanel } from "../workflow-by-id-redux/WorkflowById.actions.redux";
import { ScheduleWorkflowMessageBar } from "./ScheduleWorkflowMessageBar";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { getAllClusters } from "../../../services/GetCluster.api";

const ScheduleWorkflowC: React.FC<{
  workflowId: string;
  showEnv?: boolean;
}> = React.memo(({ workflowId, showEnv }) => {
  const dispatch = useDispatch<AppDispatch>();
  const workflowStatus = useSelector(
    (state: RootState) => state.workflowById.status
  );
  const showPanel = useSelector(
    (state: RootState) => state.workflowById.showSchedulePanel
  );
  const clusters = useSelector(
    (state: RootState) => state.workflows.clusterList
  );

  // fetch workflow whenever workflow id changes
  useEffect(() => {
    if (
      showPanel &&
      workflowId != "" &&
      workflowStatus !== RequestStatus.loading
    ) {
      dispatch(getWorkflowById(workflowId));
    }
  }, [workflowId, showPanel]);

  useEffect(() => {
    if (!clusters || clusters['dev'].length === 0) {
      dispatch(getAllClusters(null))
    }    
  }, [clusters]);

  // do not close the panel when clicked outside the panel
  const handleRequestClose = (event: any) => {
    if (
      event.target !== event.currentTarget ||
      (event.target === event.currentTarget &&
        event.detail.source === "overlay")
    ) {
      event.preventDefault();
    } else {
      dispatch(setShowSchedulePanel(false));
    }
  };

  return (
    <>
      <ScheduleWorkflowMessageBar />
      <FlyInPanel
        style={{ "--size": "var(--he-panel-size-medium)" }}
        open={showPanel}
        onHeRequestClose={handleRequestClose}
      >
        <ScheduleWorkflowHeader />
        <ScheduleWorkflowBody showEnv={showEnv} />
        <ScheduleWorkflowFooter showEnv={showEnv} />
      </FlyInPanel>
    </>
  );
});
const ScheduleWorkflow = withErrorBoundary("Schedule workflow", React.memo(ScheduleWorkflowC));
export default ScheduleWorkflow;