import { PublishModel } from "../../model/workflows/Publish.model";

export const showPreviewLoader = (
  currentPublishItem: PublishModel | undefined,
  dataIngestionStepIdStatusForAttributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  preProcessingStepIdStatusForAttributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  postProcessingStepIdStatusForAttributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  dataIngestionStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  preProcessingStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  postProcessingStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  }
) => {
  const hasDataIngestionStepLoading = Object.keys(dataIngestionStepIdStatusForAttributes)?.some(
    (id) => dataIngestionStepIdStatusForAttributes[id]?.loading
  ) || Object.keys(dataIngestionStepIdStatusForTable)?.some(
    (id) => dataIngestionStepIdStatusForTable[id]?.loading
  );
  const hasPreProcessingStepLoading =  Object.keys(preProcessingStepIdStatusForAttributes)?.some(
    (id) => preProcessingStepIdStatusForAttributes[id]?.loading
  ) || Object.keys(preProcessingStepIdStatusForTable)?.some(
    (id) => preProcessingStepIdStatusForTable[id]?.loading
  );
  const hasPostProcessingStepLoading = Object.keys(postProcessingStepIdStatusForAttributes)?.some(
    (id) => postProcessingStepIdStatusForAttributes[id]?.loading
  ) || Object.keys(postProcessingStepIdStatusForTable)?.some(
    (id) => postProcessingStepIdStatusForTable[id]?.loading  
  );

  if (
    (!currentPublishItem || !currentPublishItem.inputViewName) &&
    (hasDataIngestionStepLoading ||
      hasPreProcessingStepLoading ||
      hasPostProcessingStepLoading)
  ) {
    return true;
  }

  if (currentPublishItem && currentPublishItem.inputViewName) {
    const viewName = currentPublishItem.inputViewName;
    if (
      postProcessingStepIdStatusForAttributes[viewName]?.loading ||
      preProcessingStepIdStatusForAttributes[viewName]?.loading ||
      dataIngestionStepIdStatusForAttributes[viewName]?.loading ||
      postProcessingStepIdStatusForTable[viewName]?.loading ||
      preProcessingStepIdStatusForTable[viewName]?.loading ||
      dataIngestionStepIdStatusForTable[viewName]?.loading
    ) {
      return true;
    }
  }

  return false;
};

export const showPreviewError = (
  currentPublishItem: PublishModel | undefined,
  dataIngestionStepIdStatusForAttributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  preProcessingStepIdStatusForAttributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  postProcessingStepIdStatusForAttributes: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  dataIngestionStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  preProcessingStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  },
  postProcessingStepIdStatusForTable: {
    [stepId: string]: { loading: boolean; error: string | undefined };
  }
) => {
  

  if (!currentPublishItem || !currentPublishItem.inputViewName) {
    return undefined;
  }

  if (currentPublishItem && currentPublishItem.inputViewName) {
    const viewName = currentPublishItem.inputViewName;
    return postProcessingStepIdStatusForAttributes[viewName]?.error ||
      preProcessingStepIdStatusForAttributes[viewName]?.error ||
      dataIngestionStepIdStatusForAttributes[viewName]?.error ||
      postProcessingStepIdStatusForTable[viewName]?.error ||
      preProcessingStepIdStatusForTable[viewName]?.error ||
      dataIngestionStepIdStatusForTable[viewName]?.error    
  }

  return undefined;
};
