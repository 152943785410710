import { useEffect, useState } from 'react';
import { Select, Option, TextField, Radio, RadioGroup, DataGridView, Checkbox, Icon, Button, Tooltip } from '../../common/HarmonyEnablers';

import { useDispatch, useSelector } from 'react-redux';
import { Workflow, WorkflowSummary } from '../../model/workflows/Workflow.model';
import { updateTypeOfMerge } from '../workflows/workflow-by-id-redux/WorkflowById.actions.redux';
import { AppDispatch, RootState } from '../../root-redux/RootState';
import { SuccessNotification } from '../notifications/SuccessNotification';
import { getMergeRuleById, getMergeRuleByModel, getMergeRuleBySubKey } from '../../services/get-merge-rules/GetMergeRules.api';
import { DeleteConfirmation } from './popups/DeleteConfirmationPopup';
import { CheckPageAccessSelector } from '../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux';
import { getModelMapping } from '../../services/GetModelMapping.api';
import { CreateMergeRuleConfirmation } from './popups/CreateMergeRuleConfirmation';
import { getWorkflows, getWorkflowsBySubKeys } from '../../services/GetWorkflows.api';
import { MergeRule } from '../../model/merge-rules/MergeRule.model';
import { ErrorNotification } from '../notifications/ErrorNotification';
import { MergeType } from './MergeType';
import { getAllUserObjectsOfMergerules } from '../../services/GetUserObjectsOfMergeRules.api';

export const Merge = () => {
  const dispatch = useDispatch<AppDispatch>()
  const workflow = useSelector((state: RootState): Workflow => state.workflowById.workflow);
  const workflowsBySubKey = useSelector((state: RootState): WorkflowSummary[] => state.workflowsBySubKeys.list);
  const mergeRulesBySubKey = useSelector((state: RootState): MergeRule[] => state.mergeRuleBySubKey.list);
  const modelMappingConfig = useSelector((state: RootState) => state.modelMappingConfig.modelMappingObject)
  const selectedEnvItem = useSelector((state: RootState) => state.env.selectedEnvItem);

  const [currentMergeType, setcurrentMergeType] = useState(workflow.typeOfMerge || "SkipMerge");
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false)
  const [openTypeChangePopup, setOpenTypeChangePopup] = useState(false)
  var [modelName, setModelName] = useState("")//MRTODO || workflow.config.workflowName
  const [modelId, setModelId] = useState("")
  var [mergeRuleName, setMergeRuleName] = useState("")
  const [successText, setSuccessText] = useState("");
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [showErrorNotif, setShowErrorNotif] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [nextTarget, setNextTarget] = useState('')
  const mergeRuleAdded = useSelector((state: RootState) => state.mergeRuleByModel.mergeRule);
  const mergeRuleAddedList = useSelector((state: RootState) => state.mergeRuleByModel.list);
  const access = CheckPageAccessSelector(workflow.subscriptionKey)

  useEffect(() => {
    scrollToTop()
    if(workflow.subscriptionKey) {
      dispatch(getMergeRuleBySubKey(workflow.subscriptionKey))
      dispatch(getWorkflowsBySubKeys({subKeys: workflow.subscriptionKey}))
    }
  }, [workflow.subscriptionKey, dispatch])

  useEffect(() => {
    if(workflow.subscriptionKey) {
      dispatch(getMergeRuleBySubKey(workflow.subscriptionKey))
      dispatch(getWorkflowsBySubKeys({subKeys: workflow.subscriptionKey}))
    }
  }, [selectedEnvItem, dispatch])

  useEffect(() => {
    if(mergeRulesBySubKey.length > 0) {
      dispatch(getAllUserObjectsOfMergerules(mergeRulesBySubKey))
    }
  },[mergeRulesBySubKey, dispatch])

  useEffect(() => {
    dispatch(updateTypeOfMerge(currentMergeType))
  }, [currentMergeType])

  useEffect(() => {
    var localModelName = ""
    var localModelId = ""
    var localWorkflow = workflowsBySubKey.find(x => x.config.workflowId === workflow.config.workflowId)
    if (localWorkflow) {
      localModelName = localWorkflow.modelName || ""
    }
    if (localModelName && localModelName !== modelName) {
      setModelName(localModelName)
      setMergeRuleName("Default" + localModelName + "MR")
    } else {
      var cosmosPublish = workflow.publish.find(x => { return x.type === "COSMOS" })
      if (cosmosPublish) {
        localModelId = cosmosPublish.modelId
        if (localModelId && modelId !== localModelId) {
          setModelId(localModelId)
        }
      }
    }
  }, [workflowsBySubKey, workflow])

  useEffect(() => {
    if (!modelName && modelId) {
      dispatch(getModelMapping({ name: "", id: modelId }))
    }
  }, [modelId])

  useEffect(() => {
    if (!modelName && modelMappingConfig && modelId && Object.keys(modelMappingConfig).length > 0) {
      var localModel = modelMappingConfig.find(x => { return x.id === modelId })
      if (localModel) {
        setModelName(localModel.name)
        setMergeRuleName("Default" + localModel.name + "MR")
      }
    }
  }, [modelMappingConfig])

  useEffect(() => {
    if (modelName) {
      dispatch(getMergeRuleByModel(modelName))
    }
  }, [modelName])

  useEffect(() => {
    if (mergeRuleName) {
      dispatch(getMergeRuleById(mergeRuleName))
    }
  }, [mergeRuleName])

  const callSkipMerge = () => {
    if (currentMergeType === "Default" && mergeRule.type) {
      setOpenDeletePopUp(true)
      setNextTarget("SkipMerge")
    } else if (currentMergeType === "NewUpdate" && mergeRuleAdded.type) {
      setOpenDeletePopUp(true)
      setNextTarget("SkipMerge")
    } else {
      setcurrentMergeType("SkipMerge")
    }
  }

  const callNewUpdateMerge = () => {
    if (currentMergeType === "Default" && mergeRule.type) {
      setOpenDeletePopUp(true)
      setNextTarget("NewUpdate")
    } else {
      setcurrentMergeType("NewUpdate")
    }
  }
  const callDefaultMerge = () => {
    var isMergeModelAddedOtherThanDefault = false
    var isDefaultMergeRulePresent = false
    if(mergeRuleAddedList.length > 0) {
      var mergeRuleOtherThanDefault = mergeRuleAddedList.filter(x => {return x.mergeRuleId && x.mergeRuleId !== mergeRuleName && x.type})
      var defaultMergeRuleObject = mergeRuleAddedList.filter(x => {return x.mergeRuleId && x.mergeRuleId === mergeRuleName && x.type})
      isMergeModelAddedOtherThanDefault = mergeRuleOtherThanDefault && mergeRuleOtherThanDefault.length > 0
      isDefaultMergeRulePresent = defaultMergeRuleObject && defaultMergeRuleObject.length > 0
    }
    if (currentMergeType === "NewUpdate" && isMergeModelAddedOtherThanDefault && !isDefaultMergeRulePresent) {
      setNextTarget("Default")
      setOpenTypeChangePopup(true)
    } else {
      setcurrentMergeType("Default")
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

  return (
    <>
    <div className='mergerule-form-table' style={{paddingTop:"3.5em"}}>
      <RadioGroup value={currentMergeType}>
            <Radio disabled={!access.canEdit} value='SkipMerge' onHeSelected={() => {callSkipMerge()}}><b>Skip Merge: </b>
              No output Recommendations are generated. The data published as part of this {workflow.workFlowType || "Insight/Recommendation"} remains as is.
            </Radio>

          {workflow.workFlowType === "Recommendation" &&
              <Radio disabled={!access.canEdit} value='Default' onHeSelected={() => {callDefaultMerge()}}><b>Default Merge: </b>
              Frames new merge rule which merges data from current {workflow.workFlowType || "Insight/Recommendation"} using information you input in previous stages(no merge with other Insights/Recommedations). Click on <b>Create Rule</b> button to create the merge rule. You can edit the configuration once created
              </Radio>
            }
            <Radio disabled={!access.canEdit} value='NewUpdate' onHeSelected={() => {callNewUpdateMerge()}}><b>Custom Merge: </b>
                Create new merge rule or update existing ones to merge data from current {workflow.workFlowType || "Insight/Recommendation"} with other Insights/Recommendations.
            </Radio>
      </RadioGroup>
      <MergeType />
      <SuccessNotification successNotif={showSuccessNotif} setsuccesnotif={setShowSuccessNotif} successText={successText} />
      <ErrorNotification errorNotif={showErrorNotif} seterrornotif={setShowErrorNotif} errorText={errorText} />

      {
        openDeletePopUp &&
        <DeleteConfirmation nextTarget={nextTarget} setNextTarget={setNextTarget} setSuccessText={setSuccessText} setsuccessNotif={setShowSuccessNotif} closePopup={setOpenDeletePopUp} mergeRuleName={mergeRuleName} currentMergeType={currentMergeType} setcurrentMergeType={setcurrentMergeType} seterrornotif={setShowErrorNotif} seterrorText={setErrorText}
        />
      }
      {
        openTypeChangePopup &&
        <CreateMergeRuleConfirmation typeChange={"yes"} currentMergeType={currentMergeType} nextTarget={nextTarget} setcurrentMergeType={setcurrentMergeType} setOpenTypeChangePopup={setOpenTypeChangePopup} />
      }
      </div>
    </>
  );
}
