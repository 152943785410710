export class JobCluster
{
    public id!: string;
    public jobClusterKey!: string
    public newCluster!: NewClusterConfig
}

export class NewClusterConfig
{
    public clusterName!: string;
    public clusterId!: string;
    public sparkVersion!: string;
    public nodeTypeId!: string;
    public sparkConf!: { [key: string]: boolean };
    public availability!: { [key: string]: string };
    public autoScaleVal!: AutoScale
    public numWorkers!: number
}

export class AutoScale
{
    public minWorkers!: number
    public maxWorkers!: number
}