import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { getMergeRules } from '../../../services/get-merge-rules/GetMergeRules.api'
import { MergeRule } from '../../../model/merge-rules/MergeRule.model'
import { postMergeRule } from '../../../services/get-merge-rules/PostMergeRule.api'
import { patchMergeRule } from '../../../services/get-merge-rules/PatchMergeRule.api'
export interface IKeyValueCheckedState {[key: string] : boolean};

interface MergeRuleState {
  list: MergeRule[],
  status: RequestStatus,
  error: string | undefined,
  tenantcheckedState: IKeyValueCheckedState,
  usercheckedState: IKeyValueCheckedState,
  familycheckedState: IKeyValueCheckedState,
  workflowcheckedState: IKeyValueCheckedState,
  searchText: string
}

const initialState: MergeRuleState = {
  list: [],
  status: RequestStatus.idle,
  error: '',
  tenantcheckedState: {},
  usercheckedState: {},
  familycheckedState: {},
  workflowcheckedState: {},
  searchText: ""
}

export const mergeRulesSlice = createSlice({
  name: 'mergerules',
  initialState,
  reducers: {
    setTenantCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.tenantcheckedState = action.payload;
    },
    setUserCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.usercheckedState = action.payload;
    },
    setFamilyCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.familycheckedState = action.payload;
    },
    setWorkflowCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.workflowcheckedState = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    addMergeRule: (state, action) => {
      const mergerule = action.payload
      state.list = [...state.list, mergerule]
    },
    editMergeRule: (state, action) => {
      const mergerule = action.payload
      var mergeruleList = [...state.list]
      var isPresent = mergeruleList.find(x => x.mergeRuleId === mergerule.mergeRuleId)
      if (isPresent) {
        var index = mergeruleList.indexOf(isPresent)
        mergeruleList[index] = mergerule
        state.list = [...mergeruleList]
      }
    },
    removeMergeRule: (state, action) => {
      const mergeruleid = action.payload
      state.list = state.list.filter((item) =>
        item.mergeRuleId !== mergeruleid
      )
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getMergeRules.pending, (state, action) => {
        state.status = RequestStatus.loading
      })
      .addCase(getMergeRules.fulfilled, (state, action) => {
        if(action.payload.data instanceof Array) {
          state.status = RequestStatus.succeeded
          state.list = action.payload.data
          state.error = undefined
        } else {
          state.status = RequestStatus.failed
          state.list = []
          state.error = undefined
        }
      })
      .addCase(getMergeRules.rejected, (state, action) => {
        state.status = RequestStatus.failed
        state.error = action.error.message
      })
  }
})

export const {setTenantCheckedState, setFamilyCheckedState, setUserCheckedState,setWorkflowCheckedState,setSearchText, editMergeRule, removeMergeRule, addMergeRule } = mergeRulesSlice.actions

export const mergeRulesReducer = mergeRulesSlice.reducer