import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useSelector } from "react-redux";
import { Checkbox, Icon, FlyInPanel, Tooltip, Radio, RadioGroup, Select, Option } from "../../common/HarmonyEnablers";
import { DataGrid, Button, SearchBox, HoverCard, Dropdown, MenuItem, Menu } from '../merge-rules/MergeRulesScope'
import { updateCurrentMergeRuleSet, updateEnableSaveMR } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { ModelListDataGridRow, addedModelsColumns, addedModelsItemSelectorData, isArraySame } from './MergeRuleSetModelsColumns'
import { ModelDataGridRow, modelColumns, modelsItemSelectorByData } from "./mergerule-panel/ModelListColumns";
import { FieldListDataGridRow, allFieldsItemSelectorData, fieldsListColumns } from "./mergerule-panel/FieldListColumns";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { PriorityFieldListDataGridRow, addColumnsToWorkflows, allPriorityFieldsItemSelectorData, priorityFieldsListColumns } from "./mergerule-panel/PriorityFieldListColumns";
import { AddedPriorityFieldsDataGridRow, addedPriorityFieldColumns, addedPriorityFieldsItemSelectorData } from "./MergeRuleSetPriorityFieldsColumns";
import { FieldOrder } from "../../model/merge-rules/PriorityFieldsToMerge.model";


const MergeRuleSetPriorityFieldsC: React.FC<any> = (props: any) => { 

  const dispatch = useDispatch<AppDispatch>()
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey);
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);

  const [openPriorityFieldsPanel, setOpenPriorityFieldsPanel] = useState(false);
  const [priorityFieldsListRow, setPriorityFieldsListRow] = useState([] as PriorityFieldListDataGridRow[]);
  const [addedFields, setAddedFields] = useState([] as AddedPriorityFieldsDataGridRow[])
  const [selectedFields, setSelectedFields] = useState([] as string[])
  const [allselectedFields, setAllSelectedFields] = useState([] as string[])
  const [descOrderFields, setDescOrderFields] = useState([] as string[])
  const [searchText, setSearchText] = useState("")
  const [totalFieldCount, setTotalFieldCount] = useState(0);


  useEffect(() => {
    if (searchText) {
      setAllSelectedFields(selectedFields)
      var finalRows: PriorityFieldListDataGridRow[] = []
      priorityFieldsListRow.map((row: PriorityFieldListDataGridRow) => {
        if (row.cells?.field1?.toLowerCase().includes(searchText) || row.cells?.field2?.toLowerCase().includes(searchText)) {
          finalRows.push(row)
        }
      })
      setPriorityFieldsListRow(finalRows)
    } else {
      var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })
      var all = allselectedFields.filter(x => !selectedFields.includes(x));
      var join = selectedFields.concat(all)
      join = join.filter((v, i, a) => a.indexOf(v) == i)
      setSelectedFields(join);
      setPriorityFieldsListRow(allPriorityFieldsItemSelectorData(workflows, currentMergeRuleSet,mergeRule, selectedFields))
    }
  }, [searchText, currentMergeRuleSet?.useMRLevelConfigToPrioritizePredictions])

  useEffect(() => {
    setAddedFields(addedPriorityFieldsItemSelectorData(currentMergeRuleSet))
  }, [currentMergeRuleSet.prioritizePredictionsOnField])

  useEffect(() => {
    if (openPriorityFieldsPanel) {
      var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })
      var items = allPriorityFieldsItemSelectorData(workflows, currentMergeRuleSet,mergeRule, selectedFields)
      setPriorityFieldsListRow(items)
      setTotalFieldCount(items.length)

      var oldPriorityFieldsUnSplit = currentMergeRuleSet?.prioritizePredictionsOnField;
      var oldFieldData : FieldOrder[] = [];
      
      oldPriorityFieldsUnSplit?.map(x => {
        var temp = x.split(" ");
        var newField = new FieldOrder()
        newField.field = temp[0];
        newField.order = temp[2];
        oldFieldData.push(newField);
      });

      var descOrderFields = oldFieldData.filter(x => x.order === "DESC").map(x => x.field);
      setDescOrderFields(descOrderFields);
      
  }
}, [openPriorityFieldsPanel, currentMergeRuleSet?.useMRLevelConfigToPrioritizePredictions])

useEffect(() => {
  var oldPriorityFieldsUnSplit = currentMergeRuleSet?.prioritizePredictionsOnField;
      var oldFieldData : FieldOrder[] = [];
      
      oldPriorityFieldsUnSplit?.map(x => {
        var temp = x.split(" ");
        var newField = new FieldOrder()
        newField.field = temp[0];
        newField.order = temp[2];
        oldFieldData.push(newField);
      });

      var descOrderFields = oldFieldData.filter(x => x.order === "DESC").map(x => x.field);
      setDescOrderFields(descOrderFields);
      console.log("current mrs user mr level config", currentMergeRuleSet.useMRLevelConfigToPrioritizePredictions.toString())
}, [])

  const onRowSelectFields = (e: any) => {

    var fields = [] as string[]
    for (var i = 0; i < priorityFieldsListRow.length; i++) {
      if (priorityFieldsListRow[i].selected) {
        fields.push(priorityFieldsListRow[i].cells.field1)
      }
    }
    if(priorityFieldsListRow.length !== totalFieldCount) {
      var allFields = priorityFieldsListRow.map(x => x.cells.field1)
      var all = allselectedFields.filter(x => !allFields.includes(x));
      var join = fields.concat(all)
      join = join.filter((v, i, a) => a.indexOf(v) == i)
      setSelectedFields(join)
      var fieldsFromListPartOfAllSelectedFields = allFields.filter(x => allselectedFields.includes(x))
      var fieldsThatShouldntBeSelectedAnymore = fieldsFromListPartOfAllSelectedFields.filter(x => !fields.includes(x))
      var allselectedFieldsUpdated = allselectedFields.filter(x => !fieldsThatShouldntBeSelectedAnymore.includes(x))
      setAllSelectedFields(allselectedFieldsUpdated)
    } else {
      setSelectedFields(fields)
      setAllSelectedFields(fields)
    }
  }

  const onOrderChange = (e: any, row: PriorityFieldListDataGridRow) => {
    if(e.target.value === "DESC" && !descOrderFields.includes(row.cells.field1))
    {
      setDescOrderFields([...descOrderFields, row.cells.field1])
    }
    else if(e.target.value === "ASC" && descOrderFields.includes(row.cells.field1))
    {
      setDescOrderFields(descOrderFields.filter(x => x !== row.cells.field1))
    }
}

  const handlePriorityFieldRequestClose = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenPriorityFieldsPanel(false)
      setSelectedFields([])
      setSelectedFields([])
    }
  }

  const handleMRLevelPriorityFieldFlagChange = (e: any) => {
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, useMRLevelConfigToPrioritizePredictions: e.target.value }))
   }

   const checkIfFieldsAreDifferent = () => {
    if(currentMergeRuleSet.prioritizePredictionsOnField && currentMergeRuleSet.prioritizePredictionsOnField.length){
      var unsetdata = currentMergeRuleSet.prioritizePredictionsOnField;
      var data : string[] = [];
      unsetdata.map(x => {
        var temp = x.split(" ");
        data.push(temp[0]);
      })
      return isArraySame(data, selectedFields);
    } else{
      return selectedFields.length <= 0
    }
  }

  const onCancelFieldsClick = () => {
    setOpenPriorityFieldsPanel(false)
    setSelectedFields([])
    setAllSelectedFields([])
  }

  const onAddFieldsClick = () => {
    var localFieldsUnSplit = currentMergeRuleSet?.prioritizePredictionsOnField;
    var localFields : string[] = [];
    localFieldsUnSplit?.map(x => {
      var temp = x.split(" ");
      localFields.push(temp[0]);
    })
    var fieldsRetainedInOrder = localFields.filter(x => { return selectedFields?.includes(x) })
    var fieldsNewlyAdded = selectedFields.filter(x => { return !localFields?.includes(x) })
    var finalFields = fieldsRetainedInOrder.concat(fieldsNewlyAdded)

    var finalPriorityFields : string[] = [];
    var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet?.mergeModel?.includes(x.modelName) });

    workflows = addColumnsToWorkflows(workflows);

    var allWorkflowsFields :string[] = []
    workflows.forEach(eachworkflow => {
      allWorkflowsFields = allWorkflowsFields.concat(Object.keys(eachworkflow.fields))
    });
    var allWorkflowsFieldsAndTypes = [] as any[];
    workflows.forEach(eachworkflow => {
      if (eachworkflow.fields && Object.keys(eachworkflow.fields).length > 0) {
        Object.keys(eachworkflow.fields).map((item) => {
          allWorkflowsFieldsAndTypes.push({name : item, type : eachworkflow.fields[item]})
        })
      }
    })

    finalFields.forEach(eachField => {
      if(allWorkflowsFields.includes(eachField)){
        var fieldName = eachField;
        var fieldType = allWorkflowsFieldsAndTypes.find(x => x.name === eachField).type;
        var order = descOrderFields.includes(eachField) ? "DESC" : "ASC"
        finalPriorityFields.push(fieldName + " " + fieldType + " " + order)
      }
    });

    var globalFields = mergeRule?.prioritizePredictionsOnField;
    finalPriorityFields = finalPriorityFields.filter(x => { return !globalFields?.includes(x) });

    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, prioritizePredictionsOnField: finalPriorityFields }))
    setOpenPriorityFieldsPanel(false)
    setSelectedFields([])
    setAllSelectedFields([])
  }

  const handleDeleteFields = (row: any) => {
    var localFieldsUnSplit = currentMergeRuleSet?.prioritizePredictionsOnField;
    var fieldsRetainedInOrder = localFieldsUnSplit.filter(x => { return !x.includes(row.cells.field1) })

    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, prioritizePredictionsOnField: fieldsRetainedInOrder }))
  
  }

  return (
    <>
    
    <div>
    {
          openPriorityFieldsPanel &&
          <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)', marginBottom: "100px" }}
            heading="Select Fields to Prioritize Predictions"
            open={openPriorityFieldsPanel} onHeRequestClose={handlePriorityFieldRequestClose}>
            <div style={{ marginBottom: "10px", fontSize: "15px" }}>Below is the list of Fields available to choose to prioritize a prediction from duplicate predictions to form the base for the resulting recommendations on</div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
              <SearchBox style={{ width: "300px", marginLeft: "0px" }} placeholder="Search Field/Source Name" onInput={(e: any) => { setSearchText(e.target.value) }} onHeClear={() => { setSearchText("") }}></SearchBox>
            </div>
            <DataGrid fixedHeading={true}
              rows={priorityFieldsListRow} columns={priorityFieldsListColumns} select="multiple" onHeRowSelectChange={(e) => { onRowSelectFields(e.detail) }}>
              <span slot="no-records">No fields found. </span>
              {priorityFieldsListRow.map(row => {
                return <>

                <span slot={`field3-${row.id}`} key={`field3-${row.id}`}>
                  <Select label="" value = {descOrderFields.includes(row.cells.field1) ? "DESC" : "ASC"} style={{ width: '140px' }}>
                    <Option value = "ASC" onHeSelected={(e: any) =>
                                      onOrderChange(e, row)
                                    }>Ascending</Option>
                    <Option value = "DESC" onHeSelected={(e: any) =>
                                      onOrderChange(e, row)
                                    }>Descending</Option>
                  </Select>
                </span>

                  <HoverCard slot={`field2-${row.id}`} key={`field2-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                    <div slot="anchor" role="button" key={`field2-${row.id}`}>{row.cells.field2 === null || row.cells.field2 === '' ? '' : row.cells.field2 != null && row.cells.field2.length > 30 ? row.cells.field2.substring(0, 30) + "..." : row.cells.field2}</div>
                    {row.cells.field2 &&
                      <code style={{ color: "black" }}>{row.cells.field2.split(",").map(eachRow => {
                        return <div>{eachRow}</div>
                      })}
                      </code>
                    }
                    {!row.cells.field2 &&
                      <code style={{ color: "black" }}>
                      </code>
                    }
                  </HoverCard>

                </>
              })}
            </DataGrid>
            <div className="medium-flyinpanel-footer">
              <Button type='button' appearance='primary' disabled={(checkIfFieldsAreDifferent()) || selectedFields.length === 0} onClick={onAddFieldsClick} >Add</Button>
              <span style={{ marginLeft: '20px' }}></span>
              {(<Button type='button' appearance='secondary' onClick={onCancelFieldsClick}>Cancel</Button>)}
              <span style={{ marginLeft: '20px' }}></span>
            </div>
          </FlyInPanel>
        }

        
    <div style={{ display: "flex" , marginTop:"24px", marginBottom:"14px"}} >
        <div style={{ display: "inline-block", lineHeight:"36px", marginRight:"20px" }} >
          <span>Use Merge Rule level Prioritizing fields for predictions</span>
          <Icon id="info404945" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
        </div>
        <div style={{ display: "inline-block" }} >
          <RadioGroup  name="selectType" value = {currentMergeRuleSet.useMRLevelConfigToPrioritizePredictions.toString()} >
            <div style={{display: "flex"}}>
            <Radio disabled={!access.canEdit} style={{ marginRight: "20px" }} value="true" onHeSelected={handleMRLevelPriorityFieldFlagChange}>True
              <Icon id="info1111" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
              <Tooltip anchor="info1111" fixedPlacement={true} placement='top'>
              True will take into account the merge rule level (global) priority fields for prioritizing the predictions along with merge rule set level ones
              </Tooltip>
            </Radio>
            <Radio disabled={!access.canEdit}  value="false" onHeSelected={handleMRLevelPriorityFieldFlagChange}>False
              <Icon id="info2222" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
              <Tooltip anchor="info2222" fixedPlacement={true} placement='top'>
              If false, it will take into account only the merge rule set level priority fields for prioritizing the predictions.
              </Tooltip>
            </Radio>
            </div>
          </RadioGroup>
        </div>
        </div>

        <div style = {{display:"flex", alignItems:"center"}}>
        <div style = {{marginRight:"20px"}}>
        <span>Fields for prioritizing prediction </span>
          <Icon id="info404922" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
          <Tooltip anchor="info404922" fixedPlacement={true} placement='bottom'>
              Select fields which will be used to prioritize one prediction chosen as the base for resulting recommendation from multiple predictions.
          </Tooltip>
        </div>
        

        <Button appearance="command" style={{ fontSize: "14px" }} disabled={!access.canEdit} onClick={() => { setOpenPriorityFieldsPanel(true) }}>
            <Icon slot="start" name="add"></Icon>
            Add Fields
        </Button>
        </div>

       {
        addedFields.length > 0 &&

        <DataGrid fixedHeading={true}
        style={{ marginBottom: "10px", width: "25%" }} rows={addedFields} columns={addedPriorityFieldColumns} >
        <span slot="no-records">No Priority Fields added yet.</span>
        {addedFields.map(row => {
          return (
            <>

              <span slot={`field2-${row.id}`} key={`field2-${row.id}`}>
                {descOrderFields.includes(row.cells.field1) ? "Descending" : "Ascending"}
              </span>

              <span slot={`field3-${row.id}`} key={`field3-${row.id}`}>
                <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={() => handleDeleteFields(row)} >
                  <Icon label="delete rule" slot="start" name="delete"></Icon>
                </Button>
              </span>
            </>
          )
        })}
      </DataGrid>
       }

    </div>
    
    </>

  )
}


export const MergeRuleSetPriorityFields = withErrorBoundary("Merge rule set Priority Fields", MergeRuleSetPriorityFieldsC);
