import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../../model/ServiceRequestStatus.model'
import { MergeRule, emptyMergeRule } from '../../../model/merge-rules/MergeRule.model'
import { getMergeRuleById, getMergeRuleByModel, getMergeRuleBySubKey } from '../../../services/get-merge-rules/GetMergeRules.api'

interface MergeRuleBySubKeyState {
    list: MergeRule[],
    status: RequestStatus,
    error: string | undefined
}

const initialState: MergeRuleBySubKeyState = {
    list: [] as MergeRule[],
    status: RequestStatus.idle,
    error: ''
}
export const mergeRuleBySubKeySlice = createSlice({
    name: 'mergeRuleBySubKey',
    initialState,
    reducers: {
        resetMergeRuleBySubKey: (state) => {
            state.list = [] as MergeRule[]
            state.status = RequestStatus.idle
            state.error = ''
        },
        addMergeRuleBySubKey: (state, action) => {
            const mergerule = action.payload
            state.list = [...state.list, mergerule]
        },
        editMergeRuleBySubKey: (state, action) => {
            const mergerule = action.payload
            var mergeruleList = [...state.list]
            var isPresent = mergeruleList.find(x => x.mergeRuleId === mergerule.mergeRuleId)
            if (isPresent) {
                var index = mergeruleList.indexOf(isPresent)
                mergeruleList[index] = mergerule
                state.list = [...mergeruleList]
            }
        },
        removeMergeRuleBySubKey: (state, action) => {
            const mergeruleid = action.payload
            state.list = state.list.filter((item) =>
                item.mergeRuleId !== mergeruleid
            )
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getMergeRuleBySubKey.pending, (state, action) => {
                state.status = RequestStatus.loading
            })
            .addCase(getMergeRuleBySubKey.fulfilled, (state, action) => {
                if (action.payload.data instanceof Array) {
                    state.status = RequestStatus.succeeded
                    state.list = action.payload.data
                    state.error = undefined
                } else {
                    state.status = RequestStatus.failed
                    state.list = []
                    state.error = undefined
                }
            })
            .addCase(getMergeRuleBySubKey.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
    }
})

export const { addMergeRuleBySubKey, editMergeRuleBySubKey, removeMergeRuleBySubKey, resetMergeRuleBySubKey } = mergeRuleBySubKeySlice.actions

export const mergeRuleBySubKeyReducer = mergeRuleBySubKeySlice.reducer