import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { CardComponent_Interactive } from "../../../shared-components/card-component/Card-Interactive";
import { CardComponent_Interactive_Bigger } from "../../../shared-components/card-component/Card-Interactive-Bigger";
import { allLocalLinks } from "../../../shared-content/AllLinks";

function OnboardingTutorialsc() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CardComponent_Interactive_Bigger
          title="Onboard an Insight"
          subtitle=""
          titlelink={allLocalLinks.home_onboardinsight}
          imageSrc="../assets/icons/insightimage7.png"
          uniqueId = "onboardInsight"
        />

        <CardComponent_Interactive_Bigger
          title="Onboard a Recommendation"
          subtitle=""
          titlelink={allLocalLinks.home_onboardrec}
          imageSrc="../assets/icons/onboardRec.png"
          uniqueId = "onboardRecommendation"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <CardComponent_Interactive
          title="What is Nebula?"
          subtitle="Nebula is a Recommendation & Insights platform built to facilitate extensive intelligence curation throughout sales journeys. It streamlines the process of curating, combining, aggregating, storing, organizing, and consuming signals, benefiting both model and experience owners. Nebula offers a dependable and scalable platform, allowing model and experience owners to concentrate on delivering superior signals without worrying about building complex logic,  infrastructure availability, reliability, or scalability."
          titlelink={allLocalLinks.home_whatisnebula}
          imageSrc="../assets/icons/getstarted2.png"
          uniqueId = "whatisnebula"
        />

        <CardComponent_Interactive
          title="Empowering Efficiency"
          subtitle="Welcome to Nebula's enhanced self-serve excellence! Our journey of improvement has led us to a refined self-serve platform that puts your needs front and center. We've heard your feedback, and we're thrilled to unveil a revamped self-serve platform that addresses the pain points of the past. Say goodbye to complexity and waiting times – Nebula's new self-serve is all about efficiency and empowerment."
          titlelink={allLocalLinks.home_empoweringefficiency}
          imageSrc="../assets/icons/improvedexpnebula5.png"
          uniqueId = "empoweringefficiency"
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <CardComponent_Interactive
          title="Get Started"
          subtitle="This document will guide you through the step-by-step process to start using Nebula's Recommendation & Insights platform effectively."
          titlelink={allLocalLinks.home_getstarted}
          imageSrc="../assets/icons/getstartednebula.png"
          uniqueId = "getstartedwithnebula"
        />

        <CardComponent_Interactive
          title="In Context Help"
          subtitle="Providing in contextual help to enable smooth and faster onboarding."
          titlelink={allLocalLinks.home_incontexthelp}
          imageSrc="../assets/icons/helpnebula.png"
          uniqueId = "incontexthelp"
        />
      </div>
    </div>
  );
}
export const OnboardingTutorials = withErrorBoundary(
  "OnboardingTutorials",
  OnboardingTutorialsc
);
