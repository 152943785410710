import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "./utils/GetRequest"
import { PersonaMapping } from "../model/personaMapping/PersonaMapping.Model"
import { RootState } from "../root-redux/RootState"

  export const getPersonaMapping = createAsyncThunk<PersonaMapping[],{ persona:string, tenantName:string, type:string },{state: RootState}>('typemapping/getPersonaMapping', async ({persona, tenantName, type }, {getState}) => {
    const selectedEnv = getState().env.selectedEnvItem
    
    const response = await getRequest<PersonaMapping[]>(`${selectedEnv}/personaconfigmapping?persona=${persona}&tenantName=${tenantName}&type=${type}`)
    return response.data
  })