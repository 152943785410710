import { useDispatch, useSelector } from "react-redux";
import { Select, Option, Button, Tabs, Tab, TabPanel, Tooltip } from "../../../common/HarmonyEnablers";
import { BlobConnection } from "../../../model/connections/BlobConnection.model";
import { Icon, TextField } from "../ConnectionScope";
import "../connections.css"
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { postConnections } from "../../../services/PostConnections.api";
import { ConnectionType } from "../../../model/connections/ConnectionType.model";
import { useEffect, useState } from "react";
import { ApiConnection } from "../../../model/connections/ApiConnection.model";
import { IUpdatedCellValue, TextFieldTable } from "../../../shared-components/textfield-table/TextFieldTable";
import { TeamsList } from "../../../model/role-based-access-control/RoleBasedAcessControl.model";
import { AppPersona } from "../../../model/app-persona/AppPersona.model";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { Connection } from "../../../model/connections/Connection.model";


const ApiConnectionPanelC: React.FC<{connectionData: ApiConnection, editMode: boolean, appScopes: string[] | undefined, connectionType: ConnectionType, subscriptions: {key: string, name: string}[], authType:string}> = ({connectionData, editMode, appScopes, connectionType, subscriptions, authType}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [data, setData] = useState<ApiConnection>(connectionData);
    const selectedEnv =  useSelector((state: RootState) => state.env.selectedEnvItem);

    const adbScopeDev = "https://adb-5404863101054673.13.azuredatabricks.net/?o=5404863101054673#secrets/createScope";
    const adbScopUat = "https://adb-2987319840690271.11.azuredatabricks.net/?o=2987319840690271#secrets/createScope";
    const adbScopProd = "https://adb-5011763184037517.17.azuredatabricks.net/?o=5011763184037517#secrets/createScope";


    useEffect(() => {
        setData(connectionData)
    }, [connectionData])
    
    if (!connectionData && !editMode) {
        return <></>
    }

    const onUpdateHeaders = (rowIndex: number, values: IUpdatedCellValue[]) => {
        const newPair = {
            key: '',
            value: ''
        }

        values.forEach((value) => {
            if (value.header === 'key') {
                newPair.key = value.cellValue
            } else if (value.header === 'value') {
                newPair.value = value.cellValue
            }
        })
        let headers: {key: string, value: string}[] = []
        if (data.headers && data.headers.length > 0) {
            headers = [...data.headers]
        }

        if (rowIndex === headers.length) {
            headers.push(newPair)
        } else {
            if (newPair.key === '' && newPair.value === '') {
                headers.splice(rowIndex, 1)
            } else {
                headers[rowIndex] = newPair
            }            
        }

        setData({...data, headers})
    }

    const onUpdateBody = (rowIndex: number, values: IUpdatedCellValue[]) => {
        const newPair = {
            key: '',
            value: ''
        }

        values.forEach((value) => {
            if (value.header === 'key') {
                newPair.key = value.cellValue
            } else if (value.header === 'value') {
                newPair.value = value.cellValue
            }
        })

        let body: {key: string, value: string}[] = []
        if (data.body && data.body.length > 0) {
            body = [...data.body]
        }

        if (rowIndex === body.length) {
            body.push(newPair)
        } else {
            if (newPair.key === '' && newPair.value === '') {
                body.splice(rowIndex, 1)
            } else {
                body[rowIndex] = newPair
            }
        }

        setData({...data, body})
    }

    const onUpdatePublishMap = (rowIndex: number, values: IUpdatedCellValue[]) => {
        const newPair = {
            key: '',
            value: ''
        }

        values.forEach((value) => {
            if (value.header === 'key') {
                newPair.key = value.cellValue
            } else if (value.header === 'value') {
                newPair.value = value.cellValue
            }
        })

        let publishMap: {key: string, value: string}[] = []
        if (data.publishMap && data.publishMap.length > 0) {
            publishMap = [...data.publishMap]
        }

        if (rowIndex === publishMap.length) {
            publishMap.push(newPair)
        } else {
            if (newPair.key === '' && newPair.value === '') {
                publishMap.splice(rowIndex, 1)
            } else {
                publishMap[rowIndex] = newPair
            }
        }

        setData({...data, publishMap})
    }

    const onUpdatePrimaryKeys = (rowIndex: number, values: IUpdatedCellValue[]) => {
        const newMap = values[0].cellValue

        let primaryKeyColumns: string[] = []
        if (data.primaryKeyColumns && data.primaryKeyColumns.length > 0) {
            primaryKeyColumns = [...data.primaryKeyColumns]
        }

        if (rowIndex === primaryKeyColumns.length) {
            primaryKeyColumns.push(newMap)
        } else if(newMap === '') {
            primaryKeyColumns.splice(rowIndex, 1)
        }
        else {
            primaryKeyColumns[rowIndex] = newMap
        }

        setData({...data, primaryKeyColumns})
    }

    const canEnableSave = () => {
        if(editMode)
            {
                if( authType == "spn" && (!data.connectionName?.trim() || !data.appScope || !data.endPointUrl?.trim()
                    || !data.method?.trim() || !data.grantType?.trim()
                    || (data.grantType?.trim() === 'client_credentials' && (!data.clientId?.trim() || !data.resource?.trim() || !data.clientSecret?.trim() || !data.scope?.trim()))
                    || (data.grantType?.trim() === 'password_credentials' && (!data.userName?.trim() || !data.password?.trim()))))
                    return false;
                if( authType == "msi" && (!data.connectionName?.trim() || !data.appScope || !data.endPointUrl?.trim()
                    || !data.method?.trim() ))
                    return false;
                if(connectionData && JSON.stringify(connectionData) === JSON.stringify(data))
                    return false;
                return true;
            }
        return false;
    }

   
    const onSaveClick = () => {

        var finalData = {} as ApiConnection extends Connection ? ApiConnection : Connection;
        if(authType == "spn")
            finalData = {...data, useMSI: 0};
        else
            finalData = {...data, useMSI: 1, grantType: '',clientId: '', clientSecret: '', userName: '', password: '', resource: '', scope: ''};

        dispatch(postConnections({ newConnection: finalData, type: connectionType }))
    }

    let subscriptionName = ''
    if (!editMode) {
        subscriptionName = subscriptions.filter(item => item.key === data.subscriptionKey)[0]?.name || ''
    }

    return ( 
        <>  
            <div className='input-row'>
                <div>Name</div>
                <TextField id = "apinamefield"  required placeholder="Name" value={data.connectionName || ''} 
                    onHeInput={(e: any) => {
                        setData({...data, connectionName: e.target.value})
                        
                    
                }}/>
            </div>
            <div className='input-row'>
                    <div>Tenant</div>
                    <Select
                            value= {data.subscriptionKey || ''}
                            slot="action"
                            fixed-placement
                            disabled={!editMode}
                            placeholder={subscriptionName || 'Select'}
                            id = "apitenantfield" 
                            >
                            {
                                subscriptions?.map((item) => (
                                    <Option
                                        key={item.key}
                                        role="menuitemcheckbox"
                                        value={item.key}
                                        onHeSelected={(e: any) => {
                                            setData({...data, subscriptionKey: e.target.value})
                                            
                                        }}
                                    >
                                        {item.name}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
            <div className='input-row'>
                <div>App Scope
                <Icon id="info48" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info48" fixedPlacement={true} placement='bottom'>
                            Select the app scope. If creating, then create the app scope in all the three environments.
                            </Tooltip>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Select
                        value= {data.appScope || ''}
                        slot="action"
                        fixed-placement
                        placeholder='Select'
                        style={{width: '500px'}}
                        id = "apiscopefield" 
                        >
                        {
                            appScopes?.map(item => (
                                <Option
                                    key={item}
                                    role="menuitemcheckbox"
                                    onHeSelected={(e: any) => {
                                        setData({...data, appScope: e.target.textContent})
                                        
                                    }}
                                >
                                    {item}
                                </Option>
                            ))
                        }
                    </Select>
                    {/* TODO add this to aka.ms */}
                    {
                                selectedEnv == "dev" ? <a id="apidevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a> 
                                : selectedEnv == "uat" ? <a id="apiuatscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopUat}>Add New Scope</a> 
                                : selectedEnv == "prod" ? <a id="apiprodscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopProd}>Add New Scope</a> 
                                : <a id="apidevscope" style={{marginLeft: '10px'}} target="_blank" href={adbScopeDev}>Add New Scope</a>
                            }
                             <Icon id="infoaddscopeapi" name="info" style={{ marginLeft: "4px", cursor: "pointer", color: "#0078D4" }} tabIndex={0}/>
                            <Tooltip anchor="infoaddscopeapi" fixedPlacement={true} placement='bottom'>
                            The new scope needs to be created in all the three environments
                            </Tooltip>
                    </div>
            </div>

            <div className='input-row'>
                <div>Batch Key
                <Icon id="info49" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info49" fixedPlacement={true} placement='bottom'>
                            Select the batch key
                            </Tooltip>
                </div>
                <TextField id = "apitextfield"  placeholder="Batch Key" value={data.batchKey || ''} 
                    onHeInput={(e: any) => {
                        setData({...data, batchKey: e.target.value})
                    
                }}/>
            </div>  

            <div className='input-row'>
                <div>Batch Size
                <Icon id="info50" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info50" fixedPlacement={true} placement='bottom'>
                            Select batch size
                            </Tooltip>
                </div>
                <TextField id = "apibatchfield"  placeholder="Batch Size" value={data.batchSize || ''} 
                    onHeInput={(e: any) => {
                        setData({...data, batchSize: e.target.value})
                    
                }}/>
            </div>

            <div className='input-row'>
                <div>OCP APIM Subscription Key
                <Icon id="info51" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info51" fixedPlacement={true} placement='bottom'>
                            Select APIM Subscription Key
                            </Tooltip>
                </div>
                <TextField id = "apisubskeyfield"  placeholder="OCP APIM Subscription Key" value={data.ocp_Apim_Subscription_Key || ''} 
                    onHeInput={(e: any) => {
                        setData({...data, ocp_Apim_Subscription_Key: e.target.value})
                    
                }}/>
            </div>

            <div className='input-row'>
                <div>Endpoint URL
                <Icon id="info52" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info52" fixedPlacement={true} placement='bottom'>
                            Select Endpoint URL
                            </Tooltip>
                </div>
                <TextField id = "apiendpointfield"  required placeholder="Endpoint URL" value={data.endPointUrl || ''} 
                    onHeInput={(e: any) => {
                        setData({...data, endPointUrl: e.target.value})
                    
                }}/>
            </div>   

            <div className='input-row'>
                <div>Method</div>
                <TextField id = "apimethodfield"  required placeholder="Method" value={data.method || ''} 
                    onHeInput={(e: any) => {
                        setData({...data, method: e.target.value})
                    
                }}/>
            </div> 

            {authType == "spn" && 
            <div className='input-row'>
            <div>Grant Type</div>
            <Select
                value= {data.grantType || ''}
                slot="action"
                fixed-placement
                placeholder='Select'
                id = "apigrantfield" 
                >
                {
                    ['client_credentials', 'password_credentials'].map(item => (
                        <Option
                            key={item}
                            role="menuitemcheckbox"
                            onHeSelected={(e: any) => {
                                setData({...data, grantType: e.target.textContent})
                                
                            }}
                        >
                            {item}
                        </Option>
                    ))
                }
            </Select>
        </div>}

            { data.grantType === 'client_credentials' && authType == "spn" &&
                <> 
                    <div className='input-row'>
                        <div>Client ID
                        <Icon id="info53" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info53" fixedPlacement={true} placement='bottom'>
                            Select Client ID
                            </Tooltip>
                        </div>
                        <TextField id = "apiclientid"  required placeholder="Client ID" value={data.clientId || ''} 
                            onHeInput={(e: any) => {
                                setData({...data, clientId: e.target.value})
                            
                        }}/>
                    </div> 
                    <div className='input-row'>
                        <div>Client Secret
                        <Icon id="info54" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info54" fixedPlacement={true} placement='bottom'>
                            Select Client Secret key from keyvault
                            </Tooltip>
                        </div>
                        <TextField id = "apiclientsecret" required placeholder="Client Secret" value={data.clientSecret || ''} 
                            onHeInput={(e: any) => {
                                setData({...data, clientSecret: e.target.value})
                            
                        }}/>
                    </div>   

                    <div className='input-row'>
                        <div>Resource
                        <Icon id="info55" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info55" fixedPlacement={true} placement='bottom'>
                            Select resource 
                            </Tooltip>
                        </div>
                        <TextField id = "apiresourcefield" required placeholder="Resource" value={data.resource || ''} 
                            onHeInput={(e: any) => {
                                setData({...data, resource: e.target.value})
                            
                        }}/>
                    </div> 

                    <div className='input-row'>
                        <div>Scope
                        <Icon id="info56" name="info" style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }} />
                            <Tooltip anchor="info56" fixedPlacement={true} placement='bottom'>
                            Select the scope
                            </Tooltip>
                        </div>
                        <TextField id = "apiclientscope" required placeholder="Scope" value={data.scope || ''} 
                            onHeInput={(e: any) => {
                                setData({...data, scope: e.target.value})
                            
                        }}/>
                    </div>
                </>
            }

            { data.grantType === 'password_credentials' && authType == "spn" &&
                <>
                    <div className='input-row'>
                        <div>Username</div>
                        <TextField id = "apiusername" required placeholder="Username" value={data.userName || ''} 
                            onHeInput={(e: any) => {
                                setData({...data, userName: e.target.value})
                            
                        }}/>
                    </div> 

                    <div className='input-row'>
                        <div>Password</div>
                        <TextField id = "apipassword" type="password" required placeholder="Password" value={data.password || ''} 
                            onHeInput={(e: any) => {
                                setData({...data, password: e.target.value})
                            
                        }}/>
                    </div> 
                </>
            }

            <Tabs manualActivation>
                <Tab>Headers</Tab>
                <Tab>Body</Tab>
                <Tab>Ingestion Map</Tab>
                <Tab>Primary Key Columns</Tab>

                <TabPanel style={{marginTop: '10px'}}>
                    <TextFieldTable 
                        headers={[{headerKey: 'key', headerText: 'Key'}, {headerKey: 'value', headerText: 'Value'}]}
                        rows={data.headers}
                        updateCellValue={onUpdateHeaders}
                    />
                </TabPanel>
                <TabPanel>
                    <TextFieldTable 
                        headers={[{headerKey: 'key', headerText: 'Key'}, {headerKey: 'value', headerText: 'Value'}]}
                        rows={data.body}
                        updateCellValue={onUpdateBody}
                    />
                </TabPanel>
                <TabPanel>
                    <TextFieldTable 
                        headers={[{headerKey: 'key', headerText: 'Key'}, {headerKey: 'value', headerText: 'Value'}]}
                        rows={data.publishMap}
                        updateCellValue={onUpdatePublishMap}
                    />
                </TabPanel>
                <TabPanel>
                    <TextFieldTable 
                        headers={[{headerKey: 'key', headerText: 'Key'}]}
                        rows={data.primaryKeyColumns?.map(item => ({key: item}))}
                        updateCellValue={onUpdatePrimaryKeys}
                    />
                </TabPanel>
            </Tabs>
             

            <div slot="footer" className="panel-footer">
                <Button id = "apisavefield" onClick={onSaveClick} disabled={!canEnableSave()} appearance={editMode ? 'primary': undefined}>
                    Save
                </Button>
            </div>
        </>     
    )
}
export const ApiConnectionPanel = withErrorBoundary("API connection panel", ApiConnectionPanelC);