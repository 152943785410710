import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "./utils/GetRequest";
import {
  TenantStats,
} from "../model/tenant-stats/TenantStats.model";
import { useSelector } from "react-redux";
import { RootState } from "../root-redux/RootState";

export const getTenantStats = createAsyncThunk<any, string[], {state: RootState}>(
  "apppersonamappings/tenantstats",
  async (tenantIDs: any, { getState }) => {
    
    const selectedEnv = getState().env.selectedEnvItem  
    const responses = await Promise.all(tenantIDs.map((id:any) => getRequest<TenantStats>(`${selectedEnv}/apppersonamappings/tenantdata/${id}`)))

    let result: TenantStats[] = []
    responses?.forEach((res:any, index:any) => {
      result.push(res.data)
    })

    return result
  }
);
