import { Icon } from "../../common/HarmonyEnablers";


export const InformationText: React.FC<any> = (props:any) => {
  
  return (
    <>
    {
        props.type === "Heading" 
        ? 
        <>
        <div style={{display: 'flex', marginTop: '10px'}}>
          <p style = {{fontSize:"15px", fontWeight:"350", marginBottom : "8px", marginLeft : "0px"}}>{props.text}</p>
        </div>
        </>
        : 
        <p style = {{fontSize:"14px",  marginLeft:"10px", fontWeight:"350", marginBottom : "8px"}}>{props.text}</p>
    }
    </>

  );
}