import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "./utils/GetRequest"
import { ConnectionsCollection, IConnectionsResponse } from "../model/connections/ConnectionsCollection.model"
import { RootState } from "../root-redux/RootState"
import { getRequestReleaseManagement } from "./utils/GetRequestReleaseManagement";

// TODO: do not make api call if data already present in redux, use some expiry in the redux store based on that fetch the data to refresh it if expired
export const getConnections = createAsyncThunk<{connections: {[subscriptionKey: string]: ConnectionsCollection}, env: string}, {subscriptionKeys: string[]}, { state: RootState }>('connections/getConnections', async ({subscriptionKeys}, { getState }) => {
    const selectedEnv = getState().env.selectedEnvItem
    const responses = await Promise.all(subscriptionKeys.map((subscriptionKey:any) => getRequest<IConnectionsResponse>(`${selectedEnv}/connections/${subscriptionKey}`)))
    let result: {[subscriptionKey: string]: ConnectionsCollection} = {}
    responses?.forEach((res:any, index:any) => {
      result[subscriptionKeys[index]] = res.data?.connections
    })
    return {connections: result, env: selectedEnv}
  });

  export const getConnections_dev = createAsyncThunk<{[subscriptionKey: string]: ConnectionsCollection}, {subscriptionKeys: string[]}, { state: RootState }>('dev/connections/getConnections', async ({subscriptionKeys}) => {
    const responses = await Promise.all(subscriptionKeys.map((subscriptionKey:any) => getRequestReleaseManagement<IConnectionsResponse>(`dev/connections/${subscriptionKey}`)))
    let result: {[subscriptionKey: string]: ConnectionsCollection} = {}
    responses?.forEach((res:any, index:any) => {
      result[subscriptionKeys[index]] = res.data?.connections
    })
    return result
  });

  export const getConnections_uat = createAsyncThunk<{[subscriptionKey: string]: ConnectionsCollection}, {subscriptionKeys: string[]}, { state: RootState }>('uat/connections/getConnections', async ({subscriptionKeys}) => {
    const responses = await Promise.all(subscriptionKeys.map((subscriptionKey:any) => getRequestReleaseManagement<IConnectionsResponse>(`uat/connections/${subscriptionKey}`)))
    let result: {[subscriptionKey: string]: ConnectionsCollection} = {}
    responses?.forEach((res:any, index:any) => {
      result[subscriptionKeys[index]] = res.data?.connections
    })
    return result
  });


  export const getConnections_prod = createAsyncThunk<{[subscriptionKey: string]: ConnectionsCollection}, {subscriptionKeys: string[]}, { state: RootState }>('prod/connections/getConnections', async ({subscriptionKeys}) => {
    const responses = await Promise.all(subscriptionKeys.map((subscriptionKey:any) => getRequestReleaseManagement<IConnectionsResponse>(`prod/connections/${subscriptionKey}`)))
    let result: {[subscriptionKey: string]: ConnectionsCollection} = {}
    responses?.forEach((res:any, index:any) => {
      result[subscriptionKeys[index]] = res.data?.connections
    })
    return result
  });


