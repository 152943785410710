export const BASE_URL = process.env.REACT_APP_NEBULA_BASE_URL
export const MSX_BASE_URL = process.env.REACT_APP_MSX_BASE_URL
export const NEBULA_NON_PROD_CLIENT_ID = "1aaf894a-0fdb-487c-ace5-cc90e423ff5c"
export const NEBULA_PROD_CLIENT_ID = "fc8c87b7-2b16-4641-b29d-f8e668c9838"
export const ACCESS_TOKEN_URL = "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/oauth2/v2.0/token"
export const BASE_URL_API_DEV = "https://nebulaapidev.azurefd.net"
export const BASE_URL_API_UAT = "https://nebulaapiuat.azurefd.net"
export const BASE_URL_API_PROD = "https://nebulaapi.azurefd.net"
export const CAMPAIGN_ROWS_PER_PAGE = 20
export const DATASET_ROWS_PER_PAGE = 20
export const INPUT_BLANK = "No data was found in the selected file.";
export const INPUT_COULD_NOT_PARSE = "An error occured while reading the file, is it a valid Excel file?";
export const INPUT_NOREAD = "An unknown error occured while reading the file.";
export const INPUT_NOT_CSV = "Only *.csv files are supported at this time.";
export const INPUT_NOFILE = "NOFILE";
export const INPUT_VALID = "VALID";
export const INPUT_BAD_HEADERS = "The file headers do not match the template.";
export const INPUT_HEADERS_BAD_ORDER = "The file headers are not in correct order.";
export const INPUT_NO_ACCOUNTS = "No tpids were found in the selected file.";
export const MAX_ROWS = 15000;
