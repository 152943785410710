import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest, getRequestPerEnv, getRequestPlatformApi } from "./utils/GetRequest"
import { PersonaMapping } from "../model/personaMapping/PersonaMapping.Model"
import { RootState } from "../root-redux/RootState"
import { Insight } from "../model/preview-mergerule/Insight.model"

export const getTestApi = createAsyncThunk<any, { apipath: string, token: string, subkey: string, isIgnoreLoggedInUser: boolean, correlationIdParam: string }, { state: RootState }>('testApi/getTestApi', async ({ apipath, token, subkey, isIgnoreLoggedInUser, correlationIdParam }, { getState }) => {
  var env = getState().env.selectedEnvItem
  const response = await getRequestPlatformApi<Insight[]>(apipath, token, subkey, env, isIgnoreLoggedInUser, correlationIdParam)
  return response
})

export const getTestApiDuplicate = createAsyncThunk<any, { apipath: string, token: string, subkey: string, isIgnoreLoggedInUser: boolean, correlationIdParam: string }, { state: RootState }>('testApi/getTestApiDup', async ({ apipath, token, subkey, isIgnoreLoggedInUser, correlationIdParam }, { getState }) => {
  var env = getState().env.selectedEnvItem
  const response = await getRequestPlatformApi<Insight[]>(apipath, token, subkey, env, isIgnoreLoggedInUser, correlationIdParam)
  return response
})

export const getEntityIds = createAsyncThunk<any, { family: string, entityTypes: string, getAllFamilyData: boolean}, { state: RootState }>('testApi/getEntityIds', async ({  family, entityTypes, getAllFamilyData }, { getState }) => {
  var env = getState().env.selectedEnvItem
  var types = entityTypes && entityTypes.length > 0 ? entityTypes : ["TPID"]
  const response = await getRequestPerEnv<Object[]>(`insights/entityIds?family=${family}&entityTypes=${types}&getAllFamilyData=${getAllFamilyData}`, env);
  return response
})
