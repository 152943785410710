import { useSelector } from "react-redux";
import { RootState } from "../../../root-redux/RootState";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { ShimmerLines } from "../../../shared-components/loader/ShimmerLines";
import { Icon } from "../../../common/HarmonyEnablers";
import { Spinner } from "../../../shared-components/loader/Spinner";

export const ScheduleWorkflowHeader: React.FC = () => {
    const workflowStatus = useSelector((state: RootState) => state.workflowById.status);
    const workflow = useSelector((state: RootState) => state.workflowById.workflow);   
    const postScheduleWorkflowStatus = useSelector((state: RootState) => state.workflowById.postScheduleWorkflowStatus); 

    let component = <></>

    if (workflowStatus === RequestStatus.loading || workflowStatus === RequestStatus.idle) {
        component = <ShimmerLines />
    } else {
        component = (
            <>
                <div className="schedule-main-header">
                    <Icon name='clock'/>
                    <span>Schedule Job</span>
                    <span style={{margin: '0 10px'}}>|</span>
                    <span>{workflow?.config?.workflowName || ''}</span>
                </div>
                <div className="schedule-sub-header">
                    <div>
                        <span>Environment:</span>
                        <span style={{marginLeft: '5px'}}>{workflow.environment}</span>
                    </div>
                    <div className="display-flex">
                        <span>Status:</span>
                        {postScheduleWorkflowStatus===RequestStatus.loading ?
                            (<div style={{display: 'flex', alignItems: 'center', marginLeft: '5px'}} >
                                <Spinner type='small' />
                            </div>)
                            :
                            <span style={{marginLeft: '5px'}}>{workflow?.schedule?.jobId ? 'Scheduled' : 'Not scheduled'}</span>
                        }                        
                    </div>
                </div>
            </>
        )
    }

    return (
        <div slot='heading'>
            <div className="schedule-header-wrapper">
                {component}
            </div>            
        </div>
    )
}