import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { WorkflowSummary } from "../../../model/workflows/Workflow.model";
import { getWorkflows } from "../../../services/GetWorkflows.api";
import { postWorkflow } from "../../../services/PostWorkflow.api";
import { Cluster } from "../../../model/cluster/Cluster";
import { getAllClusters } from "../../../services/GetCluster.api";
import { DatasetUploadResponse } from "../../../model/workflows/DatasetFileUpload.model";

export interface ITypeCheckedState {Recommendation: boolean, Insight: boolean, Dataset:boolean}
export interface IStatuscheckedState {New: boolean, Submitted: boolean, Approved: boolean, Published: boolean}
export interface IKeyValueCheckedState {[key: string] : boolean};

export interface WorkflowsState {
  list: WorkflowSummary[];
  status: RequestStatus;
  error: string | undefined;
  selectedWorkflowIds: string[];
  clusterEnv: string;
  clusterList: {[env: string]: Cluster[]};
  clusterStatus: RequestStatus;
  clusterError: string | undefined;
  typecheckedState: ITypeCheckedState,
  statuscheckedState: IStatuscheckedState,
  tenantcheckedState: IKeyValueCheckedState;
  usercheckedState: IKeyValueCheckedState;
  modelProvidercheckedState: IKeyValueCheckedState;
  searchText: string
}

const initialState: WorkflowsState = {
  list: [],
  status: RequestStatus.idle,
  error: "",
  selectedWorkflowIds: [],
  clusterEnv: "Dev",
  clusterList: {"dev": [], "uat": [], "prod": []},
  clusterStatus: RequestStatus.idle,
  clusterError: "",
  typecheckedState: {Recommendation: false, Insight: false, Dataset: false},
  statuscheckedState: {New: false, Submitted: false, Approved: false, Published: false},
  tenantcheckedState: {},
  usercheckedState: {},
  modelProvidercheckedState: {},
  searchText: ""
};

const workflowsSlice = createSlice({
  name: "workflows",
  initialState,
  reducers: {
    setClusterEnv: (state, action: PayloadAction<string>) => {
      state.clusterEnv = action.payload;
    },
    setTypeCheckedState: (state, action: PayloadAction<{Recommendation: boolean, Insight: boolean, Dataset:boolean}>) => {
      state.typecheckedState = action.payload;
    },
    setStatusCheckedState: (state, action: PayloadAction<{New: boolean, Submitted: boolean, Approved: boolean, Published: boolean}>) => {
      state.statuscheckedState = action.payload;
    },
    setTenantCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.tenantcheckedState = action.payload;
    },
    setUserCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.usercheckedState = action.payload;
    },
    setModelProviderCheckedState: (state, action: PayloadAction<{[key: string] : boolean}>) => {
      state.modelProvidercheckedState = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    removeWorkflow: (state, action) => {
      const workflowid = action.payload;
      state.list = state.list.filter(
        (item) => item.config.workflowId !== workflowid
      );
    },
    addWorkflow: (state, action) => {
      state.list = state.list.concat(action.payload);
    },
    setSelectedWorkflowIds: (state, action) => {
      state.selectedWorkflowIds = action.payload;
    },
    setWorkflowSummaryStatus: (state, action: PayloadAction<{workflowId: string, status: string}>) => {
      
      const workflowId = action.payload.workflowId
      const workflowStatus = action.payload.status

      let workflowSummary: any = [];
      state.list.map((workflow) => {
        if (workflow.config.workflowId === workflowId) {
          workflowSummary = workflow;
          removeWorkflow(workflowSummary);
          workflowSummary.status = workflowStatus;
          addWorkflow(workflowSummary);
        }
      });
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getWorkflows.pending, (state, action) => {
        state.status = RequestStatus.loading;
      })
      .addCase(getWorkflows.fulfilled, (state, action) => {
        state.status = RequestStatus.succeeded;
        state.list = action.payload;
        state.error = undefined;
      })
      .addCase(getWorkflows.rejected, (state, action) => {
        state.status = RequestStatus.failed;
        state.error = action.error.message;
      })
      .addCase(postWorkflow.fulfilled, (state, action) => {
        const workflow = action.payload.workflow;
        const type = action.payload.type;
        if (type === "new" || type === "clone") {
          const newWorkflow: WorkflowSummary = {
            config: workflow.config,
            status: workflow.status,
            subscriptionKey: workflow.subscriptionKey,
            modifiedDate: Date.now().toString(),
            modifiedBy: workflow.modifiedBy,
            version: workflow.version,
            family: workflow.family,
            selfServeVersion: workflow.selfServeVersion,
            workFlowType: workflow.workFlowType,
            hasApproved: workflow.hasApproved,
            tenantName: workflow.tenantName,
            description: workflow.description,
            tags: workflow.tags,
            modelName: workflow.modelName,
            fields: workflow.fields,
            modelId: "",
            modelProvider: "",
          }

          state.list = [...state.list, newWorkflow];
        }
      })
      .addCase(getAllClusters.pending, (state, action) => {
        state.clusterStatus = RequestStatus.loading;
      })
      .addCase(getAllClusters.fulfilled, (state, action) => {
        state.clusterStatus = RequestStatus.succeeded;
        state.clusterList = action.payload;
        state.clusterError = undefined;
      })
      .addCase(getAllClusters.rejected, (state, action) => {
        state.clusterStatus = RequestStatus.failed;
        state.clusterError = action.error.message;
      });
  },
});

export const { 
  setClusterEnv, setTypeCheckedState, setStatusCheckedState, setTenantCheckedState,setModelProviderCheckedState, setUserCheckedState,
  removeWorkflow, setSelectedWorkflowIds, addWorkflow, setWorkflowSummaryStatus, setSearchText } = workflowsSlice.actions;

export const workflowsReducer = workflowsSlice.reducer;
