import { useNavigate } from 'react-router-dom';
import { NavMenuItemType } from '../../../model/NavMenu.model';
import informationConfig from '../../../shared-content/inputDescription';
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import '../spa-styles.css';
import { CampaignHeader } from "./EditCamapaignHeader";
import { EditCampaignTabs } from './EditCampaignTabs';
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../root-redux/RootState"
import { InformationText } from '../../../shared-components/info-text/InformationText';
import { Button, ProgressRing } from '../SpaAdminScope';
import { getCampaignById, postCampaignDetails } from '../../../services/SPAAdmin/CampaignById.api';
import { useCallback, useEffect, useState } from 'react';
import { setCampaignSummary } from '../redux/CampaignById.redux';
import { taxonomySelector } from '../redux/Taxonomy.selector.redux';
import { ConfigRenderer, RenderMandatoryFields, validateField } from './Components/ConfigRenderer';
import { UserMode } from '../redux/CacheHandler.redux';
import { Helper } from '../utils/Help';
import { Initialize } from '../utils/Initialize';
import { Environment, Release } from '../../../model/releases/Release.model';
import { v4 as uuidv4 } from 'uuid';
import { postRelease } from '../../../services/PostRelease.api';
import { CampaignTypesEnum, SPA_CAMPAIGN } from '../SPAConstants';
import { RequestStatus } from '../../../model/ServiceRequestStatus.model';
import { AppPersona } from '../../../model/app-persona/AppPersona.model';
import { permissionsSelector } from '../../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux';
import { Logger } from '../../../utils/Logger';

import '../../edit-workflow/edit-workflow-styles.css';
import '../spa-styles.css';

const CampaignSummaryC: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [isValid, setIsValid] = useState<boolean>(false);
    
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen);
    const campaignConfig = useSelector((state: RootState) => state.campaignConfig);
    const cacheHandler = useSelector((state: RootState) => state.cacheHandler);
    const campaignById = useSelector((state: RootState) => state.campaignById);
    const userDetails = useSelector((state: RootState) => state.userDetails);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const selectedEnv = useSelector((state: RootState) => state.env.selectedEnvItem);
    const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
    const editCampaignFlag = useSelector((state: RootState) => state.campaignById.editCampaignFlag);

    const taxonomyState = useSelector(taxonomySelector);
    const permissionsList = useSelector(permissionsSelector);

    const userTeams = permissionsList.userTeams;
    const teamsList: [string, string][] = [];
    userTeams.name.forEach((element: string) => teamsList.push([userTeams.key[userTeams.name.indexOf(element)], element]));
    
    const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
        navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
    }, [navigate]);

    const onNextClick = useCallback(() => {
        if (cacheHandler.campaignCache?.mode === UserMode.EDIT && userDetails.localAccountId !== campaignById.campaign?.createdBy && !userSalesRoles.isSpaAdmin) {
            _onNavMenuItemClick(NavMenuItemType.Campaign, "refine");
            return;
        }
        //Create release for New campaign and New version of an existing campaign
        var createRelease:boolean = campaignById?.campaign && !editCampaignFlag?false:true; 
        dispatch(postCampaignDetails()).unwrap().then((result) => {
            if(result) {
                if(createRelease) {
                    const environments: Environment[] = [
                        { environment: "dev", isDeployed: true },  { environment: "uat", isDeployed: false },  { environment: "prod", isDeployed: false }];
                    
                    const releaseData : Release = {
                        environments: environments,
                        timestamp: "",
                        releaseId: uuidv4().toString(),
                        workflowId: result.config.workflowId??'',
                        version: result.version?.toString() ?? "1",
                        id: uuidv4().toString(),
                        type: "New Version",
                        status: "New",
                        scheduleCreated: false,
                      };
                      dispatch(postRelease({payload : releaseData, env: selectedEnv}));
                }
                _onNavMenuItemClick(NavMenuItemType.Campaign, "refine");
            }
        });
    }, [dispatch, _onNavMenuItemClick])

    const handleSummaryFormChange = (e: any) => {
        if (e.target.tagName && e.target.tagName.toUpperCase().search("DATE-PICKER") !== -1) {
            const darr = e.target.value.split('-');
            const value = `${darr[1]}/${darr[2]}/${darr[0]}`;
            dispatch(setCampaignSummary({ key: e.target.name, value: value}));
        } else {
            const keyName = e.target.name;
            let value = (e.target.value || "").trim();
            value = value.replaceAll(new RegExp("\\$","g"),"$$$$"); //replace $ with $$ for escape character
            value = value.replaceAll("'", "\\\\'");
            value = value.replaceAll('\\r', '\n').replaceAll('\\n', '\n').replaceAll('\\r\\n', '\n');
            value = value.split('\n').map((str: string) => str.trim()).filter((str: string) => str.length > 0 || keyName === 'RecommendationTitle').join('\n').replaceAll('\n', '\\\\\\\\r\\\\\\\\n');
            dispatch(setCampaignSummary({ key: keyName, value: value }));
            const field = campaignConfig.campaignConfig?.layoutFields.find(lf => lf.name === keyName)?.taxonomyField;
            campaignConfig.campaignConfig?.layoutFields.filter(lf => lf.taxonomyFilterKey?.includes(field!)).forEach(lf => {
                dispatch(setCampaignSummary({ key: lf.name, value: "" }));
            });
        }
        return true;
    }

    if (cacheHandler.campaignCache?.mode === UserMode.EDIT && cacheHandler.campaignCache?.campaignId === undefined) {
        _onNavMenuItemClick(NavMenuItemType.Configuration);
    } else if (cacheHandler.campaignCache?.mode !== UserMode.CREATE && campaignById.requestStatus === RequestStatus.idle && cacheHandler.campaignCache?.campaignId !== undefined) {
        dispatch(getCampaignById(cacheHandler.campaignCache?.campaignId!));
    }

    useEffect(() => {
        let isValid = true;
        for(const layoutField of (campaignConfig.campaignConfig?.layoutFields || [])) {
            if (layoutField.required === true && !validateField(layoutField.regex, campaignById.summary[layoutField.name]) ) {
                isValid = false;
                break;
            }
        }
        setIsValid(isValid);
    }, [campaignConfig, campaignById.summary, cacheHandler]);

    useEffect(() => {
        if (Helper.isEmpty(campaignById.summary) && !Helper.isEmpty(allAppsList)) {
            handleSummaryFormChange({target: {name: "CampaignType", value: CampaignTypesEnum.SMC}});
            const item = teamsList.find(item => item[1] === SPA_CAMPAIGN);
            if (item) {
                handleSummaryFormChange({target: {name: "SubscriptionKey", value: item[0]}});
            }
        }
    }, [allAppsList, campaignById.summary]);

    return (
        <>
            <Initialize />
            
            <div className={`spa-sticky-header ${isNavPanelOpen ? '': 'collapsed'}`}>
                <CampaignHeader title={campaignById?.summary?.CampaignName ?? "Summary"} currentPage={1}  workflowName=""/>
                
                <div className='spa-workspace'>
                    <EditCampaignTabs stage={0}/>
                </div>

                <div className="spa-informationtext">
                    <InformationText text={informationConfig.campaignSummary} type="Heading"/>
                </div>
            </div>

            <div className='workflows-wrapper' style={{marginTop: "220px"}}>

                {Helper.notReadyStatus(campaignConfig.requestStatus, taxonomyState.status) || (cacheHandler.campaignCache?.mode === UserMode.EDIT && Helper.isLoadingStatus(campaignById.requestStatus)) ?
                    <div className='spa-progressring'>
                        <ProgressRing label="Loading..." indeterminate></ProgressRing>
                    </div>
                :
                    <>
                        <table className='workflow-form-table  workflow-basics-table' role='presentation' style={{marginBottom: '80px'}}>
                            <RenderMandatoryFields selectionChange={handleSummaryFormChange} layoutConfigs={campaignConfig.campaignConfig?.layoutFields} summary={campaignById.summary} editCampaignFlag={editCampaignFlag} />
                            <ConfigRenderer section={2} summary={campaignById.summary} layoutConfigs={campaignConfig.campaignConfig?.layoutFields} selectionChange={handleSummaryFormChange} taxonomyState={taxonomyState} editCampaignFlag={editCampaignFlag}/>
                        </table>
                    </>
                }
                <div className={`workflow-footer ${isNavPanelOpen ? '': 'collapsed'}`}>
                    <Button type='button' appearance='secondary' onClick={() => _onNavMenuItemClick(NavMenuItemType.Configuration)} style={{ marginRight: '20px' }}>Previous</Button>
                    <Button type='button' appearance='primary' onClick={onNextClick} disabled={!isValid || Helper.isLoadingStatus(campaignById.requestStatus)}>Next</Button> 
                    {Helper.isLoadingStatus(campaignById.requestStatus) && <ProgressRing indeterminate style={{marginTop: "1px", marginLeft: "20px"}}></ProgressRing>}
                </div>
            </div>
        </>
    )
}

export const CampaignSummary = withErrorBoundary("New Campaign Summary", CampaignSummaryC);

