import React from "react"
import { PreviewOutputColumns } from "./PreviewOutputColumn"
import { DataGrid, HoverCard } from "../merge-rules/MergeRulesScope"
import { PreviewPredictionColumns } from "./PreviewPredictionOutput"
import { PreviewRecommendationColumns } from "./PreviewRecommendationOutput"
import { PreviewInsightColumns } from "./PreviewInsightOutput"
import { Accordion, AccordionItem } from "../../common/HarmonyEnablers"
import { PreviewMergeRuleShowOutputPerPartitionKey } from "./PreviewMergeRuleShowOutputPerPartitionKey"

export const PreviewMergeRuleShowOutput: React.FC<any> = (props: any) => {
    const {previewOutput, previewOutputPred,previewOutputIns, previewOutputRec,previewMergeRuleUserInput} = props
    

    return (
        <>
            {
                previewMergeRuleUserInput && previewMergeRuleUserInput.tpids  && previewMergeRuleUserInput.tpids.map((eachTpid: any) => {
                    var previewOutputLocal = previewOutput.filter((x:any) => {return x.cells.field0 === eachTpid})
                    var previewOutputPredLocal = previewOutputPred.filter((x:any) => {return x.cells.field0 === eachTpid})
                    var previewOutputInsLocal = previewOutputIns.filter((x:any) => {return x.cells.field0 === eachTpid})
                    var previewOutputRecLocal = previewOutputRec.filter((x:any) => {return x.cells.field0 === eachTpid})
                    return <PreviewMergeRuleShowOutputPerPartitionKey distinctField={previewOutput.distinctField || "TPID"} eachTpid={eachTpid} previewOutput={previewOutputLocal} previewOutputPred={previewOutputPredLocal} previewOutputIns={previewOutputInsLocal} previewOutputRec={previewOutputRecLocal} />
                })
            }
        </>
    )
}