/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { getCampaignConfigDetails } from "../../../services/SPAAdmin/CampaignConfig.api";
import { getTaxonomy } from "../../../services/Taxonomy/Taxonomy.api";
import { fetchCache } from "../redux/CacheHandler.redux";
import { useSelector } from "react-redux";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getWorkflows } from "../../../services/GetWorkflows.api";
import { getUserSalesRoles } from "../../../services/Taxonomy/UserRoles.api";
import { Helper } from "./Help";
import { setIsApproverStatus, setIsSpaAdmin, setIsSpaUser, setOtherUser } from "../redux/UserSalesRoles.redux";
import { SPA_ADMIN_MAIL, SPA_APP_ID } from "../SPAConstants";
import { filterReviewList } from "../redux/CampaignById.redux";
import { getDatasetConfigDetails } from "../../../services/Dataset/DatasetConfig.api";
import { NavMenuItemType } from '../../../model/NavMenu.model';
import { useLocation } from 'react-router-dom';

export const Initialize: React.FC = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const dispatchApp = useDispatch<AppDispatch>();

    const campaignConfig = useSelector((state: RootState) => state.campaignConfig);
    const datasetConfig = useSelector((state: RootState) => state.datasetConfig);
    const taxonomy = useSelector((state: RootState) => state.taxonomy);
    const workflowsState = useSelector((state: RootState) => state.workflows);
    const selectedEnvItem = useSelector((state: RootState) => state.env.selectedEnvItem);
    const userSalesRoles = useSelector((state: RootState) => state.userSalesRoles);
    const userDetails = useSelector((state: RootState) => state.userDetails);
    const appPersonas = useSelector((state: RootState) => state.appPersonas);
    const userGroups = useSelector((state: RootState) => state.userGroups);
    const superNebulaAdmin = useSelector((state: RootState) => state.superAdmin.superAdminEmail);

    const notAvailableStatus = useMemo(() => [RequestStatus.idle, RequestStatus.failed], []);

    const _onNavMenuItemClick = useCallback((navMenuItemType: NavMenuItemType, path: string | undefined = undefined) => {
        navigate("/" + NavMenuItemType[navMenuItemType] + (path ? `/${path}` : ''));
    }, [navigate]);
    
    // Do not add any dependencies, As it needs to invoke these API's once on page load or Env change
    useEffect(() => {
        dispatchApp(fetchCache());
        if (notAvailableStatus.includes(campaignConfig.requestStatus) && !Helper.isEmpty(appPersonas.list)) {
            dispatchApp(getCampaignConfigDetails());
        }
        if (notAvailableStatus.includes(taxonomy.requestStatus)) {
            dispatchApp(getTaxonomy());
        }
        if (notAvailableStatus.includes(workflowsState.status)) {
            dispatchApp(getWorkflows({ fetchUserObjects: true }))
        }
        if (notAvailableStatus.includes(datasetConfig.requestStatus)) {
            dispatchApp(getDatasetConfigDetails());
        }
    }, [dispatchApp, appPersonas, notAvailableStatus, selectedEnvItem]);

    useEffect(() => {
        if (notAvailableStatus.includes(userSalesRoles.requestStatus) && userDetails.localAccountId.length > 0) {
            dispatchApp(getUserSalesRoles());
        }
    }, [userDetails, appPersonas, dispatchApp, selectedEnvItem]);

    useEffect(() => {
        if (location.pathname.includes("redirect")) {
            return;
        }
        const oldEnv = window.localStorage.getItem("previousEnv");
        if (selectedEnvItem !== oldEnv) {
            _onNavMenuItemClick(NavMenuItemType.Configuration);
            window.localStorage.setItem("previousEnv", selectedEnvItem);
        }
    }, [selectedEnvItem]);

    useEffect(() => {
        if (!userSalesRoles.isApprover && appPersonas.status === RequestStatus.succeeded && userGroups.status === RequestStatus.succeeded && campaignConfig.requestStatus === RequestStatus.succeeded) {
            const configPersona = appPersonas.list.find(item => item.appId.map(id => id.toLowerCase()).includes(SPA_APP_ID));
            const isAreaReviewer = userGroups.list.some(grp => (configPersona?.appRoles.view || []).includes(grp));
            const isSpaDri = userGroups.list.some(grp => (configPersona?.appRoles.edit || []).includes(grp)) || (configPersona?.appRoles.edit || []).includes(userDetails.username);
            const isSpaAdmin = userGroups.list.includes(SPA_ADMIN_MAIL) || userGroups.list.includes(superNebulaAdmin);
            if (isAreaReviewer) {
                dispatchApp(setIsApproverStatus(true));
                dispatchApp(filterReviewList());
            } else if (isSpaDri) {
                dispatchApp(setIsSpaUser(isSpaDri));
            } else if (isSpaAdmin) {
                dispatchApp(setIsSpaAdmin(isSpaAdmin));
            } else {
                dispatchApp(setOtherUser(true));
            }
        }
    }, [userGroups, selectedEnvItem, appPersonas, dispatchApp, campaignConfig, datasetConfig, userDetails, userSalesRoles]);

    return <></>;
};