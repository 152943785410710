import { useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import './mergerules-styles.css'
import { NavMenuItemType } from "../../model/NavMenu.model"
import { Button } from "../workflows/WorkflowsScope"
import { postMergeRule } from "../../services/get-merge-rules/PostMergeRule.api"
import { patchMergeRule } from "../../services/get-merge-rules/PatchMergeRule.api"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../root-redux/RootState"
import { MergeRule } from "../../model/merge-rules/MergeRule.model"
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model"
import { getMergeRules, getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api"
import { addMergeRule, editMergeRule } from "../merge-rules/merge-rules-redux/MergeRules.redux"
import { getPersonaMapping } from "../../services/GetPersonaMapping.api"
import { PersonaMapping } from "../../model/personaMapping/PersonaMapping.Model"
import { postPersonaMapping } from "../../services/PostPersonaMapping.api"
import { patchPersonaMapping } from "../../services/PatchPersonaMapping.api"
import { addUpdateMergeRuleSetInMergeRule, resetMergeRule, resetMergeRuleSet, resetOpenedMergeRuleSet, updateEnableSave, updateEnableSaveMR, updateEssentials, updateOpenMergeRuleSet, updateOriginalMergeRule, updateTab } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux"
import { AppPersona } from "../../model/app-persona/AppPersona.model"
import { addMergeRuleBySubKey, editMergeRuleBySubKey } from "../merge-rules/merge-rules-by-id-redux/MergeRuleBySubKey.redux"
import { addMergeRulesByModel, updateMergeRuleByModel, updateMergeRulesByModel } from "../merge-rules/merge-rules-by-id-redux/MergeRuleByModel.actions.redux"

import { EditMergeRuleMessageBar } from "./EditMergeRuleMessageBar"
import { CancelConfirmationPopup } from "./popups/CancelConfirmationPopup"

export const EditMergeRulesFooter: React.FC<any> = (props: any) => {
    // redux states
    const dispatchApp = useDispatch<AppDispatch>()
    const dispatch = useDispatch();
    const { source, setOpenEditMergeRule, openEditMergeRule } = props
    const navigate = useNavigate()
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    const isSideNavOpen = useSelector((state: RootState) => state.workflowById.isSideNavOpen)
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
    const openMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.openMergeRuleSet);
    const originalMergeRule = useSelector((state: RootState) => state.mergeRuleById.originalMergeRule);
    const enableSave = useSelector((state: RootState) => state.mergeRuleById.enableSave);
    const enableSaveMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.enableSaveMergeRuleSet);
    const unsavedChanges = useSelector((state: RootState) => state.mergeRuleById.unsavedChanges);
    const unsavedMergeRuleSetChanges = useSelector((state: RootState) => state.mergeRuleById.unsavedMergeRuleSetChanges);
    const activeTab = useSelector((state: RootState) => state.mergeRuleById.activeTab);
    const allAppsList = useSelector((state: RootState): AppPersona[] => state.appPersonas.list);
    const userDetails = useSelector((state: RootState) => state.userDetails);
    const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);

    const [unsaved, setUnsaved] = useState((openMergeRuleSet && unsavedMergeRuleSetChanges) || (!openMergeRuleSet && unsavedChanges) ? "Unsaved Changes." : "No Changes made.")
    const [message, setMessage] = useState((openMergeRuleSet && unsavedMergeRuleSetChanges) || (!openMergeRuleSet && unsavedChanges) ? "Click on Save button to save these changes. " : "")
    const [isDisabled, setIsDisabled] = useState(openMergeRuleSet ? !enableSaveMergeRuleSet : !enableSave)
    const [disableSaveButton, setDisableSaveButton] = useState((openMergeRuleSet ? !enableSaveMergeRuleSet : !enableSave) || !((openMergeRuleSet && unsavedMergeRuleSetChanges) || (!openMergeRuleSet && unsavedChanges)))
    const [mandatory, setMandatory] = useState( (openMergeRuleSet ? !enableSaveMergeRuleSet : !enableSave) ? "Fill all required fields to enable Save.  " : "")

    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [cancelConfirmed, setCancelConfirmed] = useState(false);
    
    useEffect(() => {
        setUnsaved((openMergeRuleSet && unsavedMergeRuleSetChanges) || (!openMergeRuleSet && unsavedChanges) ? "Unsaved Changes." : "No Changes made.")
        setMessage((openMergeRuleSet && unsavedMergeRuleSetChanges) || (!openMergeRuleSet && unsavedChanges) ? "Click on Save button to save these changes. " : "")
        setIsDisabled(openMergeRuleSet ? !enableSaveMergeRuleSet : !enableSave)
        setDisableSaveButton((openMergeRuleSet ? !enableSaveMergeRuleSet : !enableSave) || !((openMergeRuleSet && unsavedMergeRuleSetChanges) || (!openMergeRuleSet && unsavedChanges)))
        setMandatory( (openMergeRuleSet ? !enableSaveMergeRuleSet : !enableSave) ? "Fill all required fields to enable Save.  " : "")
    },[openMergeRuleSet, unsavedChanges, unsavedMergeRuleSetChanges, enableSave, enableSaveMergeRuleSet])

    useEffect(() => {
        if(cancelConfirmed) {
            onCancelClick();
            setCancelConfirmed(false);
        }
    }, [cancelConfirmed])

    const onSaveClick = async () => {
        if (openMergeRuleSet) {
            dispatch(addUpdateMergeRuleSetInMergeRule())
            dispatch(resetMergeRuleSet())
        } else {
            var mergeruleObj = { ...mergeRule }
            if (mergeruleObj.subscriptionKey && !mergeruleObj.tenantName) {
                mergeruleObj = {
                    ...mergeruleObj,
                    tenantName: allAppsList.find(item => item.subscriptionKey === mergeruleObj.subscriptionKey)?.appName || ""
                }
            }
            if (originalMergeRule.mergeRuleId === "undefined") {
                mergeruleObj = { ...mergeruleObj, version: "1", modifiedBy: userDetails?.localAccountId || ""}
                var output = await dispatchApp(postMergeRule({ mergeRule: mergeruleObj }))
                if (output.payload === true) {
                    if (source === "default") {
                        dispatch(addMergeRuleBySubKey(mergeruleObj))
                        dispatch(updateMergeRulesByModel(mergeruleObj))
                        dispatch(addMergeRulesByModel(mergeruleObj))
                    } else {
                        dispatch(addMergeRule(mergeruleObj))
                    }
                    dispatch(updateEssentials(mergeruleObj))
                    dispatch(updateOriginalMergeRule(mergeruleObj))
                    dispatch(updateEnableSave(false))
                }
            }
            else {
                var version = mergeruleObj.version || '1'
                mergeruleObj = { ...mergeruleObj, version: (Number(version) + 1).toString(), modifiedBy: userDetails?.localAccountId || ""}
                var outputObj = await dispatchApp(patchMergeRule({reset:false, mergeRule: mergeruleObj }))
                if (outputObj.payload === true) {
                    if (source === "default") {
                        dispatch(editMergeRuleBySubKey(mergeruleObj))
                        dispatch(updateMergeRulesByModel(mergeruleObj))
                        dispatch(updateMergeRuleByModel(mergeruleObj))
                    } else {
                        dispatch(editMergeRule(mergeruleObj))
                    }
                    dispatch(updateEssentials(mergeruleObj))
                    dispatch(updateOriginalMergeRule(mergeruleObj))
                    dispatch(updateEnableSave(false))
                }
            }
        }
    }

    const onPreviousClick = () => {
        dispatch(updateTab("tab1"))
    }

    const onNextClick = () => {
        dispatch(updateTab("tab2"))
    }

    const onCancelClick = () => {
        if (openMergeRuleSet) {
            dispatch(resetOpenedMergeRuleSet())
            dispatch(resetMergeRuleSet)
        } else {
            dispatch(resetMergeRule)
            if (source !== "default") {
                navigate("/" + NavMenuItemType[NavMenuItemType.MergeRules]);
            } else {
                setOpenEditMergeRule(false)
            }
        }
    }
    return (
        <>
            <div className={source === "default" ? `mergerule-wrapper-workflow-bar-fixed` : `mergerule-footer ${isNavPanelOpen ? '' : 'collapsed'}`}>
                {/* {activeTab === "tab2" && <Button type='button' appearance='secondary' onClick={onPreviousClick} >Previous</Button>}
                <span style={{ marginLeft: '20px' }}></span>
                { activeTab === "tab1" &&  (<Button type='button' appearance='primary' onClick={onNextClick} >Next</Button>)}
                 */}
                {(message || isDisabled) &&
                    <span style={{ marginLeft: `${source === "default" ? `${isSideNavOpen ? `200px` : `450px`}` : `500px`}` }}></span>
                }
                {(!message && !isDisabled) &&
                    <span style={{ marginLeft: `${source === "default" ? `${isSideNavOpen ? `450px` : `700px`}` : `750px`}` }}></span>
                }
                <div className="unsaved-text">{unsaved}</div>
                {isDisabled &&
                    <div className="access-text">{mandatory}</div>
                }
                {!isDisabled &&
                    <div className="access-text">{message}</div>
                }
                <Button type='button' appearance='primary' onClick={onSaveClick} disabled={disableSaveButton} >Save</Button>
                <span style={{ marginLeft: '20px' }}></span>
                {(<Button type='button' appearance='secondary' onClick={() => {unsavedChanges || unsavedMergeRuleSetChanges ? setOpenCancelConfirmation(true) : onCancelClick()}}>Cancel</Button>)}
                <span style={{ marginLeft: '20px' }}></span>
            </div>
            {openCancelConfirmation && <CancelConfirmationPopup setCancelConfirmed={setCancelConfirmed} closePopup={setOpenCancelConfirmation}/>}
        </>
    )
}