import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Tooltip, Callout, FlyInPanel } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard, Dropdown } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { createNewMergeRuleSet, deleteExistingMergeRuleSet, updateCurrentMergeRuleSet, updateEnableSave, updateEssentials, updateExistingMergeRuleSet, updateOpenMergeRuleSet } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { AddEditMergeRuleSetsPage } from "./AddEditMergeRuleSetsPage";
import { MergeRuleSet, emptyMergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { EditMergeRuleSetSideBarMenu } from "./EditMergeRuleSetSideBarMenu";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { HelpContent } from "../merge-rules/help-mergerules/HelpContent";
import { PreviewMergeRule } from "../preview-mergerule/PreviewMergeRule";

export const EditMergeRuleSetsConfig: React.FC<any> = (props: any) => {
    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation();
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
    const originalMergeRule = useSelector((state: RootState) => state.mergeRuleById.originalMergeRule);
    const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list)
    const openMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.openMergeRuleSet);
    const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
    const userTeams = useSelector(permissionsSelector).userTeams;
    const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
    const [searchText, setSearchText] = useState("")

    const { mergeRuleId, source, defaultMergeRule } = props
    const [localMergeRuleData, setLocalMergeRuleData] = useState([])
    const [currentMergeRuleSetsData, setCurrentMergeRuleSetsData] = useState(source === "default" ? defaultMergeRule : mergeRule);
    const [filteredRows, setFilteredRows] = useState([] as MergeRuleSetDataGridRow[]);
    const [totalSets, setTotalSets] = useState(0)
    const [primarySets, setPrimarySets] = useState(0)
    const [secSets, setSecSets] = useState(0)

    const [selectedMergeRuleSet, setSelectedMergeRuleSet] = useState('')
    const [selectedMergeRuleSets, setSelectedMergeRuleSets] = useState([] as string[])
    const [openedMergeRuleSetType, setOpenedMergeRuleSetType] = useState('')
    const [openedMergeRuleSetId, setOpenedMergeRuleSetId] = useState('')
    const [openedMergeRuleSetRank, setOpenedMergeRuleSetRank] = useState('')
    const [currMergeRuleSet, setCurrMergeRuleSet] = useState(emptyMergeRuleSet)
    const [openHelpPanel, setOpenHelpPanel] = useState(false)
    const [openPreviewMRPage, setOpenPreviewMRPage] = useState(false)

    useEffect(() => {

        if (mergeRule.mergeRuleId && mergeRule.mergeRuleId !== "undefined" &&
            mergeRule.mergeRuleName &&
            mergeRule.subscriptionKey &&
            mergeRule.type
        ) {
            dispatch(updateEnableSave(true))
        } else {
            dispatch(updateEnableSave(false))
        }
    }, [mergeRule])

    useEffect(() => {

        if (originalMergeRule.mergeRuleId && originalMergeRule.mergeRuleId !== "undefined" &&
            originalMergeRule.mergeRuleName &&
            originalMergeRule.subscriptionKey &&
            originalMergeRule.type
        ) {
            dispatch(updateEnableSave(true))
        } else {
            dispatch(updateEnableSave(false))
        }
    }, [originalMergeRule])

    useEffect(() => {
        if (searchText) {
            var finalRows: MergeRuleSetDataGridRow[] = []
            filteredRows.map((row) => {
                if (row.cells?.field2?.toLowerCase().includes(searchText) ||
                    row.cells?.field7?.toLowerCase().includes(searchText) ||
                    row.cells?.field6?.toLowerCase().includes(searchText) ||
                    row.cells?.field20?.toLowerCase().includes(searchText) ||
                    row.cells?.field21?.toLowerCase().includes(searchText) ||
                    row.cells?.field4?.toLowerCase().includes(searchText) ||
                    row.cells?.field8?.toLowerCase().includes(searchText) ||
                    row.cells?.field9?.toLowerCase().includes(searchText) ||
                    row.cells?.field10?.toLowerCase().includes(searchText)
                ) {
                    finalRows.push(row)
                }
            })
            setFilteredRows(finalRows)
        } else {
            setFilteredRows(mergeRuleSetItemSelectorData(mergeRule, workflowsBySubKeys))
        }
    }, [searchText])

    useEffect(() => {
        setFilteredRows(mergeRuleSetItemSelectorData(mergeRule, workflowsBySubKeys))
    }, [mergeRule, workflowsBySubKeys])

    useEffect(() => {
        setFilteredRows(mergeRuleSetItemSelectorData(mergeRule, workflowsBySubKeys))
    }, [openPreviewMRPage])

    useEffect(() => {
        var total = filteredRows.length
        var primary = filteredRows.filter(x => { return x.cells.field6 === "Generate New Rec" }).length
        var sec = filteredRows.filter(x => { return x.cells.field6 !== "Generate New Rec" }).length
        setTotalSets(total)
        setPrimarySets(primary)
        setSecSets(sec)
    }, [filteredRows])

    const onRowSelectMergeRuleSet = (e: any) => {
        let selectedRows: string[] = [];
        selectedRows = filteredRows?.filter(x => x.selected)?.map(x => {
            return x.id.toString();
        });
        setSelectedMergeRuleSets(selectedRows);
        if (selectedRows.length === 1) {
            setSelectedMergeRuleSet(filteredRows?.filter(x => x.selected)[0].id.toString());
        } else {
            setSelectedMergeRuleSet('')
        }
    }

    const handleCreateNewMergeRule = () => {
        dispatch(createNewMergeRuleSet())
    }

    const handleUpdateMergeRuleSet = (rowId: any) => {
        dispatch(updateExistingMergeRuleSet(rowId))
    }

    const handleDeleteMergeRuleSet = () => {
        dispatch(deleteExistingMergeRuleSet(selectedMergeRuleSet))
        setSelectedMergeRuleSet('')
        setSelectedMergeRuleSets([])
    }

    const handlePreview = () => {
        setOpenPreviewMRPage(true)
    }

    const handlePreviewRequestClose = (event: any) => {
        if (event.target === event.currentTarget && event.detail.source === 'close-button') {
            setOpenPreviewMRPage(false)
            setSelectedMergeRuleSet('')
            setSelectedMergeRuleSets([])
        } else {
            event.preventDefault();
        }
    }

    var move = { up: false, down: false, curRank: "-1" }

    return (
        <>
            <div className='mergerule-form-table'>
                {!openMergeRuleSet &&
                    <div>
                        <div className={`header-wrapper ${source === 'default' ? 'default' : ''}`}>
                            <div style={{ fontWeight: '700', fontSize: "15px" }}>
                                All Rulesets <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                            </div>
                            <div>Each merge ruleset independently creates recommendations from scratch.The input recommendations used in one merge ruleset are not carried over to the next ensuring unique output recommendations for each set.</div>
                            <div style={{ fontWeight: "350", fontSize: "14px" }}><i>NOTE: </i> Atleast one RuleSet should be created with Recommendation Generation Type - <i>Generate New Rec</i> </div>
                            <div style={{ display: "flex", marginTop: "20px" }}>
                                <CommandBar style={{ flex: 2 }}>
                                    <Button appearance="command" style={{ fontSize: "14px" }} disabled={!access.canEdit} onClick={handleCreateNewMergeRule} >
                                        <Icon slot="start" name="add"></Icon>
                                        Create New
                                    </Button>
                                    <Button
                                        appearance="command"
                                        style={{ marginLeft: "40px", fontSize: "14px" }}
                                        disabled={!access.canEdit || selectedMergeRuleSets.length !== 1 || (mergeRule.primaryMergeRuleSet.length === 1 && selectedMergeRuleSet === mergeRule.primaryMergeRuleSet[0].id)}
                                        onClick={handleDeleteMergeRuleSet}
                                    >
                                        <Icon slot="start" name="delete"></Icon>
                                        Delete
                                    </Button>
                                    <Button appearance="command" style={{ marginLeft: "40px", fontSize: "14px" }}
                                        disabled={selectedMergeRuleSets.length !== 1}
                                        onClick={handlePreview}
                                    >
                                        <Icon slot="start" name="redeye"></Icon>
                                        Preview
                                    </Button>
                                    <Button id= "editmergesetHelp" appearance="command" style={{ marginLeft: "40px", fontSize: "14px" }} onClick={() => { setOpenHelpPanel(!openHelpPanel); }}>
                                        <Icon slot="start" name="help"></Icon>
                                        Help
                                    </Button>
                                </CommandBar>
                            </div>
                            <div>
                                <hr />
                                <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                    <SearchBox style={{ width: "300px", marginLeft: "0px" }} placeholder="Search by Text" onInput={(e: any) => { setSearchText(e.target.value) }} onHeClear={() => { setSearchText("") }}></SearchBox>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: `${source === 'default' ? '16em': '13em'}` }}>
                            <DataGrid
                                style={{ height: 'auto' }}
                                rows={filteredRows}
                                columns={columns}
                                select="multiple"
                                onHeRowSelectChange={(e) => { onRowSelectMergeRuleSet(e.detail) }}
                                onHeSelectAllChange={(e) => { onRowSelectMergeRuleSet(e.detail) }}
                            >
                                {access.canEdit &&
                                <Callout slot="field0-column-header" heading="Rank RuleSets" fixed-placement>
                                    <Button slot="anchor" appearance="link">
                                        <Icon label="column button" name="info" style={{ fontSize: '14px' }}></Icon>
                                    </Button>
                                    <p>
                                        Ranking of Rulesets is of utmost importance because they are are executed in the sequence of ranking.<br />Data consumed in previous ruleset is not passed onto next ruleset.<br />All Rulesets with Recommendation Generation Type - <i>Generate New Rec</i> have to be ranked in sequential order first and then followed by those with Recommendation Generation Type - <i>Enrich Existing Rec</i>.<br />
                                    </p>
                                </Callout>
                                }
                                {filteredRows.map(row => {
                                    return (
                                        <>
                                            <span slot={`field0-${row.id}`} key={`field0-${row.id}`}>
                                                <div style={{ color: 'var(--he-color-primary-700)', display: 'flex', alignItems: 'center' }}>
                                                    {access.canEdit && <Dropdown fixedPlacement={true}>
                                                        <Button appearance="command" slot="trigger"
                                                        //  disabled={(row.cells.field6 === "Generate New Rec" && mergeRule.primaryMergeRuleSet.length === 1) || (row.cells.field6 === "Enrich Existing Rec" && mergeRule.secondaryMergeRuleSet.length === 1)}
                                                        >
                                                            <Icon label="start" slot="start" name='more' />
                                                        </Button>
                                                        <EditMergeRuleSetSideBarMenu subKey={mergeRule.subscriptionKey} moveEnabledProp={{
                                                            ...move,
                                                            up: row.cells.field1 !== "1" && row.cells.field1 !== (primarySets + 1).toString(),
                                                            down: row.cells.field1 !== totalSets.toString() && row.cells.field1 !== (primarySets).toString(),
                                                            curRank: row.cells.field1
                                                        }} />
                                                    </Dropdown>}
                                                </div>
                                            </span>

                                            <div slot={`field2-${row.id}`} key={`field2-${row.id}`} style={{ color: "#0078D4", cursor: "pointer", width: "150px" }}
                                                onClick={() => { handleUpdateMergeRuleSet(row.id) }}
                                            >
                                                {row.cells.field2}
                                            </div>
                                            <HoverCard slot={`field7-${row.id}`} key={`field7-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                                                <div style={{ width: '150px' }} aria-label={'description'} role="button" slot="anchor" key={`field34-${row.id}`}>{row.cells.field7 === null || row.cells.field7 === '' ? '' : row.cells.field7 != null && row.cells.field7.length > 30 ? row.cells.field7.substring(0, 30) + "..." : row.cells.field7}</div>
                                                {row.cells.field7 != undefined &&
                                                    <code style={{ color: "black" }}>{row.cells.field7.split(",").map(eachRow => {
                                                        return <div>{eachRow}</div>
                                                    })}
                                                    </code>
                                                }
                                                {row.cells.field7 == undefined &&
                                                    <code style={{ color: "black" }}>
                                                    </code>
                                                }
                                            </HoverCard>
                                            <div slot={`field6-${row.id}`} key={`field6-${row.id}`} style={{ width: "150px" }}>
                                                {row.cells.field6}
                                            </div>
                                            <div slot={`field20-${row.id}`} key={`field20-${row.id}`} style={{ width: "300px" }}>
                                                {row.cells.field20 &&
                                                    <span>{row.cells.field20.split(",").map(eachRow => {
                                                        return <div>{eachRow}<br /></div>
                                                    })}
                                                    </span>
                                                }
                                            </div>
                                            <div slot={`field21-${row.id}`} key={`field21-${row.id}`} style={{ width: "300px" }}>
                                                {row.cells.field21 &&
                                                    <span>{row.cells.field21.split(",").map(eachRow => {
                                                        return <div>{eachRow}<br /></div>
                                                    })}
                                                    </span>
                                                }
                                            </div>
                                            <div slot={`field3-${row.id}`} key={`field3-${row.id}`} style={{ width: "300px" }}>
                                                {row.cells.field3 &&
                                                    <span>{row.cells.field3.split(",").map(eachRow => {
                                                        return <div>{eachRow}<br /></div>
                                                    })}
                                                    </span>
                                                }
                                            </div>
                                            <div slot={`field4-${row.id}`} key={`field4-${row.id}`} style={{ width: "300px" }}
                                            >
                                                {row.cells.field4 &&
                                                    <div> {
                                                        row.cells.field4.split("|").map((x) => {
                                                            return <div>{x}</div>
                                                        })}
                                                    </div>
                                                }
                                                {!row.cells.field4 &&
                                                    <div></div>
                                                }
                                            </div>
                                            <div slot={`field8-${row.id}`} key={`field8-${row.id}`} style={{ width: "300px" }}
                                            >
                                                {row.cells.field8}
                                            </div>
                                            <div slot={`field9-${row.id}`} key={`field9-${row.id}`} style={{ width: "300px" }}
                                            >
                                                {row.cells.field9}
                                            </div>
                                            <div slot={`field10-${row.id}`} key={`field10-${row.id}`} style={{ width: "300px" }}>
                                                {row.cells.field10 &&
                                                    <div>{row.cells.field10.split("|").map(eachRow => {
                                                        return <div style={{ width: "300px" }}>{eachRow}</div>
                                                    })}
                                                    </div>
                                                }
                                            </div>
                                            <div slot={`field11-${row.id}`} key={`field11-${row.id}`} style={{ width: "300px" }}>
                                                {row.cells.field11 &&
                                                    <div>{row.cells.field11.split("|").map(eachRow => {
                                                        return <div style={{ width: "300px" }}>{eachRow}</div>
                                                    })}
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    )
                                })}
                                <span slot="no-records">No Rulesets found.</span>
                            </DataGrid>
                            {openHelpPanel &&
                                <HelpContent openHelpPanel={openHelpPanel} setOpenHelpPanel={setOpenHelpPanel} />
                            }
                            {openPreviewMRPage &&
                                <FlyInPanel style={{ '--size': 'var(--he-panel-size-large)' }}
                                    open={openPreviewMRPage}
                                    onHeRequestClose={handlePreviewRequestClose}
                                    heading={`Preview MergeRuleSet - ${selectedMergeRuleSet ? filteredRows.filter(x => { return x.id === selectedMergeRuleSet })[0].cells.field2 : ""}`}>
                                    <PreviewMergeRule mergeRuleName={mergeRule.mergeRuleId} mergeruleSetId={selectedMergeRuleSet} localmergeRule={mergeRule} subKey={mergeRule.subscriptionKey} />
                                </FlyInPanel>
                            }
                        </div>
                    </div>
                }
                {openMergeRuleSet && <AddEditMergeRuleSetsPage source={source} />}
            </div>
        </>
    )
}