import { Schedule } from "../../../model/workflows/Schedule.model";
import { Select, Option } from "../../../common/HarmonyEnablers";
import { updateSchedule } from "../workflow-by-id-redux/WorkflowById.actions.redux";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../root-redux/RootState";
export const ScheduleWorkflowMonth: React.FC<{
  scheduleObj: Schedule;
  dynamic?: boolean;
}> = React.memo(({ scheduleObj, dynamic }) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Select
      value={scheduleObj?.dayOfMonth}
      slot="action"
      fixed-placement
      placeholder={"Select"}
      name="subkey"
    >
      {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
        <Option
          key={item}
          role="menuitemcheckbox"
          onHeSelected={(e: any) =>
            dispatch(
              updateSchedule({
                ...scheduleObj,
                dayOfMonth: e.target.value,
              })
            )
          }
          value={item < 10 ? "0" + item : item}
        >
          {item < 10 ? "0" + item : item}
        </Option>
      ))}
    </Select>
  );
});
