import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { ModelMapping } from "../../model/configModels/ModelMapping.model";
import { getModelMapping } from "../../services/GetModelMapping.api";
import { updateModelMapping } from "../../services/PostModelMapping.api";

interface ModelMappingConfig {
    modelMappingObject: ModelMapping[],
    status: RequestStatus,
    error: string | undefined,
    updateSuccessStatus: boolean,
    modelProviderUserInput: string
}

const initialState: ModelMappingConfig = {
    modelMappingObject: [] as ModelMapping[],
    status: RequestStatus.idle,
    error: '',
    updateSuccessStatus: false,
    modelProviderUserInput: ''
}

export const modelMappingConfigSlice = createSlice({
    name: 'modelMapping',
    initialState,
    reducers: {
        resetModelMapping: (state) => {
            state.modelProviderUserInput = '';
            state.status = RequestStatus.idle
        },
        setModelProviderInput: (state, action: PayloadAction<string>) => {
            state.modelProviderUserInput = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getModelMapping.pending, (state, action) => {
                state.status = RequestStatus.loading
            }).addCase(getModelMapping.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.modelMappingObject = payload
                }
                state.error = undefined
            })
            .addCase(getModelMapping.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
            .addCase(updateModelMapping.pending, (state, action) => {
                state.status = RequestStatus.loading
            }).addCase(updateModelMapping.fulfilled, (state, action) => {
                state.status = RequestStatus.succeeded
                const payload = action.payload
                if (payload) {
                    state.updateSuccessStatus = payload
                }
                state.error = undefined
            })
            .addCase(updateModelMapping.rejected, (state, action) => {
                state.status = RequestStatus.failed
                state.error = action.error.message
            })
    }
});

export const modelMappingConfigReducer = modelMappingConfigSlice.reducer