import axios from 'axios';
import { BASE_URL, BASE_URL_API_DEV, BASE_URL_API_PROD, BASE_URL_API_UAT } from './Constants';
import {v4 as uuidv4} from 'uuid';
import { AuthClientProvider } from '../../auth/AuthClientProvider';
import { Logger } from '../../utils/Logger';
import { jwtDecode } from 'jwt-decode';

export const getRequest = async<T> (apiPath: string, env = 'none') => {
  const correlationId = uuidv4();

  Logger.Instance.trackTrace(`start getRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId
  });

  try {
    const response = await axios.get<T>(`${BASE_URL}/${apiPath}`, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(env),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
      },
    });

    Logger.Instance.trackTrace(`completed getRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId
    });

    return response;
  } catch (error) {

    Logger.Instance.trackException({error: error as Error}, {
      event: `error getRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId
    });
    throw error;
  }
};

export const getRequestPerEnv = async<T> (apiPath: string, env = 'none') => {
  const correlationId = uuidv4();
  const baseUrl=() => {
    switch(env){
      case 'dev':
        return BASE_URL_API_DEV;
        case 'uat':
          return BASE_URL_API_UAT;
          case 'prod':
            return BASE_URL_API_PROD;  
          default: 
          return BASE_URL_API_DEV;
     }
  }
  const url = baseUrl()
  Logger.Instance.trackTrace(`start getRequest ${apiPath}`, {
    baseUrl: url,
    correlationId: correlationId
  });

  try {
    const response = await axios.get<T>(`${url}/${apiPath}`, {
      headers: {
        'Authorization': await AuthClientProvider.getInstance().getAuthToken(env),
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
      },
    });

    Logger.Instance.trackTrace(`completed getRequest ${apiPath}`, {
      baseUrl: url,
      correlationId: correlationId
    });

    return response;
  } catch (error) {

    Logger.Instance.trackException({error: error as Error}, {
      event: `error getRequest ${apiPath}`,
      baseUrl: url,
      correlationId: correlationId
    });
    throw error;
  }
};

export const getRequestPlatformApi = async<T>(apiPath: string, token: string, subkey: string, env: string, isIgnoreLoggedInUser:boolean, correlationIdParam: string) => {
  const correlationId = correlationIdParam ? correlationIdParam : uuidv4();
  var authToken = '';
  var localAppId = '';
  var localAud = '';
  var localTypeOfToken = '';
  var impersonate = '';
  var debug = '';
  var hasExpired = false
  var includeDefault = ''
  if (!token) {
    authToken = await AuthClientProvider.getInstance().getAuthToken(env);
  } else {
    authToken = token.includes("Bearer") ? token : 'Bearer ' + token
  }
var errorMsg = ''
  try {
    var jwt = jwtDecode(authToken)
    if (jwt) {
      var decodedToken = JSON.parse(JSON.stringify(jwt))
      
      if(Date.now() >= decodedToken.exp * 1000) {
        errorMsg = 'Input Token has Expired. Please input a new one'
      }
      localAppId = decodedToken.appid
      localAud = ((env === "dev" || env === "uat") && decodedToken.aud === "api://1aaf894a-0fdb-487c-ace5-cc90e423ff5c") ||
        env === "prod" && decodedToken.aud === "api://fc8c87b7-2b16-4641-b29d-f8e668c9838e"
        ? "" : decodedToken.aud
      localTypeOfToken = decodedToken?.name ? "user" : "app"
      //if(!token) impersonate = '&impersonate=' + localAppId
      debug = '&debug=true'
      if(!token) includeDefault = '&includeDefault=true' 
    }
  } catch (error) {
    errorMsg = 'Input Token is invalid. Please input a valid one.'
  }
  Logger.Instance.trackTrace(`start getRequest ${apiPath}`, {
    baseUrl: BASE_URL,
    correlationId: correlationId
  });

  try {
    const response = await axios.get<T>(`${apiPath}${impersonate}${debug}${includeDefault}`, {
      headers: {
        'Authorization': authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-ms-correlation-id": correlationId,
        "nebula-subscriptionkey": subkey,
        "correlationId": correlationId,
        "appId": localAppId,
        "aud": localAud,
        "typeOfToken": localTypeOfToken,
        "errorMsg": errorMsg,
        "isIgnoreLoggedInUser": isIgnoreLoggedInUser || !token || false
      },
    });

    Logger.Instance.trackTrace(`completed getRequest ${apiPath}`, {
      baseUrl: BASE_URL,
      correlationId: correlationId
    });

    return response;
  } catch (error) {
    var err  =error as Error
    
    if(errorMsg) {
    err = {...err,
      name: errorMsg,
      stack: localAppId + "|"+localAud +"|"+localTypeOfToken
    }
    
  } else {
    err = {...err,
      stack: localAppId + "|"+localAud +"|"+localTypeOfToken
    }
  }
    Logger.Instance.trackException({ error: err }, {
      event: `error getRequest ${apiPath}`,
      baseUrl: BASE_URL,
      correlationId: correlationId,
      
    });
    throw err;
  }
};
