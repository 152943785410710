import { createSlice } from "@reduxjs/toolkit"
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { getCampaignConfigDetails } from "../../../services/SPAAdmin/CampaignConfig.api";
import { CampaignConfig } from "../../../model/SPAModels/campaigns/campaignConfig.Model";


interface CampaignConfigState  {
    campaignConfig: CampaignConfig | undefined,
    requestStatus: RequestStatus,
    error: string | undefined
}

const initialState: CampaignConfigState = {
    campaignConfig: undefined,
    requestStatus: RequestStatus.idle,
    error: undefined
};

export const CampaignConfigSlice = createSlice({
    name: 'campaignConfig',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCampaignConfigDetails.pending, (state, _) => {
                state.requestStatus = RequestStatus.loading;
                state.error = undefined
            })
            .addCase(getCampaignConfigDetails.fulfilled, (state, action) => {
                state.requestStatus = RequestStatus.succeeded
                state.error = undefined
                state.campaignConfig = action.payload;
            })
            .addCase(getCampaignConfigDetails.rejected, (state, action) => {
                state.requestStatus = RequestStatus.failed
                state.error = action.error.message
            })
    }
})

export const campaignConfigReducer = CampaignConfigSlice.reducer;