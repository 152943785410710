import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest"
import { MergeRule } from "../../model/merge-rules/MergeRule.model"
import { RootState } from "../../root-redux/RootState";
import { getRequestReleaseManagement } from "../utils/GetRequestReleaseManagement";

export const getMergeRules = createAsyncThunk<any,string, { state: RootState }>('mergerules/getMergeRules', async (env:string,{ getState }) => {
  const selectedEnvItem = getState().env.selectedEnvItem;
  const response = await getRequest<MergeRule[]>(`${selectedEnvItem}/mergerules`)
  return response
})

export const getMergeRuleById = createAsyncThunk<MergeRule[], string, { state: RootState }>('mergerules/getMergeRuleById', async (mergeRuleId: string, { getState }) => {
  const selectedEnvItem = getState().env.selectedEnvItem;
  var response = await getRequest<MergeRule[]>(`${selectedEnvItem}/mergerules?id=${mergeRuleId}`);
  return response.data
})

export const getMergeRuleByModel = createAsyncThunk<any, string, { state: RootState }>('mergerules/getMergeRuleByModel', async (mergeRuleModel: string, { getState }) => {
  const selectedEnvItem = getState().env.selectedEnvItem;
  var response = await getRequest<MergeRule[]>(`${selectedEnvItem}/mergerules?model=${mergeRuleModel}`)
  return response
})

export const getMergeRuleBySubKey = createAsyncThunk<any, string, { state: RootState }>('mergerules/getMergeRuleBySubKey', async (mergeRulesubKey: string, { getState }) => {
  const selectedEnvItem = getState().env.selectedEnvItem;
  var response = await getRequest<MergeRule[]>(`${selectedEnvItem}/mergerules?subKey=${mergeRulesubKey}`)
  return response
})

export const getMergeRules_dev = createAsyncThunk<any,string>('dev/mergerules/getMergeRules', async () => {
  const response = await getRequestReleaseManagement<MergeRule[]>(`dev/mergerules`)
  return response
});

export const getMergeRules_uat = createAsyncThunk<any,string>('uat/mergerules/getMergeRules', async () => {
  const response = await getRequestReleaseManagement<MergeRule[]>(`uat/mergerules`)
  return response
});
