import { Column, Row } from "@harmony/enablers/components/data-grid/data-grid";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { WorkflowSummary } from "../../model/workflows/Workflow.model";

export interface AddedFieldsDataGridRow extends Row {
  cells: {
    field1: string,
    field2: string
  }
}
export const addedFieldColumns: Column[] = [
  {
    field: 'field1',
    content: 'Name',
  },
  {
    field: 'field2',
    content: '',
  }
];

export const addedFieldsItemSelectorData = (data: MergeRuleSet): AddedFieldsDataGridRow[] => {
  var i = 1;
  var transformedToRowItems: AddedFieldsDataGridRow[] = []
  if (data?.mergeOn?.fields?.default) {
    data.mergeOn.fields.default.forEach((item: string) => {
      transformedToRowItems.push(
        {
          id: i,
          cells: {
            field1: item,
            field2: ''
          }
        })
      i++;
    });
  }
  return transformedToRowItems
}