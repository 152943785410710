export class MergeRulePreviewUserInput {
    public tpids!: string[]
    public mergeRules!: string[]
    public inputFrom!: string
    public isOnlyMerge!: boolean
    public tpidLimit!: string
    public tpidSource!: string
    public distinctField!:string
    public mergeRuleSetId!: string
    public onlyOneMergeRuleSet!: boolean
}

export const defaultPreviewUserInputForNonWorkflow = {
    tpids: [] as string[],
    isOnlyMerge: true,
    mergeRules: [] as string[],
    inputFrom: "existing",
    tpidSource: "user",
    tpidLimit: "1",
    distinctField: "",
    mergeRuleSetId: "",
    onlyOneMergeRuleSet: false
}

export const defaultPreviewUserInput = {
    tpids: [] as string[],
    isOnlyMerge: true,
    mergeRules: [] as string[],
    inputFrom: "both",
    tpidSource: "workflow",
    tpidLimit: "1",
    distinctField: "",
    mergeRuleSetId: "",
    onlyOneMergeRuleSet: false
}
