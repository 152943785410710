
import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from '../utils/PostRequest';
import { RootState } from '../../root-redux/RootState';
import { IMergeRuleResponse, MergeRule } from '../../model/merge-rules/MergeRule.model';
import { postRequestReleaseManagement } from '../utils/PostRequestReleaseManagement';

export const propagateMergeRule = createAsyncThunk<any,{ mergeRule: MergeRule,env:string},{ state: RootState }>('mergerules/propagateMergeRule', async ({mergeRule, env}, {getState}) => {
  const payload = mergeRule
  const selectedEnv = env ? env : getState().env.selectedEnvItem
  const response = await postRequestReleaseManagement<IMergeRuleResponse,MergeRule>(`${selectedEnv}/mergerules/propagate`, payload)
  return response.data
});

export const propagateMergeRule_uat = createAsyncThunk<any,{ mergeRule: MergeRule}>('uat/mergerules/propagateMergeRule', async ({mergeRule}) => {
  const payload = mergeRule
  const response = await postRequestReleaseManagement<IMergeRuleResponse,MergeRule>(`uat/mergerules/propagate`, payload)
  return response.data
});

export const propagateMergeRule_prod = createAsyncThunk<any,{ mergeRule: MergeRule}>('prod/mergerules/propagateMergeRule', async ({mergeRule}) => {
  const payload = mergeRule
  const response = await postRequestReleaseManagement<IMergeRuleResponse,MergeRule>(`prod/mergerules/propagate`, payload)
  return response.data
})