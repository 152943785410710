import { Connection } from "./Connection.model";

export class ApiConnection extends Connection
{
    public batchSize!: string
    public batchKey!: string
    public endPointUrl!: string
    public method!: string
    public urlParameters!: string
    public headers!: {key: string, value: string}[]
    public primaryKeyColumns!: string[]
    public body!: {key: string, value: string}[]
    public authType!: string
    public grantType!: string
    public clientId!: string
    public clientSecret!: string
    public scope!: string
    public resource!: string
    public userName!: string
    public password!: string
    public ocp_Apim_Subscription_Key!: string
    public publishMap!: {key: string, value: string}[]
}