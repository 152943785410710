import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "../utils/GetRequest";
import { SPA_APP_ID} from "../../feature-components/spa-admin/SPAConstants";
import { CampaignConfig } from "../../model/SPAModels/campaigns/campaignConfig.Model";
import { RootState } from "../../root-redux/RootState";
import { AppPersona } from "../../model/app-persona/AppPersona.model";

export const getCampaignConfigDetails = createAsyncThunk<any, void, { state: RootState }>('campaignConfig/getConfig', async (_, { getState }) => {

    const env = getState().env.selectedEnvItem;
    
    const appPersonaMappingResponse = getState().appPersonas.list;
    let appPersonaMapping: AppPersona | undefined = appPersonaMappingResponse.find((item: AppPersona) => item.appId.map(id => id.toLowerCase()).includes(SPA_APP_ID));

    const systemconfigResponse = await getRequest<any>(`${env}/configuration/systemconfig`);
    const systemconfigMapping = systemconfigResponse.data.campaignLayoutConfigObject.campaignLayoutConfigs.find((item: CampaignConfig) => {
        return item.id === appPersonaMapping!.campaignLayoutConfigurationId;
    });

    systemconfigMapping.subscriptionKey = appPersonaMapping!.subscriptionKey;
    return systemconfigMapping;
})