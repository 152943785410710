import { Button } from "../../merge-rules/MergeRulesScope"
import { Dialog } from "../../../common/HarmonyEnablers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { updateCurrentMergeRuleSet } from "../../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeCondition } from "../../../model/merge-rules/MergeCondition.model";

export const ChangeMergeOnPopup: React.FC<any> = (props:any) => {
  const dispatch = useDispatch<AppDispatch>()
  const {nextTarget, setOpenPopUp, setValue} = props;
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);

  function handleRequestClose(event:any) {
    if (event.detail.source === 'overlay') {
      event.preventDefault();
    }
  }

  const handleYes = () => {
    if(nextTarget === "conditions") {
      var localMergeOn = {...currentMergeRuleSet.mergeOn}
      var localFields = {...localMergeOn.fields, default: []}
      localMergeOn = {...localMergeOn, fields: localFields}
      dispatch(updateCurrentMergeRuleSet({...currentMergeRuleSet, mergeOn: localMergeOn}))
    } else if (nextTarget === "fields") {
      var localMergeOn = {...currentMergeRuleSet.mergeOn}
      var localMergeConditions = [] as MergeCondition[]
      localMergeOn = {...localMergeOn, mergeConditions: localMergeConditions}
      dispatch(updateCurrentMergeRuleSet({...currentMergeRuleSet, mergeOn: localMergeOn}))
    }
    setValue(nextTarget)
    setOpenPopUp(false)
  }

  const handleNo = () => {
    setOpenPopUp(false)
  }

  return (
    <>
      <Dialog
        heading="Change MergeOn Confirmation"
        open
        onHeRequestClose={handleRequestClose}
        onHeAfterHide={({ target, currentTarget }) =>
          target === currentTarget ? setOpenPopUp(false) : null
        }
      >
        {nextTarget === "conditions" &&
        <p>Choosing to merge on <b>Specific Value(s) of Fields</b> will delete the fields added as part of choice <b>Fields</b>. Do you want to proceed?</p>
        }
        {nextTarget === "fields" &&
        <p>Choosing to merge on <b>Fields</b> will delete the conditions added as part of choice <b>Specific Value(s) Of Fields</b>. Do you want to proceed?</p>
        }
        <Button
          slot="footer"
          appearance="primary"
          onClick={handleYes}
        >
          Yes
        </Button>
        <Button slot="footer" onClick={handleNo}>
          No
        </Button>
      </Dialog>
    </>
  );
};
