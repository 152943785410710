import { useEffect, useState } from "react"
import { EditMergeRulesHeader } from "./EditMergeRulesHeader"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useFetcher, useLocation } from "react-router-dom";
import { MergeRule, emptyMergeRule } from "../../model/merge-rules/MergeRule.model";
import { useSelector } from "react-redux";
import { getMergeRuleById } from "../../services/get-merge-rules/GetMergeRules.api";
import { EditMergeRulesFooter } from "./EditMergeRulesFooter";
import { getConnections } from "../../services/GetConnections.api";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
//import { PreviewMergeRule } from "../preview-merge-rules/PreviewMergeRule";
//import { initPreviewMergeRule } from "../../services/GetPreviewMergeRule.api";
import { Badge, Checkbox, Icon, TextField, Text, Select, Option, Accordion, AccordionItem, RadioGroup, Radio, FlyInPanel, TextArea } from "../../common/HarmonyEnablers";
import { CommandBar, DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { MergeRuleSetDataGridRow, columns, mergeRuleSetItemSelectorData } from './MergeRuleSetListColumns';
import { NavMenuItemType } from '../../model/NavMenu.model';
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, TabPanel } from '../../common/HarmonyEnablers'
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { Loader } from "../../shared-components/loader/Loader";
import { TenantAdminNavMenuItemType } from "../tenant-admin/TenantAdminNavMenuItem.model";
import { v4 as uuidv4 } from 'uuid';
import { DeleteConfirmationPopup } from "./popups/DeleteConfirmationPopup";
import { SuccessNotification } from "../notifications/SuccessNotification";
import { ErrorNotification } from "../notifications/ErrorNotification";
import { getPersonaMapping } from "../../services/GetPersonaMapping.api";
import { updateCurrentMergeRuleSet, updateEnableSave, updateEssentials } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { MergeOverride } from "../../model/merge-rules/MergeOverride.model";
import FilterQueryBuilder from "./FilterQueryBuilder";
import { getValue } from "../preview-mergerule/PreviewFunctions";
import { convertQueryToFilterFormat, countBrackets, sortByFieldsNameForFilter, sortByModelsNameForFilter } from "./MergeRuleSetHelper";
import informationConfig from "../../shared-content/inputDescription";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const MergeRuleSetFilterC: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation();
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const typeMapping = useSelector((state: RootState) => state.typeMappingConfig.tyepMapping.typeMapping);
  const userTeams = useSelector(permissionsSelector).userTeams;
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list)
  const [filter, setFilter] = useState(currentMergeRuleSet.filter || "")
  const [filterRec, setFilterRec] = useState(currentMergeRuleSet.filterRec || "")
  const [filterString, setFilterString] = useState(currentMergeRuleSet.jsonQueryStringFilter || "")
  const [filterRecString, setFilterRecString] = useState(currentMergeRuleSet.jsonQueryStringFilterRec || "")
  const [commandError, setcommandError] = useState("")

  const handleQueryReturn = (query: string, jsonTree: string) => {
    setFilter(query)
    setFilterString(jsonTree)
    var newFilter = convertQueryToFilterFormat(query, fieldsList, workflowsBySubKeys)
    if(countBrackets(newFilter) === 0) {
      dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, filterToDisplay: query, filter: newFilter, jsonQueryStringFilter: jsonTree }))
      setOpenPredictionFilter(false)
    } else {
      setcommandError("Filter not configured properly. Please contact Nebula team to resolve this issue")
    }
  }

  const handleQueryReturnForRec = (query: string, jsonTree: string) => {
    setFilterRec(query)
    setFilterRecString(jsonTree)
    var newFilterRec = convertQueryToFilterFormat(query, fieldsList, workflowsBySubKeys)
    if(countBrackets(newFilterRec) === 0) {
      dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, filterRecToDisplay: query, filterRec: newFilterRec, jsonQueryStringFilterRec: jsonTree }))
      setOpenRecommendationFilter(false)
    } else {
      setcommandError("Filter not configured properly. Please contact Nebula team to resolve this issue")
    }
  }

  const [modelList, setModelList] = useState([] as [string, string][])
  const [fieldsNameList, setFieldsNameList] = useState([] as string[])
  const [fieldsList, setFieldsList] = useState([] as [string, string, string][])

  const [openPredictionFilter, setOpenPredictionFilter] = useState(false)
  const [openRecommendationFilter, setOpenRecommendationFilter] = useState(false)

  useEffect(() => {
    if (openPredictionFilter || openRecommendationFilter) {
      var list = [] as [string, string][]
      //MRTODO
      //Ideally the commented line is right but Eagle seems to be using other models also other than the one relevant for the particular merg rule set, hence had to change here.
      var workflows = //openPredictionFilter ? workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) }) :
       workflowsBySubKeys.filter(x => { return mergeRule.sourceFilter.includes(x.modelName) })

      workflows.forEach((item) => {
        list.push([item.config.workflowName, "String"])
      })
      list = sortByModelsNameForFilter(list)
      setModelList(list)

      var localfieldsList = [] as [string, string, string][]
      var localfieldsNameList = [] as string[]
      workflows.forEach((item) => {
        var fields = item.fields
        if (fields != null) {
          Object.keys(fields).map((eachField) => {
            if (!localfieldsNameList.includes(eachField) && eachField !== "SourceId") {
              localfieldsList.push([eachField, fields[eachField], item.config.workflowName])
              localfieldsNameList.push(eachField)
            }
          })
        }
      })
      localfieldsList = sortByFieldsNameForFilter(localfieldsList)
      setFieldsList(localfieldsList)
      setFieldsNameList(localfieldsNameList)
    } else {
      setFieldsList([])
      setFieldsNameList([])
    }
  }, [openPredictionFilter, openRecommendationFilter])

  const handleRequestClose = (event: any) => {
    if (event.target !== event.currentTarget || event.target === event.currentTarget && event.detail.source === 'overlay') {
      event.preventDefault();
    } else {
      setOpenPredictionFilter(false)
      setOpenRecommendationFilter(false)
      setcommandError("")
    }
  }

  const disableSave = () => {
    // do nothing
  }

  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <InformationTextMergeRule text={informationConfig.editMergeRuleSetFilter} type="Tab" />
      </div>
      <div style={{ display: "flex" }}>
        <Button appearance="command" style={{ fontSize: "14px" }} onClick={() => { setOpenPredictionFilter(true); setcommandError("") }} >
          <Icon slot="start" name="edit"></Icon>
          View/Configure Filter on Insights/Input Recommendations
        </Button>
        {openPredictionFilter &&
          <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)' }}
            open={openPredictionFilter}
            heading="Configure Filter on Insights/Input Recommendations"
            onHeRequestClose={handleRequestClose}>
            <div style={{ paddingBottom: "20px", fontSize: "15px" }}>
              <span>Add/Edit filter on Insights/Input Recommendations</span>
            </div>
            <FilterQueryBuilder subKey={mergeRule.subscriptionKey} size={'medium'} setOpenFilter={setOpenPredictionFilter} modelList={modelList} fieldsList={fieldsList} inputView={""} currentJsonTree={filterString || ""} onQueryReturn={handleQueryReturn} disableSave={disableSave} commandError={commandError} setcommandError={setcommandError}/>
          </FlyInPanel>
        }
      </div>
      
        <Button appearance="command" style={{ fontSize: "14px" }} onClick={() => { setOpenRecommendationFilter(true); setcommandError("") }} >
          <Icon slot="start" name="edit"></Icon>
          View/Configure Filter on Recommendations
        </Button>
      
      {openRecommendationFilter &&
        <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)' }}
          open={openRecommendationFilter}
          heading="Configure Filter on Recommendations"
          onHeRequestClose={handleRequestClose}>
          <div style={{ paddingBottom: "20px", fontSize: "15px" }}>
            <span>Add/Edit filter on Recommendations</span>
          </div>
          <FilterQueryBuilder subKey={mergeRule.subscriptionKey} size={'medium'} setOpenFilter={setOpenRecommendationFilter} modelList={modelList} fieldsList={fieldsList} inputView={""} currentJsonTree={filterRecString || ""} onQueryReturn={handleQueryReturnForRec} disableSave={disableSave} commandError={commandError} setcommandError={setcommandError}/>
        </FlyInPanel>
      }
    </>
  )
}
export const MergeRuleSetFilter = withErrorBoundary("merge rule set Filter", MergeRuleSetFilterC);