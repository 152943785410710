
import { IExceptionTelemetry } from '@microsoft/applicationinsights-common';
import {} from '../../src'
import {appInsights} from '../config/appInsights'
import {v4 as uuidv4} from 'uuid';

export class Logger{
    private static _instance: Logger;
    private _sessionId: string = '';

    private constructor() {
        if (!this._sessionId) {
            this._sessionId = uuidv4();
        }
    }

    static get Instance() {
        if (!Logger._instance) {
            Logger._instance = new Logger();
        }

        return Logger._instance;
    }

    trackEvent(event: string, properties: {[key: string]: any}): void {
        appInsights.trackEvent({
            name: event,
            properties: {
              sessionId: this._sessionId,
              ...properties
            },
        });        
    }

    trackTrace(name: string, properties: {[key: string]: any}): void {
        appInsights.trackTrace({
            message: name,
            properties: {
              sessionId: this._sessionId,
              ...properties
            },
        });        
    }

    trackException(exception: IExceptionTelemetry, properties: {[key: string]: any}): void {
        appInsights.trackException(
            exception, 
            {
                sessionId: this._sessionId,
                ...properties
            }
        );        
    }
}