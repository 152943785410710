import React, { useEffect, useRef, useState } from "react";
import { OnboardingTutorials } from "./homeCards/OnboardingCards";
import { Button, Tooltip } from "../../common/HarmonyEnablers";
import { DiscoverData } from "./homeCards/DiscoverCards";
import {  useDispatch, useSelector } from "react-redux";
import {  AppDispatch, RootState } from "../../root-redux/RootState";
import "./Home.css"
import { v4 as uuidv4 } from 'uuid';
import { WorkflowPublished } from "./tenantStatistics/WorkflowsPublished";
import { WorkspaceOnboarded } from "./tenantStatistics/WorkspacesOnboarded";
import { NavMenuItemType } from "../../model/NavMenu.model";
import { useNavigate } from "react-router-dom";
import { RequestStatus } from "../../model/ServiceRequestStatus.model";
import { getDevReleases, getProdReleases, getUatReleases } from "../../services/GetReleases.api";
import { getWorkflowByIdandVersion_dev, getWorkflowByIdandVersion_prod, getWorkflowByIdandVersion_uat } from "../../services/GetWorkflows.api";
import { publishWorkflow } from "../../services/PublishWorkflow.api";
import { ConnectionsCollection } from "../../model/connections/ConnectionsCollection.model";
import { propagateConnectionReleaseManagement } from "../../services/PropagateConnections.api";
import { postCreateNewVersion } from "../../services/PostCreateNewVersion.api";
import { postInsightFamily } from "../../services/PostInsightFamily.api";
import { Environment, Release } from "../../model/releases/Release.model";
import { postRelease } from "../../services/PostRelease.api";
import { addNewWorkflow } from "../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { permissionsSelector } from "../../shared-components/role-based-access-control/RoleBasedAccessControl.selectors.redux";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";
import { resetModelMapping } from "../configs/ModelMappingConfig.actions.redux";
import { resetApiConfig } from "../api-configuration/api-configuration-redux/ApiConfig.actions.redux";
import { resetInsightsPreviewObject } from "../preview-api/preview-api-redux/PreviewInsights.api.redux";
import { resetRecommendationsPreviewObject } from "../preview-api/preview-api-redux/PreviewRecommendationsApi.actions.redux";
import { resetStatusForMergePreviewForRecommendationApi } from "../preview-mergerule/preview-mergerule-redux/PreviewMergeRule.actions.redux";
import { getTenantStats } from "../../services/GetTenantStats.api";

export const data =  {} as ConnectionsCollection

const HomeC: React.FC = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const stateProps = useSelector((state: RootState) => ({
    userDetails: state.userDetails.name,
    stats : state.tenantStats.list,
    tenantStatsStatus: state.tenantStats.status,
    appPersonas: state.appPersonas.list,
    selectedEnvironment: state.env.selectedEnvItem,
    UserGroups: state.userGroups.list
  }));
  const permissions = useSelector(permissionsSelector);

  var ins = 0;
  var recs = 0;
  var insWorkspaces = 0;
  var recsWorkspaces = 0;

  const quickActionsText = {
    createInsight:"Insights are impactful discoveries from ML/rule-based models or signal datasets, driving proactive actions. They're self-contained (no merging required), accessible via APIs for your workspaces or the Action Store to trigger notifications. Furthermore, they can merge to amplify existing recommendations.",
    createRecommendation:"Recommendations are informed suggestions derived from prediction based models and combined data points across diverse signals. They are often sequenced to optimize ROI. They incorporate insights and can merge across signals. These recommendations are accessible through APIs, facilitating their integration into workspaces for effective presentation and use.",
    createNotification:"Initiate notifications using insights that prompt proactive actions and can be displayed on various interfaces, including workspaces and email. You have the flexibility to create a new insight or modify an existing one before publishing it in the action store. After publication, these insights will empower you to configure and trigger notifications through the Action Center",
    merge:"This process involves transforming a single prediction model into recommendations using default merge settings. It also includes crafting custom merge rules for combining insights into recommendations, along with utilizing existing merge rules for the same purpose.",
    discover:"Discover insights and recommendations across all teams, even those provided by external models. Easily duplicate for reuse or craft new ones as needed.",
    campaign: "Create highly engaged 'New Business' Recommendations to drive pipeline for Enterprise accounts through a Solutions Play Accelerator (SPA) campaign"
 }
  
  const tenantData = stateProps.stats;

  //getting the number of insights from all teams the logged in user belongs to
  const insStat = () => {
    (tenantData || []).map((item) => {
      ins += item.insights;
      item.insights != 0 ? insWorkspaces += 1 : insWorkspaces += 0;
    });
    return ins;
  };

    //getting the number of recommendations workflows from all teams the logged in user belongs to
  const recStat = () => {
    (tenantData || []).map((item) => {
      recs += item.recommendations;
      item.recommendations != 0 ? recsWorkspaces += 1 : recsWorkspaces += 0;
    });
    return recs;
  };

  const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
    navigate("/"+ NavMenuItemType[navMenuItemType]);
  };

  const permissionsList = useSelector(permissionsSelector);
  const userTeams = permissionsList.userTeams;
  const primaryTeam = permissionsList.primaryTeam;
  const environment = useSelector((state: RootState) => state.env.selectedEnvItem);
  const userDetails = useSelector((state: RootState) => state.userDetails);

  const handleNewWorkflow = (props:any) => {

    const selectedWorkflowType = props.value;
    const newWorkflowId = uuidv4().toString();
    const environments: Environment[] = [
      { environment: "dev", isDeployed: true },  { environment: "uat", isDeployed: false },  { environment: "prod", isDeployed: false }];
  
    const releaseData : Release = {
      environments: environments,
      timestamp: "",
      releaseId: uuidv4().toString(),
      workflowId: newWorkflowId,
      version: "1",
      id: uuidv4().toString(),
      type: "New Version",
      status: "New",
      scheduleCreated: false,
    };
    dispatch(resetModelMapping());
    dispatch(resetApiConfig());
    dispatch(resetInsightsPreviewObject())
    dispatch(resetRecommendationsPreviewObject())
    dispatch(resetStatusForMergePreviewForRecommendationApi())
    dispatch(postRelease({payload : releaseData, env:"dev"}));
    dispatch(addNewWorkflow({workflowId: newWorkflowId, subscriptionKey: userTeams.key[userTeams.name.indexOf(primaryTeam)], environment, modifiedBy: userDetails?.localAccountId, workflowType: selectedWorkflowType}))
    navigate("/"+ NavMenuItemType[NavMenuItemType.WorkFlows]+"/edit", { state: { workflowId: newWorkflowId} });
}


useEffect(() => {

  if (permissions && permissions.userTeams.key.length > 0 && stateProps.tenantStatsStatus !== RequestStatus.loading) {
  dispatch(getTenantStats(permissions.userTeams.key));
  }  
  
}, [stateProps.UserGroups, stateProps.selectedEnvironment]);

  return (
    <div className="home-wrapper">
      <h3>Welcome {stateProps.userDetails}!</h3>
      <br />
      <div className="home-content-wrapper">
        <div >
          <span style={{ fontSize: "18px", fontWeight: "500" }}> My team's adoption</span>
          <hr />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "12px",
            }}
          >
            <WorkflowPublished
              insPublished={insStat()}
              recPublished={recStat()}
              status={stateProps.tenantStatsStatus}
            />
            <WorkspaceOnboarded
              insWorkspaces={insWorkspaces}
              recsWorkspaces={recsWorkspaces}
              status={stateProps.tenantStatsStatus}
            />
          </div>

          <div style={{ marginBottom: "36px" }}>
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              Quick Actions
            </span>
            <hr />
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="nebulaLink" tabIndex={0}>
                  <span
                    onClick={() => _onNavMenuItemClick(NavMenuItemType.WorkFlows)}
                  >
                    Discover Insights & Recommendations
                  </span>
                </div>
                <span id="discover" className="sentenceEllipsis">
                  {quickActionsText.discover}
                </span>
                <Tooltip
                  anchor="discover"
                  fixedPlacement={true}
                  placement="bottom"
                >
                  {quickActionsText.discover}
                </Tooltip>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "14px",
                }}
              >
                <div className="nebulaLink" tabIndex={0}>
                  <span onClick={() => handleNewWorkflow({value : "Insight"})}>
                    Create an Insight
                  </span>
                </div>
                <span id="createinsight" className="sentenceEllipsis">
                  {quickActionsText.createInsight}
                </span>
                <Tooltip
                  anchor="createinsight"
                  fixedPlacement={true}
                  placement="bottom"
                >
                  {quickActionsText.createInsight}
                </Tooltip>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "14px",
                }}
              >
                <div className="nebulaLink" tabIndex={0}>
                  <span onClick={() => handleNewWorkflow({value:"Recommendation"})}>
                    Create a Recommendation
                  </span>
                </div>
                <span id="createRecommendation" className="sentenceEllipsis">
                  {quickActionsText.createRecommendation}
                </span>
                <Tooltip
                  anchor="createRecommendation"
                  fixedPlacement={true}
                  placement="bottom"
                >
                  {quickActionsText.createRecommendation}
                </Tooltip>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "14px",
                }}
              >
                <div className="nebulaLink" tabIndex={0}>
                  <span
                    onClick={() =>
                      _onNavMenuItemClick(NavMenuItemType.MergeRules)
                    }
                  >
                    Merge Recommendations & Insights
                  </span>
                </div>
                <span id="merge" className="sentenceEllipsis">
                  {quickActionsText.merge}
                </span>
                <Tooltip anchor="merge" fixedPlacement={true} placement="bottom">
                  {quickActionsText.merge}
                </Tooltip>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "14px",
                }}
              >
                <div className="nebulaLink">
                  <span
                    onClick={() =>
                      _onNavMenuItemClick(NavMenuItemType.Configuration)
                    }
                  >
                    Create a recommendation with SPA campaign
                  </span>
                </div>
                <span id="campaign" className="sentenceEllipsis">
                  {quickActionsText.campaign}
                </span>
                <Tooltip anchor="campaign" fixedPlacement={true} placement="bottom">
                  {quickActionsText.campaign}
                </Tooltip>
              </div>
              
            </div>
          </div>

          <div >
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              What's happening in Nebula
            </span>
            <hr />
            <div className="nebulaLinks">
                <a href="https://microsoftapc.sharepoint.com/:v:/t/UnifiedRecommendationPlatformEngineeringTeam/Ecyr7P_PatdEmlmo0RXMMWoBfb9DzdReRP99yfvCAlPaEA?e=QC4XIV&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D" target="_blank" id = "sneakpeakvideo">
              <div>
                <img
                  src="../assets/icons/ssv2_sneakpeak_image.png"
                  alt="Nebula copilot"
                  style={{cursor: "pointer", width: "500px", maxWidth: "800px" , height: "auto"}}
                ></img>
              </div>
              </a>
            </div>
          </div>
        </div>

        <div >
          <div style={{ marginLeft: "20px" }}>
            <span style={{ fontSize: "18px", fontWeight: "500" }}>Tutorials</span>
            {/* <a style = {{textDecoration: "none", color: "#0078D4", fontSize:"14px", marginLeft: "20px"}} target="_blank" rel="noreferrer" href = "" >See all &gt;</a> */}
            <hr />
            <OnboardingTutorials />
          </div>
        </div>
      </div>
      
    </div>
  );
};
const Home = withErrorBoundary("Home", HomeC);
export default Home;