import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FlyInPanel,
  SearchBox,
} from "../../common/HarmonyEnablers";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { setOpenAttributesPanel } from "../workflows/workflow-by-id-redux/WorkflowById.actions.redux";
import { useEffect, useState } from "react";
import {
  AttributePanelDataGridRow,
  columns,
  getAttributePanelGridRows,
} from "./AttributesPanelColumn.selectors.redux";
import { DataGrid } from "../workflows/WorkflowsScope";
import { PublishMap } from "../../model/workflows/Publish.model";
import React from "react";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";

const AttributesPanelC: React.FC<{
  attributesList: [string, string][];
  onSelectCallback: (item: [string, string][]) => void;
  publishMap: PublishMap[];
}> = React.memo(({ attributesList, onSelectCallback, publishMap }) => {
  const dispatch = useDispatch<AppDispatch>();
  const openPanel = useSelector(
    (state: RootState) => state.workflowById.openAttributesPanel
  );
  const [attributeRows, setAttributeRows] = useState<
    AttributePanelDataGridRow[]
  >([]);
  const [selectedAttributes, setSelectedAttributes] = useState<
    [string, string][]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [updatedSelections, setUpdatedSelections] = useState<boolean>(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  useEffect(() => {
    if (openPanel) {
      const { rows, initialSelectedAttributes } = getAttributePanelGridRows(
        attributesList,
        publishMap,
        selectedAttributes,
        updatedSelections
      );
      const appliedSearchText = searchText.trim()?.toLowerCase()
      let selectedItemsShownCount = 0
      const filteredRows = rows.filter((x) => {
        if (!appliedSearchText && x.cells.field1?.toLowerCase().includes(appliedSearchText)) {
            selectedItemsShownCount += x.selected ? 1 : 0
          return true;
        }
        return false;
      });
      setSelectedAll(filteredRows.length !== 0 && selectedItemsShownCount === filteredRows.length)
      setAttributeRows(filteredRows);
      if (!updatedSelections) {
        setUpdatedSelections(true);
        setSelectedAttributes(initialSelectedAttributes);
      }
    }
  }, [
    openPanel,
    attributesList,
    publishMap,
    selectedAttributes,
    searchText,
    updatedSelections,
  ]);

  // do not close the panel when clicked outside the panel
  const handleRequestClose = (event: any) => {
    if (
      event.target !== event.currentTarget ||
      (event.target === event.currentTarget &&
        event.detail.source === "overlay")
    ) {
      event.preventDefault();
    } else {
      dispatch(setOpenAttributesPanel(false));
    }
  };

  const onAddClick = (e: any) => {
    onSelectCallback(selectedAttributes);
    dispatch(setOpenAttributesPanel(false));
  };

  const handleRowChange = () => {
    const selectedRows = attributeRows
      ?.filter((x) => x.selected)
      ?.map((x) => x.customData as [string, string]);

    setSelectedAttributes(selectedRows);
    if (!updatedSelections) {
      setUpdatedSelections(true);
    }
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  return (
    <FlyInPanel
      style={{ "--size": "var(--he-panel-size-medium)" }}
      open={openPanel}
      onHeRequestClose={handleRequestClose}
    >
      <div slot="heading">Add attributes from final dataset</div>      
      <SearchBox
        style={{ width: "300px", marginLeft: "0px", marginRight: "5px"}}
        onInput={handleSearch}
        onHeClear={() => setSearchText("")}
        placeholder="Search"
      />
      <DataGrid
        rows={attributeRows}
        columns={columns}
        select="multiple"
        style={{ height: "auto" }}
        onHeRowSelectChange={handleRowChange}
        onHeSelectAllChange={handleRowChange}
        selectAll={selectedAll}
        // selectAllIndeterminate={selectedAll}
      >
        {attributeRows.map((row) => {
          return (
            <>
              <span slot={`field1-${row.id}`} key={`field1-${row.id}`}>
                {row.cells.field1}
              </span>
            </>
          );
        })}
        <span slot="no-records">No results found.</span>
      </DataGrid>
      <div slot="footer">
        <Button onClick={onAddClick} appearance="primary">
          Add
        </Button>
      </div>
    </FlyInPanel>
  );
});

export const AttributesPanel = withErrorBoundary("Attributes Panel", AttributesPanelC);
