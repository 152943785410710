import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteRequest } from "../utils/DeleteRequest"
import { IMergeRuleResponse } from "../../model/merge-rules/MergeRule.model"
import { RootState } from "../../root-redux/RootState"

export const deleteMergeRule = createAsyncThunk<any,{ mergeRuleId: string}, {state:RootState}>('mergerules/deleteMergeRule', async ({mergeRuleId},{getState}) => {
    const selectedEnvItem = getState().env.selectedEnvItem;
    const payload = mergeRuleId
    const response = await deleteRequest<IMergeRuleResponse>(selectedEnvItem+'/mergerules/'+payload)
    return response.data
  })
