import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../root-redux/RootState";
import { FlyInPanel, Icon } from "../../common/HarmonyEnablers";
import { Button } from "../workflows/WorkflowsScope";
import { resetMergeRuleSet, resetOpenedMergeRuleSet } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { InformationTextMergeRule } from "../../shared-components/info-text/InformationTextMergeRule";
import informationConfig from "../../shared-content/inputDescription";
import { useEffect, useState } from "react";
import { HelpContent } from "../merge-rules/help-mergerules/HelpContent";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { MergeRuleSet } from "../../model/merge-rules/MergeRuleSet.model";
import { PreviewMergeRule } from "../preview-mergerule/PreviewMergeRule";
import { CancelConfirmationPopup } from "./popups/CancelConfirmationPopup";

export const EditMergeRuleSetHeader: React.FC<{ mergeRuleSetName: string, source: string }> = ({ mergeRuleSetName, source }) => {
    const dispatch = useDispatch();
    const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen)
    var title = mergeRuleSetName ? 'Edit Ruleset' : 'Create New Ruleset'
    var subtitle = mergeRuleSetName ? "| " + mergeRuleSetName : ""
    const openMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.openMergeRuleSet);
    const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
    const [openHelpPanel, setOpenHelpPanel] = useState(false)
    const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
    const [openPreview, setOpenPreview] = useState(false)
    const [modifiedMergeRule, setModifiedMergeRule] = useState(mergeRule)
    const [enablePreview, setEnablePreview] = useState(false)
    const unsavedChanges = useSelector((state: RootState) => state.mergeRuleById.unsavedChanges);
    const unsavedMergeRuleSetChanges = useSelector((state: RootState) => state.mergeRuleById.unsavedMergeRuleSetChanges);
    
    const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
    const [cancelConfirmed, setCancelConfirmed] = useState(false)

    useEffect(() => {
        if(cancelConfirmed) {
            handleClick();
            setCancelConfirmed(false);
        }
    },[cancelConfirmed])

    useEffect(() => {
        if (currentMergeRuleSet.name && currentMergeRuleSet.rank && currentMergeRuleSet.type &&
            currentMergeRuleSet.mergeModel &&
            (currentMergeRuleSet.mergeOn.fields.default.length > 0 ||
                currentMergeRuleSet.mergeOn.mergeConditions.length > 0)) {
            setEnablePreview(true)
        } else {
            setEnablePreview(false)
        }
        var local = modifiedMergeRule
        var localPrimarySet = [] as MergeRuleSet[]
        var localSecondarySet = [] as MergeRuleSet[]

        if (currentMergeRuleSet.type === "primary") {
            localPrimarySet.push(currentMergeRuleSet)
        } else {
            localPrimarySet = mergeRule.primaryMergeRuleSet
            localSecondarySet.push(currentMergeRuleSet)
        }
        local = { ...local, primaryMergeRuleSet: localPrimarySet, secondaryMergeRuleSet: localSecondarySet }
        setModifiedMergeRule(local)
    }, [currentMergeRuleSet])

    const handlePreview = () => {
        setOpenPreview(true)
    }

    const handlePreviewRequestClose = (event: any) => {
        if (event.target === event.currentTarget && event.detail.source === 'close-button') {
            setOpenPreview(false)
        }
    }

    const handleClick = () => {
        if (openMergeRuleSet) {
            dispatch(resetOpenedMergeRuleSet())
            dispatch(resetMergeRuleSet)
        }
    }
    return (
        <>
            <div className={`header-wrapper ${source === 'default' ? 'default' : ''}`}>
                <Button appearance="command" onClick={() => {unsavedMergeRuleSetChanges ? setOpenCancelConfirmation(true) : handleClick()
                }}>
                    <Icon label="back button"  slot="start" name="back" style={{ marginBottom: "5px", fontSize: "20px", alignItems: 'center', color: 'var(--he-color-primary-700)' }} />
                </Button>
                <span style={{ fontSize: '20px', fontWeight: '500' }}>{title}</span>
                <span style={{ fontSize: '20px', margin: '0 10px' }}>{subtitle}</span>
                <span>
                    <Button appearance="command" style={{ marginLeft: "20px", fontSize: "14px", marginBottom: "0.6em" }} onClick={handlePreview} disabled={!enablePreview}>
                        <Icon slot="start" name="redeye"></Icon>
                        Preview
                    </Button>
                </span>
                <span>
                    <Button id="editmergeHelp" appearance="command" style={{ marginLeft: "20px", fontSize: "14px", marginBottom: "0.6em" }} onClick={() => { setOpenHelpPanel(!openHelpPanel); }}>
                        <Icon slot="start" name="help"></Icon>
                        Help
                    </Button>
                </span>
                <div style={{ marginBottom: "24px" }}>
                    <InformationTextMergeRule text={informationConfig.editMergeRuleSetConfiguration} type="Tab" />
                </div>
            </div>
            {openHelpPanel &&
                <HelpContent openHelpPanel={openHelpPanel} setOpenHelpPanel={setOpenHelpPanel} />
            }
            {openPreview &&
                <FlyInPanel style={{ '--size': 'var(--he-panel-size-large)' }}
                    open={openPreview}
                    onHeRequestClose={handlePreviewRequestClose}
                    heading={`Preview MergeRuleSet - ${currentMergeRuleSet.name}`}>
                    <PreviewMergeRule source={"header"} mergeRuleName={mergeRule.mergeRuleId} mergeruleSetId={currentMergeRuleSet.id} localmergeRule={modifiedMergeRule} subKey={mergeRule.subscriptionKey} />
                </FlyInPanel>
            }
            {openCancelConfirmation && <CancelConfirmationPopup setCancelConfirmed={setCancelConfirmed} closePopup={setOpenCancelConfirmation} />}
        </>
    )
}