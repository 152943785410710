import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRequest,patchRequest } from "../utils/PostRequest"
import { IMergeRuleResponse, MergeRule } from "../../model/merge-rules/MergeRule.model"
import { RootState } from "../../root-redux/RootState"

export const patchMergeRule = createAsyncThunk<any,{reset: boolean, mergeRule: MergeRule},{state: RootState}>('mergerules/postMergeRule', async ({reset = false, mergeRule},{getState}) => {
    const selectedEnvItem = getState().env.selectedEnvItem;
    const payload = mergeRule
    const response = await patchRequest<IMergeRuleResponse,MergeRule>(selectedEnvItem+'/mergerules?reset='+reset, payload)
    return response.data
  })
