import { Schedule } from "../../../model/workflows/Schedule.model";
import { Select, Option } from "../../../common/HarmonyEnablers";
import { updateSchedule } from "../workflow-by-id-redux/WorkflowById.actions.redux";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../root-redux/RootState";
export const ScheduleWorkflowWeek: React.FC<{
  scheduleObj: Schedule;
}> = React.memo(({ scheduleObj }) => {
  const dispatch = useDispatch<AppDispatch>();
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return (
    <Select
      value={scheduleObj?.dayOfWeek}
      slot="action"
      fixed-placement
      placeholder={"Select"}
      name="subkey"
      className="weekday"
    >
      {weekDays.map((item) => (
        <Option
          key={item}
          role="menuitemcheckbox"
          onHeSelected={(e: any) =>
            dispatch(
              updateSchedule({
                ...scheduleObj,
                dayOfWeek: e.target.value,
              })
            )
          }
          value={item}
        >
          {item}
        </Option>
      ))}
    </Select>
  );
});
