import { PublishMap } from "../../model/workflows/Publish.model"
import { Attribute, Prediction } from "../../model/preview-mergerule/Prediction.model"
import { v4 as uuidv4 } from 'uuid';
import { Insight } from "../../model/preview-mergerule/Insight.model"
import { PreviewWorkflow } from "../../model/preview-workflow/PreviewWorkflow.model";
import { Workflow } from "../../model/workflows/Workflow.model";
import { ErrorResponse } from "../../model/preview-mergerule/ErrorResponse.model";
import { MergeRulePreviewUserInput } from "../../model/preview-mergerule/MergeRulePreviewUserInput.model";

export const getAttributes = (publishMap: PublishMap[], publishAttributes: [string, string][], eachMatch: string) => {
    var eachMatchSplit = eachMatch.split("|")
    var attributes: Attribute[] = []
    var i = 0;
    var arrayNotAllowed = ["Text", "Reason", "Status", "CreatedDate", "StartOn", "DueInDays", "ExpiryInDays", "SourceId", "PartitionKey", "Type", "Status"]
    publishAttributes.forEach(eachAttribute => {
        if (!arrayNotAllowed.includes(eachAttribute[0])) {
            var newAttr = new Attribute()
            newAttr.key = eachAttribute[0]
            var value = eachMatchSplit && eachMatchSplit.length > i ? eachMatchSplit[i].trim() : ""
            value = value && value !== "null" ? ((value.toLowerCase() === "false" || value.toLowerCase() === "true") ? value.toLowerCase() : value) : ""
            newAttr.value = value
            attributes.push(newAttr)
        }
        i++;
    });
    return attributes
}

export const getPrimaryKeyValues = (publishMap: PublishMap[], publishAttributes: [string, string][], eachMatch: string, attributeNames: string) => {
    var eachMatchSplit = eachMatch.split("|")
    var i = 0;
    var finalValue = ""
    var attributesArray = attributeNames && attributeNames.split(",") || [] as string[]
    attributesArray.forEach((eachAttr) => {
        var presentInPublishAttributes = publishAttributes.find(x => { return x[0] === eachAttr })
        if (presentInPublishAttributes) {
            var index = publishAttributes.indexOf(presentInPublishAttributes)
            var value = eachMatchSplit && eachMatchSplit.length > index ? eachMatchSplit[index].trim() : ""
            value = value && value !== "null" ? ((value.toLowerCase() === "false" || value.toLowerCase() === "true") ? value.toLowerCase() : value) : ""
            finalValue += value
        }
    })
    return finalValue
}

export const getSystemAttributes = (publishMap: PublishMap[], publishAttributes: [string, string][], eachMatch: string) => {
    var systemAttributes: Attribute[] = []
    var newAttr = new Attribute()
    newAttr.key = "Version"
    newAttr.value = "1"
    systemAttributes.push(newAttr)
    var newAttr1 = new Attribute()
    newAttr1.key = "IsFlight"
    newAttr1.value = "false"
    systemAttributes.push(newAttr1)
    return systemAttributes
}

export const getValue = (attributeName: string, publishMap: PublishMap[], publishAttributes: [string, string][], eachMatch: string) => {
    var finalVal = ""
    var eachMatchSplit = eachMatch.split("|")

    var sourceObj = publishMap.find((x: any) => { return x.destination === attributeName })
    if (sourceObj) {
        var sourceString = sourceObj.source || ""
        var isPresent = publishAttributes.find(x => { return x[0] === sourceString })
        if (isPresent) {
            var index = publishAttributes.indexOf(isPresent)
            finalVal = eachMatchSplit && eachMatchSplit.length > index ? eachMatchSplit[index] : ""
        } else {
            finalVal = sourceString
        }
    }
    return finalVal.trim();
}


export const getValueDate = (attributeName: string, publishMap: PublishMap[], publishAttributes: [string, string][], eachMatch: string) => {
    var finalVal = new Date().toISOString();
    var eachMatchSplit = eachMatch.split("|")

    var sourceObj = publishMap.find((x: any) => { return x.destination === attributeName })
    if (sourceObj) {
        var sourceString = sourceObj.source || ""
        var isPresent = publishAttributes.find(x => { return x[0] === sourceString })
        if (isPresent) {
            var index = publishAttributes.indexOf(isPresent)
            finalVal = eachMatchSplit && eachMatchSplit.length > index ? eachMatchSplit[index] : ""
        }
    }
    return finalVal;
}

export const getValueNumber = (attributeName: string, publishMap: PublishMap[], publishAttributes: [string, string][], eachMatch: string) => {
    var finalVal = 0
    var eachMatchSplit = eachMatch.split("|")
    var sourceObj = publishMap.find((x: any) => { return x.destination === attributeName })
    if (sourceObj) {
        var sourceString = sourceObj.source || ""
        var isPresent = publishAttributes.find(x => { return x[0] === sourceString })
        if (isPresent) {
            var index = publishAttributes.indexOf(isPresent)
            finalVal = eachMatchSplit && eachMatchSplit.length > index ? Number(eachMatchSplit[index]?.trim()) : 0
        } else {
            finalVal = Number(sourceString)
        }
    }
    return finalVal;
}

export const formDataPredictions = (workflow: Workflow, previewData: PreviewWorkflow, tpids: string[] = [], tpidLimit: number = 1, tpidSource: string = "workflow") => {
    var predictions: Prediction[] = []
    var output = 0
    var publishName = workflow.publish.filter(x => { return x.type === "COSMOS" && x.publishTo.toLowerCase() === "nebula" })
    var actualPublishName = ""
    var localTpidList = [] as string[]
    var localPrimaryKeyValueList = [] as string[]
    var publishMap = [] as PublishMap[]
    if (publishName && publishName.length > 0) {
        actualPublishName = publishName[0].inputViewName
        publishMap = publishName[0].publishMap
    }
    if (actualPublishName && previewData && publishMap && (previewData.postProcessing || previewData.preProcessing || previewData.dataIngestion)) {
        var previewforPublish = previewData.postProcessing && previewData.postProcessing[actualPublishName] || undefined
        if (!previewforPublish) {
            previewforPublish = previewData.preProcessing && previewData.preProcessing[actualPublishName] || undefined
        }
        if (!previewforPublish) {
            previewforPublish = previewData.dataIngestion && previewData.dataIngestion[actualPublishName] || undefined
        }
        
        if (previewforPublish) {
            var previewOutput = previewforPublish.adbOutput.split("+")
            previewOutput = previewOutput.filter(x => { return x.includes("|") })

            if (previewOutput && previewOutput.length > 0) {
                var publishAttributes = previewforPublish.adbOutputAttributes;
                var matches = previewOutput && previewOutput.length >= 2 ? previewOutput[1].split("\n|") : []
                matches = matches.filter(x => { return x })
                if (matches && matches.length > 0) {
                    matches.forEach(eachMatch => {
                        if (eachMatch) {
                            var primaryKeysValue = ""
                            var partitionKeyValue = getValue("PartitionKey", publishMap, publishAttributes, eachMatch)
                            var shouldBeAdded = false
                            shouldBeAdded = tpidSource === "workflow" && (localTpidList.length < tpidLimit || localTpidList.includes(partitionKeyValue))
                            shouldBeAdded = shouldBeAdded || (tpidSource === "user" && tpids.includes(partitionKeyValue))
                            if (shouldBeAdded) {
                                primaryKeysValue = getPrimaryKeyValues(publishMap, publishAttributes, eachMatch, publishName[0].primaryKeys)
                                shouldBeAdded = shouldBeAdded && (localPrimaryKeyValueList.length === 0 || (localPrimaryKeyValueList.length > 0 && !localPrimaryKeyValueList.includes(primaryKeysValue)))
                            }
                            if (shouldBeAdded) {
                                var newObj = new Prediction()
                                newObj.partitionKey = partitionKeyValue
                                newObj.id = uuidv4().toString()
                                newObj.type = "Prediction"
                                newObj.createdDate = getValueDate("CreatedDate", publishMap, publishAttributes, eachMatch)
                                newObj.source = {
                                    sourceId: publishName[0].modelId || ""
                                }
                                newObj.primary = {
                                    text: getValue("Primary.Text", publishMap, publishAttributes, eachMatch),
                                    reason: getValue("Primary.Reason", publishMap, publishAttributes, eachMatch),
                                    startOn: getValueDate("Primary.StartOn", publishMap, publishAttributes, eachMatch),
                                    family: workflow.family || "",
                                    dueInDays: getValueNumber("Primary.DueInDays", publishMap, publishAttributes, eachMatch),
                                    expiryInDays: getValueNumber("Primary.ExpiryInDays", publishMap, publishAttributes, eachMatch),
                                    status: "New",
                                }
                                newObj.attributes = getAttributes(publishMap, publishAttributes, eachMatch)
                                newObj.systemAttributes = getSystemAttributes(publishMap, publishAttributes, eachMatch)
                                predictions.push(newObj)
                                if (tpidSource === "workflow" && localTpidList.length < tpidLimit && !localTpidList.includes(partitionKeyValue)) {
                                    localTpidList.push(partitionKeyValue)
                                }
                                if (!localPrimaryKeyValueList.includes(primaryKeysValue)) {
                                    localPrimaryKeyValueList.push(primaryKeysValue)
                                }
                            }
                        }
                    });
                }
            }
        }
    }
    return predictions
}


export const formDataInsights = (workflow: Workflow, previewData: PreviewWorkflow, tpids: string[] = [], tpidLimit: number = 1, tpidSource: string = "workflow", isApiPreview : boolean =  false) => {
    //MRTODO remove Topic
    var insights: Insight[] = []
    var output = 0
    var publishName = !isApiPreview ? workflow.publish.filter(x => { return x.type === "COSMOS" && x.publishTo.toLowerCase() === "nebula" }) : workflow.publish.filter(x => { return x.type === "COSMOS" })
    var actualPublishName = ""
    var localTpidList = [] as string[]
    var localPrimaryKeyValueList = [] as string[]
    var publishMap = [] as PublishMap[]
    if (publishName && publishName.length > 0) {
        actualPublishName = publishName[0].inputViewName
        publishMap = publishName[0].publishMap
    }
    if (actualPublishName && previewData && publishMap && (previewData.postProcessing || previewData.preProcessing || previewData.dataIngestion)) {
        var previewforPublish = previewData.postProcessing && previewData.postProcessing[actualPublishName] || undefined
        if (!previewforPublish) {
            previewforPublish = previewData.preProcessing && previewData.preProcessing[actualPublishName] || undefined
        }
        if (!previewforPublish) {
            previewforPublish = previewData.dataIngestion && previewData.dataIngestion[actualPublishName] || undefined
        }
        if (previewforPublish) {
            var previewOutput = previewforPublish.adbOutput.split("+")
            previewOutput = previewOutput.filter(x => { return x.includes("|") })

            if (previewOutput && previewOutput.length > 0) {
                var publishAttributes = previewforPublish.adbOutputAttributes;
                var matches = previewOutput && previewOutput.length >= 2 ? previewOutput[1].split("\n|") : []
                matches = matches.filter(x => { return x })
                if (matches && matches.length > 0) {
                    matches.forEach(eachMatch => {
                        if (eachMatch) {
                            var primaryKeysValue = ""
                            var partitionKeyValue = getValue("PartitionKey", publishMap, publishAttributes, eachMatch)
                            var shouldBeAdded = false
                            shouldBeAdded = tpidSource === "workflow" && (localTpidList.length < tpidLimit || localTpidList.includes(partitionKeyValue))
                            shouldBeAdded = shouldBeAdded || (tpidSource === "user" && tpids.includes(partitionKeyValue))
                            if (shouldBeAdded) {
                                primaryKeysValue = getPrimaryKeyValues(publishMap, publishAttributes, eachMatch, publishName[0].primaryKeys)
                                shouldBeAdded = shouldBeAdded && (localPrimaryKeyValueList.length === 0 || (localPrimaryKeyValueList.length > 0 && !localPrimaryKeyValueList.includes(primaryKeysValue)))
                            }
                            if (shouldBeAdded) {
                                var newObj = new Insight()
                                newObj.id = uuidv4().toString()
                                newObj.type = "Insight"
                                newObj.partitionKey = getValue("PartitionKey", publishMap, publishAttributes, eachMatch)
                                newObj.createdDate = getValueDate("CreatedDate", publishMap, publishAttributes, eachMatch)
                                newObj.source = {
                                    sourceId: publishName[0].modelId || ""
                                }
                                newObj.primary = {
                                    text: [""],
                                    reason: [""],
                                    startOn: getValueDate("Primary.StartOn", publishMap, publishAttributes, eachMatch),
                                    family: workflow.family || "",
                                    dueInDays: getValueNumber("Primary.DueInDays", publishMap, publishAttributes, eachMatch),
                                    expiryInDays: getValueNumber("Primary.ExpiryInDays", publishMap, publishAttributes, eachMatch),
                                    status: getValue("Primary.Status", publishMap, publishAttributes, eachMatch) || "New",
                                }
                                newObj.attributes = getAttributes(publishMap, publishAttributes, eachMatch)
                                newObj.systemAttributes = getSystemAttributes(publishMap, publishAttributes, eachMatch)
                                insights.push(newObj)
                                if (tpidSource === "workflow" && localTpidList.length < tpidLimit && !localTpidList.includes(partitionKeyValue)) {
                                    localTpidList.push(partitionKeyValue)
                                }
                                if (!localPrimaryKeyValueList.includes(primaryKeysValue)) {
                                    localPrimaryKeyValueList.push(primaryKeysValue)
                                }
                            }
                        }
                    });
                }
            }
        }
    }
    return insights
}

export const getErrorResponse = (code: any, customMsg: string, typeOfRawData: string = "", workflowId: string = "", isRawDataFromSamplePresent: boolean = false, workflowType: string, previewMergeRuleUserInput: MergeRulePreviewUserInput) => {
    switch (code) {
        case "ERR032": {
            return {
                code: "ERR032",
                error: "Unknown Exception Occured.",
                reasons: [],
                possibleSolutions: ["Please contact Nebula team to resolve the issue."]
            }
        }
        case "ERR085": {
            return {
                code: "ERR085",
                error: "Merge Rule is not applied in the configurations.",
                reasons: [],
                possibleSolutions: ["Please contact Nebula team to resolve the issue."]
            }
        }
        case "ERR091": {
            return {
                code: "ERR091",
                error: "Insights/Recommendations are not added in the configurations.",
                reasons: [],
                possibleSolutions: ["Please contact Nebula team to resolve the issue."]
            }
        }
        case "ERR094": {
            return {
                code: "ERR094",
                error: "Model Mappings not found for the merge rule.",
                reasons: [],
                possibleSolutions: ["Please contact Nebula team to resolve the issue."]
            }
        }
        case "ERR092": {
            var values = customMsg.split("|")
            if (customMsg.includes("Distinct Field Absent")) {
                return {
                    code: "ERR092",
                    error: "Merge Rule is missing Distinct Field configuration.",
                    reasons: [],
                    possibleSolutions: ["Please contact Nebula team to resolve the issue."]
                }
            }
            if (customMsg.includes("Mapping Not Found")) {
                return {
                    code: "ERR092",
                    error: `Configurations required for Family '${values[1]}' not Present.`,
                    reasons: [],
                    possibleSolutions: ["Please contact Nebula team to resolve the issue."]
                }
            } else if (customMsg.includes("Incorrect Mapping Found")) {
                return {
                    code: "ERR092",
                    error: `Mismatched configurations present for family '${values[1]}'.`,
                    reasons: [`Chosen Family '${values[1]}' requires the field to be chosen as PartitionKey to be '${values[2]}'. Instead '${values[3]}' is chosen as PartitionKey in your Insight(s)/Recommendation(s).`],
                    possibleSolutions: [
                        `Rename the field assigned as PartitionKey to '${values[2]}' in your respective Insight(s)/Recommendation(s) OR`,
                        `Use Family name other than '${values[1]}' in your Insight(s)/Recommendation(s) and Merge Rule.`
                    ]
                }
            } else if (customMsg.includes("Field Not Found")) {
                return {
                    code: "ERR092",
                    error: `Missing field '${values[2]}' in insights/input recommendations on which preview is run.`,
                    reasons: [`Chosen Family '${values[1]}' requires the insights/input recommendations to be published to COSMOS have field namely '${values[2]}'.`],
                    possibleSolutions: [
                        `Rename the field assigned as PartitionKey to ${values[2]} in your respective Insight(s)/Recommendation(s) OR`,
                        `Use Family name other than '${values[1]}' in your Insight(s)/Recommendation(s) and Merge Rule.`
                    ]
                }
            }
            break;
        }
        case "ERR093":
            var values = customMsg.split("|")
            var error = ""
            var reasons = []
            var suggestions = []
            if (customMsg.includes("No Data In Cosmos")) {
                error = `No data found in Nebula Cosmos Store for Insight(s)/Recommendation(s) within this Merge Rule.`
                if (typeOfRawData === 'existing' && workflowId) {
                    reasons.push("Insight(s)/Recommendation(s) in this Merge Rule are either not released or not run.")
                    suggestions.push("Please release or run the Insight(s)/Recommendation(s) used in the Merge Rule.")
                    suggestions.push("OR Choose 'Previous Preview Steps'/'Both Previous Preview Steps and Cosmos' as 'Source of Data' and run the Merge Rule preview by loading preview data from previous stages.")
                }
                if (!workflowId) {
                    reasons.push("Insight(s)/Recommendation(s) in this Merge Rule are either not released or not run.")
                    suggestions.push("Please release or run the Insight(s)/Recommendation(s) used in the Merge Rule.")
                    suggestions.push("OR Navigate to Insight(s)/Recommendation(s) page and run the Merge Rule preview by loading preview from all the stages.")
                }
            } else if (customMsg.includes("No Predictions Or Recommendations")) {
                error = `No valid data for Insight(s)/Recommendation(s) within this Merge Rule.`
                if (typeOfRawData === 'both' || typeOfRawData === 'sample') {
                    if (!isRawDataFromSamplePresent) {
                        reasons.push("Previous preview(s) are not loaded.")
                        suggestions.push("Please rerun the preview in previous stages.")
                    } else {
                        if (workflowType && workflowType.includes("Insight")) {
                            reasons.push(`Either there are no recommendations in Cosmos or there are no recommendations for the chosen ${previewMergeRuleUserInput.distinctField || "TPID"}.`)
                            reasons.push(`Atleast 1 input recommendation is needed as Insight cannot generate recommendations on its own.`)
                            suggestions.push(`Please ensure atleast 1 recommendation(s) part of this Merge Rule is deployed/run and data is available in Cosmos with selected ${previewMergeRuleUserInput.distinctField || "TPID"}. You may choose to enter your own ${previewMergeRuleUserInput.distinctField || "TPID."}`)
                        } else {
                            reasons.push("Either there is no proper data in the Data sources/refinement steps Or Publish mapping isnt done properly.")
                            suggestions.push("Please recheck configurations/data in previous stages or Merge Rule configs.")
                        }
                    }
                } else if (typeOfRawData === 'existing' && workflowId) {
                    reasons.push("Data isnt valid/applicable for this Merge Rule.")
                    suggestions.push("Please recheck configurations/data in previous stages and re-release the insight/recommendation.")
                    suggestions.push("OR Choose 'Previous Preview Steps'/'Both Previous Preview Steps and Cosmos' as 'Source of Data' and run the Merge Rule preview by loading preview data from previous stages.")
                }
                if (!workflowId) {
                    reasons.push("Data isnt valid/applicable for this Merge Rule.")
                    suggestions.push("Please recheck configurations/data in previous stages or Merge Rule configs and re-release the insight/recommendation.")
                }
            }
            return {
                code: "ERR093",
                error: error,
                reasons: reasons,
                possibleSolutions: suggestions
            }
        case "ERR089": {
            var reasons = []
            var suggestions = []
            if (typeOfRawData === 'both' || typeOfRawData === 'sample') {
                if (!isRawDataFromSamplePresent) {
                    reasons.push("Previous preview(s) are not loaded.")
                    suggestions.push("Please rerun the preview in previous stages.")
                }
            } else if (typeOfRawData === 'existing' && workflowId) {
                reasons.push("Insight(s)/Recommendation(s) in this Merge Rule are either not released or not run")
                suggestions.push("Please release or run the Insight(s)/Recommendation(s) used in the Merge Rule")
                suggestions.push("OR Choose 'Previous Preview Steps'/'Both Previous Preview Steps and Cosmos' as 'Source of Data' and run the Merge Rule preview by loading preview data from previous stages.")
            }
            if (!workflowId) {
                reasons.push("Insight(s)/Recommendation(s) in this Merge Rule are either not released or not run")
                suggestions.push("Please release or run the Insight(s)/Recommendation(s) used in the Merge Rule")
                suggestions.push("OR Navigate to Insight(s)/Recommendation(s) page and run the Merge Rule preview by loading preview from all the stages")
            }

            return {
                code: "ERR089",
                error: `No data found for Insight(s)/Recommendation(s) within this Merge Rule.`,
                reasons: reasons,
                possibleSolutions: suggestions
            }
        }
    }
}