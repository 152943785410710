import { createAsyncThunk } from "@reduxjs/toolkit"
import { getRequest } from "./utils/GetRequest"
import { userObject } from "../model/user-object/UserObject.model";
import { RootState } from "../root-redux/RootState";
import { MergeRule } from "../model/merge-rules/MergeRule.model";

export const getAllUserObjectsOfMergerules = createAsyncThunk<userObject[], MergeRule[], { state: RootState }>('mergerules/getuserobjects', async (mergerules: MergeRule[], { getState }) => {
    const env = getState().env.selectedEnvItem;

    // Getting all the object ids from the modified by column from workflow summaries
    const objectIds: string[] = [];
    const requests = []
    mergerules.map((item) => {
      if (item.modifiedBy !== null && objectIds.includes(item.modifiedBy) === false)
         {
             objectIds.push(item.modifiedBy)
         }
    });
    const x = Math.floor(objectIds.length / 15) + 1;
    var objectIdsString = "";
    for (var i = 0; i < x; i++) {
        if((i + 1) * 15 < objectIds.length)
        {
          objectIdsString = objectIds.slice(i * 15, (i + 1) * 15).join(",");
        }
        else
        {
          objectIdsString = objectIds.slice(i * 15, objectIds.length).join(",");
        }
        requests.push(getRequest<userObject[]>(`${env}/workflows/userobjects?objectIds=${objectIdsString}`));
    }

    const responses = await Promise.all(requests);

    let result: userObject[] = [];
    responses?.forEach((res: any) => {
      result.push(...res.data)
    })

    return result
})

