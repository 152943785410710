
import { useEffect, useRef, useState } from "react";
import { FlyInPanel } from "../../../../common/HarmonyEnablers";
import { Button, Icon, SearchBox } from "../../WorkflowsScope";
import { allInsightsAndRecommendations, onboarding } from "../../../../shared-content/help-content/AllInsightAndRecommendations";
import "../HelpContent.css";
import { HelpNote } from "../Help-notes";
import { NebulaInformation } from "../../../../core-components/nebula-info/NebulaInformationCard";
import { withErrorBoundary } from "../../../../shared-components/ErrorBoundary";

const DataRefineC: React.FC<any> = (props: any) => {

  return (
    <>
    
    <div className="help-mainHeading">
          <span id="refineTag" className="help-subheading">Refine imported data</span>
          <div>
          <span className="help-heading">Summary:</span>
          <span className="help-content"> {onboarding.refine.summary} </span>
          </div>
          <div id = "onboarding_publish" style = {{marginTop:"14px"}}>
          <span className="help-content">You have two options to perform this task: </span>
          <ol>
            {onboarding.refine.refineoptions.map(info =>
              <li key={info}><span className="help-content">{info}</span></li>
            )}
          </ol>
          <div style = {{marginTop:"8px"}}>
          <HelpNote text = {onboarding.refine.extrainfo}/>
          </div>
          </div>
          </div>
          <hr/>

    </>
  );
};
export const DataRefine = withErrorBoundary("DataRefine content", DataRefineC);