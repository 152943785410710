import { useRef, useState, useEffect } from "react";
import {
  Button,
  Icon,
  ProgressRing,
  TextArea,
  TextField,
  Tooltip,
  Select
} from "../../../common/HarmonyEnablers";
import "../../onboarding-styles.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../root-redux/RootState";
import { withErrorBoundary } from "../../../shared-components/ErrorBoundary";
import { automateNextSteps, getDatasetUploadTemplate, getDatasetValidatedFile } from "../../../services/GetDatasetUploadTemplate.api";
import { ValidateFile, ValidateFileData } from "../../../services/utils/ExcellValidation";
import * as Constants from "../../../services/utils/Constants";
import { uploadDatasetFile } from "../../../services/UploadDatasetFile.api";
import { textField } from "@harmony/enablers/react";
import { RequestStatus } from "../../../model/ServiceRequestStatus.model";
import { setCurrentDatasetFileUploadState } from "../../workflows/workflows-redux/DatasetFileUpload.redux";
import { DatasetFileUploadModel, DatasetResponseType, FileValidationState } from "../../../model/workflows/DatasetFileUpload.model";
import { CampaignHeader } from "../edit-campaign/EditCamapaignHeader";
import { NavMenuItemType } from "../../../model/NavMenu.model";
import { useNavigate } from "react-router-dom";
import { InformationText } from "../../../shared-components/info-text/InformationText";
import informationConfig from "../../../shared-content/inputDescription";
import { HelpTexts, SPA_CAMPAIGN } from "../SPAConstants";
import { saveWorkflow, setWorkflowDescription, setWorkflowName } from "../../workflows/workflow-by-id-redux/WorkflowById.redux";
import { validateField } from "../edit-campaign/Components/ConfigRenderer";
import { LayoutFields } from "../../../model/SPAModels/campaigns/campaignConfig.Model";
import { getConnections } from "../../../services/GetConnections.api";
import { connectionsSelector } from "../../edit-workflow/EditWorkflow.selectors.redux";
import { getAllUserObjects } from "../../../services/GetUserObjects.api";
import { setTabCache } from "../redux/CacheHandler.redux";

const FileUploadC = () => {
  // redux status
    const isNavPanelOpen = useSelector((state: RootState) => state.nav.isNavPanelOpen);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const fileUploadRef = useRef<textField>(null);
    const datasetNameRef = useRef<textField>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const workflowList = useSelector((state: RootState) => state.workflows.list);
    const workflow = useSelector((state: RootState) => state.workflowById.workflow);
    const fileuploadState = useSelector((state: RootState) => state.fileuploadState);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [clickedButtonId, setClickedButtonId] = useState("");
    const { connections } = useSelector(connectionsSelector);
    const datasetAutomatePayload = { workflow, connections };
    const datasetConfig = useSelector((state: RootState) => state.datasetConfig.datasetLayout);
    const cacheHandler = useSelector((state: RootState) => state.cacheHandler);
    const [selectedTab, setSelectedTab] = useState<string>(cacheHandler.tabCache);

    useEffect(() => {
    // fetch connections on component load
      dispatch(getConnections({ subscriptionKeys: [workflow?.subscriptionKey] })); //TO-DO connections based on app personas etc 
    }, [dispatch]);

    useEffect(() => {
      if(workflow?.datasetFileUpload?.inputFileName) {
        dispatch(setCurrentDatasetFileUploadState({fileUploadObj: workflow?.datasetFileUpload, responseType: workflow?.datasetFileUpload?.fileValidationState == FileValidationState.Errored ? DatasetResponseType.Failed : DatasetResponseType.Complete}))
      };
      if (workflow?.subscriptionKey!=undefined && workflow?.subscriptionKey.length > 0 && connections == undefined) {
        const subscriptionKeys = [workflow.subscriptionKey];
        dispatch(getConnections({subscriptionKeys}))
      };
      dispatch(getAllUserObjects(workflowList));
    },[workflow])

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setCurrentDatasetFileUploadState({fileUploadObj :{} as DatasetFileUploadModel, responseType: DatasetResponseType.Idle}));
      const fileInput = event.target;
      const file = event.target.files![0];
      setSelectedFile(file);
      const fileValidationStatus = ValidateFile(file);
      if (fileValidationStatus !== Constants.INPUT_VALID) {
        fileUploadRef.current?.setCustomValidity(fileValidationStatus);
        setSelectedFile(null);
        event.target.value = "";
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target?.result?.toString();
        if (!csvData) {
          return;
        }
        const fileDataValidationStatus = ValidateFileData(csvData);
        if (fileDataValidationStatus !== Constants.INPUT_VALID) {
          fileUploadRef.current?.setCustomValidity(fileDataValidationStatus);
          setSelectedFile(null);
          fileInput.value = "";
          return;
        }

        fileUploadRef.current?.setCustomValidity('');
        setSelectedFile(file);
      }
      reader.readAsBinaryString(file);
    };

    const downloadTemplate = async () => {
      setClickedButtonId("downloadTemplate");
      await dispatch(getDatasetUploadTemplate(null));
    };

    const handleClear = (event: any): void => {
      event.preventDefault();
      dispatch(setCurrentDatasetFileUploadState({fileUploadObj :{} as DatasetFileUploadModel, responseType: DatasetResponseType.Idle}));
      setSubmitClicked(false);
      setClickedButtonId("");
      setSelectedFile(null);
    };

    const handleSubmit = async(event: any) => {
      if (selectedFile) {
        setSubmitClicked(true);
        setClickedButtonId("upload-file");
        const formData = new FormData();
        formData.append('file', selectedFile);
        dispatch(uploadDatasetFile({payload: formData}));
        event.preventDefault();
      }
    };
  
    const handleDownload = async(event: any) => {
      try {
        setClickedButtonId("download-file");
        await dispatch(getDatasetValidatedFile());
      }
      catch (err) {
        return err;
      }
    };

    const getSelectedFileName = () => {
       if (selectedFile) {
         return selectedFile.name;
      }
      else if(fileuploadState?.fileUploadResponse?.fileUploadObj == null || fileuploadState?.fileUploadResponse?.fileUploadObj.inputFileName == null) {
         return "";
      }
      else if (workflow?.datasetFileUpload?.inputFileName) {
        var inputFileName = workflow?.datasetFileUpload?.inputFileName;
        return inputFileName.split("__")[0] + ".csv";
      }
      return "";
    };

    const handleFieldChange = (e: any) => {
      const keyName = e.target.name;
      let value = (e.target.value || "").trim();
      if(keyName === "DatasetName")
      {
        dispatch(setWorkflowName(value));
      }
      else if(keyName === "Description")
      {
        dispatch(setWorkflowDescription(value));
      }
      workflow.datasetModelType = "File Upload";
      return true;
    };

    const validateAndApply = (selectionChange: (event: any) => void, layoutConfigs: LayoutFields[] | undefined, event: any, errorMessage: string | undefined) => {
      const target = event.target;
      const pattern = (layoutConfigs || []).find(config => config.name.toLowerCase === target.name.toLowerCase)?.regex;
      if (validateField(pattern, (target.value || "").trim())) {
          target.setCustomValidity('');
      } else {
          target.setCustomValidity(errorMessage);
      }
      if(workflow?.config?.workflowId != undefined) {
        selectionChange(event);
      }
    };

    const onClickNext = () => {
      setClickedButtonId("next"); 
      const updatedWorkflow = { ...workflow };
      let isValid = true;
      if(updatedWorkflow.config.workflowName == undefined || updatedWorkflow.config.workflowName.length < 5) {
        datasetNameRef.current?.setCustomValidity("Required Dataset Name is empty");
        isValid = false;
      }
      if(updatedWorkflow.datasetFileUpload == undefined) {
        fileUploadRef.current?.setCustomValidity("Please upload the target account list");
        isValid = false;
      }
      if(!isValid) {
        return;
      }
      updatedWorkflow.family = "";
      dispatch(saveWorkflow(updatedWorkflow));
      dispatch(automateNextSteps(datasetAutomatePayload)).unwrap().then(() => {
        setSelectedTab("datasetTab");
        dispatch(setTabCache("datasetTab"));
        _onNavMenuItemClick(NavMenuItemType.Configuration);
      });
    };
    
    const _onNavMenuItemClick = (navMenuItemType: NavMenuItemType) => {
      navigate("/" + NavMenuItemType[navMenuItemType]);
    };

    return (
      <>
      <div className={`spa-sticky-header ${isNavPanelOpen ? '': 'collapsed'}`}>
        <CampaignHeader title={"Dataset"} workflowName={workflow?.config?.workflowName} currentPage={1}/>

        <div className="spa-informationtext">
          <InformationText text={informationConfig.datasetSummary} type="Heading"/>
        </div>
      </div>
      
      <div className='workflows-wrapper' style={{marginTop: "220px"}}>
        <table className='dataset-fileupload-table workflow-form-table  workflow-basics-table' role='presentation' style={{marginBottom: '80px'}}>
        <tr>
            <td>
              Dataset Name 
              <Icon className='required-icon' name='actioncenterasterisk'></Icon>
                    <Icon name='info' id={'DatasetName'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                    <Tooltip anchor={'DatasetName'}>
                        {HelpTexts.DatasetName}
                    </Tooltip>
                </td>
                <td>
                    <TextField name={'DatasetName'} label={'DatasetName'} className={'Text-fields'}  ref={datasetNameRef} placeholder={`Enter Dataset Name here`} value={workflow?.config?.workflowName || ""} onHeChange={(event) => validateAndApply(handleFieldChange, datasetConfig?.datasetLayoutFields, event, "Dataset Name is less than (5) character or exceeds (200) in length or has invalid character's \ or /")}/>
                </td>
        </tr>
        <tr>
            <td>
                Description
                <Icon name='info' id={'Description'} style={{ marginLeft: "7px", cursor: "pointer", color: "#0078D4" }}></Icon>
                <Tooltip anchor={'Description'}>
                    {HelpTexts.datasetDescription}
                </Tooltip>
            </td>
            <td>
                <TextArea name={'Description'} label={'Description'} className={'Text-fields'} placeholder={`Enter Description here`} value={workflow?.description || ""} onHeChange={handleFieldChange} />
            </td>
        </tr>
        <tr>
          <td>
          <div className="dataset-template">
            Please select a file to upload
            <Icon className='required-icon' name='actioncenterasterisk'></Icon>
          </div>
          </td>
          <td className="fileupload-inputfield">
            <div className="fileupload-inputtext">
              <TextField style={{width: '350px', cursor: 'pointer'}} clearable value={getSelectedFileName()} ref={fileUploadRef} placeholder="Select a file to upload"
                onClick={() => { document.getElementById("file-input")!.click() }} onHeClear={handleClear}/>
            </div>
            <div className="fileupload-input-file" id={'custom-upload-btn'}>
              <input type="file" id="file-input" onChange={handleFileChange} 
                hidden />
              <label htmlFor="file-input" style={{ marginLeft: "10px", color: 'var(--he-color-primary-700)' }}>
              <Icon className="custom-button" name="upload"/>
              </label>
            </div>
            <Tooltip anchor="custom-upload-btn">Select a file to upload</Tooltip>
            <div style={{marginLeft: '7px'}}>
              <Button
                id='upload-file'
                appearance="stealth"
                className="fileupload-input-file"
                onClick = {handleSubmit}
                disabled={selectedFile == null || submitClicked}
                style={{ color: 'var(--he-color-primary-700)' }}>
                <Icon className="custom-button" label = "Upload a file" name="accept"/>
              </Button>
              <Tooltip anchor="upload-file">Submit</Tooltip>
            </div>
              {clickedButtonId === "upload-file" && fileuploadState?.status === RequestStatus.loading && 
                <div style={{marginLeft: '10px', marginTop: '12px'}}>
                <ProgressRing labelPlacement="end" label="Validation is in progress..." indeterminate></ProgressRing>;
                </div>
              }
          </td>
          <div>
          {fileuploadState?.fileUploadResponse?.fileUploadObj != undefined && fileuploadState?.fileUploadResponse?.fileUploadObj?.erroredFileName != null ? 
              (<tr>
                <div style={{ color: "red", fontSize: "15px" }}>
                  File Validation Failed. Please check the errored file here: &nbsp;
                  <Button appearance="link" onClick={handleDownload} style={{ color: "#0b62e4" }}>Download File</Button>
                </div>
              </tr>) : <></>}

              {fileuploadState?.fileUploadResponse?.responseType == DatasetResponseType.Complete && fileuploadState?.fileUploadResponse?.fileUploadObj != undefined && fileuploadState?.fileUploadResponse?.fileUploadObj?.erroredFileName == null  ? 
              (<tr>
                <div style={{ color: "green", fontSize: "15px" }}>
                  File Validation Successful. Please click next and publish the dataset. &nbsp;
                </div>
              </tr>) : <></>}

              {fileuploadState?.fileUploadResponse.responseType == DatasetResponseType.Failed ? 
              (<tr>
                <div style={{ color: "red", fontSize: "15px" }}>
                  System error occured. Please try again or raise a ticket &nbsp;
                </div>
              </tr>) : <></>}
          </div>
          <div style={{ marginTop: "10px", marginRight: "7px"}}>
            <Icon name='info' id={'downloadTemplate'} style={{ cursor: "pointer", color: "#0078D4" }}></Icon>
            <Tooltip anchor="downloadTemplate">The standard template can be downloaded here</Tooltip>
            <Button appearance="link" onClick={downloadTemplate} style={{ color: "#0b62e4" }}>Download Template</Button>
          </div>
        </tr>        
        </table>
          <div className={`workflow-footer ${isNavPanelOpen ? '': 'collapsed'}`}>
              <Button type='button' appearance='secondary' onClick={() => _onNavMenuItemClick(NavMenuItemType.Configuration)} style={{ marginRight: '20px' }}>Previous</Button>
              <Button type='button' appearance='primary' disabled={workflow?.config?.workflowName === '' || workflow.datasetFileUpload === undefined || workflow?.datasetFileUpload?.inputFileName === null || workflow?.datasetFileUpload?.erroredFileName !== null} onClick={() => onClickNext()}>Next</Button> 
          </div>
      </div>
    </>
    );  
};
      
export const FileUpload = withErrorBoundary("Dataset Creation", FileUploadC);