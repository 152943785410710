import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../root-redux/RootState";
import { useSelector } from "react-redux";
import { Checkbox, Icon, FlyInPanel } from "../../common/HarmonyEnablers";
import { DataGrid, Button, SearchBox, HoverCard } from '../merge-rules/MergeRulesScope'
import { updateCurrentMergeRuleSet } from "../merge-rules/merge-rules-by-id-redux/MergeRuleById.actions.redux";
import { CheckPageAccessSelector } from "../../shared-components/role-based-access-control/CheckAccessPage.selectors.redux";
import { ModelListDataGridRow, addedModelsColumns, addedModelsItemSelectorData, isArraySame } from './MergeRuleSetModelsColumns'
import { ModelDataGridRow, modelColumns, modelsItemSelectorByData } from "./mergerule-panel/ModelListColumns";
import { AddedFieldsDataGridRow, addedFieldColumns, addedFieldsItemSelectorData } from "./MergeRuleSetFieldsColumns"
import { FieldListDataGridRow, allFieldsItemSelectorData, fieldsListColumns } from "./mergerule-panel/FieldListColumns";
import { withErrorBoundary } from "../../shared-components/ErrorBoundary";


 const MergeRuleSetFieldsC: React.FC<any> = (props: any) => {
  const dispatch = useDispatch<AppDispatch>()
  const mergeRule = useSelector((state: RootState) => state.mergeRuleById.mergeRule);
  const currentMergeRuleSet = useSelector((state: RootState) => state.mergeRuleById.currentMergeRuleSet);
  const access = CheckPageAccessSelector(mergeRule?.subscriptionKey)
  const [addedModels, setAddedModels] = useState([] as ModelListDataGridRow[]);
  const workflowsBySubKeys = useSelector((state: RootState) => state.workflowsBySubKeys.list);

  const [openFieldsPanel, setOpenFieldsPanel] = useState(false)
  const [addedFields, setAddedFields] = useState([] as AddedFieldsDataGridRow[])
  const [fieldsListRow, setFieldsListRow] = useState([] as FieldListDataGridRow[]);
  const [selectedFields, setSelectedFields] = useState([] as string[])
  const [allselectedFields, setAllSelectedFields] = useState([] as string[])
  const [searchText, setSearchText] = useState("")
  const [totalFieldCount, setTotalFieldCount] = useState(0);

  useEffect(() => {
    if (searchText) {
      setAllSelectedFields(selectedFields)
      var finalRows: FieldListDataGridRow[] = []
      fieldsListRow.map((row) => {
        if (row.cells?.field1?.toLowerCase().includes(searchText) || row.cells?.field2?.toLowerCase().includes(searchText)) {
          finalRows.push(row)
        }
      })
      setFieldsListRow(finalRows)
    } else {
      var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })
      var all = allselectedFields.filter(x => !selectedFields.includes(x));
      var join = selectedFields.concat(all)
      join = join.filter((v, i, a) => a.indexOf(v) == i)
      setSelectedFields(join)
      setFieldsListRow(allFieldsItemSelectorData(workflows, currentMergeRuleSet,selectedFields))
    }
  }, [searchText])

  useEffect(() => {
    setAddedFields(addedFieldsItemSelectorData(currentMergeRuleSet))
  }, [currentMergeRuleSet.mergeOn.fields.default])

  useEffect(() => {
    if (openFieldsPanel) {
      //MRTODO - removed 
      var workflows = workflowsBySubKeys.filter(x => { return currentMergeRuleSet.mergeModel.includes(x.modelName) })
      var items = allFieldsItemSelectorData(workflows, currentMergeRuleSet,selectedFields)
      setFieldsListRow(items)
      setTotalFieldCount(items.length)
    }
  }, [openFieldsPanel])

  const handleRequestClose = (event: any) => {
    if (event.target === event.currentTarget && event.detail.source === 'close-button') {
      setOpenFieldsPanel(false)
      setSelectedFields([])
      setSelectedFields([])
    }
  }

  const handleDeleteFields = (row: any) => {
    var localFields = currentMergeRuleSet.mergeOn.fields.default.filter((x: any) => { return x !== row.cells.field1 })
    var fields = { ...currentMergeRuleSet.mergeOn.fields, default: localFields }
    var mergeOn = { ...currentMergeRuleSet.mergeOn, fields: fields }
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, mergeOn: mergeOn }))
  }

  const onAddFieldsClick = () => {
    var localFields = currentMergeRuleSet?.mergeOn?.fields?.default
    var fieldsRetainedInOrder = localFields.filter(x => { return selectedFields.includes(x) })
    var fieldsNewlyAdded = selectedFields.filter(x => { return !localFields.includes(x) })
    var finalFields = fieldsRetainedInOrder.concat(fieldsNewlyAdded)
    var fields = { ...currentMergeRuleSet.mergeOn.fields, default: finalFields }
    var mergeOn = { ...currentMergeRuleSet.mergeOn, fields: fields }
    dispatch(updateCurrentMergeRuleSet({ ...currentMergeRuleSet, mergeOn: mergeOn }))
    setOpenFieldsPanel(false)
    setSelectedFields([])
    setAllSelectedFields([])
  }

  const onCancelFieldsClick = () => {
    setOpenFieldsPanel(false)
    setSelectedFields([])
    setAllSelectedFields([])
  }

  const onRowSelectFields = (e: any) => {
    var fields = [] as string[]
    for (var i = 0; i < fieldsListRow.length; i++) {
      if (fieldsListRow[i].selected) {
        fields.push(fieldsListRow[i].cells.field1)
      }
    }
    if(fieldsListRow.length !== totalFieldCount) {
      var allFields = fieldsListRow.map(x => x.cells.field1)
      var all = allselectedFields.filter(x => !allFields.includes(x));
      var join = fields.concat(all)
      join = join.filter((v, i, a) => a.indexOf(v) == i)
      setSelectedFields(join)
      var fieldsFromListPartOfAllSelectedFields = allFields.filter(x => allselectedFields.includes(x))
      var fieldsThatShouldntBeSelectedAnymore = fieldsFromListPartOfAllSelectedFields.filter(x => !fields.includes(x))
      var allselectedFieldsUpdated = allselectedFields.filter(x => !fieldsThatShouldntBeSelectedAnymore.includes(x))
      setAllSelectedFields(allselectedFieldsUpdated)
    } else {
      setSelectedFields(fields)
      setAllSelectedFields(fields)
    }
  }

  const checkIfFieldsAreDifferent = () => {
    if(currentMergeRuleSet.mergeOn.fields.default && currentMergeRuleSet.mergeOn.fields.default.length){
      return isArraySame(currentMergeRuleSet.mergeOn.fields.default, selectedFields);
    } else{
      return selectedFields.length <= 0
    }
  }

  return (
    <>
      <div>
        {
          openFieldsPanel &&
          <FlyInPanel style={{ '--size': 'var(--he-panel-size-medium)', marginBottom: "100px" }}
            heading="Add Fields To Merge"
            open={openFieldsPanel} onHeRequestClose={handleRequestClose}>
            <div style={{ marginBottom: "10px", fontSize: "15px" }}>Below is the list of Fields available to choose to merge input recommendations on</div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
              <SearchBox style={{ width: "300px", marginLeft: "0px" }} placeholder="Search Field/Source Name" onInput={(e: any) => { setSearchText(e.target.value) }} onHeClear={() => { setSearchText("") }}></SearchBox>
            </div>
            <DataGrid fixedHeading={true}
              rows={fieldsListRow} columns={fieldsListColumns} select="multiple" onHeRowSelectChange={(e) => { onRowSelectFields(e.detail) }}>
              <span slot="no-records">No fields found. </span>
              {fieldsListRow.map(row => {
                return <>
                  <HoverCard slot={`field2-${row.id}`} key={`field2-${row.id}`} placement={"start"} fixedPlacement={true} heading="">
                    <div slot="anchor" role="button" key={`field2-${row.id}`}>{row.cells.field2 === null || row.cells.field2 === '' ? '' : row.cells.field2 != null && row.cells.field2.length > 30 ? row.cells.field2.substring(0, 30) + "..." : row.cells.field2}</div>
                    {row.cells.field2 &&
                      <code style={{ color: "black" }}>{row.cells.field2.split(",").map(eachRow => {
                        return <div>{eachRow}</div>
                      })}
                      </code>
                    }
                    {!row.cells.field2 &&
                      <code style={{ color: "black" }}>
                      </code>
                    }
                  </HoverCard>
                </>
              })}
            </DataGrid>
            <div className="medium-flyinpanel-footer">
              <Button type='button' appearance='primary' disabled={(checkIfFieldsAreDifferent()) || selectedFields.length === 0} onClick={onAddFieldsClick} >Add</Button>
              <span style={{ marginLeft: '20px' }}></span>
              {(<Button type='button' appearance='secondary' onClick={onCancelFieldsClick}>Cancel</Button>)}
              <span style={{ marginLeft: '20px' }}></span>
            </div>
          </FlyInPanel>
        }
        <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={() => { setOpenFieldsPanel(true) }} >
          <Icon slot="start" name="add"></Icon>
          Add Merge Fields 
        </Button>
        <DataGrid fixedHeading={true}
          style={{ marginBottom: "10px", width: "25%" }} rows={addedFields} columns={addedFieldColumns} >
          <span slot="no-records">No Fields added.</span>
          {addedFields.map(row => {
            return (
              <>
                <span slot={`field2-${row.id}`} key={`field2-${row.id}`}>
                  <Button disabled={!access.canEdit} appearance="command" style={{ fontSize: "14px" }} onClick={() => handleDeleteFields(row)} >
                    <Icon label="delete rule" slot="start" name="delete"></Icon>
                  </Button>
                </span>
              </>
            )
          })}
        </DataGrid>
      </div>
    </>
  )
}
export const MergeRuleSetFields = withErrorBoundary("Merge rule set fields", MergeRuleSetFieldsC);